import React from "react";

class Help extends React.Component {
  render() {
    return (
      <div>
        <div className="row" style={{ margin: "2% 1% 1% 1%" }}>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <iframe
              title="GroGuru InSites Overview"
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/e201geJMzi0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true" />
            <div className="card-header-text">
              <h4 className="text-left" style={{ color: "#008080" }}>GroGuru InSites Overview</h4>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <iframe
              title="GroGuru InSites Graphing 101"
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/iPuWWXTkU4E"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true" />
            <div className="card-header-text">
              <h4 className="text-left" style={{ color: "#008080" }}>GroGuru InSites Graphing 101</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Help;
