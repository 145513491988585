import React from "react";
import { connect } from 'react-redux';
import defaultimg from "../../../assets/images/farms/farm-create/group-2.png";
import { Form } from "react-bootstrap";
import Joi from "joi-browser";
import { default as GForm } from "../../common/form";
import siteService from "../../sites/duck/siteService";
import Message from "../../common/messages";
import groupService from '../../group/duck/groupService';


class SettingsTabContent extends GForm {

  state = {
    data: {
      name: this.props.farmdetails.name || null,
      mailingAddress: this.props.farmdetails.mailingAddress || null,
      physicalAddress: this.props.farmdetails.physicalAddress || '',
      latitude: this.props.farmdetails.location.latitude || null,
      longitude: this.props.farmdetails.location.longitude || null
    },
    errors: {},
    responseMsg: null,
    uploadedImage: null,
    dataSent: false,
    fileId: null,
    image: this.props.farmdetails.image || null
  };


  schema = {
    name: Joi.string().required().label("Name"),
    mailingAddress: Joi.string().required().label(" mailingAddress"),
    physicalAddress: Joi.string().allow('')
      .label("physicalAddress"),
    latitude: Joi.number()
      .required()
      .label("Latitude"),
    longitude: Joi.number()
      .required()
      .label("Longitude")
  };

  htmlUpload = React.createRef();

  openFileUpload = (e) => {
    this.htmlUpload.current.click();
  }

  componentDidUpdate(prevProps) {
    let changes = {};
    changes.data = { ...this.state.data };
    if (prevProps.farmdetails !== this.props.farmdetails) {
      // eslint-disable-next-line no-unused-expressions
      changes.data.name = this.props.farmdetails.name
      changes.data.mailingAddress = this.props.farmdetails.mailingAddress
      changes.data.physicalAddress = this.props.farmdetails.physicalAddress
      changes.data.latitude = this.props.farmdetails?.location?.latitude ? this.props.farmdetails?.location?.latitude : ''
      changes.data.longitude = this.props.farmdetails?.location?.longitude ? this.props.farmdetails?.location?.longitude : ''
      this.setState(changes);
    }

  }

  onUpload = (e) => {
    const imageData = e.currentTarget.files[0];
    this.setState({ responseMsg: null, errors: {} }, () => {
      groupService.postImage(imageData)
        .then((data) =>
          this.setState({ uploadedImage: URL.createObjectURL(imageData), fileId: data.objectId })
        )
        .catch(error => this.setState({ errors: { serviceError: "'image' Upload Failed" }, dataSent: true }));
    });

  }

  doSubmit = async () => {
    const { data } = this.state;
    this.setState({ dataSent: true, errors: {}, responseMsg: null }, () => {
      const fileId = this.state.fileId !== null ? { fileId: this.state.fileId } : '';
      if (this.props.singleSiteId !== null) {
        siteService
          .updateFarmSettings(this.props.singleSiteId, { ...data, ...fileId })
          .then(response => {
            this.setState({ responseMsg: response.message });
            this.props.singleFarmdetailsUpdated(response.data);
          })
          .catch(err => {
            this.setState({ errors: { serviceError: err.message } });
          });
      } else {
        siteService
          .updateFarmSettings(this.props.match.params.farmid, { ...data, ...fileId })
          .then(response => {
            this.setState({ responseMsg: response.message });
            this.props.farmdetailsUpdated(response.data)
          })
          .catch(err => {
            this.setState({ errors: { serviceError: err.message } });
          });
      }

    });
  };

  render() {
    const { serviceError } = this.state.errors;

    const { name, physicalAddress, mailingAddress, latitude, longitude } = this.state.data;

    return (
      <div className="setting-container">
        <h3>Settings</h3>
        <p>Edit the below options for this individual farm. </p>

        <div className="profile d-flex mt-25">
          <div onClick={this.openFileUpload}>
            <img src={this.state.uploadedImage ? this.state.uploadedImage : this.state.image ? this.state.image : defaultimg} alt="profile-img"
            ></img>
          </div>
          <p>Tap to Add Farm Logo +</p>
          <input name="image" type="file" onChange={this.onUpload} ref={this.htmlUpload}
            style={{ "display": "none" }} />
        </div>
        <Form className="mt-20">
          {this.renderFormGroup("name", "Farm Name", "", { placeholder: name })}
          {this.renderFormGroup(
            "mailingAddress",
            "Farm Mailing Address",
            "",
            { placeholder: mailingAddress }
          )}

          {this.renderFormGroup(
            "physicalAddress",
            "Farm Physical Address (optional)",
            "",
            { placeholder: physicalAddress }
          )}

          <div className="row">
            <div className="col-6 pr-10">
              {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                this.props.profileDetails.profile.role.name !== 'business_user' &&
                this.props.profileDetails.profile.role.name !== 'farm_user') ?
                this.renderFormGroup("latitude", "Latitude", "", { placeholder: latitude }) :
                this.renderFormGroup("latitude", "Latitude", "", { placeholder: latitude, disabled: true })}

            </div>
            <div className="col-6 pl-10 ">
              {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                this.props.profileDetails.profile.role.name !== 'business_user' &&
                this.props.profileDetails.profile.role.name !== 'farm_user') ?
                this.renderFormGroup("longitude", "Longitude", "", { placeholder: longitude }) :
                this.renderFormGroup("longitude", "Longitude", "", { placeholder: longitude, disabled: true })}
            </div>
          </div>
          <div className="">
            {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
              this.props.profileDetails.profile.role.name !== 'business_user' &&
              this.props.profileDetails.profile.role.name !== 'farm_user') ?
              this.renderButton("Save", "btn-submit btn") : null}

            {this.state.dataSent && serviceError && (
              <Message variant="error" message={serviceError} />
            )}

            {this.state.dataSent && this.state.responseMsg && (
              <Message variant="success" message={this.state.responseMsg} />
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profileDetails: state.userDetails.profileDetails,
});

export default connect(mapStateToProps)(SettingsTabContent);
