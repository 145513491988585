import React, { useEffect } from 'react';
import http from "../common/duck/httpService";
import logger from "../common/duck/logService";
import localStorageService from "../common/duck/localStorageService";


export default function Logout() {
    useEffect(() => {
        logoutService();
    })
    
    return (
        <span>Logging you out</span>
    )

    function logoutService() {
        return http.post(`/user/logout`)
        .then(response => {
            localStorageService.removeItems();
            window.location.href = "/";
            return response.data
        })
        .catch(error => {
            const { data } = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
    }
}


