import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Switch, Route } from 'react-router-dom';
import SubscriptionHistory from './subscriptions/subscriptionHistory';
import DealerSummary from './dealerSummary';
import SubscriptionAudit from './Audit/subscriptionAudit';

function ShopHome(props) {
  return (
    <div className="shop-container">
      <div className="shop-header d-flex justify-content-between">
        <div>
          <h5>Subscription</h5>
        </div>
        <div className="navbar-area">
          <Nav className="justify-content-end" activeKey="/home">
            {/* <Nav.Item>
              <Nav.Link as={NavLink} exact to="/dashboard/shop">My Plans</Nav.Link>
            </Nav.Item> */}
             <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/shop/subscriptions/audit">Audit</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/shop/subscriptions/plans">Dealer Summary</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} exact to="/dashboard/shop/subscriptions">Subscription History</Nav.Link>
            </Nav.Item>            
          </Nav>
        </div>
      </div>
      <div className="shop-details-container">
        <Switch>
          <Route path="/dashboard/shop/subscriptions/plans" component={DealerSummary} />
          <Route exact path="/dashboard/shop/subscriptions" component={SubscriptionHistory} />
          <Route exact path="/dashboard/shop/subscriptions/audit" component={SubscriptionAudit} />
          {/* <Route exact path="/dashboard/shop" component={MyPlans} /> */}
        </Switch>
      </div>
    </div>
  );
}

export default ShopHome;
