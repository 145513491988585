import React from 'react';
import TableHead from '../../common/tableHead'
import { connect } from 'react-redux';

function FarmSubscriptionsHead({isGroguruAdmin}) {

    let headings = [
        {text: 'UID', className: 'uid-width'},
        {text: 'Site ID Name'},
        {text: 'Subscribed Plan', className: 'subscription-width'},
        {text: 'Gold Silver None', className: 'actions-width'},
        {text: 'Comments', className : 'comments-width' },
    ];

    if (isGroguruAdmin) {
        headings.push({text: 'Delete site', className : 'delete-width' },);
    }
    return <TableHead heads={headings} />
}

const mapStateToProps = state => ({
    isGroguruAdmin: state.userDetails.profileDetails.profile.role.name === 'groguru_admin'
})

export default connect(mapStateToProps)(FarmSubscriptionsHead);