import React from "react";
import { connect } from "react-redux";
import { Card, Form } from "react-bootstrap";
import userService from "../duck/userService";
import localStorageService from "../../common/duck/localStorageService";
import Joi from "joi-browser";
import EditAccountInformation from "./editAccountInformation";
import EditDefaultLanding from "./editDefaultLanding";
import EditUnitPreferences from "./editUnitPreferences";
import AbstractForm from "../../common/abstractform";

const userId = localStorageService.getUserId();
class EditMyAccount extends AbstractForm {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    userService.getProfile(userId).then(response => {
      const { name, phone } = response.data.organisation;
      this.setState({ name, phone });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  state = {
    data: {
      name: this.props.profileDetails.profile.name,
      phone: this.props.profileDetails.profile.phone
    },
    disabled: true,
    errors: {},
    responseMsg:null
  };
  schema = {
    name: Joi.string()
      .required()
      .label("Name"),

    phone: Joi.string()
      .required()
      .label("Phone")
  };

  render() {

    const { serviceError } = this.state.errors;
    const { profile } = this.props.profileDetails;
    const { disabled} = this.state;
    return (
      <div className="edit-my-account">
        <div className="basic-info">
          <Card>
            <Card.Body>
              <div className="card-header-text d-flex justify-content-between">
                <p className="header-text">Basic Information</p>
                <p className="edit-text cp" onClick={this.onEdit}>
                  Edit
                </p>
              </div>
              <hr></hr>
              <div className="card-body-text">
                <Form onBlur={this.onSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderFormGroup(
                        "name",
                        "Name",
                        "text",
                        {placeholder: profile.name,
                        disabled}
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderFormGroup(
                        "phone",
                        "Phone",
                        "text",
                        {placeholder: profile.phone,
                        disabled}
                      )}
                    </div>
                  </div>
                </Form>
                <div className="">
                  {serviceError && (
                    <span className="error">{serviceError}</span>
                  )}
                  {!serviceError && (
                    <span className="success-msg ">
                      {this.state.responseMsg}
                    </span>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        {/* account info */}
        <EditAccountInformation />

        {/* default landing */}
        <EditDefaultLanding />

        {/* preference */}
        <EditUnitPreferences />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  profileDetails: state.userDetails.profileDetails
});
export default connect(mapStateToProps)(EditMyAccount);
