import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import siteService from "../../duck/siteService";
function EditSensorModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false);  
  }
   
  const handleShow = () => setShow(true);
  const [hardware, setHardware] = useState(null);
  const [local, setLocal] = useState(null);
  const [hardwareInfo, setHardwareinfo] = useState(null);
  const [localInfo, setLocalinfo] = useState(null);

  const prevProps = useRef(props.hardwareSensor, props.localSensor);
  
  useEffect(() => {
    if (prevProps.hardwareSensor !== props.hardwareSensor) {
      setHardware(props.hardwareSensor);
      setHardwareinfo(props.hardwareSensor?.value?.sensors)
    }
  }, [props.hardwareSensor]);
  useEffect(() => {
   if (prevProps.localSensor !== props.localSensor) {
      setLocal(props.localSensor);
      setLocalinfo(props.localSensor?.value?.sensors)
    }
  }, [props.localSensor]);

  function handleSubmit()
  {
    const siteobjectid = props.id;
    const hardware=hardwareInfo.map(arr=>Object.assign({},arr))
    const local=localInfo!==null&&localInfo.map(arr=>Object.assign({},arr))
   
    siteService.updateSite(siteobjectid, { hardwareSensors:hardware,localSensors:local })
    .then(res=>{
     
      const {settings} = res.data.parsedTwig;
    const hardwareSensorLocation = settings.hardwareSensorLocation;
    const localSensorLocation = settings.localSensorLocation;
   
  props.onTwigDetailsChange({localSensorLocation, hardwareSensorLocation})
  });
    handleClose()
  }
 
  function handleEdit(e, index) {
      
    const ind = index;
    const sensorname = e.target.name;
    if (sensorname === "hardwareSensorLocation") {
      let sensorarray = hardwareInfo&&hardwareInfo?.map((arr) =>
        Object.values(arr).map((sensor, index) => {
          if (ind === index) {
            return {...sensor, name: e.target.value};
          }
          return sensor;
        })
      );
   
      
      setHardwareinfo(sensorarray);
      
    }
 else {
      let sensorarray = localInfo&&localInfo?.map((arr) =>
        Object.values(arr).map((sensor, index) => {
          if (ind === index) {
             return {...sensor, name: e.target.value}
          }
          return sensor;
        }
        )
      );

      setLocalinfo(sensorarray);
      
    }
  }
  function handleEditClick()
  {
    handleShow()
  }
  // function Edit()
  // {
    
  //  setDisable(!disabled)
  // }



  return (
    <>
      <Button variant="none" className="edit-sensor" onClick={handleEditClick}>
        Edit Sensor Names
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className="editsensor-modal"
        size="lg"
        centered
      >
        <Modal.Header className="d-flex justify-content-between">
          <p >Edit Sensor Names</p>
        <div className='d-flex'>
          {/* <span  onClick={Edit} className="pr-20">
           Edit
          </span> */}
          <span onClick={handleClose}>
            Close
          </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>Sensors</h4>
          <Form>
            {local &&
              local !== null &&
              local.value &&
              local.value.sensors &&
              local.value.sensors.map((element) =>
              Object.entries(element)
              .sort(([key1], [key2]) => key1.localeCompare(key2))
              .map(item => item[1])
            .map((sensor, index) => {
                  return (
                    <Form.Group onChange={(e) => handleEdit(e, index)} >
                      <Form.Label>{`Fox Sensor:${sensor.name}`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="localSensorLocation"
                        placeholder={sensor.name}
                      />
                    </Form.Group>
                  );
                })
              )}

            {hardware &&
              hardware.value &&
              hardware.value.sensors &&
              hardware.value.sensors.map((element) =>
              Object.entries(element)
                .sort(([key1], [key2]) => key1.localeCompare(key2))
                .map(item => item[1]).map((sensor, index) => {
                  return (
                    <Form.Group>
                      <Form.Label>{`Sensor:${sensor.name}`}</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => handleEdit(e, index)}
                        name="hardwareSensorLocation"
                        placeholder={sensor.name}
                      />
                    </Form.Group>
                  );
                })
              )}

          </Form>
          <button className="btn-submit" onClick={handleSubmit}>Save</button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditSensorModal;
