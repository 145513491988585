import React, { useState } from 'react';
import ToggleDropDown from '../common/toggleDropDown';
import { FarmForm } from "./farmForm";
import PropTypes from 'prop-types';
import filterIcon from "../../assets/images/dashboard/filter-farm/shape.png";
import showAsIcon from "../../assets/images/dashboard/show-as/format-list-bulleted.png";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Search from '../common/search';
import { capitalise } from '../common/duck/utils';


function SearchBar(props) {
    const [sortType, setSortType] = useState("Alphabetical");

    let userHierarchy = capitalise(props.userHierarchy);
    if (userHierarchy === 'Groguru') {
        userHierarchy = 'Admin';
    }

    const optionsFilter = [
        {
            label: 'Recently Added', link: {
                pathname: `${props?.location?.pathname}`,
                search: props.location.search
            }
        },
        {
            label: 'Alphabetical', link: {
                pathname: `${props?.location?.pathname}`,
                search: props.location.search
            }
        }
    ];
    const optionShowAs = [
        {
            label: 'List View', link: {
                pathname: `${props.match.url}/list-view`,
                search: props.location.search
            }
        },
        {
            label: 'Map View', link: {
                pathname: `${props.match.url}/map-view`,
                search: props.location.search
            }
        },
        {
            label: `${userHierarchy} View`, link: {
                pathname: `${props.match.url}/tree-view`,
                search: props.location.search
            }
        },
    ];

    const stateFromProps = {};
    if (props.location.pathname.includes('map-view')) {
        stateFromProps.view = 'Map View';
    }
    else if (props.location.pathname.includes('tree-view')) {
        stateFromProps.view = `${userHierarchy} View`;
    }

    else {
        stateFromProps.view = 'List View';
    }

    let selected = '';
    const viewProps = {};
    if (selected === 'Agronomical View') {
        viewProps.view = 'Agronomical View';
    }
    else {
        viewProps.view = 'Technical View';
    }

    function handleSort(selected) {
        setSortType(selected);
        props.handleSort(selected);
    }

    return (
        <div className="row view-change">
            <Search placeholder={props.location.pathname.includes("tree-view") ? "Search Farms..." : "Search with Farms and SiteIDs.."} border={props.border} inputWidth="100%"
                onSearch={props.onSearchChange} />
            <div className="col-lg-9   filter-btn-farms-tab">
                <div className="d-flex filter-options-btn">
                    <div className="pr-15">
                        <ToggleDropDown
                            label='Filter Farms By'
                            selected="Alphabetical"
                            icon={filterIcon}
                            onChange={handleSort}
                            options={optionsFilter} />
                    </div>
                    <div className="pr-15">
                        <ToggleDropDown
                            label='Show As'
                            selected={stateFromProps.view}
                            icon={showAsIcon}
                            options={optionShowAs} />
                    </div>
                    <div className="">
                        <FarmForm sortType={sortType} />
                    </div>
                </div>

            </div>
        </div>
    )
}

SearchBar.propTypes = {
    onSearchChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func,
    onShowAsChange: PropTypes.func,
    viewOptionsFilter: PropTypes.func
}


const mapStateToProps = state => ({
    userHierarchy: state.userDetails.profileDetails.profile.role.name.split('_')[0]
})


export default connect(mapStateToProps)(withRouter(SearchBar));
