import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import PinColors from "./sidemenu/pincolors";
import { Button, Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";
import {connect} from 'react-redux'
import { isPlanActive } from "../common/duck/planActive";
import GraphThumbnail from "./siteDetails/graph/graphThumbnail";
import {history} from '../common/duck/history'
import userService from '../user/duck/userService';
import graphService from './siteDetails/graph/duck/graphService';
const SatelliteView = {
  options(maps) {
    return {
      mapTypeId: maps.MapTypeId.HYBRID,
    };
  },
};
const MapView = {
  options(maps) {
    return {
      mapTypeId: maps.MapTypeId.ROADMAP,
    };
  },
};
const markerStyle = {
  textAlign: "center",
  transform: "translate(-40%, -68%)",
  position: "absolute",
};
let noStemSites;
function LinkedPin({ farmid, site, lat, lng, view, userRole }) {
  let planActive = isPlanActive(userRole, site.planDetails);
  return (
    <Link
      to={ planActive ? {
        pathname: `/farm/${farmid}/site/${site.siteId}/${site.id}/${site.twigId}/graph`,
        state: site,
      } : "#" }
    >
      {view === "ACTIVE" ? <div className="multi-farm-pin d-flex">
        <PinColors
          key={site.siteId}
          width="70"
          height="70"
          style={markerStyle}
          state={site.twigDetails?.state}
          label={site.siteId}
          lat={lat}
          lng={lng}
        />
        <div>
          <h3 className="text-black">{site.siteId}</h3>
        </div>
      </div> :
        <div className="rectmarker">
          <p>{site.siteId}</p>
        </div>}
    </Link>
  );
}

class MultiFarmMap extends Component {
  state = {
    data: [],
    errors: null,
    center: null,
    show: false,
    back: false,
    maptype: "GraphThumbnails",
    mapView : 'Graph Thumbnails',
    graphThumbnailData: [],
    flag: true,
    view: '?query=Active',
    toggleNoStem:false

  };

  map = null;
  componentDidMount() {
    if (this.props.selectedView) {

      this.setState({
        selectedView: this.props.selectedView,

      });
    }
    this.getPreferences()
    this.loadGraphThumbnails()
  }

  graphThumbnailView = () => {
    let {graphThumbnailData} = this.state
    let temp = []
    let temppNoStemSites = []
    // eslint-disable-next-line array-callback-return
    graphThumbnailData.map((sites) => {
     if(sites.siteDetails){
      temp.push(sites)
     }else{
      temppNoStemSites.push(sites.siteId)
     }
    })
    graphThumbnailData = temp
    noStemSites = temppNoStemSites
    let column;
    let isGridIsFive = false;
    if(graphThumbnailData.length <= 7){
      column = 4
    }
    if(graphThumbnailData.length >= 8 && graphThumbnailData.length <= 19 ){
      column = 3
    }
    if(graphThumbnailData.length >= 20 && graphThumbnailData.length <= 24){
      column = 2
      isGridIsFive = true
    }
    if(graphThumbnailData.length >= 25){
      column = 2
    }
    if(graphThumbnailData?.length > 0){
      graphThumbnailData = graphThumbnailData.sort((a, b) => a?.siteDetails?.siteId - b?.siteDetails?.siteId)
      return(
        <div className="row d-flex align-items-center">
          {graphThumbnailData.sort((a, b) => a.siteDetails.siteId - b.siteDetails.siteId).map((item, index) => { 
           const siteObjectId = this.state?.data?.filter((x) => x.siteId === item.siteDetails.siteId)
          return( 
          <div key={index} className={`col-md-${column} col-lg-${column} col-xs-1 `} style={{marginLeft: isGridIsFive && index % 5 === 0 ? "3%" : null ,  marginBottom:10}}>
            <div className="SiteId d-flex justify-content-between">
              <span className="siteId">{item.siteDetails.siteId}:{item.siteDetails.locationName}</span>
            </div>
            <div className='GraphContainer' onClick={() => history.push(`/farm/${siteObjectId[0]?.farm?.objectId}/site/${item.siteDetails.siteId}/${siteObjectId[0].id}/${item.siteDetails.twigId}/graph`)} style={{width:'100%', paddingRight: 13}}>
              <div>
               <GraphThumbnail graphType={'AW'} 
               siteid={item.siteDetails.siteId} 
               twigid={item.siteDetails.twigId} 
               siteObjectId={siteObjectId && siteObjectId[0] && siteObjectId[0].id}
               data={item}/>
               </div>
               <div style={{marginTop:20}}>
               <GraphThumbnail graphType={'MOIST'} 
               siteid={item.siteDetails.siteId} 
               twigid={item.siteDetails.twigId} 
               siteObjectId={siteObjectId && siteObjectId[0] && siteObjectId[0].id}
               data={item}/>
               </div>
            </div>
          </div>
          )})}
        </div>
      )
    }
    return(
      <div className="row d-flex align-items-center">
        <p>Loading...</p>
      </div>
    )
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedView !== prevProps.selectedView) {
      this.setState({ selectedView: this.props.selectedView })
    }
    if (this.props.sites !== prevProps.sites) {
      this.setSiteData(this.props.sites);
    }
    if(prevState.data !== this.state.data){
      this.loadGraphThumbnails()
      
    }
  }

  loadApiMethods = (map, maps) => {
    this.mapPanTo = map.panTo.bind(map);
    this.map = map;
    this.maps = maps;



    this.setSiteData();
    // console.log('loadApiMethods', this.props.farmid)

    // siteService
    //   .getSitesForFarms(this.props.farmid)
    //   .then((response) => {
    //     this.setSiteData(response);
    //   })

    //   .catch(
    //     (error) =>
    //       console.log(error) ||
    //       this.setState({ errors: { serviceError: error } })
    //   );
  };

  setSiteData() {
    const {maptype} = this.state
    this.setState({ data: [] })
    const data = this.props.sites.map((sites) => Object.keys(sites)[0] &&
      Object.values(sites)[0].map((site) => site));
    let selecteddata = [];


    data.map((site) => {


      const sites = site.map(
        ({ siteId, name, location, twigId, id, twigDetails, farm, status, planDetails }) => ({
          siteId,
          name,
          location,
          twigId,
          id,
          twigDetails,
          farm,
          status,
          planDetails,
        })
      );
      selecteddata = [...selecteddata, ...sites]
      if(maptype !== "GraphThumbnails"){
      if(sites.length > 0) this.setMapBounds(this.map, this.maps, sites); // Update map bounds only if a particular farm has sites.
      }


      //} 
      return 1;
    });
    this.setState({ data: selecteddata });

  }

  setMapBounds = (map, maps, data) => {
    if (!map || !maps) return; // Create LatLngBounds object only after the maps object gets initialized. 
    let bounds = new maps.LatLngBounds();

    data.length !== 0 && data.map((site) => {
      return bounds.extend(
        new maps.LatLng(site?.location?.latitude, site?.location?.longitude)
      );
    });
    map.fitBounds(bounds);
  };

  backdropClass = "backdrop";

  openBackdrop = () => {
    this.setState({ show: !this.state.show, back: true });
    this.backdropClass += " open";
  };
  closeBackdrop = () => {
    this.setState({ show: false, back: false });
    this.backdropClass = "backdrop";
  };

  handleMapView = (maptype, mapView) => {
    this.setState({ maptype, show: false, back: false, mapView });
    const map = {mapView:mapView ,maptype:maptype }
    userService.updatePreferences({ map: map })
    .then(response => this.getPreferences());
    this.closeBackdrop();
  };
  getPreferences = () => {
    userService.getPreferences().then(response => {
      const mapView  = response?.data?.preference?.map?.mapView || "Graph Thumbnails";
      const maptype = response?.data?.preference?.map?.maptype || "GraphThumbnails";
      this.setState({ mapView, maptype})
  })
  }
  loadGraphThumbnails = () => {
    const state = this.props.gState
    const dateParams = state.userDetails.userPreferences.preferences.preference?.graph
    const siteIds = this.state?.data?.map((site) => {return (site.siteId)})
    const twigIds = this.state?.data?.map((site) => {return (site.twigId)})
    const params = { fromDate: dateParams.fromDate, toDate: dateParams.toDate }
    if(siteIds && siteIds.length && siteIds.length > 0){
    const apiCall = graphService.getGraphThumbnailData();
    apiCall.request(siteIds, twigIds, params)
      .then(data => {
        this.setState({graphThumbnailData:data})
      })
      .catch(err => {
        console.log("Something went wrong")
      });
    }
  }
  mapShowClass = "googleMapReact";
  handleMapToggle = () => {
    if (this.props.navOpen) this.mapShowClass = "googleMapReact";
    else this.mapShowClass = " ";
  };

  googleMapReact = (key, maptype, view) => {


    return (
      <GoogleMapReact
        style={{ position: "static" }}
        bootstrapURLKeys={{ key }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        options={
          maptype === "SatelliteView" ? SatelliteView.options : MapView.options
        }
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          this.loadApiMethods(map, maps);
        }}
      >
        {this.state.data !== [] &&
          this.state.data.map((site) => (site.status === view &&
            <LinkedPin
              key={site.siteId}
              lat={site.location?.latitude}
              lng={site.location?.longitude}
              site={site}
              farmid={site.farm?.objectId}
              view={view}
              userRole={this.props.userRole}
            />
          ))}
      </GoogleMapReact>
    );
  };

  toggleNoStemsSites = () => {
    this.setState({toggleNoStem: !this.state.toggleNoStem})
  }
  render() {

    const view = this.props.selectedView === "?query=Active" ? 'ACTIVE' : 'PENDING'
    const { show, maptype, mapView, toggleNoStem } = this.state;
    const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

    this.handleMapToggle();
    this.googleMapReact();
    return (
      // Important! Always set the container height explicitly
      <div style={{ width: "100%" }}>
        <div className={this.backdropClass} onClick={this.closeBackdrop}></div>
        <Button
          variant="none"
          className="mapOptionsToggleButton"
          onClick={this.openBackdrop}
        >
           {mapView}
        </Button>
        <Overlay show={show} placement="bottom">
          {({ placement, show: _show }) => (
            <div className="toggleMapOptions d-flex flex-column align-items-center">
            <div className="optionsFont">
              <span onClick={() => this.handleMapView("SatelliteView", "Satellite View")}>
                Satellite View
              </span>
            </div>
            <div className="optionsFont">
              <span onClick={() => this.handleMapView("MapView", "Map View")}>
                Map View
              </span>
            </div>
            <div className="optionsFont">
              <span onClick={() => this.handleMapView("GraphThumbnails", "Graph Thumbnails")}>
              Graph Thumbnails
              </span>
            </div>
          </div>
          )}
        </Overlay>
        <div className={this.mapShowClass}>
        {maptype === "GraphThumbnails" ? 
          <div className="container-fluid GraphThumbnailView">
                        {noStemSites?.length > 0 && 
            <div className='alert alert-danger'>
              <div>The below graphs do not have sites {!toggleNoStem &&  noStemSites[0]}
              {toggleNoStem&&
              noStemSites.map((site) => (
                <p>{site},</p>
              ))
               }
               {noStemSites.length >= 2 &&
              <strong onClick={() => this.toggleNoStemsSites()}>{toggleNoStem ? "show less" : "show all"}</strong>
               }

              </div>
            </div>
            }
            {this.graphThumbnailView()}
          </div> 
          : 
          <>
          {this.props.check && this.googleMapReact(key, maptype, view)}
          {!this.props.check && this.googleMapReact(key, maptype, view)}
          </>
  }
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return { gState: state }
}
export default connect(mapState, null)(MultiFarmMap);
