import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export default function(ComposedComponent) {
  class Authenticate extends React.Component {
    render() {
      const { isAuthenticated, ...rest } = this.props;
      if (isAuthenticated) {
        return <ComposedComponent {...rest} />;
      } else {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: rest.location } }}
          />
        );
      }
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  };

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.userDetails.profileDetails.profile ? true : false
    };
  };

  return connect(mapStateToProps)(Authenticate);
}
