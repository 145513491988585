import React from "react";
import { connect } from "react-redux";
import { Card, Form } from "react-bootstrap";
import AbstractForm from "../../common/abstractform";
import Joi from "joi-browser";
class EditAccountInformation extends AbstractForm {
  
  state = {
    data: {
      username: this.props.profileDetails.profile.username,
      password: ""
    },
    disabled: true,
    errors: {},
    responseMsg: null
  };
  schema = {
    username: Joi.string()
      .required()
      .label("Last Name"),

    password: Joi.string()
      .required()
      .regex(/^[a-zA-Z0-9]{3,30}$/)
      .label("Password")
  };
 
  render() {
    const { serviceError } = this.state.errors;
    const { disabled } = this.state;
    return (
      <div className="account-info mt-15">
        <Card>
          <Card.Body>
            <div className="card-header-text d-flex justify-content-between">
              <p className="header-text">Account Information</p>
              <p onClick={this.onEdit} className="edit-text cp">
                Edit
              </p>
            </div>
            <hr></hr>

            <div className="card-body-text">
              <Form onBlur={this.onSubmit}>
                {this.renderFormGroup(
                  "username",
                  "Email",
                  "email",
                  {placeholder:this.props.profileDetails.profile.username,
                  disabled}
                )}
                {this.renderFormGroup(
                  "password",
                  "Password",
                  "password",
                  {placeholder:"*******",
                  disabled}
                )}
              </Form>
              <div className="">
                {serviceError && <span className="error">{serviceError}</span>}
                {!serviceError && (
                  <span className="success-msg ">{this.state.responseMsg}</span>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  profileDetails: state.userDetails.profileDetails
});
export default connect(mapStateToProps)(EditAccountInformation);
