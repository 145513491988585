import React, { Component } from 'react';
import { connect } from "react-redux";
import Hardware from './hardware';
import Software from './software';
import ForeCastDays from './forecastDays'
import SentekApiDetails from './sentekApiDetails';
import SubscriptionSetting from './subscriptionSetting';
import DisconnectionStatus from './disconnectionStatus';
import ConnectionStatus from "./connectionStatus";
import ChangeLog from "../about/changeLog";
import IrrigationTypes from "./irrigationTypes";
import SiteContext, { SiteUpdate } from '../graph/duck/siteContext';
import ExpectedStages from "./expectedStages"
class ConfigurationBody extends Component {
    state = {
        updateChangeLogs: false
    }
    static contextType = SiteContext;
    setChangeLogs = (value) => {
        this.setState({ updateChangeLogs: value })
    }

    render() {
        const hardwaredetails = this.context;
        return (


            <SiteUpdate.Consumer>{(updateContext) =>

                <div className="body-container">

                    {this.props.isDirectGroguruChild ?
                        <div className="row">

                            <div className="col-lg-6 mt-10">
                                <Hardware hardwaredetails={hardwaredetails} siteUpateFunctions={updateContext}
                                    onSiteUpdate={this.props.onSiteUpdate} setChangeLogs={this.setChangeLogs}
                                />


                            </div>
                            <div className="col-lg-6 mt-10">
                                <SubscriptionSetting hardwaredetails={hardwaredetails} siteUpateFunctions={updateContext} setChangeLogs={this.setChangeLogs} />
                            </div>


                        </div>
                        :
                        <div className="row">
                            <div className="col-md-12">
                                <Hardware hardwaredetails={hardwaredetails} siteUpateFunctions={updateContext}
                                    onSiteUpdate={this.props.onSiteUpdate} setChangeLogs={this.setChangeLogs} />
                            </div>

                        </div>
                    }
                    <div className="row ">
                        <div className="col-lg-6 mt-10">
                            <Software hardwaredetails={hardwaredetails}
                                siteDetails={this.props.siteDetails} />
                        </div>
                        <div className="col-lg-6 mt-10">
                            <ConnectionStatus hardwaredetails={hardwaredetails} />
                        </div>
                    </div>
                    <div className="row ">
                        <div className={this.props.userRole === 'groguru_admin' || hardwaredetails?.planDetails?.plan === "Gold" ? "col-lg-6 mt-10" : "col-lg-12 mt-10"}>
                            <SentekApiDetails hardwaredetails={hardwaredetails} siteDetails={this.props.siteDetails} />
                        </div>
                        {this.props.userRole === 'groguru_admin' || hardwaredetails?.planDetails?.plan === "Gold" ?
                            <div className="col-lg-6 mt-10">
                                <ForeCastDays hardwaredetails={hardwaredetails}
                                    siteDetails={this.props.siteDetails} />
                            </div> : null}
                    </div>
                    <div className="row ">
                        {
                            this.props.userRole === 'groguru_admin' || hardwaredetails?.planDetails?.plan === "Gold" ? (
                                <div className="col-lg-6 mt-10">
                                    <IrrigationTypes siteDetails={this.props.siteDetails} />
                                </div>
                            )
                                : (
                                    <div className="col-lg-6 mt-10">
                                        <DisconnectionStatus hardwaredetails={hardwaredetails} />
                                    </div>
                                )
                        }
                        {
                            this.props.userRole === 'groguru_admin' || hardwaredetails?.planDetails?.plan === "Gold" ? (
                                <div className="col-lg-6 mt-10">
                                    <DisconnectionStatus hardwaredetails={hardwaredetails} />
                                </div>
                            )
                                : (
                                    <div className="col-lg-6 mt-10">
                                        <ChangeLog hardwaredetails={hardwaredetails} updateChangeLogs={this.state.updateChangeLogs} />
                                    </div>
                                )
                        }
                    </div>
                    <div className="row">
                        {
                            this.props.userRole === 'groguru_admin' || hardwaredetails?.planDetails?.plan === "Gold" ? (
                                <div className="col-lg-6 mt-10">
                                    <ChangeLog hardwaredetails={hardwaredetails} updateChangeLogs={this.state.updateChangeLogs} />
                                </div>
                            ) : null
                        }
                        {   
                            this.props.siteDetails?.twigDetails?.settings?.plantType?.value === "Potatoes" ? (
                                <div className="col-lg-6 mt-10">
                                    <ExpectedStages siteDetails={this.props.siteDetails} />
                                </div>
                            ) : ""
                        }
                    </div>
                </div>}
            </SiteUpdate.Consumer>


        );
    }
}


const mapStateToProps = (state) => ({

    isDirectGroguruChild: state.userDetails.userPrivilages.isDirectGroguruChild,
    userRole: state.userDetails.profileDetails.profile.role.name,
});

export default connect(mapStateToProps)(ConfigurationBody);
