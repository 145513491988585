import {dateTimeFormat} from '../dateUtility';
import {capitalise} from '../../../../../common/duck/utils';
import {labelSeries} from '../../graphLegend';
import {DATA_TYPES} from '../constants';

export default function makeDocForPrint(
    siteName,
    siteHardwareType,
    siteid,
    siteLastUpdated,
    sitePlantType,
    displayKey,
    fromDate,
    toDate,
    dataType,
    curves,
    currentDisabledLines
 ) {

    let selectedLines = {};
    curves.forEach(line => {
        const {shortName, measurement, isHidden, unit} = line.meta;
        if (shortName && !isHidden && !currentDisabledLines.includes(line.data.name)) {
            const label = labelSeries({
                name: line.meta.measurement,
                depth: line.meta.depth?.depth,
                depthName: line.meta.depth?.name,
                seriesName: line.data.name,
                showTopSensorLabel: line.meta.showTopSensorLabel
            });
            if (selectedLines[measurement]) {
                selectedLines[measurement].push({label, color: line.color});
            } else {
                selectedLines[measurement] = [{label, color: line.color}]
                selectedLines[measurement].unit = unit;
            }
        }
    });

    const selectedSensors = Object.keys(selectedLines)
        .map(measure => {
            return {
            selected: selectedLines[measure]
                .map(sensor => `<span class="sensor-label" style="border-left: solid 1rem ${sensor.color}">${sensor.label}</span>`)
                .join(''),
            measure,
            unit: selectedLines[measure].unit
            };
        })
        .map(sensorsForMeasurements => `<div class="alt-right-border" style="padding: 0.3rem 0.5rem">
            <h3 style="margin: 0rem">${sensorsForMeasurements.measure} (${sensorsForMeasurements.unit})</h3>
            <div style="display: inline-block; padding: 0.3rem 0">${sensorsForMeasurements.selected}</div>
        </div>`)
        .join('');

    return `<style>
      @page {size: landscape}
      .alt-right-border:not(:last-child) {
        border-right: solid 2px black;
      }
      .sensor-label {
        padding-left: 0.3rem;
        display: inline-block;
        margin: 0.2rem 0rem;
      }
      .spaced-evenly {
        display: flex;
        justify-content: space-evenly
      }
    </style>
    <h1 style="margin: 3px">${siteName}</h1>
    <div class="spaced-evenly print-target">
      <div>
          <h6 style="margin: 0px">Hardware Type</h6>
          <p>${siteHardwareType}</p>
      </div>
      <div>
          <h6 style="margin: 0px">Site ID #</h6>
          <p>${siteid}</p>
      </div>
      <div>
          <h6 style="margin: 0px">Last Updated</h6>
          <p>${dateTimeFormat(siteLastUpdated)}</p>
      </div>
      <div>
          <h6 style="margin: 0px">Crop Type</h6>
          <p>${sitePlantType}</p>
      </div>
  </div>
  <div class="spaced-evenly print-target">
      <div>
          <h6 style="margin: 0px">Display Option</h6>
          <p>${capitalise(displayKey)}</p>
      </div>
      <div>
          <h6 style="margin: 0px">Time Period</h6>
          <p>${`${dateTimeFormat(fromDate)} - ${dateTimeFormat(toDate)}`}</p>
      </div>
      <div>
          <h6 style="margin: 0px">Data Type</h6>
          <p>${dataType.map(d => DATA_TYPES[d]).join(', ')}</p>
      </div>
  </div>
  <div class="spaced-evenly">${selectedSensors}</div>`
}