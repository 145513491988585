import React, { useState } from "react";
import Pins from "../sidemenu/pins";

const PIN_COLORS = {
  // BLUE: "#175c93",
  // GREEN: "#47be9d",
  // YELLOW: "#e9a64f",
  // RED: "#e6726c",
  // GREY: "#6f6f6f",
  // WHITE: "#f5f5f5",
  // DEFAULT: "#6f6f6f",
  // PINK: "#901f72",
  // LIGHT_BLUE: '#6899ff'

  // BLUE: "#0053ff",
  // GREEN: "#66b92d",
  // YELLOW: "#ffff00",
  // RED: "#fd0d33",
  BLUE: "#175c93",
  GREEN: "#66b92d",
  YELLOW: "#ffe119",
  RED: "#cc3d37",
  GREY: "#6f6f6f",
  WHITE: "#f5f5f5",
  DEFAULT: "#6f6f6f",
  PINK: "#df8ad2",
  LIGHT_BLUE: '#c0c9f0'
};


export default function PinColors(props) {
  const status = props.state;
  const pinWidth =  props?.width || 70;
  const pinHeight = props?.height || 70;
  const pinStyle = props?.style || { marginLeft: "20px", paddingBottom: "30px" };
  const [style] = useState(pinStyle);
  let borderFill=PIN_COLORS.DEFAULT;
  let insideFill=PIN_COLORS.DEFAULT;

  let twigColorStatus = "";

  if (status?.justInstalled) {
    twigColorStatus = "pre-install";
  } else if(status?.moisture && status?.salinity){    
  twigColorStatus="both"
  } else if (status?.irrigatingNow) {
    twigColorStatus = "irrigating-now";
  } else if (status?.updatedRecently === false) {
    twigColorStatus = "no-data-received";
  } else if (status?.moisture || status?.salinity) {
    twigColorStatus = "active-twig";
  }else if (status?.pressure) {
      twigColorStatus = "pressure";
    }
   else {
    twigColorStatus = "default";
  }

  let sta='';

  if(status?.salinity==="HIGH" && status?.moisture==="HIGH"){
    sta="high-high";
  } else if(status?.salinity==="HIGH" && status?.moisture==="GOOD"){
    sta="high-good"
  } else if(status?.salinity==="HIGH" && status?.moisture==="FAIR"){
    sta="high-fair"
  } else if(status?.salinity==="HIGH" && status?.moisture==="MED"){
    sta="high-med"
  } else if(status?.salinity==="HIGH" && status?.moisture==="LOW"){
    sta="high-low"
  } else if(status?.salinity==="MED" && status?.moisture==="HIGH"){
    sta="med-high"
  } else if(status?.salinity==="MED" && status?.moisture==="GOOD"){
    sta="med-good"
  } else if(status?.salinity && status?.salinity==="MED" && status?.moisture &&status?.moisture==="FAIR"){
    sta="med-fair"
  } else if(status?.salinity==="MED" && status?.moisture==="MED"){
    sta="med-med"
  } else if(status?.salinity==="MED" && status?.moisture==="LOW"){
    sta="med-low"
  } else if(status?.salinity==="LOW" && status?.moisture==="HIGH"){
    sta="low-high"
  } else if(status?.salinity==="LOW" && status?.moisture==="GOOD"){
    sta="low-good"
  } else if(status?.salinity && status?.salinity==="LOW" && status?.moisture &&status?.moisture==="FAIR"){
     sta="low-fair"
  } else if(status?.salinity==="LOW" && status?.moisture==="MED"){
    sta="low-med"
  } else {
    sta="low-low"
  }


  switch (twigColorStatus) {
    case "pre-install": 
          borderFill=PIN_COLORS.WHITE;
          insideFill=PIN_COLORS.WHITE;
          break;
    case "no-data-received": 
          borderFill=PIN_COLORS.GREY;
          insideFill=PIN_COLORS.GREY;
          break;
    case "irrigating-now": {
            if (status?.irrigatingNow === "ON") {
                borderFill=PIN_COLORS.GREEN
                insideFill=PIN_COLORS.BLUE;
                break;
            } else {
                borderFill=PIN_COLORS.GREY;
                insideFill=PIN_COLORS.WHITE;
                break;
            }
          }
    case "active-twig": {
     
      if (status?.moisture) {
        switch (status?.moisture) {
          case "LOW":
                borderFill=PIN_COLORS.DEFAULT;
                insideFill=PIN_COLORS.RED;
                break;
          case "FAIR":
                borderFill=PIN_COLORS.DEFAULT;
                insideFill=PIN_COLORS.GREEN;
                break;
          case "MED":
                borderFill=PIN_COLORS.DEFAULT;
                insideFill=PIN_COLORS.YELLOW;
                break;
          case "HIGH":
                borderFill=PIN_COLORS.DEFAULT;
                insideFill=PIN_COLORS.BLUE;
                break;
          case "GOOD":
                borderFill=PIN_COLORS.DEFAULT;
                insideFill=PIN_COLORS.LIGHT_BLUE;
                break;
          default:break;
        }
      }

      switch (status?.salinity) {
        case "LOW":
              borderFill=PIN_COLORS.GREEN;
              insideFill=PIN_COLORS.DEFAULT;
              break;

        case "MED":
              borderFill=PIN_COLORS.YELLOW;
              insideFill=PIN_COLORS.DEFAULT;
              break;
        case "HIGH":
              borderFill=PIN_COLORS.RED;
              insideFill=PIN_COLORS.DEFAULT;
              break;
        default:break;
      }
      break;
    }

    case "both": {
                switch(sta){
                    case "high-high": 
                          borderFill=PIN_COLORS.RED;
                          insideFill=PIN_COLORS.BLUE;
                          break;
                    case "high-good":
                          borderFill=PIN_COLORS.RED;
                          insideFill=PIN_COLORS.LIGHT_BLUE;
                          break;
                    case "high-fair":
                          borderFill=PIN_COLORS.RED;
                          insideFill=PIN_COLORS.GREEN;
                          break;
                    case "high-med":
                          borderFill=PIN_COLORS.RED;
                          insideFill=PIN_COLORS.YELLOW;
                          break;
                    case "high-low":
                          borderFill=PIN_COLORS.RED;
                          insideFill=PIN_COLORS.RED;
                          break;
                    case "med-high":
                          borderFill=PIN_COLORS.YELLOW;
                          insideFill=PIN_COLORS.BLUE;
                          break;
                    case "med-good":
                          borderFill=PIN_COLORS.YELLOW;
                          insideFill=PIN_COLORS.LIGHT_BLUE;
                          break;
                    case "med-fair":
                          borderFill=PIN_COLORS.YELLOW;
                          insideFill=PIN_COLORS.GREEN;
                          break;
                    case "med-med":
                          borderFill=PIN_COLORS.YELLOW;
                          insideFill=PIN_COLORS.YELLOW;
                          break;
                    case "med-low":
                          borderFill=PIN_COLORS.YELLOW;
                          insideFill=PIN_COLORS.RED;
                          break;
                    case "low-high":
                          borderFill=PIN_COLORS.GREEN;
                          insideFill=PIN_COLORS.BLUE;
                          break
                    case "low-good":
                          borderFill=PIN_COLORS.GREEN;
                          insideFill=PIN_COLORS.LIGHT_BLUE;
                          break;
                    case "low-fair":
                          borderFill=PIN_COLORS.GREEN;
                          insideFill=PIN_COLORS.GREEN;
                          break;
                    case "low-med":
                          borderFill=PIN_COLORS.GREEN;
                          insideFill=PIN_COLORS.YELLOW;
                          break;
                    case "low-low":
                          borderFill=PIN_COLORS.GREEN;
                          insideFill=PIN_COLORS.RED;
                          break;
                    default:break;
                          }
                          break;
                  }

   case "pressure" : {
         if(status?.pressure === "ON"){
         borderFill=PIN_COLORS.GREEN;
         insideFill=PIN_COLORS.BLUE;
         }else{
       borderFill=PIN_COLORS.GREY;
      insideFill=PIN_COLORS.WHITE;
    
   }
   break;
}

    case "default":
          borderFill=PIN_COLORS.DEFAULT;
          insideFill=PIN_COLORS.DEFAULT;
          break;
    default:break;
  }

  return (
  <Pins
  style={style}
  width={pinWidth}
  height={pinHeight}
  borderFill={borderFill}
  insideFill={insideFill}
  />)
}
