import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// import selectZoom from '../../../assets/images/zoom/zoom1.png';
import xzoom from '../../../assets/images/zoom/xzoom.png';
import {
    hideSeries,
    showSeries,
} from '../../common/chart/utils';
const coordsLike = {
    x: PropTypes.string,
    y: PropTypes.string
}

class ThresholdChart extends Component {

    static propTypes = {
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        lineWidth: PropTypes.number,
        series: PropTypes.array.isRequired,
        stacked: PropTypes.bool,
        tooltip: PropTypes.bool,
        legend: PropTypes.bool,
        axisLabels: PropTypes.shape(coordsLike),
        xaxisType: PropTypes.oneOf(['category', 'datetime']),
        zoomType: PropTypes.oneOf(['x', 'y', 'xy']),
        xcategories: PropTypes.array,
        yAxis: PropTypes.array,
        yAxisTooltipFormat: PropTypes.func,
        seriesNameFormat: PropTypes.func,
        customTooltip: PropTypes.func,
        red: PropTypes.array.isRequired,
        red2: PropTypes.array.isRequired,
        timePeriod: PropTypes.string.isRequired,
        yellow: PropTypes.array.isRequired,
        yellow2: PropTypes.array.isRequired,

        green: PropTypes.array.isRequired,
        green2: PropTypes.array.isRequired,
        blueAbsolute: PropTypes.array.isRequired,
        blueAutoAbsolute: PropTypes.array.isRequired,
        maroonAbsolue: PropTypes.array.isRequired,
        maroonAutoAbsolute: PropTypes.array.isRequired,

        redAbsolute: PropTypes.array.isRequired,
        redAutoAbsolute: PropTypes.array.isRequired,

        yellowAbsolute: PropTypes.array.isRequired,
        yellowAutoAbsolute: PropTypes.array.isRequired,

        greenAbsolute: PropTypes.array.isRequired,
        greenAutoAbsolute: PropTypes.array.isRequired,
        checkList: PropTypes.object.isRequired,
    }

    static defaultProps = {
        tooltip: true,
        lineWidth: 2,
        width: '100%',
        legend: false,
        stacked: false,
        xaxisType: 'datetime',
        zoomType: 'x',

    }

    tools = {
        download: false,
        selection: false,
        zoomin: `<img src='${xzoom}' width="28">`,
        zoomout: false,
        zoom: '<i class="fas fa-crosshairs"></i>',
        pan: true,
        reset: false,
    }


    state = {
        options: {
            chart: {
                id: this.props.id,
                stacked: this.props.stacked,
                stackType: '100%',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: this.tools,
                    autoSelected: 'zoom'
                },
                animations: {
                    enabled: false
                },
                markers: {
                    size: 0
                }
            },
            xaxis: {
                labels: {
                    datetimeUTC: false,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'd MMM',
                        hour: 'HH:mm',
                    },
                    hideOverlappingLabels: true,
                },
                crosshairs: {
                    show: true,
                    position: 'front',
                    stroke: {
                        color: '#b6b6b6',
                        width: 1,
                        dashArray: 4,
                    }
                },
                tooltip: {
                    enabled: true,
                },
            },
            annotations: {
                position: 'front',

            },

            stroke: {
                curve: 'smooth',
                width: this.props.lineWidth,
            },
            tooltip: {
                enabled: this.props.tooltip
            },
            legend: {
                show: this.props.legend
            }
        },

    }

    constructor(props) {
        super(props);
        const series = props.series.data || props.series;
        this.state.series = series.map(item => item.data);
        if (series.length > 0) {
            this.state.options.colors = series.map(item => item.color);
            this.state.options.stroke.dashArray = series.map(item => item.dashArray);
            this.state.options.tooltip.enabledOnSeries = series.map((item, seriesIndex) => item.tooltip && seriesIndex).filter(Number.isInteger);
        }
        if (props.axisLabels) {
            this.state.options.xaxis.title = { text: this.props.axisLabels.x };
            this.state.options.yaxis.title = { text: this.props.axisLabels.y };
        }
        if (props.yAxis && props.yAxis.length > 0) {
            this.state.options.yaxis = props.yAxis;
        }
        if (props.xcategories) {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                type: 'category',
                categories: props.xcategories
            };
        } else {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                type: props.xaxisType
            }
        }
        if (props.yAxisTooltipFormat) {
            this.state.options.tooltip.y = { formatter: props.yAxisTooltipFormat }
        }
        if (props.seriesNameFormat) {
            this.state.options.tooltip.y = {
                ...this.state.options.tooltip.y,
                title: { formatter: props.seriesNameFormat }
            }
        }
        if (props.zoomType) {
            this.state.options.chart.zoom = {
                type: props.zoomType
            }
        }
        if (props.customTooltip) {
            this.state.options.tooltip.custom = props.customTooltip;
            this.state.options.tooltip.shared = true;
        }
        if (props.xAxisLabel) {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                tooltip: {
                    formatter: props.xAxisLabel
                }
            }
        }
        if (this.props.tools) {
            this.state.options.chart.toolbar.tools.customIcons = this.props.tools;
        } else if (this.props.tools === false) {
            this.state.options.chart.toolbar.show = false;
        }
        props.series.stacked !== undefined && (this.state.options.chart.stacked = props.series.stacked);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.series !== this.props.series ||
            prevProps.axisLabels !== this.props.axisLabels ||
            prevProps.xcategories !== this.props.xcategories ||
            prevProps.yAxis !== this.props.yAxis ||
            prevProps.seriesNameFormat !== this.props.seriesNameFormat ||
            prevProps.tools !== this.props.tools) {
            let changes = {};
            changes.options = { ...this.state.options };
            if (prevProps.series !== this.props.series) {
                let seriesData = this.props.series.data || this.props.series;
                changes.series = seriesData.map(item => item.data);
                changes.options.colors = seriesData.map(item => item.color);
                changes.options.stroke.dashArray = seriesData.map(item => item.dashArray);
                changes.options.stroke.width = seriesData.map(item => item.meta?.lineWidth ? item.meta.lineWidth : 2);
                changes.options.tooltip.enabledOnSeries = seriesData.map((item, seriesIndex) => item.tooltip && seriesIndex).filter(Number.isInteger);
            }
            if (prevProps.axisLabels !== this.props.axisLabels || prevProps.xcategories !== this.props.xcategories) {
                if (this.props.axisLabels && prevProps.axisLabels !== this.props.axisLabels) {
                    changes.options.xaxis.title.text = this.props.axisLabels.x;
                    changes.options.yaxis.title.text = this.props.axisLabels.y;
                }
                if (this.props.xcategories && (prevProps.xcategories !== this.props.xcategories)) {
                    changes.options.xaxis.categories = this.props.xcategories;
                }
            }
            if (prevProps.yAxis !== this.props.yAxis) {
                changes.options.yaxis = this.props.yAxis;
            }
            if (prevProps.seriesNameFormat !== this.props.seriesNameFormat) {
                changes.options.tooltip.y.title = { formatter: this.props.seriesNameFormat }
            }
            if (prevProps.tools !== this.props.tools) {
                if (this.props.tools) {
                    changes.options.chart.toolbar.show = true;
                    changes.options.chart.toolbar.tools.customIcons = this.props.tools;
                } else {
                    changes.options.chart.toolbar.show = false;
                }
            }
            this.setState(changes, () => {
                if (changes?.series) {
                    const names = changes?.series?.map(item => item.name);
                    if (names.length > 0) {
                        names.map(i => {
                            return hideSeries("threshold-graph", 'hideSeries', i);
                        });
                        showSeries("threshold-graph", 'showSeries', names[this.props.sensorIndex]);
                    }

                }

            });


        }

    }

    render() {

        return <ReactApexChart
            keys={this.props.keys}
            options={this.state.options}
            series={this.state.series}
            width={this.props.width}
            height="100%"
        // height={this.props.height}
        />;
    }

}

export default ThresholdChart;
