import React from "react";
import { Modal, Form } from "react-bootstrap";
import groupService from '../duck/groupService';
import localStorageService from '../../common/duck/localStorageService';
import Joi from "joi-browser";
import CForm from '../../common/form';
import Message from "../../common/messages";
import { ROLES } from '../../common/duck/constants';


class CreateGroup extends CForm {

  state = {
    data: {
      name: "",
      invitedToName: "",
      invitedToEmail: "",
      userId: "",
      password: "",
      invitedBy: localStorageService.getUserId(),
      type: this.props.label.toLowerCase(),
      farm: null,
    },
    errors: {}
  }

  schema = {
    name: Joi.string().required().label(`${this.props.label} Name`),
    invitedToName: Joi.string().required().label(`${this.props.label} Administrator Name`),
    invitedToEmail: Joi.string().email().required().label(`${this.props.label} Administrator Email`),
    userId: Joi.string().required(),
    password: Joi.string().required(),
    invitedBy: Joi.string().required(),
    type: Joi.string().required(),
    farm: Joi.string().allow(null),
    parentId: Joi.string().allow(null).allow(''),
  }

  doSubmit = async () => {
    try {
      const resq = await groupService.inviteNewAdminMember(this.state.data);
      this.props.openDoneModal({
        title: `Invite Email Sent`,
        message: `We’ve sent the invite email to ${resq.invitedToEmail}. The recipient can create the new ${resq.type} profile and invite other members of their group.`
      });
      return null;
    } catch (error) {
      this.setState({ errors: { ...this.state.errors, serviceError: error } })
      return error.response;
    }
  }

  componentDidMount() {
    const dealerList = (this.props?.accountsData?.dealer || []).map(e => ({ _id: e.objectId, ...e }));
    dealerList.unshift({ _id: "", name: "Please choose an option" });
    this.setState({ dealerList });
  }


  render() {
    return (
      <Modal
        className="create-invitation-modal"
        show={this.props.show}
        onHide={this.props.onClose}
        size="lg"
        centered>
        <Modal.Header closeButton={false}>
          <p>Create a New {this.props.label}</p>
          <div className="d-flex justify-content-end ">
            <span
              className="pr-20 cp"
              onClick={this.props.onClickBack}>Back</span>
            <span
              className=" cp"
              onClick={this.props.onClose}>Close</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-para">
            <p>Enter a few initial pieces of basic information and the administrator contact for this new {this.props.label.toLowerCase()}. They will receive an email to finish creating their {this.props.label.toLowerCase()} profile and invite other administrators or users.</p>
          </div>
          <Form>
            {this.renderFormGroup('name', `${this.props.label} Name`, 'text', 'Iron & Wine Distribution Partners, Ltd.')}
            <div className="row mt-25">
              <div className="col-sm-6">
                {this.renderFormGroup('invitedToName', `${this.props.label} Administrator Name`, 'text', 'Jeffrey Smith')}
              </div>
              <div className="col-sm-6">
                {this.renderFormGroup('invitedToEmail', `${this.props.label} Administrator Email`, 'email', 'jsmith@ironandwine.com')}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {this.renderFormGroup('userId', `${this.props.label} UserID`, 'text', 'Jeffrey Smith')}
              </div>
              <div className="col-sm-6">
                {this.renderFormGroup('password', `${this.props.label} Password`, 'password', 'somepasswordText')}
              </div>
            </div>
            { this.props.role === ROLES.GROGURU_ADMIN && this.state.data.type === "business" ? (
              <div className="row">
                <div className="col-sm-12">
                  {this.renderSelect('parentId', 'Dealer', this.state.dealerList)}
                </div>
              </div>) : null
            }
            {this.renderButton('Send Invite Email', 'mt-15 btn-submit btn')}
            {(this.state.errors.serviceError && this.state.errors.serviceError.response.data.message !== "UserId already exists, kindly enter a new userId!") ? <Message variant="error" message="Cannot Send Data" /> : null}
          </Form>


        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateGroup;
