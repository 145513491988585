import React, { Component } from "react";
import { Table, ButtonToolbar, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// import GroupEmptyCase from "./groupEmptyCase";
import InviteMember from "./inviteMember";
import TableHead from '../common/tableHead';
import InvitationModal from "./InvitationModal/InvitationModal";

function TableItem({ profileDetails, name, hqAddress, phone, email, image, type, uid, enableEdit, enableMemberInvite, enableViewTeam = true }) {
  const editDetailsRoute = {
    pathname: "/dashboard/groups/edit",
    state: {
      data: {
        name,
        hqAddress,
        phone,
        businessEmail: email,
        image,
        type,
      },
      memberData:{
        name,
        hqAddress,
        phone,
        businessEmail: email,
        image,
        type,
      },
      uid,
      type
    }
  };

  return (
    <tr>
      <td>{name}</td>
      <td className="">{hqAddress}</td>
      <td>{phone}</td>
      <td className="td-width">
        <ButtonToolbar className="d-flex ">
          {(profileDetails?.profile.role.name !== 'dealer_user' &&
            profileDetails?.profile.role.name !== 'business_user' &&
            profileDetails?.profile.role.name !== 'farm_user' && enableEdit) ?
            (<Button
              as={Link}
              to={editDetailsRoute}
              variant="outline-primary edit"
            >
              Edit
            </Button>) : null}

          {enableViewTeam && <Button
            as={Link}
            to={`/dashboard/groups/${uid}`}
            variant="outline-primary v-teams"
          >
            View Groups
          </Button>}
          {enableMemberInvite && (
            <InviteMember
              teamid={uid}
              name={name}
              type={type}
            />
          )}
        </ButtonToolbar>
      </td>
    </tr>
  );
}

class GroupTable extends Component {

  renderItems() {
    console.log("inside renderItems");
    return this.props.data.map((item) => (
      <TableItem
        key={item.objectId}
        uid={item.objectId}
        name={item.name}
        hqAddress={item.hqAddress}
        phone={item.phone}
        email={item.businessEmail}
        image={item.image}
        type={this.props.label.toLowerCase()}
        enableEdit={this.props.enableEdit}
        enableViewTeam={this.props.enableViewTeam}
        enableMemberInvite={this.props.enableMemberInvite}
      />
    ));
  }

  renderMemberItems() {
    console.log("memememmemberData::: ",this.props.memberData);
    return this.props.memberData.map((item) => (
      <TableItem
        key={item.objectId}
        uid={item.objectId}
        name={item.name}
        hqAddress={item.hqAddress}
        phone={item.phone}
        email={item.businessEmail}
        image={item.image}
        type={this.props.label.toLowerCase()}
        enableEdit={this.props.enableEdit}
        enableViewTeam={this.props.enableViewTeam}
        enableMemberInvite={this.props.enableMemberInvite}
      />
    ));
  }


  tableHeads = [`${this.props.label} Name`, 'Address', 'Phone', 'Actions'].map(i => ({ text: i }))

  renderSection() {
    console.log("this.props.memberData:::: ",this.props.data, this.props.memberData, this.props.label);
      return (
        <React.Fragment>
          <div className="section-item d-flex justify-content-between">
            <p>{this.props.label}</p>
            {this.props.label !== "Groguru" &&
              this.props.enableGroupInvite && <InvitationModal label={this.props.label} accountsData={this.props.accountsData}/>}
          </div>
          {(this.props.data && this.props.data.length > 0 && this.props.label !== 'Member') ?
          (<Table striped bordered hover responsive>
            <TableHead heads={this.tableHeads} />
            <tbody>{this.renderItems()}</tbody>
          </Table>) : (this.props.memberData && this.props.label === 'Member') ? (<Table striped bordered hover responsive>
            <TableHead heads={this.tableHeads} />
            <tbody>{this.renderMemberItems()}</tbody>
          </Table>) : null}
          </React.Fragment>

      );
  }

  render() {
    return <div>{this.renderSection()}</div>;
  }
}

const mapStateToProps = state => ({
  userID: state.userDetails.profileDetails.profile.objectId,
  profileDetails: state.userDetails.profileDetails,
});

export default connect(mapStateToProps)(GroupTable);
