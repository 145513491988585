import React from 'react';
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";


export default function ShopItemCard({ productId, title, image, price, refLink, className='col-lg-3 col-md-6 col-12' }) {
    const productDetails = {
        pathname:`/dashboard/shop/product/${productId}`,
        state: {refLink}
    }
    return (
        <div className={className}>
            <Card className="mt-20">
                <Card.Body>
                    <Card.Img variant="top" src={image} />
                    <Card.Title>{title}</Card.Title>
                    <Card.Text className="text-center">{price}</Card.Text>
                    <Button as={Link} to={productDetails} variant="none" className="detail-btn">Details</Button>
                </Card.Body>
            </Card>
        </div>
    );
}