export const MEASURE_TYPE = {
    AW: 'Summary',
    MOIST: 'Moisture',
    GDD: 'GDD Envelope',
    TEMP: 'Temperature',
    PRE: 'Pressure',
    SAL: 'Salinity',
    EC: 'EC',
    BT: 'Battery',
    RSSI: 'RSSI',
    RAIN: 'Rainfall',
    IRR: 'Irrigation',
    VIC: 'VIC',
    SYS: 'System',
    PRED: 'Predicted',
    PRED_R: 'Rainfall',
}

export const MEASURE_SHORT_NAMES = Object.entries(MEASURE_TYPE)
    .reduce((acc, [shortName, label]) => {
        acc[label] = shortName;
        return acc;
    }, {});

export const MEASURE_ALIAS = new Proxy({
    volwatcont: 'moisture',
    moict_c1: 'moisture',
    moist_c2: 'moisture',
    soil_temperature: 'temp_f',
    temp_c: 'temp_f',
    conductivity: 'ec',
    vic: 'vic',
    pressure: 'pressure',
    gddEnvelope: 'gddEnvelope',
    v_bandgap: 'battery',
}, { get: (aliasDict, measure) => aliasDict[measure] || measure });

export const MEASURE_NAME = new Proxy({
    aw: MEASURE_TYPE.AW,
    pressure: MEASURE_TYPE.PRE,
    moisture: MEASURE_TYPE.MOIST,
    salinity: MEASURE_TYPE.SAL,
    temp_f: MEASURE_TYPE.TEMP,
    ec: MEASURE_TYPE.EC,
    vic: MEASURE_TYPE.VIC,
    gddEnvelope: MEASURE_TYPE.GDD,
    system: MEASURE_TYPE.SYS,
    battery: MEASURE_TYPE.BT,
    rssi: MEASURE_TYPE.RSSI,
}, { get: (nameDict, measure) => nameDict[MEASURE_ALIAS[measure]] });

export const DATA_TYPES_KEYS = {
    Raw: '0',
    SMA5: '5',
    SMA10: '10',
    SMA20: '20'
}

export const DATA_TYPES = {
    [DATA_TYPES_KEYS.Raw]: 'Raw',
    [DATA_TYPES_KEYS.SMA5]: 'SMA5',
    [DATA_TYPES_KEYS.SMA10]: 'SMA10',
    [DATA_TYPES_KEYS.SMA20]: 'SMA20'
}

export const CHART_NAME = 'template-plots';

export const TIME_PERIODS = {
    ONE_DAY: 'Day',
    ONE_WEEK: 'Week',
    ONE_MONTH: 'Month',
    THREE_MONTHS: '3 Months',
    SIX_MONTHS: '6 Months',
    ONE_YEAR: 'Year'
}