import React from 'react';
import { Spinner } from 'react-bootstrap';

function GraphLoader({ graphIsLoading, templateIsLoading }) {

    let MESSAGE;
    if (graphIsLoading && templateIsLoading) {
        MESSAGE = 'Loading graph & template data ...';
    } else if (graphIsLoading) {
        MESSAGE = 'Loading graph data ...'
    } else if (templateIsLoading) {
        MESSAGE = 'Loading template data ...';
    }

    const loaderWrapperStyle = {
        position: 'fixed',
        zIndex: 20,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '5rem',
        minWidth: '5rem',
        padding: '1rem 2rem',
        border: 'solid 1px #000',
        borderRadius: '0.5rem',
        background: '#eee',
        top: '40%',
        left: '30%'

    }

    return (graphIsLoading || templateIsLoading) && (
        <div style={loaderWrapperStyle}>
            <Spinner size="lg" animation="grow" />
            <small>{MESSAGE}</small>
        </div>
    )
}

export default React.memo(GraphLoader);