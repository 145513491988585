import React from "react";
import { Form } from "react-bootstrap";

function FormGroup({ name, label, error, userIdStatus, userEmailStatus, ...rest }) {
    return (
        <Form.Group>
            <Form.Label> {label}</Form.Label>
            <Form.Control autoComplete="new-password" name={name} {...rest} required />
            { (name === 'userId' && !error && userIdStatus && userIdStatus !== {}) ? <span className="error">{userIdStatus.message}</span> : null}
            { (name === 'invitedToEmail' && !error && userEmailStatus && userEmailStatus !== {}) ? <span className="error">{userEmailStatus.message}</span> : null}
            { error && <span className="error">{error}</span>}
        </Form.Group>
    );
}

export default FormGroup;
