import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';
import cornerIcon from "../../assets/images/corner-arrow/rectangle.png";
import farmDefaultImage from "../../assets/images/farms/farmdefaultimg.png";
import Search from "../common/search";
import Message from "../common/messages";
import SiteEmptyCase from "../sites/sidemenu/siteEmptyCase";
import farmService from "../farm/duck/farmService";
import './farmhealth.scss';

function FarmsSelectionList(props) {
    let requestObj = farmService.getFarmsForUserRequest();
    let apiCall = null;
    const [farmDataLength, setFarmDataLength] = useState(null);
    const [selectBoolean, setSelectBoolean] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedfarms, setSelectedfarms] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [respMsg, setRespMsg] = useState(null);
    const [dataIsLoading, setDataIsLoading] = useState(false);

    useEffect(() => {
        if (props.mode === 'add') {
            setDataIsLoading(true);
            farmService.getFarmsForUserRequest().request(null, null, "false").then((farms) => {
                const farmlength = farms.length;
                setFarmDataLength(farmlength);
                setData(farms.map(({ name, physicalAddress, objectId, image }) => ({
                    name,
                    physicalAddress,
                    objectId,
                    image
                })))
                setDataIsLoading(false);
            });
        } else if (props.mode === 'view') {
            setDataIsLoading(true);
            farmService.getFarmsForUserRequest().request(null, null, "false").then((farms) => {
                const userFarms = farms.filter(f => props.currentUser?.farms.indexOf(f.objectId) !== -1);
                const farmlength = userFarms.length;
                setFarmDataLength(farmlength);

                setData(userFarms.map(({ name, physicalAddress, objectId, image }) => ({
                    name,
                    physicalAddress,
                    objectId,
                    image
                })));
                setDataIsLoading(false);
            });
        } else if (props.mode === 'edit') {
            setDataIsLoading(false);
            farmService.getFarmsForUserRequest().request(null, null, "false").then((farms) => {
                const farmlength = farms.length;
                setFarmDataLength(farmlength);
                setData(farms.map(({ name, physicalAddress, objectId, image }) => ({
                    name,
                    physicalAddress,
                    objectId,
                    image
                })));

                const userfarms = props.currentUser?.farms;
                setSelectedfarms(userfarms);
                setUserEmail(props.currentUser?.email);
                setDataIsLoading(false);
            });
        }

    }, [props.mode, props.currentUser]);


    const onSearch = (e) => {
        let trimmedstr = e.target.value;
        let searchQuery = trimmedstr.replace(/\s+$/, '');
        let selectBoolean = true;

        setSelectBoolean(true);
        setSearch(searchQuery);
        if (apiCall !== null && requestObj) {
            requestObj.cancel("SEARCH_SKIPPED");
            clearTimeout(apiCall);
            apiCall = null;
            requestObj = null;
        }
        requestObj = farmService.getFarmsForUserRequest();
        if (props.mode !== 'view') {
            apiCall = setTimeout(() => {
                requestObj
                    .request(searchQuery, null, "false")
                    .then((data) => {
                        selectBoolean = search.length === 0 ? false : true;
                        setSelectBoolean(selectBoolean);
                        setData(data);
                    })
                    .catch((error) => !error.isCancelled && setData([]));
            }, 500);
        } else {
            apiCall = setTimeout(() => {
                requestObj
                    .request(searchQuery, null, "false")
                    .then((data) => {
                        selectBoolean = search.length === 0 ? false : true;
                        setSelectBoolean(selectBoolean);
                        const userData = data.filter(f => props.currentUser?.farms.indexOf(f.objectId) !== -1);
                        setData(userData);
                    })
                    .catch((error) => !error.isCancelled && setData([]));
            }, 500);
        }


    };

    const modal = () => {
        setSearch('');
        props.setShow(false);
        setData(data);
        setSelectBoolean(false);
        setSelectedfarms([]);
        setSelectAll(false);
    }

    const handleChange = (e) => {
        if (e.target.value === "selectAll") {
            setSelectAll(!selectAll);
            if (!selectAll) {
                let selectedfarms = [];
                data.map(({ objectId, name }) => {
                    return selectedfarms.push(objectId);
                });
                setSelectedfarms(selectedfarms);
            } else {
                setSelectedfarms([]);
            }
        } else {
            if (selectedfarms?.some(farm => farm === e.target.value)) {
                const selectedfarms1 = selectedfarms.filter(
                    (farms) => farms !== e.target.value
                );
                setSelectedfarms(selectedfarms1);
                setSelectAll(false);

            } else {
                let selectedfarmsList = selectedfarms && [...selectedfarms];
                selectedfarmsList && selectedfarmsList.push(e.target.value);
                setSelectedfarms(selectedfarmsList);
            }
        }
    };

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    }

    const closeSaveModal = () => {
        modal();
        props.setUser();
    }

    const saveUserFarms = () => {

        if (userEmail && selectedfarms?.length > 0) {
            if (props.mode === 'add') {
                const newUser = {
                    "email": userEmail,
                    "farms": selectedfarms,
                };
                farmService.saveUserFarm(newUser)
                    .then((response) => {
                        props.updateUsers(response);
                        modal();
                    })
                    .catch((error) => {
                        setRespMsg(error.message);
                        setTimeout(() => {
                            setRespMsg(null);
                        }, 1000);
                    });
            } else if (props.mode === 'edit') {
                const farmsList = {
                    farms: selectedfarms
                };
                farmService.editUser(props.currentUser?.objectId, farmsList)
                    .then((response) => {
                        props.getUsers();
                        modal();
                    })
                    .catch((error) => {
                        setRespMsg(error.message);
                        setTimeout(() => {
                            setRespMsg(null);
                        }, 1000);
                    });
            }
        }


    }

    return (
        <Modal
            className="select-farm farm-health-modal"
            show={props.show}
            onHide={() => modal()}
            size="lg"
            centered
        >
            <Modal.Header
                closeButton={false}
                className="d-flex justify-content-between"
            >
                {props.mode !== 'view' ? <p>Select Farms </p> : <p>View Farms </p>}

                <div className="d-flex header-btn">
                    <p className="text-right cp"
                        onClick={() => closeSaveModal()}>
                        {" "}
          Close
        </p>
                    {props.mode !== 'view' && <p className="save-text"
                        onClick={() => saveUserFarms()}>
                        {" "}
          Save
        </p>}
                </div>
            </Modal.Header>
            <Modal.Body>
                {(dataIsLoading) && (
                    <div className="loaderWrapperStyle">
                        <Spinner size="lg" animation="grow" />
                        <small>
                            Loading Data...</small>
                    </div>)
                }
                <div className="messagePopup">
                    {respMsg !== null && respMsg?.length !== 0 && <Message variant={"error"} message={respMsg} />}
                </div>
                <h6>Email</h6>
                <p className="pr-20">
                    {props.currentUser ? <input className="email-field" readOnly value={props.currentUser?.email} />
                        : <input
                            className="email-field"
                            onChange={handleEmailChange}
                            placeholder="Enter email id" />}
                </p>
                <div className="select-container">
                    <div className="d-flex justify-content-between ">
                        <h6>
                            Sort By:{" "}
                            <span>
                                Alphabetical <img src={cornerIcon} alt="corner-icon"></img>
                            </span>
                        </h6>
                        {props.mode !== 'view' && !selectBoolean && <div className="d-flex pr-6">
                            <h6 className="pr-15">Select All</h6>
                            <label className="label ">
                                <input
                                    type="checkbox"
                                    checked={selectedfarms?.length === farmDataLength}
                                    name="radio"
                                    value="selectAll"
                                    onChange={handleChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>}
                    </div>
                    <div className="select-farm-search">
                        {" "}
                        <Search
                            placeholder="Search Farms and Site IDs ..."
                            onSearch={onSearch}
                        />{" "}
                    </div>
                    <div className="content-area">
                        {data !== [] && data.length !== 0 ? (
                            data.map((farm) => (

                                <div key={farm?.objectId} className="d-flex  justify-content-between">
                                    <div className="profile-area d-flex">
                                        <div className="image">
                                            <img src={farm?.image || farmDefaultImage} alt="user profile"></img>
                                        </div>
                                        <div className="text-section">
                                            <h1>{farm?.name}</h1>
                                            <p>{farm?.physicalAddress} </p>
                                        </div>
                                    </div>
                                    {props.mode !== 'view' && <div className="modalCheckbox">
                                        <label className="label mt-10">
                                            <input
                                                type="checkbox"
                                                className="fixedModalCheckBox"
                                                checked={
                                                    selectedfarms?.indexOf(farm?.objectId) !== -1
                                                }
                                                name={farm?.name}
                                                value={farm?.objectId}
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>}
                                </div>
                            ))
                        ) : (
                            <SiteEmptyCase search={search} />
                        )}
                    </div>

                </div>
            </Modal.Body>
        </Modal>)

}
export default FarmsSelectionList;