
import http from "../../common/duck/httpService";
import logger from "../../common/duck/logService";
import localStorageService from '../../common/duck/localStorageService';

const orgAPI = '/org';
const userAPI = '/user';


const { CancelAxiosToken, getDataOrError } = http;


function getGroupsData() {
    const source = CancelAxiosToken.source();
    function request(params = {}, userid = localStorageService.getUserId()) {
        const apiEndPoint = `${userAPI}/${userid}/organisations`;
        return http.get(apiEndPoint, {
            params,
            cancelToken: source.token
        }).then(response => {
            const { parent, groguru, distributor, business, dealer, farm } = response.data.data;
            return { parent, groguru, distributor, business, dealer, farm };
        }).catch(error => {
            if (http.isCancel(error)) {
                return Promise.reject({ isCancelled: true });
            }
            logger.exception(error);
            return Promise.reject(error);
        })
    };
    return { request, cancel: source.cancel }
}

export function getParent(userid = localStorageService.getUserId()) {
    return http.get(`${userAPI}/${userid}/organisations`)
        .then(response => {
            const parent = response.data.data.parent;
            return parent && parent[0]
        })
}

function editDetails(orgid, payload) {


    const apiEndPoint = `${orgAPI}/${orgid}`;
    return http.patch(apiEndPoint, payload)
        .then(response => getDataOrError(response));
}

function inviteMember(teamid, type, memberDetails) {
    const apiEndPoint = `${userAPI}/invite/${type}/${teamid}`;
    return http.post(apiEndPoint, memberDetails)
        .then(response => getDataOrError(response));
}

function inviteNewAdminMember(payload) {
    return http.post(orgAPI, payload)
        .then(response => getDataOrError(response));
}

function getOwnGroup() {
    return http.get(`${userAPI}?content=team`)
        .then(response => getDataOrError(response))
}

function postImage(imageData) {

    const imageFormData = new FormData();
    imageFormData.append('file', imageData);


    return http.post('/file/image', imageFormData)
        .then(response => getDataOrError(response));
}

function getGroupMembers(groupid) {
    return http.get(`${orgAPI}/${groupid}/users`)
        .then(response => getDataOrError(response));
}

function memberRoleChange(orgId, userId, role) {
    return http.patch(`${orgAPI}/${orgId}/users/${userId}`, { roleName: role })
        .then(response => response.data);
}

function removeGroupMember(orgId, userId) {
    return http.delete(`${orgAPI}/${orgId}/users/${userId}`)
        .then(response => response.data);
}

function getAllUsersInTreePath() {
    return http.get('/user/group/users').then(response => response.data.data);
}

function getAllMembers(){

    const source = CancelAxiosToken.source();
    function request(params = {}, userid = localStorageService.getUserId()) {
        const apiEndPoint = `${userAPI}/myteam`;
        return http.get(apiEndPoint, {
            params,
            cancelToken: source.token
        }).then(response => {
            const { invited, members } = response.data.data;
            return { invited, members };
        }).catch(error => {
            if (http.isCancel(error)) {
                return Promise.reject({ isCancelled: true });
            }
            logger.exception(error);
            return Promise.reject(error);
        })
    };
    return { request, cancel: source.cancel }
}
export default {
    getGroupsData,
    editDetails,
    inviteMember,
    inviteNewAdminMember,
    getOwnGroup,
    postImage,
    getGroupMembers,
    memberRoleChange,
    removeGroupMember,
    getParent,
    getAllUsersInTreePath,
    getAllMembers
}