import React from "react";
import PropTypes from 'prop-types';
import StandardEmptyCase from '../common/standardEmptyCase';

function FarmEmptyCase({search}) {
    return <StandardEmptyCase
        mainText={`No results for “${search}”`}
        subText="We didn’t find any farms listed for that query." />;
}


FarmEmptyCase.propTypes = {
    search: PropTypes.string
}

export default FarmEmptyCase;
