import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import groupService from './duck/groupService';
import { connect } from 'react-redux';
import Joi from 'joi-browser';
import InviteDone from './InvitationModal/inviteDone';
import CForm from '../common/form';
import Message from '../common/messages';

class InviteMember extends CForm {

  state = {
    show: (this.props.modalShown && 'form') || '',
    data: { email: '', type: this.props.roleTypeValue || 'user' },
    errors: {}
  }

  schema = {
    email: Joi.string().email().required().label('New Member Email'),
    type: Joi.string().valid('user', 'admin').required(),
    name: Joi.string().required(),
    userId: Joi.string().required(),
    password: Joi.string().required(),
  }

  closeAll = () => this.setState({ show: '' }, () => {
    this.props.onClose && this.props.onClose();
    this.setState({ data: { email: '', type: this.props.roleTypeValue || 'user' } })
  })
  openForm = () => this.setState({ show: 'form' })
  openDone = () => this.setState({ show: 'done' })

  doSubmit = () => {
    const { multi } = this.props;
    let teamid;
    if (multi && multi.length === 1) {
      teamid = multi[0];
    } else {
      teamid = this.props.teamid
    }
    groupService.inviteMember(teamid, this.props.type, { users: [this.state.data], user: this.props.userID })
      .then(data => {
        this.openDone();
      })
      .catch(error => this.setState({ errors: { serviceErrors: (error.response && error.response.data.message) || 'NetworkError' } }));
  }

  componentDidUpdate() {
    if (this.props.modalShown !== undefined) {
      if (this.props.modalShown && this.state.show === '') {
        this.setState({ show: 'form' });
      } else if (!this.props.modalShown && this.state.show !== '') {
        this.setState({ show: '' });
      }
    }
  }

  render() {

    const roleDisplay = {
      user: 'a user',
      admin: 'an admin'
    };

    const doneModalData = {
      title: 'Invite Email Sent',
      message: `We've sent the invite email to ${this.state.data.email}. Once the recipient finishes their profile they will be added as ${roleDisplay[this.state.data.type]} to the ${this.props.name} group.`
    };

    const formOptions = [{ _id: 'user', name: 'User' }, { _id: 'admin', name: 'Admin' }];
    return (
      <div className="user-invitation">
        {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
          this.props.profileDetails.profile.role.name !== 'business_user' &&
          this.props.profileDetails.profile.role.name !== 'farm_user' && !this.props.hideDefaultButton) ?
          (<Button
            onClick={this.openForm}
            variant="outline-primary invite"
            className="text-right">Invite</Button>)
          : (this.props.profileDetails.profile.role.name === 'dealer_admin') ? (<Button
            onClick={this.openForm}
            variant="outline-primary invite"
            className="text-right">Create Member +</Button>) : null}
        {this.state.show === 'form' && <Modal
          className="invite-member"
          show={this.state.show === 'form'}
          onHide={this.closeAll}
          size="lg"
          centered>
          <Modal.Header closeButton={false}>
            <p>Invite a New Member </p>
            <span
              className="text-right cp"
              onClick={this.closeAll}>close</span>
          </Modal.Header>
          <Modal.Body>
            <div className="details">
              <p>
                Invite a new member to {this.props.name}. Enter their
                email and choose which level of permissions they receive.
                Administrators can invite and/or remove all members on their own
                group and any subsequent lower groups while users cannot. The new
                member will receive an email allowing them to complete their
                profile.
              </p>
            </div>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  {this.renderFormGroup('email', 'New Member Email', 'email', { placeholder: 'sallysmith@agdealers.com' })}
                </div>
                <div className="col-md-6">
                  {this.renderSelect('type', 'Member Level', formOptions, { value: this.props.roleTypeValue })}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderFormGroup('name', `New Member Name`, 'name', 'someName')}
                </div>
                <div className="col-sm-6">
                  {this.renderFormGroup('userId', `New Member UserID`, 'text', 'Jeffrey Smith')}
                </div>
                <div className="col-sm-6">
                  {this.renderFormGroup('password', `New Member Password`, 'password', 'somepasswordText')}
                </div>
              </div>
              {this.renderButton('Send Invite Email', 'btn-submit btn btn-none')}
              {this.state.errors.serviceErrors && <Message variant="error" message={this.state.errors.serviceErrors} />}
            </Form>
          </Modal.Body>
        </Modal>}
        {this.state.show === 'done' && <InviteDone
          show={this.state.show === 'done'}
          data={doneModalData}
          onClose={this.closeAll} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userID: state.userDetails.profileDetails.profile.objectId,
  profileDetails: state.userDetails.profileDetails,
});

export default connect(mapStateToProps)(InviteMember);
