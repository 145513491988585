import React from "react";
import defaultFarmIcon from "../../assets/images/emptycase_icon/bitmap.png";
import PropTypes from 'prop-types';

function StandardEmptyCase({mainText, subText}) {
    return (
        <div className="text-center empty-case mx-auto ">
            <div className="emptycase-icon mx-auto">
                <img src={defaultFarmIcon} alt="icon" />
            </div>
            <h3 className="mt-15 mb-0">{mainText}</h3>
            <p className="mt-10">{subText}</p>
        </div>
    );
}


StandardEmptyCase.propTypes = {
    mainText: PropTypes.string,
    subText: PropTypes.string
}

export default StandardEmptyCase;
