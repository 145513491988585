import React, { Component } from "react";
import configService from "./duck/configService";
import { Card, Form } from "react-bootstrap";
import Message from "../../../common/messages";
import { connect } from "react-redux";

class ForeCastDays extends Component {
  state = {
    disabled: true,
    predictedWaterUse: this.props?.hardwaredetails?.twigDetails?.settings?.predictedWaterUse || null,
    selectedDayOne: null,
    selectedDayTwo: null,
    selectedDayThree: null,
    errors: { serviceError: null },
    responseMsg: null,
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hardwaredetails !== prevProps.hardwaredetails) {
      this.setState({
        predictedWaterUse: this.props?.hardwaredetails?.twigDetails?.settings?.predictedWaterUse?.filter(data => data.display === true)
      }, () => {
        this.setState({ selectedDayOne: this.state.predictedWaterUse[0]?.forecastDay, selectedDayTwo: this.state.predictedWaterUse[1]?.forecastDay, selectedDayThree: this.state.predictedWaterUse[2]?.forecastDay })
      })
    }
  }


  onSubmit = (e) => {
    let responseMsg = 'Data updated successfully';
    const siteId = this.props.siteDetails?.siteId;
    const foreCastData = {
      "forecastDays": [this.state.selectedDayOne, this.state.selectedDayTwo, this.state.selectedDayThree]
    }
    const forecastDays = true;
    configService.updateForeCastdays(siteId, forecastDays, foreCastData).then(res => {
      this.setState({ responseMsg })
    }).catch(error => {
      this.setState({ errors: error.message });
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    let selected = e.target.name;

    if (selected === "selectedDay-1") {

      this.setState({ selectedDayOne: parseInt(value) })
    }

    else if (selected === "selectedDay-2") {

      this.setState({ selectedDayTwo: parseInt(value) })
    }
    else if (selected === "selectedDay-3") {

      this.setState({ selectedDayThree: parseInt(value) })
    }
  };
  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled, responseMsg: null
    });
    if (!this.state.disabled) {
      this.onSubmit();
    }
  };
  render() {    
    return (
      <div className="software-container">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5>Forecast Days</h5>

              <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button>
            </div>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="row">
                <div className="col-lg-3">
                  <Form.Group>
                    <div className="selectdiv">
                      <select name="selectedDay-1" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.selectedDayOne ? this.state.selectedDayOne : ''}
                        onChange={this.handleChange} disabled={this.state.disabled}>
                        {this.state.options.map(value => (
                          <option key={value} value={value}
                            disabled={this.state.selectedDayTwo === value || this.state.selectedDayThree === value}
                          >
                            {value}
                          </option>
                        ))}
                       </select>
                      <span className="fas fa-caret-down"> </span>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <Form.Group>
                    <div className="selectdiv">
                      <select name="selectedDay-2" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.selectedDayTwo ? this.state.selectedDayTwo : ''}
                        onChange={this.handleChange} disabled={this.state.disabled}>
                        {this.state.options.map(value => (
                          <option key={value} value={value}
                            disabled={this.state.selectedDayOne === value || this.state.selectedDayThree === value}
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="fas fa-caret-down"> </span>

                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <Form.Group>
                    <div className="selectdiv">
                      <select name="selectedDay-3" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.selectedDayThree ? this.state.selectedDayThree : ''}
                        onChange={this.handleChange} disabled={this.state.disabled}>
                        {this.state.options.map(value => (
                          <option key={value} value={value}
                            disabled={this.state.selectedDayTwo === value || this.state.selectedDayOne === value}
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="fas fa-caret-down"> </span>

                    </div>
                  </Form.Group>
                </div>
              </div>
            </form>
          </Card.Body>
          <div className="messagePopup">
            {this.state.responseMsg !== null && this.state.responseMsg?.length !== 0 && <Message variant="success" message={'Data updated successfully'} />}
          </div>
        </Card>
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(ForeCastDays);
