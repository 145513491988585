import React from "react";
import { Modal } from "react-bootstrap";
import successIcon from "../../../assets/images/successful_tick/group.png"

function InviteDone({show, onClose, data, buttonLabel = 'Done', isImageNotRequired }) {
  return (
    <Modal
      className="invite-email invite-done"
      show={show}
      onHide={onClose}
      size="lg"
      centered>
      <Modal.Body className="">
      {isImageNotRequired ? "" :
        <div className="account-img">
          <img src={ data.image || successIcon} alt="success-icon" />
        </div>
         }
        <h4 className="mx-auto mt-40">{data.title}</h4>
        <p className="">{data.message}</p>
        <button  className="btn-submit mt-25" onClick={onClose}>{buttonLabel}</button>
      </Modal.Body>
    </Modal>
  );
}

export default InviteDone;
