import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function SpinnerButton({children, spinning = false, disabled, ...props}) {
    return (
        <button type="button" disabled={spinning || disabled} {...props}>
            {spinning && <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />}
            {children}
        </button>
    )
}