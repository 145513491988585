import { getTimestamp } from './dateUtility';
import { orderByString, lastItem } from '../../../../common/duck/utils';
import cubicSplineInterpolation from 'cubic-spline';


export function getPoints(collection) {
    return collection && collection.reduce((acc, dp) => {
        acc.push({ x: getTimestamp(dp["xValue"]), y: dp["yValue"] });
        return acc;
    }, []);
}
export function getPointsByDepth(collection, depth) {
    return collection.reduce((acc, dp) => {
        if (typeof (dp[depth]) === 'number') {
            acc.push({ x: getTimestamp(dp["xValue"]), y: dp[depth] });
        }
        return acc;
    }, []);
}

function measurementUnitsAndLabels(data, type) {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, {
        unit: (type && type === 'absolute' && value.label === 'Available Water') ? 'inches' : value.Unit,
        label: value.label
    }]));
}

function calculateDepth(hardwareSensorLocation, keyPrefix = '') {
    if (!hardwareSensorLocation) {
        return {};
    } else if (hardwareSensorLocation.sensorType === 'groguru') {
        const referenceDepth = hardwareSensorLocation.value.referenceDepth;
        const sensorsOrderedByKey = orderByString(Object.entries(hardwareSensorLocation.value.sensors[0]));
        return Object.fromEntries(sensorsOrderedByKey.map(([key, sensor], i) => [`${keyPrefix}${key}`, {
            key: `${keyPrefix}${key}`,
            name: sensor.name,
            depth: referenceDepth[i] + sensor.depthOffset
        }]));
    } else if (hardwareSensorLocation.sensorType === 'aquacheck' || hardwareSensorLocation.sensorType === 'sentek-api' || hardwareSensorLocation.sensorType === 'zenseio-api' || hardwareSensorLocation.sensorType === 'agsense-api') {
        const referenceDepth = hardwareSensorLocation.value.referenceDepth[0];
        const sensorsOrderedByKey = orderByString(Object.entries(hardwareSensorLocation.value.sensors[0]));
        return Object.fromEntries(sensorsOrderedByKey.map(([key, sensor]) => [`${keyPrefix}${key}`, {
            key: `${keyPrefix}${key}`,
            name: sensor.name,
            depth: referenceDepth + sensor.depthOffset
        }]));
    }
}

function two(currentInput) {
    return function (array) {
        if (array.includes(currentInput)) {
            return array.filter(item => item !== currentInput);
        }
        if (array.length > 1) {
            return array.slice(1).concat(currentInput);
        }
        return array.concat(currentInput);
    }
}

function multiple(currentInput) {
    return function (array) {
        if (array.includes(currentInput)) {
            return array.filter(item => item !== currentInput);
        }
        return array.concat(currentInput);
    }
}

export function hasNoDepthVariants(dataPoints) {
    return dataPoints.length === 0 || (
        dataPoints?.[0] &&
        (typeof dataPoints[0].yValue === 'number')
    );
}

export function closestLow(collection, comparisonInt) {
    return collection.find(
        (point, i) => point.x <= comparisonInt && (collection?.[i + 1]?.x > comparisonInt || !collection[i + 1])
    );
}

export function closestHigh(collection, comparisonInt) {
    return collection.find(point => point.x >= comparisonInt);
}

export function getMinMaxPoints(collection, accumulator = [], valueKey = 'y') {
    if (collection.length === 0) {
        return accumulator;
    }

    let minPoint = { [valueKey]: Infinity };
    let maxPoint = { [valueKey]: -Infinity };
    for (let point of collection) {
        if (point[valueKey] < minPoint[valueKey]) {
            minPoint = point;
        } else if (point[valueKey] > maxPoint[valueKey]) {
            maxPoint = point;
        }
    }

    return [...accumulator, minPoint, maxPoint];
}

export function getDatapointsMinMax(collection = [], accumulator = { min: Infinity, max: -Infinity }, valueKey = 'y') {
    if (collection.length === 0) {
        return accumulator;
    }

    let min = accumulator.min;
    let max = accumulator.max;
    for (let point of collection) {
        const value = point[valueKey];
        if (value < min) {
            min = value;
        }
        if (value > max) {
            max = value;
        }
    }

    return { min, max };
}

export function nearestUpper(n, scale = 10) {
    return n + (scale - n % scale)
}

export function nearestLower(n, scale = 10) {
    return n - (n % scale);
}

function between(collection, from, to) {
    return collection.filter(
        point => {
            let x = Date.parse(point.xValue);
            return x >= from && x <= to
        }
    )
}

export function sliceTimeseries(graph, dateParams) {
    let data = {};
    const fromDate = Date.parse(dateParams.fromDate);
    const toDate = Date.parse(dateParams.toDate);
    for (let measure in graph) {
        data[measure] = {
            absolute: between(graph[measure].absolute, fromDate, toDate),
            relative: between(graph[measure].relative, fromDate, toDate)
        };
    }
    return data;
}

export function templateForDateRange(templateDataPointsSorted, startDateMS, endDateMS) {
    if (templateDataPointsSorted.length === 0) {
        return []
    }

    if (startDateMS === undefined) {
        return templateDataPointsSorted;
    }

    const first = templateDataPointsSorted[0];
    const last = lastItem(templateDataPointsSorted);

    const dataPoints = templateDataPointsSorted.filter(
        point => point.x >= startDateMS
    );

    const interpolatedDatapoints = new cubicSplineInterpolation(...templateDataPointsSorted.reduce((acc, point) => {
        acc[0].push(point.x)
        acc[1].push(point.y)
        return acc;
    }, [[], []]))

    if (!dataPoints.find(point => point.x === startDateMS)) {
        const rangeCompletelyContained = first.x < startDateMS && last.x > startDateMS;
        if (rangeCompletelyContained) {
            dataPoints.unshift({ x: startDateMS, y: interpolatedDatapoints.at(startDateMS) });
        } else if (last.x < startDateMS) {
            // template ended before daterange
            dataPoints.unshift({ x: startDateMS, y: last.y });
        } else {
            dataPoints.unshift({ x: startDateMS, y: first.y });
        }
    }

    if (lastItem(dataPoints)?.x < endDateMS) {
        const rangeCompletelyContained = first.x < endDateMS && last.x > endDateMS;
        if (rangeCompletelyContained) {
            dataPoints.push({ x: endDateMS, y: interpolatedDatapoints.at(endDateMS) });
        } else if (first.x > endDateMS) {
            // template starts after daterange
            dataPoints.push({ x: endDateMS, y: first.y });
        } else {
            dataPoints.push({ x: endDateMS, y: last.y });
        }
    }

    return dataPoints;
}

export const allow = {
    two,
    multiple
}

export const getMapping = {
    measurementUnitsAndLabels,
    calculateDepth
}