import React from "react";

export default function SitePending(props) {
    const { name, siteId,status } = props.data;

    
    return (
        <div>
        {status==='PENDING' &&

        <div className="sitepending-container">

            <div className="d-flex justify-content-between">
                <div className="site-name">
                    <h6>Status: Pending Installation</h6>
                    <h3>{name}</h3>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-15">
                <div className="sensor-name">
                    <h6>Hardware Type</h6>
                    <h3>{props?.data?.twigDetails?.settings?.hardwareType?.value}</h3>
                </div>
                <div className="sensor-name">
                    <h6>Site ID #</h6>
                    <h3>{siteId}</h3>
                </div>
            </div>
            <div className=" d-flex mt-20 justify-content-between">
             <div className="sensor-id">
                    <h6>Fox Sensor</h6>
                    <h3>{props?.data?.twigDetails?.settings?.localSensor?.value!==null?props?.data?.twigDetails?.settings?.localSensor?.value:"No Fox Sensor"}</h3>
                </div>
                 <div className="sensor-id">
                    <h6>Install By</h6>
                    <h3>{props?.data?.twigDetails?.settings?.installByDate?.value}</h3>
                </div>
                 <div className="sensor-id">
                    <h6>Planting Date</h6>
                    <h3>{props?.data?.twigDetails?.settings?.plantingDate?.value}</h3>
                </div>
            </div>
        </div>}
        </div>
    );
}


