import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import SearchFilter from './searchFilter';
import subscriptionService from './subscriptions/duck/subscriptionService';
import { connect } from "react-redux";


function DealerSummary(props) {

  const [dealerData, setDealerData] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    subscriptionService.getDealerSummary()
      .then(data => {
        data.sort((a, b) => {
          let x = a.dealerName.toLowerCase();
          let y = b.dealerName.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        })
        setDealerData(data);
      })
      .catch(err => console.log(err));
  }, []);

  const onSearch = (e) => {
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    setSearch(searchQuery);
  }

  return (
    <React.Fragment>
      <SearchFilter searchPlaceholder="Search For Dealer Names" handleSearch={onSearch} />
      <div className="availableplans-container mt-20">
        {!dealerData ? <Spinner style={{ margin: 'auto' }} animation="grow" /> : dealerData.filter(dealer => !dealer.dealerName || dealer.dealerName.toLowerCase().includes(search.toLowerCase())).map(dealer =>
          <div>
            <div className="dealer-summary"><b>{dealer.dealerName}</b> - {dealer.noneCount} not subscribed, {dealer.silverCount} silver, {dealer.goldCount} gold</div>
          </div>

        )}
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails }
}
export default connect(mapStateToProps)(DealerSummary);

