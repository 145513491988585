import userTypes from './userTypes';
import userService from './userService';
import { getParent } from '../../group/duck/groupService';
import localStorageService from "../../common/duck/localStorageService";

const login = (email, password) => {
    return dispatch => {
        dispatch(request({ email }));
        userService.login(email, password).then(user => {
            dispatch(success(user.data));
            localStorageService.storeToken(user.data.sessionToken);
            localStorageService.storeUserId(user.data.id);
            window.location.href = '/';
        }).catch(error => {
            dispatch(failure(error));
        });
    }

    function request(email) {
        return { type: userTypes.LOGIN_REQUEST, payload: email };
    }

    function success(user) {
        return { type: userTypes.LOGIN_SUCCESS, payload: user };
    }

    function failure(error) {
        return { type: userTypes.LOGIN_FAILURE, payload: error };
    }
}

const getProfile = (userId) => {
    return dispatch => {
        userService.getProfile(userId).then(profile => {
            dispatch({ type: userTypes.PROFILE_SUCCESS, payload: profile.data });
        }).catch(error => {
            let unAuthorized = false;
            if (error.status === 401) {
                unAuthorized = true;
            }

            dispatch({ type: userTypes.PROFILE_FAILURE, error, unAuthorized });
        });
    }
}

const getPreferences = () => {
    return dispatch => {
        userService.getPreferences().then(preference => {
            dispatch({ type: userTypes.PREFERENCE_SUCCESS, payload: preference.data });
        }).catch(error => {
            dispatch({ type: userTypes.PREFERENCE_FAILURE, error });
        });
    }
}

const logout = () => {
}

const getParentRole = () => {
    return dispatch => {
        dispatch({ type: userTypes.PARENT_REQUEST });
        getParent().then(parent => {
            const isDirectGroguruChild = parent === undefined || (parent.type && parent.type === 'groguru_admin');
            dispatch({
                type: userTypes.PARENT_SUCCESS,
                payload: { parentName: parent?.name, parentType: parent?.type, isDirectGroguruChild }
            });
        }).catch(error => dispatch({ type: userTypes.PARENT_FAILURE, error }));
    }
}

const updateSiteCategories = (payload) => ({
    type: userTypes.PREFERENCE_UPDATE_GRAPH_TABS,
    payload
});

const updateCardList = (cardSources) => ({
    type: userTypes.PROFILE_UPDATE_CARDS,
    payload: cardSources
});

const updateStripeCustomer = (details) => ({
    type: userTypes.PROFILE_UPDATE_CUSTOMER,
    payload: details
})

const updateGraphPreferencesForSite = (preferences) => ({
    type: userTypes.UPDATE_USER_GRAPH_PREFERENCES_FOR_SITE,
    payload: preferences
})

const updateDefaultLanding = (preference) => ({
    type: userTypes.UPDATE_DEFAULT_LANDING,
    payload: preference
})

const updateSort = (preference) => ({
    type: userTypes.UPDATE_SORT,
    payload: preference
})

const updateBatteryState = (preference) => ({
    type: userTypes.UPDATE_BATTERY,
    payload: preference
})

const updateRssState = (preference) => ({
    type: userTypes.UPDATE_RSS,
    payload: preference
})

const updateSelectedSite = (preference) => ({
    type: userTypes.SELECTED_SITE,
    payload: preference
})

export default {
    login,
    logout,
    getProfile,
    getPreferences,
    getParentRole,
    updateSiteCategories,
    updateCardList,
    updateStripeCustomer,
    updateGraphPreferencesForSite,
    updateDefaultLanding,
    updateSort,
    updateBatteryState,
    updateRssState,
    updateSelectedSite,
}
