import React, { Component } from "react";
import { connect } from "react-redux";
import configService from "./duck/configService";
import { Card, Form } from "react-bootstrap";
import Message from "../../../common/messages";
import DoneModal from '../../../group/InvitationModal/inviteDone';


class Software extends Component {
  state = {
    disabled: true,
    irrigationDataSource: this.props?.hardwaredetails?.twigDetails || null,
    rainfallDataSource: this.props?.hardwaredetails?.twigDetails || null,
    errors: { serviceError: null },
    responseMsg: null,
    oneTimeMigrationError: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hardwaredetails !== prevProps.hardwaredetails) {
      this.setState({
        irrigationDataSource: this.props?.hardwaredetails?.twigDetails?.settings?.irrigationDataSource?.type,
        rainfallDataSource: this.props?.hardwaredetails?.twigDetails?.settings?.rainfallDataSource?.type
      })
    }
  }

  closeDialogue = () => this.setState({ oneTimeMigrationError: false })


  onRunOneTimeMigration = () => {
    let responseMsg = 'Data updated successfully';
    const siteId = this.props.siteDetails?.siteId;
    configService.runOneTimeMigration(siteId).then(res => {
      this.setState({ responseMsg })
    }).catch(error => {
      this.setState({ errors: error.message });
    });
    this.setState({ oneTimeMigrationError: true });
  };

  onSubmit = (e) => {
    let responseMsg = 'Data updated successfully';
    const siteId = this.props.siteDetails?.siteId;
    const sofwareData = {
      irrigationDataSourceType: this.state.irrigationDataSource,
      rainfallDataSourceType: this.state.rainfallDataSource,
    }
    configService.updateSoftware(siteId, sofwareData).then(res => {
      this.setState({ responseMsg })
    }).catch(error => {
      this.setState({ errors: error.message });
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    let selected = e.target.name;

    if (selected === "irrigationDataSource") {

      this.setState({ irrigationDataSource: value })

    }

    else if (selected === "rainfallDataSource") {

      this.setState({ rainfallDataSource: value })

    }
  };
  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled, responseMsg: null
    });
    if (!this.state.disabled) {
      this.onSubmit();
    }
  };
  render() {
    return (
      <div className="software-container">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5>Software</h5>
              {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                this.props.profileDetails.profile.role.name !== 'business_user' &&
                this.props.profileDetails.profile.role.name !== 'farm_user') ?
                <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button> : null}
            </div>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="row">
                <div className="col-lg-8 ">
                  <Form.Group>
                    <div>
                      <Form.Label>Applied Irrigation Source</Form.Label>
                    </div>
                    <div className="selectdiv">
                      <select name="irrigationDataSource" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.irrigationDataSource ? this.state.irrigationDataSource : ''}
                        onChange={this.handleChange} disabled={this.state.disabled}>
                        <option value={""}></option>
                        <option value="pivotrac">pivotrac</option>

                      </select>
                      <span className="fas fa-caret-down"> </span>

                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 ">
                  <Form.Group>
                    <div>
                      <Form.Label>Rainfall Source</Form.Label>
                    </div>

                    <div className="selectdiv">
                      <select name="rainfallDataSource" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.rainfallDataSource}
                        onChange={this.handleChange} disabled={this.state.disabled}>
                        <option value="DTN">DTN</option>
                        <option value="pivotrac">pivotrac</option>
                      </select>
                      <span className="fas fa-caret-down"> </span>

                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="run-one-time" onClick={this.onRunOneTimeMigration}>Run One Time Migration</div>
            </form>
          </Card.Body>


          <div className="messagePopup">
            {this.state.responseMsg !== null && this.state.responseMsg?.length !== 0 && <Message variant="success" message={'Data updated successfully'} />}
          </div>
          <DoneModal
            buttonLabel="Ok"
            show={this.state.oneTimeMigrationError}
            onClose={this.closeDialogue}
            data={{ title: 'Update In Progress!', message: 'One time migration is in progress, Please check after sometime' }} />
        </Card>
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(Software);

