import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import templateService from "../template/duck/templateService";
import Search from "../../../common/search";
import { StickyMessage } from "../../../common/messages";
import { connect } from "react-redux";

const ERRORS = {
  TEMPLATE_UNIDENTIFIED: "Cannot identify 'Crop Type' for this site",
  NO_TEMPLATES_FOUND: "Cannot find templates for CropType",
};

const ALL_OWNERS = 'All Owners';

function TemplateItem({
  name,
  ownerName,
  ownerOrgName,
  sharingOption,
  openTemplateEditModal = () => { },
  isUserOwnedTemplate = false,
  askConfirmation,
  deleteTemplateUpdateLocal,
  ...radioAttrs
}) {

  const templateId = radioAttrs.value;
  const ownerInfo = [ownerName, ownerOrgName].filter(i => i).join(' - ');

  function deleteTemplate(event) {
    event.preventDefault();
    askConfirmation({
      title: 'Are you sure?',
      message: 'This action cannot be reverted. Are you sure you want to delete this template?',
      onYes: () => {
        templateService.deleteTemplate(templateId)
          .then(() => deleteTemplateUpdateLocal(templateId));
      }
    });
  }

  function editTemplate(event) {
    event.preventDefault();
    openTemplateEditModal({ id: templateId, name, sharingOption });
  }

  return (
    <div className="template-select d-flex justify-content-between">
      <div>
        <h6>{name}</h6>
        <p>{isUserOwnedTemplate ? 'You own this template!' : ownerInfo}</p>
        {isUserOwnedTemplate && (
          <div className="d-flex">
            <button onClick={editTemplate} className="template-edit-btn ">Edit</button>
            <button onClick={deleteTemplate} className="template-del-btn ">Delete</button>
          </div>
        )}
      </div>
      <label className="label mt-20 ">
        <input type="radio" name="template" {...radioAttrs} />
        <span className="checkmark "></span>
      </label>
    </div>
  );
}

function searchTemplatesByName(templates, name = "") {
  return templates.filter((template) => (
    !template.headerDetails.Name ||
    template.headerDetails.Name.toLowerCase()
      .replace(" ", "")
      .includes(name.toLowerCase().replace(" ", ""))
  )
  );
}

function TemplateModal({
  userid,
  hideModal,
  siteObjectId,
  cropType,
  templateList,
  setTemplateList,
  appliedTemplate,
  applyTemplate,
  openTemplateEditModal,
  askConfirmation,
  savePreference = true,
  siteDetails,
  setIsDataLoading,
}) {

  const [show, setShow] = useState(false);
  const [templateIdLocal, setTemplateIdLocal] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [templateOwners, setTemplateOwners] = useState([]);
  const [search, setSearch] = useState("");
  const [ownerFilter, setOwnerFilter] = useState(ALL_OWNERS)
  const [error, setError] = useState(null);
  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const shouldFetchAvailableTemplateList = cropType && !templateList.loaded;
  const defaultTemplateId = siteDetails?.cropTemplate?.objectId
  useEffect(() => {
    if (shouldFetchAvailableTemplateList) {
      templateService
        .getCropTemplate({ croptype: cropType })
        .then(data => {
          setTemplateList({
            list: data,
            loaded: true
          });
          const template = data.find(t => t.objectId === defaultTemplateId);
          if (template) applyTemplate(defaultTemplateId, template?.headerDetails?.Name, template);
          setTemplateOwners(Array.from(new Set(data.map(template => template.createdBy.name))))
        })
        .catch((err) => {
          setError(err);
          console.log(err);
        });
    }
  }, [applyTemplate, cropType, setTemplateList, shouldFetchAvailableTemplateList, defaultTemplateId]);

  const handleTemplate = () => {
    // if (savePreference) {
      templateService.putTemplateData(siteObjectId, {
        cropTemplate: templateIdLocal
      });
    // }
  };

  function deleteTemplateUpdateLocal(id) {
    setTemplateList(templateListData => ({
      list: templateListData.list.filter(tmpt => tmpt.objectId !== id),
      loaded: templateListData.loaded
    }));
  }

  const resetModal = () => {
    setTemplateIdLocal(false);
    // setSelectedCrop(null)
    setSearch("");
    handleClose();
  };

  const updateViewTemplate = (event) => {
    event.preventDefault();
    if (templateIdLocal !== false) {
      const template = templateList.list.find(t => t.objectId === templateIdLocal);
      applyTemplate(templateIdLocal, templateName, template);
      handleTemplate();
      setTemplateIdLocal(false);
    }
    handleClose();
  }
  const onSearchName = (e) => {
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    setSearch(searchQuery);
  }

  let errorMessage;
  if (!cropType) {
    errorMessage = ERRORS.TEMPLATE_UNIDENTIFIED;
  } else if (templateList.list.length === 0) {
    errorMessage = `${ERRORS.NO_TEMPLATES_FOUND}: ${cropType}`;
  } else if (error?.response && (error.response.data?.message || error.response.message)) {
    errorMessage = error.response?.message || error.response?.data?.message
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <Button
          onClick={handleShow}
          variant="none"
          className="onClickSimpleButton"
        >
          {(appliedTemplate && cropType) || "No Template Selected"}
          {/* <i className="fas fa-caret-down pl-15"></i> */}
        </Button>
      </div>
      <Modal
        show={!hideModal && show}
        centered
        size="lg"
        onHide={resetModal}
        className="saveTemplateModal"
      >
        <Modal.Header
          closeButton={false}
          className="d-flex justify-content-between"
        >
          <p>Select the Template</p>
          <span onClick={resetModal}>Close</span>
        </Modal.Header>

        <Modal.Body>
          {templateList.loaded === false && cropType ? (
            <small>Loading templates for cropType: {cropType} ...</small>
          ) : (
            (errorMessage) && (
              <StickyMessage
                variant="error"
                message={errorMessage}
              />
            )
          )}
          <Form className="mb-10">
            <div className="row">
              {/* <div className="col-lg-6 col-12">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Crop type</Form.Label>
                                    <Form.Control as="select" value={props.selectedCrop} onChange={e => setSelectedCrop(e.target.value)}>
                                        <option>None</option>
                                        {templateList && Object.keys(templateList).map(template => <option key={template}>{template}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </div> */}
              <div className="col-lg-6 col-12">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Owner</Form.Label>
                  <Form.Control as="select" value={ownerFilter} onChange={event => setOwnerFilter(event.target.value)}>
                    <option>{ALL_OWNERS}</option>
                    {templateOwners.map(ownerName => <option key={ownerName}>{ownerName}</option>)}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="template-search">
              <Search
                as="div"
                onSearch={onSearchName}
              />
            </div>
            <TemplateItem
              name="No Template"
              value=""
              checked={((templateIdLocal === false) && (appliedTemplate === null)) || templateIdLocal === null}
              onChange={(event) => {
                setTemplateName(null);
                setTemplateIdLocal(null)
              }}
            />
            {cropType &&
              templateList.list.length > 0 &&
              searchTemplatesByName(templateList.list, search)
                .filter(template => ownerFilter === ALL_OWNERS || template.createdBy.name === ownerFilter)
                .map((template) => (
                  <TemplateItem
                    key={template.objectId}
                    isUserOwnedTemplate={template.createdBy.objectId === userid}
                    ownerName={template.createdBy.name || template.createdBy.username}
                    ownerOrgName={template.ownerOrganisation.name}
                    name={template.headerDetails.Name}
                    sharingOption={template?.sharingSettings?.sharingOption}
                    value={template.objectId}
                    checked={((templateIdLocal === false) && (appliedTemplate === template.objectId)) || templateIdLocal === template.objectId}
                    openTemplateEditModal={openTemplateEditModal}
                    askConfirmation={askConfirmation}
                    deleteTemplateUpdateLocal={deleteTemplateUpdateLocal}
                    onChange={(event) => {
                      setTemplateName(template.headerDetails.Name)
                      setTemplateIdLocal(event.target.value)
                    }}
                  />
                ))}
            <button
              className="btn-submit templateButton mt-30"
              disabled={templateIdLocal === false}
              onClick={updateViewTemplate}
            >
              Use Selected Template
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  userid: state.userDetails.profileDetails.profile.objectId
});

export default connect(mapStateToProps)(TemplateModal);