import React from 'react';


export default function NotificationTabContent(props) {
    return <div className="notification-container">
        <div className="notification-header d-flex justify-content-between">
            <h6>New Notifications</h6>
            <h6>Read All</h6>
            </div>
            <hr></hr>
            {/* <div className="enabled" >
            <div className="notifications d-flex justify-content-between align-items-center">
                <div className="notification-desc">
                  <h4>Corn Field North (12DFA) is disconnected.</h4>
                  <h6>Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum. </h6>
                  <p>April 24, 2019 - 10:12am</p>

                </div>
               <div className="pr-25">
               <div className="notification-checked">
               <span className="icon-arrow_right_alt-24px-1"></span>
               </div>
               </div>

            </div>
            <hr></hr>
            </div>
            <div  className="enabled">
            <div className="notifications d-flex justify-content-between align-items-center">
                <div className="notification-desc">
                  <h4>Corn Field North (12DFA) is disconnected.</h4>
                  <h6>Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum. </h6>
                  <p>April 24, 2019 - 10:12am</p>

                </div>
                <div className="pr-25">
               <div className="notification-checked">
               <span className="icon-arrow_right_alt-24px-1"></span>
               </div>
               </div>
            </div>

            <hr></hr>
            </div> */}
            <div className="notification-header d-flex justify-content-between">
            <h6>Read Notifications</h6>

            </div>
            <hr></hr>
            {/* <div  className="disabled">
            <div className="notifications d-flex justify-content-between align-items-center">
                <div className="notification-desc">
                  <h4>Corn Field North (12DFA) is disconnected.</h4>
                  <h6>Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum. </h6>
                  <p>April 24, 2019 - 10:12am</p>

                </div>
                <div className="pr-25">
               <div className="notification-check">

               <span className="icon-arrow_right_alt-24px-1"></span>

               </div>
               </div>

            </div>

            <hr></hr>
            </div> */}


            </div>



}
