import React, { useState } from "react";
import fileService from "./duck/fileService";

export default function FileUpload({defaultImage, label, onUploadSuccess, onUploadError, imageAlt, onChange = () => null}) {
    const htmlUpload = React.createRef();
    const [imageData, setImageData] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    function onUpload(e) {
        const image = e.currentTarget.files[0];
        const imgObjURL = URL.createObjectURL(image);
        onChange(imgObjURL);
        fileService.postImage(image).then(data => {
            setUploadedImage(imgObjURL);
            setImageData(data.image);
            onUploadSuccess(data);
        }).catch(error => {
           if(onUploadError) onUploadError(error);
        });
    }


    function openFileUpload(e) {
        htmlUpload.current.click();
    }

    return (
      <React.Fragment>
          <div onClick={openFileUpload} className="d-flex  mt-20 farm-logo">
              <div className="logo">
              <img  
                  src={uploadedImage || imageData || defaultImage}
                  alt={imageAlt}/>
              </div>
              <p >{label}</p>
              <input name="image" type="file" onChange={onUpload} ref={htmlUpload}
                     style={{"display": "none"}}/>
          </div>
      </React.Fragment>
    );
}
