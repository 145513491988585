import React from "react";
import { Card, Form } from "react-bootstrap";
import GForm from "../../../common/form";
import siteService from "../../duck/siteService";
import { connect } from "react-redux";
import Joi from "joi-browser";
import configService from "../configuration/duck/configService";
import { SensorInfo } from "./sensorInfo";

class IrrigationInfo extends GForm {
    componentDidMount() {
        configService.getType('IrrigationType').then((res) => {
            this.setState({ irrigationtypes: res });
        });

    }

    componentDidUpdate(prevProps) {
        if (this.props.siteDetails !== prevProps.siteDetails) {

            this.setState({
                data: {
                    wellCapacity: this.props.siteDetails?.twigDetails?.maxIrrigationVolume,
                    fieldSize: this.props.siteDetails?.twigDetails?.distanceFromWaterSource,
                    irrigationType: this.props.siteDetails?.twigDetails?.irrigationType
                },
                id: this.props.siteDetails.id
            })
        }
    }
    state = {
        data: {
            wellCapacity: this.props.siteDetails?.twigDetails?.maxIrrigationVolume || '',
            fieldSize: this.props.siteDetails?.twigDetails?.distanceFromWaterSource || '',
            irrigationType: this.props.siteDetails?.twigDetails?.irrigationType || '',
        },
        errors: {},
        serviceError: null,
        id: this.props.siteDetails.id || null,
        irrigationtypes: null,
        disabled: true

    }

    schema = {
        wellCapacity: Joi.number().label('Well Capacity').allow(null),
        fieldSize: Joi.number().label('Field Size').allow(null),
        irrigationType: Joi.any().allow(null),

    };

    handleEdit = ({ currentTarget: input }) => {

        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        const error = this.validateProperty(input);
        if (error) errors[input.name] = error;
        else delete errors[input.name];

        data[input.name] = input.value;


        this.setState({ data, errors });


    }

    doSubmit = () => {

        if (!this.state.disabled) {
            const siteobjid = this.state.id
            const dataSent = {
                maxIrrigationVolume: this.state.data.wellCapacity, distanceFromWaterSource: this.state.data.fieldSize,
                irrigationType: this.state.data.irrigationType
            }
            siteService.updateSite(siteobjid, dataSent).then(response => {
                this.props.setChangeLogs(false)

                const { settings } = response.data.parsedTwig;
                const distanceFromWaterSource = settings.distanceFromWaterSource.value;
                const maxIrrigationVolume = settings.maxIrrigationVolume.value;
                const irrigationType = settings.irrigationType.value;
                this.props.onTwigDetailsChange({ distanceFromWaterSource, maxIrrigationVolume, irrigationType });
                this.props.setChangeLogs(true)


            })


        }
    }


    handledisable = () => {
        this.setState({ disabled: !this.state.disabled })
    }
    render() {

        const { disabled } = this.state;
        const formOptions = this.state.irrigationtypes &&
            this.state.irrigationtypes.map((type, index) => ({ name: Object.values(type)[0], _id: Object.values(type)[0] }));

        return (
            <div className="basic-info-container">


                <Card.Title className="d-flex justify-content-between" >
                    <div>Irrigation Information</div>
                    {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                        this.props.profileDetails.profile.role.name !== 'business_user' &&
                        this.props.profileDetails.profile.role.name !== 'farm_user') ?
                        (<div onClick={this.handledisable}>
                            {this.renderButton(this.state.disabled ? 'Edit' : 'Save', { className: "save-btn" })}
                        </div>) : null}
                </Card.Title>
                <hr></hr>

                <Form>
                    <div className="row">
                        <div className="col-lg-4 col-6">
                            {this.renderSelect("irrigationType", "Irrigation Type", formOptions,
                                { placeholder: this.state?.data?.irrigationType !== null ? this.state?.data?.irrigationType : '', disabled })}

                        </div>
                        <div className="col-lg-4 col-6">

                            <Form.Group >
                                <Form.Label>Well Capacity (gallons)</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={disabled}
                                    label="wellCapacity"
                                    name="wellCapacity"
                                    onChange={this.handleEdit}
                                    placeholder={(this.state.data.wellCapacity !== null && this.state.data.wellCapacity !== '') ? this.state.data.wellCapacity : ''}
                                />
                                {this.state.errors && <span className="error">{this.state.errors["wellCapacity"]}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 col-6">
                            <Form.Group >
                                <Form.Label>Field Size (acres)</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={disabled}
                                    label='fieldSize'
                                    name="fieldSize"
                                    onChange={this.handleEdit}
                                    placeholder={(this.state.data.fieldSize !== null && this.state.data.fieldSize !== '') ? this.state.data.fieldSize : ''}
                                />
                                {this.state.errors && <span className="error">{this.state.errors["fieldSize"]}</span>}
                            </Form.Group>

                        </div>
                    </div>
                </Form>
                <SensorInfo siteDetails={this.props.siteDetails} onTwigDetailsChange={this.props.onTwigDetailsChange} />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { userDetails } = state;
    const profileDetails = userDetails.profileDetails;
    return { profileDetails }
}

const connectedSiteBasicInfo = connect(mapStateToProps)(IrrigationInfo);
export { connectedSiteBasicInfo as IrrigationInfo };
