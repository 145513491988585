import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MessageProps = {
    variant: PropTypes.oneOf(['error', 'success']),
    message: PropTypes.string.isRequired
};

function Message({variant, message, dismissTime, onDismiss}) {
    const [active, setActive] = useState(true);
    const toggle = () => {
        setActive(!active);
        onDismiss && onDismiss();
    };

    useEffect(function () {
        let timer;
        if (active) {
            timer = setTimeout(toggle, dismissTime);
        }
        return () => clearTimeout(timer);
    })

    return active && <StickyMessage
        variant={variant}
        message={message}
        children={<button onClick={toggle}><i className="fas fa-times"></i></button>} />;
}

export function StickyMessage({variant, message, show = true, children = null}) {
    const _variant = variant ? `${variant}-msg` : '';
    return show && (
        <div className={`${_variant} message`}>
            <div className="d-flex msg-view justify-content-between">
                <h6>{message}</h6>
                {children}
            </div>
        </div>
    )
}

StickyMessage.propTypes = MessageProps;

Message.propTypes = MessageProps;
Message.defaultProps = {
    dismissTime: 2000,
    variant: 'error'
}
export default Message;