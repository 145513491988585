import React from "react";

function SensorItem(props) {

    function handleSeriesVisibility() {
        if (props.sensor.checked) {
            props.updateSensor(props.sensor, false);
        } else {
            props.updateSensor(props.sensor, true);
        }
        props.change();
    }

    return (
        <div onClick={handleSeriesVisibility}
            style={{ padding: '5px 10px' }}

            className={`moisture-content ${props.sensor.checked ? "ticked" : "unticked"} d-flex`}>
            <div className="d-flex">
                <div className="d-flex justify-content-between">
                    <div
                        className={`notification-check${props.sensor.checked ? ' notification-checked' : ''}`}
                        style={{ background: props.sensor.color }}>
                        {props.sensor.checked ? <span className="icon-done-24px"></span> : ""}
                    </div>
                    <p style={{ marginLeft: '10px' }}> {props.sensor.name}</p>
                </div>
            </div>
            <h6>({props.sensor.depth} in)</h6>
        </div>
    )
}

export default SensorItem;