import React, { useState, useEffect} from "react";
import { Card, Accordion, Button, Spinner } from "react-bootstrap";
import IssueStatus from './issueStatus';
import { statusSelectors,statusSelect } from './duck/farmsUtils';
import farmService from './duck/farmService';
import {Link} from "react-router-dom";
import farmDefaultImage from "../../assets/images/farms/farmdefaultimg.png";
import profileIcon from "../../assets/images/placeholder/group.png";
import iconimage from "../../assets/images/left-arrow/chevron-left.png";
import {getFilterFromQuery} from './duck/farmsUtils';
import FarmEmptyCase from './farmEmptyCase'

function LeafCard({ name, type, image, address, reportingStatus,moistureState,agronomic }){
  
  return <Card.Header>
  <div className="row">
    <div className="col-md-1 col-3 d-flex justify-content-center align-items-center pl-50">{type?'':<div className="d-flex profile-content"></div>}</div>
    
    <div className="col-md-7 col-9 distributer-image d-flex">
        <div className="card-img "><img src={image? image:type?profileIcon:farmDefaultImage} alt="pic" /></div>
        <div className="card-text">
          <h3 className="mb-0">{name}</h3>
          <p>{address}</p>
        </div>
    </div>

    <div className="col-md-1 col-3 d-flex status-farm status-farrm-pl-10"><div><h6># of farm</h6></div></div>
    
    <div className="col-md-2 col-6 status-farm">
      <div className="farm-number ml-30 pr-40">
        <h6>Site ID Statuses</h6>
        <div className="d-flex align-items-center  map-style">

          {!agronomic ?
          <IssueStatus
            red={reportingStatus.red}
            yellow={reportingStatus.yellow}
            green={reportingStatus.green} 
            maroon={reportingStatus.maroon} 
            agronomic={agronomic}
            />:
            <IssueStatus 
            red={moistureState.red}
            yellow={moistureState.yellow}
            green={moistureState.green} 
            maroon={reportingStatus.maroon}
            agronomic={agronomic}
            />}
        </div>
      </div>
    </div>
    <div className="col-md-1 col-3"></div>

  </div>
</Card.Header>
}

function LeafNode({ name, type,objectId, image , address,leaf:child,agronomic }) {


 
  const reportingStatus={
    red: statusSelectors['needs-attention'](child).count,
    yellow: statusSelectors['has-warning'](child).count,
    green: statusSelectors['no-issues'](child).count,
    maroon: statusSelectors['extracted'](child)?.count
  }
  
if(type){
    
  return (
    !agronomic && <Card className="mb-2" >
     <LeafCard name={name} type={type} address={address} image={image} reportingStatus={reportingStatus && reportingStatus}/>
      </Card>
    )
  }
  else
  {
    const moistureState={
    red: statusSelect['needs-attention'](child).count,
    yellow: statusSelect['has-warning'](child).count,
    green: statusSelect['no-issues'](child).count,
    maroon: statusSelect['extracted'](child)?.count
  
}
    
  return (
    <Card className="mb-2" as={Link} to={{pathname: `/farm/${objectId}/sites`}}>
    {agronomic?<LeafCard name={name} type={type} address={address} image={image} agronomic={agronomic} moistureState={moistureState && moistureState}/>:
    <LeafCard name={name} type={type} address={address} image={image} agronomic={agronomic} reportingStatus={reportingStatus && reportingStatus}/>}
    
    </Card>
  )
  }
}

function FarmsSubtree({ name, type,count,headType, filterOption, children,image,agronomic, reportingStatus,moistureState }) {

  function toggleArrow(event) {
    if(event.target.className === 'arrow-90icon'){
      event.currentTarget.classList.toggle('arrow-90icon');
    }else{
      event.currentTarget.classList.toggle('arrow-90icon');
    }
  }


  let filteredArray = [];
      if(filterOption === 'needs-attention'){
          let redArray = [];
          
          children.map((child) => child.reportingStatus.map((report) => report.status === 'LOW' && report.count >0 ? redArray.push(child): ''))
          filteredArray=[...redArray];
      }
      else if(filterOption === 'has-warning'){
        let yellowArray = [];
        children.map((child) => child.reportingStatus.map((report) => report.status === 'MED' && report.count >0 ? yellowArray.push(child): ''))
        filteredArray=[...yellowArray];
      }else if(filterOption === 'no-issues'){
        let blueArray = [];
        
        children.map((child) => child.reportingStatus.map((report) => report.status === 'FAIR' && report.count >0 ? blueArray.push(child): ''))
        filteredArray=[...blueArray];
    }else if(filterOption === 'extracted'){
      let blueArray = [];
      
      children.map((child) => child.reportingStatus.map((report) => report.status === 'EXTRACTED' && report.count >0 ? blueArray.push(child): ''))
      filteredArray=[...blueArray];
  }else{
    filteredArray = [...children];
    }
  return (
    <Accordion defaultActiveKey={type === headType && "0"}>
      <Card className="mb-2">
        <Card.Header>
          <div className="row">
            <div className="col-md-1 col-3"></div>

            <div className="col-md-7 col-9 d-flex">

              <div className="card-img ">{type === "BusinessNode"?'':<img src={image ? image:profileIcon} alt="pic" />}</div>
              <div className="card-text">
                <h3 className="mb-0">{name}</h3>
                <p>{type}</p>
              </div>
            </div>

            <div className="col-md-1 col-3 d-flex status-farm status-farrm-pl-10">
                <div className="farm-number">
                  <h6># of farms</h6>
                  <p>{count}</p>
                </div>
            </div>

            <div className="col-md-2 col-6 status-farm">
            {!agronomic &&<div className="farm-number ml-30 pr-40">
                  <h6>Total Site ID Statuses</h6>
                <div className="d-flex align-items-center  map-style">
                  <IssueStatus red={reportingStatus.red} yellow={reportingStatus.yellow} green={reportingStatus.green} maroon={reportingStatus.maroon} />
                </div>
              </div>}
            </div>

            <div className="col-md-1 col-3 d-flex">       
              <Accordion.Toggle as={Button} onClick={toggleArrow} className={type==="groguru"?'arrow-90icon':''} variant="link" eventKey="0" >
                <img src={iconimage}  alt="Expand this Accordion" />
              </Accordion.Toggle>
              </div>
          </div>


        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <div>
            {filteredArray && filteredArray.map((child) =>{
              if (child!==undefined && child.children?.length > 0) {
                return <div className="distributerFarmContents" key={child.objectId}>
                  <FarmsSubtree
                  children={child.children}
                  type={child.type}
                  count={findFarms(child.children)}
                  name={child.name}
                  image={child.image}

                  agronomic={agronomic}
                  reportingStatus={{
                    red: statusSelectors['needs-attention'](child).count,
                    yellow: statusSelectors['has-warning'](child).count,
                    green: statusSelectors['no-issues'](child).count,
                    maroon: statusSelectors['extracted'](child)?.count
                  }} />

                  
                  </div>
              }else{
                return child!==undefined && <div className="noChildrenLeafNode" key={child.objectId}>
                
                <LeafNode
                  name={child.name}
                  type={child.type}
                  image={child.image}
                  objectId={child.objectId}
                  address={child.hqAddress}
                  agronomic={agronomic}
                  leaf={child}
                  
                   />

                  </div>
            }})}
 
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}
export function findFarms(children){
  let count = 0;
  children && children.map((child)=> child.type ? child.children.length>0 && findMoreFarms(child.children): count++)
  function findMoreFarms(children){
    children.map((child)=> child.children?.length>0?findMoreFarms(child.children):child.type?'':count++);
  }
  return count;
}
function getReportStatusCount(children){
  let countRed=0;
  let countYellow=0;
  let countGreen=0;
  let countNotConnect=0;
  let countExtracted=0;
  children && children.map((child)=>child.children.length>0?findMoreCount(child.children):child.reportingStatus.map(({status,count})=>
  {if(status==="MED"){
    return countYellow+=count;
  }else if(status==="LOW"){
    return countRed+=count;
  }else if(status==="FAIR"){
    return countGreen+=count;
  }else if (status==="NOT_CONNECTED"){
    return countNotConnect+=count;
  }else if (status==="EXTRACTED"){
    return countExtracted+=count;
  }
  else return null;}
  ))
  function findMoreCount(children){
    children && children.map((child)=>child.children.length>0?findMoreCount(child.children):child.reportingStatus.map(({status,count})=>
    {
      if(status==="MED"){
      return countYellow+=count;
    }else if(status==="LOW"){
      return countRed+=count;
    }else if(status==="FAIR"){
      return countGreen+=count;
    }else if (status==="NOT_CONNECTED"){
      return countNotConnect+=count;
    }else if (status==="EXTRACTED"){
      return countExtracted+=count;
    }
    else return null;}
    ))
  }
  
  return [
    {status:"LOW",count:countRed},
    {status:"MED",count:countYellow},
    {status:"FAIR",count:countGreen},
    {status:"NOT_CONNECTED",count:countNotConnect},
    {status:"EXTRACTED",count:countExtracted}
  ]
}

function handleSearch(children,search){
  let searchedResult=[]
  if(search.length>0){
    children.map((child)=>child.name.toLowerCase().search(search.toLowerCase())!== -1  ? searchedResult.includes(child)?'':searchedResult.push(child): child.children.length>0 && searchedResult.push(relativeSearch(child,child.children,search)));
  }else{
    return children && [...children];
  }
  function relativeSearch(child, childrens,search){
    childrens.map((children)=> children.name.toLowerCase().search(search.toLowerCase())!== -1  ? searchedResult.includes(child)?'':searchedResult.push(child): children.children.length>0 && relativeSearch(child,children.children,search))
  }
  let searchResult = searchedResult?.filter((e)=> e!==undefined);
  return searchResult;
  }


function escapeRegExp (text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
 }
function FarmsTreeView(props) {

  const [nodeListHead, setNodeListHead] = useState(null);
  let notFound = false;
  useEffect(() => {
    setNodeListHead(null);
    const farms= farmService.getTreeView();
    if(props.sortType==="Recently Added") {
      farms.request("createdAt").then(data => setNodeListHead(data));
    }
    else{
      farms.request().then(data => setNodeListHead(data));
    }
    return ()=>{
      farms.cancel();
    }
  }, [props.sortType]);
  let searching = escapeRegExp(props.search);
  let childrenArray=null;
  if(nodeListHead){
    let bus = handleSearch(nodeListHead?.directBusinessEntities,props.accessFarm ? props.accessFarm:searching);
    let other = handleSearch(nodeListHead?.otherEntities,props.accessFarm ? props.accessFarm:searching);
    const business={
      name:"GroGuru",
      objectId:"BusinessNode",
      type:"direct",
      count:findFarms(nodeListHead?.directBusinessEntities),
      reportingStatus:getReportStatusCount(nodeListHead?.directBusinessEntities),
      children: bus, 
    }
    childrenArray = [business,...other];
    notFound = bus.length === 0 && other.length === 0 && true;
    
  }

  let searchedResult=childrenArray;
  let filteredSearchResult = null;
  if(props.accessFarm !==null){
    filteredSearchResult = searchedResult?.filter((e)=> e.type === "direct" || e.objectId===props.accessFarmObject);
  }
  
  return (
    <div>
      <div className="distributer-container">
        <div className="view-area">
          <div className="distributerFarmContents">
                {nodeListHead? searchedResult.length>0 && !notFound?
                <>
                   
                  <FarmsSubtree
                    children={filteredSearchResult ? filteredSearchResult:searchedResult}
                    filterOption={getFilterFromQuery(props.location.search)}
                    headType={nodeListHead.type}
                    type={nodeListHead.type}
                    count={findFarms(nodeListHead.children)}
                    name={nodeListHead.name}
                    image={nodeListHead.image}
                    agronomic={props.agronomic}
                    reportingStatus={{
                      red: statusSelectors['needs-attention'](nodeListHead).count,
                      yellow: statusSelectors['has-warning'](nodeListHead).count,
                      green: statusSelectors['no-issues'](nodeListHead).count,
                      maroon: statusSelectors['extracted'](nodeListHead)?.count
                    }} />  </>:<FarmEmptyCase search={props.search}/>
                : <Spinner style={{display: 'block', margin: 'auto'}} animation="grow" />}
              
              
              
              
              
      
          </div>
        </div>
      </div>
    </div>
  );
}

export default FarmsTreeView;