function fetchingUnitsBasedOnType(irrigationType) {
    const type = irrigationType;
    let unitsObject = {};
    switch (type) {
        case 2:
            unitsObject["system_type"] = 2;
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area_unit"] = "ac";
            break;

        case 5:
            unitsObject["system_type"] = 5;
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area_unit"] = "ac";
            break;

        case 8:
            unitsObject["system_type"] = 8;
            unitsObject["emitter_discharge_rate_unit"] = "gpm"
            unitsObject["emitter_spacing_unit"] = "in"
            unitsObject["irrigated_area_unit"] = "ac"
            unitsObject["lateral_spacing_unit"] = "in"
            unitsObject["line_depth_unit"] = "in"
            // unitsObject["plant_density_unit"] = "/ac"
            break;

        default:
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area_unit"] = "ac";
            break;
    }
    return unitsObject;
}

function defaultValuesForIrrigationType(irrigationType) {
    const type = irrigationType;
    let unitsObject = {};
    switch (type) {
        case 2:
            unitsObject["system_type"] = 2;
            unitsObject["flow_rate"] = 500;
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area"] = 120;
            unitsObject["irrigated_area_unit"] = "ac";
            unitsObject["nozzle_type"] = 0;

            break;

        case 5:
            unitsObject["system_type"] = 5;
            unitsObject["flow_rate"] = 500;
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area"] = 120;
            unitsObject["irrigated_area_unit"] = "ac";

            break;

        case 8:
            unitsObject["system_type"] = 8;
            unitsObject["emitter_discharge_rate"] = 0.2
            unitsObject["emitter_spacing"] = 20
            unitsObject["irrigated_area"] = 80
            unitsObject["lateral_spacing"] = 30
            unitsObject["line_depth"] = 12
            unitsObject["emitter_discharge_rate_unit"] = "gpm"
            unitsObject["emitter_spacing_unit"] = "in"
            unitsObject["irrigated_area_unit"] = "ac"
            unitsObject["lateral_spacing_unit"] = "in"
            unitsObject["line_depth_unit"] = "in"
            // unitsObject["emitters_per_plant"] = 0.33
            // unitsObject["plant_density"] = 32000
            // unitsObject["plant_density_unit"] = "/ac"
            break;

        default:
            unitsObject["flow_rate_unit"] = "gpm";
            unitsObject["irrigated_area_unit"] = "ac";
            break;
    }
    return unitsObject;
}

export default {
    fetchingUnitsBasedOnType,
    defaultValuesForIrrigationType,
}