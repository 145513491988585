import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import subscriptionService from '../subscriptions/duck/subscriptionService';
import TableHead from '../../common/tableHead'
import SearchFilter from '../searchFilter';
import * as moment from 'moment';


let headings = [
  { text: 'Site ID', className: "sid" },
  { text: 'Old Plan', className: "old-plan" },
  { text: 'New Plan', className: "new-plan" },
  { text: 'Modified By', className: 'modified-by' },
  { text: 'Modified Time', className: "modified-time" }
];



function SubscriptionAudit(props) {


  const [search, setSearch] = useState('');
  const [auditData, setAuditData] = useState(null);

  useEffect(() => {
    const reqObj = subscriptionService.getAuditData();
    reqObj.then(data => setAuditData(data))
      .catch(error => console.log(error));
  }, []);

  const onSearch = (e) => {
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    setSearch(searchQuery);
  }

  return (
    <React.Fragment>
      <div className="subscription-audit">
        <SearchFilter searchPlaceholder="Search with Site ID" handleSearch={onSearch} />
        <Table striped bordered hover responsive="sm">
          <TableHead heads={headings} />
          <tbody>
            {!auditData ? <Spinner style={{ margin: 'auto' }} animation="grow" /> : auditData?.filter(site => !site.siteId || site.siteId?.includes(search)).map(data => {
              let stillUtc = data.modifiedAt && data.modifiedAt !== null ? moment.utc(data.modifiedAt).toDate() : null;
              let local = stillUtc !== null ? moment(stillUtc).local().format("MMM DD,YYYY-hh:mmA") : '';
              return (
                <tr>
                  <td>{data.siteId}</td>
                  <td>{data.oldPlan}</td>
                  <td>{data.currentPlan}</td>
                  <td>{data.modifiedBy}</td>
                  <td>{local}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      </div>
    </React.Fragment>
  );
}

export default SubscriptionAudit;