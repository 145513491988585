import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  hideSeries,
  toggleSeriesVisibility, addYaxisAnnotation, clearAnnotations
} from '../../../common/chart/utils';
import { closestLow } from './duck/graphUtility';
import { lastItem } from '../../../common/duck/utils';

export function labelSeries({ depthName, depth, name, seriesName, showTopSensorLabel }) {
  if (depthName && depth) {
    return `${depthName} (${depth} in)`;
  } else if (showTopSensorLabel && name) {
    return `${name} (L)`;
  } else if (name) {
    return `${name}`;
  } else {
    return seriesName;
  }
}

function SiteLegend({ checkList, timePeriod,
  red, redAbsolute, redAutoAbsolute, red2,
  yellow, yellowAbsolute, yellowAutoAbsolute, yellow2,
  green, greenAbsolute, greenAutoAbsolute, green2,
  blueAbsolute,
  blueAutoAbsolute,
  maroonAbsolute,
  rpValuesLocal,
  redLocalAbsolute,
  wl1ValuesLocal,
  yellowLocalAbsolute,
  wl2ValuesLocal,
  greenLocalAbsolute,
  fcValuesLocal,
  blueLocalAbsolute,
  pwpValuesLocal,
  maroonLocalAbsolute,
  siteAnnotation,
  maroonAutoAbsolute,
  passCurrentSensorIndex,
  siteName, dataTypesCount, siteId, curvesToRender, legendPoints, notifySeriesToggled, notifySeriesListToggled }) {


  const curves = useMemo(() => {
    const curvesByName = curvesToRender.reduce((acc, c) => {
      if (c.name in acc) {
        acc[c.name].push(c);
      } else {
        acc[c.name] = [c];
      }
      return acc;
    }, {});

    // for (let key in curvesByName) {
    //   curvesByName[key] = curvesByName[key].sort((b, a) => b.depth - a.depth);
    // }

    return curvesByName;
  }, [curvesToRender]);


  return (
    <React.Fragment>
      {Object.keys(curves).map(measure => <LegendSection
        checkList={checkList}
        key={measure}
        timePeriod={timePeriod}
        // sectionLabel={measure}
        curves={curves[measure]}
        measurements={[{ key: "MOIST", name: "Moisture" }]}
        legendPoints={legendPoints}
        dataTypesCount={dataTypesCount}
        setCurrentSensorIndex={(i) => passCurrentSensorIndex(i)}
        notifySeriesToggled={notifySeriesToggled}
        notifySeriesListToggled={notifySeriesListToggled}
        red={red}
        red2={red2}
        redAbsolute={redAbsolute}
        redAutoAbsolute={redAutoAbsolute}

        yellow={yellow}
        yellow2={yellow2}
        yellowAbsolute={yellowAbsolute}
        yellowAutoAbsolute={yellowAutoAbsolute}

        green={green}
        green2={green2}
        greenAbsolute={greenAbsolute}
        greenAutoAbsolute={greenAutoAbsolute}
        blueAbsolute={blueAbsolute}
        blueAutoAbsolute={blueAutoAbsolute}

        maroonAbsolute={maroonAbsolute}
        maroonAutoAbsolute={maroonAutoAbsolute}

        rpValuesLocal={rpValuesLocal}
        redLocalAbsolute={redLocalAbsolute}

        wl1ValuesLocal={wl1ValuesLocal}
        yellowLocalAbsolute={yellowLocalAbsolute}

        wl2ValuesLocal={wl2ValuesLocal}
        greenLocalAbsolute={greenLocalAbsolute}

        fcValuesLocal={fcValuesLocal}
        blueLocalAbsolute={blueLocalAbsolute}

        pwpValuesLocal={pwpValuesLocal}
        maroonLocalAbsolute={maroonLocalAbsolute}

        siteAnnotation={siteAnnotation}

      />)}
      <div className="legend-options">
      </div>
    </React.Fragment>
  )
}

function LegendSection({ timePeriod,
  checkList,
  red,
  red2,
  redAbsolute,
  redAutoAbsolute,
  yellow,
  yellow2,
  yellowAbsolute,
  yellowAutoAbsolute,
  green,
  green2,
  greenAbsolute,
  greenAutoAbsolute,
  blueAbsolute,
  blueAutoAbsolute,
  maroonAbsolute,
  maroonAutoAbsolute,
  rpValuesLocal,
  redLocalAbsolute,
  wl1ValuesLocal,
  yellowLocalAbsolute,
  wl2ValuesLocal,
  greenLocalAbsolute,
  fcValuesLocal,
  blueLocalAbsolute,
  pwpValuesLocal,
  maroonLocalAbsolute,
  siteAnnotation,
  setCurrentSensorIndex, sectionLabel, measurements, dataTypesCount, curves, legendPoints, notifySeriesListToggled, notifySeriesToggled }) {

  const [shouldResetDisabledSeries, setShouldResetDisabledSeries] = useState(null);
  const prevSeriesNameList = useRef([]);
  const seriesNameList = useMemo(() => {
    const currentSeriesList = curves.map(series => series.seriesName);
    if (prevSeriesNameList.current.length === currentSeriesList.length) {
      // if same length
      for (let seriesName of currentSeriesList) {
        // if all items are not in prev -> current
        if (!prevSeriesNameList.current.includes(seriesName)) {
          prevSeriesNameList.current = currentSeriesList;
          setShouldResetDisabledSeries(true);
          return currentSeriesList;
        }
      }
      return prevSeriesNameList.current;
    } else {
      prevSeriesNameList.current = currentSeriesList;
      setShouldResetDisabledSeries(true);
      return currentSeriesList;
    }
  }, [curves]);

  const [checked, setChecked] = useState({
    ...Object.fromEntries(seriesNameList.map((series, i) => [series, i === 0 ? true : false])),
    checkedCount: curves.length,
    visibilityToggled: false
  });

  useEffect(() => {
    setChecked(checked => ({
      ...Object.fromEntries(seriesNameList.map((series, i) => [series, i === 0 ? true : false])),
      checkedCount: curves.length,
      visibilityToggled: false
    }));
  }, [curves.length, timePeriod, seriesNameList])

  const shouldDoHardReset = useRef(false);

  const prevMeasurements = useRef(measurements);
  useEffect(() => {
    // disable legend items indicators
    if (shouldResetDisabledSeries) {
      setChecked(chk => {
        // if the measurements are not changed
        // then any items in the legend that was previously disabled should stay disabled
        // unless dataTypes are changed
        if (!shouldDoHardReset.current && measurements === prevMeasurements.current) {
          return {
            ...Object.fromEntries(seriesNameList.map(series => [series, true])),
            ...chk,
            checkedCount: curves.length,
            visibilityToggled: false
          }
        } else {
          shouldDoHardReset.current = false;
          return {
            ...Object.fromEntries(seriesNameList.map(series => [series, true])),
            checkedCount: curves.length,
            visibilityToggled: false
          }
        }
      });
      setShouldResetDisabledSeries(false);
    }
    return () => {
      prevMeasurements.current = measurements;
    }
  }, [shouldResetDisabledSeries, measurements, curves.length, seriesNameList]);


  useEffect(() => {
    const seriesWithYaxis = curves.find(curve => curve.isHidden && curve.hasYAxis);
    if (!seriesWithYaxis)
      return;
    if (checked.visibilityToggled && checked.checkedCount === 1 && checked[seriesWithYaxis.seriesName]) {
      //toggleSeriesVisibility('threshold-graph', seriesWithYaxis.seriesName);
      setChecked(checked => ({
        ...checked,
        [seriesWithYaxis.seriesName]: false,
        checkedCount: 0
      }));
    } else if (checked.checkedCount > 0 && !checked[seriesWithYaxis.seriesName]) {
      // toggleSeriesVisibility('threshold-graph', seriesWithYaxis.seriesName, 'show');
      setChecked(checked => ({
        ...checked,
        [seriesWithYaxis.seriesName]: true,
        checkedCount: ++checked.checkedCount
      }));
    }
  }, [curves, checked]);

  return (
    <React.Fragment>
      <div className="pt-20" style={{ display: 'flex' }}>
        {curves.map((curve, i) => {
          if (curve.seriesName.includes('yaxis'))
            return null;

          // find closest lower value
          let value = null;
          if (curve.data.length > 0) {
            const firstXValue = curve.data[0].x;
            const lastXValue = lastItem(curve.data).x;
            if (firstXValue <= legendPoints.xValue && lastXValue >= legendPoints.xValue) {
              const currentDataPoint = curve.data[legendPoints.dataPointIndex];
              if (currentDataPoint && currentDataPoint.x === legendPoints.xValue) {
                value = currentDataPoint;
              } else {
                value = closestLow(curve.data, legendPoints.xValue);
              }
            }
          }

          let seriesLabel = labelSeries(curve);

          return <LegendItem
            checkList={checkList}
            timePeriod={timePeriod}
            index={i}
            key={curve.seriesName}
            isHovered={legendPoints.seriesIndex === curve.seriesIndex}
            label={seriesLabel}
            value={`${(value && typeof (value.y) === 'number') ? value.y.toFixed(1) : '-- '}${curve.unit}`}
            color={curve.color}
            setSensorIndex={(i) => setCurrentSensorIndex(i)}
            seriesName={curve.seriesName}
            seriesNameList={seriesNameList}
            checked={{ get: checked, set: setChecked }}
            curves={curves}
            notifySeriesToggled={notifySeriesToggled}
            red={red}
            red2={red2}
            redAbsolute={redAbsolute}
            redAutoAbsolute={redAutoAbsolute}
            yellow={yellow}
            yellow2={yellow2}
            yellowAbsolute={yellowAbsolute}
            yellowAutoAbsolute={yellowAutoAbsolute}
            green={green}
            green2={green2}
            greenAbsolute={greenAbsolute}
            greenAutoAbsolute={greenAutoAbsolute}
            blueAbsolute={blueAbsolute}
            blueAutoAbsolute={blueAutoAbsolute}

            maroonAbsolute={maroonAbsolute}
            maroonAutoAbsolute={maroonAutoAbsolute}

            rpValuesLocal={rpValuesLocal}
            redLocalAbsolute={redLocalAbsolute}

            wl1ValuesLocal={wl1ValuesLocal}
            yellowLocalAbsolute={yellowLocalAbsolute}

            wl2ValuesLocal={wl2ValuesLocal}
            greenLocalAbsolute={greenLocalAbsolute}

            fcValuesLocal={fcValuesLocal}
            blueLocalAbsolute={blueLocalAbsolute}

            pwpValuesLocal={pwpValuesLocal}
            maroonLocalAbsolute={maroonLocalAbsolute}

            siteAnnotation={siteAnnotation}
          />
        })}
      </div>
    </React.Fragment>
  )
}


function LegendItem({ timePeriod,
  checkList,
  red,
  red2,
  redAbsolute,
  redAutoAbsolute,
  yellow,
  yellow2,
  yellowAbsolute,
  yellowAutoAbsolute,
  green,
  green2,
  greenAbsolute,
  greenAutoAbsolute,
  blueAbsolute,
  blueAutoAbsolute,
  maroonAbsolute,
  maroonAutoAbsolute,
  rpValuesLocal,
  redLocalAbsolute,
  wl1ValuesLocal,
  yellowLocalAbsolute,
  wl2ValuesLocal,
  greenLocalAbsolute,
  fcValuesLocal,
  blueLocalAbsolute,
  pwpValuesLocal,
  maroonLocalAbsolute,
  siteAnnotation,
  curves, seriesNameList, setSensorIndex, index, label, value, color: background, isHovered, seriesName, checked, notifySeriesToggled }) {

  const localSensorCount = siteAnnotation?.settings?.localSensorCount?.value || 0;
  const hasTopSensor = localSensorCount > 0;

  function handleSeriesVisibility(i) {

    if (hasTopSensor && i < localSensorCount) {

      let annotateY = [
        {
          y: blueLocalAbsolute[i],
          borderColor: "#4472C4",
          id: "fcChecked",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#4472C4",
            style: {
              color: "#fff",
              background: "#4472C4",
              width: '50px',
            },
            text:
              `A: ${blueLocalAbsolute[i].toString()} R: 100 `
          }
        },
        {
          y: greenLocalAbsolute[i],
          id: "wl2Checked",
          borderColor: "#B4C7E7",// light blueAbsolute color(green in table should be light blueAbsolute as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#B4C7E7",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#B4C7E7"
            },
            text: `A: ${greenLocalAbsolute[i]}
                   R: ${Object.values(wl2ValuesLocal[i])[0]}`
          }
        },
        {
          y: yellowLocalAbsolute[i],
          id: "wl1Checked",
          borderColor: "#70AD47", // green color(yellow in table should be green as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#70AD47",
            style: {
              color: "#fff",
              background: "#70AD47"
            },
            text: `A: ${yellowLocalAbsolute[i]}
                   R: ${Object.values(wl1ValuesLocal[i])[0]}`
          }
        },
        {
          y: redLocalAbsolute[i],
          id: "rpChecked",
          borderColor: "#FF0000", //red color
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#FF0000",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#FF0000"
            },
            text: `A: ${redLocalAbsolute[i]}
                   R: ${Object.values(rpValuesLocal[i])[0]}`
          }
        },
        {
          y: maroonLocalAbsolute[i],
          id: "pwpChecked",
          borderColor: "#A50021",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#A50021",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#A50021"
            },
            text: `A: ${maroonLocalAbsolute[i].toString()} R: 0`
          }
        },

      ];
      setSensorIndex(i);
      const c = checked.get;
      const properties = Object.keys(c);
      const series1 = properties.filter(i => (i.indexOf('Raw') !== -1));
      const series = series1.filter(i => (i.indexOf(seriesName) === -1));
      series.map(s => {
        return hideSeries("threshold-graph", 'hideSeries', s);
      });
      // showSeries("threshold-graph", 'showSeries', seriesName);
      toggleSeriesVisibility('threshold-graph', seriesName, 'show');
      if (!c[seriesName]) {
        clearAnnotations('threshold-graph');
        annotateY.map((i) => {
          return addYaxisAnnotation('threshold-graph', i);
        })
      } else {
        hideSeries("threshold-graph", 'hideSeries', seriesName);
        clearAnnotations('threshold-graph');
      }
    } else {
      let redList;
      let yellowList;
      let greenList;
      let blueList;
      let maroonList;

      let redRelative = red;
      if (checkList["rpChecked"] && redAbsolute?.length > 0) {
        redList = redAbsolute;
      } else if (checkList["rpChecked"] === undefined && redAbsolute?.length > 0) {
        redList = redAbsolute;
      } else if (!checkList["rpChecked"] && redAutoAbsolute?.length > 0) {
        redList = redAutoAbsolute;
        redRelative = red2;
      } else if (!checkList["rpChecked"] && !redAutoAbsolute) {
        redList = redAbsolute;
      }

      let yellowRelative = yellow;
      if (checkList["wl1Checked"] && yellowAbsolute?.length > 0) {
        yellowList = yellowAbsolute;
      } else if (checkList["wl1Checked"] === undefined && yellowAbsolute?.length > 0) {
        yellowList = yellowAbsolute;
      } else if (!checkList["wl1Checked"] && yellowAutoAbsolute?.length > 0) {
        yellowList = yellowAutoAbsolute;
        yellowRelative = yellow2;
      } else if (!checkList["wl1Checked"] && !yellowAutoAbsolute) {
        yellowList = yellowAbsolute;
      }

      let greenRelative = green;
      if (checkList["wl2Checked"] && greenAbsolute?.length > 0) {
        greenList = greenAbsolute;
      } else if (checkList["wl2Checked"] === undefined && greenAbsolute?.length > 0) {
        greenList = greenAbsolute;
      } else if (!checkList["wl2Checked"] && greenAutoAbsolute?.length > 0) {
        greenList = greenAutoAbsolute;
        greenRelative = green2;
      } else if (!checkList["wl2Checked"] && !greenAutoAbsolute) {
        greenList = greenAbsolute;

      }

      if (checkList["fcChecked"] && blueAbsolute?.length > 0) {
        blueList = blueAbsolute;
      } else if (checkList["fcChecked"] === undefined && blueAbsolute?.length > 0) {
        blueList = blueAbsolute;
      } else if (!checkList["fcChecked"] && blueAutoAbsolute?.length > 0) {
        blueList = blueAutoAbsolute;
      } else if (!checkList["fcChecked"] && blueAutoAbsolute.length === 0) {
        blueList = blueAbsolute;

      }

      if (checkList["pwpChecked"] && maroonAbsolute?.length > 0) {
        maroonList = maroonAbsolute;
      } else if (checkList["pwpChecked"] === undefined && maroonAbsolute?.length > 0) {
        maroonList = maroonAbsolute;
      } else if (!checkList["pwpChecked"] && maroonAutoAbsolute?.length > 0) {
        maroonList = maroonAutoAbsolute;
      } else if (!checkList["pwpChecked"] && maroonAutoAbsolute.length === 0) {
        maroonList = maroonAbsolute;

      }


      let annotateY = [];

      let r, y, g, b, m;
      let index = i - localSensorCount;

      if (maroonList?.length > 0) {
        m = {
          y: maroonList[index],
          id: "pwpChecked",
          borderColor: "#A50021",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#A50021",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#A50021"
            },
            text: `A: ${maroonList[index].toString()} R: 0`
          }
        }
        annotateY.push(m);
      }

      if (blueList?.length > 0) {
        b = {
          y: blueList[index],
          id: "fcChecked",
          borderColor: "#4472C4",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#4472C4",
            style: {
              color: "#fff",
              background: "#4472C4",
              width: '50px'
            },
            text: `A: ${blueList[index].toString()} R: 100 `

          }
        };
        annotateY.push(b);
      }

      if (redList?.length > 0) {
        r = {
          y: redList[index],
          id: "rpChecked",
          borderColor: "#FF0000", //rpValues color
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#FF0000",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#FF0000",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${redList[index].toString()}
                   R: ${Object.values(redRelative[index])[0]} `
          }
        };
        annotateY.push(r);
      };

      if (yellowList?.length > 0) {
        y = {
          y: yellowList[index],
          id: "wl1Checked",
          borderColor: "#70AD47", // green color(yellow in table should be green as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#70AD47",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#70AD47",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${yellowList[index].toString()}
                   R: ${Object.values(yellowRelative[index])[0]} `
          }
        };
        annotateY.push(y);
      }

      if (greenList?.length > 0) {
        g = {
          y: greenList[index],
          id: "wl2Checked",
          borderColor: "#B4C7E7",// light blue color(green in table should be light blue as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#B4C7E7",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#B4C7E7",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${greenList[index].toString()}
                   R: ${Object.values(greenRelative[index])[0]}`
          }
        };
        annotateY.push(g);
      }

      setSensorIndex(i);
      const c = checked.get;
      const properties = Object.keys(c);
      const series1 = properties.filter(i => (i.indexOf('Raw') !== -1));
      const series = series1.filter(i => (i.indexOf(seriesName) === -1));

      series.map(s => {
        return hideSeries("threshold-graph", 'hideSeries', s);
      });
      //showSeries("threshold-graph", 'showSeries', seriesName);
      toggleSeriesVisibility('threshold-graph', seriesName, 'show');

      if (!c[seriesName] && annotateY.length > 0) {
        clearAnnotations('threshold-graph');
        annotateY.map((i) => {
          return addYaxisAnnotation('threshold-graph', i);
        })
      } else {
        hideSeries("threshold-graph", 'hideSeries', seriesName);
        clearAnnotations('threshold-graph');
      }

    }

    checked.set(checkedObjectMap => {
      if (checkedObjectMap[seriesName]) {
        return {
          ...checkedObjectMap,
          [seriesName]: false,
          checkedCount: checkedObjectMap.checkedCount - 1,
          visibilityToggled: true
        };
      }

      return {
        ...Object.fromEntries(seriesNameList.map(series => [series, false])),
        [seriesName]: true,
        checkedCount: curves.length,
        visibilityToggled: false
      };
    });
  }

  return (
    <div onClick={() => handleSeriesVisibility(index)} style={{ padding: '10px' }}
      className={`moisture-content ${checked.get[seriesName] ? "ticked" : "unticked"} d-flex justify-content-between`}>
      <div className="d-flex">
        {/* <span className="icon-done-24px" ></span> */}
        <div className="d-flex justify-content-between">
          <div className={`notification-check${checked.get[seriesName] ? ' notification-checked' : ''} `} style={{ background }}>
            {checked.get[seriesName] ? <span className="icon-done-24px"></span> : ""}
          </div>
        </div>
      </div >
    </div >
  )
}

export default function TemplateGraphLegend(props) {

  const curvesToRender = useMemo(() => {
    const newCurvesToRender = props.curvesToRender.filter((each) => !each.meta?.shortName.includes("RAIN") && !each.meta?.shortName.includes("IRR"));
    return newCurvesToRender.map((curve, seriesIndex) => ({
      color: curve.color,
      name: curve.meta.measurement,
      depth: curve.meta.depth && curve.meta.depth.depth,
      depthName: curve.meta.depth && curve.meta.depth.name,
      data: curve.data.data,
      dataType: curve.meta.dataType,
      unit: curve.meta.unit,
      hasYAxis: curve.meta.hasYAxis,
      isHidden: curve.meta.isHidden,
      seriesName: curve.data.name,
      seriesIndex,
      showTopSensorLabel: curve.meta.showTopSensorLabel
    }))
  }, [props.curvesToRender]);

  // function resetSeriesVisibility() {
  //   toggleSectionVisibility('threshold-graph', curvesToRender.map(c => c.seriesName), 'show');
  // }

  return (
    <div className="graph-legend-container ">
      <div className="legend-sets">
        <SiteLegend
          timePeriod={props.timePeriod}
          passCurrentSensorIndex={(i) => props.updateCurrentSensorIndex(i)}
          siteName="This Site ID (122KL)"
          siteId={props.siteId}
          legendPoints={props.legendPoints}
          curvesToRender={curvesToRender}
          legendControls={props.legendControls}
          dataTypesCount={props.dataTypesCount}
          notifySeriesToggled={props.notifySeriesToggled}
          notifySeriesListToggled={props.notifySeriesListToggled}
          checkList={props.checkList}
          red={props.red}
          redAbsolute={props.redAbsolute}
          red2={props.red2}
          redAutoAbsolute={props.redAutoAbsolute}

          yellow={props.yellow}
          yellowAbsolute={props.yellowAbsolute}
          yellow2={props.yellow2}
          yellowAutoAbsolute={props.yellowAutoAbsolute}

          green={props.green}
          greenAbsolute={props.greenAbsolute}
          green2={props.green2}
          greenAutoAbsolute={props.greenAutoAbsolute}

          blueAbsolute={props.blueAbsolute}
          blueAutoAbsolute={props.blueAutoAbsolute}

          maroonAbsolute={props.maroonAbsolute}
          maroonAutoAbsolute={props.maroonAutoAbsolute}
          rpValuesLocal={props.rpValuesLocal}
          redLocalAbsolute={props.redLocalAbsolute}
          wl1ValuesLocal={props.wl1ValuesLocal}
          yellowLocalAbsolute={props.yellowLocalAbsolute}
          wl2ValuesLocal={props.wl2ValuesLocal}
          greenLocalAbsolute={props.greenLocalAbsolute}
          fcValuesLocal={props.fcValuesLocal}
          blueLocalAbsolute={props.blueLocalAbsolute}
          pwpValuesLocal={props.pwpValuesLocal}
          maroonLocalAbsolute={props.maroonLocalAbsolute}
          siteAnnotation={props.siteAnnotation}
        />
      </div>
    </div>
  );
}
