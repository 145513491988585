import React from 'react';
import Search from '../common/search';


export default function SearchFilter({searchPlaceholder, handleSearch}) {
    return (
        <div className="searchfilter d-flex justify-content-between">
            <div className="search-area">
                <Search placeholder={searchPlaceholder} onSearch={handleSearch} />
            </div>
        </div>
    );
}