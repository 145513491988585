import http from "../../../../common/duck/httpService";
import logger from "../../../../common/duck/logService";

const templateAPI = "/templates";
function getCropTemplate(params) {
  return http
    .get(templateAPI, { params })
    .then(response => response.data.data);
}

function getTemplateData(siteid, templateid, plantingdate) {
  return http
    .get(`/sites/${siteid}/templates/${templateid}`, {
      params: { plantingdate },
    })
    .then((response) => response.data.data);
}

function putTemplateData(siteObjectId, payload) {
  return http
    .patch(`/site/${siteObjectId}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
}

function getTemplateid(siteObjectId) {
  return http.get(`/site/${siteObjectId}`).then((response) => response.data);
}

function createTemplate(templateData) {
  return http.post(templateAPI, templateData).then(response => response.data.data);
}

function getRawTemplate(templateId) {
  return http.get(`${templateAPI}/${templateId}`).then(response => response.data.data);
}

function editTemplate(templateId, updatedData) {
  return http.patch(`${templateAPI}/${templateId}`, updatedData).then(response => response.data.data);
}

function deleteTemplate(templateId) {
  return http.delete(`${templateAPI}/${templateId}`)
}

export default {
  getCropTemplate,
  getTemplateData,
  putTemplateData,
  getTemplateid,
  getRawTemplate,
  createTemplate,
  editTemplate,
  deleteTemplate
};
