import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import FarmSubscriptionsHead from './farmSubscriptionsHead';
import FarmSubscriptionsItem from './farmSubscriptionsItem';
import siteService from '../../sites/duck/siteService';
import InfoDialogue from '../../group/InvitationModal/inviteDone';
import subscriptionService from '../subscriptions/duck/subscriptionService';
import Confirm, { useConfirm } from '../../common/confirm';
import * as moment from 'moment';

function FarmSubscriptions({
    name,
    farmId,
    search,
    parent,
    sources,
    openDoneModal,
    onLastFarmIsRendered,
    matchedSitesCount,
    setMatchedSitesCount,
    activeNewSubscription,
    setActiveNewSubscriptions,
    updatedSites,
    selectedDelete, 
    setSelectedDelete 
}) {
    const [sites, setSites] = useState(null);
    const [dialogueBoxSate, setDialogueBoxState] = useState({ show: false, data: { title: '', message: '' } });
    const { confirmBoxSate, askConfirmation } = useConfirm();
    // const [addCardShow, setAddCardShow] = useState(false);
    const [showPayInfoModal, setShowPayInfoModal] = useState(false)
    const [addCommentModal, setAddCommentModal] = useState(false)
    const [currentSite, setCurrentSite] = useState(null);
    const [subscriptionComments, setSubscriptionComments] = useState([])
    const [newComment, setNewComment] = useState(null)
    const [loading, setLoading] = useState(false);

    function showDialogue(title, message) {
        setDialogueBoxState({ show: true, data: { title, message } });
    }

    function closeDialogue() {
        setDialogueBoxState(prev => ({ ...prev, show: false }));
    }

    function addNewComment(event) {
        const comment = event.target.value;
        setNewComment(comment);
    }

    function saveNewComment() {
        setLoading(true);
        const subscriptionData = [{
            siteId: currentSite?.siteId,
            oldPlan: currentSite?.planDetails?.plan,
            comments: newComment
        }]
        siteService.activateSubscriptionsOrComments(subscriptionData)
            .then(data => {
                setAddCommentModal(false)
                setLoading(false)
            }
            )
            .catch()
            .then();

    }

    function viewSiteComments(siteId) {
        setLoading(true);
        subscriptionService.getComments(siteId)
            .then(data => {
                setSubscriptionComments(data?.data)
                setLoading(false);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        siteService.getSiteDetailsWithSubscriptions

            (farmId, false)
            .then(sites => setSites(sites.data))
            .catch(error => console.log(error));
    }, [farmId]);

    const matchedSites = sites && sites.filter(site => {
        return (
        site?.siteId?.startsWith(search) ||
        site?.name?.toLowerCase().includes(search?.toLowerCase()) ||
        site?.dealerName?.toLowerCase().includes(search?.toLowerCase()))}
    );

    useEffect(() => {
        if (search && sites && onLastFarmIsRendered) {
            onLastFarmIsRendered();
        }
    })

    if (search && sites && matchedSites.length === 0) {
        return null;
    }

    if (sites === null) {
        return (
            <React.Fragment>
                <h6>{name}</h6>
                <Spinner animation="grow" />
            </React.Fragment>
        )
    }

    if (sites && sites.length === 0) {
        return (
            <React.Fragment>
                <h6>{name}</h6>
                <small>This farm has no sites</small>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <h6>{name}</h6>
            <Table striped bordered hover responsive="sm">
                <FarmSubscriptionsHead />
                <tbody>
                    {matchedSites.map(site => <FarmSubscriptionsItem
                        key={site.siteId}
                        siteId={site.siteId}
                        objectId={site.id}
                        name={site.name}
                        subscription={
                            site.planDetails?.plan
                        }
                        viewSiteComments={() => viewSiteComments(site.siteId)}
                        subscriptionHistory={() => setCurrentSite(site)}
                        openDoneModal={openDoneModal}
                        parent={parent}
                        showDialogue={showDialogue}
                        askConfirmation={askConfirmation}
                        showPaymentModal={() => setShowPayInfoModal(true)}
                        showAddCommentModal={() => setAddCommentModal(true)}
                        activeNewSubscription={activeNewSubscription}
                        setActiveNewSubscriptions={setActiveNewSubscriptions}
                        selectedDelete = {selectedDelete}
                        setSelectedDelete = {setSelectedDelete}
                        isDeleted={site.deleted}
                    />)}
                </tbody>
            </Table>
            <InfoDialogue
                show={dialogueBoxSate.show}
                onClose={closeDialogue}
                buttonLabel="OK"
                data={dialogueBoxSate.data} />
            <Confirm
                show={confirmBoxSate.show}
                title={confirmBoxSate.data.title}
                message={confirmBoxSate.data.message}
                onYes={confirmBoxSate.onYes}
                onNo={confirmBoxSate.onNo} />
            <Modal
                show={showPayInfoModal}
                centered
                onHide={() => setShowPayInfoModal(false)}
                className="checkout-modal checkout2 comment-modal"
            >
                <Modal.Header className="d-flex justify-content-between">
                    <p>Previous Comments</p>
                    <p className="cp" onClick={() => { setShowPayInfoModal(false); setLoading(false) }}>Close</p>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <Spinner style={{ display: 'block', margin: 'auto' }} animation="grow" /> :
                        <div>
                            {subscriptionComments?.length === 0 && <p style={{ textAlign: "center" }}>No Comments Available</p>}

                            <div className="comments-list">{subscriptionComments?.map((data, i) => {
                                let stillUtc = data.modifiedAt && data.modifiedAt !== null ? moment.utc(data.modifiedAt).toDate() : null;
                                let local = stillUtc !== null ? moment(stillUtc).local().format("MMM DD,YYYY-hh:mmA") : '';
                                return (<p>{data.comments} <div className="d-flex justify-content-between"><span>{data.modifiedBy}</span><span>{local}</span></div></p>)
                            })}</div>
                        </div>}
                </Modal.Body>
            </Modal>
            <Modal
                show={addCommentModal}
                centered
                onHide={() => setAddCommentModal(false)}
                className="checkout-modal checkout2 comment-modal add-comment-modal"
            >
                <Modal.Header className="d-flex justify-content-between">
                    <p>Add a New Comment</p>
                    <p className="cp" onClick={() => { setAddCommentModal(false); setLoading(false) }}>Close</p>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-between">
                    <div className="comment-section"><textarea onChange={addNewComment}></textarea></div>
                    <div style={{ display: 'flex' }}> {loading ? <Spinner animation="grow" /> : null} <p className="comments-save" onClick={saveNewComment}>Save</p></div>

                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = state => {

    const customer = state.userDetails.profileDetails.profile?.stripeCustomerDetails;

    if (!customer) {
        return { sources: null }
    }

    return { sources: customer.sources?.data };
}

export default connect(mapStateToProps)(FarmSubscriptions);