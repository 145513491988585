import React from 'react';
import { Switch, Route } from 'react-router';
import EditGroup from './editGroup';
import GroupList from './groupList';
import GroupViewTeam from './groupViewTeam';



function GroupBody(props) {
    return (
        <div>
            <Switch>
                <Route path={`${props.match.path}/edit`} component={EditGroup} />
                <Route path={`${props.match.path}/:orgId`} component={GroupViewTeam} />
                <Route path={props.match.path} component={GroupList} />
            </Switch>
        </div>

    );
}


export default GroupBody;
