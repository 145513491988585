import React, {useState} from 'react';
import {connect} from 'react-redux';
import Confirm, {useConfirm} from '../../../common/confirm';
import TemplateModal from './templateModal';
import {SaveTemplateModalPresentation} from '../template/saveTemplateModal';

function TemplateSettingModal({
    userid,
    cropType,
    appliedTemplate,
    applyTemplate,
    // fromDate,
    siteObjectId,
    // formData,
    templateList,
    setTemplateList,
    savePreference = false,
    siteDetails,
    setIsDataLoading,
}) {

    const [show, setShow] = useState(false);
    const [editedTemplate, setEditedTemplate] = useState(null);
    // const [templateList, setTemplateList] = useState(null);
    const closeTemplateEditModal = () => {
        setShow(false);
        setEditedTemplate(null);
    };
    const openTemplateEditModal = (template) => {
        setShow(true);
        setEditedTemplate(template);
    };
    const {confirmBoxSate, askConfirmation} = useConfirm();

    function editTemplateLocal(updatedTemplate) {
        setTemplateList(templatesListData => ({
            loaded: templatesListData.loaded,
            list: templatesListData.list.map(template => {
                if (template.objectId === updatedTemplate.objectId) {
                    return updatedTemplate;
                }
                return template;
            })
        }))
    }

    return (
        <React.Fragment>
            <TemplateModal
                cropType={cropType}
                appliedTemplate={appliedTemplate}
                applyTemplate={applyTemplate}
                templateList={templateList}
                setTemplateList={setTemplateList}
                savePreference={savePreference}
                // dateParams={fromDate}
                siteObjectId={siteObjectId}
                hideModal={show}
                openTemplateEditModal={openTemplateEditModal}
                siteDetails={siteDetails}
                askConfirmation={askConfirmation} 
                setIsDataLoading={setIsDataLoading}/>       
            <SaveTemplateModalPresentation
                key="TEMP:SETTINGS"
                userid={userid}
                show={show}
                handleShow={openTemplateEditModal}
                handleClose={closeTemplateEditModal}
                patchOnTemplate={editedTemplate}
                onTemplateEdited={editTemplateLocal}
                cropType={cropType} />
            <Confirm
                show={confirmBoxSate.show}
                title={confirmBoxSate.data.title}
                message={confirmBoxSate.data.message}
                onYes={confirmBoxSate.onYes}
                onNo={confirmBoxSate.onNo} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    userid: state.userDetails.profileDetails.profile.objectId
});

export default connect(mapStateToProps)(TemplateSettingModal)