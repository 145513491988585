const LINE_TYPES = {
    dotted: 3,
    dashed: 10,
    solid: 0
}

function series(type, name, data, checkedIndex = null, color, style, tooltip, meta, dashArray) {
    return {
        color,
        checkedIndex,
        dashArray: dashArray || LINE_TYPES[style],
        data: {
            name,
            type,
            data
        },
        tooltip,
        meta
    }
}

export function Line(name, data, { color = '#000000', checkedIndex = null, style = 'solid', tooltip = true, dashArray, meta } = {}) {
    return series('line', name, data, checkedIndex, color, style, tooltip, meta, dashArray);
}

export function Area(name, data, { color = '#000000', checkedIndex = null, style = 'solid', tooltip = true, meta } = {}) {
    return series('area', name, data, checkedIndex, color, style, tooltip, meta)
}

export function StackedData(...data) {
    return {
        data,
        stacked: true
    }
}

export function UnstackedData(...data) {
    return {
        data,
        stacked: false
    }
}