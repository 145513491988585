/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// import selectZoom from '../../../assets/images/zoom/zoom1.png';
import xzoom from '../../../assets/images/zoom/xzoom.png';
const coordsLike = {
    x: PropTypes.string,
    y: PropTypes.string
}

class Chart extends Component {

    static propTypes = {
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        lineWidth: PropTypes.number,
        series: PropTypes.array.isRequired,
        stacked: PropTypes.bool,
        tooltip: PropTypes.bool,
        legend: PropTypes.bool,
        axisLabels: PropTypes.shape(coordsLike),
        xaxisType: PropTypes.oneOf(['category', 'datetime']),
        zoomType: PropTypes.oneOf(['x', 'y', 'xy']),
        xcategories: PropTypes.array,
        yAxis: PropTypes.array,
        yAxisTooltipFormat: PropTypes.func,
        seriesNameFormat: PropTypes.func,
        customTooltip: PropTypes.func,
        annotations: PropTypes.array,
        yAxisAnnotation:PropTypes.array,
    }

    static defaultProps = {
        tooltip: true,
        lineWidth: 2,
        width: '100%',
        legend: false,
        stacked: false,
        xaxisType: 'datetime',
        zoomType: 'x',
        annotations : [],
        yAxisAnnotation:[],
        pointAnnotation: [],
        showContextmenu: false,
    }

    tools = {
        download: false,
        selection: false,
        zoomin: this.props.isGraphThumbnail ? '' : `<img src='${xzoom}' width="28">`,
        zoomout: false,
        zoom: this.props.isGraphThumbnail ? '' : '<i class="fas fa-crosshairs"></i>',
        pan: this.props.isGraphThumbnail ? '' : true,
        reset: false,
    }
    // '<i class="fa fa-search-plus" aria-hidden="true"></i>'
    // '<i class="fa fa-search-minus" aria-hidden="true"></i>'

    state = {
        options: {
            chart: {
                id: this.props.id,
                stacked: this.props.stacked,
                stackType: '100%',
                toolbar: {
                    show: this.props.hideToolBar ? false : true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: this.tools,
                    autoSelected: 'zoom'
                },
                animations: {
                    enabled: false
                },
                markers: {
                    size: 0
                },
            },
            xaxis: {
                labels: {
                    datetimeUTC: false,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'd MMM',
                        hour: 'HH:mm',
                    },
                    hideOverlappingLabels: true,
                },
                crosshairs: {
                    show: true,
                    position: 'front',
                    stroke: {
                        color: '#b6b6b6',
                        width: 1,
                        dashArray: 4,
                    }
                },
                tooltip: {
                    enabled: true,
                },
            },             
            yaxis: {
                // max: 100,
                labels: {
                  style: {
                    fontSize: '16px',
                },
            },
            },
            stroke: {
                curve: 'smooth',
                width: this.props.lineWidth,
            },
            tooltip: {
                enabled: this.props.tooltip
            },
            legend: {
                show: this.props.legend
            },
            annotations: {
                xaxis: this.props.annotations,
                yaxis: this.props.yAxisAnnotation,
                points: this.props.pointAnnotation,
            }
        }
    }

    constructor(props) {
        super(props);
        const series = props.series.data || props.series;
        this.state.series = series.map(item => item.data);
        if (series.length > 0) {
            this.state.options.colors = series.map(item => item.color);
            this.state.options.stroke.dashArray = series.map(item => item.dashArray);
            this.state.options.tooltip.enabledOnSeries = series.map((item, seriesIndex) => item.tooltip && seriesIndex).filter(Number.isInteger);
        }
        if (props.axisLabels) {
            this.state.options.xaxis.title = { text: this.props.axisLabels.x };
            this.state.options.yaxis.title = { text: this.props.axisLabels.y };
        }
        if (props.yAxis && props.yAxis.length > 0) {
            const injectedFont = []
            props.yAxis && props.yAxis.length > 0 && props.yAxis.map((axis) => {
                axis.labels  = {style: {fontSize: this.props.isGraphThumbnail ? '11px' :'16px'}}
                // if(this.props.isGraphThumbnail){
                //     axis.forceNiceScale = true
                // }
                injectedFont.push(axis)
            })
            this.state.options.yaxis = props.yAxis;
        }
        if (props.xcategories) {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                type: 'category',
                categories: props.xcategories,
            };
        } else {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                type: props.xaxisType,
            }
        }
        if (props.yAxisTooltipFormat) {
            this.state.options.tooltip.y = { formatter: props.yAxisTooltipFormat }
        }
        if (props.seriesNameFormat) {
            this.state.options.tooltip.y = {
                ...this.state.options.tooltip.y,
                title: { formatter: props.seriesNameFormat }
            }
        }
        if (props.zoomType) {
            this.state.options.chart.zoom = {
                type: props.zoomType
            }
        }
        if (props.customTooltip) {
            this.state.options.tooltip.custom = props.customTooltip;
            this.state.options.tooltip.shared = true;
        }
        if (props.xAxisLabel) {
            this.state.options.xaxis = {
                ...this.state.options.xaxis,
                tooltip: {
                    formatter: props.xAxisLabel
                }
            }
        }
        if(this.props.annotations.length){
            this.state.options.annotations.xaxis = this.props.annotations;
        }
        if (this.props.tools) {
            this.state.options.chart.toolbar.tools.customIcons = this.props.tools;
        } else if (this.props.tools === false) {
            this.state.options.chart.toolbar.show = false;
        }
        props.series.stacked !== undefined && (this.state.options.chart.stacked = props.series.stacked);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.series !== this.props.series ||
            prevProps.axisLabels !== this.props.axisLabels ||
            prevProps.xcategories !== this.props.xcategories ||
            prevProps.yAxis !== this.props.yAxis ||
            prevProps.seriesNameFormat !== this.props.seriesNameFormat ||
            prevProps.tools !== this.props.tools || prevProps.annotations !== this.props.annotations ||
            prevProps.showContextmenu !== this.props.showContextmenu) {
            let changes = {};
            changes.options = { ...this.state.options };
            if (prevProps.series !== this.props.series) {
                let seriesData = this.props?.series?.data || this.props.series;
                changes.series = seriesData?.map(item => item.data);
                changes.options.colors = seriesData?.map(item => item.color);
                changes.options.stroke.dashArray = seriesData?.map(item => item.dashArray);
                changes.options.stroke.width = seriesData?.map(item => item.meta?.lineWidth ? item.meta.lineWidth : 2);
                changes.options.tooltip.enabledOnSeries = seriesData?.map((item, seriesIndex) => item.tooltip && seriesIndex).filter(Number.isInteger);
            }
            if (prevProps.axisLabels !== this.props.axisLabels || prevProps.xcategories !== this.props.xcategories) {
                if (this.props.axisLabels && prevProps.axisLabels !== this.props.axisLabels) {
                    changes.options.xaxis.title.text = this.props.axisLabels.x;
                    changes.options.yaxis.title.text = this.props.axisLabels.y;
                }
                if (this.props.xcategories && (prevProps.xcategories !== this.props.xcategories)) {
                    changes.options.xaxis.categories = this.props.xcategories;
                }
            }
            if (prevProps.yAxis !== this.props.yAxis) {
                const injectedFont = []
                this.props.yAxis && this.props.yAxis.length > 0 && this.props.yAxis.map((axis) => {
                    axis.labels  = {style: {fontSize: this.props.isGraphThumbnail ? '11px' :'16px'}}
                    // if(this.props.isGraphThumbnail){
                    //     axis.forceNiceScale = true
                    // }
                    injectedFont.push(axis)
                })
                changes.options.yaxis = this.props.yAxis;
            }
            if (prevProps.annotations !== this.props.annotations) {
                changes.options.annotations.xaxis = this.props.annotations;
                changes.options.annotations.yaxis = this.props.yAxisAnnotation;
                changes.options.annotations.points = this.props.pointAnnotation;
            }
            if (prevProps.seriesNameFormat !== this.props.seriesNameFormat) {
                changes.options.tooltip.y.title = { formatter: this.props.seriesNameFormat }
            }
            if (prevProps.tools !== this.props.tools) {
                if (this.props.tools) {
                    changes.options.chart.toolbar.show = true;
                    changes.options.chart.toolbar.tools.customIcons = this.props.tools;
                } else {
                    changes.options.chart.toolbar.show = false;
                }
            }
            if (!this.props.showContextmenu && this.state.showMenu) {
                changes.showMenu = false; 
            }
            this.setState(changes);
            
        }
    }

    handleClick = (e) => {
        if (this.state.showMenu) this.setState({ showMenu: false });
        if (e.target.tagName === "image") { // https://github.com/apexcharts/apexcharts.js/issues/1927
           const selectedNode = e.target; 
           const imageNodes = document.getElementsByTagName("image");
           let indexOfSelectedAnnotation = Array.from(imageNodes).findIndex(each => each.id === selectedNode.id);
           if (indexOfSelectedAnnotation !== -1) {
               this.props.showSelectedNote(indexOfSelectedAnnotation);
           } 
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault();
        if (!this.props.showContextmenu) return;
        this.setState({
            xPos: `${e.layerX}px`,
            yPos: `${e.layerY}px`,
            showMenu: true,
        });
    }

    componentDidMount() {
        const reactApexChartNode = document.getElementById('ReactApexChart');
        reactApexChartNode.addEventListener("click", this.handleClick);
        reactApexChartNode.addEventListener("contextmenu", this.handleContextMenu);
    }

    componentWillUnmount() {
        const reactApexChartNode = document.getElementById('ReactApexChart');
        reactApexChartNode.removeEventListener("click", this.handleClick);
        reactApexChartNode.removeEventListener("contextmenu", this.handleContextMenu);
    }

    handleAddNoteClick = () => {
        this.setState({ showMenu: false });
        this.props.showAddNote();
    }

    render() {
        return (<>
            <ReactApexChart
                keys={this.props.keys}
                options={this.state.options}
                series={this.state.series}
                width={this.props.width}
                height="100%"
                id="ReactApexChart"
            // height={this.props.height}
            />
            {
                this.state.showMenu && this.props.showContextmenu ?
                (<div style={{ position: 'absolute', top: this.state.yPos, left: this.state.xPos }} className="contextMenu">
                    <div className="contextMenu--option" onClick={(e) => this.handleAddNoteClick()}>
                       <i className="fa fa-plus" aria-hidden="true"></i> 
                       <span style={{ marginLeft: "5px" }}>Add Note</span>
                    </div>
                </div>) : ""
            }
        </>)
    }

}

export default Chart;
