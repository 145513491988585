import React from "react";
import { connect } from "react-redux";
import { Card, Form } from "react-bootstrap";
import userService from "../duck/userService";
import userAction from '../duck/userActions';
import Joi from "joi-browser";
import { default as GForm } from "../../common/form";
import farmsActions from "../../farm/duck/farmActions";

class EditDefaultLanding extends GForm {
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
        this.props.getFarms();
        userService.getPreferences().then(response => {

            const { defaultLanding: data, selectedFarm } = response.data.preference
            this.setState({
                data,
                selectedFarm: (selectedFarm) ? selectedFarm.objectId : null
            });
        })
    }


    state = {
        data: {
            allFarms: false,
            myFarms: false,
            specificFarm: true,
            lastFarmVisited: false
        },

        selectedFarm: {},
        errors: {}
    };
    schema = {
        selectedFarm: Joi.any(),
    };

    handlePreference = ({ target }) => {
        const { value } = target;
        const { data } = this.state
        const preferred = { ...data };
        for (let key in preferred) {
            preferred[key] = false;
        }
        preferred[value] = true;
        this.setState({
            data: preferred
        });


        userService.updatePreferences({ defaultLanding: preferred })
            .then(response => this.props.updateDefaultLanding(response.data));
    };
    handleOptionChange = (name, value) => {

        this.setState(
            { selectedFarm: value });
        userService.updatePreferences({
            selectedFarm: value
        }).then(response => this.props.updateDefaultLanding(response.data));
    };

    componentWillUnmount() {
        this._isMounted = false;

        this.props.clearFarmsData();
    }

    render() {
        const { data } = this.state;
        const formOptions =
            this.props.farms.data &&
            this.props.farms.data.map(({ name, objectId }) => ({
                name,
                _id: objectId
            }));
        return (
            <div className="default-landing mt-15">
                <Card>
                    <Card.Body>
                        <div className="card-header-text d-flex justify-content-between">
                            <p className="header-text">Default Landing</p>
                        </div>
                        <hr></hr>

                        <div className="card-body-text">
                            <Form onChange={this.handlePreference}>
                                <Form.Group>
                                    <Form.Label>
                                        After logging in, the default landing page is:
                                    </Form.Label>
                                </Form.Group>
                                <div className="row radio-buttons">
                                    <div className="col-md-4 col-6">
                                        <div className="radio_tick">
                                            {this.renderRadioInput(
                                                "Last Farm Visited",
                                                "defaultLanding",
                                                "lastFarmVisited",

                                                { checked: data.lastFarmVisited, onChange: () => { } }
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="radio_tick">
                                            {this.renderRadioInput(
                                                " All Farms",
                                                "defaultLanding",
                                                "allFarms",

                                                { checked: data.allFarms, onChange: () => { } }
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="radio_tick">
                                            {this.renderRadioInput(
                                                " A Specific Farm",
                                                "defaultLanding",
                                                "specificFarm",
                                                { checked: data.specificFarm, onChange: () => { } }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>

                            {this.renderSelect("selectedFarm", "", formOptions,
                                { value: this.state.selectedFarm })}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    farms: state.farms,

});
const mapDispatchToProps = {
    getFarms: farmsActions.fetchFarms,
    clearFarmsData: farmsActions.clearFarmsData,
    updateDefaultLanding: userAction.updateDefaultLanding
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDefaultLanding);
