import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import filterIcon from "../../assets/images/dashboard/filter-farm/shape.png";
import GroupTable from "./groupTable.jsx";
import Search from "../common/search.jsx";
import ToggleDropDown from "../common/toggleDropDown.jsx";
import groupService from "./duck/groupService";
import GroupEmptyCase from "./groupEmptyCase";
import { capitalise } from "../common/duck/utils";
import { GROUP_HIERARCHY, GROUP_HIERARCHY_DEALER } from './duck/groupUtils';
import aboutService from "../sites/siteDetails/about/duck/aboutService";
import localStorageService from '../common/duck/localStorageService';//'../../common/duck/localStorageService';


class GroupTableListPresentation extends Component {
  optionsFilter = [
    {
      label: "Recently Added", link: {
        pathname: `${this.props?.location?.pathname}`
      }
    },
    {
      label: "Name", link: {
        pathname: `${this.props?.location?.pathname}`
      }
    },
  ];

  componentDidMount() {
    aboutService.getUsers().then((res) => {
      this.setState({ suggestion: res });
    });
  }


  renderMembers = () => {
    let [org, level] = this.props.role.split("_");
    if(this.props.role === "dealer_admin"){
      let memberNewData  = []//this.props.memberData['invited'];
      for (let item of this.props.memberData['invited']) {
        memberNewData.push(item);
      }
      for (let item of this.props.memberData['members']) {
        if(item.objectId === localStorageService.getUserId()){
          continue
        }
        memberNewData.push(item);
    }

      return GROUP_HIERARCHY_DEALER
      .slice(GROUP_HIERARCHY_DEALER.indexOf(org))
      .map((orgType) => (

        <GroupTable
          key={orgType}
          label={capitalise(orgType)}
          search={this.props.search}
          data={this.props.data[orgType]}
          memberData={memberNewData}
          accountsData={this.props.data}
          enableEdit={level === "admin" && orgType !== "member"}
          enableMemberInvite={orgType !== "groguru" && orgType !== "member"}
          enableGroupInvite={true}
          enableViewTeam={orgType !== "groguru" && orgType !== "member"}
        />
      ));
    }
    return GROUP_HIERARCHY
      .slice(GROUP_HIERARCHY.indexOf(org))
      .map((orgType) => (

        <GroupTable
          key={orgType}
          label={capitalise(orgType)}
          search={this.props.search}
          data={this.props.data[orgType]}
          accountsData={this.props.data}
          enableEdit={level === "admin"}
          enableMemberInvite={orgType !== "groguru"}
          enableGroupInvite={true}
          enableViewTeam={orgType !== "groguru"}
        />
      ));
  };

  render() {
    const pageEmpty = this.props.data && !(
      this.props.data.groguru ||
      this.props.data.distributor ||
      this.props.data.business ||
      this.props.data.dealer
    );

    return (
      <div className="master-body container-fluid  ">
        <div className=" d-flex account-search justify-content-between search-filter-view">
          <Search placeholder="Search Accounts ..." onSearch={this.props.onSearch} />
          <ToggleDropDown
            label="Filter By"
            selected="Name"
            onChange={this.props.handleSortChange}
            icon={filterIcon}
            options={this.optionsFilter}
          />
        </div>

        <div className="sections">
          {(!this.props.data) ?
            <Spinner animation="grow" className="spinnerCentre" />
            : <React.Fragment>
              {this.props.data.parent?.id && (
                <GroupTable
                  key="parent"
                  label="Parent"
                  data={[this.props.data.parent]}
                />
              )}
              {this.props.memberData && this.renderMembers()}
            </React.Fragment>}
          {pageEmpty && <GroupEmptyCase label="Accounts" search={this.props.search} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.userDetails.profileDetails.profile.role.name,
});

export const GroupTableList = connect(mapStateToProps)(withRouter(GroupTableListPresentation));

class GroupList extends Component {
  state = { search: "", data: null, memberData: null, errors: null, sort: 'name' };
  requestObj = groupService.getGroupsData();
  requestObjMember = groupService.getAllMembers();
  apiCall = null;

  componentDidMount() {
    this.handleApi('', this.state.sort)
  }

  componentWillUnmount() {
    this.requestObj.cancel();
    this.requestObjMember.cancel();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.sort !== this.state.sort) {
      this.handleApi(this.state.search, this.state.sort);
    }
  }
  handleSortChange = sort => {
    if (sort === "Name") {
      this.setState({ sort: 'name' });
    } else {
      this.setState({ sort: 'createdAt' })
    }
  }
  handleApi = (name, sort) => {
    this.requestObj
      .request({ name: this.state.search, sort: this.state.sort })
      .then((data) => this.setState({ data }))
      .catch((error) => this.setState({ errors: { serviceErrors: error } }));
    this.requestObjMember
      .request({ name: this.state.search, sort: this.state.sort })
      .then((memberData) => this.setState({ memberData }))
      .catch((error) => this.setState({ errors: { serviceErrors: error } }));

  }
  onSearch = (e) => {
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    this.setState({ data: null, memberData: null, search: searchQuery });
    if (this.apiCall !== null && this.requestObj && this.requestObjMember) {
      this.requestObj.cancel("SEARCH_SKIPPED");
      clearTimeout(this.apiCall);
      this.apiCall = null;
      this.requestObj = null;
      this.requestObjMember = null;
    }
    this.apiCall = setTimeout(() => {
      this.requestObj = groupService.getGroupsData();
      this.requestObj
        .request({ name: searchQuery, sort: this.state.sort })
        .then((data) => this.setState({ data }))
        .catch((error) => !error.isCancelled && this.setState({ data: [] }));
    }, 200);

    this.apiCall = setTimeout(() => {
      this.requestObjMember = groupService.getAllMembers();
      this.requestObjMember
        .request({ name: searchQuery, sort: this.state.sort })
        .then((memberData) => this.setState({ memberData }))
        .catch((error) => !error.isCancelled && this.setState({ memberData: [] }));
    }, 200);
  
  };



  render() {
    return <GroupTableList
      data={this.state.data}
      memberData={this.state.memberData}
      search={this.state.search}
      onSearch={this.onSearch}
      handleSortChange={this.handleSortChange} />
  }

}

export default GroupList;
