import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

function emptyFunction() {}

export default function Confirm({ show, title, message, yesLabel, noLabel, onYes, onNo }) {
    return (
        <Modal
            className="invite-email invite-done"
            show={show}
            onHide={onNo}
            size="lg"
            centered>
            <Modal.Body className="">
                <h4 className="mx-auto mt-0">{title}</h4>
                <p className="">{message}</p>
                <div style={{display: 'flex'}}>
                    <button className="btn-submit mt-25 mr-20" onClick={onYes}>{yesLabel}</button>
                    <button className="btn-submit mt-25 ml20" onClick={onNo}>{noLabel}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function useConfirm(defaultState = initialConfirmState) {
    const [confirmBoxSate, setConfirmBoxState] = useState(defaultState);
    function askConfirmation({title, message, onYes, onNo = defaultState.onNo}) {
        setConfirmBoxState({
            show: true,
            data: {title, message},
            onYes: () => {
                onYes();
                setConfirmBoxState(defaultState);
            },
            onNo: () => {
                onNo();
                setConfirmBoxState(defaultState);
            }
        });
    }
    return {confirmBoxSate, setConfirmBoxState, askConfirmation}
}

export const initialConfirmState = {
    show: false,
    data: {title: '', message: ''},
    onYes: emptyFunction,
    onNo: emptyFunction
};

Confirm.defaultProps = {
    yesLabel: 'Yes',
    noLabel: 'No',
    title: 'Are you sure ?',
    message: '',
    onYes: emptyFunction,
    onNo: emptyFunction
}