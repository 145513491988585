import React, { Component } from "react";
import EditBasicInfo from './editBasicInfo';
import Members from './members';
import TeamShoppingSettings from './teamShoppingSettings';
import { connect } from 'react-redux';
 import groupService from './duck/groupService';

class EditGroup extends Component {
  state={
    objectId:'',
    image:''
  }

  redirectIfNoData = () => {
    if (!this.props.location.state) {
      this.props.history.replace('/dashboard/groups');
    }
  }

  componentDidMount() {
    this.redirectIfNoData();
    groupService.getOwnGroup().then(res => 
      this.setState({
        
        objectId:res?.team?.basicInfo?.objectId,
      }))
       

  }


render() {
      
 
    // uid: orgId, type: orgType, data: orgData
     const {state: locState} = this.props.location;
   
    return (
      <div className="edit-group-container">
        <div className="row">
          <div className="col-lg-7 col-12">
            {locState && <EditBasicInfo uid={locState.uid} data={locState.data} />}
            
            {this.props.isDirectGroguruChild && locState && this.state.objectId && 
            this.state.objectId===locState.uid && this.props.role!=='groguru_admin'&&
            <TeamShoppingSettings groupid={locState.uid} orgType={locState.type} data={locState.data}/>}
          
          </div>
          <div className="col-lg-5 col-12">
            {locState && <Members groupid={locState.uid} orgType={locState.type} data={locState.data} 
                />}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  const { userPrivilages,profileDetails } = state.userDetails;
  return {role:profileDetails.profile.role.name,isDirectGroguruChild: userPrivilages.isDirectGroguruChild};

};

export default connect(mapStateToProps)(EditGroup)