import http from '../../../common/duck/httpService';
import localStorageService from '../../../common/duck/localStorageService';

const api = {
    coupons: '/coupons',
    subscription: '/subscriptions',
    customers: '/customers',
    plans: '/plans',
    org:'/org'
};



function validateCoupon(couponCode) {
    return http.get(`${api.coupons}/${couponCode}`)
        .then(response => response.data);
}

function getPlans() {
    return http.get(api.plans)
        .then(response => response.data.data);
}

function getPlansForUser() {
    const loggedInUserId = localStorageService.getUserId();
    return http.get(`${api.customers}/${loggedInUserId}${api.plans}`)
        .then(response => response.data.data);
}

function createSubscription(subscriptionData, customerData = null) {
    let body = {subscriptionData};
    customerData && (body.customerData = customerData);
    return http.post(api.subscription, body)
        .then(response => http.getDataOrError(response));
}

function updateSubscription(subsId, subscriptionData) {
   
    return http.patch(`${api.subscription}/${subsId}`, subscriptionData)
        .then(response => http.getDataOrError(response));
}

function updateSubscriptionAddPlan(subsId, {plan, sites = []}) {
    return updateSubscription(subsId, {
        // subscriptionItemId,
        // "isPlanDeletion": false,
        "isNewPlan": true,
        // "isSiteAdditionOperation": false,
        // "isSiteRemoveOperation": false,
        // "isPlanUpdation": false,
        subscriptionData: {
            selectedPlan: {plan, sites}
        }
    });
}

function updateSubscriptionAddSites(subsId, subscriptionItemId, {plan, sites = []}) {
    return updateSubscription(subsId, {
        isSiteAdditionOperation: true,
        subscriptionItemId,
        subscriptionData: {
            selectedPlan: {plan, sites}
        }
    })
}

function updateSubscriptionCancelSubs(subsId, subscriptionItemId, {plan, sites}) {
   
    
    return updateSubscription(subsId, {
        isSiteRemoveOperation : true,
        subscriptionItemId,
        subscriptionData: {
            selectedPlan: {plan, sites},
        }
    });
}

function getInvoices(siteId) {
    const loggedInUserId = localStorageService.getUserId();
    return http.get(`${api.subscription}/invoices`, {params: {userId: loggedInUserId, siteId}})
        .then(response => http.getDataOrError(response))
}
function getComments(siteId) {
    return http.get(`site/subscription-comments/${siteId}`)
        .then(response => http.getDataOrError(response))
}
 
function getDealerSummary() {
    const loggedInUserId = localStorageService.getUserId();
    return http.get(`user/${loggedInUserId}/dealer-summary`)
        .then(response => http.getDataOrError(response))
}

function getAllSiteDetails(){
    const loggedInUserId = localStorageService.getUserId();
    return http.get(`user/${loggedInUserId}/subscription-history`)
        .then(response => http.getDataOrError(response))
}

function getSubscription(userId = localStorageService.getUserId()) {
    return http.get(`${api.customers}/${userId}${api.subscription}`)
        .then(response => http.getDataOrError(response));
    // /customers/AdEqV00qTp/subscriptions
}

function getCardDetailsofOrg(orgType,userId,name) {
   

    return http.get(`${api.org}/search/${orgType}?orgId=${userId}&name=${name}`)
    .then(response =>{ return response.data.data});
   
  
}

function getAuditData() {
    return http.get(`/subscriptions/plan-audit`)
    .then(response =>{ return response.data.data});
}

export default {
    validateCoupon,
    getPlans,
    getPlansForUser,
    createSubscription,
    getDealerSummary,
    getComments,
    updateSubscription,
    getInvoices,
    getAuditData,
    getSubscription,
    updateSubscriptionAddPlan,
    updateSubscriptionAddSites,
    updateSubscriptionCancelSubs,
    getCardDetailsofOrg,
    getAllSiteDetails,
}