import React, {useEffect, useState} from 'react';
import customerService from './duck/customerService';
import CardElementForm from './cardInput';
import AddCardModal from './addCardModal';
import CardListItem from './cardListItem';
import {StickyMessage} from '../common/messages';
import userActions from '../user/duck/userActions';
import { connect } from 'react-redux';

function addCardToken(token) {
    return customerService.updateCustomerToken(token)
}

function CardSourceList({
    sources,
    defaultSource,
    addCardShow,
    setAddCardShow,
    updateStripeCustomer,
    change,
    children
}) {
    const [checked, setChecked] = useState(defaultSource);
    const [error, setError] = useState({});
    const defaultAddCardShow = !sources || sources.length === 0;

    useEffect(() => {
        setChecked(defaultSource);
    }, [defaultSource]);

    useEffect(() => {
        if (defaultAddCardShow) {
            setAddCardShow(null);
        }
    }, [defaultAddCardShow, setAddCardShow])

    function changeDefaultSource(event) {
        const id = event.currentTarget.value;
        const prevId = checked;
        setChecked(id);
        customerService
            .updateCustomerDefaultPaymentSource(id)
            .then(data => updateStripeCustomer(data.stripeCustomerDetails))
            .catch(err => {
                const failure = {...err};
                setChecked(prevId);
                if (!failure.message) {
                    failure.message = "Couldnt change your default card";
                }
                setError(failure);
            });
    }

    function createCardForNewUser(token) {
        addCardToken(token)
            .then(data => updateStripeCustomer(data.stripeCustomerDetails))
            .catch(err => setError(err));
    }

    return (
        <React.Fragment>
            {error.message && <StickyMessage variant="error" message={error.message} />}
            {(sources && sources.length > 0) ? sources.map(card => <CardListItem
                key={card.id}
                name={card.name}
                brand={card.brand}
                last4={card.last4}
                value={card.id}
                checked={checked === card.id}
                onChange={changeDefaultSource}
                openCardModal={() =>setAddCardShow(true)} 
                change={change ? change :''}
                />)
                : 
                <CardElementForm
                    onSubmit={createCardForNewUser}
                    buttonLabel="Add Card" />}
            <AddCardModal
                show={!defaultAddCardShow && addCardShow}
                onClose={() => setAddCardShow(false)}
                addCardToken={addCardToken} />
            {(sources && sources.length > 0) && children}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const customer = state.userDetails.profileDetails.profile.stripeCustomerDetails;
    return {
        defaultSource: customer?.default_source,
        sources: customer?.sources?.data
    }
}

const mapDispatchToProps = {
    updateStripeCustomer: userActions.updateStripeCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSourceList)