import React, { Component } from "react";
import { Button, Overlay } from "react-bootstrap";
import siteService from "../../sites/duck/siteService";
import * as moment from 'moment'

class WeatherDetails extends Component {

  state = {
    show: false,
    back: false,
    day: '',
    weatherdata: [],
  };


  backdropClass = "backdrop";

  openBackdrop = () => {
    this.setState(state => ({ show: !state.show, back: true }));
    this.backdropClass += " open";
    const { latitude, longitude } = this.props?.singleFarmDetails?.location ? this.props?.singleFarmDetails?.location : this.props?.farmdetails?.location;
    if (latitude !== null && longitude !== null) {
      siteService.weatherDetails(latitude, longitude).then(response => {
        this.setState({ weatherdata: response.data });
      });
    }

  };
  closeBackdrop = () => {
    this.setState(state => ({ show: !state.show, back: false }));
    this.backdropClass = "backdrop";
  };

  weather(latitude, longitude) {
    siteService.weatherDetails(latitude, longitude).then(response => {
      this.setState({ weatherdata: response.data });
    });
  }

  handleShow = () => {
    this.setState({ show: !this.state.show });
  };


  render() {
    const { show } = this.state;
    const date = new Date();
    const day = date.getDay();
    const irrDate = moment(date);
    const ir = irrDate.format('YYYY-MM-DD')
    const today = moment();
    const res = Array(6).fill().map(
      () => today.add(1, 'd').format('YYYY-MM-DD')
    );
    res.splice(0, 0, ir)

    const sorter = {
      "sunday": 0, // << if sunday is first day of week
      "monday": 1,
      "tuesday": 2,
      "wednesday": 3,
      "thursday": 4,
      "friday": 5,
      "saturday": 6,

    }
    res.sort(function sortByDay(a, b) {
      let d1 = moment(a).format('dddd')
      let d2 = moment(b).format('dddd')
      let day1 = d1.toLowerCase();
      let day2 = d2.toLowerCase();
      return sorter[day1] - sorter[day2];
    });

    let weather = [...this.state.weatherdata]
    let weatherarray = []


    res.map((res) => {
      weather.map((weather) => {
        let day = weather.day;
        let d = moment(day).local().format('YYYY-MM-DD')
        if (res === d)

          weatherarray.push(weather)
        return 1;
      })
      return weatherarray;
    })
    return (
      <div
        className={`weatherbar ${show ? "weatherBorder" : ""
          } d-flex justify-content-between`}
      >
        <div className={this.backdropClass} onClick={this.closeBackdrop}></div>
        <Button variant="none" id="arrowButton" className="pr-10" onClick={this.openBackdrop}>
          <img
            id="arrowPosition"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAAAXNSR0IArs4c6QAAAE9JREFUGBljYICABiAFwliBNlD0NxD/B+IGIMYKwoCiRCn6A1SI16RwoALSFDFhcREjFjG4UAQ+K8iXBAUUTpczAyVfAzHIYQeBuAGIUQAAJnccPXX/NgAAAAAASUVORK5CYII="
            className={show ? "arrow-90icon" : ""}
            alt="open side menu"
          />
        </Button>
        <Overlay show={show} placement="bottom">
          {() => (
            <div style={{ top: this.props?.singleFarmDetails?.weather ? '203px' : '205px' }} className="weatherinfo">
              <table className="table">
                <tbody>
                  <tr>
                    <th>
                      <div style={{ maxWidth: "65px" }}>Temp Max/Min</div>
                      <div>(F)</div>
                    </th>
                    {this.props?.singleFarmDetails &&
                      this.props?.singleFarmDetails?.weather?.map((t) => (
                        <td style={{ paddingTop: '20px' }} key={day}>{t.tempMaxMin}</td>
                      ))}
                    {!this.props?.singleFarmDetails &&
                      this.props?.farmdetails?.weather?.map((t) => (
                        <td style={{ paddingTop: '20px' }} key={day}>{t.tempMaxMin}</td>
                      ))}

                  </tr>
                  <tr>
                    <th>
                      <div>Wind dir.</div>
                    </th>
                    {this.props?.singleFarmDetails && this.props?.singleFarmDetails?.weather?.map((w) => (
                      <td key={day}>
                        <img alt="wind-dir" width="30px" height="30px" src={w.windDir} />
                      </td>
                    ))}
                    {!this.props?.singleFarmDetails && this.props?.farmdetails?.weather?.map((w) => (
                      <td key={day}>
                        <img alt="wind-dir" width="30px" height="30px" src={w.windDir} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      <div>Wind</div>
                      <div>( mph )</div>
                    </th>
                    {this.props?.singleFarmDetails && this.props?.singleFarmDetails?.weather?.map((w) => (
                      <td key={day}>{w.windMaxMin}</td>
                    ))}
                    {!this.props?.singleFarmDetails && this.props?.farmdetails?.weather?.map((w) => (
                      <td key={day}>{w.windMaxMin}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      <div>Precip</div>
                      <div>( in )</div>
                    </th>
                    {this.props?.singleFarmDetails && this.props?.singleFarmDetails?.weather?.map((p) => (
                      <td key={day}>{p.precip}</td>
                    ))}
                    {!this.props?.singleFarmDetails && this.props?.farmdetails?.weather?.map((p) => (
                      <td key={day}>{p.precip}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      <div>Precip prob</div>
                      <div>( % )</div></th>
                    {this.props?.singleFarmDetails && this.props?.singleFarmDetails?.weather?.map((p) => (
                      <td key={day}>{p.precipProb}</td>
                    ))}
                    {!this.props?.singleFarmDetails && this.props.farmdetails?.weather?.map((p) => (
                      <td key={day}>{p.precipProb}</td>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      <div>ETo</div>
                      <div>( in )</div>
                    </th>
                    {this.props?.singleFarmDetails && this.props?.singleFarmDetails?.weather?.map((e) => (
                      <td key={day}>{e.et}</td>
                    ))}
                    {!this.props?.singleFarmDetails && this.props.farmdetails?.weather?.map((e) => (
                      <td key={day}>{e.et}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Overlay>
        {this.props?.singleFarmDetails &&
          this.props?.singleFarmDetails?.weather?.map((w) => (
            <div>
              <img alt="weather" width="30px" height="30px" src={w.weatherDesc} />
              <p style={{ fontFamily: "d-din", textAlign: 'center', fontWeight: '700', color: '#000', fontSize: '13px' }}>{w.weekdayName}</p>
            </div>))
        }
        {!this.props?.singleFarmDetails && this.props.farmdetails?.weather?.map((w) => (
          <div>
            <img alt="weather" width="30px" height="30px" src={w.weatherDesc} />
            <p style={{ fontFamily: "d-din", textAlign: 'center', fontWeight: '700', color: '#000', fontSize: '13px' }}>{w.weekdayName}</p>
          </div>))}

      </div >
    );
  }
}

export default WeatherDetails;
