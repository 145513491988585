import React from "react";
import { Card, Form } from "react-bootstrap";
import GForm from "../../../common/form";
import siteService from "../../duck/siteService";
import { connect } from "react-redux";
import EditSensorModal from "./editSensorModal";

class SensorInfo extends GForm {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.siteDetails !== prevProps.siteDetails) {
      this.setState({
        localSensor: this.props.siteDetails?.twigDetails?.localSensorLocation,
        hardwareSensor: this.props.siteDetails?.twigDetails
          ?.hardwareSensorLocation,
        referenceDepth: this.props.siteDetails?.twigDetails
          ?.hardwareSensorLocation?.value?.referenceDepth,
        sensorType: this.props.siteDetails?.twigDetails?.hardwareSensorLocation
          ?.sensorType,
        referenceDepth_local: this.props.siteDetails?.twigDetails
          ?.localSensorLocation?.value?.referenceDepth,
        sensorType_local: this.props.siteDetails?.twigDetails
          ?.localSensorLocation?.sensorType,
        hardwarecount: this.props.siteDetails?.twigDetails
          ?.hardwareSensorLocation?.count,
        localcount: this.props.siteDetails?.twigDetails?.localSensorLocation
          ?.count,
        foxSensor: this.props.siteDetails?.twigDetails?.FoxSensor,
        id: this.props.siteDetails.id,
        hardwaretype: this.props.siteDetails?.twigDetails?.hardwaretype,


      });
    }
  }
  state = {
    data: {

    },
    errors: {},
    serviceError: null,
    id: this.props.siteDetails.id || null,
    hardwareSensorLocation: null,
    hardwareSensor: this.props.siteDetails?.twigDetails?.hardwareSensorLocation || null,
    localSensor: this.props.siteDetails?.twigDetails?.localSensorLocation || null,
    localSensorLocation: null,
    referenceDepth: this.props.siteDetails?.twigDetails?.hardwareSensorLocation?.value?.referenceDepth || null,
    sensorType: this.props.siteDetails?.twigDetails?.hardwareSensorLocation?.sensorType || null,
    referenceDepth_local: this.props.siteDetails?.twigDetails?.localSensorLocation?.value?.referenceDepth || null,
    sensorType_local: this.props.siteDetails?.twigDetails?.localSensorLocation?.sensorType || null,
    foxSensor: this.props.siteDetails?.twigDetails?.FoxSensor || null,
    hardwarecount: this.props.siteDetails?.twigDetails?.hardwareSensorLocation?.count || null,
    localcount: this.props.siteDetails?.twigDetails?.localSensorLocation?.count || null,
    hardwaretype: this.props.siteDetails?.twigDetails?.hardwaretype || null,
    hard: '',
    modal: false,
    show: false,
    disabled: true,
    prev: null


  };

  schema = {
  };


  doSubmit = () => {


    if (!this.state.disabled) {
      const siteobjid = this.state.id

      siteService.updateSite(siteobjid, {
        "hardwareReferenceDepth": this.state.referenceDepth,
        "localReferenceDepth": this.state.referenceDepth_local
      }).then(response => {


        const { settings } = response.data.parsedTwig;
        const hardwareSensorLocation = settings.hardwareSensorLocation;
        const localSensorLocation = settings.localSensorLocation;

        this.props.onTwigDetailsChange({ localSensorLocation, hardwareSensorLocation });


      });
    }

  }

  handleChangedepths = (e, index) => {
    let sensorvalue = e.target.value === '' ? 0 : e.target.value;

    if (e.target.name === 'hardwareSensorLocation') {
      if (this.state.sensorType === 'aquacheck') {
        let reference = [...this.state.referenceDepth];
        reference[index] = parseInt(sensorvalue)
        this.setState({ referenceDepth: reference })

      }
      else {
        let reference = [...this.state.referenceDepth];
        reference[index] = parseInt(sensorvalue);
        this.setState({ referenceDepth: reference })


      }
    }

    if (e.target.name === 'localSensorLocation') {
      if (this.state.sensorType_local === 'aquacheck') {
        let reference = [...this.state.referenceDepth_local];
        reference[0] = parseInt(sensorvalue)
        this.setState({ referenceDepth_local: reference })
      }
      else {
        let reference = [...this.state.referenceDepth_local];
        reference[index] = parseInt(sensorvalue);
        this.setState({ referenceDepth_local: reference })

      }
    }
  };


  handleModal = () => {
    this.setState({ modal: !this.state.modal, show: !this.state.show })

  }

  handleOption = (e) => {
    let selected = e.target.name;
    const id = this.state.id

    if (this.state.foxSensor === "false" || this.state.foxSensor === false) {
      this.setState({ foxSensor: true });
      siteService.updateSite(id, { [selected]: "true" });
    } else {
      this.setState({ foxSensor: false });
      siteService.updateSite(id, { [selected]: "false" });
    }
  };
  handledisable = () => {
    this.setState({ disabled: !this.state.disabled })
  }

  render() {

    let name = '';
    if (this.state.sensorType === "aquacheck") {
      name = "Top of Aquacheck Probe"
    }
    else {
      name = "Top of GroGuru Sensor"
    }

    return (
      <div className="basic-info-container">
        <Card.Title className="d-flex justify-content-between">
          <div>Sensor Information</div>
          {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
            this.props.profileDetails.profile.role.name !== 'business_user' &&
            this.props.profileDetails.profile.role.name !== 'farm_user') ? (<div className='d-flex' onClick={this.handledisable}>

              {this.renderButton(this.state.disabled ? 'Edit' : 'Save', { className: "save-btn" })}


              <EditSensorModal handleModal={this.handleModal} show={this.state.show}
                hardwareSensor={this.state.hardwareSensor !== undefined && this.state.hardwareSensor}
                localSensor={this.state.localSensor !== undefined && this.state.localSensor}
                onTwigDetailsChange={this.props.onTwigDetailsChange}
                id={this.state.id} />

            </div>) : null}
        </Card.Title>
        <hr></hr>

        <Form>
          <div className="row">
            <div className="col-lg-12 pl-0 ">
              <div className="col-lg-6 ">
                <Form.Group>
                  <Form.Label>Hardware Sensors</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={this.state.hardwaretype !== null ? this.state.hardwaretype : ''}

                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
            {this.state.referenceDepth && this.state.referenceDepth.map((hardware, index) =>
              <div className="col-lg-4 col-6" key={index}>
                <Form.Group >
                  <Form.Label>{name}({index + 1})</Form.Label>
                  <Form.Control
                    name="hardwareSensorLocation"
                    type="text"
                    disabled={this.state.disabled}
                    value={isNaN(hardware) ? '0' : hardware}
                    onChange={(e) => this.handleChangedepths(e, index)}
                  ></Form.Control>
                </Form.Group>
              </div>)}


          </div>

          <div className="d-flex flex-wrap" >
            {this.state.hardwareSensor !== null &&
              this.state.hardwareSensor?.value?.sensors !== null &&
              this.state.hardwareSensor?.value?.sensors.map((element, index) =>
                Object.entries(element)
                  .sort(([key1], [key2]) => key1 - key2)
                  .map(item => item[1])
                  .map((sensor, index) => {
                    if (this.state.sensorType !== "groguru")
                      return (
                        <div
                          className="w-150 mr-8 "
                          key={index}
                        >
                          <Form.Group >
                            <Form.Label>{`Depth of sensor:${sensor.name}`}</Form.Label>
                            <Form.Control
                              type="text"
                              name="hardwareSensorLocation"
                              label={sensor.name}

                              value={
                                isNaN(sensor.depthOffset +
                                  parseInt(this.state.referenceDepth[0])) ?
                                  '0' : sensor.depthOffset +
                                  parseInt(this.state.referenceDepth[0])

                              }

                              disabled
                            />
                          </Form.Group>


                        </div>
                      );
                    else {
                      return (
                        <div
                          className="w-150 mr-8 "
                          key={index}
                        >
                          <Form.Group>
                            <Form.Label>{`Depth of sensor:${sensor.name}`}</Form.Label>
                            <Form.Control
                              type="text"
                              name="hardwareSensorLocation"
                              label={sensor.name}

                              value={
                                isNaN(sensor.depthOffset +
                                  parseInt(this.state.referenceDepth[index])) ?
                                  '0' : sensor.depthOffset +
                                  parseInt(this.state.referenceDepth[index])

                              }
                              placeholder=''

                              disabled
                            />
                          </Form.Group>
                        </div>
                      );
                    }
                  })
              )}


          </div>
          {this.state.localSensor && <div className="row  mt-15">
            {this.state.referenceDepth_local && this.state.referenceDepth_local.map((local, index) =>

              <div className="col-4" key={index}>
                <Form.Group>
                  <Form.Label>Top of Fox Sensor({index + 1})</Form.Label>
                  <Form.Control type="text" name="localSensorLocation" value={isNaN(local) ? '0' : local}
                    disabled={this.state.disabled} onChange={(e) => this.handleChangedepths(e, index)} />
                </Form.Group>
              </div>)}
          </div>}
          {this.state.localSensor && <div className="d-flex flex-wrap">
            {this.state.localSensor !== null &&
              this.state.localSensor?.value?.sensors !== null &&
              this.state.localSensor?.value?.sensors.map((element) =>
                Object.entries(element)
                  .sort(([key1], [key2]) => key1.localeCompare(key2))
                  .map(item => item[1])
                  .map((sensor, index) => {
                    if (this.state.sensorType_local === "aquacheck") {
                      return (
                        <div
                          className="w-150 mr-8 "
                          key={Math.random() + Date.now()}
                        >
                          <Form.Group >
                            <Form.Label>{`Depth of Sensor:${sensor.name}`}</Form.Label>
                            <Form.Control
                              type="text"
                              name="localSensorLocation"
                              label={sensor.name}
                              value={
                                isNaN(sensor.depthOffset +
                                  parseInt(this.state.referenceDepth_local[0])) ?
                                  '0' : sensor.depthOffset +
                                  parseInt(this.state.referenceDepth_local[0])

                              }
                              placeholder=''
                              disabled
                            />
                          </Form.Group>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="w-150 mr-8 "
                          key={Math.random() + Date.now()}
                        >
                          <Form.Group>
                            <Form.Label>{`Depth of Sensor:${sensor.name}`}</Form.Label>
                            <Form.Control
                              type="text"
                              name="localSensorLocation"
                              label={sensor.name}
                              value={
                                isNaN(sensor.depthOffset +
                                  parseInt(this.state.referenceDepth_local[index])) ?
                                  '0' : sensor.depthOffset +
                                  parseInt(this.state.referenceDepth_local[index])

                              }
                              placeholder=''
                              disabled
                            />
                          </Form.Group>
                        </div>
                      );
                    }
                  })
              )}

          </div>}



        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

const connectedSiteBasicInfo = connect(mapStateToProps)(SensorInfo);
export { connectedSiteBasicInfo as SensorInfo };
