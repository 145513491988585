import React, { Component } from 'react';
import { Card, Form } from "react-bootstrap";
import utils from './duck/utils';
import siteService from '../../duck/siteService';
import Message from "../../../common/messages";

class IrrigationTypes extends Component {
    state = {
        disabled: true,
        errors: null,
        irrigationProperties: this.props.siteDetails?.twigDetails?.settings?.irrigationProperties ? this.props.siteDetails?.twigDetails?.settings?.irrigationProperties : {},
        irrigationType: this.props.siteDetails?.twigDetails?.settings?.irrigationProperties?.system_type ? this.props.siteDetails?.twigDetails?.settings?.irrigationProperties?.system_type : null,
        editedObject: {},
        responseMsg: null,
    }

    componentDidMount() {
        const { irrigationType } = this.state;
        if (irrigationType !== null) {
            let defaultIrrigationProperties = utils.defaultValuesForIrrigationType(irrigationType);
            this.setState({ irrigationProperties: defaultIrrigationProperties, editedObject: defaultIrrigationProperties });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.siteDetails?.twigDetails?.settings?.irrigationProperties !== prevProps.siteDetails?.twigDetails?.settings?.irrigationProperties && this.props.siteDetails?.twigDetails?.settings?.irrigationProperties !== null) {
            this.setState({ irrigationProperties: this.props.siteDetails?.twigDetails?.settings?.irrigationProperties, irrigationType: this.props.siteDetails?.twigDetails?.settings?.irrigationProperties?.system_type, editedObject: this.props.siteDetails?.twigDetails?.settings?.irrigationProperties });
        }
    }

    handleChange = (e) => {
        let value = e.target.value;
        let parsedValue = parseInt(value);

        let defaultIrrigationProperties = utils.defaultValuesForIrrigationType(parsedValue);

        this.setState({ irrigationType: parseInt(value), irrigationProperties: defaultIrrigationProperties, editedObject: defaultIrrigationProperties });
    };

    handleValueChange = (source, e) => {
        let value = e.target.value;
        let tempObj = this.state.editedObject;

        if (tempObj === null) {
            tempObj = {};
        }
        if (source === "emitter_discharge_rate" || source === "emitters_per_plant") {
            tempObj[source] = parseFloat(value);
        } else {
            tempObj[source] = parseInt(value);
        }
        this.setState({ editedObject: tempObj })
    };

    onEdit = () => {
        this.setState({
            disabled: !this.state.disabled
        });
        if (!this.state.disabled) {
            this.onSubmit();
        }
    };

    onSubmit = () => {
        let unitsObj = utils.fetchingUnitsBasedOnType(this.state.irrigationType);
        let mergedObject = { ...this.state.editedObject, ...unitsObj };
        const siteId = this.props.siteDetails?.id;
        const apiBody = {
            irrigationProperties: mergedObject,
        }
        siteService.updateSite(siteId, apiBody).then((res) => {
        }).catch(error => {
            this.setState({ errors: error.message });
        });
    };

    render() {
        return (
            <div className="software-container">
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h5>Irrigation Types</h5>

                            <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <form>
                            <div className="row">
                                <div className="col-lg-10">
                                    <Form.Group>
                                        <Form.Label>Irrigation Type</Form.Label>
                                        <div className="selectdiv">
                                            <select name="irrigationType" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.irrigationType ? this.state.irrigationType : ''}
                                                onChange={this.handleChange} disabled={this.state.disabled}>
                                                <option value="2">2</option>
                                                <option value="5">5</option>
                                                <option value="8">8</option>
                                            </select>
                                            <span className="fas fa-caret-down"> </span>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>

                            {this.state.irrigationType === 2 && (
                                <div className="row ">
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Flow rate (gpm)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="flowRate"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.flow_rate !== null ? this.state.irrigationProperties?.flow_rate : ""
                                                }
                                                onChange={(event) => this.handleValueChange('flow_rate', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Irrigated area (ac)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="irrigatedArea"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.irrigated_area !== null ? this.state.irrigationProperties?.irrigated_area : ""
                                                }
                                                onChange={(event) => this.handleValueChange('irrigated_area', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Nozzle type</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="nozzleType"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.nozzle_type !== null ? this.state.irrigationProperties?.nozzle_type : ""
                                                }
                                                onChange={(event) => this.handleValueChange('nozzle_type', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            )}

                            {this.state.irrigationType === 5 && (
                                <div className="row ">
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Flow rate (gpm)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="flowRate"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.flow_rate !== null ? this.state.irrigationProperties?.flow_rate : ""
                                                }
                                                onChange={(event) => this.handleValueChange('flow_rate', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Irrigated area (ac)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="irrigatedArea"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.irrigated_area !== null ? this.state.irrigationProperties?.irrigated_area : ""
                                                }
                                                onChange={(event) => this.handleValueChange('irrigated_area', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            )}

                            {this.state.irrigationType === 8 && (
                                <div className="row ">
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Discharge rate (gpm)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="dischargeRate"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.emitter_discharge_rate !== null ? this.state.irrigationProperties?.emitter_discharge_rate : ""
                                                }
                                                onChange={(event) => this.handleValueChange('emitter_discharge_rate', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Emitter spacing (inches)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="emitterSpacing"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.emitter_spacing !== null ? this.state.irrigationProperties?.emitter_spacing : ""
                                                }
                                                onChange={(event) => this.handleValueChange('emitter_spacing', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Emitters per plant (inches)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="emittersPerPlant"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.emitters_per_plant !== null ? this.state.irrigationProperties?.emitters_per_plant : ""
                                                }
                                                onChange={(event) => this.handleValueChange('emitters_per_plant', event)}
                                            />
                                        </Form.Group>
                                    </div> */}
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Irrigated area (ac)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="irrigatedArea"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.irrigated_area !== null ? this.state.irrigationProperties?.irrigated_area : ""
                                                }
                                                onChange={(event) => this.handleValueChange('irrigated_area', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Lateral spacing (inches)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="lateralSpacing"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.lateral_spacing !== null ? this.state.irrigationProperties?.lateral_spacing : ""
                                                }
                                                onChange={(event) => this.handleValueChange('lateral_spacing', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Line depth (inches)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="lineDepth"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.line_depth !== null ? this.state.irrigationProperties?.line_depth : ""
                                                }
                                                onChange={(event) => this.handleValueChange('line_depth', event)}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-lg-10">
                                        <Form.Group>
                                            <Form.Label>Plant density (ac)</Form.Label>
                                            <Form.Control
                                                disabled={this.state.disabled}
                                                type="text"
                                                name="plantDensity"
                                                className={`${this.state.disabled ? '' : "whiteBC"}`}
                                                placeholder={
                                                    this.state.irrigationProperties !== null && this.state.irrigationProperties?.plant_density !== null ? this.state.irrigationProperties?.plant_density : ""
                                                }
                                                onChange={(event) => this.handleValueChange('plant_density', event)}
                                            />
                                        </Form.Group>
                                    </div> */}
                                </div>
                            )}
                        </form>
                    </Card.Body>

                    <div className="messagePopup">
                        {(this.state.errors !== null) &&
                            <Message variant="error" message={this.state.errors} />}
                        {this.state.responseMsg !== null && this.state.responseMsg?.length !== 0 && <Message variant="success" message={'Data updated successfully'} />}
                    </div>
                </Card>
            </div>
        );
    }
}

export default IrrigationTypes;

