import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import SearchFilter from '../searchFilter';
import FarmSubscriptions from './FarmSubscriptions';
import DoneModal from '../../group/InvitationModal/inviteDone';
import farmService from '../../farm/duck/farmService';
import groupService from '../../group/duck/groupService';
import StandardEmpty from '../../common/standardEmptyCase';
import siteService from '../../sites/duck/siteService';
import subscriptionService from '../subscriptions/duck/subscriptionService';
import Confirm from '../../common/confirm';

function renderCollection(data, mapping, emptyCaseMessage) {
  if (!data) {
    return <Spinner animation="grow" size="lg" />
  } else if (data.length === 0) {
    return emptyCaseMessage && <span>{emptyCaseMessage}</span>;
  } else if (data.length > 0) {
    return data.map(mapping)
  }
}

function SubscriptionHistory(props) {

  const [farmsData, setFarmsData] = useState(null)
  const [search, setSearch] = useState('');
  const previousSearch = useRef(null);
  const [parent, setParent] = useState(null);
  const [doneModalShow, setDoneModalShow] = useState(false);
  const [doneSubscrptionModal, setDoneSubscrptionModal] = useState(false);
  const [silverSites, setSilverSites] = useState("0");
  const [GoldSites, setGoldSites] = useState("0");
  const [notSubscribedSites, setNotSubscribedSites] = useState("0");
  const [allSites, setAllSites] = useState(null);
  const [activeNewSubscription, setActiveNewSubscriptions] = useState([])

  const [isEmptyResult, setIsEmptyResult] = useState(null);
  const [showDelete, setShowDelete] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState([])

  // useEffect(() => {
  //   const reqObj = farmService.getFarmsForUserRequest();
  //   reqObj.request()
  //     .then(farms => setFarmsData(farms.map(({ objectId, name }) => ({ objectId, name }))))
  //     .catch(error => console.log(error));
  //   return () => reqObj.cancel('Farms cancelled');
  // }, []);

  // useEffect(() => {

  // },[selectedDelete])
  // useEffect(() => {

  // }, []);
  function initialLoad(){
    const reqObj = farmService.getFarmsForUserRequest();
    reqObj.request()
      .then(farms => setFarmsData(farms.map(({ objectId, name }) => ({ objectId, name }))))
      .catch(error => console.log(error));
      console.log(farmsData, "farmsData")
    return () => reqObj.cancel('Farms cancelled');
  }
function loadData(){
  //2
  groupService.getParent()
  .then(data => setParent(data))
  .catch(err => console.log(err));
  //3
  let totalSites = []
  subscriptionService.getAllSiteDetails()
    .then(data => {
      // eslint-disable-next-line no-unused-vars
      let allSites1 = data?.map(site1 =>
        site1.sites.map(site => totalSites.push({ ...site, dealerName: site1.dealerName }))
      )
      setAllSites(totalSites);
      const silverSites1 = totalSites && totalSites.filter(site => (
        site.plan === "Silver"
      ))
      const goldSites1 = totalSites && totalSites.filter(site => (
        site.plan === "Gold"
      ))
      const noneSites1 = totalSites && totalSites.filter(site => (
        site.plan === "None"
      ))
      setSilverSites(silverSites1?.length);
      setGoldSites(goldSites1?.length);
      setNotSubscribedSites(noneSites1?.length);
    }
    )
    .catch(err => console.log(err));
}
  useEffect(() => {
    initialLoad()
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function activateSubscription() {
    const removeSameSubscribedPlan = activeNewSubscription.filter(data => data.oldPlan !== data.currentPlan)
    siteService.activateSubscriptionsOrComments(removeSameSubscribedPlan)
      .then(
        setActiveNewSubscriptions([]),
        setDoneSubscrptionModal(true)
      )
      .catch(error => console.log(error));
  }

  function handleSiteSearch(event) {
    let trimmedstr = event.target.value;
    const query = trimmedstr.replace(/\s+$/, '');

    if (previousSearch.current !== null) {
      clearTimeout(previousSearch.current);
    }

    previousSearch.current = setTimeout(() => {
      setSearch(query);
      setIsEmptyResult(null);
      console.log(allSites, 'allSites');
      const matchedSites = allSites && allSites.filter(site => (
        site?.siteId?.startsWith(query) ||
        site?.siteName?.toLowerCase().includes(query?.toLowerCase()) ||
        site?.dealerName?.toLowerCase().includes(query?.toLowerCase()))
      );
      console.log(matchedSites, 'matchedSitescccc');
      const silverSites2 = matchedSites && matchedSites.filter(site => (
        site.plan === "Silver"
      ))
      const goldSites2 = matchedSites && matchedSites.filter(site => (
        site.plan === "Gold"
      ))
      const noneSites2 = matchedSites && matchedSites.filter(site => (
        site.plan === "None"
      ))
      setSilverSites(silverSites2?.length);
      setGoldSites(goldSites2?.length);
      setNotSubscribedSites(noneSites2?.length);
    }, 300)
  }

  function reportEmptyResult() {
    const tableCount = document.getElementsByTagName('table').length;
    setIsEmptyResult(tableCount === 0);
  }

    function deleteSelectedSites () {
      setShowDelete(true);
    }
    function deleteSiteOnYes () {
        // eslint-disable-next-line array-callback-return
        selectedDelete.map((siteId) => {
          siteService.deleteSite(siteId.id, {"deleted" : siteId.deleted})
        })
        setShowDelete(false);
        setSelectedDelete([])
        setFarmsData(null)
        setParent(null)
        setAllSites(null)
        initialLoad()
        loadData()
    }
    function deleteSiteOnNo () {
        setShowDelete(false);
    }


  return (
    <React.Fragment>
      <div>
        <div className="d-flex justify-content-between subscription-search">
          <SearchFilter
            searchPlaceholder="Search with SiteIDs,Site Names and Dealer Names..."
            handleSearch={handleSiteSearch} />
          {activeNewSubscription?.length > 0 ? <div onClick={activateSubscription} className="activate-subscriptions">Activate Subscriptions</div> : null}
          {selectedDelete?.length > 0 ? <div onClick={deleteSelectedSites} className="activate-subscriptions">Delete selected sites</div> : null}

        </div>
        {allSites && <div className="search-summary">Summary : {GoldSites} Gold {GoldSites === 1 ? "SiteID" : "SiteIDs"}, {silverSites} Silver {silverSites === 1 ? "SiteID" : "SiteIDs"} , {notSubscribedSites} not subscribed</div>}
        <div className="subscription-history-container">
          {search && isEmptyResult && <StandardEmpty
            mainText="No sites found"
            subText={`No sites or site IDs matched the search text '${search}'.`} />}
          {renderCollection(farmsData, (farm, i) => <FarmSubscriptions
            key={farm.objectId}
            name={farm.name}
            farmId={farm.objectId}
            search={search}
            openDoneModal={() => setDoneModalShow(true)}
            onLastFarmIsRendered={(i === (farmsData.length - 1)) && reportEmptyResult}
            parent={parent}
            activeNewSubscription={activeNewSubscription}
            setActiveNewSubscriptions={setActiveNewSubscriptions}
            selectedDelete = {selectedDelete}
            setSelectedDelete = {setSelectedDelete}
          />, 'No farms for this user')}
        </div>
        <DoneModal
          buttonLabel="Ok"
          show={doneModalShow}
          onClose={() => setDoneModalShow(false)}
          data={{ title: 'Contact your Admin', message: `Please contact '${parent && parent.username}' to add or upgrade this site subscription` }} />
        <DoneModal
          buttonLabel="Ok"
          show={doneSubscrptionModal}
          onClose={() => setDoneSubscrptionModal(false)}
          data={{ title: 'Subscription Activation In Progress', message: `Changes in the subscribed plan for the sites will be activated tomorrow` }} />
                      <Confirm
                show={showDelete}
                title={'Are you sure you want to delete the selected sites'}
                message={'This site will be deleted'}
                onYes={() =>deleteSiteOnYes()}
                onNo={() => deleteSiteOnNo()} 
                />
      </div>
    </React.Fragment>
  );
}

export default SubscriptionHistory;