import {orderByStartDay} from '../../../template/duck/templateValidation';
import {lastItem} from '../../../../../common/duck/utils';

export default function sma(dataPoints, windowSize = 5) {
    if (dataPoints.length === 0) {
        return [];
    }
    let sorted = orderByStartDay(dataPoints, 'x')

    if (windowSize === 0) {
        return sorted;
    }
    
    if (sorted.length < windowSize) {
        const avg = sorted.reduce((acc, point) => acc + point.y, 0) / sorted.length;
        return [{x: lastItem(sorted), y: avg}]
    }
    
    const result = [];

    for (let i = windowSize; i <= sorted.length; i++) {
        const window = sorted.slice(i - windowSize, i);
        const avg = window.reduce((acc, point) => acc + point.y, 0) / windowSize;
        const timePeriod = lastItem(window).x;
        result.push({ x: timePeriod, y: isNaN(avg) ? undefined : avg});
    }

    return result;
}