import React from 'react';

function InputSelect({name, label, value, error, options, onChange, ...rest}) {

    return (
        <div className="form-group">
            <label htmlFor={name} className="form-label">{label}</label>
            <select className="form-control" id={name} name={name} value={value} onChange={onChange} {...rest}>
                {options && options.map(option => <option key={option._id} value={option._id}>{option.name}</option>)}
            </select>
            {error && <span className="error">{error}</span>}
        </div>
    );
}

export default InputSelect;
