import React from "react";
import { Card, Form } from "react-bootstrap";
import GForm from "../../../common/form";
import siteService from "../../duck/siteService";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Button } from "react-bootstrap";
import configService from "../configuration/duck/configService";
import { AgronomicInfo } from "./agronomicInfo";
import { AboutMap } from "./aboutMap";


class SiteBasicInfo extends GForm {
  componentDidMount() {
    configService.getType("PlantType").then((res) => {
      this.setState({ planttype: res });
    });
    configService.getType("IrrigationType").then((res) => {
      this.setState({ irrigationtype: res });
    });
    configService.getType("SoilType").then((res) => {
      this.setState({ soiltype: res });
    });

  }

  componentDidUpdate(prevProps) {
    if (this.props.siteDetails !== prevProps.siteDetails) {

      this.setState({
        data: {
          siteId: this.props.siteDetails?.siteId,
          latitude: this.props.siteDetails?.location?.latitude,
          longitude: this.props.siteDetails?.location?.longitude,
          name: this.props.siteDetails?.name,
        },
        id: this.props.siteDetails.id,
      });
    }
  }
  state = {
    data: {
      siteId: this.props.siteDetails?.siteId || "",
      latitude: this.props.siteDetails?.location?.latitude || "",
      longitude: this.props.siteDetails?.location?.longitude || "",
      name: this.props.siteDetails?.name || "",
    },
    errors: {},

    responseMsg: null,
    serviceError: null,
    map: false,
    show: false,
    id: this.props.siteDetails.id || "",
    disabled: true
  };
  schema = {
    name: Joi.string().required().label("Name").allow(null),
    latitude: Joi.number().required().label("Latitude").allow(null),
    longitude: Joi.number().required().label("Longitude").allow(null),
    siteId: Joi.number().required().allow(null),
  };

  handleMap = () => {


    this.setState({ disabled: true, map: !this.state.map, show: !this.state.show });
  };
  handleLatLng = (lat, lng) => {
    const name_siteid = { name: this.state.data.name, siteId: this.state.data.siteId }
    this.setState({ data: { latitude: lat, longitude: lng, ...name_siteid }, disabled: this.state.disabled })
  }
  doSubmit = () => {

    if (!this.state.disabled) {
      const siteobjid = this.state.id;
      const data = { siteId: this.state.data.siteId, name: this.state.data.name, location: { latitude: this.state.data.latitude, longitude: this.state.data.longitude } };


      siteService.updateSite(siteobjid, data).then(response => {
        this.props.setChangeLogs(false)
        const { name, siteId } = response.data.parsedSite;
        const { latitude, longitude } = response.data.parsedSite.location;

        this.props.onSiteDataChange({ name, siteId, location: { latitude, longitude } });
        // this.props.onTwigDetailsChange({siteId,location:{latitude,longitude}})
        this.setState({ responseMsg: response.message });
        this.props.setChangeLogs(true)
      })
        .catch(err => {
          this.setState({ errors: { serviceError: err.message } });
        })
    }

  };

  handledisable = () => {
    this.setState({ disabled: !this.state.disabled })
  }

  render() {


    return (
      <div className="basic-info-container">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <h6>Basic Information</h6>

          </Card.Header>
          <hr></hr>
          <Card.Body>
            <Card.Title className="d-flex justify-content-between">
              <div>Site Information</div>
              {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                this.props.profileDetails.profile.role.name !== 'business_user' &&
                this.props.profileDetails.profile.role.name !== 'farm_user') ?
                (<div onClick={this.handledisable}>
                  {this.renderButton(this.state.disabled ? 'Edit' : 'Save', { className: "save-btn" })}
                </div>) : null}

            </Card.Title>

            <hr></hr>

            <Form>
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <Form.Group >
                    <Form.Label>Site ID</Form.Label>
                    <Form.Control
                      name="siteId"
                      type="text"
                      disabled
                      value={this.props.siteDetails?.siteId || ''}

                    ></Form.Control>
                  </Form.Group>
                  {/* {this.renderFormGroup("siteId", "Site ID", "text", { disabled :this.state.disabled,
                    value: this.state.data.siteId!==null?this.state.data.siteId:'',
                  })} */}
                </div>
                <div className="col-lg-9 col-md-9">
                  {this.renderFormGroup("name", "Site Name", "text", {
                    disabled: this.state.disabled,
                    value: this.state.data.name !== null ? this.state.data.name : this.props.siteDetails?.name ? this.props.siteDetails?.name : '',
                  })}

                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  {this.renderFormGroup("latitude", "Site Latitude", "text", {
                    disabled: this.state.disabled,
                    value: this.state.data.latitude !== null ? this.state.data.latitude : '',
                  })}

                </div>
                <div className="col-lg-4 col-md-4">
                  {this.renderFormGroup("longitude", "Site Longitude", "text", {
                    disabled: this.state.disabled,
                    value: this.state.data.longitude !== null ? this.state.data.longitude : '',
                  })}
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                  <div className="mt-50">
                    <span className="icon-map  "></span>
                  </div>
                  <div>
                    <Button
                      onClick={this.handleMap}
                      variant="none"
                      className={`create-farm-btn ${this.state.disabled ? "button-disabled" : ''} mt-42 green`}
                      disabled={this.state.disabled}
                    >
                      Move Site ID Using Map
                    </Button>
                    {this.state.map && (
                      <AboutMap
                        handleMap={this.handleMap}
                        show={this.state.show}
                        id={this.state.id}
                        handleLatLng={this.handleLatLng}
                        lat={this.state.data.latitude}
                        lng={this.state.data.longitude}
                        onSiteDataChange={this.props.onSiteDataChange}
                        setChangeLogs={this.props.setChangeLogs}
                      />
                    )}
                  </div>
                </div>
              </div>

            </Form>

            <AgronomicInfo
              siteDetails={this.props.siteDetails}
              onSiteDataChange={this.props.onSiteDataChange}
              onTwigDetailsChange={this.props.onTwigDetailsChange}
              setChangeLogs={this.props.setChangeLogs}
            />

          </Card.Body>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

const connectedSiteBasicInfo = connect(mapStateToProps)(SiteBasicInfo);
export { connectedSiteBasicInfo as SiteBasicInfo };
