import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { CHART_NAME } from './duck/constants';
import { connect } from 'react-redux';
import { toggleSectionVisibility, toggleSeriesVisibility } from '../../../common/chart/utils';
import { closestLow } from './duck/graphUtility';
import { lastItem } from '../../../common/duck/utils';
import GraphFilter from './graphFilter';
import closeButton from "../../../../assets/images/left-arrow/close-1.png";
import _ from 'lodash';
import jsonexport from 'jsonexport';
import * as moment from 'moment';

export function labelSeries({ depthName, depth, name, seriesName, showTopSensorLabel }) {
  if (depthName) {
    if (depthName && depth) return `${depthName} (${depth} in)`;
    if (depthName) return `${depthName}`;
  } else if (showTopSensorLabel && name) {
    return `${name} (L)`;
  } else if (seriesName.indexOf("total") > -1) {
    return 'Total';
  } else if (name) {
    return `${name}`;
  } else {
    return seriesName;
  }
}

function SiteLegend({
  mode,
  tab,
  isGrouruAdmin,
  sitePlan,
  includedSensor,
  setTotal,
  siteName,
  disabledSeries,
  dataTypesCount,
  siteId,
  twigId,
  forceUpdateGraphData,
  curvesToRender,
  globalGraphControll,
  legendPoints,
  notifySeriesToggled,
  notifySeriesListToggled,
  measurements,
  notes,
  siteObjectId,
  showNotes,
  setShowNotes
}) {
  const curves = useMemo(() => {
    const curvesByName = curvesToRender.reduce((acc, c) => {
      if (c.name in acc) {
        acc[c.name].push(c);
      } else {
        acc[c.name] = [c];
      }
      return acc;
    }, {});
    for (let key in curvesByName) {
      if (key === "undefined") {
        delete curvesByName[key];
      } else {
        curvesByName[key] = curvesByName[key].sort((b, a) => b.depth - a.depth);
      }
    }

    return curvesByName;
  }, [curvesToRender]);


  return (
    <React.Fragment>
      {Object.keys(curves).map(measure => <LegendSection
        isGrouruAdmin={isGrouruAdmin}
        plan={sitePlan}
        checkTotal={setTotal}
        includedSensors={includedSensor}
        key={measure}
        // sectionLabel={measure}
        curves={curves[measure]}
        measurements={globalGraphControll.measurements}
        legendPoints={legendPoints}
        dataTypesCount={dataTypesCount}
        disabledSeries={disabledSeries}
        notifySeriesToggled={notifySeriesToggled}
        notifySeriesListToggled={notifySeriesListToggled} />)}
      {(tab === 'AW' && mode === 'absolute') ||
        (tab === 'TEMP' || tab === 'MOIST' || tab === 'custom') ?
        < div >
          <img src={closeButton} style={{ width: '10px', marginTop: '-3px' }} alt="close" /> indicates that sensor is not included in summary calculations.
        </div> : null
      }

      {
        measurements[0] !== 'GDD' ?
          <div className="legend-options">
            <GraphFilter
              siteId={siteId}
              twigId={twigId}
              forceUpdateGraphData={forceUpdateGraphData}
              graphControls={globalGraphControll.graphControls}
              dateParams={globalGraphControll.dateParams}
              measurements={globalGraphControll.measurements}
              selectedMeasurement={measurements[0]}
              notes={notes}
              siteObjectId={siteObjectId}
              showNotes={showNotes}
              setShowNotes={setShowNotes}
              depths={globalGraphControll.depths} />
          </div> : null
      }
    </React.Fragment >
  )
}

function LegendSection({ isGrouruAdmin, plan, includedSensors, checkTotal, sectionLabel, measurements, dataTypesCount, disabledSeries, curves, legendPoints, notifySeriesListToggled, notifySeriesToggled }) {

  const [shouldResetDisabledSeries, setShouldResetDisabledSeries] = useState(null);

  const prevSeriesNameList = useRef([]);
  const seriesNameList = useMemo(() => {
    const currentSeriesList = curves.map(series => series.seriesName);
    if (prevSeriesNameList.current.length === currentSeriesList.length) {
      // if same length
      for (let seriesName of currentSeriesList) {
        // if all items are not in prev -> current
        if (!prevSeriesNameList.current.includes(seriesName)) {
          prevSeriesNameList.current = currentSeriesList;
          setShouldResetDisabledSeries(true);
          return currentSeriesList;
        }
      }
      return prevSeriesNameList.current;
    } else {
      prevSeriesNameList.current = currentSeriesList;
      setShouldResetDisabledSeries(true);
      return currentSeriesList;
    }
  }, [curves]);

  const [checked, setChecked] = useState({
    ...Object.fromEntries(seriesNameList.map(series => [series, true])),
    checkedCount: curves.length,
    visibilityToggled: false
  });
  const unit = `${curves[0].unit}` === 'undefined' ? '' : `(${curves[0].unit})`;
  const sectionTitle = `${curves[0].unit}` !== '' ? `${curves[0].name}${unit}` : `${curves[0].name}`;

  function handleSectionVisibility() {
    if (checked.checkedCount === curves.length) {
      toggleSectionVisibility(CHART_NAME, seriesNameList, 'hide');
      setChecked({
        ...Object.fromEntries(seriesNameList.map(series => [series, false])),
        checkedCount: 0,
        visibilityToggled: true
      });
      notifySeriesListToggled(seriesNameList, 'hide');
      return
    }
    toggleSectionVisibility(CHART_NAME, seriesNameList, 'show');
    setChecked({
      ...Object.fromEntries(seriesNameList.map(series => [series, true])),
      checkedCount: curves.length,
      visibilityToggled: false
    });
    notifySeriesListToggled(seriesNameList, 'show');
  }

  const initialShouldResetDisabledSeries = shouldResetDisabledSeries === null;
  const prevDataTypesCount = useRef(dataTypesCount)
  const shouldDoHardReset = useRef(false);
  useEffect(() => {
    // whenever disabledSeries is changed
    // reset checked series
    if (initialShouldResetDisabledSeries) {
      setShouldResetDisabledSeries(false)
    } else if (disabledSeries.length === 0 || prevDataTypesCount.current !== dataTypesCount) {
      setShouldResetDisabledSeries(true);
      shouldDoHardReset.current = true;
      if (prevDataTypesCount.current !== dataTypesCount) {
        notifySeriesListToggled([], 'reset');
      }
    }
    return () => {
      prevDataTypesCount.current = dataTypesCount;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialShouldResetDisabledSeries, dataTypesCount, disabledSeries]);


  const prevMeasurements = useRef(measurements);
  useEffect(() => {
    // disable legend items indicators
    if (shouldResetDisabledSeries) {
      setChecked(chk => {
        // if the measurements are not changed
        // then any items in the legend that was previously disabled should stay disabled
        // unless dataTypes are changed
        if (!shouldDoHardReset.current && measurements === prevMeasurements.current) {
          return {
            ...Object.fromEntries(seriesNameList.map(series => [series, true])),
            ...chk,
            checkedCount: curves.length,
            visibilityToggled: false
          }
        } else {
          shouldDoHardReset.current = false;
          return {
            ...Object.fromEntries(seriesNameList.map(series => [series, true])),
            checkedCount: curves.length,
            visibilityToggled: false
          }
        }
      });
      setShouldResetDisabledSeries(false);
    }
    return () => {
      prevMeasurements.current = measurements;
    }
  }, [shouldResetDisabledSeries, measurements, curves.length, seriesNameList]);

  function totalChecked(checked) {
    checkTotal(checked);
  }

  useEffect(() => {
    const seriesWithYaxis = curves.find(curve => curve.isHidden && curve.hasYAxis);
    if (!seriesWithYaxis)
      return;
    if (checked.visibilityToggled && checked.checkedCount === 1 && checked[seriesWithYaxis.seriesName]) {
      toggleSeriesVisibility(CHART_NAME, seriesWithYaxis.seriesName);
      setChecked(checked => ({
        ...checked,
        [seriesWithYaxis.seriesName]: false,
        checkedCount: 0
      }));
    } else if (checked.checkedCount > 0 && !checked[seriesWithYaxis.seriesName]) {
      toggleSeriesVisibility(CHART_NAME, seriesWithYaxis.seriesName, 'show');
      setChecked(checked => ({
        ...checked,
        [seriesWithYaxis.seriesName]: true,
        checkedCount: ++checked.checkedCount
      }));
    }
  }, [curves, checked]);

  return (
    <React.Fragment>
      <div className="legend-section  d-flex justify-content-between border-bottom-black">
        <p>{sectionTitle}</p>
        <div className="d-flex justify-content-between">
          <label className="label ">
            <input type="checkbox" checked={checked.checkedCount === curves.length} name="radio" onChange={handleSectionVisibility} />
            <span className="checkmark"></span>
          </label>
          <div className="select-all">Select All</div>
        </div>
      </div>
      <div className="pt-20 ">
        {curves.map(curve => {
          if (curve.seriesName.includes('yaxis'))
            return null;

          // find closest lower value
          let value = null;
          if (curve.data.length > 0) {
            const firstXValue = curve.data[0].x;
            const lastXValue = lastItem(curve.data).x;
            if (firstXValue <= legendPoints.xValue && lastXValue >= legendPoints.xValue) {
              const currentDataPoint = curve.data[legendPoints.dataPointIndex];
              if (currentDataPoint && currentDataPoint.x === legendPoints.xValue) {
                value = currentDataPoint;
              } else {
                value = closestLow(curve.data, legendPoints.xValue);
              }
            }
          }

          let seriesLabel = labelSeries(curve);

          return <LegendItem
            isGrouruAdmin={isGrouruAdmin}
            sitePlan={plan}
            checkedIndex={curve.checkedIndex}
            incSensorList={includedSensors}
            setTotalChecked={totalChecked}
            key={curve.seriesName + curve.color} // series names not unique across local and hardware sensors
            isHovered={legendPoints.seriesIndex === curve.seriesIndex}
            label={seriesLabel}
            value={`${(value && typeof (value.y) === 'number') ? (curve?.seriesName?.includes("TEMP") ? value.y.toFixed(1): value.y.toFixed(2)) : '-- '}${curve.unit === undefined ? '' : curve.unit}`}
            color={curve.color}
            seriesName={curve.seriesName}
            checked={{ get: checked, set: setChecked }}
            disabledSeries={disabledSeries}
            notifySeriesToggled={notifySeriesToggled} />
        })}
      </div>
    </React.Fragment>
  )
}


function LegendItem({ isGrouruAdmin, sitePlan, incSensorList, checkedIndex, setTotalChecked, label, value, color: background, isHovered, seriesName, checked, notifySeriesToggled, disabledSeries }) {
  function handleSeriesVisibility() {
    toggleSeriesVisibility(CHART_NAME, seriesName);
    let measurementShortName = seriesName.split(" ")[0];
    let measureYaxis = `${measurementShortName} yaxis`;
    if (disabledSeries.includes(measureYaxis)) {
      notifySeriesToggled(measureYaxis);
    }
    checked.set(checkedObjectMap => {
      notifySeriesToggled(seriesName);
      if (seriesName.indexOf('total') > 0 && checkedObjectMap[seriesName]) {
        setTotalChecked(false);
      } else if (seriesName.indexOf('total') > 0 && !checkedObjectMap[seriesName]) {
        setTotalChecked(true);
      }
      if (checkedObjectMap[seriesName]) {
        return {
          ...checkedObjectMap,
          [seriesName]: false,
          checkedCount: checkedObjectMap.checkedCount - 1,
          visibilityToggled: true
        };
      }
      return {
        ...checkedObjectMap,
        [seriesName]: true,
        checkedCount: checkedObjectMap.checkedCount + 1,
        visibilityToggled: false
      };
    });
  }

  return (
    <div onClick={handleSeriesVisibility} className={`moisture-content ${checked.get[seriesName] ? "ticked" : "unticked"} ${(isHovered && checked.get[seriesName]) ? 'legend-item-active' : ''} d-flex justify-content-between`}>
      <div className="d-flex">
        {/* <span className="icon-done-24px" ></span> */}
        <div className="d-flex justify-content-between">
          <div className={`notification-check${checked.get[seriesName] ? ' notification-checked' : ''}`} style={{ background }}>
            {checked.get[seriesName] ? <span className="icon-done-24px"></span> : ""}
            {(isGrouruAdmin || sitePlan === 'Gold') && (checkedIndex && incSensorList?.indexOf(checkedIndex) === -1) ?
              <img src={closeButton} className="exclude-sensor" alt="close" />
              : null}
          </div>
          <p>{label}</p>
        </div>
      </div>
      <h6>{value}</h6>
    </div>
  )
}

function GraphLegend(props) {

  const curvesToRender = useMemo(() => {
    const newCurvesToRender = props.curvesToRender.filter((each) => each && each.meta && each.meta.shortName && (!each.meta?.shortName.includes("RAIN") && !each.meta?.shortName.includes("IRR")))
                              .filter(each => !each?.data?.name?.includes('PRED'));
    return newCurvesToRender.map((curve, seriesIndex) => ({
      color: curve.color,
      checkedIndex: curve.checkedIndex,
      name: curve.meta.measurement,
      depth: curve.meta.depth && curve.meta.depth.depth,
      depthName: curve.meta.depth && curve.meta.depth.name,
      data: curve.data.data,
      dataType: curve.meta.dataType,
      unit: curve.meta.unit,
      hasYAxis: curve.meta.hasYAxis,
      isHidden: curve.meta.isHidden,
      seriesName: curve.data.name,
      seriesIndex,
      showTopSensorLabel: curve.meta.showTopSensorLabel
    }))
  }, [props.curvesToRender]);
  //const [csvContent, setCsvContent] = useState(null);
  const [loadMsg, setLoadMsg] = useState(false);
  const [hardware, setHardware] = useState(null);
  const [local, setLocal] = useState(null);
  const replaceName = useCallback((sensors) => {
    let a = Object.values(sensors);
    let c = a[0];
    var sensorData = Object.keys(c).map(key => {
      return { name: key, rename: c[key]['name'] };
    });
    return sensorData;
  }, []);


  useEffect(() => {
    if (props.hardwareSensors) {
      let hwData = replaceName(props.hardwareSensors);
      setHardware(hwData);
    }
  }, [props.hardwareSensors, replaceName]);

  useEffect(() => {
    if (props.localSensors) {
      let hwData = replaceName(props.localSensors);
      setLocal(hwData);
    }
  }, [props.localSensors, replaceName]);

  const createJson = useCallback((groupedAw, measureName, initial) => {
    let initialGrouping = { ...initial };
    for (let k in groupedAw) {
      let timeKey = Object.keys(groupedAw[k]);
      let timevalues = Object.values(groupedAw[k]);
      for (let j in timevalues) {
        let entries = Object.entries(timevalues[j]);
        if (_.isEmpty(initial) && hardware) {
          for (let l in entries) {
            let hw = hardware.filter(h => {
              return h.name === entries[l][0];
            });
            if (entries[l][0] !== 'xValue' && hw[0]) {
              initialGrouping[timeKey + '_' + entries[l][0]] = {
                'timestamp': moment(timeKey[0]).format('DD MMM YYYY H:mm'),
                'sensorName': hw[0].rename,
                [measureName]: entries[l][1],
              }
            }
          }
        } else {
          for (let l in entries) {
            if (entries[l][0] !== 'xValue') {

              // if new entry add timestamp and sensorName
              if (initialGrouping[timeKey + '_' + entries[l][0]] === undefined) {
                let lc;
                if (local) {
                  lc = local.filter(h => {
                    return h.name === entries[l][0];
                  });
                }


                initialGrouping[timeKey + '_' + entries[l][0]] =
                {
                  'timestamp': moment(timeKey[0]).format('DD MMM YYYY H:mm'),
                  'sensorName': lc ? lc[0]?.rename : ''
                }

              }
              initialGrouping[timeKey + '_' + entries[l][0]] = {
                ...initialGrouping[timeKey + '_' + entries[l][0]],
                [measureName]: entries[l][1],
              }
            }
          }
        }


      }
    }
    return initialGrouping;
  }, [hardware, local]);

  useEffect(() => {
    if (loadMsg) {
      const awAbsolute = props.csvdata?.aw?.absolute;
      const moistureAbsolute = props.csvdata?.moisture?.absolute;
      const volwatcontAbsolute = props.csvdata?.volwatcont?.absolute;
      const tempAbsolute = props.csvdata?.temp_f?.absolute;


      const awLocal = props.csvdata?.aw_local?.absolute;
      const moistLocal = props.csvdata?.volwatcont_local?.absolute;
      const tempLocalAbsolute = props.csvdata?.temp_f_local?.absolute;

      const salinityAbsolute = props.csvdata?.salinity?.absolute;
      const salinityLocalAbsolute = props.csvdata?.salinity_local?.absolute;


      const awRelative = props.csvdata?.aw?.relative;
      const moistRelative = props.csvdata?.moisture?.relative;
      const volwatRelative = props.csvdata?.volwatcont?.relative;
      const volwatLocalRelative = props.csvdata?.volwatcont_local?.relative;

      const vicAbsolute = props.csvdata?.vic?.absolute;
      let finalJson;
      if (awAbsolute) {
        setLoadMsg(true);
        let groupedAw = _.chain(awAbsolute)
          .groupBy('xValue')
          .map((value, key) => ({ [key]: value[0] }))
          .value();
        finalJson = createJson(groupedAw, 'availableWaterAbsolute(inch)', {});
        if (awLocal) {
          let groupedAw = _.chain(awLocal)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedAw, 'availableWaterAbsolute(inch)', finalJson);
        }

        if (awRelative) {
          let groupedawRelative = _.chain(awRelative)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedawRelative, 'availableWaterRelative(%)', finalJson);
        }
        if (moistureAbsolute) {
          let groupedmoisture = _.chain(moistureAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoisture, 'moistureAbsolute(%)', finalJson);
        }
        if (moistLocal) {
          let groupedmoisture = _.chain(moistLocal)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoisture, 'moistureAbsolute(%)', finalJson);
        }
        if (moistRelative) {
          let groupedmoistRelative = _.chain(moistRelative)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoistRelative, 'moistureRelative(%)', finalJson);
        }
        if (volwatcontAbsolute) {
          let groupedmoisture = _.chain(volwatcontAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoisture, 'moistureAbsolute(%)', finalJson);
        }
        if (volwatRelative) {
          let groupedmoistRelative = _.chain(volwatRelative)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoistRelative, 'moistureRelative(%)', finalJson);
        }
        if (volwatLocalRelative) {
          let groupedmoistRelative = _.chain(volwatLocalRelative)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoistRelative, 'moistureRelative(%)', finalJson);
        }


        if (tempAbsolute) {
          let groupedmoisture = _.chain(tempAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoisture, 'tempAbsolute(F)', finalJson);
        }
        if (tempLocalAbsolute) {
          let groupedmoisture = _.chain(tempLocalAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedmoisture, 'tempAbsolute(F)', finalJson);
        }

        if (vicAbsolute) {
          let groupVic = _.chain(vicAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupVic, 'vicAbsolute', finalJson);
        }
        if (salinityAbsolute) {
          let groupSalinity = _.chain(salinityAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupSalinity, 'salinityAbsolute(dS/m)', finalJson);
        }
        if (salinityLocalAbsolute) {
          let groupedSalinity = _.chain(salinityLocalAbsolute)
            .groupBy('xValue')
            .map((value, key) => ({ [key]: value[0] }))
            .value();
          finalJson = createJson(groupedSalinity, 'salinityAbsolute(dS/m)', finalJson);
        }
        finalJson = Object.values(finalJson);
        finalJson.sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp);
        });
        let csvData;
        jsonexport(finalJson, function (err, csv) {
          if (err) return console.error(err);
          csvData = csv;
        });
        setLoadMsg(false);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${props.siteId}.csv`;
        hiddenElement.click();
      }
    }

  }, [loadMsg, createJson, props.csvdata, props.siteId]);

  const downloadCsv = () => {
    setLoadMsg(true);
  }

  return (
    <div className="graph-legend-container ">
      {/*<div className="header-text d-flex justify-content-end  border-bottom-black">
         <h4>Legend + Options</h4> 
        <button onClick={resetSeriesVisibility}> Reset</button>
      </div>*/}

      <div className="legend-sets">
        <SiteLegend
          mode={props.mode}
          tab={props.tab}
          isGrouruAdmin={props.isGroguruAdmin}
          sitePlan={props.plan}
          includedSensor={props.includedSensorsList}
          setTotal={props.totalChecked}
          siteName="This Site ID (122KL)"
          siteId={props.siteId}
          twigId={props.twigId}
          forceUpdateGraphData={props.forceUpdateGraphData}
          legendPoints={props.legendPoints}
          curvesToRender={curvesToRender}
          legendControls={props.legendControls}
          globalGraphControll={props.globalGraphControll}
          disabledSeries={props.disabledSeries}
          dataTypesCount={props.dataTypesCount}
          notifySeriesToggled={props.notifySeriesToggled}
          notifySeriesListToggled={props.notifySeriesListToggled}
          measurements={props.measurements}
          notes={props.notes}
          siteObjectId={props.siteObjectId}
          showNotes={props.showNotes}
          setShowNotes={props.setShowNotes}
        />
      </div>
      {props.measurements[0] !== 'GDD' && props.csvdata?.aw?.absolute && (props.isGroguruAdmin || props.plan === 'Gold') ? <div>
        <button className="dowload-csv" onClick={downloadCsv}>Download all data</button>
      </div> : null}
      {loadMsg ? <div style={{ marginLeft: '30px' }}>Preparing data for download...</div> : null}

    </div>

  );
}

const mapStateToProps = state => {
  const { profile } = state.userDetails.profileDetails;
  return {
    isGroguruAdmin: profile.role.name === 'groguru_admin'
  }
}
export default connect(mapStateToProps)(GraphLegend)