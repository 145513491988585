import React from 'react';
import iconimage from "../../../../assets/images/left-arrow/chevron-left.png";

export default function ArrowToggle({isArrowDirLeft = false, onChange = () => null}) {
    return(
    
        <div className="legendControlArrow">
            <img
                src={iconimage}
                className={isArrowDirLeft ? "" : "arrow-icon"}
                alt="Expand the Graph"
                onClick={onChange}
            />
        </div>
    )
}