import React from "react";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import PinColors from "./pincolors";
import ProgressBar from "./progressbar";
import { isPlanActive } from "../../common/duck/planActive";

export default function GraphSiteItem(props) {
  const id = useParams().farmid;
  const farmid = props.farmid ? props.farmid : id;
  const { name, siteId, twigId, id: siteObjectId, status } = props.data;
  const state = props?.data?.twigDetails?.state;
  const { plantType } = props?.data && props?.data.twigDetails && props?.data.twigDetails.settings;
  const totalpercentage = props.data?.bucketDetails?.totalDictionary?.percentageFilled[0]?.total
  // const totalawValue = props.data?.bucketDetails?.totalDictionary.aw[0].total
  const totalBucketValue = props.data?.bucketDetails?.totalDictionary.bucketValue[0].total;
  const totalRP = props.data?.bucketDetails?.totalDictionary?.RP
  const totalWL1 = props.data?.bucketDetails?.totalDictionary.WL1

  const totalWL2 = props.data?.bucketDetails?.totalDictionary?.WL2

  let readilyAWValue;
  let redThreshold = totalRP && totalRP[0]?.total;
  let ARP;
  if ((totalpercentage-redThreshold) > 0) {
    ARP = (totalpercentage-redThreshold)/100;
  } else {
    ARP = 0;
  }
  readilyAWValue = (ARP * totalBucketValue).toFixed(2);
  let planActive = isPlanActive(props.userRole, props.data.planDetails);

  return (
    <div className="graph-item">
      <div className="users">
        {(name, siteId, twigId, status === 'ACTIVE') ? (
          <Card>
            <Card.Header>
              <div className="row">
                <div className="col-9 pr-5 mb-10">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex  ">
                      <div className="headers pl-20">
                        <h6>{name}</h6>
                      </div>
                    </div>
                    <div className="crop-type ">
                      <p className="pt-5">{plantType.value}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between progress-graphs">
                    <div className="disable pr-5">
                      <h6>Readily Avail. Water</h6>
                      <h6 className="aw-label" title={`${readilyAWValue}”`}>{readilyAWValue}”</h6>
                    </div>
                    <div className="disable pl-0 pr-5">
                      <h6>Deficit</h6>
                      <h6 className="aw-label" title={`${totalBucketValue}”`}>{totalBucketValue}”</h6>
                    </div>
                  </div>
                  <div className=" progress-graphs d-flex" style={{ marginLeft: '5%'}}>
                    <div className=" pl-0 pr-10 graph">
                      {(totalpercentage || totalpercentage === 0) ?
                        <ProgressBar
                          totalpercentage={totalpercentage}
                          RP={totalRP}
                          WL1={totalWL1}
                          WL2={totalWL2}
                        />
                        : ''
                      }

                    </div>
                  </div>
                </div>
                <div className="col-3 pl-0">
                  <Link
                    to={ planActive ? {
                      pathname: `/farm/${farmid}/site/${siteId}/${siteObjectId}/${twigId}/graph`,
                      state: props.data,
                      query: { loadFromPopUp: true }
                    } : "#" }
                    onClick={props.close}
                  >
                    {state && (
                      <div className="mapmarker">
                        <PinColors state={state} />
                      </div>
                    )}
                    <p className="mt-35 mb-0 w-78 pinSiteId">
                      ID# {siteId}

                    </p>
                  </Link>
                </div>
              </div>
            </Card.Header>
          </Card>
        ) : (
            ""
          )}

      </div>
    </div>
  );
}


