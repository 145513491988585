import React from "react";
import { Card } from "react-bootstrap";
import aboutService from "../about/duck/aboutService";
import createFarm from "../../../../assets/images/farms/farm-create/group-2.png";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { Component } from "react";
import Message from "../../../common/messages";

const Item = ({ entity: { name, type, key } }) => (


  <div className="search-dropdown ">
    <div className="search-content d-flex ">
      {/* <div className="">
                    <img src={uploadedImg} alt=""></img>
                  </div> */}
      <div className="search-text pl-20">
        <h6 key={key}>{`${name}`}</h6>
        <p>{`${type}`}</p>
      </div>
    </div>
    {/* <hr></hr> */}
  </div>
);
class AddNote extends Component {
  state = {
    data: {
      image: null,
      fileId: "",
    },
    id: null,
 
    uploadedImage: null,
    notes: '',
    value: "",
    suggestion: [],
    autosuggest: false,
    publish:false,
    responseMsg:null,
    form:null,
    errors: null,
    serviceError: null,
   
  };

  componentDidMount() {
    aboutService.getUsers().then((res) => {
      this.setState({ suggestion: res });

    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteDetails !== prevProps.siteDetails) {
      this.setState({
        id: this.props.siteDetails.id,
      });
    }
  }

  htmlUpload = React.createRef()

  handleNote = (e) => {
    this.setState({ notes: e.target.value });
  };


  handlePublish = () => {

    this.setState({responseMsg:null,publish:!this.state.publish,notes:''})
    let responseMsg='';
    if(this.state.notes.length!==0 && this.state.form===null)
    {
        responseMsg="'Notes' updated";
    }
    else if (this.state.notes.length===0 && this.state.form)
    {
      responseMsg="'Image' updated";
    }
    else if(this.state.notes.length!==0 && this.state.form)
    {
      responseMsg="'Notes & Image' updated";
    }
    else{
          responseMsg=null
    }
  const objid=this.state.id ?this.state.id:this.props.siteDetails.id

    const imageFormData = new FormData();
    this.state.notes!==null &&imageFormData.append('site',this.state.notes&&this.state.notes);
    this.state.form!==null && imageFormData.set('file',this.state.form);
      aboutService.postNotes(objid,imageFormData).then(res=>{
        
       this.setState({responseMsg})
        this.props.onNoteUpdate({res});
     
        
      }).catch(error => {
          this.setState({ errors: { serviceError: `${error.message}: 'Notes' could not be updated` } });
        });
        this.setState({uploadedImage:null,form:null})

  }
      

  handledata = () => {
    this.state.suggestion.map((org) => org);
  };

  handleAdd=()=>  {
   
  this.setState({responseMsg:null, publish:!this.state.publish})
    }

  openFileUpload = (e) => {
    this.htmlUpload.current.click();
  }
  onUpload = (e) => {
    const imageData = e.currentTarget.files[0];
    this.setState({form:imageData})
    this.setState({ responseMsg: null, errors: null }, () => {
      aboutService.postImage(imageData,this.state.id)
        .then(res =>{ 
         this.setState({
              data: {image:res.file.url },
          uploadedImage: URL.createObjectURL(imageData),
            })
          
          }).catch(error => {
            this.setState({ errors: { serviceError: `${error.message}: 'Error Updating 'image'` } });
          });
        
    });
    
  }

  render() {

    let arrays = [];
    this.state.suggestion.map((org) => {
      let name = org.organisation.name;
      let type = org.organisation.type;
      let key = Math.random();
      return arrays.push({ name, type, key });
    });

   return (
      <div className="addNote-container mt-50">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div>
              <h6>Add a Note</h6>
            </div>
            <div className="d-flex pointerClass">
              {/* <button>
                <i className="fas fa-camera"></i>
              </button> */}
              {this.state.publish===false ?
              <button className="pl-15" onClick={this.handleAdd} >
                Add
              </button>:
              <button className="pl-15" onClick={this.handlePublish}>
                Publish
              </button>}
            </div>
          </Card.Header>
          <hr></hr>
          {this.state.publish===true &&
          <Card.Body>
            <div className="addnote-box">
              <ReactTextareaAutocomplete
                className="textArea"
                onChange={this.handleNote}
                loadingComponent={() => <span>Loading</span>}
                trigger={{
                  "@": {
                    dataProvider: (token) => {
                      return arrays;
                    },
                    component: Item,
                    output: (item, trigger) => "@" + item.name,
                  },
                }}
              />

              <div  className="image-area mt-0 ml-20" style={{ width: "100px" }}>

              <div className="logo" onClick={this.openFileUpload}>
              <img  
                  src={this.state.uploadedImage ||createFarm } alt=''
                  />
              </div>
              <input name="image" type="file" onChange={this.onUpload} ref={this.htmlUpload}
                     style={{"display": "none"}}/>
                </div>

                
            </div>
           
          </Card.Body>}
        </Card>

        <div className ="messagePopup">
        {this.state.errors && this.state.errors.serviceError!==null && <Message variant="error" message={this.state.errors.serviceError} />}
       {this.state.responseMsg!==null && this.state.errors===null&&
       <Message variant="success" message={this.state.responseMsg} />}

        
        </div>
      </div>
    );
  }
}
export default AddNote;
