import React, { Component } from 'react';
import {
    Dropdown,
} from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom';

class ToggleDropDown extends Component {
    state = {
        selectedLabel: ''
    }

    constructor(props) {
        super(props);
        this.state.selectedLabel = props.selected;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({selectedLabel: this.props.selected});
        }
    }

    changeLabel = (key, e) => {
        let selectedLabel = e.target.innerHTML;
        this.setState({ selectedLabel });
        this.props.onChange && this.props.onChange(selectedLabel);
    }

    render() {
    
        return (
            <div className="showAs-section">
                <Dropdown>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="filter-width">
                        <div className="btn-padding d-flex">
                            <div className="  pr-0 pl-0">
                                <p className="text-right ">{this.props.label}</p>
                                <h6 className="text-right m-0">{this.state.selectedLabel}</h6>
                            </div>

                            <div className="  pl-20 ">
                                <img src={this.props.icon} className=" mt-15" alt="icon" />
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="m-0">
                        {this.props.options[0] && this.props.options.map(option => <Dropdown.Item
                            key={option.label}
                            as={Link}
                            onSelect={this.changeLabel}
                            to={option.link}
                            className="bb">{option.label}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

export default withRouter(ToggleDropDown);
