import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import cornerIcon from "../../assets/images/corner-arrow/rectangle.png";
import { connect } from "react-redux";
import farmService from "../farm/duck/farmService";
import Search from "../common/search";
import SiteEmptyCase from "../sites/sidemenu/siteEmptyCase";
import farmDefaultImage from "../../assets/images/farms/farmdefaultimg.png";

class SelectFarm extends Component {

  state = {
    data: [],
    show: false,
    selectAll: false,
    selectedfarms: [],
    search: "",
    errors: null,
    data2: [],
    notfound: false,
    namesarray: [],
    selectBoolean: false,
    loading: false,
  };

  requestObj = farmService.getFarmsForUserRequest();
  apiCall = null;

  componentDidMount() {
    this.requestObj.request(null, null, "false").then((farms) => {
      const farmlength = farms.length;
      this.setState({
        farmlength,
        data: farms.map(({ name, physicalAddress, objectId, image }) => ({
          name,
          physicalAddress,
          objectId,
          image
        })),
        data2: farms.map(({ name, physicalAddress, objectId, image }) => ({
          name,
          physicalAddress,
          objectId,
          image
        }))

      })
    });
    this.props?.getMultipleFarms && this.setState({ selectedfarms: this.props.getMultipleFarms })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updatedFarmdata !== prevProps.updatedFarmdata) {
      this.requestObj.request().then((farms) => {
        const farmlength = farms.length;

        this.setState({
          farmlength,
          data: farms.map(({ name, physicalAddress, objectId, image }) => ({
            name,
            physicalAddress,
            objectId,
            image
          })),
          data2: farms.map(({ name, physicalAddress, objectId, image }) => ({
            name,
            physicalAddress,
            objectId,
            image
          }))

        })
      }
      );
    }

  }
  componentWillUnmount() {
    this.requestObj.cancel();
  }



  handleModal() {
    this.setState({ show: !this.state.show });
  }


  onSearch = (e) => {
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    let selectBoolean = true;

    this.setState({ search: searchQuery, selectBoolean });
    if (this.apiCall !== null && this.requestObj) {
      this.requestObj.cancel("SEARCH_SKIPPED");
      clearTimeout(this.apiCall);
      this.apiCall = null;
      this.requestObj = null;
    }
    this.requestObj = farmService.getFarmsForUserRequest();

    this.apiCall = setTimeout(() => {
      this.setState({loading : true})
      this.requestObj
        .request(searchQuery, null, "false")
        .then((data) => {
          this.setState({loading : false})
           selectBoolean = this.state.search.length === 0 ? false : true; this.setState({ data, selectBoolean }); 
          })
        .catch((error) => !error.isCancelled && this.setState({ data: [] }));
    }, 500);
  };


  Modal() {
    let selectAll = (this.props?.getMultipleFarms?.length === this.state.farmlength) ? true : false;
    let selectedfarms = (this.props?.getMultipleFarms !== null && this.props?.getMultipleFarms !== undefined) ? this.props?.getMultipleFarms : []
    this.setState(state => ({
      search: '',
      show: !state.show,
      data: state.data2,
      selectBoolean: false,
      selectedfarms,
      selectAll


    }));
  }
  handleselection() {
    this.setState({ show: !this.state.show })
    this.setState({data: this.state.data2, search: '', selectBoolean: false})
    if(this.state?.selectedfarms?.length > 1) {
      this.props.mainLoad(true)
    setTimeout(() => {
      this.props.onSubmit(this.state?.selectedfarms, this.state?.selectAll);
    }, 500);
  } else{
    this.props.onSubmit(this.state?.selectedfarms, this.state?.selectAll);
  }
  }
  handleChange = (e) => {

    if (e.target.value === "selectAll") {
      this.setState({ selectAll: !this.state.selectAll });
      if (!this.state.selectAll) {
        //let selectAll=(this.state?.selectedfarms?.length===this.state.farmlength)?true:false;
        let selectedfarms = [];

        this.state.data.map(({ objectId, name }) => {

          return selectedfarms.push({ "name": name, "objectId": objectId });
        });
        this.setState({ selectedfarms });
      } else {
        this.setState({ selectedfarms: [] });
      }
    } else {
      if (this.state.selectedfarms?.some(farm => farm.objectId === e.target.value)) {

        const selectedfarms = this.state.selectedfarms.filter(
          (farms) => farms.objectId !== e.target.value
        );

        this.setState({ selectedfarms, selectAll: false });
      } else {

        let selectedfarms = this.state.selectedfarms && [...this.state.selectedfarms];
        selectedfarms && selectedfarms.push({ "name": e.target.name, "objectId": e.target.value });
        this.setState({ selectedfarms });
      }
    }
  };


  render() {
    const selectedstatus = (this.state?.selectedfarms?.length === this.state.farmlength) ? true : false;
    return (
      <div>
        <Button
          onClick={() => {
            this.handleModal();
          }}
          variant="none"
          className="create-farm-btn"
        >
          <img src={cornerIcon} alt="icon"></img>
        </Button>
        <Modal
          className="select-farm"
          show={this.state.show}
          onHide={() => this.Modal()}
          size="lg"
          centered
        >
          <Modal.Header
            closeButton={false}
            className="d-flex justify-content-between"
          >
            <p>Select Farms to Show Site IDs </p>
            <div className="d-flex header-btn">
              <p
                className="text-right cp"
                onClick={() => {
                  this.Modal();
                }}
              >
                {" "}
                Close
              </p>
              <p
                className="save-text"
                onClick={() => {
                  this.handleselection();
                }}
              >
                {" "}
                Save
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p className="pr-20">
              Choose below which farms you want to view the site IDs for. You
              may choose one, multiple, or all farms you manage.
            </p>
            <div className="select-container">
              <div className="d-flex justify-content-between ">
                <h6>
                  Sort By:{" "}
                  <span>
                    Alphabetical <img src={cornerIcon} alt="corner-icon"></img>
                  </span>
                </h6>
                {!this.state.selectBoolean && <div className="d-flex pr-6">
                  <h6 className="pr-15">Select All</h6>
                  <label className="label ">
                    <input
                      type="checkbox"
                      checked={selectedstatus}
                      name="radio"
                      value="selectAll"
                      onChange={this.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>}
              </div>
              <div className="select-farm-search">
                {" "}
                <Search
                  placeholder="Search Farms and Site IDs ..."
                  onSearch={this.onSearch}
                />{" "}
              </div>
              <div className="content-area">
                {this.state.data !== [] && this.state.data.length !== 0 ? (
                  this.state.data.map((farm) => (

                    <div key={farm?.objectId} className="d-flex  justify-content-between">
                      <div className="profile-area d-flex">
                        <div className="image">
                          <img src={farm?.image || farmDefaultImage} alt="user profile"></img>
                        </div>
                        <div className="text-section">
                          <h1>{farm?.name}</h1>
                          <p>{farm?.physicalAddress} </p>
                        </div>
                      </div>
                      <div className="modalCheckbox">
                        <label className="label mt-10">
                          <input
                            type="checkbox"
                            className="fixedModalCheckBox"
                            checked={

                              this.state.selectedfarms?.some(selected => selected?.objectId === farm?.objectId)
                            }
                            name={farm?.name}
                            value={farm?.objectId}
                            onChange={this.handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  ))
                ) : (
                  <SiteEmptyCase search={this.state.search} />
                )}
              </div>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

//export default SelectFarm;
const mapStateToProps = state => ({
  getMultipleFarms: state.farms.multiFarmdata,



});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(SelectFarm);