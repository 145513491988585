import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { SearchByGroups } from '../dashboard/searchByGroups.jsx'
import { connect } from 'react-redux';
import NavFarmFilter from './farmNavFilter';


class FarmMastHead extends Component {
  render() {
    return (
      <div className="master-header container-fluid">
        <div className="row">
          <div className="col-lg-6 ">
            <SearchByGroups sort={this.props.sort} search={this.props.search} farmSelect={this.props.farmSelect}/>
          </div>
          <div className="col-lg-6 navbar-area">
            <Navbar bg="light" expand="lg">
              <Navbar aria-controls="basic-navbar-nav" />
              <Navbar id="basic-navbar-nav">
                <NavFarmFilter agronomic={this.props.agronomic}/>
              </Navbar>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.userDetails.profileDetails.profile.name
});

export default connect(mapStateToProps)(FarmMastHead);
