import http from '../../../../common/duck/httpService';
const {getDataOrError} = http;

function getNotes(siteobjid) {
    return http
      .get(`/installationNotes/site/${siteobjid}?sort=date`)
      .then((response) => response.data.data);
  }


  function postNotes(siteobjid,payload)
  {
  return http.post(`/installationNotes/site/${siteobjid}`,payload
    
)
    .then((response) => getDataOrError(response));
}
  


function postImage(imageData,parentId) {
  const imageFormData = new FormData();
  imageFormData.append('file', imageData);
  imageFormData.append('parentId', parentId);
  imageFormData.append('type', 'installation');
  return http.post('/file/image', imageFormData).then(response => getDataOrError(response));
}


function getUsers() {
  return http
    .get(`/user/myteam/users`)
    .then((response) => response.data.data);
}
  export default {
      getNotes,
      postNotes,
      getUsers,
      postImage
  }
