import React, { Component } from "react";
import { Card, Table } from "react-bootstrap";
import siteService from "../../duck/siteService";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ExpectedStages extends Component {
  constructor(props) {

    super(props);
    this.state = {
      errorMessage: "",
      disabled: true,
      expectedStage: props.siteDetails?.twigDetails?.settings?.expectedStages?.value ||
        [{ "stageId": 1, stageDate: "NO DATE" }, { "stageId": 2, stageDate: "NO DATE" }, { "stageId": 3, stageDate: "NO DATE" }, { "stageId": 4, stageDate: "NO DATE" }],
      plantingDate: props.siteDetails?.twigDetails?.settings?.plantingDate?.value
    };

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.siteDetails?.twigDetails?.settings?.expectedStages?.value !==
      this.props.siteDetails?.twigDetails?.settings?.expectedStages?.value) {
      this.setState({ expectedStage: this.props.siteDetails?.twigDetails?.settings?.expectedStages?.value || [] })
    }
  }


  onSave = () => {
    const siteObjectId = this.props.siteDetails.id;
    const payload = { expectedStages: this.state.expectedStage }
    siteService.updateSite(siteObjectId, payload)
      .then(response => {
      }).catch(error => {
      });
  }

  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled
    });

    if (!this.state.disabled) {
      if (this.state.expectedStage.findIndex((object) => object.stageDate === "NO DATE") > -1) {
        this.setState({
          errorMessage: "Please Enter Dates for all Stages"
        })
        return;
      } else {
        const { expectedStage } = this.state;
        for (let i = 0; i < expectedStage.length - 1; i++) {
          let date1 = new Date(expectedStage[i].stageDate).getTime();
          let date2 = new Date(expectedStage[(i + 1)].stageDate).getTime();
          let diff = date2 - date1;
          if (diff < 0) {
            this.setState({
              errorMessage: "Please Enter Stage Dates In Sequence"
            })
            return;
          } else if (diff === 0) {
            this.setState({
              errorMessage: "Multiple stages cannot happen on the same Date"
            })
            return;
          }
        }
      }
      this.onSave();
    }

  };

  handleChange = (date, index) => {
    let newExpectedStage = [...this.state.expectedStage]
    newExpectedStage[index].stageDate = date
    this.setState({ expectedStage: newExpectedStage, errorMessage: '' })
  }

  getMinDate = (prevStageDate) => {
    let minDate = new Date(new Date(prevStageDate).getTime() + (1 * 24 * 60 * 60 * 1000));
    return minDate;
  }

  render() {
    return (
      <div>
        <div className="harware-container">
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between">
                <h5>Expected Stages</h5>
                <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button>
              </div>
            </Card.Header>
            <Card.Body>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Stage ID</th>
                    <th>Stage Date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.expectedStage.map((object, index) => {
                    return (
                      <tr key={index}>
                        <td>{object.stageId}</td>
                        <td>
                          {object.stageDate !== "NO DATE" ?
                            (
                              <DatePicker
                                className="manualDatePicker"
                                selected={new Date(object.stageDate)}
                                dateFormat='MM/dd/yyyy'
                                minDate={this.getMinDate(this.state.expectedStage[index - 1]?.stageDate || this.state.plantingDate)}
                                onChange={(date) => this.handleChange(date, index)}
                                disabled={this.state.disabled}
                              />
                            ) : (
                              <DatePicker
                                className="manualDatePicker"
                                dateFormat='MM/dd/yyyy'
                                minDate={this.getMinDate(this.state.expectedStage[index - 1]?.stageDate || this.state.plantingDate)}
                                onChange={(date) => this.handleChange(date, index)}
                                disabled={this.state.disabled}
                                placeholderText='ENTER A DATE'
                              />
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </Table>
              <h5 style={{ color: '#ff0000' }}>{this.state.errorMessage ? this.state.errorMessage : ""}</h5>
            </Card.Body>
          </Card>
        </div>
      </div>

    );
  }
}

export default ExpectedStages;

