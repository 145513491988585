import http from "../../common/duck/httpService";
import logger from "../../common/duck/logService";
import localStorageService from "../../common/duck/localStorageService";

const getInvitationDetails = (invitationId) => {
    return http.get(`invitations/${invitationId}`)
        .then(response => {
            return response.data.data;
        }).catch(error => {
            logger.log(error);
            return Promise.reject(error);
        });
}

const signUp = (userData) => {
    return http.post(`user/signup/${userData.username}`, userData)
        .then(response => {
            const signUpData = response.data.data;
            localStorageService.storeUserId(signUpData.id);
            localStorageService.storeToken(signUpData.sessionToken).then(() => {
                http.setToken(localStorageService.getToken());
            });
        }).catch(error => {
            return Promise.reject(error.response.data);
        })
}

export default {
    getInvitationDetails,
    signUp
}
