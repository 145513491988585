import userTypes from './userTypes';
import { combineReducers } from "redux";

let user = null;
const initialState = user ? { loggedIn: true, user } : {};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.email
            };
        case userTypes.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                user: action.payload
            };
        case userTypes.LOGIN_FAILURE:
            return {
                loggingIn: false,
                error: action.payload
            };
        case userTypes.LOGOUT:
            return {};
        default:
            return state
    }
}

const profileReducer = (state = {}, action) => {
    switch (action.type) {
        case userTypes.PROFILE_REQUEST:
            return {}
        case userTypes.PROFILE_SUCCESS:
            return { profile: action.payload }
        case userTypes.PROFILE_FAILURE:
            return { error: action.error, unAuthorized: action.unAuthorized }
        case userTypes.PROFILE_UPDATE_CARDS:
            return {
                profile: {
                    ...state.profile,
                    stripeCustomerDetails: {
                        ...state.profile.stripeCustomerDetails,
                        sources: { data: action.payload }
                    }
                }
            }
        case userTypes.PROFILE_UPDATE_CUSTOMER:
            return {
                profile: {
                    ...state.profile,
                    stripeCustomerDetails: action.payload
                }
            }
        default:
            return state;
    }
}

const preferenceReducer = (state = {}, action) => {
    switch (action.type) {
        case userTypes.PREFERENCE_REQUEST:
            return {}
        case userTypes.PREFERENCE_SUCCESS:
            return { preferences: action.payload }
        case userTypes.PREFERENCE_FAILURE:
            return {}
        case userTypes.PREFERENCE_UPDATE_GRAPH_TABS:
            return { ...state }
        case userTypes.SELECTED_SITE:
            return { ...state, selectedSite: action.payload }
        case userTypes.UPDATE_BATTERY:
            return { ...state, batteryState: action.payload }
        case userTypes.UPDATE_RSS:
            return { ...state, rssState: action.payload }
        case userTypes.UPDATE_SORT:
        case userTypes.UPDATE_DEFAULT_LANDING:
        case userTypes.UPDATE_USER_GRAPH_PREFERENCES_FOR_SITE:
            return { ...state, preferences: { ...action.payload } }
        default:
            return state;
    }
}


const initialParentDetails = { parentType: null, parentName: null, isDirectGroguruChild: null, loadingStatus: null };
const userParentReducer = (state = initialParentDetails, action) => {
    switch (action.type) {
        case userTypes.PARENT_REQUEST:
            return { parentName: null, loadingStatus: 'pending' }
        case userTypes.PARENT_SUCCESS:
            return action.payload;
        case userTypes.PREFERENCE_FAILURE:
            return action.error;
        default:
            return state;
    }
}

export default combineReducers({
    loginDetails: loginReducer,
    profileDetails: profileReducer,
    userPreferences: preferenceReducer,
    userPrivilages: userParentReducer
});
