import React, {useContext,useState} from 'react';
import {SiteBasicInfo} from './basicInfo';
import ChangeLog from './changeLog';
import AddNote from './addNote';
import PreviousNote from './previousNote';
import {SiteUpdate} from '../graph/duck/siteContext';

export default function AboutBody(props) {

    const sitePropUpdate = useContext(SiteUpdate);
    const [updatedNotes,setNotes]=useState(null);
    const [updateChangeLogs,setChangeLogs]=useState(false);

    return <div className="about-container">
        <div className="row">
            <div className="col-lg-8 col-12 ">
                <SiteBasicInfo
                    siteDetails={props.siteDetails}
                    onSiteDataChange={sitePropUpdate.handleSiteDetailsUpdate}
                    onTwigDetailsChange={sitePropUpdate.handleTwigDetailsUpdate}
                    setChangeLogs={setChangeLogs}
                    />
            </div>
            <div className="col-lg-4">

                
                <ChangeLog updateChangeLogs={updateChangeLogs} siteDetails={props.siteDetails}/>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-5 col-12">
                <AddNote siteDetails={props.siteDetails} onNoteUpdate={setNotes}/>
            </div>
            <div className="col-lg-7 col-12">
                <PreviousNote siteDetails={props.siteDetails} updatedNotes={updatedNotes} 
               />
            </div>
        </div>
    </div>
}
