import React, { Component } from "react";
import { connect } from "react-redux";
import configService from "./duck/configService";
import { Card, Form } from "react-bootstrap";
import Message from "../../../common/messages";


class SentekApiDetails extends Component {
  state = {
    disabled: true,
    errors: null,
    responseMsg: null,
    apiKey: this.props.siteDetails?.twigDetails?.settings?.sentekData?.apiKey || null,
    loggerName: this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerName || null,
    loggerId: this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerId || null,
    deviceName: this.props.siteDetails?.twigDetails?.settings?.agsenseData?.name || null,
    password: this.props.siteDetails?.twigDetails?.settings?.agsenseData?.password || null,
    deviceId: this.props.siteDetails?.twigDetails?.settings?.agsenseData?.serial || null,
    userName: this.props.siteDetails?.twigDetails?.settings?.agsenseData?.userName || null,
  };
  onSubmit = (e) => {
    let responseMsg = 'Data updated successfully';
    const siteId = this.props.siteDetails?.siteId;
    const sentekData = {
      apiKey: this.state.apiKey || this.props.siteDetails?.twigDetails?.settings?.sentekData?.apiKey,
      loggerId: this.state.loggerId || this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerName,
      loggerName: this.state.loggerName || this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerId,
    }
    configService.updateSentekData(siteId, sentekData).then(res => {
      this.setState({ responseMsg })

    }).catch(error => {
      this.setState({ errors: error.message });
    });
  };
    
  handleChange = (e) => {
    let value = e.target.value;
    let selected = e.target.name;
    this.setState({ [selected]: value })    

  };
  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled, responseMsg: null
    });
    if (!this.state.disabled) {
      this.onSubmit();
    }
  };
  render() {
    let hardwareAPI;
    if (this.props.siteDetails.twigDetails?.settings?.hardwareType?.type === "zenseio-api") {
      hardwareAPI = "ZenseIO API Detail"
    } else if (this.props.siteDetails.twigDetails?.settings?.hardwareType?.type === "sentek-api") {
      hardwareAPI = "Sentek API Detail"
    } else if (this.props.siteDetails.twigDetails?.settings?.hardwareType?.type === "agsense-api") {
      hardwareAPI = "AgSense API Details"
    }
  

    return (
      <div className="harware-container">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5>{hardwareAPI}</h5>
              {(this.props.siteDetails?.twigDetails?.settings?.hardwareType?.type === "zenseio-api") ||(this.props.siteDetails?.twigDetails?.settings?.hardwareType?.type === "agsense-api") ||
                (this.props.profileDetails.profile.role.name === 'dealer_user' ||
                  this.props.profileDetails.profile.role.name === 'business_user' ||
                  this.props.profileDetails.profile.role.name === 'farm_user') ?
                null : <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button>}
            </div>
          </Card.Header>
          <Card.Body>
            {this.props.siteDetails?.twigDetails?.settings?.hardwareType?.type === "zenseio-api" ?
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Device ID</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        name="DeviceId"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigId
                        }
                      />
                    </Form.Group>

                  </div>
                </div>

              </form>
              :(this.props.siteDetails?.twigDetails?.settings?.hardwareType?.type === "sentek-api" ?

              <form>
                <div className="row ">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>API Key</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        name="apiKey"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.sentekData?.apiKey : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Logger Name</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        name="loggerName"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerName : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Logger ID</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        name="loggerId"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.sentekData?.loggerId : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                  </div>
                </div>

              </form>
              :
             <form>
                <div className="row ">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        name="userName"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.agsenseData?.userName : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        name="password"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.agsenseData?.password : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </div>
                  </div>
                <div className="row">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Device Name</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        name="deviceName"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.agsenseData?.name : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <Form.Group>
                      <Form.Label>Device ID</Form.Label>
                      <Form.Control
                        disabled={this.state.disabled}
                        type="text"
                        className={`${this.state.disabled ? '' : "whiteBC"}`}
                        name="deviceId"
                        placeholder={
                          this.props.siteDetails && this.props.siteDetails?.twigDetails?.settings ? this.props.siteDetails?.twigDetails?.settings?.agsenseData?.serial : ""
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                  </div>
                </div>

                </form>
              )
            }
          </Card.Body>


          <div className="messagePopup">
            {console.log(this.state.errors, 'this.state.errors')}
            {(this.state.errors !== null) &&
              <Message variant="error" message={this.state.errors} />}
            {this.state.responseMsg !== null && this.state.responseMsg?.length !== 0 && <Message variant="success" message={'Data updated successfully'} />}


          </div>
        </Card>
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(SentekApiDetails);

