import http from "../../common/duck/httpService";
import logger from "../../common/duck/logService";
import localStorageService from '../../common/duck/localStorageService';

const apiEndPoint = `/farm/user`;

const { CancelAxiosToken, getDataOrError } = http;

const getFarmsForUserRequest = () => {
    const source = CancelAxiosToken.source();

    const request = (q = '', sortType, status) => {
        const userid = localStorageService.getUserId();
        const encodedSearch = encodeURIComponent(q);
        const params = '?imageRequired=true' + (sortType ? '&sort=' + sortType + '&sortorder=DESC' : '') + (q ? '&name=' + encodedSearch : '') + (status ? '&siteStatus=' + status : '');
        return http.get(`${apiEndPoint}/${userid}${params}`, {
            cancelToken: source.token
        }).then(response => {
            return response.data.data;
        }).catch(error => {
            if (http.isCancel(error)) {
                return Promise.reject({ isCancelled: true });
            } else {
                const { data } = error.response;
                logger.log(error);
                return Promise.reject(data);
            }
        })
    };

    return { request, cancel: source.cancel }
}

const getBusinessesUnderOrg = () => {
    return http.get('/org?type=business')
        .then(response => {
            return response.data.data;
        }).catch(error => {
            const { data } = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
}

const createFarm = (payload) => {
    return http.post('/farm', payload)
        .then(response => getDataOrError(response));
}

const getTreeView = () => {
    const source = CancelAxiosToken.source();
    const request = (sortType) => {
        const userid = localStorageService.getUserId();
        const params = `?sort=${sortType}`;
        return http.get(`/org/view/${userid}${params}`, {
            cancelToken: source.token
        }).then(response => getDataOrError(response))
            .catch(error => {
                if (http.isCancel(error)) {
                    return Promise.reject({ isCancelled: true });
                } else {
                    const { data } = error.response;
                    logger.log(error);
                    return Promise.reject(data);
                }
            });
    }
    return { request, cancel: source.cancel }
}

const saveUserFarm = (payload) => {
    return http.post('/farm-health-report/user', payload)
        .then(response => getDataOrError(response)).catch(error => {
            const { data } = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
}

const getUsers = () => {
    return http.get('/farm-health-report/users')
        .then(response => {
            return response.data;
        }).catch(error => {
            const { data } = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
}

const deleteUser = (objectId) => {
    return http.delete(`/farm-health-report/user/${objectId}`)
        .then(response => response.data);
}

const editUser = (objectId, payload) => {
    return http.patch(`/farm-health-report/user/${objectId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const { data } = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
}



export default {
    getFarmsForUserRequest,
    getBusinessesUnderOrg,
    createFarm,
    getTreeView,
    saveUserFarm,
    getUsers,
    deleteUser,
    editUser,
}
