import React from 'react';
import techviewgreen from '../../assets/images/issues-icons/techview-green.svg';
import techviewred from '../../assets/images/issues-icons/techview-red.svg';
import techviewyellow from '../../assets/images/issues-icons/techview-yellow.svg';
import techviewmaroon from '../../assets/images/issues-icons/techview-maroon.svg';
export default function IssueStatus({red, yellow, green,agronomic, maroon}) {

    if(!agronomic){
        
    return (

        <React.Fragment>
        <div className="attention d-flex pr-20">
            <p className="pl-5 mt-1 d-flex">
                <img src={techviewmaroon} alt=''></img>{maroon}</p>
        </div>
        <div className="attention d-flex pr-20">
            <p className="pl-5 mt-1 d-flex">
                <img src={techviewred} alt=''></img>{red}</p>
        </div>
        <div className="warning d-flex pr-20 ">
            <p className="pl-5 mt-1 d-flex ">
            <img src={techviewyellow} alt=''></img>{yellow}</p>
        </div>
        <div className="issue d-flex ">
            <p className="pl-5  mt-1  d-flex">
            <img src={techviewgreen} alt=''></img>{green}</p>
        </div>
    </React.Fragment>)


       
    }
    else{

    
        return(
        <React.Fragment>

<div className="attention techattention d-flex pr-20">
            <p className="pl-5 mt-1 ">{maroon}</p>
        </div>
        <div className="attention techattention d-flex pr-20">
            <p className="pl-5 mt-1 ">{red}</p>
        </div>
        <div className="warning techwarning d-flex pr-20 ">
            <p className="pl-5 mt-1 ">{yellow}</p>
        </div>
        <div className="issue techissue d-flex ">
            <p className="pl-5  mt-1 ">{green}</p>
        </div> 

        
    </React.Fragment>

    )}

}


