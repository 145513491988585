import React, { useEffect, useState } from "react";
import iconimage from "../../../assets/images/left-arrow/chevron-left.png";
import { Accordion, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isPlanActive, isPlanGold } from "../../common/duck/planActive";
// import update from "../../../assets/images/update/group-3.png";
import PinColors from "../sidemenu/pincolors";
import ProgressBar from "./progressbar";
import Aquacheck from "../../../assets/images/sensor/Aquacheck.png";
import topSensor from '../../../assets/images/sensor/Demeter.jpg';
import Sentek from '../../../assets/images/sensor/Sentek.jpg';
import Generic from '../../../assets/images/sensor/Generic.jpg';
import * as moment from 'moment';
import { plantImages, rootImages } from "../duck/plantAndRootImages";
// import Status from "./Status";

function SiteItem(props) {
  const id = useParams().farmid;
  // const farmid = props.farmid ? props.farmid : id;
  const farmid = props?.data?.farm?.objectId ? props?.data?.farm?.objectId : id;
  const { name, siteId, twigId, id: siteObjectId, status } = props.data;
  const state = props?.data?.twigDetails?.state;
  const { plantType, plantingDate, rootDepth } = props.data && props.data.twigDetails && props.data.twigDetails.settings;
  const [accordion, setAccordion] = useState(false);
  const depth = props.data?.bucketDetails?.bucketDictionary?.depth;
  const percentage = props.data?.bucketDetails?.bucketDictionary?.percentageFilled
  const awValue = props.data?.bucketDetails?.bucketDictionary?.awValue
  // const [totaldepth, setTotalDepth] = useState([]);
  const totalpercentage = props.data?.bucketDetails?.totalDictionary?.percentageFilled[0]?.total;
  const totalpercentage24HrsBack = props.data?.bucketDetails?.totalDictionary?.percentageFilled24HrsBack[0]?.total;
  const totalawValue = props.data?.bucketDetails?.totalDictionary.aw[0].total
  const totalBucketValue = props.data?.bucketDetails?.totalDictionary.bucketValue[0].total;
  // const depthkey =  props.data?.bucketDetails?.bucketDictionary?.depth?.length
  const localpercentage = props.data?.bucketDetails?.localBucketDictionary?.percentageFilled
  const pressureValue = props.data?.twigDetails?.details?.data?.pressure?.value
  const localdepth = props.data?.bucketDetails?.localBucketDictionary?.depth
  const localSensorCount = props.data?.twigDetails?.settings?.localSensorCount?.value;
  const waterPer = props.data?.twigDetails?.settings?.irrigationPercolation?.value
  const refDept = props.data?.twigDetails?.settings?.hardwareSensorLocation?.value?.referenceDepth[0];
  const bucketValue = props.data?.bucketDetails?.bucketDictionary?.bucketValue
  const localBucketValue = props.data?.bucketDetails?.localBucketDictionary?.bucketValue
  const RP = props.data?.bucketDetails?.bucketDictionary?.RP
  const WL1 = props.data?.bucketDetails?.bucketDictionary?.WL1

  const readilyAWValues = props.data?.bucketDetails?.bucketDictionary?.rawValue; // RAW values For individual buckets for each sensor 
  const localReadilyAWValues = props.data?.bucketDetails?.localBucketDictionary?.rawValue; // local RAW values For each local sensor 
  const totalReadilyAWValue = props.data?.bucketDetails?.totalDictionary.raw[0]?.total;

  const WL2 = props.data?.bucketDetails?.bucketDictionary?.WL2
  const totalRP = props.data?.bucketDetails?.totalDictionary?.RP
  const totalWL1 = props.data?.bucketDetails?.totalDictionary.WL1

  const totalWL2 = props.data?.bucketDetails?.totalDictionary?.WL2
  const localRP = props.data?.bucketDetails?.localBucketDictionary?.RP
  const localWL1 = props.data?.bucketDetails?.localBucketDictionary?.WL1

  const localWL2 = props.data?.bucketDetails?.localBucketDictionary?.WL2

  const hardwareType = props.data?.twigDetails?.settings?.hardwareType?.value.toLowerCase() || "";
  const hardware = props.data?.twigDetails?.settings?.hardwareType?.type.toLowerCase() || "";
  const planDetails = props.data?.planDetails;
  const predictedWaterUse = props.data?.twigDetails?.settings?.predictedWaterUse;
  const nextGrowthStage = props.data?.twigDetails?.settings?.dtnDetails?.nextGrowthStage;
  const nextGrowthStageDate = props.data?.twigDetails?.settings?.dtnDetails?.nextGrowthStageDate;
  const lastWettingDate = props.data?.twigDetails?.settings?.irrigationPercolation?.date;
  const [mergedArray, setMergeArray] = useState(null);
  const [depthPlus, setdepthPlus] = useState(null);
  const [mergeRaw, setMergeRaw] = useState(null);
  const [localSensors, setLocalSensors] = useState(null);

  useEffect(() => {
    let mergeRaw = [];
    if (localReadilyAWValues) {
      mergeRaw = [...localReadilyAWValues];
      readilyAWValues.forEach((item) => {
        mergeRaw.push(item);
      })

    } else if (readilyAWValues) {
      mergeRaw = [...readilyAWValues];
    }
    setMergeRaw(mergeRaw);
  }, [readilyAWValues, name, localReadilyAWValues]);


  useEffect(() => {
    let mergeArray = [];
    if (localdepth) {
      const onlyLocalSensors = localdepth.filter(i => {
        return Object.keys(i)[0] !== 'Top';
      });
      setLocalSensors(onlyLocalSensors);
    }
    if (localdepth) {
      mergeArray = [...localdepth];
      depth.forEach((item) => {
        mergeArray.push(item);
      })

    } else if (depth) {
      mergeArray = [...depth];
    }
    setMergeArray(mergeArray);

  }, [depth, localdepth]);

  useEffect(() => {
    if (mergedArray) {
      const depthPlus = mergedArray?.map((i, index) => {
        let d;
        if (index !== 0) {
          const current = parseInt(Object.values(i));
          const y = mergedArray[index - 1];
          const prev = parseInt(Object.values(y));
          d = Math.abs((current - prev)) * 2;
        }
        else {
          d = parseInt(Object.values(i)) * 2;
        }

        return d;
      });
      setdepthPlus(depthPlus);
    }

  }, [mergedArray, localdepth])

  const handleClick = () => {
    setAccordion((acc) => !acc);
  };

  const lastUpdated = props.data.twigDetails.details
    ? props.data.twigDetails.details.lastUpdated
    : null;

  let probeImage;
  if (hardwareType.includes("aquacheck") && hardware === "badger") {
    probeImage = Aquacheck;
  } else if (hardwareType.includes("sentek")) {
    probeImage = Sentek;
  } else if (hardwareType.indexOf("groguru") === -1 && hardwareType.indexOf("stem with 2 ds") === -1 && hardwareType.indexOf("stem with 3 ds") === -1) {
    probeImage = Generic;
  }


  let rootGrowthHeight = 0;
  let rootImage;
  let plantImage;
  if (rootDepth?.value !== 0 && status === 'ACTIVE' && rootDepth?.rootImage) {
    const totalDepthArray = [...localdepth, ...depth];
    let sensorDepth = rootDepth?.value;
    let sensorPosition = totalDepthArray.findIndex(each => Object.values(each)[0] === sensorDepth);
    rootGrowthHeight = 53 + (25 * (sensorPosition + 1)) + (sensorDepth * 2); // 53px for height of empthy place + 25px for height of each progress bar + (depth * 2px)
    rootImage = rootImages[rootDepth?.rootImage];
  }

  if (rootDepth?.plantImage && status === 'ACTIVE') {
    plantImage = plantImages[rootDepth?.plantImage];
  }

  let stillUtc = moment.utc(lastUpdated).toDate();
  let local = moment(stillUtc).local().fromNow();

  let deficient = totalBucketValue - totalawValue;
  if (deficient < 0) {
    deficient = 0;
  }

  let planActive = isPlanActive(props.userRole, planDetails);
  let planGold = isPlanGold(props.userRole, planDetails);

  let predictedWater = [];
  if (predictedWaterUse) {
    predictedWaterUse.forEach(each => {
      if (each.display && each.forecastValue) predictedWater.push(each);
    })
  }

  let lastWettingDepth;
  if (waterPer > 0 && status === 'ACTIVE') {
    if (localdepth?.length > 0 && waterPer <= localSensorCount) {
      // no depth
    } else if (localdepth?.length > 0 && waterPer > 1) {
      lastWettingDepth = Object.values(depth[waterPer - (localSensorCount + 1)]);
    } else if (localdepth?.length === 0 && waterPer >= 1) {
      lastWettingDepth = Object.values(depth[waterPer - 1]);
    }
  }

  return (
    <div className="users">
      <Accordion>
        {(name, siteId, twigId, status === 'ACTIVE') ? (
          <Card>
            <Card.Header onClick={handleClick}>
              <Accordion.Toggle
                variant="link"
                eventKey="0"
                className=""
              >
                <div className="row">
                  <div className="col-9 pr-5 mb-10">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex  ">
                        <div className="img-class">
                          <img
                            src={iconimage}
                            alt="icon"
                            className={`${accordion ? "arrow-90icon" : ""}`}
                          ></img>
                        </div>
                        <div className="headers pl-20">
                          <h6>{name}</h6>
                        </div>
                      </div>
                      <div className="crop-type ">
                        <p className="pt-5">{plantType.value}</p>
                      </div>
                    </div>
                    {hardwareType !== 'pressure transducer' ?
                      <div className="d-flex pb-15 justify-content-between progress-graphs">
                        <div className="disable pr-5">
                          <h6>Readily Avail. Water (RAW) : {totalReadilyAWValue}”</h6>
                        </div>
                        <div className="disable pl-0 pr-0">
                          <h6>Deficit : {deficient.toFixed(2)}”</h6>
                        </div>
                      </div> : null}
                    <div className="progress-graphs d-flex" style={{ marginLeft: '5%' }}>
                      <div className=" pl-0 pr-10 graph">
                        {totalpercentage || (totalpercentage === 0 && hardwareType !== 'pressure transducer') ? (
                          <ProgressBar
                            totalpercentage={totalpercentage}
                            totalpercentage24HrsBack={totalpercentage24HrsBack}
                            planGold={planGold}
                            RP={totalRP}
                            WL1={totalWL1}
                            WL2={totalWL2}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-3 pl-0">
                    <Link
                      to={planActive ? {
                        pathname: `/farm/${farmid}/site/${siteId}/${siteObjectId}/${twigId}/graph`,
                        state: props.data,
                      } : "#"}
                    >
                      {state && (
                        <div className="mapmarker">
                          <PinColors state={state} pressure={pressureValue} />
                        </div>
                      )}
                      <p className="mt-35 mb-0 w-78 pinSiteId">ID# {siteId}</p>
                    </Link>
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              {planActive ?
                <Card.Body>
                  {hardwareType !== 'pressure transducer' ? (
                    <>
                      {(predictedWater.length > 0 || (nextGrowthStage && nextGrowthStageDate) || plantImage) && planActive ?
                        <div className="main-container main-plant-image" style={{ backgroundColor: "#a7dcfb" }}>
                          <div className="d-flex progress-graph">
                            <div style={{ width: "50%", height: "250px" }}>
                              {predictedWater.length > 0 ? (
                                <div>
                                  <p className="bucket-model-label">
                                    <u>Predicted Water Use</u>
                                  </p>
                                  { predictedWater.map((each) => (
                                    <p className="bucket-model-value" key={each.forecastDay}>
                                      {each.forecastDay} day = {each.forecastValue}"
                                    </p>
                                  ))}
                                </div>
                              ) : null}
                              {nextGrowthStage && nextGrowthStageDate ? (
                                <div>
                                  <p className="bucket-model-label">
                                    <u>Next Growth Stage</u>
                                  </p>
                                  <p className="bucket-model-value">{nextGrowthStage} - {moment(nextGrowthStageDate).format("Do MMM")}</p>
                                </div>
                              ) : null}
                              <div>
                                <p className="bucket-model-label">
                                  <u>Last Wetting Event</u>
                                </p>
                                <p className="bucket-model-value">
                                  {lastWettingDate && lastWettingDepth ?
                                    `${moment(lastWettingDate).format("Do MMM")} - ${lastWettingDepth}"`
                                    : "None"}
                                </p>
                              </div>
                            </div>
                            <div style={{ backgroundImage: `url(${plantImage})`, backgroundSize: '100% 100%', width: '100%', marginRight: '9%' }} />
                          </div>
                        </div>
                        :
                        (<div className="main-container main-plant-image" style={{ backgroundColor: "#a7dcfb" }}>
                          <div className="d-flex progress-graph">
                            <div style={{ width: "50%" }}>
                              <div style={{ marginBottom: '10%' }}>
                                <p className="bucket-model-label"><u>Last Wetting Event</u></p>
                                <p className="bucket-model-value">
                                  {lastWettingDate && lastWettingDepth ?
                                    `${moment(lastWettingDate).format("Do MMM")} - ${lastWettingDepth}"`
                                    : "None"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>)}

                      <div className="main-container main-root-image">
                        <div className="d-flex progress-graph">
                          <div className=" pr-10 img-height">
                            <h6 style={{ visibility: 'hidden' }}>sensor {refDept}</h6>
                            {localdepth && localdepth[0]?.Top ? <div style={{
                              height: 25 + (Object.values(localdepth[0]) * 2) + 'px', paddingTop: (Object.values(localdepth[0]) * 2) + 'px'
                            }}>
                              <img src={topSensor} width="65px" alt="topSoil" />
                            </div> : null}
                            {localSensors && localSensors.map(ls => (
                              <div style={{
                                height: '25px',
                                marginTop: '10px'
                              }}>
                                <img src={topSensor} height="100%" width="65px" alt="sensor" />
                              </div>
                            ))}
                            {(hardwareType.includes("groguru") || hardwareType.includes("stem with 2 ds") || hardwareType.includes("stem with 3 ds")) &&
                              readilyAWValues && depthPlus && readilyAWValues.map((hs, index) => (
                                <div style={{
                                  height: 27 + depthPlus[index] + 'px',
                                  paddingTop: 1 + depthPlus[index] + 'px'
                                }}>
                                  <img src={topSensor} height="100%" width="65px" alt="sensor" />
                                </div>))}
                            {hardwareType.indexOf("groguru") === -1 && hardwareType.indexOf("stem with 2 ds") === -1 && hardwareType.indexOf("stem with 3 ds") === -1 ?
                              (<div style={{
                                height: '90%',
                                marginTop: localSensorCount ? (refDept - Object.values(localdepth[0])) * 2 + 'px' : refDept !== 0 ? (refDept * 2) + 'px' : '0px'
                              }}>
                                <img src={probeImage} height="100%" width="65px" alt="sensor" />
                              </div>) : null}
                          </div>
                          <div className=" disable width-80 pl-0 pr-10">
                            <h6 title="Readily Avail. Water"><br />RAW</h6>

                            {

                              awValue && awValue !== undefined && mergeRaw && depthPlus &&
                              mergeRaw.map((awvalue, index) => (
                                <div className="bucket-values" style={{
                                  height: 25 + depthPlus[index] + 'px',
                                  paddingTop: depthPlus[index] + 'px'
                                }} key={index}>{Object.values(awvalue)}”</div>
                              ))}
                          </div>

                          <div className="d-flex" style={{ backgroundImage: `url(${rootImage})`, backgroundSize: `100% ${rootGrowthHeight}px`, backgroundRepeat: 'no-repeat', width: '100%' }}>
                            <div className=" pl-0 pr-10 graph">
                              {localpercentage && depthPlus && localRP && localWL1 && localWL2 && localBucketValue &&
                                <ProgressBar
                                  percentage={localpercentage && localpercentage.map((per) => Object.values(per)[0])}
                                  depthValues={depthPlus}
                                  type="local"
                                  localSensors={localSensorCount}
                                  localdepthValue={localdepth[0].Top}
                                  RP={localRP && localRP.map((bucket) => Object.values(bucket)[0])}
                                  WL1={localWL1 && localWL1.map((bucket) => Object.values(bucket)[0])}
                                  WL2={localWL2 && localWL2.map((bucket) => Object.values(bucket)[0])}
                                  bucketValue={localBucketValue && localBucketValue.map((bucket) => Object.values(bucket)[0])}
                                />}

                              {depthPlus && percentage && RP && WL1 && WL2 && bucketValue &&
                                <ProgressBar
                                  type="hardware"
                                  localSensors={localSensorCount}
                                  percentage={percentage && percentage.map((per) => Object.values(per)[0])}
                                  depthValues={depthPlus}
                                  depth={depth.length}
                                  RP={RP && RP.map((bucket) => Object.values(bucket)[0])}
                                  WL1={WL1 && WL1.map((bucket) => Object.values(bucket)[0])}
                                  WL2={WL2 && WL2.map((bucket) => Object.values(bucket)[0])}
                                  bucketValue={bucketValue && bucketValue.map((bucket) => Object.values(bucket)[0])
                                  }
                                />}

                            </div>
                          </div>
                          {localSensorCount === null ? (<div className=" pr-10">
                            <h6 style={{ visibility: 'hidden' }}>{waterPer} <br /> value</h6>

                            { depthPlus && mergedArray &&
                              mergedArray.map((depth, index) => (
                                <div className="wp-v" style={{
                                  backgroundColor: index < waterPer ? 'blue' : 'black',
                                  height: 25 + depthPlus[index] + 'px',
                                  paddingTop: depthPlus[index] + 'px'
                                }} key={index}>{Object.values(depth)}”</div>
                              ))}
                          </div>) : null}
                          {(localSensorCount !== null && (waterPer <= localSensorCount)) ? (<div className=" pr-10">
                            <h6 style={{ visibility: 'hidden' }}>{waterPer} <br /> value</h6>
                            {depthPlus && mergedArray &&
                              mergedArray.map((depth, index) => (
                                <div className="wp-v" style={{
                                  backgroundColor: 'black',
                                  height: 25 + depthPlus[index] + 'px',
                                  paddingTop: depthPlus[index] + 'px'
                                }} key={index}>{Object.values(depth)}”</div>
                              ))}

                          </div>) : null}
                          {(localSensorCount !== null && (waterPer > localSensorCount)) ? (<div className=" pr-10">
                            <h6 style={{ visibility: 'hidden' }}>{waterPer} <br /> value</h6>
                            {depthPlus && mergedArray &&
                              mergedArray.map((depth, index) => (
                                <div className="wp-v" style={{
                                  backgroundColor: index < waterPer ? 'blue' : 'black',
                                  height: 25 + depthPlus[index] + 'px',
                                  paddingTop: depthPlus[index] + 'px'
                                }} key={index}>{Object.values(depth)}”</div>
                              ))}

                          </div>) : null}
                        </div>
                        {/* <div id="chart">
                    <ReactApexChart options={temp.options} series={temp.series} type="bar" height={400} />
                  </div> */}
                      </div> </>) : null}
                  <hr></hr>

                  <div className="main-container">
                    <p> Site Information</p>

                    <div className="d-flex  mt-39">
                      <div className="site-informations">
                        <p>Last Updated</p>
                        <h5>
                          {lastUpdated
                            ? local
                            : " "}

                        </h5>
                      </div>
                      <div className="site-informations">
                        <p>Planting Date</p>
                        {plantingDate && plantingDate.value ? <h5>{plantingDate.value}</h5> : ''}
                      </div>
                    </div>
                    {/* <button>Go to Site Details</button> */}
                  </div>
                  <hr></hr>
                </Card.Body>
                : (
                  <div className="main-container" style={{ borderTop: "1px solid #dad8d8", paddingTop: "10px" }}>Please contact your dealer to subscribe to this feature</div>
                )}
            </Accordion.Collapse>
          </Card>
        ) : (
          ""
        )}
      </Accordion>
    </div>
  );
}

const mapStateToProps = state => ({
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
})

export default connect(mapStateToProps)(SiteItem);


