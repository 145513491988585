import * as moment from 'moment'


export function getTimestamp(datestring) {
    return new Date(datestring).valueOf();
}

export function toUTC(dateString) {
    if (!dateString)
        return;
    return moment.utc(new Date(dateString)).format();
}

export function diffDays(currentDate, pastDate) {
    return moment(currentDate).diff(moment(pastDate), 'days')
}

export function dateTimeFormat(timestamp, format = "DD MMM YYYY HH:mma") {
    return moment(timestamp).local().format(format);
}

export function chartTooltipXAxisFormatting(timestamp) {
    const dateObject = new Date(timestamp);
    const stillUtc = moment.utc(dateObject).toDate();
    const local = moment(stillUtc).local().format("DD MMM YYYY | HH:mm");
    return local;
}

function today() {
    return new Date().toISOString();
}

function yesterday(day = 1) {
    const date = new Date();
    date.setDate(date.getDate() - day);
    return date.toISOString();
}

function lastMonth(month = 1) {
    const date = new Date();
    date.setMonth(date.getMonth() - month);
    return date.toISOString();
}

function lastYear() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date.toISOString();
}

function lastWeek() {
    return yesterday(7);
}



export function getDateParams(timePeriod, { fromDate = '', toDate: _toDate = '' } = {}) {

    const toDate = today();
    switch (timePeriod) {
        case 'ONE_DAY':
            return {
                fromDate: yesterday(),
                toDate
            }
        case 'ONE_WEEK':
            return {
                fromDate: lastWeek(),
                toDate
            }
        case 'ONE_MONTH':
            return {
                fromDate: lastMonth(),
                toDate
            }
        case 'THREE_MONTHS':
            return {
                fromDate: lastMonth(3),
                toDate
            }
        case 'SIX_MONTHS':
            return {
                fromDate: lastMonth(6),
                toDate
            }
        case 'ONE_YEAR':
            return {
                fromDate: lastYear(),
                toDate
            }
        default:
            return {
                fromDate,
                toDate: _toDate,
                isEmpty: fromDate === '' && _toDate === ''
            };
    }
}

export function initialDateParams(fromDate, toDate, timePeriod = 'ONE_MONTH') {
    const defaultParams = getDateParams(timePeriod);
    return {
        fromDate: fromDate || defaultParams.fromDate,
        toDate: toDate || defaultParams.toDate
    }
}