import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import subscriptionService from './subscriptions/duck/subscriptionService';
import sensorImg from "../../assets/images/sensor.png";
// import defaultimg from "../../assets/images/farms/farm-default-icon.png";
import farmService from '../farm/duck/farmService';
import siteService from '../sites/duck/siteService';
import CardSourceList from './cardSourceList';


export default function PurchaseModal({ subscriptionItem, defaultSource, pSources, show, onClose, site }) {

    // const [addAddrShow, setAddAddrShow] = useState(false);
    const [addCardShow, setAddCardShow] = useState(false);
    const [sites, setSites] = useState(null);
    const [checkedSites, setCheckedSites] = useState([]);
    const netAmount = (subscriptionItem || 0) && checkedSites.length * (subscriptionItem.plan.amount / 100);

    useEffect(() => {
        const farms = farmService.getFarmsForUserRequest();
        console.log("SITE: ", site);
        if (show && !sites) {
            farms.request()
                .then(farms => farms.map(({ objectId }) => objectId))
                .then(farms => {
                    siteService.getSitesForFarms(farms)
                        .then(sites => setSites(
                            sites.map(farm => Object.values(farm))
                                .reduce((a, b) => a.concat(b), [])
                                .flat()
                                .map(site => ({
                                    id: site.id,
                                    name: site.name,
                                    plan: site?.subscriptionDetails?.subscription?.plan?.id
                                }))
                        ))
                })
        }

        return farms.cancel;
    }, [show, sites, site]);

    useEffect(() => {
        if (site && sites && site?.subscriptionDetails?.subscription?.plan?.id !== subscriptionItem?.plan?.id) {
            let checkedItem = sites.find(i => {
                return i.id === site.id
            });
            setCheckedSites([checkedItem.id]);
        }
    }, [sites, site, subscriptionItem])

    function toggleSite(event) {
        const objectId = event.target.value;
        setCheckedSites(sites => {
            if (sites.includes(objectId)) {
                return sites.filter(site => site !== objectId);
            }
            return sites.concat(objectId);
        })
    }

    function makePurchase() {
        return subscriptionService.updateSubscriptionAddSites(
            subscriptionItem.subscription,
            subscriptionItem.id,
            {
                plan: { id: subscriptionItem.plan.id, name: subscriptionItem.plan.metadata.name },
                sites: checkedSites
            }
        )
            .then(subs => console.log(subs))
            .catch(purchaseError => console.log(purchaseError));
    }

    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={() => onClose(false)}
                size="lg"
                className="checkout-modal"
            >
                <Modal.Header className="d-flex justify-content-between">
                    <p>Checkout</p>
                    <p className="cp" onClick={() => onClose(false)}>Close</p>
                </Modal.Header>
                <Modal.Body>
                    <div className="items">
                        <div className="items-content d-flex justify-content-between">
                            <div className="product d-flex justify-content-between">
                                <div>
                                    <img src={sensorImg} alt="" />
                                </div>
                                <div className="pl-25">
                                    <h4>{subscriptionItem && subscriptionItem.plan.metadata.name}</h4>
                                    <div className=" items-quantity d-flex ">
                                        <p>Quantity: {checkedSites.length}</p>
                                        {/* <button className="btn-change">Change</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="amount">
                                <h4>${netAmount}</h4>
                                <button className="btn-remove " onClick={() => setCheckedSites([])}>Remove</button>
                            </div>
                        </div>
                        {/* <div className="informations d-flex justify-content-between mt-20">
                            <div>
                                <p>Subtotal</p>
                            </div>
                            <div>
                                <p>$999.98</p>
                            </div>
                        </div>
                        <div className="informations d-flex justify-content-between mt-15">
                            <div>
                                <p>Tax</p>
                            </div>
                            <div>
                                <p>$39.02</p>
                            </div>
                        </div>
                        <div className="informations d-flex justify-content-between mt-15">
                            <div>
                                <p>Shipping</p>
                            </div>
                            <div>
                                <p>FREE</p>
                            </div>
                        </div>
                        <hr className="mt-20"></hr>
                        <div className="informations d-flex justify-content-between mt-15">
                            <div>
                                <h6>Total</h6>
                            </div>
                            <div>
                                <h6>$1039.00</h6>
                            </div>
                        </div> */}
                    </div>
                    <div style={{ maxHeight: '10rem', overflow: 'auto', padding: '1rem 2rem' }}>
                        {sites === null && <Spinner animation="grow" />}
                        {sites && 'Select the sites to add these plans to:'}
                        {sites && sites.length === 0 && 'No sites to add these plans to'}
                        {sites && sites.map(site => <div key={site.id} style={{ display: 'block' }}>
                            <input key={site.id}
                                onChange={toggleSite}
                                disabled={subscriptionItem.plan.id === site.plan}
                                checked={subscriptionItem.plan.id === site.plan || checkedSites.includes(site.id)}
                                type="checkbox"
                                name="sites"
                                id={site.id}
                                value={site.id} />
                            <label htmlFor={site.id}>{site.name}</label>
                        </div>)}
                    </div>
                    {/* <div className="informations d-flex justify-content-between mt-15">
                        <div>
                            <p>Shipping</p>
                        </div>
                        <div>
                            <button className="addNew" onClick={() => setAddAddrShow(true)}>Add New +</button>
                        </div>
                    </div>
                    <div className="payment-methods mb-20">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6>Agriculture, Inc. HQ</h6>
                                <p>123 Main Street, Whitewater, WI 53156</p>
                                <button>Change</button>
                            </div>
                            <div>
                                <label className="label mt-30">
                                    <input
                                        type="checkbox"
                                        defaultChecked="checked"
                                        name="radio"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="payment-methods mb-20">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6>Agriculture, Inc. HQ</h6>
                                <p>123 Main Street, Whitewater, WI 53156</p>
                                <button>Change</button>
                            </div>
                            <div>
                                <label className="label mt-30">
                                    <input
                                        type="checkbox"
                                        defaultChecked="checked"
                                        name="radio"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div> */}
                    <div className="informations d-flex justify-content-between mt-15">
                        <div>
                            <p>Payment</p>
                        </div>
                        {addCardShow !== null && <div>
                            <button className="addNew" onClick={() => setAddCardShow(true)}>Add New +</button>
                        </div>}
                    </div>
                    <CardSourceList
                        addCardShow={addCardShow}
                        setAddCardShow={setAddCardShow}>
                        <Button
                            variant="none"
                            className="btn-submit"
                            disabled={checkedSites.length === 0}
                            onClick={() => {
                                makePurchase().then(() => onClose(true))
                            }}>Purchase ${netAmount}</Button>
                    </CardSourceList>
                </Modal.Body>
            </Modal>
            {/* <AddNewAddressModal show={addAddrShow} onClose={() => setAddAddrShow(false)} /> */}
        </React.Fragment>
    )
}

// function AddNewAddressModal({show, onClose}) {
//     return (
//         <Modal className="newAddressModal" centered show={show} onHide={onClose} >
//             <Modal.Header>
//                 <Modal.Title id="contained-modal-title-vcenter"> Add New Address +</Modal.Title>
//                 <span onClick={onClose}>Close</span>
//             </Modal.Header>

//             <Modal.Body>
//                 <Form>
//                     <Form.Group className="streetInfo">
//                         <Form.Label>Name of Address</Form.Label>
//                         <Form.Control></Form.Control>
//                     </Form.Group>
//                     <Form.Group className="streetInfo">
//                         <Form.Label>Street Address (Line 1)</Form.Label>
//                         <Form.Control></Form.Control>
//                     </Form.Group>
//                     <Form.Group className="streetInfo">
//                         <Form.Label>Street Address (Line 2)</Form.Label>
//                         <Form.Control></Form.Control>
//                     </Form.Group>
//                     <div className="row countryAddressInfo">
//                         <div className="col-md-4 countryInfo d-flex flex-column pr-5">
//                             <Form.Group>
//                                 <Form.Label>City</Form.Label>
//                                 <Form.Control></Form.Control>
//                             </Form.Group>
//                         </div>
//                         <div className="col-md-4 countryInfo d-flex flex-column pr-5">
//                             <Form.Group>
//                                 <Form.Label>State</Form.Label>
//                                 <Form.Control></Form.Control>
//                             </Form.Group>
//                         </div>
//                         <div className="col-md-4 countryInfo d-flex flex-column">
//                             <Form.Group>
//                                 <Form.Label>Pin Code</Form.Label>
//                                 <Form.Control></Form.Control>
//                             </Form.Group>
//                         </div>
//                     </div>
//                     <div>
//                         <Button onClick={onClose} variant="none" className="btn-submit">Add Address </Button>
//                     </div>
//                 </Form>
//             </Modal.Body>
//         </Modal>
//     )
// }