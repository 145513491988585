import React, {Component} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {Login} from "./app/user/login";
import Logout from "./app/user/logout";

import Farm from "./app/farm/farm";
import {Dashboard} from "./app/dashboard/dashboard";
import SignUp from "./app/user/signUp";
import "bootstrap/scss/bootstrap.scss";
import './App.css';
import "./assets/scss/style.scss";
import "./assets/scss/font.scss";
import "./assets/scss/spacing.scss";
import {history} from "./app/common/duck/history";
import Notfound from "./app/common/notFound";
import requireAuth from "./app/common/requireAuth";
import localStorageService from "./app/common/duck/localStorageService";
import {connect} from "react-redux";
import userActions from "./app/user/duck/userActions";
import http from "./app/common/duck/httpService";
import {BusinessInformation} from "./app/user/groupInvite/businessInformation";
import {PersonalInformation} from "./app/user/groupInvite/personalInformation";
import {InviteMembers} from "./app/user/groupInvite/inviteMembers";
import {JoinGroup} from "./app/user/userInvite/joinGroup";
import queryString from "query-string";
import {Spinner} from "react-bootstrap";
import {Helmet} from 'react-helmet';

class App extends Component {

    componentDidMount() {
        if (this.isSignUpFlow()) {
            localStorageService.removeItems();
        }
        if (this.shouldLoadProfile() && !this.props.hasProfileDetails) {
            http.setToken(localStorageService.getToken());
            this.props.getProfile(localStorageService.getUserId());
            this.props.getPreferences();
            this.props.getParentRole();
        }

    }

    componentDidUpdate(){
        if(this.props.parentDetailsLoadingStatus === null){
          this.props.getParentRole();
        }
    }
    
    AuthedDashboard = requireAuth(Dashboard)
    AuthedFarm = requireAuth(Farm)

    render() {
        const isWaitingLogin = (
            !this.props.unAuthorized &&
            this.shouldLoadProfile() &&
            (!this.props.hasProfileDetails || !this.props.hasPreferences)
        );
        
        if (isWaitingLogin) {
            return (<React.Fragment>
                <div className="spinnerCentre"><Spinner animation="grow" size="lg" /></div>
            </React.Fragment>);
        }
        if (this.props.hasProfileDetails) {
            window.heap.identify(this.props.userName);
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Groguru</title>
                </Helmet>
                <main>
                    <Router history={history}>
                        <Switch>
                            <Route path="/login" component={Login}/>
                            <Route path="/logout" component={Logout}/>
                            <Route path="/signUp/org/businessInfo" component={BusinessInformation}/>
                            <Route path="/signUp/org/personalInfo" component={PersonalInformation}/>
                            <Route path="/signUp/org/inviteMembers" component={InviteMembers}/>
                            <Route path="/signUp/member/personalInfo" component={JoinGroup}/>
                            <Route path="/signUp/:encryptedKey" component={SignUp}/>
                            <Route path="/dashboard" component={this.AuthedDashboard}/>
                            <Route path="/farm/:farmid" component={this.AuthedFarm}/>
                            <Route path="/not-found" component={Notfound}/>
                            <Redirect exact from="/" to="/dashboard"/>
                            <Redirect to="/not-found"/>
                        </Switch>
                    </Router>
                </main>
            </React.Fragment>
        );
    }

    shouldLoadProfile() {
        return localStorageService.isTokenAvailable() && localStorageService.isUserIdAvailable() && !this.isSignUpFlow();
    }

    isSignUpFlow() {
        const isSignUpLink = history.location.pathname.startsWith('/signUp');
        const {invitationId} = queryString.parse(history.location.search);
        if(invitationId) return isSignUpLink;
    }
}

const mapStateToProps = (state) => ({
    userName: state.userDetails.profileDetails?.profile?.username,
    hasProfileDetails: !!state.userDetails.profileDetails?.profile,
    hasPreferences: !!state.userDetails.userPreferences?.preferences,
    unAuthorized: state.userDetails.profileDetails.unAuthorized,
    parentDetailsLoadingStatus: state.userDetails?.userPrivilages?.loadingStatus
});

const mapDispatchToProps = {
    getProfile: userActions.getProfile,
    getPreferences: userActions.getPreferences,
    getParentRole: userActions.getParentRole
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export {connectedApp as App};



