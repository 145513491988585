import React, { Component } from "react";
import { Card,Modal } from "react-bootstrap";
import defaultimg from "../../assets/images/farms/farm-default-icon.png";
import { connect } from 'react-redux';
import CardSourceList from "../shop/cardSourceList";
import userActions from '../user/duck/userActions';
import customerService from '../shop/duck/customerService';


class TeamShoppingSettings extends Component {
  state={
    cardDetails:[],
    card:false,
    addCardShow:false,

  }
//   addCardToken=(token, actionDispatcher = console.log)=>{
//     return customerService
//         .updateCustomerToken(token)
//         .then(data => actionDispatcher(data.stripeCustomerDetails.sources.data));
// } 

addCardToken=(token)=>{
  return customerService.updateCustomerToken(token);
}
 toggleAddCardModal = () => this.setState(state => ({card: !state.card}));

  setAddCardShow = () => this.setState(state => ({addCardShow: !state.addCardShow}))
 
  render() {
  
   return (
      <div className="team-shopping">
        <Card>
          <Card.Body>
            <div className="card-header-text">Team Shopping Settings</div>
            <hr></hr>
            <div className="card-body-text">
              <p>
                Add or edit your shipping and payment information below. Only
                Admins level users can use the payment options below. Regular
                users will have to add a card during checkout.{" "}
              </p>

              
              {/* payments */}
              <div className="pt-25">
                {this.props.sources && this.props.sources.length !== 0 ?
                <span className="admin-text">Payment</span>:''}
                {/* {this.props.sources && this.props.sources.length ===0 &&
                <button className="invite-text" onClick={this.AddCard}>Add New +</button>}
            
                <AddCardModal show={this.state.addCardShow}
                 onClose={this.toggleAddCardModal}
                 addCardToken={this.addCardToken}
                /> */}
              </div>
              <div className="payments">
                  {/* card1 */}
             
                  {(this.props.sources && this.props.sources.length > 0) ?
                   this.props.sources.map(card =>
                        card.id===this.props.defaultSource &&
                <Card className="mt-15">
                  <Card.Body className="d-flex justify-content-between">
                    <div className=" d-flex">
                      <div className="card-area">
                        <img src={defaultimg} alt="card"></img>
                      </div>
                      <div className="text-area">
                        <div className="name">{card.brand}</div>
                        <div className="address">
                        x{card.last4}
                        </div>
                        <button className="change" onClick={this.toggleAddCardModal}>Change</button>

                          <Modal
                          show={this.state.card}
                          centered
                          onHide={this.toggleAddCardModal}
                          className="checkout-modal checkout2"
                          >
                          <Modal.Header className="d-flex justify-content-between">
                          <p>Payment Options</p>
                          <p className="cp" onClick={this.toggleAddCardModal}>Close</p>
                          </Modal.Header>
                          <Modal.Body>

                         
                          <CardSourceList
                        addCardShow={this.state.addCardShow}
                        setAddCardShow={this.setAddCardShow} />
                            </Modal.Body>
                         </Modal>
                </div>
                    </div>

                    <div className="pt-25  ">
                      <label className="label">
                        <input type="radio" defaultChecked="checked" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>)
                
                :
                  <div>
                  {!this.state.card&&<button className="invite-text" onClick={this.toggleAddCardModal}>Add New +</button>}
                   {this.state.card&&<CardSourceList
                  addCardShow={this.state.addCardShow}
                  setAddCardShow={this.setAddCardShow} />}


                         
                  </div>
                 }
              </div>
            </div>
          </Card.Body>
        </Card>
       
      
      </div>
    );
  }
}


const mapStateToProps = state => {
  const customer = state?.userDetails?.profileDetails?.profile?.stripeCustomerDetails;
  return {
      defaultSource: customer?.default_source,
      sources: customer?.sources?.data
  }
}
const mapDispatchToProps = {
  updateStripeCustomer: userActions.updateStripeCustomer
}

export default connect(mapStateToProps,mapDispatchToProps)(TeamShoppingSettings)