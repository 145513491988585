import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import * as moment from 'moment';
import {Spinner} from "react-bootstrap";
import siteService from "./duck/siteService";
import graphService from './siteDetails/graph/duck/graphService';
import PinColors from "./sidemenu/pincolors";
import userService from '../user/duck/userService';
import { Button, Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isPlanActive } from "../common/duck/planActive";
import {connect} from 'react-redux'
import GraphThumbnail from "./siteDetails/graph/graphThumbnail";
import {history} from '../common/duck/history'
import PinColorService from "./duck/pinColorService"
const SatelliteView = {
  options(maps) {
    return {
      mapTypeId: maps.MapTypeId.HYBRID,
    };
  },
};
const MapView = {
  options(maps) {
    return {
      mapTypeId: maps.MapTypeId.ROADMAP,
    };
  },
};
const markerStyle = {
  textAlign: "center",
  transform: "translate(-40%, -68%)",
  position: "absolute",
};
let noStemSites;
const popover = (siteData) => {
  let stillUtc = moment.utc(siteData.twigDetails.details?.lastUpdated).toDate();
  let local = moment(stillUtc).local().fromNow();
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{siteData.name}</Popover.Title>
      <Popover.Content>
        <div>
          <div>ID: {siteData.siteId}</div>
          <div>Last Updated: {local}</div>
        </div>
      </Popover.Content>
    </Popover>
  );
}

function LinkedPin({ farmid, site, lat, lng, view, userRole }) {
  let planActive = isPlanActive(userRole, site.planDetails);
  return (
    <Link
      to={planActive ? {
        pathname: `/farm/${farmid}/site/${site.siteId}/${site.id}/${site.twigId}/graph`,
        state: site,
      } : "#" }
    >
      {view === "ACTIVE" ?
        <div style={{ position: "relative" }} className="multi-farm-pin d-flex">
          <OverlayTrigger placement="right" overlay={popover(site)}>
            <div>
              <PinColors
                key={site?.siteId}
                width="70"
                height="70"
                style={markerStyle}
                state={site?.twigDetails?.state}
                label={site?.siteId}
              />

              <div>
                <h3 className="text-black">{site?.siteId}</h3>
              </div>
            </div>
          </OverlayTrigger>
        </div>
        :
        <div className="rectmarker">
          <p>{site?.siteId}</p>
        </div>}

    </Link>
  );
}

class SitesMap extends Component {
  state = {
    data: null,
    errors: null,
    center: null,
    show: false,
    back: false,
    maptype: "GraphThumbnails",
    mapView : 'Graph Thumbnails',
    graphThumbnailData: [],
    toggleNoStem:false,
    isLoading:true,
    sort: this.props?.sortPreferences || 'ALPHA-ASC',
  };

  //map = null;

  loadApiMethods = (map, maps) => {

    this.mapPanTo = map && map.panTo.bind(map);
    this.map = map;
    this.maps = maps;
    this.loadInitialData()

  };
  loadInitialData = () => {
    if (this.props.sites && this.props.sites.length) {
      this.setSiteData(this.props.sites);
    } else {
      siteService
        .getSiteDetails(this.props.farmid)
        .then(({ data }) => {
          this.setSiteData(data);
        })
        .catch(
          (error) =>
            console.log(error) ||
            this.setState({ errors: { serviceError: error } })
        );
    }
  }
  setSiteData = async(data) => {
    const {maptype} = this.state
    if (data.length > 0) {
      const sites = data.map(
        ({ siteId, name, location, twigId, id, twigDetails, status, farm, planDetails }) => ({
          siteId,
          name,
          location,
          twigId,
          id,
          twigDetails,
          status,
          farm,
          planDetails,
        })
      );
      this.setState({
        data: sites,
      });
      if(maptype !== "GraphThumbnails"){
        this.setMapBounds(this.map, this.maps, sites);
      }
    } else {
      this.setState({ data: [] });
    }
  }

  setMapBounds = (map, maps, sites) => {

    const bounds = new maps.LatLngBounds();

    sites.forEach((site) => {

      bounds.extend(
        new maps.LatLng(site.location.latitude, site.location.longitude)
      );
    });
    map.fitBounds(bounds);
  };

  backdropClass = "backdrop";

  openBackdrop = () => {
    this.setState({ show: !this.state.show, back: true });
    this.backdropClass += " open";
  };
  closeBackdrop = () => {
    this.setState({ show: false, back: false });
    this.backdropClass = "backdrop";
  };

  handleMapView = (maptype, mapView) => {
    this.setState({ maptype, show: false, back: false, mapView });
    const map = {mapView:mapView ,maptype:maptype }
    userService.updatePreferences({ map: map })
    .then(response => this.getPreferences());
    this.closeBackdrop();
  };

  mapShowClass = "googleMapReact";
  handleMapToggle = () => {
    if (this.props.navOpen) this.mapShowClass = "googleMapReact";
    else this.mapShowClass = " ";
  };

  graphThumbnailView = () => {
    let {graphThumbnailData, isLoading} = this.state
    let temp = []
    let temppNoStemSites = []
    // eslint-disable-next-line array-callback-return
    graphThumbnailData.map((sites) => {
     if(sites.siteDetails){
      temp.push(sites)
     }else{
      temppNoStemSites.push(sites.siteId)
     }
    })
    graphThumbnailData = temp
    noStemSites = temppNoStemSites
    let column;
    let isGridIsFive = false;
    if(graphThumbnailData.length <= 7){
      column = 4
    }
    if(graphThumbnailData.length >= 8 && graphThumbnailData.length <= 19 ){
      column = 3
    }
    if(graphThumbnailData.length >= 20 && graphThumbnailData.length <= 24){
      column = 2
      isGridIsFive = true
    }
    if(graphThumbnailData.length >= 25){
      column = 2
    }
    if(graphThumbnailData?.length > 0 ){
      return(
        <div className="row d-flex align-items-center">
          {graphThumbnailData.map((item, index) => { 
           const siteObjectId = this.state?.data?.filter((x) => x.siteId === item?.siteDetails?.siteId)
          return( 
          <div key={index} className={`col-md-${column} col-lg-${column} col-xs-1 `} style={{marginLeft: isGridIsFive && index % 5 === 0 ? "3%" : null ,  marginBottom:10}}>
            <div className="SiteId d-flex justify-content-between">
              <span className="siteId">{item.siteDetails.siteId}:{item.siteDetails.locationName}</span>
            </div>
            <div className='GraphContainer' style={{width:'100%', paddingRight: 13}}>
              <div 
              onClick={() => history.push(`/farm/${siteObjectId[0]?.farm?.objectId}/site/${item.siteDetails.siteId}/${siteObjectId[0].id}/${item.siteDetails.twigId}/graph`)}
              >
               <GraphThumbnail graphType={'AW'} 
               uniqueID = {`${index}${item.siteDetails.siteId} AW`}
               siteid={item.siteDetails.siteId} 
               twigid={item.siteDetails.twigId} 
               siteObjectId={siteObjectId && siteObjectId[0] && siteObjectId[0].id}
               data={item}/>
               </div>
               <div style={{marginTop:20}}>
                <div 
                onClick={() => history.push(`/farm/${siteObjectId[0]?.farm?.objectId}/site/${item.siteDetails.siteId}/${siteObjectId[0].id}/${item.siteDetails.twigId}/graph`)}
                >
               <GraphThumbnail graphType={'MOIST'} 
               uniqueID = {`${index}${item.siteDetails.siteId} MOIST`}
               siteid={item.siteDetails.siteId} 
               twigid={item.siteDetails.twigId} 
               siteObjectId={siteObjectId && siteObjectId[0] && siteObjectId[0].id}
               data={item}/>
               </div>
               </div>
            </div>
          </div>
          )})}
        </div>
      )
    }
    if(isLoading === true){
      return(
        <div className="row d-flex align-items-center">
          <Spinner animation="grow" size="lg" />
        </div>
      )
    }
    if(isLoading === false && graphThumbnailData.length === 0){
      return(
        <div className="row d-flex align-items-center">
          <p>No sites available</p>
        </div>
      )
    }
  }

  getPreferences = () => {
    userService.getPreferences().then(response => {
      const mapView  = response?.data?.preference?.map?.mapView || "Graph Thumbnails";
      const maptype = response?.data?.preference?.map?.maptype || "GraphThumbnails";
      this.setState({ mapView, maptype})
  })
  }
  loadGraphThumbnails = async() => {
    const state = this.props.gState
    const dateParams = state.userDetails.userPreferences.preferences.preference?.graph
    const siteIds = this.state?.data?.map((site) => {return (site.siteId)})
    const twigIds = this.state?.data?.map((site) => {return (site.twigId)})
    const params = { fromDate: dateParams.fromDate, toDate: dateParams.toDate }
      if(siteIds && siteIds.length && siteIds.length > 0){
        const apiCall = graphService.getGraphThumbnailData();
        this.setState({isLoading: true});
        apiCall.request(siteIds, twigIds, params)
          .then(data => {
            this.setState({graphThumbnailData:data}, () => {
              this.sortThumbnailData()
            })
            this.setState({isLoading: false});
          })
          .catch(err => {
            this.setState({isLoading: false});
            console.log("Something went wrong")
          });
        }
  }
  sortAcend = (sites) => {
    return sites.sort(function (x, y) {
      let a = x.siteDetails.locationName.toLowerCase(),
        b = y.siteDetails.locationName.toLowerCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  sortDecend = (sites) => {
    return sites.sort(function (x, y) {
      let a = x.siteDetails.locationName.toLowerCase(),
        b = y.siteDetails.locationName.toLowerCase();
      return a === b ? 0 : a > b ? -1 : 1;
    });
  }
  sortRToB = (siteList) => {
    let newArray = [];
    const red = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'red';
    });
    newArray.push(...red);

    const yellow = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'yellow';
    });
    newArray.push(...yellow);

    const green = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'green';
    });
    newArray.push(...green);

    const lightblue = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'lightblue';
    });
    newArray.push(...lightblue);

    const blue = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'blue';
    });
    newArray.push(...blue);

    const grey = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'white';
    });
    newArray.push(...grey);

    const white = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'grey';
    });
    newArray.push(...white);

    return newArray;
  }

  sortBToR = (siteList) => {
    let newArray = [];

    const blue = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'blue';
    });
    newArray.push(...blue);

    const lightblue = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'lightblue';
    });
    newArray.push(...lightblue);

    const green = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'green';
    });
    newArray.push(...green);

    const yellow = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'yellow';
    });
    newArray.push(...yellow);

    const red = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'red';
    });
    newArray.push(...red);

    const grey = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'white';
    });
    newArray.push(...grey);

    const white = siteList.filter(s => {
      return PinColorService(s.siteDetails.state) === 'grey';
    });
    newArray.push(...white);

    return newArray;
  }
  sortOrder = (dataArr) => {
   const {data} = this.state
   console.log(data , "ogdata")
   let tempData = [...data].map((i) => {
    return i.siteId
   })
   console.log(tempData, 'tempData')
   return dataArr
  }
  sortThumbnailData = () => {
    const {graphThumbnailData} = this.state 
    const {sortPreferences} = this.props
      if(sortPreferences === 'ALPHA-ASC'){
        const dataArr = [...graphThumbnailData]
        const sortedData = this.sortAcend(dataArr)
        this.setState({graphThumbnailData : sortedData})
      }
      if(sortPreferences === 'ALPHA-DESC'){
        const dataArr = [...graphThumbnailData]
        const sortedData = this.sortDecend(dataArr)
        this.setState({graphThumbnailData : sortedData})
      }
      if(sortPreferences === 'PINCLR-RED'){
        const dataArr = [...graphThumbnailData]
        const sortedData = this.sortRToB(dataArr)
        this.setState({graphThumbnailData : sortedData})
      }
      if(sortPreferences === 'PINCLR-BLUE'){
        const dataArr = [...graphThumbnailData]
        const sortedData = this.sortBToR(dataArr)
        this.setState({graphThumbnailData : sortedData})
      }
  }

  async componentDidMount() {
     await this.getPreferences()
     await this.loadInitialData()
     await this.loadGraphThumbnails()
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.data !== this.state.data){
      this.loadGraphThumbnails()
    }
      if(prevProps.sortPreferences !== this.props.sortPreferences){
        this.setState({sort : this.props.sortPreferences , graphThumbnailData: []} ,() => {
           this.loadGraphThumbnails()
        })
      }
  }

   toggleNoStemsSites = () => {
    this.setState({toggleNoStem: !this.state.toggleNoStem})
  }
  render() {
    const view = this.props.selectedView === "?query=Active" ? 'ACTIVE' : 'PENDING'
    const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const { show, maptype, mapView, toggleNoStem } = this.state;
    this.handleMapToggle();
    return (
      // Important! Always set the container height explicitly
      <div style={{ width: "100%" }}>
        <div className={this.backdropClass} onClick={this.closeBackdrop}></div>
        <div className="mapStickyOption">
        <Button
          variant="none"
          className={ maptype === "GraphThumbnails" ? "mapOptionsToggleButtonGraph":"mapOptionsToggleButton"}
          onClick={this.openBackdrop}
        >
          {mapView}
        </Button>
        </div>
        <Overlay show={show} placement="bottom">
          {({ placement, show: _show }) => (
            <div className="toggleMapOptions d-flex flex-column align-items-center">
              <div className="optionsFont">
                <span onClick={() => this.handleMapView("SatelliteView", "Satellite View")}>
                  Satellite View
                </span>
              </div>
              <div className="optionsFont">
                <span onClick={() => this.handleMapView("MapView", "Map View")}>
                  Map View
                </span>
              </div>
              <div className="optionsFont">
                <span onClick={() => this.handleMapView("GraphThumbnails", "Graph Thumbnails")}>
                Graph Thumbnails
                </span>
              </div>
            </div>
          )}
        </Overlay>
        <div className={this.mapShowClass}>
          {maptype === "GraphThumbnails" ? 
          <div className="container-fluid GraphThumbnailView">
                        {noStemSites?.length > 0 && 
            <div className='alert alert-danger'>
              <span>The below graphs do not have sites {!toggleNoStem && noStemSites[0]}
              {toggleNoStem&&
              noStemSites.map((site) => (
                <p>{site},</p>
              ))
               }
               {noStemSites.length >= 2 &&
              <strong onClick={() => this.toggleNoStemsSites()}>{toggleNoStem ? "show less" : "show all"}</strong>
               }

              </span>
            </div>
            }
            {this.graphThumbnailView()}
          </div> 
          : 
          <GoogleMapReact
            style={{ position: "static" }}
            bootstrapURLKeys={{ key }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={
              maptype === "SatelliteView"
                ? SatelliteView.options
                : MapView.options
            }
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => {
              this.loadApiMethods(map, maps);
            }}
          >
            {this.state.data &&
              this.state.data.map((site) => (site.status === view &&
                <LinkedPin
                  key={site.siteId}
                  lat={site.location.latitude}
                  lng={site.location.longitude}
                  site={site}
                  farmid={this.props.farmid}
                  view={view}
                  userRole={this.props.userRole}
                />
              ))}
          </GoogleMapReact>
  }
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return { 
    gState: state ,
    sortPreferences: state.userDetails.userPreferences?.preferences?.preference?.site?.sort,
  }
}
export default connect(mapState, null)(SitesMap)
