export const FILTER_TYPES = {
    ALL_FARMS: 'filter=all-farms',
    NEEDS_ATTENTION: 'filter=needs-attention',
    HAS_WARNINGS: 'filter=has-warning',
    NO_ISSUES: 'filter=no-issues',
    NEEDSATTENTION: 'filter=needs-attention',
    HASWARNINGS: 'filter=has-warning',
    NOISSUES: 'filter=no-issues',
    EXTRACTED: 'filter=extracted'
}

export const statusSelectors = {
    'needs-attention': farm => farm.reportingStatus.find(stat => stat.status === 'LOW'),
    'has-warning': farm => farm.reportingStatus.find(stat => stat.status === 'MED'),
    'no-issues': farm => farm.reportingStatus.find(stat => stat.status === 'FAIR'),
    'extracted': farm => farm.reportingStatus.find(stat => {
        return stat.status === 'EXTRACTED'
    }),
};

const filters = {
    'needs-attention': farm => statusSelectors['needs-attention'](farm).count > 0,
    'has-warning': farm => statusSelectors['has-warning'](farm).count > 0,
    'no-issues': farm => statusSelectors['no-issues'](farm).count > 0,
    'extracted': farm => statusSelectors['extracted'](farm).count > 0,
};

export function filterFarms(data, filterBy = null) {
    if (filterBy)

        return data.filter(filters[filterBy])
    return data;
}

export function getFilterFromQuery(query) {
    let filterBy = '';
    if (query) {
        if (query.indexOf(FILTER_TYPES.NEEDS_ATTENTION) !== -1)
            filterBy = 'needs-attention';
        else if (query.indexOf(FILTER_TYPES.HAS_WARNINGS) !== -1)
            filterBy = 'has-warning';
        else if (query.indexOf(FILTER_TYPES.NO_ISSUES) !== -1)
            filterBy = 'no-issues';
            else if (query.indexOf(FILTER_TYPES.EXTRACTED) !== -1)
            filterBy = 'extracted';
    }
    return filterBy;
}

//----moistureSummary-----
export const statusSelect = {
    'needs-attention': farm => farm.moistureState.find(stat => stat.value === 'LOW'),
    'has-warning': farm => farm.moistureState.find(stat => stat.value === 'MED'),
    'no-issues': farm => farm.moistureState.find(stat => stat.value === 'FAIR'),
    'extracted': farm => farm.moistureState.find(stat => stat.value === 'EXTRACTED')
};

const filter = {
    'needs-attention': farm => statusSelect['needs-attention'](farm).count > 0,
    'has-warning': farm => statusSelect['has-warning'](farm).count > 0,
    'no-issues': farm => statusSelect['no-issues'](farm).count > 0,
    'extracted': farm => statusSelect['extracted'](farm).count > 0,
};

export function filterFarm(data, filterBy = null) {

    if (filterBy)
        
        return data.filter(filter[filterBy])
    return data;
}

export function getFilter(query) {
   
    
    let filterBy = '';
    if (query) {
        if (query.indexOf(FILTER_TYPES.NEEDSATTENTION) !== -1)
            filterBy = 'needs-attention';
        else if (query.indexOf(FILTER_TYPES.HASWARNINGS) !== -1)
            filterBy = 'has-warning';
        else if (query.indexOf(FILTER_TYPES.NOISSUES) !== -1)
            filterBy = 'no-issues';
            else if (query.indexOf(FILTER_TYPES.EXTRACTED) !== -1)
            filterBy = 'extracted';
    }
    return filterBy;
}
//---------------------


export function sortByColumn(data, column, order = 'asc') {
    let _data = [...data];
    let dataType = typeof data[0][column];

    const sort = {
        asc: {
            number: (a, b) => a[column] - b[column],
            string: (a, b) => {
                if (a[column] < b[column]) return -1;
                else if (a[column] > b[column]) return 1;
                else return 0;
            }
        },
        desc: {
            number: (a, b) => b[column] - a[column],
            string: (a, b) => {
                if (a[column] > b[column]) return -1;
                else if (a[column] < b[column]) return 1;
                else return 0;
            }
        }
    }

    _data.sort(sort[order][dataType])
    return _data;
}

export function getIssueSummary(data) {

    return data?.reduce((acc, item) => {

        let count = item?.reportingStatus?.reduce((acc, i) => {
            if (i.count > 0) {
                acc[i.status] += i?.count;
            }
            return acc;
        }, { LOW: 0, MED: 0, FAIR: 0 , EXTRACTED: 0});

        acc.LOW += count?.LOW;
        acc.MED += count?.MED;
        acc.FAIR += count?.FAIR;
        acc.EXTRACTED += count?.EXTRACTED;

        return acc;
    }, { LOW: 0, MED: 0, FAIR: 0, EXTRACTED: 0 });
}

export function getMoistureSummary(data) {

    return data?.reduce((acc, item) => {
            
        let count = item?.moistureState?.reduce((acc, i) => {
            if (i?.count > 0) {
                acc[i?.value] += i.count;
            }
            return acc;
        }, { LOW: 0, MED: 0, FAIR: 0, EXTRACTED: 0 });

        acc.LOW += count?.LOW;
        acc.MED += count?.MED;
        acc.FAIR += count?.FAIR;
        acc.EXTRACTED += count?.EXTRACTED;

        

        return acc;
    }, { LOW: 0, MED: 0, FAIR: 0, EXTRACTED:0 });
}


