import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import InfoDialogue from '../group/InvitationModal/inviteDone';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ShopItemCard from './shopItemCard';
import subscriptionService from './subscriptions/duck/subscriptionService';
import currencyMap from './subscriptions/duck/currencyMap';
// import {makeSubscriptionData} from './subscriptions/duck/subscriptionUtility';
import PurchaseModal from './purchaseModal';
import SpinnerButton from '../common/spinnerButton';
import Message from '../common/messages';
import itemImg from "../../assets/images/sensor/shop.jpg";
import sensorImg from "../../assets/images/sensor.png";
import backspace from "../../assets/images/backspace/keyboard-backspace-1.png";
import userActions from '../user/duck/userActions';

class ProductDetails extends Component {
  state = {
    itemQtyCounter: 1,
    allPlans: null,
    viewingPlan: null,
    show: false,
    subscriptionItem: null,
    stripeCustomerDetails: null,
    error: null,
    showPaymentmsg: false,
  }

  clearErrors = () => {
    this.setState({ error: null })
  }

  closePaymentmsg = () => {
    this.setState({ showPaymentmsg: false });
    this.props.history.push('/dashboard/shop');
  }

  handleClose = (success) => {
    if (success) {
      this.setState({ showPaymentmsg: true });
    }
    this.setState({ show: false });
  }
  // handleAdd = () => {
  //     this.setState({ itemQtyCounter: this.state.itemQtyCounter + 1 })
  // }
  // handleDeduce = () => {
  //     let itemQtyCounter = this.state.itemQtyCounter;
  //     itemQtyCounter = itemQtyCounter < 0 ? 0 : itemQtyCounter - 1;
  //     this.setState({ itemQtyCounter })
  // }
  // setQuantity = event => {
  //     const quantity = parseInt(event.target.value);
  //     this.setState({itemQtyCounter: quantity > 0? quantity: 0})
  // }

  createNewSubscription = (newSubscription) => subscriptionService
    .createSubscription([newSubscription])
    .then(subs => {
      this.setState({
        subscriptionItem: subs.subscription[0].details.subscription.items.data.find(
          subsItem => subsItem.plan.id === newSubscription.selectedPlan[0].plan.id
        )
      });
    });

  addPlanToOrgSubscription = (subscriptionId, plan) => subscriptionService
    .updateSubscriptionAddPlan(subscriptionId, { plan })
    .then(data => {
      let subscriptionItem = data.subscription.details.subscription.items.data.find(
        subsItem => subsItem.plan.id === plan.id
      );
      this.setState({ subscriptionItem })
    })
    .catch(errorAddingPlan => {
      console.log({ errorAddingPlan });
      this.setState({ error: errorAddingPlan, show: false });
    })

  addPurchase = () => {

    if (!this.props.isAdmin) {
      return;
    }

    const plan = {
      id: this.state.viewingPlan.id,
      name: this.state.viewingPlan.metadata.name
    };

    const newSubscription = {
      name: plan?.metadata?.name,
      description: plan?.metadata?.name,
      selectedPlan: [{ plan, sites: [] }]
    };

    if (this.props.hasStripeCustomerDetails) {
      // customer exists
      subscriptionService.getSubscription()
        .then(subs => {
          // subscription exists for customer
          const subscriptionId = subs.details.subscription.id;
          let subscriptionItem;
          try {
            // plan exists in customer subscription
            subscriptionItem = subs.details.subscription.items.data.find(
              subsItem => subsItem.plan.id === plan.id
            );
            this.setState({ subscriptionItem });
          } catch (err) {
            subscriptionItem = null;
          }

          if (!subscriptionItem) {
            this.addPlanToOrgSubscription(subscriptionId, plan);
          }
        })
        .catch(err => {
          this.createNewSubscription(newSubscription);
          console.log({ err })
        })
    } else {
      // customer doesnot exist
      const profile = {
        name: this.props.username,
        email: this.props.email,
        phone: this.props.phone,
        address: { line1: this.props.address }
      }
      subscriptionService.createSubscription([newSubscription], profile)
        .then(subs => {
          const customer = subs.subscription["0"].user.stripeCustomerDetails;
          this.setState({
            subscriptionItem: subs.subscription[0].details.subscription.items.data.find(
              subsItem => subsItem.plan.id === plan.id
            ),
            stripeCustomerDetails: customer
          });
          this.props.updateStripeCustomer(customer);
        })
        .catch(subscriptionCreationError => {
          this.setState({
            error: subscriptionCreationError,
            show: false
          })
        });
    }
  }

  componentDidMount() {
    subscriptionService.getPlans()
      .then(plans => this.setState({
        allPlans: plans,
        viewingPlan: plans.find(plan => plan.id === this.props.match.params.productId)
      }))
      .catch(err => this.setState({ errors: err }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.productId !== this.props.match.params.productId) {
      this.setState({
        itemQtyCounter: 1,
        viewingPlan: this.state.allPlans.find(
          plan => plan.id === this.props.match.params.productId
        )
      });
    }
  }

  handelePurchaseButtonClick = () => {
    this.addPurchase();
    this.setState({ show: true });
  }

  render() {
    const { itemQtyCounter, viewingPlan } = this.state;
    const backLink = this.props.location.state?.refLink || '/dashboard/shop';
    const recommendedPlans =
      this.state.allPlans &&
      this.state.allPlans.filter(
        plan => plan.id !== this.props.match.params.productId
      );
    const isUserAllowedToShop = this.props.isAdmin && !this.props.isGroguruAdmin;
    const data = { title: 'Success', message: 'Subscription Updated.' };
    return (
      <div className="shopItemPage ">
        <div className="backButton">
          <Link to={backLink}>
            <div className="d-flex ">
              <img src={backspace} alt="backspace" />
              <h6>Back to home page</h6>
            </div>
          </Link>
        </div>
        <div className="row mr-0 itemInformation">
          <div className="leftSideImages col-lg-6">
            <img src={(viewingPlan && viewingPlan?.metadata?.image) || sensorImg} alt="sensor" />
          </div>
          <div className="RightSideInfo col-lg-6">
            {viewingPlan && <div className="itemBuyingInfo d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div className="itemNameandInfo d-flex flex-column">
                  <h2>{viewingPlan?.metadata?.name}</h2>
                  <h5>{viewingPlan?.metadata?.description || 'No oneline description'}</h5>
                  <h6>{currencyMap[viewingPlan.currency]}{viewingPlan?.amount / 100} ea.</h6>
                </div>
                {/* <div className="itemQtyToggle d-flex align-items-center">
                                <Button variant="none" disabled={itemQtyCounter <= 0 ? true : false} className="itemQtyToggleButton" onClick={this.handleDeduce}>-</Button>
                                <div className="d-flex flex-column align-items-center">
                                    <input value={itemQtyCounter} onChange={this.setQuantity}/>
                                    <div className="numberLine"></div>
                                </div>
                                <Button variant="none" className="itemQtyToggleButton" onClick={this.handleAdd}>+</Button>
                            </div> */}
              </div>
              <div className="descriptionandPay d-flex flex-column">
                <div className="itemDescription">
                  <h5>About</h5>
                  <p>{viewingPlan.metadata.about || "No description available"}</p>
                </div>
                {this.state.error && <Message
                  variant="error"
                  message={this.state.error.message}
                  dismissTime={6000}
                  onDismiss={this.clearErrors} />}
                {isUserAllowedToShop ? <SpinnerButton
                  spinning={this.state.show && !this.state.subscriptionItem}
                  onClick={this.handelePurchaseButtonClick}
                  className="btn-submit itemPayButton btn btn-none">
                  {currencyMap[viewingPlan.currency]}
                  {(viewingPlan.amount / 100) * itemQtyCounter}
                </SpinnerButton>
                  : !this.props.isAdmin && <p>Please contact your admin to add plans to sites.</p>}
                <PurchaseModal
                  show={this.state.subscriptionItem && this.state.show}
                  subscriptionItem={this.state.subscriptionItem}
                  onClose={(success) => this.handleClose(success)}
                  site={this.props.siteId} />
                <InfoDialogue
                  show={this.state.showPaymentmsg}
                  onClose={this.closePaymentmsg}
                  buttonLabel="OK"
                  data={data} />
              </div>
            </div>}
            <div className="relatedItemInfo">
              <div className="otherHardwares">Other Plans</div>
              <div className="row">
                {!recommendedPlans ? (
                  <Spinner animation="grow" />
                ) : (
                    recommendedPlans.map(plan => (
                      <ShopItemCard
                        key={plan.id}
                        title={plan.metadata.name}
                        image={plan.metadata.image || itemImg}
                        productId={plan.id}
                        price={`${currencyMap[plan.currency]}${plan.amount / 100}`}
                        className="col-lg-4 pl-0 pr-0"
                      />
                    ))
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const profile = state.userDetails.profileDetails.profile;
  const customer = state.userDetails.profileDetails.profile.stripeCustomerDetails;
  const selectedSite = state.userDetails.userPreferences.selectedSite;
  const isGroguruAdmin = profile.role.name === 'groguru_admin';
  return {
    siteId: selectedSite,
    hasStripeCustomerDetails: !!customer,
    email: profile.email,
    name: profile.name,
    phone: profile.phone,
    address: profile.address,
    isAdmin: profile.role.name.includes('admin'),
    isGroguruAdmin
  }
};

const mapDispatchToProps = {
  updateStripeCustomer: userActions.updateStripeCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);