import React from "react";
import {Modal, Form} from "react-bootstrap";
import GForm from "../../../common/form";
import greenMarker from "../../../../assets/images/farms/farm-create/marker-green.png";
import Joi from "joi-browser";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import {connect} from "react-redux";
import farmActions from "../../../farm/duck/farmActions";
import siteService from "../../duck/siteService";

const defaults = {
    zoom: 4,
    options(maps) {
        return {
            maxZoom: 18,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            }
        }
    },
};

const markerStyle = {
    position: 'absolute',
    transform: 'translate(-30%, -50%)',
    height: "30px"
}

const Marker = () => <div><img src={greenMarker} style={markerStyle} alt="farm location marker"/></div>;
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
Geocode.setApiKey(key);
Geocode.setLanguage("en");

class AboutMap extends GForm {
    state = {
        data: {
            latitude: this.props.lat,
            longitude: this.props.lng,
           },
        errors: {},
        serviceError: null,
        show: this.props.show,
        businesses: []
    }
    

    schema = {
        
        latitude: Joi.number().required().label('Latitude'),
        longitude: Joi.number().required().label('Longitude'),
       
    };

    reverseGeoCodeFunc = null;

    static defaultProps = {
        center: {
            lat: 8.3,
            lng: 77.5
        },
        zoom: defaults.zoom
    };


    toggleModal = (value) => {
        if (!value) {
            const data = {
               
                latitude: 0.0,
                longitude: 0.0,
                
            }
            this.setState({data});
            this.props.handleMap();
        }
        // this.setState({show: value});
        this.props.handleMap();
    }

    handleMapClick = ({lat, lng, event}) => {
        const data = {...this.state.data};
        data.latitude = lat;
        data.longitude = lng;
        this.setState({data});
     
    }

    

    doSubmit = () => {
     const id=this.props.id;
     const data=this.state.data;
     this.props.setChangeLogs(false);
    siteService.updateSite(id,{location:{...data}}).then(response=>{
        
        const {latitude,longitude}=response.data.parsedSite.location;
        this.props.onSiteDataChange({location:{latitude,longitude}});
        this.props.setChangeLogs(true)

    })
      this.toggleModal(false);
    this.props.handleLatLng(this.state.data.latitude,this.state.data.longitude);

    }

    
    loadApiMethods = (map, maps) => {

        this.mapPanTo = map && map.panTo.bind(map);
        this.map = map;
        this.maps = maps;
        // if (map) {
        //     this.mapPanTo = map.panTo.bind(map);
        // }
        this.setMapBounds(this.map, this.maps);
    };
    setMapBounds = (map, maps) => {

        const bounds = new maps.LatLngBounds();
    
        
          
          bounds.extend(
            new maps.LatLng(this.state.data.latitude,this.state.data.longitude)
          );
       
        map.fitBounds(bounds);
      };

    render() {
      
       
        return (
            <div className="" >
                <div className="create-farm">
         
         
         <Modal className="create-farm-modal "  show={this.state.show} size="lg" centered
                onHide={() => {
                    this.toggleModal(false);
                }}>
             <Modal.Header   closeButton={false}>
                 <p></p>
                 <span className=" cp" onClick={() => {
                     this.toggleModal(false);
                 }}>Close</span>
             </Modal.Header>
             <Modal.Body>
                 

                 <Form className="mt-20 mb-10">
                    

                     <div className="map" >
                         <GoogleMapReact
                             bootstrapURLKeys={{key}}
                             defaultCenter={this.props.center}
                             defaultZoom={this.props.zoom}
                             options={defaults.options}
                             onChange={this.handleMapChange}
                             onClick={this.handleMapClick}
                             yesIWantToUseGoogleMapApiInternals={true}
                             onGoogleApiLoaded={({map, maps}) => this.loadApiMethods(map, maps)}>
                             <Marker lat={this.state.data.latitude}
                                     lng={this.state.data.longitude}></Marker>
                         </GoogleMapReact>
                     </div>
                     <div className="row">
                         <div className="col-md-6">
                             {this.renderFormGroup('latitude', 'Latitude', 'text')}
                         </div>

                         <div className="col-md-6">
                             {this.renderFormGroup('longitude', 'Longitude', 'text')}
                         </div>
                     </div>
                     {this.renderButton('Submit', 'btn-submit btn',
                         )}
                     
                 </Form>
             </Modal.Body>
         </Modal>
     </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    createFarm: farmActions.createFarm
};

const mapStateToProps = (state) => {
    const {userDetails, farms} = state;
    const profileDetails = userDetails.profileDetails;
    return {profileDetails, farms}
}

const connectedFarmForm = connect(mapStateToProps, mapDispatchToProps)( AboutMap);
export {connectedFarmForm as AboutMap};
