import React, { Component } from "react";
import { Card, Spinner } from "react-bootstrap";
import groupService from './duck/groupService';
import InviteMember from './inviteMember';
import MemberItem from './memberItem';
import {invertUserLevel} from './duck/groupUtils';


class Members extends Component {
  state = {
    admin: null,
    user: null,
    modalShown: ''
  }

  modalShowAdmin = () => this.setState({modalShown: 'admin'})

  modalShowUser = () => this.setState({modalShown: 'user'})

  modalHide = () => this.setState({modalShown: ''})
  
  reduceMembers(data) {
      return data.reduce((acc, member) => {
     
      let userLevel = member.role? member.role?.name?.split('_')[1]:null
      userLevel!==null && acc[userLevel].push(member);
      return acc;
      

      
    }, { admin: [], user: [] })
    
  }

  changeUserRoleUI = (userLevel, userId) => {
    const user = this.state[userLevel].find(member => member.objectId === userId);
    const [orgType] = user.role.name.split('_');
    const changeTo = invertUserLevel[userLevel];
    user.role.name = `${orgType}_${changeTo}`;
    this.setState({
      [userLevel]: this.state[userLevel].filter(member => member.objectId !== userId),
      [changeTo]: [user, ...this.state[changeTo]]
    });
  }

  removeMemberUI = (userLevel, userId) => {
    this.setState({[userLevel]: this.state[userLevel].filter(member => member.objectId !== userId)});
  }

  componentDidMount() {
   
   groupService.getGroupMembers(this.props.groupid)
      .then(members => this.setState(this.reduceMembers(members)))
      .catch(error => console.log({ MembersFetch: error }));
  }

  renderMembers = (userLevel) => {
    const data = this.state[userLevel];
    if (data === null) {
      return <Spinner animation="grow" size="lg" />
    } else if (data.length > 0) {
      return this.state[userLevel].map(member =>
         <MemberItem
        key={member.objectId}
        userLevel={userLevel}
        data={member}
        changeUserRoleUI={this.changeUserRoleUI}
        removeMemberUI={this.removeMemberUI} />);
    } else if (data.length === 0) {
      return <span>No {userLevel}s</span>
    }
  }

  render() {
    

    return (
      <div className="members-container">
        <Card>
          <Card.Body>
            <div className="card-header-text">Members</div>
            <hr></hr>
            <div className="card-body-text">
              <div className="section">
                <div>
                  <span className="admin-text">Admin</span>
                  {this.props.orgType!=='groguru' &&
                  <button onClick={this.modalShowAdmin} className="invite-text">Invite New Admin +</button>}
                  <InviteMember
                    teamid={this.props.groupid}
                    name={this.props.data.name}
                    type={this.props.orgType}
                    roleTypeValue={'admin'}
                    modalShown={this.state.modalShown === 'admin'}
                    hideDefaultButton
                    onClose={this.modalHide} />
                </div>
                {this.renderMembers('admin')}
              </div>
              <div className="section mt-20">
                <div>
                  <span className="admin-text">Users</span>
                  {this.props.orgType!=='groguru' &&
                  <button onClick={this.modalShowUser} className="invite-text">Invite New User +</button>}
                  <InviteMember
                    teamid={this.props.groupid}
                    name={this.props.data.name}
                    type={this.props.orgType}
                    roleTypeValue={'user'}
                    modalShown={this.state.modalShown === 'user'}
                    hideDefaultButton
                    onClose={this.modalHide} />
                </div>
                {this.renderMembers('user')}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Members;
