/* eslint-disable array-callback-return */
import http from "../../common/duck/httpService";
import logger from "../../common/duck/logService";
import localStorageService from "../../common/duck/localStorageService";

const baseEndPoint = "/farm";
const apiEndPoint = "/site";
const reMigrateData = "stem/re-migrate-data";
const { CancelAxiosToken } = http;

const token = localStorageService.getToken();

const getFarmDetails = (farmId) => {
  return http
    .get(`${baseEndPoint}/${farmId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
};

const getSiteDetails = (farmId, twigDetails = true) => {
  return http
    .get(`${apiEndPoint}/farm/${farmId}`, {params: {twigDetails}})
    .then(response => {
      const data = []
    // eslint-disable-next-line no-unused-expressions
    response?.data?.data?.map((site, index) => {
     if((site?.twigDetails?.settings?.extracted && site?.twigDetails?.settings?.extracted === 'true') || site.deleted){
       //Extracted Site
     }else{
      data.push(site)
     }
    })
    response.data.data = data
    return response.data;
    })
    
};


const getSiteDetailsWithSubscriptions = (farmId, twigDetails = true, subscriptionHistory=true) => {
  return http
    .get(`${apiEndPoint}/farm/${farmId}`, {params: {twigDetails,subscriptionHistory}})
    .then(response => {

      return response.data;
    })
    
};

function activateSubscriptionsOrComments(SubscriptionData) {
  return http.post("/subscriptions/activate-comments", SubscriptionData)
  .then(response => response.data)
  .catch((error) => {
    const { data } = error.response;
    logger.log(error);
    return Promise.reject(data);
  });
} 

const weatherDetails = (lat, long) => {
  return http
    .get(`/weather/${lat},${long}?unit=uk2`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
};
const getSiteReportingStatus = (siteId) => {
  return http
    .get(`${apiEndPoint}/${siteId}/state`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
};

const updateFarmSettings = (farmId, payload) => {
  return http
    .patch(`${baseEndPoint}/${farmId}`, payload, {
      headers: { Authorization: token },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
};
const getSite = (objectId) => {
  return http
    .get(`${apiEndPoint}/${objectId}?twigDetails=true`)
    .then((response) => 

    response.data.data[0]
    );
};

// Get site with templatedata
const getSiteWithTemplateData = (objectId) => {
  return http
    .get(`${apiEndPoint}/${objectId}/Templatedatas?twigDetails=true`)
    .then((response) => 

    response.data.data[0]
    );
};

function getSiteByName() {
  const source = CancelAxiosToken.source();
  function request(name,status,farmId,flag) {
    let farmid='';
    if (isNaN(name) || name.length===0 ||flag){farmid=''}else {farmid=`&farmId=${farmId}`}
    const encodedSearch = encodeURIComponent(name);
    const EndPoint = `${apiEndPoint}?name=${encodedSearch}&twigDetails=true&status=${status}${farmid}`;
    return http
      .get(EndPoint, {
        cancelToken: source.token,
      })
      .then((response) => {
        const data = []
        // eslint-disable-next-line no-unused-expressions
        response?.data?.data?.map((site, index) => {
         if(site?.twigDetails?.settings?.extracted && site?.twigDetails?.settings?.extracted === 'true'){
           //Extracted Site
         }else{
          data.push(site)
         }
        })
        response.data.data = data
        return response.data.data;
      })
      .catch((error) => {
        if (http.isCancel(error)) {
          return Promise.reject({ isCancelled: true });
        }
        logger.exception(error);
        return Promise.reject(error);
      });
  }
  return { request, cancel: source.cancel };
}

const getStemBucketDetails = (siteId) => {
  return http
    .get(`/stem/${siteId}/bucket-details`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      logger.log(error);
      return Promise.reject(data);
    });
};
function siteSearch() {
  const source = CancelAxiosToken.source();
  function request(params) {
    return http
      .get(apiEndPoint, {
        cancelToken: source.token,
        params
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (http.isCancel(error)) {
          return Promise.reject({ isCancelled: true });
        }
        logger.exception(error);
        return Promise.reject(error);
      });
  }
  return { request, cancel: source.cancel };
}

function getSitesForFarms(listOfFarms = []) {
  return http.post(`${apiEndPoint}/farms?twigDetails=true`, {
    farms: listOfFarms
  })
  .then(response => Object.values(response.data.data).flat())
}

function updateSite(siteId,payload)
{
  return http.patch(`${apiEndPoint}/${siteId}`,payload)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    const { data } = error.response;
    logger.log(error);
    return Promise.reject(data);
  });
}

function deleteSite(siteId,payload)
{
  return http.post(`${apiEndPoint}/${siteId}/delete`,payload)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    const { data } = error.response;
    logger.log(error);
    return Promise.reject(data);
  });
}

function updateSiteWithAllValues(siteId,twigId,status)
{
return http
.put(`${reMigrateData}/${siteId}/${twigId}/${status}`)
.then((response) => {
  return response.data;
})
.catch(error => {
  if(error.response){
    const {data} = error.response;
    logger.log(error);
    return Promise.reject(data);
  }else{
    return Promise.reject(error.response);
  }
});
}


export default {
  getSiteDetails,
  getFarmDetails,
  weatherDetails,
  getSiteReportingStatus,
  updateFarmSettings,
  getSiteByName,
  getSite,
  getStemBucketDetails,
  updateSite,
  updateSiteWithAllValues,
  siteSearch,
  getSitesForFarms,
  getSiteDetailsWithSubscriptions,
  activateSubscriptionsOrComments,
  deleteSite,
  getSiteWithTemplateData
};
