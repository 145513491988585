export default {
    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    PROFILE_UPDATE_CARDS: 'USER_PROFILE_UPDATE_CARDS',
    PROFILE_UPDATE_CUSTOMER: 'PROFILE_UPDATE_STRIPE_CUSTOMER_DETAILS',

    PREFERENCE_REQUEST: 'USER_PREFERENCE_REQUEST',
    PREFERENCE_SUCCESS: 'USER_PREFERENCE_SUCCESS',
    PREFERENCE_FAILURE: 'USER_PREFERENCE_FAILURE',
    PREFERENCE_UPDATE_GRAPH_TABS: 'USER_PREFERENCE_UPDATE_GRAPH_TABS',

    PARENT_REQUEST: 'USER_PARENT_DETAILS_LOAD',
    PARENT_SUCCESS: 'USER_PARENT_DETAILS_SUCCESS',
    PARENT_FAILURE: 'USER_PARENT_DETAILS_FAILURE',

    UPDATE_USER_GRAPH_PREFERENCES_FOR_SITE: 'UPDATE_USER_GRAPH_PREFERENCES_FOR_SITE',
    UPDATE_DEFAULT_LANDING: 'UPDATE_USER_PREFERENCE_DEFAULT_LANDING_PAGE',
    UPDATE_SORT: 'UPDATE SORT ORDER',

    UPDATE_BATTERY: 'UPDATE_BATTERY',
    UPDATE_RSS: 'UPDATE_RSS',

    SELECTED_SITE: 'SELECTED_SITE',

    LOGOUT: 'USERS_LOGOUT'
};
