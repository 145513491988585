import { PLAN_DETAILS, ROLES } from "./constants";

/**
 * Get's the status of Plan is Active or not
 * @param {String} userRole - User role 
 * @param {Object} planDetails - planDetails Object 
 * @returns {Boolean}
 */
function isPlanActive(userRole, planDetails) {
    let planActive = false;
    if (userRole === ROLES.GROGURU_ADMIN || planDetails?.plan === PLAN_DETAILS.SILVER || planDetails?.plan === PLAN_DETAILS.GOLD) {
        planActive = true;
    }
    return planActive;
}

function isPlanGold(userRole, planDetails) {
    let goldPlan = false;
    if (userRole === ROLES.GROGURU_ADMIN || planDetails?.plan === PLAN_DETAILS.GOLD) {
       goldPlan = true;
    }
    return goldPlan;
}

export {
    isPlanActive,
    isPlanGold,
}