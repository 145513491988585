import React from "react";
import {Card, Form} from "react-bootstrap";
import logo from "../../../assets/images/groguru-long-logo/groguru-long-logo.png";
import GForm from "../../common/form";
import Joi from "joi-browser";
import userActions from "../duck/userActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {history} from "../../common/duck/history";
import Message from "../../common/messages";
import invitationService from "../duck/invitationService";
import localStorageService from "../../common/duck/localStorageService";
import logService from "../../common/duck/logService";

class SignUpToGroup extends GForm {
    state = {
        data: {
            email: this.props.invitationDetails.email, password: "", confirmPassword: ""
        },
        errors: {},
        serviceError: null
    }
    schema = {
        email: Joi.string().required().label('Email'),
        password: Joi.string().required().label('Password'),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required()
            .options({language: {any: {allowOnly: 'must match Password'}}}).label('Confirm Password')
    };

  doSubmit = () => {
    this.setState({serviceError: null});
    const {invitationDetails} = this.props;
    const signUpData = {...this.state.data};
    signUpData.autologin = true;
    signUpData.org = invitationDetails.organisation.objectId;
    signUpData.username = signUpData.email
    invitationService.signUp(signUpData).then(() => {
      this.props.getProfile(localStorageService.getUserId());
      this.props.getPreferences();
    }).catch(error => {
      logService.log(error);
      this.setState({serviceError: error.message});
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.profileDetails.profile) {
      history.push(`/signUp/member/personalInfo`);
    }
    return (prevState);
  }

  render() {
        const {invitationDetails} = this.props;
        const userRole = (invitationDetails.role.endsWith('_user')) ? 'a member' : 'an administrator';
        return (
            <div className="sign-up-team">
                <div className="sign-up-image-container">
                    <div className="content mb-40 mt-40">
                        <div className="text-center  ">
                            <img src={logo} className="groguru_long_logo" alt="logo"/>
                        </div>
                        <Card className="mt-80  pt-30 signup-width">
                            <Card.Body>
                                <h2>Welcome to GroGuru</h2>
                                <Card.Title>
                                    You’ve been invited to join the {invitationDetails.organisation.name}. team
                                    as {userRole}. Create an account (or sign in if you already have an account) to join.
                                </Card.Title>

                                <Form className="mt-10">
                                    <div className="row">
                                        <div className="col-12 ">
                                            {this.renderFormGroup("email", "Email", "text", {disabled: true})}
                                        </div>
                                        <div className="col-12 ">
                                            {this.renderFormGroup("password", "Password", "password",
                                                {autoComplete: 'off'})}
                                            {this.renderFormGroup("confirmPassword", "Confirm Password", "password",
                                                {autoComplete: "off"})}
                                        </div>
                                    </div>
                                    {this.renderButton("Create Your Account",
                                        "btn btn-lg btn-submit btn-block mt-30 sign-in-text")}
                                </Form>

                                <h5 className="pt-20"
                                    onClick={() => history.push("/login")}>Already have an account? Log in.</h5>
                                {this.state.serviceError && <Message variant="error" message={this.state.serviceError}/>}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = {
    getProfile: userActions.getProfile,
    getPreferences: userActions.getPreferences
};

const mapStateToProps = (state) => {
    const {profileDetails} = state.userDetails;
    return {profileDetails}
}

const connectedSignUpToGroup = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SignUpToGroup)
);

export {connectedSignUpToGroup as SignUpToGroup};
