import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Nav, Spinner } from "react-bootstrap";
import { NavLink, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import userActions from "../../../user/duck/userActions";
import GraphDetails from "../graph/graphDetails";
import TemplateBody from "../template/templateBody";
import ConfigurationBody from "../configuration/configurationBody";
import AboutBody from "../about/aboutBody";
import SelectSite from "../../selectSite";
import siteService from '../../duck/siteService';
import backspace from "../../../../assets/images/backspace/keyboard-backspace-1.png";
import { Helmet } from 'react-helmet';
import SiteContext, { SiteUpdate } from './duck/siteContext';
import * as moment from 'moment'
import BarIcon from "./BarIcon/barIcon";
import BatteryIcon from "./BatteryIcon/batteryIcon";
import { isPlanActive } from "../../../common/duck/planActive";


function GraphBody(props) {

    const { farmid, siteid, siteObjId, twigid } = props.match.params;
    const [siteData, setSiteData] = useState({ isLoading: true });
    const [allSites, setAllSites] = useState(null);
    // const locationState = props.location.state;

    useEffect(() => {
        // if (!locationState) {
        siteService.getSite(siteObjId)
            .then(data => {
                let planActive = isPlanActive(props.userRole, data.planDetails);
                if (!planActive) {
                    props.history.push({
                        pathname: `/farm/${farmid}/sites`,
                    });
                    return;
                }
                const twigDetails = {
                    type: data.twigDetails.type,
                    twigObjectId: data.twigDetails?.objectId,
                    lastUpdated: new Date(data.twigDetails.details && data.twigDetails.details.lastUpdated).valueOf(),
                    plantType: data.twigDetails.settings.plantType.value,
                    badgerId: data.twigDetails.badgerId,
                    settings: data.twigDetails.settings,
                    configInterval: data.twigDetails.settings.reportingInterval && data.twigDetails.settings.reportingInterval.value,
                    FoxSensor: data.twigDetails.settings.addLocalSensor && data.twigDetails.settings.addLocalSensor.value,
                    hardwaretype: data.twigDetails.settings.hardwareType && data.twigDetails.settings.hardwareType.value,
                    plantingDate: data.twigDetails.settings.plantingDate && data.twigDetails.settings.plantingDate.value,
                    installByDate: data.twigDetails.settings.installByDate && data.twigDetails.settings.installByDate.value,
                    irrigationType: data.twigDetails.settings.irrigationType && data.twigDetails.settings.irrigationType.value,
                    soilType: data.twigDetails.settings.soilType && data.twigDetails.settings.soilType.value,
                    distanceFromWaterSource: data.twigDetails.settings.distanceFromWaterSource?.value,
                    maxIrrigationVolume: data.twigDetails.settings.maxIrrigationVolume?.value,
                    hardwareSensorLocation: data.twigDetails.settings.hardwareSensorLocation,
                    localSensorLocation: data.twigDetails.settings.localSensorLocation,

                    wl2ValuesEdited: data.twigDetails.settings.x_volwatcontWL2 && data.twigDetails.settings.x_volwatcontWL2.userEdited,
                    wl2ValuesLocalEdited: data.twigDetails.settings.x_volwatcontWL2_local && data.twigDetails.settings.x_volwatcontWL2_local.userEdited,
                    wl2Values: data.twigDetails.settings.x_volwatcontWL2 && data.twigDetails.settings.x_volwatcontWL2.value,
                    wl2ValuesLocal: data.twigDetails.settings.x_volwatcontWL2_local && data.twigDetails.settings.x_volwatcontWL2_local.value,

                    wl2Values_mlValue: data.twigDetails.settings.x_volwatcontWL2 && data.twigDetails.settings.x_volwatcontWL2.ml_value,
                    wl2ValuesLocal_mlValue: data.twigDetails.settings.x_volwatcontWL2_local && data.twigDetails.settings.x_volwatcontWL2_local.ml_value,

                    wl1ValuesLocal: data.twigDetails.settings.x_volwatcontWL1_local && data.twigDetails.settings.x_volwatcontWL1_local.value,
                    wl1Values: data.twigDetails.settings.x_volwatcontWL1 && data.twigDetails.settings.x_volwatcontWL1.value,

                    wl1ValuesLocalEdited: data.twigDetails.settings.x_volwatcontWL1_local && data.twigDetails.settings.x_volwatcontWL1_local.userEdited,
                    wl1ValuesEdited: data.twigDetails.settings.x_volwatcontWL1 && data.twigDetails.settings.x_volwatcontWL1.userEdited,


                    wl1ValuesLocal_mlValue: data.twigDetails.settings.x_volwatcontWL1_local && data.twigDetails.settings.x_volwatcontWL1_local.ml_value,
                    wl1Values_mlValue: data.twigDetails.settings.x_volwatcontWL1 && data.twigDetails.settings.x_volwatcontWL1.ml_value,


                    rpValues: data.twigDetails.settings.x_volwatcontRP && data.twigDetails.settings.x_volwatcontRP.value,
                    rpValuesLocal: data.twigDetails.settings.x_volwatcontRP_local && data.twigDetails.settings.x_volwatcontRP_local.value,
                    rpValuesEdited: data.twigDetails.settings.x_volwatcontRP && data.twigDetails.settings.x_volwatcontRP.userEdited,
                    rpValuesLocalEdited: data.twigDetails.settings.x_volwatcontRP_local && data.twigDetails.settings.x_volwatcontRP_local.userEdited,

                    rpValues_mlValue: data.twigDetails.settings.x_volwatcontRP && data.twigDetails.settings.x_volwatcontRP.ml_value,
                    rpValuesLocal_mlValue: data.twigDetails.settings.x_volwatcontRP_local && data.twigDetails.settings.x_volwatcontRP_local.ml_value,

                    hardwareSensor: data.twigDetails.settings.hardwareSensorLocation &&
                        data.twigDetails.settings.hardwareSensorLocation.value.sensors,

                    localSensor: data.twigDetails.settings.localSensorLocation &&
                        data.twigDetails.settings.localSensorLocation.value.sensors,

                    Local: data?.twigDetails?.settings?.localSensor,
                    fcValues: data.twigDetails.settings.x_volwatcontMax && data.twigDetails.settings.x_volwatcontMax.value,
                    fcValuesLocal: data.twigDetails.settings.x_volwatcontMax_local && data.twigDetails.settings.x_volwatcontMax_local.value,
                    fcValuesEdited: data.twigDetails.settings.x_volwatcontMax && data.twigDetails.settings.x_volwatcontMax.userEdited,
                    fcValuesLocalEdited: data.twigDetails.settings.x_volwatcontMax_local && data.twigDetails.settings.x_volwatcontMax_local.userEdited,


                    fcValues_mlValue: data.twigDetails.settings.x_volwatcontMax && data.twigDetails.settings.x_volwatcontMax.ml_value,
                    fcValuesLocal_mlValue: data.twigDetails.settings.x_volwatcontMax_local && data.twigDetails.settings.x_volwatcontMax_local.ml_value,

                    pwpValues: data.twigDetails.settings.x_volwatcontMin && data.twigDetails.settings.x_volwatcontMin.value,
                    pwpValuesLocal: data.twigDetails.settings.x_volwatcontMin_local && data.twigDetails.settings.x_volwatcontMin_local.value,
                    pwpValuesEdited: data.twigDetails.settings.x_volwatcontMin && data.twigDetails.settings.x_volwatcontMin.userEdited,
                    pwpValuesLocalEdited: data.twigDetails.settings.x_volwatcontMin_local && data.twigDetails.settings.x_volwatcontMin_local.userEdited,

                    pwpValues_mlValue: data.twigDetails.settings.x_volwatcontMin && data.twigDetails.settings.x_volwatcontMin.ml_value,
                    pwpValuesLocal_mlValue: data.twigDetails.settings.x_volwatcontMin_local && data.twigDetails.settings.x_volwatcontMin_local.ml_value,
                };
                setSiteData({ ...data, twigDetails });
                props.updateSelectedSite(data);
            })
            .catch(console.log);
        // } else {
        // const twigDetails = {
        //     type: locationState.twigDetails.type,
        //     twigObjectId:locationState.twigDetails?.objectId,
        //     lastUpdated: new Date(locationState.twigDetails.details?.lastUpdated).valueOf(),
        //     plantType: locationState.twigDetails.settings.plantType.value,

        //     badgerId:locationState.twigDetails.badgerId,
        //     configInterval:locationState.twigDetails.settings.reportingInterval && locationState.twigDetails.settings.reportingInterval.value,

        //     FoxSensor:locationState.twigDetails.settings.addLocalSensor?.value,
        //     hardwaretype:locationState.twigDetails.settings.hardwareType?.value ,
        //     plantingDate:locationState.twigDetails.settings.plantingDate?.value ,
        //     installByDate:locationState.twigDetails.settings.installByDate?.value ,
        //     irrigationType :locationState.twigDetails.settings.irrigationType?.value ,
        //     soilType:locationState.twigDetails.settings.soilType?.value ,
        //     hardwareSensorLocation:locationState.twigDetails.settings.hardwareSensorLocation,
        //     localSensorLocation:locationState.twigDetails.settings.localSensorLocation,

        //    wl2Values:locationState.twigDetails.settings.x_volwatcontWL2 && locationState.twigDetails.settings.x_volwatcontWL2.value,
        //    wl2Values_local: locationState.twigDetails.settings.x_volwatcontWL2_local && locationState.twigDetails.settings.x_volwatcontWL2_local.value, 
        //    wl2ValuesEdited:locationState.twigDetails.settings.x_volwatcontWL2 && locationState.twigDetails.settings.x_volwatcontWL2.userEdited,
        //    wl2Values_localEdited: locationState.twigDetails.settings.x_volwatcontWL2_local && locationState.twigDetails.settings.x_volwatcontWL2_local.userEdited, 

        //     wl2Values_mlValue:locationState.twigDetails.settings.x_volwatcontWL2 && locationState.twigDetails.settings.x_volwatcontWL2.ml_value,
        //     wl2ValuesLocal_mlValue: locationState.twigDetails.settings.x_volwatcontWL2_local && locationState.twigDetails.settings.x_volwatcontWL2_local.ml_value,

        //    wl1Values_local:locationState.twigDetails.settings.x_volwatcontWL1_local && locationState.twigDetails.settings.x_volwatcontWL1_local.value,
        //    wl1Values : locationState.twigDetails.settings.x_volwatcontWL1 && locationState.twigDetails.settings.x_volwatcontWL1.value,
        //    wl1Values_localEdited:locationState.twigDetails.settings.x_volwatcontWL1_local && locationState.twigDetails.settings.x_volwatcontWL1_local.userEdited,
        //    wl1ValuesEdited : locationState.twigDetails.settings.x_volwatcontWL1 && locationState.twigDetails.settings.x_volwatcontWL1.userEdited,


        //    wl1ValuesLocal_mlValue:locationState.twigDetails.settings.x_volwatcontWL1_local && locationState.twigDetails.settings.x_volwatcontWL1_local.ml_value,
        //     wl1Values_mlValue : locationState.twigDetails.settings.x_volwatcontWL1 && locationState.twigDetails.settings.x_volwatcontWL1.ml_value,


        //     rpValues: locationState.twigDetails.settings.x_volwatcontRP && locationState.twigDetails.settings.x_volwatcontRP.value,
        //     rpValuesLocal:locationState.twigDetails.settings.x_volwatcontRP_local &&locationState.twigDetails.settings.x_volwatcontRP_local.value,
        //     rpValuesEdited: locationState.twigDetails.settings.x_volwatcontRP && locationState.twigDetails.settings.x_volwatcontRP.userEdited,
        //     rpValuesLocalEdited:locationState.twigDetails.settings.x_volwatcontRP_local &&locationState.twigDetails.settings.x_volwatcontRP_local.userEdited,

        //    rpValues_mlValue: locationState.twigDetails.settings.x_volwatcontRP && locationState.twigDetails.settings.x_volwatcontRP.ml_value,
        //     rpValuesLocal_mlValue:locationState.twigDetails.settings.x_volwatcontRP_local &&locationState.twigDetails.settings.x_volwatcontRP_local.ml_value,


        //     hardwareSensor: locationState.twigDetails.settings.hardwareSensorLocation &&
        //     locationState.twigDetails.settings.hardwareSensorLocation.value.sensors,

        //     localSensor: locationState.twigDetails.settings.localSensorLocation &&
        //     locationState.twigDetails.settings.localSensorLocation.value.sensors,

        //     fcValues :locationState.twigDetails.settings.x_volwatcontMax && locationState.twigDetails.settings.x_volwatcontMax.value,
        //     fcValuesLocal :locationState.twigDetails.settings.x_volwatcontMax_local && locationState.twigDetails.settings.x_volwatcontMax_local.value,
        //     fcValuesEdited :locationState.twigDetails.settings.x_volwatcontMax && locationState.twigDetails.settings.x_volwatcontMax.userEdited,
        //     fcValuesLocalEdited :locationState.twigDetails.settings.x_volwatcontMax_local && locationState.twigDetails.settings.x_volwatcontMax_local.userEdited,

        //     fcValues_mlValue :locationState.twigDetails.settings.x_volwatcontMax && locationState.twigDetails.settings.x_volwatcontMax.ml_value,
        //     fcValuesLocal_mlValue :locationState.twigDetails.settings.x_volwatcontMax_local && locationState.twigDetails.settings.x_volwatcontMax_local.ml_value,

        //     pwpValues :locationState.twigDetails.settings.x_volwatcontMin && locationState.twigDetails.settings.x_volwatcontMin.value,
        //     pwpValuesLocal :locationState.twigDetails.settings.x_volwatcontMin_local && locationState.twigDetails.settings.x_volwatcontMin_local.value,
        //     pwpValuesEdited :locationState.twigDetails.settings.x_volwatcontMin && locationState.twigDetails.settings.x_volwatcontMin.userEdited,
        //     pwpValuesLocalEdited :locationState.twigDetails.settings.x_volwatcontMin_local && locationState.twigDetails.settings.x_volwatcontMin_local.userEdited,


        //     pwpValues_mlValue :locationState.twigDetails.settings.x_volwatcontMin && locationState.twigDetails.settings.x_volwatcontMin.ml_value,
        //     pwpValuesLocal_mlValue :locationState.twigDetails.settings.x_volwatcontMin_local && locationState.twigDetails.settings.x_volwatcontMin_local.ml_value,
        // };
        // setSiteData({...locationState, twigDetails});
        // }
    }, [siteObjId, props, farmid/*, locationState*/]);

    useEffect(() => {
        siteService
            .getSitesForFarms([farmid])
            .then((farms) => {
                const y = farms[0];
                const sites = y[farmid];
                let filtered = []
                // eslint-disable-next-line array-callback-return
                sites.map((site) => {
                    if(site.twigDetails.settings?.extracted === 'true' || site.deleted){
                      // Extracted Site function goes here
                    }else{
                        filtered.push(site) 
                    }
                })
                setAllSites(filtered);
            });
    }, [farmid]);

    const handleTwigDetailsUpdate = useCallback(update => setSiteData(data => ({
        ...data,
        twigDetails: { ...data.twigDetails, ...update }
    })), []);

    const handleSiteDetailsUpdate = useCallback(update => setSiteData(data => ({ ...data, ...update })), []);

    const siteUpateFunctions = useMemo(() => ({
        handleSiteDetailsUpdate,
        handleTwigDetailsUpdate,
    }), [handleTwigDetailsUpdate, handleSiteDetailsUpdate]);

    const stillUtc = siteData?.twigDetails && moment.utc(siteData?.twigDetails?.lastUpdated).toDate();
    const local = moment(stillUtc).local().fromNow();

    const handleMultipleFarms = (id, selectAll) => {
        console.log("SELECETED FARM: ", id);
        console.log("SELECETED: ", selectAll);
    }

    const calculateRssValue = (rss) => {
        if (rss === null) {
            return;
        }
        if (rss > -60) {
            return 5;
        } else if (rss > -80 && rss <= -60) {
            return 4;
        } else if (rss > -100 && rss <= -80) {
            return 3;
        } else if (rss > -110 && rss <= -100) {
            return 2;
        } else if (rss > -120 && rss <= -110) {
            return 1;
        } else {
            return 0;
        }
    }
    /*
    * battery = (battery reading – 2.0)x100 
    * decimal subtraction fix
    */
    let batterySubtracted = (props.getBatteryState * 100);
    let battery = batterySubtracted.toFixed(2) - 200;

    if (battery < 0) {
        battery = 0;
    } else if (battery > 100) {
        battery = 100;
    }
    const rss = calculateRssValue(props.getRssState);
    return (
        <div>
            <Helmet>
                <title>{siteData.name}</title>
            </Helmet>
            <div className="site-details-container">
                <div className="site-details-header">
                    <div className="row">
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 d-flex align-items-center header-class">
                        <Link to={`/farm/${farmid}/sites`}>
                            <div className='backspace-icon cursor-pointer'>
                                    <img src={backspace} alt="backspace" />
                            </div>
                            </Link>
                            <div className="d-flex">
                                <h6>{siteData.name || <Spinner animation="grow" size="lg" />}</h6>
                                <div className="corner-arrow">
                                    <SelectSite onSubmit={handleMultipleFarms} sites={allSites} userRole={props.userRole} />
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 d-flex header-details ">
                            {(rss !== null && rss !== undefined) ? <div className="pl-25">
                                <h6>LORA Signal Strength</h6>
                                <BarIcon bars={rss} />
                            </div> : null}
                            {battery ? <div className="pl-25">
                                <h6>FOX</h6>
                                <BatteryIcon charge={battery} />
                            </div> : null}
                            <div className="pl-25">
                                <h6>Hardware Type</h6>
                                <p>{siteData.twigDetails && siteData.twigDetails.hardwaretype}</p>
                            </div>
                            <div className="pl-25">
                                <h6>Site ID #</h6>
                                <p>{siteid}</p>
                            </div>
                            <div className="pl-15">
                                <h6>Last Updated</h6>
                                <p>{local}</p>
                            </div>
                            <div className="pl-25">
                                <h6>Crop Type</h6>
                                <p>{siteData.twigDetails && siteData.twigDetails.plantType}</p>
                            </div>
                            <div className="pl-25">
                                <h6>Planting Date</h6>
                                <p>{siteData.twigDetails && siteData.twigDetails.plantingDate}</p>
                            </div>

                        </div>
                    </div>
                    <div className="navbar-area">
                        <Nav defaultActiveKey='/home' as="ul">
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} exact
                                    to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/about`}>About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink}
                                    to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/graph`}>Graph</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/metrics`} >Metrics</Nav.Link>
              </Nav.Item> */}
                            {/* <Nav.Item as="li">
                                <Nav.Link as={NavLink}
                                          to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/alerts`}>Alerts</Nav.Link>
                            </Nav.Item> */}
                            {siteData?.twigDetails?.settings?.hardwareType?.value  === "Pressure Transducer" ? null : 
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink}
                                    to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/template`}>Template</Nav.Link>
                            </Nav.Item>}
                            {/* <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/recommendations`} >Recommendations</Nav.Link>
              </Nav.Item> */}
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink}
                                    to={`/farm/${farmid}/site/${siteid}/${siteObjId}/${twigid}/config+subs`}>Configuration
                                    + Subscription</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>

            </div>
            <hr></hr>

            <div className="graph-details">
                <SiteUpdate.Provider value={siteUpateFunctions}>
                    <SiteContext.Provider value={siteData} >
                        <Switch>
                            <Route path="/farm/:farmid/site/:siteid/:siteObjectId/:twigid/graph" component={GraphDetails} />
                            <Route path="/farm/:farmid/site/:siteid/:siteObjectId/:twigid/config+subs"
                                render={(props) => <ConfigurationBody {...props}
                                    siteDetails={siteData} />}
                            />
                            <Route path="/farm/:farmid/site/:siteid/:siteObjectId/:twigid/template"
                                render={(props) => < TemplateBody {...props} onThresholdChange={handleTwigDetailsUpdate} siteDetails={siteData} />} />
                            <Route path="/farm/:farmid/site/:siteid/:siteObjectId/:twigid/about"
                                render={(props) => <AboutBody {...props}
                                    siteDetails={siteData}
                                    id={siteObjId} />} />
                        </Switch>
                    </SiteContext.Provider>
                </SiteUpdate.Provider>
            </div>
        </div >
    );
}

const mapStateToProps = state => ({
    getBatteryState: state.userDetails.userPreferences.batteryState,
    getRssState: state.userDetails.userPreferences.rssState,
    userRole: state.userDetails.profileDetails?.profile?.role?.name,
});
const mapDispatchToProps = {
    updateSelectedSite: userActions.updateSelectedSite
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphBody);
