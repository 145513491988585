import React, { Component } from "react";
import { connect } from "react-redux";
import configService from "./duck/configService";
import siteService from "../../duck/siteService";
import { Card, Form, Dropdown } from "react-bootstrap";
import Message from "../../../common/messages";
import { axisColors } from '../graph/duck/colorUtility';
import DoneModal from '../../../group/InvitationModal/inviteDone';
import SensorItem from './sensorList';
import {
  getMapping,
} from '../graph/duck/graphUtility';
import { ROLES } from '../../../common/duck/constants';
class Hardware extends Component {
  state = {
    disabled: true,
    hardware: null,
    addLocalSensor: this.props?.hardwaredetails?.twigDetails?.FoxSensor || null,
    hardwareType: {
      name: this.props?.hardwaredetails?.twigDetails?.hardwaretype,
      id: this.props?.hardwaredetails?.twigDetails?.settings?.hardwareType?.hardwareId,
      ...this.props?.hardwaredetails?.twigDetails?.settings?.hardwareType,
    } || null,
    reportingInterval: this.props?.hardwaredetails?.twigDetails?.configInterval || null,
    badgerId: this.props?.hardwaredetails?.twigDetails?.badgerId || null,
    twigId: this.props?.hardwaredetails?.twigId || null,
    errors: { serviceError: null },
    responseMsg: null,
    Local: null,
    hardwareId: null,
    configuration: false,
    sensor: false,
    sensorListChanged: false,
    sensorList: null,
    selectedSensors: [],
    isDialogueShowing: false,
    extracted: this.props?.hardwaredetails?.twigDetails?.settings?.extracted
  };

  componentDidMount() {
    configService.getType('HardwareType').then((res) => {
      this.setState({ hardware: res });
    });
    this.setState({ hardwareSensors: this.props?.hardwaredetails?.twigDetails })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.hardwaredetails !== prevProps.hardwaredetails) {
      let hardwareSensors = getMapping.calculateDepth(this.props?.hardwaredetails?.twigDetails.hardwareSensorLocation);
      let list = this.state.selectedSensors.length !== 0 ? this.state.selectedSensors : this.props?.hardwaredetails?.twigDetails?.settings?.selectedSensors?.value;
      let hColor;
      if (!list) {
        hColor = Object.values(hardwareSensors)?.map((h, index) => {
          let color = axisColors[index];
          return { color: color, checked: true, ...h }
        });
      } else {
        hColor = Object.values(hardwareSensors)?.map((h, index) => {
          let color = axisColors[index];

          return { color: color, checked: list.indexOf(h.key) === -1 ? false : true, ...h }
        });
      }
      const sensorKeys = Object.values(hardwareSensors)?.map(s => {
        return s.key;
      })

      const sensors = [...hColor];
      this.props.hardwaredetails &&
        this.props.hardwaredetails.twigDetails &&
        this.setState({
          addLocalSensor: this.props.hardwaredetails?.twigDetails?.FoxSensor,
          reportingInterval: this.props?.hardwaredetails?.twigDetails?.configInterval,
          badgerId: this.props?.hardwaredetails?.twigDetails?.badgerId,
          twigId: this.props?.hardwaredetails?.twigId,
          hardwareId: prevProps.hardwaredetails?.twigDetails?.Local?.hardwareId,//Saving Local sensor id
          sensorList: sensors,
          selectedSensors: list || sensorKeys,
          extracted:this.props?.hardwaredetails?.twigDetails?.settings?.extracted
        });
      if (!prevState.hardwareType?.id) {
        this.setState({
          hardwareType: {
            name: this.props.hardwaredetails?.twigDetails?.hardwaretype,
            id: this.props?.hardwaredetails?.twigDetails?.settings?.hardwareType?.hardwareId,
            ...this.props?.hardwaredetails?.twigDetails?.settings?.hardwareType,
          }
        })
      }
    }
  }


  onSubmit = (e) => {
    const id = this.props.hardwaredetails.id;
    //uses previous Localsensor id for checking Fox Sensor
    const withFoxSensor = this.state.addLocalSensor === "true" ? this.state.Local : { "addLocalSensor": this.state.addLocalSensor };
    const extracted = {'extracted' : this.state.extracted} 
    let data = this.state.configService && { hardwareType: this.state.hardwareType, twigId: this.state.twigId, badgerId: this.state.badgerId, hardwareConfiguration: true }
    let data1 = this.state.sensor && { reportingInterval: this.state.reportingInterval, ...withFoxSensor , };
    let sensorList = this.state.sensorListChanged && { selectedSensors: this.state.selectedSensors }
    let selectedData = { ...data, ...data1, ...sensorList, ...extracted };
    this.props.setChangeLogs(false);

    let responseMsg = 'Data updated successfully';
    (Object.keys(selectedData)?.length !== 0) && siteService.updateSite(id, { ...selectedData }).then(response => {

      const { parsedTwig } = response.data;
      const { settings } = response.data.parsedTwig;
      const twigId = parsedTwig.twigId;
      const badgerId = parsedTwig.badgerId;
      const hardwaretype = settings.hardwareType?.value;
      const FoxSensor = settings.addLocalSensor?.value;
      const configInterval = settings.reportingInterval?.value;
      const extracted = settings.extracted;
      this.props.siteUpateFunctions.handleTwigDetailsUpdate({ hardwaretype, badgerId, FoxSensor, configInterval });
      this.props.siteUpateFunctions.handleSiteDetailsUpdate({ twigId, extracted })
      this.setState({ responseMsg, sensor: false, configService: false, sensorListChanged: false,extracted })
      this.props.setChangeLogs(true)
      if (sensorList) {
        this.setState({ selectedSensors: response.data.parsedTwig.settings.selectedSensors.value });
      }
    }).catch(error => {
      this.setState({ errors: { serviceError: `${error.message}` } });
    });

  };

  sensorChange = () => {
    this.setState({ sensorListChanged: true });
  }

  handleChange = (e) => {
    let value = e.target.value;


    let selected = e.target.name;
    if (selected === "hardwareType") {

      const selectedHardwareType = (this.state.hardware || []).filter(type => type.id === value)[0];
      this.setState({ [selected]: { name: selectedHardwareType?.name, id: selectedHardwareType?.id }, configService: true })

    }

    else if (selected === "twigId" || selected === "badgerId") {

      this.setState({ [selected]: value, configService: true })

    }
    else if (selected === 'reportingInterval') {
      this.setState({ [selected]: value, sensor: true })

    }

    else {
      if(selected === 'extracted'){
        if (this.state.extracted === "false" || this.state.extracted === false) {
          this.setState({ [selected]: "true"});
        }
        else {
          this.setState({ [selected]: "false"});
        }
      }
      else{
        if (this.state.addLocalSensor === "false" || this.state.addLocalSensor === false) {
          this.setState({ [selected]: "true", Local: { localSensor: { id: this.state.hardwareId, addLocalSensor: "true" } }, sensor: true });
        }
        else {
          this.setState({ [selected]: "false", sensor: true });
        }
      }

    }
  };
  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled, responseMsg: null
    });
    if (!this.state.disabled) {
      this.onSubmit();
    }
  };

  updateSensorList = (sensor, checked) => {
    const { sensorList, selectedSensors } = this.state;
    const copyList = [...sensorList];
    const copySelectedSensors = [...selectedSensors];

    const selectedSensorList = copyList.filter(s => {
      return s.key === sensor.key;
    });
    selectedSensorList[0].checked = checked;
    this.setState({ sensorList: copyList });
    if (checked) {
      copySelectedSensors.push(sensor.key);
      this.setState({ selectedSensors: copySelectedSensors });
    } else {
      let filteredList = copySelectedSensors.filter(s => s !== sensor.key);
      this.setState({ selectedSensors: filteredList });
    }

  }

  closeDialogue = () => this.setState({ isDialogueShowing: false });

  recompute = () => {
    siteService.updateSiteWithAllValues(this.props.hardwaredetails.siteId, this.props.hardwaredetails.twigId, this.props.hardwaredetails.status)
      .then(this.setState({ isDialogueShowing: true })).catch(err => {
        this.setState({ errors: { serviceError: `${err.message}` } });
      });
  }

  render() {
    const hardware = this.props.hardwaredetails;
    const reportingtime =
      this.props.hardwaredetails.twigDetails &&
        this.props.hardwaredetails.twigDetails.configInterval
        ? this.props.hardwaredetails.twigDetails.configInterval
        : "";

    return (
      <div className="harware-container">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5>Hardware</h5>
              {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                this.props.profileDetails.profile.role.name !== 'business_user' &&
                this.props.profileDetails.profile.role.name !== 'farm_user') ?
                <button onClick={this.onEdit}>{this.state.disabled ? 'Edit' : 'Save'}</button> : null}
            </div>
          </Card.Header>
          <Card.Body>
            <form>
              <div className="row">
                <div className="col-lg-12 ">
                  <Form.Group>
                    <div>
                      <Form.Label>Hardware Type</Form.Label>
                    </div>

                    <div className="selectdiv">
                      <select name="hardwareType" className={`${this.state.disabled ? '' : "whiteBC"}`} value={this.state.hardwareType.id ? this.state.hardwareType.id : ''}
                        onChange={this.handleChange} disabled={this.state.disabled}>

                        {this.state.hardware &&
                          this.state.hardware.map((type, index) => (
                            <option key={type.id} value={type.id}>
                              {Object.values(type)[0]}
                            </option>
                          ))}

                      </select>
                      <span className="fas fa-caret-down"> </span>

                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
              <div className={`col-lg-6 d-flex foxSensorCheckBox ${this.state.disabled ? 'disaledClass' : ''}`} >
                  <label className="label mt--18 ">
                    <input
                      type="checkbox"
                      onChange={this.handleChange}
                      checked={
                        (this.state.addLocalSensor &&
                          this.state.addLocalSensor === "true") ||
                          this.state.addLocalSensor === true
                          ? true
                          :

                          false
                      }
                      name="addLocalSensor"
                      disabled={this.state.disabled}
                    />
                    <span className={`checkmark ${this.state?.addLocalSensor === "true" || this.state.addLocalSensor === true
                      ? "checkedTrue"
                      : "checkedFalse"
                      }`}></span>
                  </label>
                  <p>With Fox Sensor?</p>
                </div>
                {/* Extracted */}
                <div className={`col-lg-6 d-flex foxSensorCheckBox ${this.state.disabled ? 'disaledClass' : ''}`} >
                  <label className="label mt--18 ">
                    <input
                      type="checkbox"
                      onChange={this.handleChange}
                      checked={
                        (this.state.extracted &&
                          this.state.extracted === "true") ||
                          this.state.extracted === true
                          ? true
                          :

                          false
                      }
                      name="extracted"
                      disabled={this.state.disabled}
                    />
                    <span className={`checkmark ${this.state?.extracted === "true" || this.state.extracted === true
                      ? "checkedTrue"
                      : "checkedFalse"
                      }`}></span>
                  </label>
                  <p>Extracted</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Fox ID</Form.Label>
                    <Form.Control
                      disabled={this.state.disabled}
                      type="text"
                      name="twigId"
                      className={`${this.state.disabled ? '' : "whiteBC"}`}
                      placeholder={
                        hardware && hardware.twigId ? hardware.twigId : ""
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Fox Config Interval (Seconds)</Form.Label>
                    <Form.Control
                      disabled={this.state.disabled}
                      type="text"
                      className={`${this.state.disabled ? '' : "whiteBC"}`}
                      name="reportingInterval"
                      placeholder={
                        reportingtime ? reportingtime : ""
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Badger ID</Form.Label>
                    <Form.Control
                      disabled={this.state.disabled}
                      type="text"
                      className={`${this.state.disabled ? '' : "whiteBC"}`}
                      name="badgerId"
                      placeholder={
                        hardware.twigDetails && hardware.twigDetails.badgerId
                          ? hardware.twigDetails.badgerId
                          : ""
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                </div>
                {(this.props.profileDetails.profile.role.name === ROLES.GROGURU_ADMIN ||
                  (this.props.profileDetails.profile.role.name === ROLES.DEALER_ADMIN && this.props.hardwaredetails.planDetails?.plan === "Gold") ||
                  (this.props.profileDetails.profile.role.name === ROLES.DEALER_USER && this.props.hardwaredetails.planDetails?.plan === "Gold")) ?
                  <div className="col-lg-6 sensor-list">
                    <Form.Group>
                      <Form.Label>Sensors to include</Form.Label>
                      <Dropdown className={`${this.state.disabled ? '' : "whiteBC"}`}>
                        <Dropdown.Toggle disabled={this.state.disabled} name="selectedSensors" id="dropdown-basic">
                          Select Sensors
                     </Dropdown.Toggle>
                        <Dropdown.Menu>

                          {/*  <Form.Check inline label="1" type='radio' id="01" /> */}
                          {this.state.sensorList?.map(s => (
                            <SensorItem change={this.sensorChange} updateSensor={this.updateSensorList} sensor={s} />
                          ))}


                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>

                  </div> : null}

              </div>
              {this.state.disabled && (this.props.profileDetails.profile.role.name === ROLES.GROGURU_ADMIN ||
                (this.props.profileDetails.profile.role.name === ROLES.DEALER_ADMIN && this.props.hardwaredetails.planDetails?.plan === "Gold") ||
                (this.props.profileDetails.profile.role.name === ROLES.DEALER_USER && this.props.hardwaredetails.planDetails?.plan === "Gold")) ?
                <div onClick={this.recompute} className="run-one-time" >Recompute all values</div>
                : null}
            </form>
          </Card.Body>
          <DoneModal
            buttonLabel="Ok"
            show={this.state.isDialogueShowing}
            onClose={this.closeDialogue}
            data={{ title: 'Update In Progress!', message: 'Recompute all values is in progress, Please check after some time' }} />

          <div className="messagePopup">
            {(this.state.errors?.serviceError !== null) &&
              <Message variant="error" message={this.state.errors.serviceError} />}
            {this.state.responseMsg !== null && this.state.responseMsg?.length !== 0 && <Message variant="success" message={'Data updated successfully'} />}


          </div>
        </Card>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(Hardware);