export const axisColors = [
    '#4363d8',
    '#3cb44b',
    '#e6194B',
    '#f58231',
    '#a9a9a9',
    '#f032e6',
    '#42d4f4',
    '#bfef45',
    '#469990',
    '#911eb4',
    '#dcbeff',
    '#000075',
    '#800000',
    '#aaffc3',
    '#808000',
    '#fabed4',
    '#508ea3',
    '#ffe119',
    '#00ff22'
]

export default {
    red: '#cc3d37',
    yellow: '#ffe119',
    green: '#66b92d',
    light_blue: '#c0c9f0',
    dark_blue: '#175c93',
    pink: '#df8ad2',
    black: '#000000',
    transparent: '#ffffff00',
    brown_fox: ['#9A6324', '#508ea3', '#dcbeff', '#fabed4', '#ed7a72', '#d9bc82', '#99d1a5', '#85aac7'],
    grey: '#404040'
}