import React from 'react';
import CARD_ICONS from './duck/cardIcons';

export default function CardListItem({name, last4, brand, change, openCardModal, ...checkboxAttrs}) {
 
    
    return (
        <div className="payment-methods mb-20">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="pr-20 pt-20">
                        <img src={CARD_ICONS[brand] || CARD_ICONS.default} alt={`${brand} Card`}></img>
                    </div>
                    <div>
                        <React.Fragment>
                            <h6>{name} Card</h6>
                            <p>x{last4}</p>
                            <button onClick={openCardModal}>Change</button>
                        </React.Fragment>
                    </div>
                </div>
                <div>
                    <label className="label mt-30">
                        <input
                            type="radio"
                            name="selected_card"
                            {...checkboxAttrs}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    )
}