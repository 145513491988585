import farmActions from './farmActions';
import {getIssueSummary,getMoistureSummary} from './farmsUtils';

const initialState = {
    token: null,
    data: null,
    multiFarmdata:null,
    issueSummary: {},
    moistureSummary:{},
}

export default function farmsReducer(state = initialState, action) {
    
    switch (action.type) {
        case farmActions.TYPES.LOADING:
            return {
                token: action.token,
                data: null,
                issueSummary: state.issueSummary,
                moistureSummary:state.moistureSummary,
            };
        case farmActions.TYPES.SUCCESS:
            return {
                token: null,
                data: action.data,
                issueSummary: getIssueSummary(action.data),
                moistureSummary:getMoistureSummary(action.data),
            };
        case farmActions.TYPES.FAILURE:
            return {
                token: null,
                data: action.error,
                issueSummary: null,
                moistureSummary:null,
            };
        case farmActions.TYPES.CLEAR_DATA:
            return {};
        case farmActions.TYPES.CREATING:
            return {
                ...state, isCreating: action.payload
            }
        case farmActions.TYPES.CREATE_SUCCESS:
            return {
                ...state,
                isCreating: false,
                createdFarm: action.payload
            }
        case farmActions.TYPES.CREATE_FAILURE :
            return {
                isCreating: false,
                error: action.payload
            }

        case farmActions.TYPES.SELECTED_FARM :
            return {
                ...state,
                data: action.payload,
                issueSummary: getIssueSummary(action.payload),
                moistureSummary:getMoistureSummary(action.payload),
                isCreating: false
            }
            case farmActions.TYPES.MULTI_SELECTED_FARM :
            return {
                    ...state,
                    multiFarmdata: action.payload
                }

          
        default:
            return state;
    }
}




