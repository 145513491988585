import http from '../../../../common/duck/httpService';
import logger from '../../../../common/duck/logService';
const apiEndpoint = "/site"


function getType(type) {
    return http
      .get(`/object/${type}/options`)
      .then((response) => response.data.data);
  }

  function getHardwareConnection(siteid)
{
    return http
      .get(`${apiEndpoint}/${siteid}/connection`)
      .then((response) => response.data.data);
}

function getDisconnectionHistory(siteid){
 
    return http.get(`${apiEndpoint}/${siteid}/disconnections`)
    .then((response)=>response.data.data)
    .catch(error => {
      const {data} = error.response;
      logger.log(error);
      return Promise.reject(data);
  });;
}

function getChangelog(objectid,twigid)
{
        return http.get(`/changelog?siteid=${objectid}&twigid=${twigid}`).then((response)=>response.data.data)
}

function updateSentekData(siteId,sentekData) {

  const sentekApiEndPoint = `/site/update/sentek-details/${siteId}`;
 return http.put(sentekApiEndPoint,sentekData).then(response => response)
 .catch(error => {
  const {data} = error.response;
  logger.log(error);
  return Promise.reject(data);
 })
}

function runOneTimeMigration(siteId){
  return http.get(`/site/migrate/irrigation-rainfall/${siteId}`).then((response)=>response.data.data)
}

function updateSoftware(siteId,softwareData){
  const updateSoftware = `/site/update/irrigation-rainfall/${siteId}`;
 return http.put(updateSoftware,softwareData).then(response => response)
 .catch(error => {
  const {data} = error.response;
  logger.log(error);
  return Promise.reject(data);
 }) 
}

function updateForeCastdays(siteId,forecastDays,foreCastData){
  const updateSoftware = `/site/update/irrigation-rainfall/${siteId}?forecastDays=${forecastDays}`;
 return http.put(updateSoftware,foreCastData).then(response => response)
 .catch(error => {
  const {data} = error.response;
  logger.log(error);
  return Promise.reject(data);
 }) 
}

  export default {
    getType,
    getHardwareConnection,
    getDisconnectionHistory,
    getChangelog,
    updateSentekData,
    updateSoftware,
    runOneTimeMigration,
    updateForeCastdays,
}