import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Card, Modal } from "react-bootstrap";
import InfoDialogue from '../../../group/InvitationModal/inviteDone';
import logopro from "../../../../assets/images/proimage/group.png";
import subscriptionService from "../../../shop/subscriptions/duck/subscriptionService";
import * as moment from 'moment';
import userActions from '../../../user/duck/userActions';

class SubscriptionSetting extends Component {
  state = {
    plan: this.props?.hardwaredetails?.planDetails?.plan || null,
    show: false,
    created:
      this.props?.hardwaredetails?.subscriptionDetails?.subscription?.created ||
      null,
    interval:
      this.props?.hardwaredetails?.subscriptionDetails?.subscription?.plan?.interval || null,
    count:
      this.props?.hardwaredetails?.subscriptionDetails?.subscription?.plan?.interval_count || null,
    cancelled: false,
    serviceError: false,
    error: '',
    showPaymentmsg: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.hardwaredetails !== prevProps.hardwaredetails) {
      this.setState({
        created: this.props?.hardwaredetails?.subscriptionDetails?.subscription
          ?.created,
        plan: this.props?.hardwaredetails?.planDetails
          ?.plan,
        interval: this.props?.hardwaredetails?.subscriptionDetails?.subscription
          ?.plan?.interval,
        count: this.props?.hardwaredetails?.subscriptionDetails?.subscription
          ?.plan?.interval_count,
      });
    }
  }

  CancelSubscription = () => {
    //this.setState({show:true});
    const objectId = this.props.hardwaredetails.id;
    const isGroguruDirectChild = this.props.isDirectGroguruChild;

    this.props.setChangeLogs(false);

    if (isGroguruDirectChild) {
      if (this.props.stripeCustomerDetails && this.state.plan) {
        subscriptionService.getSubscription().then((subs) => {
          const subscriptionId =
            subs.details &&
            subs.details.subscription &&
            subs.details.subscription.id;
          const currentSubscriptions =
            subs.details &&
            subs.details.subscription.items &&
            subs.details.subscription.items.data;
          const subscriptionItem =
            currentSubscriptions &&
            currentSubscriptions.find(
              (sub) => sub?.plan?.id === this?.state?.plan?.id
            );
          const subscriptionItemId = subscriptionItem && subscriptionItem.id;
          subscriptionService
            .updateSubscriptionCancelSubs(subscriptionId, subscriptionItemId, {
              plan: { id: this.state.plan.id, name: this.state.plan.name },
              sites: [objectId],
            })
            .then(res => {
              this.props.siteUpateFunctions.handleSiteDetailsUpdate({ subscriptionDetails: { subscription: {} } })
              this.props.setChangeLogs(true)
              this.setState({ showPaymentmsg: true });
              console.log("cancelled");
              this.setState({ cancelled: true });
            })
            .catch((e) => {
              console.log(e);
              let err = e;
              this.setState({ serviceError: true, error: err, cancelled: false, show: true })
            });
        });


      } else {
        console.log("Payment info was not added by this user for this site");
        this.setState({ show: true });

      }
    } else {
      console.log("not a child");
    }
  };

  closePaymentmsg = () => {
    const { farmid, siteid, siteObjectId, twigid } = this.props.match.params;
    this.setState({ showPaymentmsg: false });
    this.props.history.push(`/farm/${farmid}/site/${siteid}/${siteObjectId}/${twigid}/config+subs`);
  }

  updateSubscription = () => {
    this.props.history.push("/dashboard/shop");
  };
  handleMoment = (date, count, time) => {
    const endDate = moment(date).add(count, time).toDate();
    return endDate;
  };

  handleCloseModal = (e) => {

    this.setState({ show: !this.state.show })
  }

  render() {
    // const time = this.state.created && this.state.created !== null && this.state.created;
    // const date = new Date(time * 1000);
    // const year = date.getFullYear() + 1;
    const data = { title: 'Success', message: 'Subscription Cancelled.' };
    // const count = this.state && this.state.count;
    // const interval = this.state.interval && this.state.interval;
    // let end_date = "";

    // switch (interval) {
    //   case "month":
    //     const month = this.handleMoment(date, count, "M");
    //     end_date = month.toString().slice(4, 15);

    //     break;
    //   case "year":
    //     const year = this.handleMoment(date, count, "y");
    //     end_date = year.toString().slice(4, 15);

    //     break;
    //   case "day":
    //     const day = this.handleMoment(date, count, "d");
    //     end_date = day.toString().slice(4, 15);

    //     break;
    //   case "week":
    //     const week = this.handleMoment(date, count, "w");
    //     end_date = week.toString().slice(4, 15);

    //     break;
    //   default:
    //     break;
    // }

    return (
      <div className="subscription-container">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5>Subscription Settings</h5>
              {/* hidden */}
              <button hidden>Save</button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="text-center mt-20 mb-20">
              <img src={logopro} alt="" />
            </div>
            {/* need to be hidden */}
            <div>
              {/* {this.state.plan && !this.state.cancelled ? ( */}
              {this.state.plan === "Silver" || this.state.plan === "Gold" ? (
                <h6 className="header-text subscription-header">
                  This Site ID is currently subscribed to{" "}
                  <strong>{this.state.plan} </strong> Plan
                  {/* Your next payment will be charged automatically for{" "}
                  <strong> ${this.state.plan && (this.state.plan.amount / 100)}</strong>{" "}
                  on <strong>Jan 1<sup>st</sup>,{year}</strong>. */}
                </h6>
              ) : (
                  <h6 className="header-text">
                    This Site ID is currently not subscribed to any of the plans
                  </h6>
                )}
              <form>
                <div className="row  header-form"></div>
              </form>
              <div className="text-center">
                <button className={this.props.userRole === 'groguru_admin' ? "red-color" : "greyed-out"} onClick={this.props.userRole === 'groguru_admin' ? this.updateSubscription : ''}>
                  {" "}
                  Update Subscription
                </button> 
                {/* <button className="red-color" onClick={this.CancelSubscription}>
                  Cancel Subscription
                </button> */}
              </div>
              <InfoDialogue
                show={this.state.showPaymentmsg}
                onClose={this.closePaymentmsg}
                buttonLabel="OK"
                data={data} />
              <Modal show={this.state.show} onHide={this.handleCloseModal} className="cancelSubscriptionModal" centered>
                <Modal.Header className="cancelSubscriptionHeader row m-0">
                  <div className="col-12 d-flex justify-content-start">
                    <Modal.Title>{this.state.serviceError ? 'Bad Request' : 'Payment info was not added by this user for this site'}</Modal.Title>
                  </div>
                  {/* <div className="col-12 d-flex justify-content-end">
                        <Button variant="none" onClick={this.handleCloseModal} name="No" className="noButton red-color">No</Button>
                        <Button variant="none" onClick={this.handleCloseModal} name="Yes" className="noButton red-color">Yes</Button>
                      </div> */}
                </Modal.Header>
              </Modal>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stripeCustomerDetails:
    state.userDetails.profileDetails.profile.stripeCustomerDetails,
  isDirectGroguruChild: state.userDetails.userPrivilages.isDirectGroguruChild,
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
});
const mapDispatchToProps = {
  updateStripeCustomer: userActions.updateStripeCustomer
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionSetting));
