import React from "react";
import userService from "../user/duck/userService";
import { default as GForm } from "../common/form";
import Joi from "joi-browser";
import { Card, Form } from "react-bootstrap";
import Message from "../common/messages";

class ContactForm extends GForm {
  state = {
    data: {
      firstName: "",
      email: "",
      lastName: "",
      message: "",
      phone: ""
    },
    errors: {},
    responseMsg: null,
    dataSent: false
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name"),
    lastName: Joi.string()
      .required()
      .label("Last Name"),
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .label("Email"),
    message: Joi.string()
      .required()
      .label("Message"),
    phone: Joi.string()
      .required()
      .label("Phone")
  };

  doSubmit = async () => {
    const { data } = this.state;
    this.setState({dataSent: true, errors: {}, responseMsg: null}, () => {
      userService
        .contact({ ...data })
        .then(response => {
          this.setState({ data: {
            firstName: "",
            email: "",
            lastName: "",
            message: "",
            phone: ""
          }})
          this.setState({ responseMsg: response.message });
        })
        .catch(err => {
          this.setState({ errors: { serviceError: err.message } });
        });
    })
  };

  render() {
    const { serviceError } = this.state.errors;
    return (
      <div className="contact-info">
        <Card>
          <Card.Header>
            <div className="card-header-text ">
              <p>Contact GroGuru</p>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="card-body-text">
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderFormGroup(
                      "firstName",
                      "First Name",
                      "text"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderFormGroup("lastName", "Last Name", "text")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderFormGroup(
                      "email",
                      "Email",
                      "email",
                      {placeholder: "email@email.com"}
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderFormGroup("phone", "Phone", "phone")}
                  </div>
                </div>
                {this.renderFormGroup(
                  "message",
                  "Message",
                  null,
                  {
                    placeholder: "Type your message here...",
                    disabled: null,
                    as: "textarea",
                    className: "message-view"
                  }
                )}
              </Form>
              <div className="">
                {this.renderButton("Send Message", "btn-submit btn")}

                {this.state.dataSent && serviceError &&
                <Message variant="error" message={serviceError} />}

                {this.state.dataSent && this.state.responseMsg &&
                <Message variant="success" message={this.state.responseMsg} />}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default ContactForm;
