import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import CreateGroup from './createGroup';
import InviteUser from './inviteUser';
import InviteDone from './inviteDone';
import InviteMember from '../inviteMember';

class InvitationModal extends Component {

  state = { modal: '', doneData: {} }

  openFormModal = () => this.setState({ modal: 'form' });
  openSearchModal = () => this.setState({ active: true, modal: 'search' });
  openDoneModal = (doneData) => {
    this.setState({ modal: 'done', doneData })
  };
  closeAll = () => this.setState({ modal: '' })

  render() {

    return (
      <div className="user-invitation">
        {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
          this.props.profileDetails.profile.role.name !== 'business_user' &&
          this.props.profileDetails.profile.role.name !== 'farm_user' && this.props.label !== 'Member') ?
          (<Button
            onClick={this.openSearchModal}
            variant="none"
            className="text-right">
            Create {this.props.label} +
          </Button>) : (this.props.profileDetails.profile.role.name === 'dealer_admin') ? <InviteMember
                        teamid={this.props.profileDetails.profile.organisation.objectId}
                        show={true}
                        hideDefaultButton = {true}
                    name={this.props.profileDetails.profile.role.name}
                    type={this.props.profileDetails.profile.organisation.type}/>:null}

        {this.state.modal === 'search' && <InviteUser
          show={this.state.modal === 'search'}
          label={this.props.label}
          onClose={this.closeAll}
          onClickCreate={this.openFormModal}
          openDoneModal={this.openDoneModal} />}
        {this.state.modal === 'form' && <CreateGroup
          show={this.state.modal === 'form'}
          label={this.props.label}
          accountsData={this.props.accountsData}
          role={this.props?.profileDetails?.profile?.role?.name}
          onClose={this.closeAll}
          onClickBack={this.openSearchModal}
          openDoneModal={this.openDoneModal} />}
        {this.state.modal === 'done' && <InviteDone
          show={this.state.modal === 'done'}
          label={this.props.label}
          data={this.state.doneData}
          onClose={this.closeAll} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(InvitationModal);