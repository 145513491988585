import React from 'react';
import NumberCell from './numberCell';

export default function TableRow({
    index,
    data,
    onChange = () => {},
    onDelete,
    onInsetAfter
}) {

    const setting = {
        precision: 2,
        step: 0.01,
        min: 0
    }

    function updateThisRow(event) {
        const {name, value} = event.target;
        if (name === 'start_day') {
            onChange(index, { [name]: parseInt(value) });
        } else if (name) {
            onChange(index, { [name]: parseFloat(value)});
        }
    }

    function deleteThisRow() {
        onDelete(index);
    }

    function insertRowAfterThisRow() {
        const newRow = {
            start_day: NaN,
            red: data.red,
            yellow: data.yellow,
            green: data.green,
            light_blue: data.light_blue
        };
        onInsetAfter(index, newRow);
    }

    const buttonStyle= {
        fontSize: '0.9rem'
    }

    return (
        <tr onChange={updateThisRow} style ={{ display : data.addedNew ? 'none' : 'table-row'}}>
            <NumberCell name="start_day" value={data.start_day} required />
            <NumberCell name="red" value={data.red} max={data.yellow - setting.step} {...setting} required />
            <NumberCell name="yellow" value={data.yellow} max={data.green - setting.step} {...setting} required />
            <NumberCell name="green" value={data.green} max={data.light_blue - setting.step} {...setting} required />
            <NumberCell name="light_blue" value={data.light_blue} readOnly />
            <td style={{display: 'flex'}}>
                <button style={buttonStyle} onClick={insertRowAfterThisRow}>Insert Row Below</button>
                <button style={buttonStyle} onClick={deleteThisRow}>Delete</button>
            </td>
        </tr>
    );
}