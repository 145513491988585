export function capitalise(input) {
    return `${input[0].toUpperCase()}${input.substr(1)}`;
}

export function lastItem(array = []) {
    return array[array.length - 1];
}

export function orderByString(arrayOfObjects, key = '0') {
    return arrayOfObjects?.slice()?.sort((a, b) => {
        if (a[key] > b[key]) return 1;
        else if (a[key] < b[key]) return -1;
        return 0
    });
}