import React, { Component } from "react";
import { Card} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import configService from "./duck/configService";
import startArrow from "../../../../assets/images/startArrow/rayStartArrow@2x.png";
import * as moment from 'moment';

class DisconnectionStatus extends Component {
  state={
    accountDetails:[],
    alerts:[],
    totalError: "",
  }

  componentDidMount (){
    const objectId = this.props.match.params.siteObjectId
    configService.getDisconnectionHistory(objectId).then((response) => this.setState({accountDetails:response.disconnectionActions, alerts:response.disconnectionAlerts, totalError:response.totalCount}))
  }

  updateMonth = (month) => {
    switch(month){
      case 0: return "January";
      case 1: return "February";
      case 2: return "March";
      case 3: return "April";
      case 4: return "May";
      case 5: return "June";
      case 6: return "July";
      case 7: return "August";
      case 8: return "September";
      case 9: return "October";
      case 10: return "November";
      case 11: return "December";
      default: return "";
    }
  }

  updateHour = (hour) =>{
    if(hour <= 0){
      return {hour:"12",zone:"am"};
    }else if(hour > 12){
      const newhour = hour - 12;
      return {hour:newhour,zone:"pm"};
    }else{
      return {hour,zone:"am"};
    }
  }
  render() {
    return (
      <div className="disconnection-container">
        <Card>
          <Card.Header>
            <div className="">
              <h5>Disconnection History</h5>
              
            </div>
          </Card.Header>
          <Card.Body>

      {this.state.totalError >= 0 && this.state.alerts.length>0 && 
        this.state.alerts.map((alert,index) =>{
          
     
        
          let stillUtc = this.state.alerts[index]?.date.iso!==null?moment.utc(this.state.alerts[index]?.date.iso).toDate():null;
          let local = stillUtc!==null?moment(stillUtc).local().format("MMMM DD,YYYY-hh:mmA"):'';



          return <div className="mt-30 mb-20">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                    <h4>Base Station</h4>
                    <img src={startArrow} className="arrow-img" alt=""></img>
                    <h4>{alert?.deviceType}</h4>
                    </div>
                    {/* <p>{month} {day}, {year} - {hour}:{minute<10?'0'+minute:minute}{zone}</p> */}
                    <p>{local}</p>
                  </div>
                  <h6>The base station failed to connect with the {alert?.deviceType}. The email {this.state.accountDetails?.email?.value} has been notified.</h6>
                  </div> 
          })}
          </Card.Body>
        </Card>

     
      </div>

    );
  }
}
export default withRouter(DisconnectionStatus);
