import React, {Component} from "react";

export const FarmCardPlaceHolder = props => (
    <div className="wrapper-cell ">
        <div className="row">
            <div className="col-md-6 d-flex">
                <div className="dot"></div>
                <div className="pic">
                    <div className="image"></div>
                </div>
                <div className="text mt-10">
                    <div className="heading"></div>
                    <div className="text-line"></div>
                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center ">
                <div className="dots d-flex">
                    <div className="pl-25">
                        <div className="r-dot"></div>
                    </div>
                    <div className="pl-25">
                        <div className="r-dot"></div>
                    </div>
                    <div className="pl-25">
                        <div className="r-dot"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

class FarmLoadingCard extends Component {
    render() {
        return (
            <div className="loading-body">
                <FarmCardPlaceHolder/>
                <FarmCardPlaceHolder/>
                <FarmCardPlaceHolder/>
                <FarmCardPlaceHolder/>
                <FarmCardPlaceHolder/>
            </div>
        );
    }
}

export default FarmLoadingCard;
