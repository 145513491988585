import React, { useEffect, useState } from 'react';
import './farmhealth.scss';
import { Button, Modal } from "react-bootstrap";
import { history } from "../common/duck/history";
import FarmsSelectionList from './FarmsSelectionList';
import farmService from "../farm/duck/farmService";
import Message from "../common/messages";


function FarmHealth(props) {
    const [showfarmList, setShowfarmList] = useState(false);
    const [users, setUsers] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [mode, setMode] = useState('add');
    const [responseMsg, setResponseMsg] = useState(null);

    useEffect(() => {
        farmService.getUsers()
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => console.log(error));
    }, []);

    const fetchUsers = () => {
        farmService.getUsers()
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => console.log(error));
        if (mode === 'edit') {
            setResponseMsg('User Updated Successfully.');
            setTimeout(() => {
                setResponseMsg(null);
            }, 1000);
        }
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedUser(null);
        setResponseMsg(null);
    }

    const selectDelete = (user) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
        setResponseMsg(null);
    }

    const selectEdit = (user) => {
        setMode('edit');
        setSelectedUser(user);
        setShowfarmList(true);
        setResponseMsg(null);
    }

    const viewFarms = (user) => {
        setMode('view');
        setSelectedUser(user);
        setShowfarmList(true);
        setResponseMsg(null);
    }
    const populateUsers = () => {
        if (users !== null) {
            return users?.map(u => {
                return (
                    <div key={u.objectId} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                        <div className="user-wrapper">
                            <div style={{ width: '100%', height: '29px' }}>
                                <button onClick={() => selectDelete(u)} className="u-btn delete-btn">Delete</button>
                                <button onClick={() => selectEdit(u)} className="u-btn edit-btn">Edit</button>
                                <button onClick={() => viewFarms(u)} className="u-btn view-btn">View</button>
                            </div>
                            <div className="email-input">Email: <span>{u.email}</span></div>
                        </div>
                    </div>
                )
            })
        }

    }

    const showfarms = () => {
        setMode('add');
        setSelectedUser(null);
        setShowfarmList(true);
        setResponseMsg(null);
    }
    const hidefarms = () => {
        setShowfarmList(false);
        //setResponseMsg(null);
    }

    const updateUsersList = (user) => {
        const newUsers = [...users, user];
        setResponseMsg('User Added Successfully.');
        setUsers(newUsers);

        setTimeout(() => {
            setResponseMsg(null);
        }, 2000);
    }

    const deleteUser = () => {
        farmService.deleteUser(selectedUser.objectId)
            .then((response) => {
                const updatedUsers = users.filter(u => u.objectId !== selectedUser.objectId);
                setUsers(updatedUsers);
            })
            .catch((error) => console.log(error));
        setSelectedUser(null);
        setShowDeleteModal(false);
        setResponseMsg('User Deleted Successfully.');
        setTimeout(() => {
            setResponseMsg(null);
        }, 1000);
    }

    const setUserState = () => {
        setSelectedUser(null);
    }

    return <div className="field-health-wrapper">
        <div className="back-btn" onClick={() => history.goBack()}>
            Back
        </div>
        <div className="field-header">Farm Health Report</div>
        <div style={{ float: 'right' }}>
            <button className="add-btn" onClick={showfarms}>+ Add New</button>
        </div>
        <div className="messagePopup">
            {responseMsg !== null && responseMsg?.length !== 0 && <Message variant={"success"} message={responseMsg} />}
        </div>
        <FarmsSelectionList
            setUser={setUserState}
            getUsers={fetchUsers}
            currentUser={selectedUser}
            mode={mode}
            updateUsers={updateUsersList}
            show={showfarmList}
            setShow={hidefarms} />
        <div className="row user-container">
            {users !== null ? populateUsers() : null}
        </div>
        <Modal className="fhrdelete" show={showDeleteModal} onHide={closeDeleteModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>User will no longer receive Field health Report. Are you sure?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeleteModal}>
                    Close
          </Button>
                <Button variant="primary" onClick={deleteUser}>
                    Delete
          </Button>
            </Modal.Footer>
        </Modal>

    </div>
}

export default FarmHealth;