import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import InviteMembers from "../../group/inviteMember";
import groupService from "../../group/duck/groupService";
import defaultimg from "../../../assets/images/farms/farm-create/group-2.png";

function UserAccessTabContent(props) {
  const farmid = props.match.params.farmid;
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const openInviteModal = useCallback(() => setShow(true), []);

  const closeInviteModal = useCallback(() => setShow(false), []);
  useEffect(() => {
    if (props.multi && props.multi.length === 1) {
      groupService.getGroupMembers(props.multi[0]).then(response => {
        setUsers(response);
      });
    } else {
      groupService.getGroupMembers(farmid).then(response => {
        setUsers(response);
      });
    }

  }, [farmid, props.multi]);




  return (
    <div className="userAccess-container">
      <div className="userAccess-header d-flex justify-content-between">
        <div>
          <h4>User Access</h4>
          <p>View, add, or edit the user access of this farm below</p>
        </div>
        <div className="pt-15">
          {(props.profileDetails.profile.role.name !== 'dealer_user' &&
            props.profileDetails.profile.role.name !== 'business_user' &&
            props.profileDetails.profile.role.name !== 'farm_user') ?
            (<button onClick={openInviteModal} className="btn-invite">
              Invite +
            </button>) : null}

          <InviteMembers
            teamid={props.farmdetails.objectId}
            name={props.farmdetails.name}
            multi={props.multi}
            type="farm"
            modalShown={show}
            hideDefaultButton
            onClose={closeInviteModal}
          />
        </div>
      </div>
      <hr></hr>


      {users.map((user) => (
        <div>
          <div className="user-section">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="profile-img">
                  <img src={defaultimg} alt="user profile"></img>
                </div>
                <div className="user-text">
                  <h6>{user.name}</h6>
                  <p>{user.role.name}</p>
                </div>
              </div>
              <div >

              </div>

            </div>
          </div>
          <hr></hr>
        </div>
      ))}
    </div>
  );
}
const mapStateToProps = state => ({
  profileDetails: state.userDetails.profileDetails,
});

export default connect(mapStateToProps)(UserAccessTabContent);