import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FarmDetails from '../sites/farmDetails';
import GraphBody from '../sites/siteDetails/graph/graphBody';


class Farm extends Component {
  render() {
    return (
      <div>
          <Switch>
            
             <Route path="/farm/:farmid/sites" component={ FarmDetails} />
             <Route path="/farm/:farmid/site/:siteid/:siteObjId/:twigid" component={GraphBody} />
           
          </Switch>
        </div>
      );
    }
  }

export default Farm;
