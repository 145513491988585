import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import userReducers from "./user/duck/userReducers";
import alertReducer from "./common/duck/commonReducers";
import farmsReducer from './farm/duck/farmReducers'

const rootReducer = combineReducers({
    userDetails: userReducers,
    alert: alertReducer,
    farms: farmsReducer
});

let combinedEnhancers;

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    combinedEnhancers = compose(
        applyMiddleware(thunkMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
} else {
    combinedEnhancers = applyMiddleware(thunkMiddleware);
}

const store = createStore(rootReducer, {}, combinedEnhancers);
export default store;
