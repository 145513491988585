import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from "../../assets/images/dashboard/search/group-10.png";


export default function Search(props) {
    const requireBorder = props.border ? 'search-border' : '';
    const Wrapper = props.as || "form";
    const inputWidth = props.inputWidth;
    return (
        <div className="col-lg-8 landing-search search-section ">
            <Wrapper className={`search-bar ${requireBorder} d-flex`} onSubmit={(event) => event.preventDefault()}>
                <div className="search-area">
                    <img src={searchIcon} className="search-icon" alt="logo" />
                </div>

                <input
                    type="text"
                    style={inputWidth ? { width: inputWidth } : {}}
                    onChange={props.onSearch}
                    placeholder={props.placeholder}
                    className="pl-30"
                />
            </Wrapper>
        </div>
    );
}

Search.propTypes = {
    onSearch: PropTypes.func.isRequired
}