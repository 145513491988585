import React, { useState, useEffect } from 'react';
import { Button, Form, Modal/*, Spinner*/ } from "react-bootstrap";
// import Select from "react-select";
import { connect } from 'react-redux';
import SpinnerButton from '../../../common/spinnerButton';
// import groupService from '../../../group/duck/groupService';
import templateService from '../template/duck/templateService';
import validateTemplateData, { orderByStartDay } from './duck/templateValidation';

function RadioButton({ label, name = 'template', ...props }) {
    return (
        <div className="template-select d-flex justify-content-between">
            <label className="label ">
                <input type="radio" name={name} {...props} />
                <span className="checkmark checkButtonCircular"></span>
            </label>
            <h6>{label}</h6>
        </div>
    );
}

// function getSearchOptions(userName, designation) {
//     return <div className="userSearchResults">
//         <div className="userSearchResult d-flex ">
//             <i className="far fa-user-circle"></i>
//             <div>
//                 <h6>{userName}</h6>
//                 <p>{designation}</p>
//             </div>
//         </div>
//     </div>
// }

const SHARING_SETTING = {
    ONLY_ME: 'ONLY_ME',
    TEAM_AND_ABOVE: 'TEAM_AND_ABOVE',
    MY_TEAM: 'MY_TEAM',
    CUSTOM: 'CUSTOM',
    PUBLIC: 'PUBLIC'
}


function SaveTemplateModal({
    show,
    handleClose,
    userid,
    userRole,
    cropType,
    formData,
    children,
    patchOnTemplate = null,
    onTemplateEdited,
    onTemplateCreated,
}) {

    const [isFormSending, setIsFormSending] = useState(false);
    const patchedTemplateId = patchOnTemplate && patchOnTemplate.id;
    const patchedTemplateName = patchOnTemplate && patchOnTemplate.name;
    const patchedTemplateSharingOption = patchOnTemplate && patchOnTemplate.sharingOption;
    const [templateName, setTemplateName] = useState(patchedTemplateName)
    const [sharingOption, setSharingOption] = useState(patchedTemplateSharingOption || SHARING_SETTING.TEAM_AND_ABOVE);
    // const [allMemberOptionsList, setAllMemberOptionsList] = useState(null);
    const [selectedMembers, setSelectedMembers] = useState({ selectedOption: [] });
    // const [fetchedTemplate, setFetchedTemplate] = useState(null);
    const [error, setError] = useState(null);


    const isCustomSharingSelected = sharingOption === SHARING_SETTING.CUSTOM;

    function changeSharingOption(event) {
        const value = event.target.value;
        setSharingOption(value);
    }

    // function searchMembers({data}, string) {
    //     if (data.name.toLowerCase().includes(string)) {
    //         return true;
    //     }
    //     return false;
    // }

    function resetModal() {
        setSelectedMembers({ selectedOption: [] });
        setSharingOption(SHARING_SETTING.TEAM_AND_ABOVE);
        // setAllMemberOptionsList(null);
    }

    function saveNewTemplate(event) {
        event.preventDefault();

        const templateNameTrimmed = templateName.trim();
        const templateDataError = validateTemplateData(formData);
        if (templateDataError) {
            setError(templateDataError);
            return;
        } else if (isCustomSharingSelected && selectedMembers.selectedOption.length === 0) {
            setError('Please select members allowed to access template');
            return;
        } else if (!templateNameTrimmed) {
            setError('Please give a name for this template');
            return;
        }

        let templateData = {
            name: templateNameTrimmed,
            cropType,
            templateData: orderByStartDay(formData)
        }

        if (sharingOption !== SHARING_SETTING.TEAM_AND_ABOVE) {
            templateData.sharingSettings = { sharingOption };
        }

        setError(null);
        setIsFormSending(true);
        templateService.createTemplate(templateData)
            .then(template => {
                handleClose();
                resetModal();
                onTemplateCreated(template);
            })
            .catch(error => setError(error.response.data.message))
            .then(() => setIsFormSending(false));
    }

    function editTemplateDetails(event) {
        event.preventDefault();

        let templateData = {};
        let templateNameTrimmed = templateName.trim();

        if (templateName && templateNameTrimmed !== patchedTemplateName) {
            templateData.name = templateNameTrimmed;
        }

        if (patchedTemplateSharingOption !== sharingOption) {
            templateData.sharingSettings = { sharingOption };
        }

        setError(null);
        setIsFormSending(true);
        templateService.editTemplate(patchedTemplateId, templateData)
            .then(template => {
                handleClose();
                resetModal();
                onTemplateEdited(template);
            })
            .catch(error => setError(error.response.data.message))
            .then(() => setIsFormSending(false));
    }

    useEffect(() => {
        if (patchedTemplateName) {
            setTemplateName(patchedTemplateName);
        }
        setSharingOption(patchedTemplateSharingOption || SHARING_SETTING.TEAM_AND_ABOVE);
    }, [patchedTemplateName, patchedTemplateSharingOption]);

    // useEffect(() => {
    //     if (isCustomSharingSelected && allMemberOptionsList === null) {
    //         groupService.getAllUsersInTreePath()
    //             .then(members => setAllMemberOptionsList(members.filter(member => member.objectId !== userid).map(member => ({
    //                 value: member.objectId,
    //                 name: member.name || member.username,
    //                 label: getSearchOptions(member.name || member.username, member.organisation.type)
    //             }))));
    //     }
    // }, [isCustomSharingSelected, userid, allMemberOptionsList]);

    // useEffect(() => {
    //     if (patchedTemplateId) {
    //         // fetch template and set allowed users and other settings
    //         templateService.getRawTemplate(patchedTemplateId)
    //             .then(templateRawData => {
    //                 // const allUserIdsWithTemplateAccess = templateRawData.sharingSettings.users;

    //                 // if (allUserIdsWithTemplateAccess.length === 1) {
    //                 //     setSharingOption(SHARING_SETTING.ONLY_ME);
    //                 // } else {
    //                 //     setSharingOption(SHARING_SETTING.CUSTOM);
    //                 // }

    //                 setSharingOption(templateRawData.sharingSettings.sharingOption);
    //                 setFetchedTemplate(templateRawData);
    //             });
    //     }
    // }, [patchedTemplateId]);

    // useEffect(() => {
    //     if (fetchedTemplate !== null && allMemberOptionsList !== null && isCustomSharingSelected) {
    //         const allUserIdsWithTemplateAccess = fetchedTemplate.sharingSettings.users;
    //         setSelectedMembers({
    //             selectedOption: allMemberOptionsList.filter(option => (
    //                 option.value !== userid &&
    //                 allUserIdsWithTemplateAccess.includes(option.value)
    //             ))
    //         });
    //     }
    // }, [isCustomSharingSelected, userid, fetchedTemplate, allMemberOptionsList]);

    let updateTemplate = { onClick: saveNewTemplate, label: 'Add Template' };
    if (patchOnTemplate !== null) {
        updateTemplate.onClick = editTemplateDetails;
        updateTemplate.label = 'Update Template';
    }

    return (
        <React.Fragment>
            {children}
            {/*  */}
            <Modal
                centered
                show={show}
                size="lg"
                onHide={handleClose}
                className="newTemplateModal"
            >
                <Modal.Header closeButton={false}>
                    <p>Select the Template</p>
                    <span className="text-right cp" onClick={handleClose}>close</span>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mb-10">
                        <div className="row m-0">
                            <h6>Template information</h6>
                            <div className="col-12 pl-0 pr-0">
                                <Form.Group className="mt-0">
                                    <Form.Label>Template Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="active-border"
                                        required
                                        value={templateName}
                                        onChange={event => setTemplateName(event.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col-12 pl-0 pr-0">
                                <Form.Group className="mt-0">
                                    <Form.Label>Crop type</Form.Label>
                                    {/* <Form.Control as="select">
                                        <option>Corn</option>
                                    </Form.Control> */}
                                    <Form.Control value={cropType} disabled />
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group>
                            <div className="templateSearchSettings d-flex flex-column">
                                <h6>Sharing Settings</h6>
                                <div className="d-flex" style={{ justifyContent: "space-around"}}>
                                    <RadioButton
                                        label="Share with your Team and Above"
                                        value={SHARING_SETTING.TEAM_AND_ABOVE}
                                        checked={sharingOption === SHARING_SETTING.TEAM_AND_ABOVE}
                                        onChange={changeSharingOption} />
                                    {/* <RadioButton
                                        label="Only Yourself"
                                        value={SHARING_SETTING.ONLY_ME}
                                        checked={sharingOption === SHARING_SETTING.ONLY_ME}
                                        onChange={changeSharingOption} /> */}
                                    {userRole === 'groguru_admin' ?
                                        <RadioButton
                                            label="Share with everyone (public)"
                                            value={SHARING_SETTING.PUBLIC}
                                            checked={sharingOption === SHARING_SETTING.PUBLIC}
                                            onChange={changeSharingOption} />
                                        : null }
                                        {/* <RadioButton
                                            label="Share with your team"
                                            value={SHARING_SETTING.MY_TEAM}
                                            checked={sharingOption === SHARING_SETTING.MY_TEAM}
                                            onChange={changeSharingOption} /> */}
                                    {/* <RadioButton
                                        label="Select Who to share With"
                                        value={SHARING_SETTING.CUSTOM}
                                        checked={sharingOption === SHARING_SETTING.CUSTOM}
                                        onChange={changeSharingOption} /> */}
                                </div>
                            </div>
                        </Form.Group>
                        {/* {sharingOption === SHARING_SETTING.CUSTOM && (
                            <>
                                <Form.Group className="m-0">
                                    <div className="template-search">
                                    {allMemberOptionsList === null?
                                        <Spinner animation="grow" /> :
                                        <Select
                                            isMulti
                                            filterOption={searchMembers}
                                            value={selectedMembers.selectedOption}
                                            onChange={selectedOption => setSelectedMembers({selectedOption})}
                                            options={allMemberOptionsList}
                                            menuIsOpen={true}
                                            placeholder="Search Members....." />}
                                    </div>
                                </Form.Group>
                            </>)} */}
                        <Form.Group>
                            {error && <small style={{ color: 'red' }}>{error}</small>}
                            <SpinnerButton
                                spinning={isFormSending}
                                className="btn-submit btn btn-none"
                                type="submit"
                                onClick={updateTemplate.onClick}>{updateTemplate.label}</SpinnerButton>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            {/*  */}
        </React.Fragment>
    );
}

const mapStateToModalProps = state => ({
    userRole: state.userDetails.profileDetails.profile.role.name
})

export const SaveTemplateModalPresentation = connect(mapStateToModalProps)(SaveTemplateModal);

function ModalState(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <SaveTemplateModalPresentation
        key="TEMP:NEW"
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        {...props}>
        <Button
            disabled={(props.profileDetails.profile.role.name === 'dealer_user' ||
                props.profileDetails.profile.role.name === 'business_user' ||
                props.profileDetails.profile.role.name === 'farm_user')}
            variant="none"
            onClick={handleShow}
            className="savenewtemplate-btn"
        >Save New Template +</Button>
    </SaveTemplateModalPresentation>
}


const mapStateToProps = state => ({
    userid: state.userDetails.profileDetails.profile.objectId,
    profileDetails: state.userDetails.profileDetails
});

export default connect(mapStateToProps)(ModalState)