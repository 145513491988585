import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav } from "react-bootstrap";
import { FILTER_TYPES} from './duck/farmsUtils';
import techviewgreen from '../../assets/images/issues-icons/techview-green.svg';
import techviewred from '../../assets/images/issues-icons/techview-red.svg';
import techviewyellow from '../../assets/images/issues-icons/techview-yellow.svg';
import techviewmaroon from '../../assets/images/issues-icons/techview-maroon.svg';

function checkIfActive(query, isIndexRoute) {
    return function (match, location) {
        return (location.search.indexOf(query) >= 0) || (isIndexRoute && location.search === '');
    }
}

function NavFarmFilter(props) {
 
    const { NEEDS_ATTENTION, HAS_WARNINGS, NO_ISSUES, EXTRACTED } = FILTER_TYPES;
 
    return (
        <Nav className="mr-auto ">
            <Nav.Link as={NavLink} isActive={checkIfActive(EXTRACTED)} to={`?${EXTRACTED}`} className="navlink pl-30 vl">
                {!props.agronomic ?<div className="d-flex" ><img src={techviewmaroon} alt='' width="30px" ></img>
                    <p className="pt-10 pl-10" >{props.issueSummary && props.issueSummary.EXTRACTED}</p></div>:
                <div className={"orange-oval "}>{props.moistureSummary && props.moistureSummary.EXTRACTED}</div>}
                <div className="pt-5 "> Extracted</div>
            </Nav.Link>
            <Nav.Link as={NavLink} isActive={checkIfActive(NEEDS_ATTENTION)} to={`?${NEEDS_ATTENTION}`} className="navlink pl-30 vl">
                {!props.agronomic ?<div className="d-flex" ><img src={techviewred} alt='' width="30px" ></img>
                    <p className="pt-10 pl-10" >{props.issueSummary && props.issueSummary.LOW}</p></div>:
                <div className={"orange-oval "}>{props.moistureSummary && props.moistureSummary.LOW}</div>}
                <div className="pt-5 "> Needs Attention</div>
            </Nav.Link>
            <Nav.Link as={NavLink} isActive={checkIfActive(HAS_WARNINGS)} to={`?${HAS_WARNINGS}`} className="navlink pl-30">
            {!props.agronomic ?<div  className="d-flex">
            <img src={techviewyellow} alt='' width="30px" ></img>
                    <p className="pt-10 pl-10" >{props.issueSummary && props.issueSummary.MED}</p>
                </div>:
            <div className={"yellow-oval "}>{props.moistureSummary && props.moistureSummary.MED}</div>}
                <div className="pt-5">Has Warnings</div>
            </Nav.Link>
            <Nav.Link as={NavLink} isActive={checkIfActive(NO_ISSUES)} to={`?${NO_ISSUES}`} className="navlink pl-30 pr-50">
            {!props.agronomic ?<div className="d-flex" >
            <img src={techviewgreen} alt='' width="30px" ></img>
                    <p className="pt-10 pl-10" >{props.issueSummary && props.issueSummary.FAIR}</p>
               </div>:
            <div className={"green-oval"}>{props.moistureSummary && props.moistureSummary.FAIR}</div>}
                <div className="pt-5">No Issues</div>
            </Nav.Link>
        </Nav>
    );
}



const mapStateToProps = state => ({
    issueSummary: state.farms.issueSummary,
    moistureSummary:state.farms.moistureSummary
});

export default connect(mapStateToProps)(NavFarmFilter);