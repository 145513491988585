import React from 'react';
import Joi from 'joi-browser';
import GForm from "../../common/form";
import logo from "../../../assets/images/common/groguru-long-logo@3x.png";
import {history} from "../../common/duck/history";
import invitationService from "../duck/invitationService";
import Message from "../../common/messages";
import logService from "../../common/duck/logService";
import localStorageService from "../../common/duck/localStorageService";
import userActions from "../duck/userActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Form} from "react-bootstrap";

class GroupSignUp extends GForm {

    state = {
        data: {
            email: this.props.invitationDetails.email, password: "", username: "", confirmPassword: ""
        },
        errors: {},
        serviceError: null
    }
    schema = {
        email: Joi.string().required().label('Email'),
        password: Joi.string().required().label('Password'),
        username: Joi.string().required().label('Username'),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required()
            .options({ language: { any: { allowOnly: 'must match Password' } } }).label('Confirm Password')
    };

    doSubmit = () => {
        this.setState({serviceError: null});
        const {invitationDetails} = this.props;
        const signUpData = {...this.state.data};
        signUpData.autologin = true;
        signUpData.org = invitationDetails.organisation.objectId;
        invitationService.signUp(signUpData, invitationDetails, ).then(() => {
            this.props.getProfile(localStorageService.getUserId());
            this.props.getPreferences();
        }).catch(error => {
            logService.log(error);
            this.setState({serviceError: error.message});
        });
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.profileDetails.profile) {
            history.push(`/signUp/org/businessInfo`);
        }
        return (prevState);
    }

    render() {
        const {invitationDetails} = this.props;
        return (
            <div className="sign-up ">
                <div className="container signup-container">
                    <div className="text-center  pt-25">
                        <img src={logo} className="groguru_long_logo" alt="logo"/>
                    </div>
                    <div className="card border-0 shadow mt-60 card-style mx-auto ">
                        <div className="card-body  ">
                            <h5 className="card-title">Sign Up</h5>
                            <p className="card-desc">You’ve been invited to create a new {invitationDetails.organisation.type} team. Please create
                                an account to get started.</p>
                            <Form>
                                {this.renderFormGroup("email", "Email", "text", {disabled: true})}
                                {this.renderFormGroup("username", "Username")}
                                {this.renderFormGroup("password", "Password", "password",
                                    {autoComplete: 'off'})}
                                {this.renderFormGroup("confirmPassword", "Confirm Password", "password",
                                    {autoComplete: "off"})}
                                {this.renderButton("Create Your Account",
                                    "btn btn-lg btn-submit btn btn-block mt-30 sign-in-text", )}
                                <h3 className="sign-up-text text-center "
                                    onClick={() => history.push("/login")}>Already have an account? Log in.</h3>
                                {this.state.serviceError && <Message variant="error" message={this.state.serviceError} />}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getProfile: userActions.getProfile,
    getPreferences: userActions.getPreferences
};

const mapStateToProps = (state) => {
    const {profileDetails} = state.userDetails;
    return {profileDetails}
}

const connectedGroupSignup = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GroupSignUp)
);

export {connectedGroupSignup as GroupSignUp};
