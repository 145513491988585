import React from "react";
import "./barIcon.scss";

export default function BarIcon(props) {
    switch (props.bars) {
        case 5: return (
            <div className="bar-status">
                <div className="bar-mr-2">Excellent</div>
                <div className="bar-wrapper">
                    <div className="filler-bar bar-1"></div>
                    <div className="filler-bar bar-2"></div>
                    <div className="filler-bar bar-3"></div>
                    <div className="filler-bar bar-4"></div>
                    <div className="filler-bar bar-5"></div>
                </div>
            </div>
        )
        case 4: return (
            <div className="bar-status">
                <div className="bar-mr-2">V.Good</div>
                <div className="bar-wrapper">
                    <div className="filler-bar bar-1"></div>
                    <div className="filler-bar bar-2"></div>
                    <div className="filler-bar bar-3"></div>
                    <div className="filler-bar bar-4"></div>
                </div>
            </div>
        )
        case 3: return (
            <div className="bar-status">
                <div className="bar-mr-2">Good</div>
                <div className="bar-wrapper">
                    <div className="filler-bar bar-1"></div>
                    <div className="filler-bar bar-2"></div>
                    <div className="filler-bar bar-3"></div>
                </div>
            </div>
        )
        case 2: return (
            <div className="bar-status">
                <div className="bar-mr-2">Fair</div>
                <div className="bar-wrapper">
                    <div className="filler-bar bar-1"></div>
                    <div className="filler-bar bar-2"></div>
                </div>
            </div>
        )
        case 1: return (
            <div className="bar-status">
                <div className="bar-mr-2">Bad</div>
                <div className="bar-wrapper">
                    <div className="filler-bar bar-1"></div>
                </div>
            </div>
        )
        default: return (
            <div className="bar-status">
                <div className="mr-2">V.BAD</div></div>)
    }
}
