/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useSelector } from 'react-redux';
import { connect } from "react-redux";
import Chart from '../../../common/chart/Chart';
import { Area, Line } from '../../../common/chart/series';
import { resetChart, dataURI } from '../../../common/chart/utils';
import templateService from '../template/duck/templateService';
import graphService from './duck/graphService';
import userActions from "../../../user/duck/userActions";
import {
  getDateParams,
  chartTooltipXAxisFormatting,
  toUTC,
  initialDateParams} from '../graph/duck/dateUtility';
import {
  getPoints,
  getPointsByDepth,
  getDatapointsMinMax,
  sliceTimeseries,
  allow,
  getMapping,
  hasNoDepthVariants,
  //templateForDateRange
} from '../graph/duck/graphUtility';
import { hiddenYAxis, updateOptions } from '../../../common/chart/utils';
// import GraphFilter from "./graphFilter";
import colors, { axisColors } from './duck/colorUtility';
import {
  CHART_NAME,
  MEASURE_SHORT_NAMES,
  MEASURE_NAME,
  MEASURE_TYPE,
  DATA_TYPES,
  DATA_TYPES_KEYS
} from './duck/constants';
import GraphLoader from './graphLoader';
import { StickyMessage } from '../../../common/messages';
import sma from './duck/features/sma';
import applyStackLevel, { MAX_STACK, applyCommonScale } from './duck/features/stacking';
import { updateSeries, toggleSectionVisibility } from '../../../common/chart/utils';
import yaxisScaleOptions from './duck/features/yaxisScale';
import printIcon from '../../../../assets/images/print/print.svg';
import { lastItem } from '../../../common/duck/utils';
import yzoom from '../../../../assets/images/zoom/yzoom.png';
import printJS from 'print-js';
import makeDocFromChart from './duck/features/printDoc';
import ERROR from './duck/messages';
import { Modal, Spinner } from "react-bootstrap";
import groguruLogo from '../../../../assets/images/emptycase_icon/bitmap.png';
import * as moment from 'moment';
import Input from "../../../common/input";
import siteService from "../../duck/siteService";


function GraphThumbnail(props) {
  const UNIQUE_CHART_NAME = CHART_NAME.concat(props.uniqueID)
  const graphPreferences = useSelector(state => state.userDetails.userPreferences.preferences.preference?.graphPreference)
  const { siteid, /*farmid,*/ twigid } = props;
  const siteUserSettings = graphPreferences?.[siteid];
  // const siteDetails = useContext(SiteContext);
  // const updateSiteDetails = useContext(SiteUpdate);
  const [siteDetails, setSiteDetails] = useState({})
  const [depthKeyMapping, setDepthKeyMapping] = useState({}); // {key: {key: objKey, depth: calcd_depth}}
  const [measurementsMapping, setMeasurementsMapping] = useState({}); // {'aw': {unit, label}}
  const [displayKey, setDisplayKey] = useState(siteUserSettings?.displayOptions?.toLowerCase() || 'relative');
  const [templateId , setTemplateId] = useState(siteDetails?.cropTemplate?.objectId || null);
  const [plantingDate, setPlantingDate] = useState(null);
  const [plantingDateWithoutUTC, setPlantingDateWithoutUTC] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [localSensor, setLocalSensor] = useState(null);
  const [hardwareSensor, setHardwareSensor] = useState(null);
  const [includedSensors, setIncludedSensors] = useState(null);
  const [irrigationData, setIrrigationData] = useState([]);
  const [predictionWaterData, setPredictionWaterUseData] = useState([]);
  const [predictionRainfallData, setPredictionRainfallData] = useState([]);
  const [rainfallData, setRainfallData] = useState([]);
  const [rootActivityData, setRootActivityData] = useState([]);
  const [waterPercoationData, setWaterPercoationData] = useState(null);
  const [legendView, setLegend] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState({ shouldLoadInitialGraph: true, shouldLoadInitialTemplate: true, graph: false, template: false });
  const [growthStage, setGrowthStage] = useState(false);
  const [subscriptionAlert, setSubscriptionAlert] = useState(false);
  const [summaryMin, setSummaryMin] = useState(null);
  const [awMinum, setAwmin] = useState(null);

  // const setTemplateId = useCallback(objectId => {
  //   updateSiteDetails.handleSiteDetailsUpdate({ cropTemplate: { objectId } });
  // }, [updateSiteDetails])

  const [templateColorSeries, setTemplateColorSeries] = useState({
    red: [],
    yellow: [],
    green: [],
    light_blue: [],
  });
  const [datapointsCache, setDatapointsCache] = useState({ queued: true, cacheCount: 0 });

  const [showIrrigationData, setShowIrrigationData] = useState(false);
  const [showRainfallData, setShowRainfallData] = useState(false);
  const [showPredictedData, setShowPredictedData] = useState(true);

  const [currentTab, onTabChangeCalled] = useState('AW')

  // filter menu options
  const [measurements, setMeasurements] = useState([]);
  const [depths, setDepths] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  const savedTimePeriodOption = 'ONE_MONTH';
  const defaultDateType = (savedTimePeriodOption === 'ONE_MONTH' || savedTimePeriodOption === 'THREE_MONTHS' || savedTimePeriodOption === 'ONE_WEEK' || savedTimePeriodOption === ' ONE_DAY') ? true : false
  const [timePeriod, setTimePeriod] = useState(savedTimePeriodOption);
  const [dataType, setDataType] = useState(siteUserSettings?.dataType?.map(d => DATA_TYPES_KEYS[d]) || (defaultDateType ? [DATA_TYPES_KEYS.SMA5] : [DATA_TYPES_KEYS.Raw]));

  const [dateParams, setDateParams] = useState(
    initialDateParams(
      siteUserSettings?.timePeriod?.fromDate,
      siteUserSettings?.timePeriod?.toDate,
      savedTimePeriodOption
    )
  );
  const [totalChecked, setTotalChecked] = useState(true);
  const [graphMax, setGraphMax] = useState(null);
  const graphControls = {
    measurements: {
      get: measurements,
      set: (measurement) => setMeasurements(allow.two(measurement)),
    },
    depths: {
      get: depths,
      set: (depth) => setDepths(allow.multiple(depth)),
    },
    siteIds: {
      get: siteIds,
      set: setSiteIds,
    },
    timePeriod: {
      get: timePeriod,
      set: setTimePeriod,
      setCustom: setDateParams
    },
    displayOptions: {
      get: displayKey,
      set: setDisplayKey
    },
    dataType: {
      get: dataType,
      set: (type, name) => setDataType(selected => {
        if (selected.includes(type) && name === timePeriod && type !== dataType[0]) {
          return selected.filter(i => i !== type);
        } else {
          if (name === undefined) {
            if (selected.includes(type)) {
              return selected.filter(i => i !== type);
            }
            else if (type !== DATA_TYPES_KEYS.Raw) {
              return [...selected.filter(i => i === DATA_TYPES_KEYS.Raw), type]
            }
            return [...selected, type]
          }
          return [type]
        }
      })
    }
  }

  const dataNotFoundForDateRange = useRef(false);
  const prevTimePeriod = useRef(null);
  const prevDateParams = useRef({ fromDate: '', toDate: '' });
  const dateParamsOnDisplay = useRef({ fromDate: '', toDate: '' });
  const dataPoints = useMemo(() => {
    dataNotFoundForDateRange.current = false;
    if (timePeriod !== 'CUSTOM' && datapointsCache[timePeriod]) {
      prevTimePeriod.current = timePeriod;
      prevDateParams.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
      dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
      return datapointsCache[timePeriod];
    } else {
      const reqStart = Date.parse(dateParams.fromDate);
      const reqEnd = Date.parse(dateParams.toDate);
      const startAvailable = reqStart >= datapointsCache.fromDate;
      const endAvailable = reqEnd <= datapointsCache.toDate;
      if (timePeriod === 'CUSTOM') {
        if (startAvailable && endAvailable) {
          dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
          return sliceTimeseries(datapointsCache.ONE_YEAR, {
            fromDate: dateParams.fromDate,
            toDate: dateParams.toDate
          });
        } else {
          if (
            datapointsCache['CUSTOM'] &&
            datapointsCache['CUSTOM'].start === dateParams.fromDate &&
            datapointsCache['CUSTOM'].end === dateParams.toDate
          ) {
            if (Object.keys(datapointsCache['CUSTOM'].data).length === 0) {
              return []
            } else {
              dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
              return datapointsCache['CUSTOM'].data;
            }
          }
          dataNotFoundForDateRange.current = true;
        }
      }
      if (timePeriod !== 'CUSTOM' && datapointsCache.cacheCount !== 0) {
        dataNotFoundForDateRange.current = true;
      }
      if (prevTimePeriod.current && datapointsCache[prevTimePeriod.current]) {
        if (prevDateParams.current.fromDate === dateParams.fromDate && prevDateParams.current.toDate === dateParams.toDate) {
          dataNotFoundForDateRange.current = false;
        }
        return datapointsCache[prevTimePeriod.current]
      }
      return []
    }
  }, [timePeriod, dateParams.fromDate, dateParams.toDate, datapointsCache]);


  const measurementOptions = useMemo(() => {
    const measurementsByName = {};
    let measurementsOrdered = [];

    for (let measureKey in MEASURE_NAME) {
      if (measureKey === "battery" || measureKey === "rssi") continue; //Need to show battery and rssi in single tab(system tab)
      const name = MEASURE_NAME[measureKey];
      const key = MEASURE_SHORT_NAMES[name];
      measurementsByName[name] = { key, name };
    }

    for (let key in MEASURE_TYPE) {
      const item = measurementsByName[MEASURE_TYPE[key]];
      if (item) {
        measurementsOrdered.push(item);
      }
    }

    return measurementsOrdered;
  }, []);

  const [errors, setErrors] = useState(null);
  const templateAreaCount = useRef(0);
  const depthOptions = useMemo(() => Object.entries(depthKeyMapping).sort(([_, depthB], [__, depthA]) => depthB.depth - depthA.depth).map(([key, depthObj]) => ({ key, name: `${depthObj.depth} in` })), [depthKeyMapping]);
  const [unitData, setUnitData] = useState(null);
  const [templateLastDate, setTemplateLastDate] = useState(null);
  // set data for legend
  const [legendPoints, setLegendPoints] = useState({ dataPointIndex: 0, seriesIndex: 0, xValue: 0 });

  // useEffect(() => {
  //   // show 'loading template' onload only when there is a preset template
  //   if (!siteDetails.isLoading) {
  //     const savedTemplateId = siteDetails?.cropTemplate?.objectId || null;
  //     if (savedTemplateId) {
  //       setTemplateId(savedTemplateId);
  //     } else {
  //       setIsDataLoading(loading => ({ ...loading, shouldLoadInitialTemplate: false }));
  //     }
  //   }
  //   // sitedetails has loaded
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [siteDetails.isLoading]);

  useEffect(() => {
    // siteService.getSiteWithTemplateData(props?.siteObjectId)
    // .then(data => {
      setSiteDetails(props.data.siteDetails)
      setTemplateId(props.data?.templateData?.cropID)
      let DATA = props?.data
      setIsDataLoading(loading => ({ ...loading, template: true }));
      var feature = new Date();
          var numberOfDaysToAdd = 40;
         feature.setDate(feature.getDate() + numberOfDaysToAdd); 
          var pasts = new Date();
          pasts.setDate(pasts.getDate()-30);
       const DateseriesNew = DATA?.templateData?.dateSeries?.filter((datas) => {
         const xdate = new Date(datas)
         
         return xdate.getTime() < feature.getTime();
     });  if(DATA?.templateData?.dateSeries){
          DATA.templateData.dateSeries = DateseriesNew
          const datalength = DATA?.templateData?.dateSeries?.length;
          const templateLastDate = DATA?.templateData?.dateSeries?.[datalength - 1];
          setTemplateLastDate(templateLastDate);
          let colorSeriesData = {};
          for (let color in DATA.templateData?.colorSeries) {
            colorSeriesData[color] = DATA?.templateData?.colorSeries[color].map((p, i) => ({
              x: DATA.templateData.dateSeries[i],
              y: p,
            }));
          }
          setTemplateColorSeries(colorSeriesData);
        }
          setIsDataLoading(loading => ({ ...loading, shouldLoadInitialTemplate: false, template: false }))
    // })
  }, [props.siteObjectId])
  

  useEffect(() => {
      setDateParams(getDateParams("ONE_MONTH"));
    
  }, [])

  let shouldLoadInitialGraphData = (!dateParams.isEmpty && datapointsCache.cacheCount === 0) || props?.location?.query?.loadFromPopUp;

  useEffect(() => {
    if (shouldLoadInitialGraphData) {
      props.updateBattery(null);
      props.updateRss(null);
      setIsDataLoading(loading => ({ ...loading, graph: true }));
      const data = props?.data
          setLocalSensor(data.siteDetails.settings.localSensorLocation.value.sensors);
          setHardwareSensor(data.siteDetails.settings.hardwareSensorLocation.value.sensors);
          setIncludedSensors(data.siteDetails.settings?.selectedSensors?.value);
          setCsvData(data.graph);
          let depthLSL = {};
          let depthHSL = getMapping.calculateDepth(data.siteDetails.settings.hardwareSensorLocation);
          if (data.siteDetails.settings.localSensorLocation.count) {
            depthLSL = getMapping.calculateDepth(data.siteDetails.settings.localSensorLocation, 'local-');
          }
          setDepthKeyMapping(Object.assign(depthHSL, depthLSL));
          setUnitData(data.siteDetails.details.data);
          setMeasurementsMapping(getMapping.measurementUnitsAndLabels(data.siteDetails.details.data, displayKey));
          setDatapointsCache(cache => {
            var feature = new Date();
            var numberOfDaysToAdd = 5;
           feature.setDate(feature.getDate() + numberOfDaysToAdd); 
            var past = new Date();
            past.setDate(past.getDate()-30);
      
              const CUSTOM = {
                start: new Date(past),
                end: new Date(feature),
                data: data.graph
              }
              return { ...cache, cacheCount: cache.cacheCount + 1, CUSTOM };

          });
          setPlantingDate(toUTC(data.siteDetails.settings.plantingDate.value));
          setPlantingDateWithoutUTC(data.siteDetails.settings.plantingDate.value);
          setIsDataLoading(loading => ({ ...loading, shouldLoadInitialGraph: false, template: loading.template, graph: false }));
          setErrors(null); // dateParams change frequently when custom timeperiod is selected,  causing the api call to get cancelled multiple times
    }
    // should not run when dataParams.isEmpty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayKey, siteid, twigid, dateParams.fromDate, dateParams.toDate, shouldLoadInitialGraphData]);

  const isDepthsNotSet = depths.length === 0;
  const isMeasurementsNotSet = measurements.length === 0;
  useEffect(() => {
    // set default selections of measurement & depths
    const depthKeys = depthOptions.map((d) => d.key);
    if (isDepthsNotSet || depths.length !== depthKeys.length) {
      setDepths(depthKeys);
    }
    if (isMeasurementsNotSet && measurementOptions.length > 0) {
      const setting = ([props.graphType]).filter(
        measure => measurementOptions.find(opt => opt.key === measure)
      );
      if (setting.length > 0) {
        setMeasurements(setting);
      } else {
        setMeasurements([measurementOptions[0]?.key]);
      }
    }
    // 'siteUserSettings' changes on 'save' this should not reset filter options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDepthsNotSet, isMeasurementsNotSet, depthOptions, measurementOptions]);

  // useEffect(() => {
  //   if (shouldLoadInitialGraphData) {
  //     const growthId = props.location.state?.twigDetails?.settings?.dtnDetails?.growthId;
  //     const apiCall = graphService.getGddData();
  //     apiCall.request(siteid, growthId)
  //     .then(data => {
  // setGdd(data);
  //  console.log(data,'dataqq');
  // })
  // .catch(err => {
  //       if (!http.isCancel(err)) {
  //         console.log(err)
  //         setErrors(err?.response?.data?.message || 'Sorry, Something went wrong!');
  //         // setIsDataLoading({ graph: false, template: false });
  //       }
  //     });
  //   }
  // }, [isDepthsNotSet, isMeasurementsNotSet, depthOptions, measurementOptions]);


  const linesXValueMinMax = useRef({ min: 0, max: 0 });
  const scalesByMeasurement = useRef({});
  // setting graph series data
  const curvesToRender = useMemo(() => {
    if (dataPoints.length === 0 || measurements.length === 0) {
      return []
    }
    const [left] = measurements;
    let xValue;
    scalesByMeasurement.current = {};
    linesXValueMinMax.current = { min: 0, max: 0 };

    let measurementUnitAndLabelByShortName = {};
    let curvesByMeasurement = {};
    for (let measure of measurements) {
      curvesByMeasurement[measure] = [];
    }
    let gddAverageCondition, checkAverage;
    for (const measureKey in dataPoints) {
      const measureKeyAlias = measureKey.split('_local')[0];
      const measureLabel = MEASURE_NAME[measureKeyAlias];
      const measureShortName = MEASURE_SHORT_NAMES[measureLabel];
      if (measurements.includes(measureShortName)) {
        let displayBranch = dataPoints[measureKey][displayKey];
        if (displayBranch.length === 0) {
          if (measureKey === 'aw_local' && displayKey === 'relative') {
            // without this fix, 'aw_local' absolute will be shown in chart because of this branch selection
            continue;
          } else if (measureKey === 'aw_local' && displayKey === 'absolute') {
            // Fix for not showing aw_local relative values in absolute curve 
            continue;
          } else if (dataPoints[measureKey]['absolute'].length !== 0) {
            displayBranch = dataPoints[measureKey]['absolute'];
          } else {
            displayBranch = dataPoints[measureKey]['relative'];
          }
        }
        if (measureKey === "gddEnvelope") { // GDD graph won't depends on data Type 
          let smaWindowSize = parseInt(0);
          let dataTypeName = 'Raw';
          if (hasNoDepthVariants(displayBranch)) {
            const measurementDatapoints = sma(getPoints(displayBranch), smaWindowSize);
            scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
            if (!measurementUnitAndLabelByShortName[measureShortName]) {
              measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
            }
            xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
            const showTopSensorLabel = measureKey.includes('_local');
            const series = Line(`${measureShortName} ${showTopSensorLabel ? 'L' : '-'} ${dataTypeName}`, measurementDatapoints, {
              color: measureKey === 'aw' ?
                colors.black
                : null,
              checkedIndex: null,
              meta: {
                measureKey,
                shortName: measureShortName,
                measurement: measureLabel,
                depth: (measureKey.includes('aw')) ? (showTopSensorLabel ? { depth: '', key: "local-0", name: "Summary With TSS" } : { depth: '', key: "1", name: "Summary Without TSS" }) : null,
                showTopSensorLabel,
                unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                opposite: left !== measureShortName,
                dataType: DATA_TYPES[smaWindowSize]
              }
            });
            curvesByMeasurement[measureShortName].push(series);
          } else {
            let depthsToLoop = [];
            let prefix = '';
            if (measureKey.includes('_local')) {
              prefix = 'local-'
              depthsToLoop = depths.filter(d => d.includes('local-')).map(d => d.split('local-')[1]);
            } else {
              depthsToLoop = depths.filter(d => !d.includes('local-'));
              // if (measureShortName === 'AW') {
              //   depthsToLoop = ["total", ...depthsToLoop];
              // }
            }

            if (!measurementUnitAndLabelByShortName[measureShortName]) {
              measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
            }
            checkAverage = displayBranch.filter(data => data.agddEnvelopeAvg === null);
            const newDepthKeyMapping = {
              "agddEnvelopeMin": {
                depth: "",
                key: "agddEnvelopeMin",
                name: "Lowest",
                unit: "",
              },
              "agddEnvelopeAvg": {
                depth: "",
                key: "agddEnvelopeAvg",
                name: "Average",
                unit: ""
              },
              "agddEnvelopeMax": {
                depth: "",
                key: "agddEnvelopeMax",
                name: "Highest",
                unit: ""
              }
            }
            gddAverageCondition = displayBranch?.length === checkAverage?.length;
            if (gddAverageCondition) {
              delete newDepthKeyMapping["agddEnvelopeAvg"]
              displayBranch.forEach(function (v) { delete v?.agddEnvelopeAvg });
            }
            const depthsLoop = measurements[0] === 'GDD' ? ["agddEnvelopeMin", "agddEnvelopeAvg", "agddEnvelopeMax", "agdd"] : depthsToLoop;
            for (const depth of depthsLoop) {
              const measurementDatapoints = sma(getPointsByDepth(displayBranch, depth), smaWindowSize);
              if (depth !== 'total') {
                scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
              } else if (depth === 'total') {
                const q = measurementDatapoints.map(i => i.y);
                const graphMax = Math.max(...q);
                setGraphMax(graphMax);
              }

              xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
              const series = Line(`${measureShortName} ${depth} ${dataTypeName}`, measurementDatapoints, {
                color: null,
                checkedIndex: depth === 'total' ? null : depth,
                meta: {
                  measureKey,
                  shortName: measureShortName,
                  measurement: measureLabel,
                  depth: measureShortName !== 'GDD' ? depthKeyMapping[`${prefix}${depth}`] : newDepthKeyMapping[`${prefix}${depth}`],
                  showTopSensorLabel: false,
                  unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                  opposite: left !== measureShortName,
                  dataType: DATA_TYPES[smaWindowSize]
                }
              });
              curvesByMeasurement[measureShortName].push(series);
            }
          }
        } else {

          for (const dType of dataType) {
            const smaWindowSize = parseInt(dType);
            const dataTypeName = dType === '0' ? 'Raw' : 'SMA';
            if (hasNoDepthVariants(displayBranch)) {
              const measurementDatapoints = sma(getPoints(displayBranch), smaWindowSize);
              scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
              if (!measurementUnitAndLabelByShortName[measureShortName]) {
                measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
              }
              xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
              const showTopSensorLabel = measureKey.includes('_local');
              const series = Line(`${measureShortName} ${showTopSensorLabel ? 'L' : '-'} ${dataTypeName}`, measurementDatapoints, {
                color: measureKey === 'aw' ?
                  colors.black
                  : null,
                checkedIndex: null,
                meta: {
                  measureKey,
                  shortName: measureShortName,
                  measurement: measureLabel,
                  depth: (measureKey.includes('aw')) ? (showTopSensorLabel ? { depth: '', key: "local-0", name: "Summary With TSS" } : { depth: '', key: "1", name: "Summary Without TSS" }) : null,
                  showTopSensorLabel,
                  unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                  opposite: left !== measureShortName,
                  dataType: DATA_TYPES[dType]
                }
              });
              curvesByMeasurement[measureShortName].push(series);
            } else {
              let depthsToLoop = [];
              let localLoop = false;
              let prefix = '';
              if (measureKey.includes('_local')) {
                prefix = 'local-'
                depthsToLoop = depths.filter(d => d.includes('local-')).map(d => d.split('local-')[1]);
                localLoop = true;
              } else {
                depthsToLoop = depths.filter(d => !d.includes('local-'));
                localLoop = false;
                if (measureShortName === 'AW') {
                  depthsToLoop = ["total", ...depthsToLoop];
                }
              }

              if (!measurementUnitAndLabelByShortName[measureShortName]) {
                measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
              } else if (measurementUnitAndLabelByShortName[measureShortName] &&
                measurementUnitAndLabelByShortName[measureShortName].label !== 'Soil Moisture' &&
                (measureKey === 'moisture' || measureKey === 'volwatcont')) {
                measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
              } else if (measurementUnitAndLabelByShortName[measureShortName] &&
                measurementUnitAndLabelByShortName[measureShortName].label !== 'Soil Temperature' &&
                (measureKey === 'temp_f')) {
                measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
              } else if (measurementUnitAndLabelByShortName[measureShortName] &&
                measurementUnitAndLabelByShortName[measureShortName].label !== 'Salinity' &&
                (measureKey === 'salinity')) {
                measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
              }

              for (const depth of depthsToLoop) {
                const measurementDatapoints = sma(getPointsByDepth(displayBranch, depth), smaWindowSize);
                if (depth !== 'total') {
                  scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
                } else if (depth === 'total') {
                  const q = measurementDatapoints.map(i => i.y);
                  const graphMax = Math.max(...q);
                  setGraphMax(graphMax);
                }

                xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
                const series = Line(`${measureShortName} ${prefix}${depth} ${dataTypeName}`, measurementDatapoints, {
                  color: null,
                  checkedIndex: (depth === 'total' || localLoop) ? null : depth,
                  meta: {
                    measureKey,
                    shortName: measureShortName,
                    measurement: measureLabel,
                    depth: depthKeyMapping[`${prefix}${depth}`],
                    showTopSensorLabel: false,
                    unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                    opposite: left !== measureShortName,
                    dataType: DATA_TYPES[dType]
                  }
                });
                curvesByMeasurement[measureShortName].push(series);
              }
            }
          }
        }
      }
    }

    let showRainAndIrrDataBasedOnTab = (measurements[0] === "MOIST" || measurements[0] === "AW") && measurements.filter(each => each !== "RAIN" && each !== "IRR").length === 1;

    if (showRainAndIrrDataBasedOnTab && rainfallData.length > 0 && showRainfallData) {
      curvesByMeasurement["RAIN"] = [];
      scalesByMeasurement.current["RAIN"] = getDatapointsMinMax(rainfallData, scalesByMeasurement.current["RAIN"]);
      if (!measurementUnitAndLabelByShortName["RAIN"]) {
        measurementUnitAndLabelByShortName["RAIN"] = { measureKey: "rainfall", unit: "inch", label: "Rainfall" };
      }
      if (!measurements.includes("RAIN")) setMeasurements([...measurements, "RAIN"]);
      curvesByMeasurement["RAIN"].push(Line(`RAIN&IRR Raw`, rainfallData, {
        color: colors.dark_blue,
        checkedIndex: null,
        meta: {
          measureKey: 'rainfall',
          shortName: 'RAIN',
          measurement: 'Rainfall',
          depth: { depth: '', key: "Rainfall", name: "Rainfall" },
          unit: "inch",
          opposite: true,
          dataType: "Raw",
          lineWidth: 5,
        }
      }));
    }
    if (showRainAndIrrDataBasedOnTab && irrigationData.length > 0 && showIrrigationData) {
      curvesByMeasurement["IRR"] = [];
      scalesByMeasurement.current["IRR"] = getDatapointsMinMax(irrigationData, scalesByMeasurement.current["IRR"]);
      if (!measurementUnitAndLabelByShortName["IRR"]) {
        measurementUnitAndLabelByShortName["IRR"] = { measureKey: "irrigation", unit: "inch", label: "Applied Irrigation" };
      }
      if (!measurements.includes("IRR")) setMeasurements([...measurements, "IRR"]);
      curvesByMeasurement["IRR"].push(Line(`RAIN&IRR Raw`, irrigationData, {
        color: colors.light_blue,
        checkedIndex: null,
        meta: {
          measureKey: 'irrigation',
          shortName: 'IRR',
          measurement: 'Applied Irrigation',
          depth: { depth: '', key: "App Irrigation", name: "App Irrigation" },
          unit: "inch",
          opposite: true,
          dataType: "Raw",
          lineWidth: 5,
        }
      }));
    }

    if ((siteDetails?.planDetails?.plan === "Gold" || props.userRole === "groguru_admin") && showPredictedData && currentTab === "AW") {
      if (predictionRainfallData.length > 0) {
        if (!curvesByMeasurement["RAIN"]) { //Rainfall and Prediction rainfall are using the same y-axis so treat them as the same measurement. 
          curvesByMeasurement["RAIN"] = [];
        }
        scalesByMeasurement.current["RAIN"]  = getDatapointsMinMax(predictionRainfallData, scalesByMeasurement.current["RAIN"]);
        if (!measurementUnitAndLabelByShortName["RAIN"]) {
          measurementUnitAndLabelByShortName["RAIN"] = { measureKey: "rainfall", unit: "inch", label: "Rainfall" };
        }
        curvesByMeasurement["RAIN"].push(Line(`RAIN&IRR PRED_R Raw`, predictionRainfallData, {
          color: colors.dark_blue,
          style: 'dotted',
          dashArray: 3,
          meta: {
            measureKey: 'rainfall',
            shortName: 'RAIN',
            measurement: 'Rainfall',
            depth: { depth: '', key: "Rainfall PRED_R", name: "Rainfall PRED_R"},
            unit: "inch",
            opposite: true,
            dataType: "Raw",
            lineWidth: 3.0,
          }
        }));
      }
      if (displayKey === 'absolute' && predictionWaterData.length > 0) {
        scalesByMeasurement.current["AW"] = getDatapointsMinMax(predictionWaterData, scalesByMeasurement.current["AW"]); 
        // Summary and Predicted water use belong to the same category. So no need to add separate measurement PRED.
        curvesByMeasurement["AW"].push(Line(`AW PRED Raw`, predictionWaterData, {
          color: colors.grey,
          style: 'dotted',
          meta: {
            measureKey: 'aw',
            shortName: 'AW',
            measurement: 'Summary',
            depth: { depth: '', key: "Summary PRED", name: "Summary PRED" },
            unit: 'inches', // TODO: For the relative summary graph unit is the percentage.
            opposite: false,
            dataType: "Raw",
            lineWidth: 2.0,
          }
        }));
      }
    }

    //use one Y-axis for both rainfall and irrigation 
    if ((measurementUnitAndLabelByShortName["IRR"] || measurementUnitAndLabelByShortName["RAIN"])){
      let yAxisLabel;
      if (measurementUnitAndLabelByShortName["IRR"] && measurementUnitAndLabelByShortName["RAIN"]) {
        yAxisLabel = 'App Irrigation & Rainfall';
      } else if (measurementUnitAndLabelByShortName["IRR"]) {
        yAxisLabel = 'Applied Irrigation';
      } else if (measurementUnitAndLabelByShortName["RAIN"]) {
        yAxisLabel = 'Rainfall';
      }
      measurementUnitAndLabelByShortName["RAIN&IRR"] = { measureKey: "RAIN&IRR", unit: "in", label: yAxisLabel };
      scalesByMeasurement.current["RAIN&IRR"] = getDatapointsMinMax([...rainfallData, ...irrigationData, ...predictionRainfallData], scalesByMeasurement.current["RAIN&IRR"]);
    }

    let seriesArray = [];
    let colorIndex = 0;
    let localColorIndex = 0;
    // flatten all series & add colors linearly by depth
    for (let series of Object.values(curvesByMeasurement)) {
      let seriesForMeasurement = series;
      if (seriesForMeasurement[0]?.meta?.depth?.depth) {
        seriesForMeasurement = seriesForMeasurement.sort(
          (a, b) => a.meta?.depth?.depth - b.meta?.depth?.depth
        );
      }

      const multipleDatatypesSelected = dataType.length > 1;
      let shouldUseDifferentColor = true;
      for (let curve of seriesForMeasurement) {
        if (curve.color === null) {

          if (curve.meta?.depth?.key?.includes('local-')) {
            curve.color = colors.brown_fox[localColorIndex];
            ++localColorIndex;
          } else {
            if (curve.data.name.indexOf("total") > -1) {
              curve.color = "#000";
            } else {
              curve.color = axisColors[colorIndex];
            }
            if (multipleDatatypesSelected) {
              // the lines for same sensors must have same color for SMA & Raw
              shouldUseDifferentColor = !shouldUseDifferentColor;
            }

            if (shouldUseDifferentColor && curve.data.name.indexOf("total") === -1) {
              ++colorIndex;
            }

          }
        }
        seriesArray.push(curve);
      }
    }

    if (xValue === undefined) {
      return []
    }
    let newMeasurements = [...measurements];
    // If only predicted rainfall is displaying then RAIN measurement won't be present. So add RAIN for the y-axis of predicted rainfall.
    if ((siteDetails?.planDetails?.plan === "Gold" || props.userRole === "groguru_admin") && showPredictedData && currentTab === "AW" && !newMeasurements.includes('RAIN')) /*newMeasurements.push('RAIN')*/;
    newMeasurements = newMeasurements.map(each => ((each === "RAIN" || each === "IRR")) ? 'RAIN&IRR' : each).filter((item, index, array) => array.indexOf(item) === index);
    const axisSeries = newMeasurements.map(measureShortName => Line(`${measureShortName} yaxis`, [
      { x: xValue.min, y: scalesByMeasurement.current[measureShortName]?.min || 0 },
      { x: xValue.max, y: scalesByMeasurement.current[measureShortName]?.max || 10 }
    ], {
      color: colors.transparent,
      checkedIndex: null,
      meta: {
        measureKey: measurementUnitAndLabelByShortName[measureShortName],
        measurement: MEASURE_TYPE[measureShortName],
        shortName: measureShortName,
        unit: (measureShortName === 'AW' && displayKey === 'absolute') ? 'in' : measurementUnitAndLabelByShortName[measureShortName]?.unit === undefined ? '' : measurementUnitAndLabelByShortName[measureShortName]?.unit,
        label: measureShortName !== 'GDD' ? measurementUnitAndLabelByShortName[measureShortName]?.label : 'Accumulated Growing Day Degrees',
        opposite: measureShortName !== left,
        hasYAxis: measureShortName,
        isHidden: true,
      },
    }));

    let gddSeriesArray = []
    linesXValueMinMax.current = xValue;
    if (measurements[0] === "GDD" && seriesArray.length === 1 && seriesArray[0].data.data.length === 0) {
      return [];
    } else if ((measurements[0] === 'GDD' && gddAverageCondition) || (measurements[0] === 'GDD' && checkAverage?.length === 0)) {
      gddSeriesArray = [...seriesArray]
      gddSeriesArray.splice(1, 1);
      gddSeriesArray[0].color = "#0055ff"
      gddSeriesArray[1].color = "#ff0000"
      gddSeriesArray[2].color = "#000"
      gddSeriesArray[1].dashArray = '3'
      gddSeriesArray[0].dashArray = '3'
      if ((measurements[0] === 'GDD' && siteDetails?.planDetails?.plan === "Silver" && props.userRole !== "groguru_admin")) {
        return []
      }
      return [...gddSeriesArray, ...axisSeries];
    } else if (measurements[0] === 'GDD' && seriesArray[0]?.data?.data?.length > 0) {
      gddSeriesArray = [...seriesArray]
      gddSeriesArray[0].color = "#0055ff"
      gddSeriesArray[1].color = "#0C9F52"
      gddSeriesArray[2].color = "#ff0000"
      gddSeriesArray[3].color = "#000"
      gddSeriesArray[1].dashArray = '7'
      gddSeriesArray[2].dashArray = '3'
      gddSeriesArray[0].dashArray = '3'
      return [...gddSeriesArray, ...axisSeries];
    }
    if ((measurements[0] === 'GDD' && siteDetails?.planDetails?.plan === "Silver" && props.userRole !== "groguru_admin")) {
      return []
    }
    return [...seriesArray, ...axisSeries];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoints,growthStage, measurements, currentTab, displayKey, dataType, depths, depthKeyMapping, measurementsMapping, rainfallData, irrigationData, showIrrigationData, showRainfallData, props, siteDetails, showPredictedData, predictionRainfallData, predictionWaterData]);

  const annotations = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    const annotations = dataPoints?.gddEnvelope?.stageData.map((data, index) => {
      if (curvesToRender.length !== 0) {
        if (measurements[0] === "GDD") {
          return {
            x: Date.parse(data.xValue),
            strokeDashArray: Date.parse(new Date()) > Date.parse(data.xValue) ? 0 : 5,
            borderColor: '#000',
            label: {
              borderWidth: 0,
              offsetY: index % 2 === 0 ? 170 : 0,
              style: {
                color: '#000',
                fontSize: '14px',
                textAnchor: 'end',
                position: 'bottom',
                background: "transparent",
                fontWeight: 'bold',
              },
              text: data.stageName,
            }
          }

        } else if (templateLastDate === null) {
          if (data.xValue >= dateParams.fromDate && data.xValue <= dateParams.toDate) {
            return {
              x: Date.parse(data.xValue),
              strokeDashArray: Date.parse(new Date()) > Date.parse(data.xValue) ? 0 : 5,
              borderColor: '#000',
              label: {
                borderWidth: 0,
                offsetY: index % 2 === 0 ? 170 : 0,
                style: {
                  color: '#000',
                  fontSize: '14px',
                  textAnchor: 'end',
                  position: 'bottom',
                  background: "transparent",
                  fontWeight: 'bold',
                },
                text: data.stageName,
              }
            }
          }
        } else {
          if (templateLastDate && data.xValue >= dateParams.fromDate && data.xValue <= templateLastDate) {
            return {
              x: Date.parse(data.xValue),
              strokeDashArray: Date.parse(new Date()) > Date.parse(data.xValue) ? 0 : 5,
              borderColor: '#000',
              label: {
                borderWidth: 0,
                offsetY: index % 2 === 0 ? 170 : 0,
                style: {
                  color: '#000',
                  fontSize: '14px',
                  textAnchor: 'end',
                  position: 'bottom',
                  background: "transparent",
                  fontWeight: 'bold',
                },
                text: data.stageName,
              }
            }
          }
        }
      }
    });
    if (measurements[0] === "GDD" || (measurements[0] === "AW" && growthStage)) {
      return annotations;
    }
    return [];
  }, [dataPoints, templateLastDate, measurements, growthStage, dateParams, curvesToRender])
    
  const yAxisAnnotation = useMemo(() => {
    if (measurements[0] === "MOIST" || measurements[0] === "AW") {
      return [
        {
          y: 100.5,
          y2: 100,
          fillColor:"#0A0A0A",
          opacity: 0.6,
        },
      ];
    } else return [];
  }, [measurements]);

  const [pointAnnotation, setPointAnnotation] = useState([]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({ x: '', note: '' });
  const [isSaving, setIsSaving] = useState(false);
  const [showViewNote, setShowViewNote] = useState(false);
  const [showNoteError, setShowNoteError] = useState(false);
  let showContextmenu = measurements[0] === "MOIST" || measurements[0] === "AW";
  const [showNotes, setShowNotes] = useState(siteDetails?.twigDetails?.settings?.showNotes || false);


  useEffect(() => {
    setShowNotes(siteDetails?.twigDetails?.settings?.showNotes?.value || false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetails?.siteId])


  const onCloseNoteModal = () => {
     setShowAddNote(false);
     setShowViewNote(false);
     setSelectedNote({ x: '', note: '' });
     setShowNoteError(false);
  }

  const addNote = () => {
    if (selectedNote.note === "") {
      setShowNoteError(true);
      return;
    }  
    setIsSaving(true);
    let payload = {
      timestamp: new Date(selectedNote.x).toISOString(),
      note: selectedNote.note
    }
    graphService.addNoteForSite(siteid, payload)
      .then(data => {
        let newNote = {
          x: new Date(data.timestamp).getTime(),
          note: data.note,
          objectId: data.objectId,
          image: {
            path: groguruLogo,
          },
        }
        if (dateParams.fromDate <= data.timestamp && data.timestamp <= dateParams.toDate) {
          let newPointAnnotation = [...pointAnnotation, newNote].sort((a,b) => a.x - b.x);
          setPointAnnotation(newPointAnnotation);
          updateOptions(UNIQUE_CHART_NAME, { annotations: { points : newPointAnnotation} })
        }
        setIsSaving(false);
        setSelectedNote({ x: '', note: '' });
        onCloseNoteModal();
      });
  }

  function handleNoteChange(event) {
    const { name, value } = event.target;
    setSelectedNote(noteValues => ({...noteValues, [name] : value }));
    if (name === "note" && value !== "") setShowNoteError(false);  
  }


  const removeNote = () => {
    setIsSaving(true);
    graphService.removeNoteForSite(selectedNote.objectId)
      .then(() => {
        let newPointAnnotation = pointAnnotation.filter(each => each.objectId !== selectedNote.objectId);
        setPointAnnotation(newPointAnnotation);
        updateOptions(UNIQUE_CHART_NAME, { annotations: { points : newPointAnnotation} });
        setSelectedNote({ x: '', note: '' });
        setIsSaving(false);
        onCloseNoteModal();
      });
  }

  // making template series
  const shouldNotViewTemplate = (
    !templateId ||
    !measurements.includes('AW') ||
    displayKey !== 'relative' ||
    !((dataPoints.aw || dataPoints.aw_local)?.relative?.length > 0)
  );

  const shouldSkipTemplateLoad = (
    shouldNotViewTemplate ||
    (templateColorSeries.red.length === 0 && templateColorSeries.green.length === 0)
  );

  useEffect(() => {
    if (curvesToRender.length > 0 && displayKey === 'relative' && measurements[0] === 'AW') {
      const awCurve = curvesToRender?.filter(i => {
        return i.color === '#000000';
      });
      const awTssCurve = curvesToRender?.filter(i => {
        return i.color === '#9A6324';
      });
      let finaltssMin = null;
      if (awTssCurve.length > 0) {
        const awtssData = awTssCurve[0].data.data;
        const ay = awtssData.map(i => i.y);
        const awtssMin = Math.min(...ay);
        finaltssMin = awtssMin - 10;
        //setAwmin(finalMin);
      }
      const awData = awCurve[0].data.data;
      const ay = awData.map(i => i.y);
      const awMin = Math.min(...ay);
      const finalMin = awMin - 10;
      if (finaltssMin) {
        if (finaltssMin < finalMin) {
          setAwmin(finaltssMin);
        } else {
          setAwmin(finalMin);
        }
      } else {
        setAwmin(finalMin);
      }

      const redCurve = templateColorSeries.red;
      const ry = redCurve.map(i => i.y);
      const redMin = Math.min(...ry);

      if (redMin < awMin) {
        const less = redMin - 10;
        setSummaryMin(less);
      } else {
        const less = awMin - 10;
        setSummaryMin(less);
      }
    }
  }, [templateColorSeries, curvesToRender, displayKey, measurements])

  const lowestAWValue = (siteDetails?.cropTemplate?.objectId !== null &&
    measurements[0] === 'AW' &&
    displayKey === 'relative' &&
    summaryMin !== null) ? summaryMin : awMinum;
  const highestAWValue = (totalChecked && graphMax && measurements[0] === 'AW' && displayKey === 'absolute') ? graphMax : scalesByMeasurement.current?.AW?.max;

  //should show the selected daterange on custom dateranges, evenif data is not available
  // const isCustomDateRangeMinAvailable = timePeriod === 'CUSTOM' && dateParams.fromDate === datapointsCache.CUSTOM?.start;
  // const isCustomDateRangeMaxAvailable = timePeriod === 'CUSTOM' && dateParams.toDate === datapointsCache.CUSTOM?.end;
  // const summaryLineStart = (
  //   (isCustomDateRangeMinAvailable && Date.parse(dateParams.fromDate)) ||
  //   linesXValueMinMax.current.min
  // );

  // const summaryLineEnd = (
  //   (isCustomDateRangeMaxAvailable && Date.parse(dateParams.toDate))
  // );

  let template = useMemo(() => {
    if (shouldSkipTemplateLoad) {
      templateAreaCount.current = 0;
      return [];
    } else {
      let { max: awMax, min: awMin } = yaxisScaleOptions(
        lowestAWValue,
        highestAWValue
      );
      const templateSeries = Object.entries(templateColorSeries).map(([color, colorSeries]) => {
        let dataPointsTimeseries1 = colorSeries.map((point) => {
          if (new Date(point.x).valueOf() > Date.parse(dateParams.fromDate)) {

            return {
              //x: (Date.parse(dateParams.fromDate) <= new Date(point.x).valueOf())? new Date(point.x).valueOf() : Date.parse(dateParams.fromDate),
              x: new Date(point.x).valueOf(),
              y: point.y,
            }
          } else return null;
        });

        var dataPointsTimeseries = dataPointsTimeseries1.filter(i => {
          return i != null;
        });
        return Area(
          color,
          dataPointsTimeseries,
          { color: colors[color], tooltip: false, meta: { isTemplate: true } }
        );
      });
      const summaryLineStart = Date.parse(dateParams.fromDate) < templateSeries[0].data.data[0]?.x ? templateSeries[0].data.data[0]?.x : Date.parse(dateParams.fromDate);
      const templateStartTime = timePeriod === 'CUSTOM' ? templateSeries[0].data.data[0]?.x : summaryLineStart;
      // const templateStartTime = templateSeries[0].data.data[0].x ;
      let templateEndingTime;
      if (templateSeries[0].data.data[0]) {
        templateEndingTime = lastItem(templateSeries[0].data.data).x;
      }


      if (awMax < 110) {
        awMax = 110;
      }

      // Dark Blue Area for points > 100
      let overflowCaseDatapoints;
      let negativeCaseDatapoints;
      if (templateSeries[0].data.data[0]) {
        overflowCaseDatapoints = [
          { x: templateStartTime, y: awMax },
          { x: templateEndingTime, y: awMax }
        ];
        templateSeries.push(Area('template-overflow', overflowCaseDatapoints, {
          color: colors.dark_blue,
          tooltip: false,
          meta: { isTemplate: true }
        }));

        if (awMin < 0) {
          negativeCaseDatapoints = [
            { x: templateStartTime, y: awMin },
            { x: templateEndingTime, y: awMin }
          ];
          templateSeries.push(Area('template-underflow', negativeCaseDatapoints, {
            color: colors.red,
            tooltip: false,
            meta: { isTemplate: true }
          }));
        }
      }
      templateAreaCount.current = templateSeries.length;
      const reverse = templateSeries.reverse();
      return reverse;
    }
  }, [highestAWValue, lowestAWValue, templateColorSeries, shouldSkipTemplateLoad, timePeriod, dateParams.fromDate]);


  let templateExists = template.filter((item) => {
    return item.data.data.length > 0;
  });

  let curves = useMemo(() => [...template, ...curvesToRender], [
    template,
    curvesToRender,
  ]);

  // add Y-Axis forEach series
  const curvesYAxis = useMemo(() => {
    if (curves.length === 0) {
      return { min: 0, max: 100, decimalsInFloat: 0 };
    }
    let { min: awTemplateMin, max: awTemplateMax } = yaxisScaleOptions(
      (measurements[0] === 'AW' && displayKey === 'relative' && summaryMin !== null) ? summaryMin : scalesByMeasurement.current?.AW?.min,
      scalesByMeasurement.current?.AW?.max
    );

    if (awTemplateMax < 110) {
      awTemplateMax = 110;
    }

    return curves.map(curve => {
      const seriesName = curve.data.name;
      const [measureShortName] = seriesName.split(' ');
      let lowestYValue;
      if (measurements[0] === 'AW' && displayKey === 'relative') {// && !showRainfallData
        lowestYValue = (siteDetails?.cropTemplate?.objectId !== null &&
          summaryMin !== null &&
          templateExists.length > 0) ? summaryMin : awMinum;
      } else {
        lowestYValue = scalesByMeasurement.current[measureShortName]?.min;
      }

      const highestYValue = (totalChecked && graphMax && measurements[0] === 'AW' && displayKey === 'absolute' && measureShortName === 'AW') ? graphMax : scalesByMeasurement.current[measureShortName]?.max;
      let { min, max, tickAmount, decimalsInFloat } = yaxisScaleOptions(lowestYValue, highestYValue);

      if (measureShortName === 'MOIST' && min < 0 && max > 0) {
        min = 0;
      }

      if (templateAreaCount.current !== 0 && (measureShortName === 'AW' || curve.meta.isTemplate)) {
        if (max < 110) {
          max = 110;
        }
      }

      if (measureShortName === 'RAIN&IRR') {// && min <= 0 // To hide the zero values of rainfall & irrigation curve 
        // min = 0.01;
        min = 0.00;
        max = max + 0.02;
      }

      const tooltip = { enabled: true };
      if (curve.meta?.isTemplate) {
        return hiddenYAxis(seriesName, { min: awTemplateMin, max: awTemplateMax, tooltip })
      } else if (!curve.meta?.hasYAxis) {
        return hiddenYAxis(`${measureShortName} yaxis`, { min, max, tooltip })
      } else {
        return {
          // show: false,
          seriesName,
          opposite: curve.meta.opposite,
          min,
          max,
          decimalsInFloat,
          title: { text: `${curve.meta.unit}` !== '' ? `${curve.meta.label}(${curve.meta.unit})` : `${curve.meta.label}` },
          forceUpdate: Date.now(),
          tickAmount,
          tooltip,
          // dashArray: 0,
          // style: "solid"
        }
      }
    });
  }, [siteDetails?.cropTemplate, curves, graphMax, awMinum, totalChecked, templateExists.length, measurements, summaryMin, displayKey]);

  const minmaxFromStacking = useRef({ min: null, max: null });
  const [zoomY, setZoomY] = useState(0);
  const activeZoomedY = useRef(null);
  useEffect(() => {
    // Y-Axis zooming
    if (zoomY > 0) {
      let zoomedAxis;
      let canZoomIn = true;
      let isStacking = minmaxFromStacking.current.min !== null && minmaxFromStacking.current.max !== null;

      if (isStacking) {

        const start = activeZoomedY.current?.min || minmaxFromStacking.current.min;
        const end = activeZoomedY.current?.max || minmaxFromStacking.current.max;
        const factor = (end - start) / 10;
        const min = start + factor;
        const max = end - factor;
        zoomedAxis = { min, max, show: false };
        activeZoomedY.current = { min, max }

        if (min === max || max < min) {
          canZoomIn = false;
        }

      } else if (activeZoomedY.current === null) {

        activeZoomedY.current = [];
        zoomedAxis = curvesYAxis.map(yaxis => {
          let factor = (yaxis.max - yaxis.min) / 10;
          let min = yaxis.min + factor;
          let max = yaxis.max - factor;
          activeZoomedY.current.push({ min, max });
          return { ...yaxis, min, max };
        });

      } else {
        zoomedAxis = curvesYAxis.map((yaxis, index) => {
          let { min: low, max: high } = activeZoomedY.current[index];
          let factor = (high - low) / 10;
          let min = low + factor;
          let max = high - factor;
          activeZoomedY.current[index] = { min, max };
          if (min === max || max < min) {
            canZoomIn = false;
          }
          return { ...yaxis, min, max };
        });
      }
      if (canZoomIn) {
        updateOptions(UNIQUE_CHART_NAME, { yaxis: zoomedAxis });
      }
    } else if (zoomY === 0 && activeZoomedY.current) {
      updateOptions(UNIQUE_CHART_NAME, { yaxis: curvesYAxis });
      activeZoomedY.current = null;
    }
  }, [zoomY, curvesYAxis])

  // For disabling series from Legend
  const [disabledSeries, setDisabledSeries] = useState([]);


  let graphClass = "col-lg-12 col-md-12 col-12";
  let legendClass = "col-lg-3 col-md-3 col-12 whiteBack moisture-table";

  if (!legendView) {
    graphClass = "col";
    legendClass = "closeLegend";
  }

  const globalGraphControll = useMemo(() => ({
    graphControls,
    measurements: measurementOptions,
    depths: depthOptions,
    dateParams: { fromDate: dateParams.fromDate, toDate: dateParams.toDate }
  }), [graphControls, measurementOptions, depthOptions, dateParams.fromDate, dateParams.toDate]);

  // const startPrefetching = (
  //   !siteDetails.isLoading &&
  //   !isDataLoading.shouldLoadInitialGraph &&
  //   !isDataLoading.shouldLoadInitialTemplate &&
  //   datapointsCache.queued &&
  //   !timePeriod.isEmpty &&
  //   !errors
  // );
  // useEffect(() => {
  //   console.log('inside prefetching')
  //   // prefetch year data once the initial data is loaded
  //   if (startPrefetching) {
  //     setIsDataLoading(state => ({ ...state, prefetching: true }));
  //     const apiCall = graphService.getTwigData();
  //     const dateParams = getDateParams('ONE_YEAR');
  //     apiCall.request(siteid, twigid, dateParams)
  //       .then(data => {
  //         setDatapointsCache(cacheData => {
  //           const newCache = { ...cacheData };
  //           newCache.ONE_YEAR = data.graph;
  //           ['THREE_MONTHS', 'ONE_MONTH', 'ONE_WEEK', 'ONE_DAY'].forEach(tp => {
  //             if (!(tp in cacheData)) {
  //               newCache[tp] = sliceTimeseries(data.graph, getDateParams(tp));
  //             }
  //           });
  //           newCache.cacheCount = 5;
  //           if (newCache.CUSTOM) {
  //             newCache.cacheCount++;
  //           }
  //           newCache.fromDate = Date.parse(dateParams.fromDate);
  //           newCache.toDate = Date.parse(dateParams.toDate);
  //           newCache.queued = false;
  //           return newCache;
  //         });
  //         setIsDataLoading(state => ({ ...state, prefetching: false }));
  //       })
  //       .catch(err => {
  //         if (!http.isCancel(err)) {
  //           console.log(err);
  //           setErrors('Prefetching failed');
  //         }
  //       });
  //     return () => apiCall.cancel('Cancel: Prefetching');
  //   }
  // }, [startPrefetching, siteid, twigid]);

  const storageMiss = dataNotFoundForDateRange.current;
  useEffect(() => {
    if (storageMiss) {
      // fetch the custom range in case its not available on dataPointsCache
      setIsDataLoading(state => ({ ...state, graph: true }));
      const data = props?.data
          setLocalSensor(data.siteDetails.settings.localSensorLocation.value.sensors);
          setHardwareSensor(data.siteDetails.settings.hardwareSensorLocation.value.sensors);
          setIncludedSensors(data.siteDetails.settings?.selectedSensors?.value);
          setCsvData(data.graph);
          setDatapointsCache(cache => {
            if (timePeriod === 'CUSTOM') {
              const CUSTOM = {
                start: dateParams.fromDate,
                end: dateParams.toDate,
                data: data.graph
              }
              return { ...cache, cacheCount: cache.cacheCount + 1, CUSTOM };
            } else {
              return { ...cache, cacheCount: cache.cacheCount + 1, [timePeriod]: data.graph };
            }
           
          });
          setIsDataLoading(state => ({ ...state, graph: false }));
  
    }
  }, [storageMiss, dateParams.fromDate, dateParams.toDate, siteid, twigid, timePeriod, siteDetails, props.data]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   const apiCall = graphService.getRainFallData();
  //   apiCall.request(siteid, { startDate: dateParams.fromDate, endDate: dateParams.toDate })
  //     .then(data => {
  //       let finalRainfallData = [];
  //       for (let i = 0; i < data.length; i++) {
  //         let yValue = parseFloat(data[i]?.yValue?.toFixed(3))> 12 ? 12 : parseFloat(data[i]?.yValue?.toFixed(3));
          
  //         finalRainfallData.push({ x: getTimestamp(data[i]?.xValue), y:  yValue});
  //       }
  //       const filteredRainFallData = finalRainfallData.filter(data => !isNaN(data?.y))
  //       setRainfallData(filteredRainFallData);
  //       setIsDataLoading(state => ({ ...state, graph: false }));
  //     })
  // }, [dateParams.fromDate, dateParams.toDate, siteid]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   const apiCall = graphService.getIrrigationData();
  //   apiCall.request(siteid, { startDate: dateParams.fromDate, endDate: dateParams.toDate })
  //     .then(data => {
  //       let finalIrrigationData = [];
  //       for (let i = 0; i < data.length; i++) {
  //         finalIrrigationData.push({ x: getTimestamp(data[i]?.xValue), y: parseFloat(data[i]?.yValue?.toFixed(3)) });
  //       }
  //       const filteredFinalIrrigationData = finalIrrigationData.filter(data => !isNaN(data?.y))
  //       setIrrigationData(filteredFinalIrrigationData);
  //       setIsDataLoading(state => ({ ...state, graph: false }));
  //     })
  // }, [dateParams.fromDate, dateParams.toDate, siteid]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   const apiCall = graphService.getPredictionData();
  //   apiCall.request(siteid)
  //     .then(data => {
  //       let finalPredictionRainfallData = [];
  //       for (let i = 0; i < data.rainfallData.length; i++) {
  //         finalPredictionRainfallData.push({ x: getTimestamp(data.rainfallData[i].xValue), y: parseFloat(data.rainfallData[i].yValue.toFixed(3)) });
  //       }
  //       const filteredFinalPredictionRainfallData = finalPredictionRainfallData.filter(data => !isNaN(data?.y))
  //       setPredictionRainfallData(filteredFinalPredictionRainfallData);


  //       let finalPredictionWaterData = [];
  //       for (let i = 0; i < data.waterUseData.length; i++) {
  //         finalPredictionWaterData.push({ x: getTimestamp(data.waterUseData[i].xValue), y: parseFloat(data.waterUseData[i].yValue.toFixed(3)) });
  //       }
  //       const filteredFinalPredictionWaterData = finalPredictionWaterData.filter(data => !isNaN(data?.y))
  //       setPredictionWaterUseData(filteredFinalPredictionWaterData);

  //       setIsDataLoading(state => ({ ...state, graph: false }));
  //     })
  // }, [dateParams.fromDate, dateParams.toDate, siteid]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   const apiCall = graphService.getWaterPerlocationnData();
  //   apiCall.request(siteid, { fromDate: dateParams.fromDate, toDate: dateParams.toDate })
  //     .then(data => {
  //       let modifiedWPData = getModifiedWPData(data);
  //       setWaterPercoationData(modifiedWPData);
  //       setIsDataLoading(state => ({ ...state, graph: false }));
  //     })
  // }, [dateParams.fromDate, dateParams.toDate, siteid]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   const apiCall = graphService.getRootActivityDataForSiteId();
  //   apiCall.request(siteid,{plantingDate: plantingDateWithoutUTC})
  //     .then(data => {
  //       let finalRootActivityData = [];
  //       for (let i = 0; i < data.length; i++) {
  //         finalRootActivityData.push({ dateOfFirstRA: data[i].dateOfFirstRA, dateOfFirstRAManual: data[i].dateOfFirstRAManual, sensorId: data[i].sensorId });
  //       }
  //       finalRootActivityData.sort((a,b) => (Number(a.sensorId) - Number(b.sensorId)));
  //       setRootActivityData(finalRootActivityData);
  //       setIsDataLoading(state => ({ ...state, graph: false }));
        
  //     })
  // }, [ siteid, plantingDateWithoutUTC]);

  // useEffect(() => {
  //   setIsDataLoading(state => ({ ...state, graph: true }));
  //   graphService.getNotesForSite(siteid, { fromDate: dateParams.fromDate, toDate: dateParams.toDate })
  //      .then(data => {
  //         let notes = [];
  //         for (let i = 0; i < data.length; i++) {
  //           notes.push({
  //             ...data[i],
  //             x: getTimestamp(data[i].timestamp),
  //             image: {
  //               path: groguruLogo
  //             }
  //           });
  //         }
  //         setPointAnnotation(notes);
  //         updateOptions(UNIQUE_CHART_NAME, { annotations: { points : notes} })
  //         setIsDataLoading(state => ({ ...state, graph: false }));
  //      })
  // }, [dateParams.fromDate, dateParams.toDate, siteid])

  //set stacking
  const [stackLevel, setStackLevel] = useState({ current: null, prev: null });
  const [stackedSeriesCache, setStackedSeriesCache] = useState([]);


  // useEffect(() => {
  //   if (stackLevel.current !== null && stackLevel.current <= MAX_STACK && stackedSeriesCache.length > 0) {

  //     const { colors, updatedSeries, min, max } = stackedSeriesCache[stackLevel.current];
  //     updateSeries(UNIQUE_CHART_NAME, updatedSeries);
  //     if (stackLevel.current === 0) {
  //       // reset y-axis; enable back the template
  //       updateOptions(UNIQUE_CHART_NAME, { colors, yaxis: curvesYAxis, annotations: { yaxis : yAxisAnnotation } })
  //       setStackLevel({ current: null, prev: null });
  //       minmaxFromStacking.current = { min: null, max: null };
  //     }

  //     if (disabledSeries.length > 0) {
  //       toggleSectionVisibility(UNIQUE_CHART_NAME, disabledSeries, 'hide');
  //     }

  //     if (stackLevel.current > 0) {
  //       updateOptions(UNIQUE_CHART_NAME, { colors, yaxis: { show: false, min, max }, annotations: { yaxis : [] } });
  //       minmaxFromStacking.current = { min, max };
  //     }

  //   }
  // }, [stackLevel]);

  useEffect(() => {
    // reset stacking on tab change etc
    if (stackLevel.current !== null) {
      setStackLevel({ current: null, prev: null });
    }
    // reset yZoom on measurement change
    setZoomY(0);
    activeZoomedY.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements]);

  useEffect(() => {
    // reset the disabled chart if series selections change
    if (disabledSeries.length > 0) {
      resetChart(UNIQUE_CHART_NAME);
      setDisabledSeries([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements]);// curves

  const prevMeasurements = useRef(null);
  useEffect(() => {
    // force y-axis when displayOption, dataType, .. are changed without changing measurements
    const shouldResetSeries = (
      prevMeasurements.current &&
      prevMeasurements.current === measurements &&
      curvesToRender.length > 0
    );

    if (shouldResetSeries) {
      // force reset the chart options & series
      setStackLevel(prevState => ({ current: 0, prev: prevState.current }))
    }

    return () => {
      prevMeasurements.current = measurements;
    }
  }, [measurements, dataType, displayKey, curvesYAxis, curvesToRender]);

  useEffect(() => {
    // precalculate all the stacked up levels of the graph data
    const normalisedSeries = applyCommonScale(curvesToRender.filter(series => !series.meta.isHidden && !series.meta.isTemplate), scalesByMeasurement.current)
    let computedSeriesList = Array
      .from({ length: MAX_STACK }, (_, i) => i + 1)
      .map(i => {
        const seriesDataAndColors = applyStackLevel(normalisedSeries, i);
        let minmax;
        for (const series of seriesDataAndColors.updatedSeries) {
          minmax = getDatapointsMinMax(series?.data, minmax);
        }
        return { ...seriesDataAndColors, ...minmax };
      });
    setStackedSeriesCache([
      { updatedSeries: curves.map(c => c.data), colors: curves.map(c => c.color) },
      ...computedSeriesList
    ]);
  }, [curves, curvesToRender]);

  const currentDisabledLines = useRef([]);
  useEffect(() => {
    currentDisabledLines.current = disabledSeries;
  }, [disabledSeries]);


  const [templateList, setTemplateList] = useState({ list: [], loaded: false });

  const isPrefetchCompletionRequired = (
    isDataLoading.prefetching &&
    timePeriod !== 'CUSTOM' &&
    timePeriod !== savedTimePeriodOption
  );
  const isCustomFetchLoading = timePeriod === 'CUSTOM' && (
    dateParamsOnDisplay.current.fromDate !== dateParams.fromDate ||
    dateParamsOnDisplay.current.toDate !== dateParams.toDate
  )
  const graphIsLoading = isCustomFetchLoading || isDataLoading.graph || isPrefetchCompletionRequired;

  useEffect(() => {
    var duration = globalGraphControll.graphControls.timePeriod.get;
    var graphValues = datapointsCache[duration];
    if (graphValues?.v_bandgap && graphValues.v_bandgap !== null && graphValues.v_bandgap.absolute.length !== 0) {
      const battery = graphValues.v_bandgap.absolute[0].yValue;
      props.updateBattery(battery);
    } else {
      props.updateBattery(null);
    }
    if (!graphIsLoading) {
      if (graphValues?.rssi && graphValues.rssi !== null && graphValues.rssi.absolute.length !== 0) {
        const rss = graphValues.rssi.absolute[0].yValue;
        const rssDate = graphValues.rssi.absolute[0].xValue;
        var now = new Date().toISOString();

        const nowConvDate = new Date(now);
        const rssConvDate = new Date(rssDate);
        var res = Math.abs(nowConvDate - rssConvDate) / 1000;

        // get total days between two dates
        var days = Math.floor(res / 86400);

        // get hours        
        // var hours = Math.floor(res / 3600) % 24;

        if (days > 1) {
          props.updateRss("no value");
        } else {
          props.updateRss(rss);
        }

      } else {
        props.updateRss("no value");
      }
    }

  }, [globalGraphControll, datapointsCache, props, graphIsLoading]);









  let showMessage = null;
  if (!errors && !isDataLoading.shouldLoadInitialGraph) {
    if (curvesToRender.length === 0) {
      showMessage = ERROR.NO_DATA_FOR_LAST_30_DAYS;
    } 
    // else if (!plantingDate && isDataLoading.shouldLoadInitialTemplate && siteDetails?.twigDetails?.settings?.hardwareType?.value !== 'Pressure Transducer') {
    //   showMessage = ERROR.NO_PLANTING_DATE;
    // }
  }
  const stackingControls = useMemo(() => [
    { icon: '<i class="fa fa-plus" aria-hidden="true"></i>', title: 'Stack Plus', class: 'counterplus-btn', click: () => setStackLevel(prevState => ({ current: prevState.current < MAX_STACK ? prevState.current + 1 : prevState.current, prev: prevState.current })), index: 0 },
    { icon: '<i class="fa fa-minus" aria-hidden="true"></i>', title: 'Stack Minus', class: 'counterminus-btn', click: () => setStackLevel(prevState => ({ current: (prevState.current > 0 ? prevState.current - 1 : prevState.current), prev: prevState.current })), index: 1 },
  ], [siteid, siteDetails, displayKey, dateParams.fromDate, dateParams.toDate, dataType, curves]);
  // let tableHeads = [
  //   "Date",
  //   "Sensor Id",
  //   "Root Activity Prediction",
  //   "RA (Manual)"
  // ].map((i) => ({ text: i }));

  return (
    <React.Fragment >
      <GraphLoader
        graphIsLoading={graphIsLoading && siteDetails.siteId}
        templateIsLoading={!shouldNotViewTemplate && isDataLoading.template} />
      { isDataLoading.prefetching && <small className="prefetching">Prefetching ...</small>}
      {
        ((errors || showMessage)) && 
        // <StickyMessage
        //   variant={errors ? 'error' : null}
        //   message={errors || showMessage} />
        <div className="alert-popup"><h6 className='alert-message'>{showMessage}</h6></div>
      }
      <div className="graph-body-container">
        <div className="row">
          <div className={graphClass}>
            <div className="vertical-line">
              {/* <div className="header-text border-bottom-black d-flex justify-content-between">
                <GraphNavBar
                  siteId={siteid}
                  measurementOptions={measurementOptions}
                  measurements={measurements}
                  setMeasurements={setMeasurements}
                  dataPoints={dataPoints}
                  plan={siteDetails?.planDetails?.plan}
                  setSubscriptionAlert={setSubscriptionAlert}
                  subscriptionAlert={setSubscriptionAlert}
                  userRole={props.userRole}
                  onTabChangeCalled={onTabChangeCalled}
                />
                {siteDetails?.twigDetails?.settings?.hardwareType?.value === "Pressure Transducer" ? null :
                  <div className="d-flex align-items-center">
                    <TemplateSettings
                      templateList={templateList}
                      setTemplateList={setTemplateList}
                      cropType={cropType}
                      fromDate={dateParams.fromDate}
                      appliedTemplate={templateId}
                      applyTemplate={setTemplateId}
                      siteObjectId={siteObjectId}
                      setIsDataLoading={setIsDataLoading}
                      savePreference={true} />
                    <ArrowToggle
                      isArrowDirLeft={legendView}
                      onChange={toggleLegendView} />
                  </div>
                }
              </div> */}

              <div id="main-chart-area" className="graph-region" style={{height:200}}>
                {/* <div className="d-flex" style={{ flexFlow: "wrap", flexDirection: "row-reverse" }}>
                  <label className="ml-5">
                    <div className="first-option d-flex">
                      <label className="label ">
                        <input type="checkbox" name="graphDisplay"
                          defaultChecked={graphControls.displayOptions.get === 'relative'}
                          onChange={(e) => onrelativeChange('displayOptions', e)} />
                        <span className="checkmark"></span>
                      </label>
                      <label className="optionFont">Relative</label>
                    </div>
                  </label>
                 
                
                  {
                    showRainAndIrrOptions
                      ? (
                        <>
                          <label className="ml-5">
                            <div className="first-option d-flex">
                              <label className="label ">
                                <input type="checkbox" name="rainfall"
                                  defaultChecked={rainfallData.length > 0 && showRainfallData}
                                  onChange={(e) => onRainfallChange('rainfall', e)} />
                                <span className="checkmark"></span>
                              </label>
                              <label className="optionFont" title="Rainfall">Rainfall</label>
                            </div>
                          </label>
                          <label className="ml-5">
                            <div className="first-option d-flex">
                              <label className="label ">
                                <input type="checkbox" name="appliedIrrigation"
                                  defaultChecked={irrigationData.length > 0 && showIrrigationData}
                                  onChange={(e) => onAppliedIrrigationChange('appliedIrrigation', e)} />
                                <span className="checkmark"></span>
                              </label>
                              <label className="optionFont" title="Applied Irrigation">App Irrigation</label>
                            </div>
                          </label>                         
                        </>) : null
                  }
                  {(siteDetails?.planDetails?.plan === "Gold" || props.userRole === "groguru_admin") && (measurements[0] === "AW" && dataPoints?.gddEnvelope?.absolute?.length > 0) ?
                    <label className="ml-5">
                      <div className="first-option d-flex">
                        <label className="label ">
                          <input type="checkbox" name="graphDisplay"
                            checked={growthStage}
                            onChange={(e) => setGrowthStage(!growthStage)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="optionFont">Growth Stages</label>
                      </div>
                    </label> : ""
                  }

                  {(siteDetails?.planDetails?.plan === "Gold" || props.userRole === "groguru_admin") && (measurements[0] === "AW") && currentTab === "AW" ?
                    <label className="ml-5">
                      <div className="first-option d-flex">
                        <label className="label ">
                          <input type="checkbox" name="predictedWaterUse"
                            defaultChecked={showPredictedData}
                            onChange={(e) => setShowPredictedData(!showPredictedData)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="optionFont" title="Predicted">Predicted</label>
                      </div>
                    </label> : ""
                  }
                  {showContextmenu ? (
                    <label className="ml-5">
                      <div className="first-option d-flex">
                        <label className="label ">
                          <input type="checkbox" name="notes"
                            defaultChecked={showNotes}
                            checked={showNotes}
                            onChange={(e) => notesSelect(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="optionFont" title="Notes">Notes</label>
                      </div>
                    </label>) : null
                  }
                  {isStackedView && <small className="stackedView">Stacked View</small>}
                </div> */}
                {/* {console.log(MAX_DATE, 'UNIQUE_CHART_NAME', UNIQUE_CHART_NAME, isMax)} */}
                <Chart
                  keys={legendView.toString()}
                  id={UNIQUE_CHART_NAME}
                  series={curves}
                  yAxis={curvesYAxis}
                  xAxisLabel={chartTooltipXAxisFormatting}
                  zoomType="xy"
                  hideToolBar={true}
                  isGraphThumbnail={true}
                  // customTooltip={customTooltip}
                  tools={''}
                  annotations={annotations}
                  yAxisAnnotation={yAxisAnnotation}
                  pointAnnotation={showContextmenu && showNotes ? pointAnnotation : []}
                  // maxDate = {isMax}
                  // showAddNote={showAddNoteModal}
                  // showSelectedNote={showSelectedNote}
                  // showContextmenu={showContextmenu && showNotes}
                />
                {/* <DoneModal
                  buttonLabel="Ok"
                  show={subscriptionAlert}
                  onClose={closeDialogue}
                  isImageNotRequired={true}
                  data={{ title: 'Upgrade Subscription', message: 'Please contact your dealer to subscribe to this feature' }} /> */}
              </div>
            </div>
          </div>
          {/* <div className={legendClass}>
            <GraphLegend
              mode={displayKey}
              tab={currentTab}
              includedSensorsList={includedSensors}
              totalChecked={setTotalChecked}
              plan={siteDetails?.planDetails?.plan}
              csvdata={csvData}
              hardwareSensors={hardwareSensor}
              localSensors={localSensor}
              siteId={siteid}
              twigId={twigid}
              forceUpdateGraphData={forceUpdateGraphData}
              legendPoints={legendPoints}
              curvesToRender={curvesToRender}
              globalGraphControll={globalGraphControll}
              disabledSeries={disabledSeries}
              dataTypesCount={dataType?.length}
              notifySeriesToggled={notifySeriesToggled}
              notifySeriesListToggled={notifySeriesListToggled}
              measurements={measurements}
              notes={pointAnnotation}
              siteObjectId={siteObjectId}
              showNotes={showNotes}
              setShowNotes={setShowNotes}   
            />
            {measurements[0] === 'GDD' ? <div className="recalculate-envelop"><button onClick={recomputeEnvelop}>Recalculate Envelope</button></div> : null}
          </div> */}
        </div>
      </div>
      {/*  { (siteDetails.siteId !== undefined && !showGraph) ? <div>Kindly subscribe to a plan by clicking on to <b>Configuration + Subscription.</b></div> : null} */}
      {/* {
        curvesToRender[0]?.data?.name.includes('MOIST') ?
          <>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <IrrigationTable
                  title="Water Percolation"
                  data={waterPercoationData}
                  secondColumnHeader="WP (auto)"
                  thirdColumnHeader="WP (manual)"
                  hardwareSensorLocation={siteDetails?.twigDetails?.settings?.hardwareSensorLocation}
                  siteId={siteid}
                  updateWPData = {(apibody) => updateWPData(apibody)}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <IrrigationTable
                  title="Applied Irrigation"
                  data={irrigationData}
                  secondColumnHeader="Applied Irrigation (inches)"
                />
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <IrrigationTable
                  title="Rainfall"
                  data={rainfallData}
                  secondColumnHeader="Rainfall (inches)"
                />
              </div>
            </div>
            <div className="row">
              <div className="irrigation-table col-lg-4 col-md-4 col-12">
                <IrrigationTable
                  title="Root Activity"
                  data={rootActivityData}
                  secondColumnHeader="RA (auto)"
                  thirdColumnHeader="RA (manual)"
                  plantingDate={plantingDate}
                  siteId={siteid}
                />
              </div>
            </div>
          </>
          : null
      } */}
      <Modal
        show={showAddNote}
        onHide={onCloseNoteModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Input
               name="x"
               label="Date :"
               value={selectedNote.x}
               onChange={handleNoteChange}
               type="datetime-local"
            />
            <Input
               name="note"
               label="Note :"
               value={selectedNote.note}
               onChange={handleNoteChange}
               error={showNoteError ? "Please enter note" : ""}
            />
        </Modal.Body>
        <Modal.Footer className="d-flex flex-nowrap">
          <button className="btn-submit mr-20" onClick={onCloseNoteModal}>Cancel</button>
          <button className="btn-submit mr-20" onClick={addNote} disabled={isSaving || showNoteError}>
            {isSaving ? <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : ""}
            Add
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showViewNote}
        onHide={onCloseNoteModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
              <span className="h6">Date : </span>
              <span>{moment(selectedNote.x).format("MM/DD/YY HH:mm")}</span>
            </div>
            <div style={{ wordWrap: "break-word"}}>
              <span className="h6">Note : </span>
              <span>{selectedNote.note}</span>
              <button onClick={removeNote} title="Delete note" style={{border: 'none', background: 'white', marginLeft: '2%'}} disabled={isSaving} >
                  { isSaving ? <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> : "" }
                  <i className="fas fa-trash-alt"></i>
              </button>
            </div>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-nowrap">
          <button className="btn-submit mr-20" onClick={onCloseNoteModal}>Close</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment >
  );
}
const mapStateToProps = state => ({
  getBatteryState: state.userDetails.userPreferences.batteryState,
  getRssState: state.userDetails.userPreferences.rssState,
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
});
const mapDispatchToProps = {
  updateBattery: userActions.updateBatteryState,
  updateRss: userActions.updateRssState,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphThumbnail);
