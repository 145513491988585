import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import GForm from "../common/form";
import createFarm from "../../assets/images/farms/farm-create/group-2.png";
import greenMarker from "../../assets/images/farms/farm-create/marker-green.png";
import Joi from "joi-browser";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import Message from "../common/messages";
import FileUpload from "../common/fileUpload";
import farmService from "./duck/farmService";
import { connect } from "react-redux";
import farmActions from "./duck/farmActions";

const defaults = {
    zoom: 4,
    options(maps) {
        return {
            maxZoom: 18,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            }
        }
    },
};

const markerStyle = {
    position: 'absolute',
    transform: 'translate(-30%, -50%)',
    height: "30px"
}

const Marker = () => <div><img src={greenMarker} style={markerStyle} alt="farm location marker" /></div>;
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
Geocode.setApiKey(key);
Geocode.setLanguage("en");

class FarmForm extends GForm {
    state = {
        data: {
            name: "",
            mailingAddress: "",
            physicalAddress: "",
            latitude: '',
            longitude: '',
            businessId: "",
            fileId: ""
        },
        errors: {},
        serviceError: null,
        show: false,
        businesses: []
    }
    schema = {
        name: Joi.string().required().label('Farm Name'),
        mailingAddress: Joi.string().allow('').optional(),
        physicalAddress: Joi.string().required().label('Farm Physical Address'),
        latitude: Joi.number().required().label('Latitude'),
        longitude: Joi.number().required().label('Longitude'),
        businessId: (this.props.profileDetails?.profile?.organisation?.type !== 'business')
            ? Joi.string().required().label('Business Organisation') : Joi.string().allow('').optional(),
        fileId: Joi.string().allow('').optional()
    };

    reverseGeoCodeFunc = null;

    static defaultProps = {
        center: {
            lat: 8.3,
            lng: 77.5
        },
        zoom: defaults.zoom
    };


    toggleModal = (value) => {
        if (!value) {
            const data = {
                name: "",
                mailingAddress: "",
                physicalAddress: "",
                latitude: 0.0,
                longitude: 0.0,
                businessId: "",
                fileId: ""
            }
            this.setState({ show: value, data });
        }
        this.setState({ show: value });
    }

    handleMapClick = ({ lat, lng, event }) => {
        const data = { ...this.state.data };
        data.latitude = lat;
        data.longitude = lng;
        this.setState({ data });
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                data.physicalAddress = address;
                this.setState({ data });
            },
            error => {
                this.setState({ serviceError: error.message });
            }
        );
    }

    handleUploadSuccess = (data) => {
        this.setState({ data: { ...this.state.data, fileId: data.objectId } });
    }

    handleUploadError = (error) => {
        this.setState({ serviceError: error.message });
    }

    doSubmit = () => {


        const { latitude, longitude, ...payload } = this.state.data;
        payload.location = {
            lat: latitude,
            lng: longitude
        }
        this.setState({ isCreating: true })

        if (this.props.sortType === "Recently Added") {
            this.props.createFarm(payload, 'createdAt').then(() => this.toggleModal(false));
        } else {
            this.props.createFarm(payload).then(() => this.toggleModal(false));
        }
    }

    handleOptionChange = (name, value) => {
        if (name === 'physicalAddress' || name === 'mailingAddress') {
            if(name === 'mailingAddress'){
                this.setState({ data: { ...this.state.data, physicalAddress: value, mailingAddress: value } });
            }
            if (this.reverseGeoCodeFunc) {
                clearTimeout(this.reverseGeoCodeFunc);
                this.reverseGeoCodeFunc = null;
            }
            this.reverseGeoCodeFunc = setTimeout(() => this.getLatLngFromAddress(value), 1000);
        }
    }

    getLatLngFromAddress = (address) => {
        Geocode.fromAddress(address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;

                if (lat && lng) {
                    this.setState({ data: { ...this.state.data, latitude: lat, longitude: lng } })
                    this.mapPanTo({ lat, lng });
                }
            },
            error => {
                console.error(error);
            }
        );
    }
    loadApiMethods = (map, maps) => {
        if (map) {
            this.mapPanTo = map.panTo.bind(map);
        }
    };

    componentDidMount() {
        farmService.getBusinessesUnderOrg().then(businesses => {
            businesses.unshift({ objectId: "", name: "Select a business to link this farm" });
            this.setState({ businesses });
        }).catch(error => {
            this.setState({ serviceError: error.message });
        })
    }

    render() {
        const { profile } = this.props.profileDetails;
        return (
            <div className="create-farm">
                {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                    this.props.profileDetails.profile.role.name !== 'business_user' &&
                    this.props.profileDetails.profile.role.name !== 'farm_user') ?
                    <Button onClick={() => {
                        this.toggleModal(true)
                    }}
                        variant="none"
                        className="create-farm-btn">
                        Create New Farm +
                </Button> : null}
                <Modal className="create-farm-modal" show={this.state.show} size="lg" centered
                    onHide={() => {
                        this.toggleModal(false); this.setState({ errors: '' })
                    }}>
                    <Modal.Header closeButton={false}>
                        <p>Create a New Farm </p>
                        <span className="text-right cp" onClick={() => {
                            this.toggleModal(false); this.setState({ errors: '' })
                        }}>Close</span>
                    </Modal.Header>
                    <Modal.Body>
                        <FileUpload defaultImage={createFarm} label="Tap to Add Farm Logo +"
                            onUploadSuccess={this.handleUploadSuccess} onUploadError={this.handleUploadError}
                            imageAlt="farm logo"></FileUpload>

                        <Form className="mt-20 mb-10">

                            {this.renderFormGroup('name', 'Farm Name', 'text',
                                { placeholder: 'Enter farm name here' })}
                            {this.renderFormGroup('mailingAddress', 'Farm Mailing Address', 'text',
                                { placeholder: 'Enter farm mailing address here' })}
                            {profile.organisation?.type !== 'business' &&
                                this.renderSelect('businessId', 'Business Organisation',
                                    this.state.businesses.map(business => {
                                        return { _id: business.objectId, name: business.name };
                                    }))}
                            <h3 className="map-details mt-23">Map Details</h3>
                            <p className="details">
                                Type in the physical address of your farm, center the map on
                                your farm, or type in the Latitude/Longitude. All fields will
                                auto update (e.g. if you put the marker on the map, the physical address text
                                field and lat/long fields will update as well).
                            </p>

                            {this.renderFormGroup('physicalAddress',
                                'Farm Physical Address (if different than mailing address)', 'text',
                                { placeholder: 'Enter farm physical address here' })}

                            <div className="maps">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key }}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}
                                    options={defaults.options}
                                    onChange={this.handleMapChange}
                                    onClick={this.handleMapClick}
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    onGoogleApiLoaded={({ map, maps }) => this.loadApiMethods(map, maps)}>
                                    <Marker lat={this.state.data.latitude}
                                        lng={this.state.data.longitude}></Marker>
                                </GoogleMapReact>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    {this.renderFormGroup('latitude', 'Latitude', 'text')}
                                </div>

                                <div className="col-md-6">
                                    {this.renderFormGroup('longitude', 'Longitude', 'text')}
                                </div>
                            </div>
                            {this.renderButton('Submit', 'btn-submit btn',
                                this.props.farms.isCreating, "Creating Farm..")}
                            {this.state.serviceError &&
                                <Message variant="error" message={this.state.serviceError} />}
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    createFarm: farmActions.createFarm
};

const mapStateToProps = (state) => {
    const { userDetails, farms } = state;
    const profileDetails = userDetails.profileDetails;
    return { profileDetails, farms }
}

const connectedFarmForm = connect(mapStateToProps, mapDispatchToProps)(FarmForm);
export { connectedFarmForm as FarmForm };
