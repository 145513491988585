import React from "react";
import { Card, Form } from "react-bootstrap";
import GForm from "../../../common/form";
import siteService from "../../duck/siteService";
import templateService from "../template/duck/templateService";
import { connect } from "react-redux";
import Joi from "joi-browser";
import configService from "../configuration/duck/configService";
import { IrrigationInfo } from './irrigationInfo';
import DatePicker from "react-datepicker";
import * as moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

class AgronomicInfo extends GForm {
    componentDidMount() {
        configService.getType('PlantType').then((res) => {
            this.setState({ planttypes: res });
        });

        configService.getType('SoilType').then((res) => {
            this.setState({ soiltypes: res });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.siteDetails !== prevProps.siteDetails) {
            this.setState({
                data: {
                    soilType: this.props.siteDetails?.twigDetails?.soilType,
                    plantType: this.props.siteDetails?.twigDetails?.plantType
                },
                plantingDate: this.props.siteDetails?.twigDetails?.plantingDate,
                installByDate: this.props.siteDetails?.twigDetails?.installByDate,
                id: this.props.siteDetails.id
            })
        }
    }
    state = {
        data: {
            plantType: this.props.siteDetails?.twigDetails?.plantType || '',
            soilType: this.props.siteDetails?.twigDetails?.soilType || '',
        },
        errors: {},
        serviceError: null,
        id: this.props.siteDetails.id || null,
        plantingDate: this.props.siteDetails?.twigDetails?.plantingDate || null,
        installByDate: this.props.siteDetails?.twigDetails?.installByDate || null,
        planttypes: '',
        soiltypes: '',
        disabled: true,
        date: null,
        date1: null,
        showError: false,
    }

    schema = {
        plantType: Joi.any().allow(null),
        soilType: Joi.any().allow(null)
    };

    doSubmit = () => {
        if (!this.state.disabled && (this.state.installByDate !== null && this.state.installByDate !== undefined && this.state.installByDate !== '')) {
            const siteobjid = this.state.id

            const { data } = this.state;
            const dataSent = { ...data, plantingDate: this.state.plantingDate, installByDate: this.state.installByDate }
            siteService.updateSite(siteobjid, dataSent).then(response => {
                this.props.setChangeLogs(false)
                const { updatedAt, settings } = response.data.parsedTwig;
                const plantType = settings.plantType.value;
                const soilType = settings.soilType.value;
                const plantingDate = settings.plantingDate.value;
                const lastUpdated = new Date(updatedAt).valueOf();
                const installByDate = settings.installByDate.value;
                const templateSet = this.props.siteDetails?.cropTemplate?.objectId;
                const prevPlantType = this.props.siteDetails.twigDetails?.plantType;

                if (templateSet && prevPlantType !== plantType) {
                    templateService.putTemplateData(siteobjid, { cropTemplate: null });
                    this.props.onSiteDataChange({ cropTemplate: { objectId: null } });
                }
                this.props.setChangeLogs(true)

                this.props.onTwigDetailsChange({ plantType, plantingDate, lastUpdated, soilType, installByDate });
            });
        }
    }


    handledisable = () => {
        this.setState({ disabled: !this.state.disabled })
    }

    handleDate = (date, name) => {
        let datestring = moment(date).toDate();
        let local = moment(datestring).local().format("MM/DD/YYYY");
        let datastr = moment(local, "MM/DD/YYYY", true).isValid();

        if (!datastr) {
            if (name === "installByDate") {
                let apiInstallByDate = this.props.siteDetails?.twigDetails?.installByDate;
                if (apiInstallByDate !== null) {

                    let datestring1 = moment(apiInstallByDate).toDate();
                    let local1 = moment(datestring1).local().format("MM/DD/YYYY");
                    this.setState({ date: datestring1, installByDate: local1, showError: true });

                    setTimeout(() => {
                        this.setState({ showError: false });
                    }, 2000);
                } else {
                    this.setState({ date: '', installByDate: null, showError: true });
                }
            }
            else {
                this.setState({ date1: '', plantingDate: null });
            }
        }
        else {
            if (name === "installByDate") {
                let plant = { ...this.state }
                this.setState({ date: datestring, installByDate: local, plantingDate: plant.plantingDate, showError: false })
            }
            else {
                let install = { ...this.state }
                this.setState({ date1: datestring, plantingDate: local, installByDate: install.installByDate })
            }
        }
    }

    render() {

        const currentdate = new Date();
        const maxdate = new Date(currentdate.setYear(currentdate.getFullYear() + 2));

        const formOptions = this.state.planttypes &&
            this.state.planttypes.map((type, index) => ({ name: Object.values(type)[0], _id: Object.values(type)[0] }));

        const Options = this.state.soiltypes &&
            this.state.soiltypes.map((type, index) => ({ name: Object.values(type)[0], _id: Object.values(type)[0] }))


        const selected = this.state.plantingDate !== null && this.state.plantingDate;
        const selectedDate = selected && selected !== undefined && new Date(selected);

        const selected_installDate = this.state.installDate !== null && this.state.installByDate;
        const selectedinstallDate = selected_installDate && selected_installDate !== undefined && new Date(selected_installDate);

        return (
            <div className="basic-info-container">
                <Card.Title className="d-flex justify-content-between">
                    <div>Agronomic Information</div>
                    {(this.props.profileDetails.profile.role.name !== 'dealer_user' &&
                        this.props.profileDetails.profile.role.name !== 'business_user' &&
                        this.props.profileDetails.profile.role.name !== 'farm_user')
                        ? (<div onClick={this.handledisable}>
                            {this.renderButton(this.state.disabled ? 'Edit' : 'Save', { className: "save-btn" })}
                        </div>) : null}

                </Card.Title>
                <hr></hr>

                <Form>
                    <div className="row">
                        <div className="col-lg-3 col-md-4 mt-10 datePickerHead">
                            <h4>Install Date</h4>
                            <DatePicker
                                className="aboutDatePicker"
                                selected={selectedinstallDate && selectedinstallDate}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => this.handleDate(date, 'installByDate')}
                                disabled={this.state.disabled}
                                placeholderText={this.state.installByDate !== null ? this.state.installByDate : ''}
                                maxDate={maxdate}
                            />
                            {(this.state.showError === true) && (<h4 className="error-msg">Install date should have a value.</h4>)}
                        </div>
                        <div className="col-lg-3 col-md-4 mt-10 datePickerHead">
                            <h4>Planting Date</h4>
                            <DatePicker
                                className="aboutDatePicker"
                                selected={selectedDate && selectedDate}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => this.handleDate(date, 'plantingDate')}
                                disabled={this.state.disabled}
                                placeholderText={this.state.plantingDate !== null ? this.state.plantingDate : ''}
                                maxDate={maxdate}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            {this.renderSelect("plantType", "Crop Type", formOptions,
                                { placeholder: this.state.data.plantType !== null ? this.state.data.plantType : '', disabled: this.state.disabled })}
                        </div>
                        <div className="col-lg-3 col-md-6">
                            {this.renderSelect("soilType", "Soil Type", Options,
                                { placeholder: this.state.data.soilType !== null ? this.state.data.soilType : '', disabled: this.state.disabled })}

                        </div>
                    </div>
                </Form>

                <IrrigationInfo siteDetails={this.props.siteDetails} onTwigDetailsChange={this.props.onTwigDetailsChange} setChangeLogs={this.props.setChangeLogs} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { userDetails } = state;
    const profileDetails = userDetails.profileDetails;
    return { profileDetails }
}

const connectedSiteBasicInfo = connect(mapStateToProps)(AgronomicInfo);
export { connectedSiteBasicInfo as AgronomicInfo };
