import {connect} from 'react-redux';

function UserHasShopAccess({isDirectGroguruChild, children, fallback = null}) {
    if (isDirectGroguruChild === true) {
        return children;
    } else if (isDirectGroguruChild === undefined) {
        return null;
    }
    return fallback;
}

const mapStateToProps = state => ({
    isDirectGroguruChild: state.userDetails?.userPrivilages?.isDirectGroguruChild
})

export default connect(mapStateToProps)(UserHasShopAccess);