import React, {Component} from "react";
import {connect} from "react-redux";
import {Nav} from "react-bootstrap";
import farm from "../../assets/images/farms/myfarrm/shape.png";
import contact from "../../assets/images/farms/contact/contactless-payment.png";
import shop from "../../assets/images/farms/shop/group-2.png";
import team from "../../assets/images/farms/team/shape.png";
import logo from "../../assets/images/common/groguru-long-logo.png";
// import farmDefaultImage from "../../assets/images/farms/farm-default-icon.png";
import logout from "../../assets/images/dashboard/logout/exit-to-app.png";
import placeholderImage from "../../assets/images/placeholder/group.png";
import closeButton from "../../assets/images/left-arrow/close-1.png";
import { Link } from "react-router-dom";
import UserHasShopAccess from '../shop/UserHasShopAccess';

class Menu extends Component {

    state = {
        menuOpen: false,
        image: null
    };

    backdropClass = "backdrop";
    
    toggleMenu = () => {
        this.setState({menuOpen: true});
    }

    closeMenu = () => {
        this.setState({ menuOpen: false});
    };
    
    render() {
        const {profile} = this.props.profileDetails;
        // const {image} = this.props.farmdetails;

        return (

            <div className="harmburger-menu cp">
 
                <span className="icon-menu" onClick={this.toggleMenu}></span>
                <div
                    id="mySidebar"
                    className={`sidebar ${this.state.menuOpen ? "menu-active" : ""}`}>
                    <div className="menu-content">
                            <div className="sideMenuCloseButton">
                                <img src={closeButton} alt= " " onClick={this.closeMenu}></img>
                            </div>
                            
                            <div className="profile-img">
                                <img
                                    src={profile.profileImage || placeholderImage}
                                    alt="account-img"
                                ></img>
                            </div>
                       
                        <div className="name-tag">
                            <h3>{profile.username} </h3>
                            <p>{profile.role.name}</p>
                        </div>
                        <div className="menu">
                            <p>Menu</p>
                            <Nav className="flex-column">
                                <Nav.Link as={Link} to="/dashboard/farms" className="mt-25">
                                    <img src={farm} alt="go to farms list"></img>
                                    <span>Farms</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/dashboard/groups" className="mt-40">
                                    <img src={team} alt="go to groups"></img>
                                    <span>Accounts</span>
                                </Nav.Link>
                                {profile.role?.name === 'groguru_admin' ?
                                <UserHasShopAccess>
                                    <Nav.Link as={Link} to="/dashboard/shop/subscriptions" className="mt-40">
                                        <img src={shop} alt="go to Subscription"></img>
                                        <span>Subscription</span>
                                    </Nav.Link>
                                </UserHasShopAccess> : null }
                                <Nav.Link as={Link} to="/dashboard/contact" className="mt-40">
                                    <img src={contact} alt="go to contact form"></img>
                                    <span>Contact Us</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/dashboard/help" className="mt-40">
                                    <i class="fas fa-info"></i>
                                    <span> Help</span>
                                </Nav.Link>
                               
                                <a  href="/logout" className="mt-40"   >
                                    <img src={logout} alt="go to contact form"></img>
                                    <span >Log Out</span>
                                   
                                </a>

                            </Nav>
                        </div>
                        <div className="logo">
                            <img src={logo} alt="logo"></img>
                        </div>
                    </div>
                </div>
                <div id="main">
                    {/* blank area need to close the menu for any click over here */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profileDetails: state.userDetails.profileDetails
});

export default connect(mapStateToProps)(Menu);
