import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import SpinnerButton from '../common/spinnerButton';

function CardElementForm({show, onClose, onSubmit = (token) => {}, buttonLabel}) {

    const stripe = useStripe();
    const elements = useElements();
    const [sending, setSending] = useState(false);
    
    
    async function handleCard() {
        if (stripe) {
            const cardElement = elements.getElement(CardElement);
            setSending(true);
            const {error, token} = await stripe.createToken(cardElement);
            if (!error) {
                onSubmit(token.id);
            } else {
                setSending(false);
            }
        }
    }
    return (show &&
        <React.Fragment>
            
            {onClose && <button onClick={onClose}>x</button>}
     
           <CardElement/>
       
            <SpinnerButton onClick={handleCard} spinning={!stripe || sending}>{buttonLabel}</SpinnerButton>
           
        </React.Fragment>
    )
}

CardElementForm.defaultProps = {
    onSubmit: (token) => {},
    buttonLabel: 'Pay',
    show: true,
    onClose: false
}

export default CardElementForm;