// Alfalfa
import Alfalfa from '../../../assets/images/plantAndRoot/Alfalfa/Alfalfa.PNG';

// Almond
import AlmondStages19 from '../../../assets/images/plantAndRoot/Almond/AlmondStages1-9.PNG';
import AlmondStage10Onwards from '../../../assets/images/plantAndRoot/Almond/AlmondStage10Onwards.PNG';

// Citrus
import CitrusStage0 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage0.PNG';
import CitrusStage1 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage1.PNG';
import CitrusStage1Point5 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage1.5.PNG';
import CitrusStage2 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage2.PNG';
import CitrusStage3 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage3.PNG';
import CitrusStage4 from '../../../assets/images/plantAndRoot/Citrus/CitrusStage4.PNG';

// Corn
import Corn0VE from '../../../assets/images/plantAndRoot/Corn/Corn0VE.png';
import Corn1V1V2 from '../../../assets/images/plantAndRoot/Corn/Corn1V1-V2.png';
import Corn2V3V5 from '../../../assets/images/plantAndRoot/Corn/Corn2V3-V5.png';
import Corn3V6V8 from '../../../assets/images/plantAndRoot/Corn/Corn3V6-V8.png';
import Corn4V9V12 from '../../../assets/images/plantAndRoot/Corn/Corn4V9-V12.png';
import Corn5VT from '../../../assets/images/plantAndRoot/Corn/Corn5VT.png';
import Corn6R1 from '../../../assets/images/plantAndRoot/Corn/Corn6R1.png';
import Corn7R2 from '../../../assets/images/plantAndRoot/Corn/Corn7R2.png';
import Corn8R3 from '../../../assets/images/plantAndRoot/Corn/Corn8R3.png';
import Corn9R4 from '../../../assets/images/plantAndRoot/Corn/Corn9R4.png';
import Corn10R5 from '../../../assets/images/plantAndRoot/Corn/Corn10R5.png';
import Corn11R6 from '../../../assets/images/plantAndRoot/Corn/Corn11R6.png';

// Cotton
import Cottonv10 from '../../../assets/images/plantAndRoot/Cotton/Cottonv1.0.PNG';
import Cottonv20 from '../../../assets/images/plantAndRoot/Cotton/Cottonv2.0.PNG';
import Cottonv30 from '../../../assets/images/plantAndRoot/Cotton/Cottonv3.0.PNG';
import Cottonv40 from '../../../assets/images/plantAndRoot/Cotton/Cottonv4.0.PNG';
import Cottonv50 from '../../../assets/images/plantAndRoot/Cotton/Cottonv5.0.PNG';
import Cottonv70 from '../../../assets/images/plantAndRoot/Cotton/Cottonv7.0.PNG';

//Sorghum
import Sorghum13 from '../../../assets/images/plantAndRoot/Sorghum/Sorghum1-3.PNG';
import Sorghum45 from '../../../assets/images/plantAndRoot/Sorghum/Sorghum4-5.PNG';
import Sorghum67 from '../../../assets/images/plantAndRoot/Sorghum/Sorghum6-7.PNG';
import Sorghum810 from '../../../assets/images/plantAndRoot/Sorghum/Sorghum8-10.PNG';
import Sorghum1112 from '../../../assets/images/plantAndRoot/Sorghum/Sorghum11-12.PNG';

// Soybean
import Soybean1VE from '../../../assets/images/plantAndRoot/Soybean/Soybean1VE.PNG';
import Soybean2VC from '../../../assets/images/plantAndRoot/Soybean/Soybean2VC.PNG';
import Soybean3V1V3 from '../../../assets/images/plantAndRoot/Soybean/Soybean3V1-V3.PNG';
import Soybean4V4V5 from '../../../assets/images/plantAndRoot/Soybean/Soybean4V4-V5.PNG';
import Soybean5V6V8 from '../../../assets/images/plantAndRoot/Soybean/Soybean5V6-V8.PNG';
import Soybean6AfterV9 from '../../../assets/images/plantAndRoot/Soybean/Soybean6AfterV9.PNG';
import Soybean7R1 from '../../../assets/images/plantAndRoot/Soybean/Soybean7R1.PNG';
import Soybean8R2 from '../../../assets/images/plantAndRoot/Soybean/Soybean8R2.PNG';
import Soybean9R3 from '../../../assets/images/plantAndRoot/Soybean/Soybean9R3.PNG';
import Soybean10R3Point5 from '../../../assets/images/plantAndRoot/Soybean/Soybean10R3.5.PNG';
import Soybean11R4 from '../../../assets/images/plantAndRoot/Soybean/Soybean11R4.PNG';
import Soybean12R5 from '../../../assets/images/plantAndRoot/Soybean/Soybean12R5.PNG';
import Soybean13R6 from '../../../assets/images/plantAndRoot/Soybean/Soybean13R6.PNG';
import Soybean14R7 from '../../../assets/images/plantAndRoot/Soybean/Soybean14R7.PNG';
import Soybean15R8 from '../../../assets/images/plantAndRoot/Soybean/Soybean15R8.PNG';

// Wheat
import WheatStage101 from '../../../assets/images/plantAndRoot/Wheat/WheatStage101.PNG';
import WheatStage102 from '../../../assets/images/plantAndRoot/Wheat/WheatStage102.PNG';
import WheatStage103 from '../../../assets/images/plantAndRoot/Wheat/WheatStage103.PNG';
import WheatStage104 from '../../../assets/images/plantAndRoot/Wheat/WheatStage104.PNG';
import WheatStage105 from '../../../assets/images/plantAndRoot/Wheat/WheatStage105.PNG';
import WheatStage106 from '../../../assets/images/plantAndRoot/Wheat/WheatStage106.PNG';
import WheatStage107 from '../../../assets/images/plantAndRoot/Wheat/WheatStage107.PNG';
import WheatStage108 from '../../../assets/images/plantAndRoot/Wheat/WheatStage108.PNG';
import WheatStage109 from '../../../assets/images/plantAndRoot/Wheat/WheatStage109.PNG';
import WheatStage110 from '../../../assets/images/plantAndRoot/Wheat/WheatStage110.PNG';
import WheatStage111 from '../../../assets/images/plantAndRoot/Wheat/WheatStage111.PNG';
import WheatStage112 from '../../../assets/images/plantAndRoot/Wheat/WheatStage112.PNG';
import WheatStage113 from '../../../assets/images/plantAndRoot/Wheat/WheatStage113.PNG';
import WheatStage114 from '../../../assets/images/plantAndRoot/Wheat/WheatStage114.PNG';
import WheatStage115 from '../../../assets/images/plantAndRoot/Wheat/WheatStage115.PNG';
import WheatStage116 from '../../../assets/images/plantAndRoot/Wheat/WheatStage116.PNG';
import WheatStage117 from '../../../assets/images/plantAndRoot/Wheat/WheatStage117.PNG';
import WheatStage118 from '../../../assets/images/plantAndRoot/Wheat/WheatStage118.PNG';
import WheatStage119 from '../../../assets/images/plantAndRoot/Wheat/WheatStage119.PNG';
import WheatStage120 from '../../../assets/images/plantAndRoot/Wheat/WheatStage120.PNG';
import WheatStage121 from '../../../assets/images/plantAndRoot/Wheat/WheatStage121.PNG';

// Common plant images
import GenericTree from '../../../assets/images/plantAndRoot/Common plant images/GenericTree.PNG';

// Common root images
import FiberousBroadRoot from '../../../assets/images/plantAndRoot/Common root images/FiberousBroadRoot.PNG';
import FiberousVeryThinRoot1 from '../../../assets/images/plantAndRoot/Common root images/FiberousVeryThinRoot1.png';
import FiberousVeryThinRoot2 from '../../../assets/images/plantAndRoot/Common root images/FiberousVeryThinRoot2.png';
import FiberousVeryThinRoot3 from '../../../assets/images/plantAndRoot/Common root images/FiberousVeryThinRoot3.png';
import FiberousVeryThinRoot4 from '../../../assets/images/plantAndRoot/Common root images/FiberousVeryThinRoot4.png';
import FiberousVeryThinRoot5 from '../../../assets/images/plantAndRoot/Common root images/FiberousVeryThinRoot5.PNG';
import TapThinDenseRoot2 from '../../../assets/images/plantAndRoot/Common root images/TapThinDenseRoot2.PNG';
import TapThinDenseRoot3 from '../../../assets/images/plantAndRoot/Common root images/TapThinDenseRoot3.PNG';
import TapThinRoot1 from '../../../assets/images/plantAndRoot/Common root images/TapThinRoot1.PNG';
import TapThinRoot2 from '../../../assets/images/plantAndRoot/Common root images/TapThinRoot2.PNG';
import TapThinRoot3 from '../../../assets/images/plantAndRoot/Common root images/TapThinRoot3.PNG';


const plantImages = {
    Alfalfa,
    "AlmondStages1-9": AlmondStages19,
    AlmondStage10Onwards,
    CitrusStage0,
    CitrusStage1,
    "CitrusStage1.5": CitrusStage1Point5,
    CitrusStage2,
    CitrusStage3,
    CitrusStage4,
    Corn0VE,
    "Corn1V1-V2": Corn1V1V2,
    "Corn2V3-V5": Corn2V3V5,
    "Corn3V6-V8": Corn3V6V8,
    "Corn4V9-V12": Corn4V9V12,
    Corn5VT,
    Corn6R1, 
    Corn7R2,
    Corn8R3,
    Corn9R4,
    Corn10R5,
    Corn11R6,
    "Cottonv1.0": Cottonv10,
    "Cottonv2.0": Cottonv20,
    "Cottonv3.0": Cottonv30,
    "Cottonv4.0": Cottonv40,
    "Cottonv5.0": Cottonv50,
    "Cottonv7.0": Cottonv70,
    "Sorghum1-3": Sorghum13,
    "Sorghum4-5": Sorghum45,
    "Sorghum6-7": Sorghum67,
    "Sorghum8-10": Sorghum810,
    "Sorghum11-12": Sorghum1112,
    Soybean1VE,
    Soybean2VC,
    "Soybean3V1-V3": Soybean3V1V3,
    "Soybean4V4-V5": Soybean4V4V5,
    "Soybean5V6-V8": Soybean5V6V8,
    "Soybean6AfterV9": Soybean6AfterV9,
    Soybean7R1,
    Soybean8R2,
    Soybean9R3,
    "Soybean10R3.5": Soybean10R3Point5,
    Soybean11R4,
    Soybean12R5,
    Soybean13R6,
    Soybean14R7,
    Soybean15R8,
    WheatStage101,
    WheatStage102,
    WheatStage103,
    WheatStage104,
    WheatStage105,
    WheatStage106,
    WheatStage107,
    WheatStage108,
    WheatStage109,
    WheatStage110,
    WheatStage111,
    WheatStage112,
    WheatStage113,
    WheatStage114,
    WheatStage115,
    WheatStage116,
    WheatStage117,
    WheatStage118,
    WheatStage119,
    WheatStage120,
    WheatStage121,
    "Generic Tree": GenericTree,
}

const rootImages = {
    FiberousBroadRoot,
    FiberousVeryThinRoot1,
    FiberousVeryThinRoot2,
    FiberousVeryThinRoot3,
    FiberousVeryThinRoot4,
    FiberousVeryThinRoot5,
    TapThinDenseRoot2,
    TapThinDenseRoot3,
    TapThinRoot1,
    TapThinRoot2,
    TapThinRoot3,
}

export {
    plantImages,
    rootImages
}