import React from "react";
import { Card, Table } from "react-bootstrap";
import configService from "./duck/configService";
import { withRouter } from "react-router-dom";
import * as moment from 'moment'

class ConnectionStatus extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.siteid;



    configService.getHardwareConnection(id).then(response => {
      if (response) {
        const { badger, baseStation, fox } = response;
        this.setState({ badger, baseStation, fox })


      }
    })
  }
  state = {
    badger: null,
    baseStation: null,
    fox: null

  }

  getreportedtime = (time) => {
    const stillUtc = moment.utc(time).toDate();
    const local = moment(stillUtc).local().fromNow();
    return local;
  }


  getBasestationtime = (time) => {

    let time1 = ''
    if (time !== null && time !== '') {
      const date = time.slice(0, 10);
      const letter = time.slice(10, 11) === '' ? 'T' : time.slice(10, 11);
      const t = time.slice(11, 19);
      const zone = time.slice(19) !== '.000Z' ? '.000Z' : time.slice(19);
      time1 = date + letter + t + zone;
      this.getreportedtime(time1);

    }
  }


  render() {
    return (
      <div className="connection-container">
        <Card>
          <Card.Header>
            <div className="">
              <h5>Hardware Connection Status</h5>
            </div>
          </Card.Header>
          <Card.Body>
            {/* <div className="table-responsive-md table-responsive-lg"> */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Hardware ID</th>
                  <th>Hardware Type</th>

                  <th>Signal</th>
                  <th>Last Reported</th>
                  <th>PER</th>
                </tr>
              </thead>
              <tbody>
                {this.state.badger && this.state.badger.deviceId &&
                  <tr>

                    <td>{this.state.badger.deviceId}</td>
                    <td>Badger</td>

                    <td>{this.state.badger.rssi && this.state.badger.rssi}</td>

                    <td>{this.state.badger.extraData && this.state.badger.extraData.lastReportedTime ?
                      this.getreportedtime(this.state.badger.extraData.lastReportedTime) : ''} </td>
                    {/* <td>{this.state.badger.extraData && this.state.badger.extraData.lastReportedTime ?
      `${timeDiffString(new Date(this.state.badger.extraData.lastReportedTime).valueOf())} ago`:''} </td> */}
                    <td>{this.state.badger.extraData && this.state.badger.extraData.per}</td>
                  </tr>}

                {this.state.fox && this.state.fox.deviceId &&
                  <tr>
                    <td>{this.state.fox.deviceId}</td>
                    <td>Fox</td>

                    <td>{this.state.fox.rssi && this.state.fox.rssi}</td>
                    <td>{this.state.fox.extraData && this.state.fox.extraData.lastReportedTime ?
                      this.getreportedtime(this.state.fox.extraData.lastReportedTime) : ''}</td>
                    <td>{this.state.fox.extraData && this.state.fox.extraData.per}</td>
                  </tr>}

                {this.state.baseStation && this.state.baseStation.deviceId &&
                  <tr>

                    <td>{this.state.baseStation.deviceId}</td>
                    <td>Base Station</td>

                    <td>{this.state.baseStation.rssi && this.state.baseStation.rssi}</td>
                    <td>{this.state.baseStation?.lastReportedTime ?
                      this.getreportedtime(this.state.baseStation?.lastReportedTime) : ''}</td>
                    <td>N/A</td>
                  </tr>}
              </tbody>
            </Table>
            {/* </div> */}
          </Card.Body>
        </Card>
      </div>
    );
  }
}
export default withRouter(ConnectionStatus);
