import React, {useState} from 'react';
import { connect } from 'react-redux';
import userActions from '../../user/duck/userActions';

// const billHistoryOption = { key: 'bill-history', label: 'View Billing History' };
// const changePaymentOption = { key: 'update-payment', label: 'Change Payment Information' };
// const cancelSubscriptionOption = { key: 'cancel-subs', label: 'Cancel Subscription', className: 'red-color' };

// function menuOptions(hasBillHistoryOption) {
//     const options = [changePaymentOption, cancelSubscriptionOption];
//     if (hasBillHistoryOption) {
//         options.unshift(billHistoryOption);
//     }
//     return options;
// }

// const menuOptions = [
//     { key: 'bill-history', label: 'View Billing History' },
//     { key: 'update-payment', label: 'Change Payment Information' },
//     { key: 'cancel-subs', label: 'Cancel Subscription', className: 'red-color' }
// ];

function FarmSubscriptionsItem({
    objectId,
    siteId,
    name,
    subscription,
    parent,
    isAdmin,
    isGroguruAdmin,
    openDoneModal,
    hasStripeCustomerDetails,
    email,
    address,
    username,
    phone,
    // subscriptionId,
    // subscriptionItemId,
    subscriptionHistory,
    showDialogue,
    askConfirmation,
    showPaymentModal,
    showAddCommentModal,
    activeNewSubscription,
    setActiveNewSubscriptions,
    updateStripeCustomer,
    viewSiteComments,
    selectedDelete, 
    setSelectedDelete ,
    isDeleted
}) {
    // const [modalShow, setModalShow] = useState(false);
    // const [modalComment,setModalComment] = useState(false);
    // const [subsCancelled, setSubsCancelled] = useState(null);
    // const [newplan,setNewPlan] = useState([])
    // const isGroguruDirectChild = parent === undefined || (
    //     parent !== null &&
    //     parent.type &&
    //     parent.type.includes('groguru')
    // );
    // const allowedOptions = useRef(menuOptions(!isGroguruAdmin));
    const [isDelete, setIsDelete] = useState(isDeleted)
    const [isSubscription, setIsSubscription] = useState(subscription)
    return (
        <tr>
            <td>{siteId}</td>
            <td>{name}</td>
            <td>{subscription || 'None'}</td>
            {/* {!isGroguruAdmin && ( */}
            <td>
                <input disabled={isGroguruAdmin ? false : true} style={{ width: '25px', height: '25px', marginRight: '20px' }} value={`${subscription}/Gold/${siteId}`} name={`plan${siteId}`} type="checkbox" onChange={changePlan} checked={isSubscription === "Gold"} id={'Gold'}></input>
                <input disabled={isGroguruAdmin ? false : true} style={{ width: '25px', height: '25px', marginRight: '20px' }} value={`${subscription}/Silver/${siteId}`} name={`plan${siteId}`} type="checkbox" onChange={changePlan} checked={isSubscription === "Silver"} id={'Silver'}></input>
                <input disabled={isGroguruAdmin ? false : true} style={{ width: '25px', height: '25px' }} value={`${subscription}/None/${siteId}`} name={`plan/${siteId}`} type="checkbox" onChange={changePlan} checked={isSubscription === "None"} id={'None'}></input>
            </td>
            <td>
                <span className="add-comment-icon" onClick={handleMenuOptions}>View</span>
                <span className="add-comment-icon" onClick={addComment}>Add</span>
            </td>
            {isGroguruAdmin &&
            <td className="d-flex justify-content-center align-items-center" style={{height: 74}}>
            <input style={{ width: '25px', height: '25px' }} type='checkbox' checked={isDelete} onChange={(e) =>deleteSites(e,siteId)}></input>
            </td>}
        </tr>
    )
    function addComment() {
        subscriptionHistory()
        showAddCommentModal()
    }

    function deleteSites (e,siteId) {
    setIsDelete(e.target.checked)
    const isTrue = e.target.checked
    let selected = selectedDelete
    if(isDeleted){
        if(isTrue){
            const filtered = selected.filter(item => item.id !== siteId)
            selected = filtered
        }
        else{
            let newSelected = [...selectedDelete]
            let tempObj = {"id" : siteId , 'deleted': isTrue}
            newSelected.push(tempObj)
            selected = newSelected
        }
    }
    else{
        if(isTrue){
            let newSelected = [...selectedDelete]
            let tempObj = {"id" : siteId , 'deleted': isTrue}
            newSelected.push(tempObj)
            selected = newSelected
        }
        else{
            const filtered = selected.filter(item => item.id !== siteId)
            selected = filtered
        }
    }
     setSelectedDelete(selected)
    }

    function changePlan(e) {

        const newPlan = e.target.value;
        setIsSubscription(e.target.id)
        const filteredPlan = activeNewSubscription.filter(plan => plan.siteId !== siteId);
        const newPlanSelected = newPlan.split("/");
        if (!activeNewSubscription.some(item => item.siteId === siteId && item.currentPlan === newPlanSelected[1])) {
            const selectedPlan =
                [...filteredPlan, {
                    siteId: newPlanSelected[2],
                    oldPlan: newPlanSelected[0],
                    currentPlan: newPlanSelected[1]
                }]
            setActiveNewSubscriptions(selectedPlan);
        } else if (activeNewSubscription?.length === 0) {
            const selectedPlan =
                [...filteredPlan, {
                    siteId: newPlanSelected[2],
                    oldPlan: newPlanSelected[0],
                    currentPlan: newPlanSelected[1]
                }]
            setActiveNewSubscriptions(selectedPlan);
        } else {
            const filterUncheckedSites = activeNewSubscription.filter(plan => plan.siteId !== siteId);
            setActiveNewSubscriptions(filterUncheckedSites);
        }

    }

    function handleMenuOptions() {
        showPaymentModal()
        viewSiteComments()
        subscriptionHistory()
    }
}

const mapStateToProps = state => {
    const { profile } = state.userDetails.profileDetails;
    return {
        hasStripeCustomerDetails: !!profile.stripeCustomerDetails,
        email: profile.email,
        address: profile.address,
        username: profile.name,
        phone: profile.phone,
        isAdmin: profile.role.name.includes('admin'),
        isGroguruAdmin: profile.role.name === 'groguru_admin'
    }
}

const mapDispatchToProps = {
    updateStripeCustomer: userActions.updateStripeCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmSubscriptionsItem)