import React, { Component } from "react";
import { Table, Spinner, Form, FormCheck } from "react-bootstrap";
import siteService from "../../duck/siteService";
import TableHead from '../../../common/tableHead';
import DoneModal from '../../../group/InvitationModal/inviteDone';
import SpinnerButton from '../../../common/spinnerButton';
import { getMapping } from '../graph/duck/graphUtility';
import { connect } from "react-redux";
class TemplateTable extends Component {

  constructor(props) {
    super(props);

    const { hardwareSensor, localSensor, ...tableDate } = props.siteDetails || {};

    this.state = {
      isSuccessDialogueShowing: false,
      savingForm: false,
      savingAllValues: false,
      error: false,


      data: tableDate || [],
      localSensorsByKey: {},
      hardwareSensorsByKey: {},
      hardwareSensor: this.props.siteDetails?.hardwareSensor || [],
      localSensor: this.props.siteDetails?.localSensor || [],
      rpValues: this.props.siteDetails?.rpValues || [],
      rpValuesLocal: this.props.siteDetails?.rpValuesLocal || [],
      wl1Values: this.props.siteDetails?.wl1Values || [],
      wl1ValuesLocal: this.props.siteDetails?.wl1ValuesLocal || [],
      wl2Values: this.props.siteDetails?.wl2Values || [],
      wl2ValuesLocal: this.props.siteDetails?.wl2ValuesLocal || [],
      fcValues: this.props.siteDetails?.fcValues || [],
      fcValuesLocal: this.props.siteDetails?.fcValuesLocal || [],
      pwpValues: this.props.siteDetails?.pwpValues || [],
      pwpValuesLocal: this.props.siteDetails?.pwpValuesLocal || [],
      rpValues_mlValue: this.props.siteDetails?.rpValues_mlValue || [],
      rpValuesLocal_mlValue: this.props.siteDetails?.rpValuesLocal_mlValue || [],
      wl1Values_mlValue: this.props.siteDetails?.wl1Values_mlValue || [],
      wl1ValuesLocal_mlValue: this.props.siteDetails?.wl1ValuesLocal_mlValue || [],
      wl2Values_mlValue: this.props.siteDetails?.wl2Values_mlValue || [],
      wl2ValuesLocal_mlValue: this.props.siteDetails?.wl2ValuesLocal_mlValue || [],
      fcValues_mlValue: this.props.siteDetails?.fcValues_mlValue || [],
      fcValuesLocal_mlValue: this.props.siteDetails?.fcValuesLocal_mlValue || [],
      pwpValues_mlValue: this.props.siteDetails?.pwpValues_mlValue || [],
      pwpValuesLocal_mlValue: this.props.siteDetails?.pwpValuesLocal_mlValue || [],
      rpChecked: this.props?.siteDetails?.rpValuesEdited,
      wl1Checked: this.props?.siteDetails?.wl1ValuesEdited,
      wl2Checked: this.props?.siteDetails?.wl2ValuesEdited,
      fcChecked: this.props?.siteDetails?.fcValuesEdited,
      pwpChecked: this.props?.siteDetails?.pwpValuesEdited,
    };
  }

  initialValues = {
    FC: 50, PWP: 10, RP: 60, WL1: 70, WL2: 95
  }

  closeDialogue = () => this.setState({ isSuccessDialogueShowing: false, error: false })
  finishFormSubmit = () => this.setState({
    savingAllValues: false,
    isSuccessDialogueShowing: true,
    savingForm: false
  })

  initData(currentValue, sensors, initValue = 0) {
    if (sensors && sensors[0]) {
      // Initialise Empty Value Arrays with default values
      const sensorsCount = Object.keys(sensors[0]).length;
      if (!currentValue || (currentValue && currentValue.length !== sensorsCount)) {
        return Object.keys(sensors[0]).map(sensorKey => {
          const valueForDepth = currentValue?.find(sensor => Object.keys(sensor).includes(sensorKey));
          if (!valueForDepth) {
            return { [sensorKey]: initValue };
          } else {
            return valueForDepth;
          }
        });
      }
    }
    return currentValue;
  }

  componentDidMount() {
    if (this.props.siteDetails) {

      this.setState({
        hardwareSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.hardwareSensorLocation),
        localSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.localSensorLocation),
        rpChecked: this.props?.siteDetails?.rpValuesEdited,
        wl1Checked: this.props?.siteDetails?.wl1ValuesEdited,
        wl2Checked: this.props?.siteDetails?.wl2ValuesEdited,
        fcChecked: this.props?.siteDetails?.fcValuesEdited,
        pwpChecked: this.props?.siteDetails?.pwpValuesEdited,
        rpValues_mlValue: this.props.siteDetails?.rpValues_mlValue || [],
        rpValuesLocal_mlValue: this.props.siteDetails?.rpValuesLocal_mlValue || [],
        wl1Values_mlValue: this.props.siteDetails?.wl1Values_mlValue || [],
        wl1ValuesLocal_mlValue: this.props.siteDetails?.wl1ValuesLocal_mlValue || [],
        wl2Values_mlValue: this.props.siteDetails?.wl2Values_mlValue || [],
        wl2ValuesLocal_mlValue: this.props.siteDetails?.wl2ValuesLocal_mlValue || [],
        fcValues_mlValue: this.props.siteDetails?.fcValues_mlValue || [],
        fcValuesLocal_mlValue: this.props.siteDetails?.fcValuesLocal_mlValue || [],
        pwpValues_mlValue: this.props.siteDetails?.pwpValues_mlValue || [],
        pwpValuesLocal_mlValue: this.props.siteDetails?.pwpValuesLocal_mlValue || [],
        selected: 'true'

      });
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.siteDetails !== prevProps.siteDetails) {

      const { hardwareSensor, localSensor, rpValues, rpValuesLocal, wl1Values, wl1ValuesLocal, wl2Values, wl2ValuesLocal,
        fcValues, fcValuesLocal, pwpValues, pwpValuesLocal


      } = this.props.siteDetails || {};

      if (this.props.siteDetails &&
        (this.props?.siteDetails?.pwpValues !== prevProps?.siteDetails?.pwpValues ||
          this.props?.siteDetails?.fcValues !== prevProps?.siteDetails?.fcValues ||
          this.props?.siteDetails?.rpValues !== prevProps?.siteDetails?.rpValues ||
          this.props?.siteDetails?.wl1Values !== prevProps?.siteDetails?.wl1Values ||
          this.props?.siteDetails?.wl2Values !== prevProps?.siteDetails?.wl2Values)) {
        this.setState({
          hardwareSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.hardwareSensorLocation),
          localSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.localSensorLocation),
          rpValues_mlValue: this.props.siteDetails?.rpValues_mlValue || [],
          rpValuesLocal_mlValue: this.props.siteDetails?.rpValuesLocal_mlValue || [],
          wl1Values_mlValue: this.props.siteDetails?.wl1Values_mlValue || [],
          wl1ValuesLocal_mlValue: this.props.siteDetails?.wl1ValuesLocal_mlValue || [],
          wl2Values_mlValue: this.props.siteDetails?.wl2Values_mlValue || [],
          wl2ValuesLocal_mlValue: this.props.siteDetails?.wl2ValuesLocal_mlValue || [],
          fcValues_mlValue: this.props.siteDetails?.fcValues_mlValue || [],
          fcValuesLocal_mlValue: this.props.siteDetails?.fcValuesLocal_mlValue || [],
          pwpValues_mlValue: this.props.siteDetails?.pwpValues_mlValue || [],
          pwpValuesLocal_mlValue: this.props.siteDetails?.pwpValuesLocal_mlValue || [],
          selected: 'true'
        });
      }

      if (this.props.siteDetails &&
        this.props?.siteDetails?.pwpValues === prevProps?.siteDetails?.pwpValues &&
        this.props?.siteDetails?.fcValues === prevProps?.siteDetails?.fcValues &&
        this.props?.siteDetails?.rpValues === prevProps?.siteDetails?.rpValues &&
        this.props?.siteDetails?.wl1Values === prevProps?.siteDetails?.wl1Values &&
        this.props?.siteDetails?.wl2Values === prevProps?.siteDetails?.wl2Values) {
        this.setState({
          hardwareSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.hardwareSensorLocation),
          localSensorsByKey: getMapping.calculateDepth(this.props.siteDetails.localSensorLocation),
          // rpChecked: this.props?.siteDetails?.rpValuesEdited,
          // wl1Checked: this.props?.siteDetails?.wl1ValuesEdited,
          // wl2Checked: this.props?.siteDetails?.wl2ValuesEdited,
          // fcChecked: this.props?.siteDetails?.fcValuesEdited,
          // pwpChecked: this.props?.siteDetails?.pwpValuesEdited,
          rpValues_mlValue: this.props.siteDetails?.rpValues_mlValue || [],
          rpValuesLocal_mlValue: this.props.siteDetails?.rpValuesLocal_mlValue || [],
          wl1Values_mlValue: this.props.siteDetails?.wl1Values_mlValue || [],
          wl1ValuesLocal_mlValue: this.props.siteDetails?.wl1ValuesLocal_mlValue || [],
          wl2Values_mlValue: this.props.siteDetails?.wl2Values_mlValue || [],
          wl2ValuesLocal_mlValue: this.props.siteDetails?.wl2ValuesLocal_mlValue || [],
          fcValues_mlValue: this.props.siteDetails?.fcValues_mlValue || [],
          fcValuesLocal_mlValue: this.props.siteDetails?.fcValuesLocal_mlValue || [],
          pwpValues_mlValue: this.props.siteDetails?.pwpValues_mlValue || [],
          pwpValuesLocal_mlValue: this.props.siteDetails?.pwpValuesLocal_mlValue || [],
          selected: 'true'
        });
      }

      if (!prevProps?.siteDetails) {
        this.setState({
          rpChecked: this.props?.siteDetails?.rpValuesEdited,
          wl1Checked: this.props?.siteDetails?.wl1ValuesEdited,
          wl2Checked: this.props?.siteDetails?.wl2ValuesEdited,
          fcChecked: this.props?.siteDetails?.fcValuesEdited,
          pwpChecked: this.props?.siteDetails?.pwpValuesEdited,
        });
      }

      if (rpValues && wl1Values && wl2Values && fcValues && pwpValues && rpValuesLocal && wl1ValuesLocal && wl2ValuesLocal &&
        fcValuesLocal && pwpValuesLocal) {
        this.setState({
          data: {
            rpValues,
            rpValuesLocal,
            wl1Values,
            wl1ValuesLocal,
            wl2Values,
            wl2ValuesLocal,
            fcValues,
            fcValuesLocal,
            pwpValues,
            pwpValuesLocal,

          },

          rpValues: this.initData(rpValues, hardwareSensor, this.initialValues.RP),
          rpValuesLocal: this.initData(rpValuesLocal, localSensor, this.initialValues.RP),
          wl1Values: this.initData(wl1Values, hardwareSensor, this.initialValues.WL1),
          wl1ValuesLocal: this.initData(wl1ValuesLocal, localSensor, this.initialValues.WL1),
          wl2Values: this.initData(wl2Values, hardwareSensor, this.initialValues.WL2),
          wl2ValuesLocal: this.initData(wl2ValuesLocal, localSensor, this.initialValues.WL2),
          fcValues: this.initData(fcValues, hardwareSensor, this.initialValues.FC),
          fcValuesLocal: this.initData(fcValuesLocal, localSensor, this.initialValues.FC),
          pwpValues: this.initData(pwpValues, hardwareSensor, this.initialValues.PWP),
          pwpValuesLocal: this.initData(pwpValuesLocal, localSensor, this.initialValues.PWP),

        });
      }

    }
  }


  handleSubmit = ({ allValues }) => {
    const {
      rpValues,
      rpValuesLocal,
      wl1Values,
      wl1ValuesLocal,
      wl2Values,
      wl2ValuesLocal,
      fcValues,
      fcValuesLocal,
      pwpValues,
      pwpValuesLocal,
      rpChecked,
      wl1Checked,
      wl2Checked,
      pwpChecked,
      fcChecked,
      rpValues_mlValue,
      wl1Values_mlValue,
      wl2Values_mlValue,
      fcValues_mlValue,
      pwpValues_mlValue,
      rpValuesLocal_mlValue,
      wl1ValuesLocal_mlValue,
      wl2ValuesLocal_mlValue,
      fcValuesLocal_mlValue,
      pwpValuesLocal_mlValue
    } = this.state;

    const rp = (rpChecked) ? rpValues : rpValues_mlValue;
    const rpLocal = (rpChecked) ? rpValuesLocal : rpValuesLocal_mlValue;
    const wl1 = (wl1Checked) ? wl1Values : wl1Values_mlValue;
    const wl1Local = (wl1Checked) ? wl1ValuesLocal : wl1ValuesLocal_mlValue;
    const wl2 = (wl2Checked) ? wl2Values : wl2Values_mlValue;
    const wl2Local = (wl2Checked) ? wl2ValuesLocal : wl2ValuesLocal_mlValue;
    const fc = (fcChecked) ? fcValues : fcValues_mlValue;
    const fcLocal = (fcChecked) ? fcValuesLocal : fcValuesLocal_mlValue;
    const pwp = (pwpChecked) ? pwpValues : pwpValues_mlValue;
    const pwpLocal = (pwpChecked) ? pwpValuesLocal : pwpValuesLocal_mlValue;

    const update = {
      rpValues: rp,
      rpValuesLocal: rpLocal,
      wl1Values: wl1,
      wl1ValuesLocal: wl1Local,
      wl2Values: wl2,
      wl2ValuesLocal: wl2Local,
      fcValues: fc,
      fcValuesLocal: fcLocal,
      pwpValues: pwp,
      pwpValuesLocal: pwpLocal,
      userEdited: {
        rpValues: rpChecked, fcValues: fcChecked, wl1Values: wl1Checked, wl2Values: wl2Checked, pwpValues: pwpChecked,
        rpValuesLocal: rpChecked, fcValuesLocal: fcChecked, wl1ValuesLocal: wl1Checked, wl2ValuesLocal: wl2Checked, pwpValuesLocal: pwpChecked
      }

    };

    if (allValues) {
      this.setState({ savingAllValues: true });
      siteService.updateSite(this.props.siteDetailsLoading.id, update)
        .then(response => {
          siteService.updateSiteWithAllValues(this.props.siteDetailsLoading.siteId, this.props.siteDetailsLoading.twigId, this.props.siteDetailsLoading.status)
            .then(this.setState({ error: true, savingAllValues: false })).catch(err => {
              this.setState({ error: true, savingAllValues: false })
            });
        })
      this.props.onThresholdChange({
        rpValuesEdited: rpChecked, wl1ValuesEdited: wl1Checked,
        wl2ValuesEdited: wl2Checked, fcValuesEdited: fcChecked, pwpValuesEdited: pwpChecked,
        rpValues, rpValuesLocal, wl1Values, wl1ValuesLocal, wl2Values, wl2ValuesLocal, fcValues, fcValuesLocal, pwpValues, pwpValuesLocal
      });
    } else {
      siteService.updateSite(this.props.siteDetailsLoading.id, update)
        .then(this.finishFormSubmit)
      this.props.onThresholdChange({
        rpValuesEdited: rpChecked, wl1ValuesEdited: wl1Checked,
        wl2ValuesEdited: wl2Checked, fcValuesEdited: fcChecked, pwpValuesEdited: pwpChecked,
        rpValues, rpValuesLocal, wl1Values, wl1ValuesLocal, wl2Values, wl2ValuesLocal, fcValues, fcValuesLocal, pwpValues, pwpValuesLocal
      });
    }
  };

  handleChange = (e, val) => {
    let name = e.target.name;

      if (e.target.value !== "") {
        let myState = this.state && Object.entries(this.state);
        myState.map((key) => {

          if (key.includes(name)) {
            let newArray = key[1];
            const result = newArray && newArray.length !== 0 && newArray.map((el, index) => {
              let dataObject = Object.assign({}, el)
              let obj = newArray[val];
              let objKey = Object.keys(obj)[0];
              for (let key in dataObject) {
                if (key === objKey) {
                  dataObject[key] = parseFloat(e.target.value);
                }
              }
              return dataObject;
            });
            this.props.updateAnnotationValue(name, result);
            this.setState({ [name]: result });
          }
          return 1;
        });


      }
    
    //   else {



    //     let name = e.target.name;
    //     let myState = this.state.data && Object.entries(this.state.data);

    //     myState.map((key) => {
    //       if (key.includes(name)) {
    //         let newArray = key[1];
    //         this.setState({ [name]: newArray });
    //       }
    //       return 1;
    //     });
    //   }

  };
  handleClick = () => {
    this.setState({ selected: true })
  }

  tableHeads = [
    { name: "Sensor" },
    { name: "R", tooltipName: "Refill Point (Red)" },
    { name: "G", tooltipName: "Desired – Lower (Green)" },
    { name: "B", tooltipName: "Desired – Upper (Light Blue)" },
    { name: "FC" },
    { name: "PWP" },
  ].map((i) => ({ text: i.name, tooltipName: i.tooltipName }));

  tableBody = {}
  auto_Body = {}



  handleCheck = (e) => {
    const { name } = e.target;
    this.setState({ [name]: !this.state[name], selected: e.currentTarget.name });
    this.props.changesAnnotationName(name);
    this.props.handleAutoManualChange(name, !this.state[name]);
  }

  getColData(colName, sensorKey) {
    if (this.props.siteDetails?.[colName] !== undefined &&
      this.props.siteDetails?.[colName] !== null && this.props.siteDetails?.[colName].length > 0) {


      let x = Object.values(this.props.siteDetails?.[colName] &&
        this.props.siteDetails?.[colName][sensorKey])[0]
      return x.toFixed(2);
    }

  }

  getColorForEachSensor(sensorName, isLocal) {
    if (this.props.curves) {
      let tempObject = JSON.parse(JSON.stringify(this.props.curves));
      tempObject = tempObject.filter(each => isLocal ? each?.data?.name?.includes("local-") : !each?.data?.name?.includes("local-"));
      const sensorObject = tempObject.find((eachObj) => { return eachObj && eachObj.meta && eachObj.meta.depth && eachObj.meta.depth.name && eachObj.meta.depth.name === sensorName });
      return sensorObject && sensorObject.color ? sensorObject.color : "";
    }
    return "";
  }

  render() {
    let isDisabled = false;
    if (this.props.profileDetails && (this.props.profileDetails.profile.role.name === 'dealer_user' ||
      this.props.profileDetails.profile.role.name === 'business_user' ||
      this.props.profileDetails.profile.role.name === 'farm_user')) {
      isDisabled = true;
    }
    return (
      <div className="template-tablecontainer-tr" style={{ padding: 0 }}>
        <div className="template-header">
          <h6>Threshold</h6>
        </div>

        <Table striped bordered hover responsive="lg">
          <TableHead heads={this.tableHeads} />
          <tbody>
            {this.state.localSensorsByKey &&
              Object.values(this.state.localSensorsByKey)
                .sort((a, b) => a.depth - b.depth)
                .map((localSensor, index) => (
                  <tr
                    key={localSensor.name + '-local'}
                  // onChange={(e) => this.handleChange(e, localSensor.key)}
                  >
                    <td style={{ color: `${this.getColorForEachSensor(localSensor.name, true)}` }} >{`${localSensor.name} (${localSensor.depth} in)`}</td>

                    <td>
                      <div key={this.state.rpChecked}>
                        {this.state.rpChecked ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="rpValuesLocal"
                          defaultValue={this.getColData('rpValuesLocal', index)}
                          onChange={(e) => this.handleChange(e, index)}
                        /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="rpValuesLocal"
                            disabled
                            defaultValue={this.getColData('rpValuesLocal_mlValue', index)}
                          />}
                      </div>
                      {this.state.rpValuesLocal_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('rpValuesLocal_mlValue', index) ? this.getColData('rpValuesLocal_mlValue', index) : '--'}</p></div>}

                    </td>

                    <td>
                      <div key={this.state.wl1Checked}>
                        {this.state.wl1Checked ?
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl1ValuesLocal"
                            defaultValue={this.getColData('wl1ValuesLocal', index)}
                            onChange={(e) => this.handleChange(e, index)}
                          /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl1ValuesLocal"
                            disabled
                            defaultValue={this.getColData('wl1ValuesLocal_mlValue', index)}
                          />}
                      </div>
                      {this.state.wl1ValuesLocal_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('wl1ValuesLocal_mlValue', index) ? this.getColData('wl1ValuesLocal_mlValue', index) : '--'}</p></div>}

                    </td>

                    <td>
                      <div key={this.state.wl2Checked}>
                        {this.state.wl2Checked === true ?
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl2ValuesLocal"
                            defaultValue={this.getColData('wl2ValuesLocal', index)}
                            onChange={(e) => this.handleChange(e, index)}
                          /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl2ValuesLocal"
                            disabled
                            defaultValue={this.getColData('wl2ValuesLocal_mlValue', index)}
                          />}
                      </div>
                      {this.state.wl2ValuesLocal_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('wl1ValuesLocal_mlValue', index) ? this.getColData('wl1ValuesLocal_mlValue', index) : '--'}</p></div>}
                    </td>

                    <td>
                      <div key={this.state.fcChecked}>
                        {this.state.fcChecked === true ?
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="fcValuesLocal"
                            defaultValue={this.getColData('fcValuesLocal', index)}
                            onChange={(e) => this.handleChange(e, index)}
                          /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="fcValuesLocal"
                            disabled
                            defaultValue={this.getColData('fcValuesLocal_mlValue', index)}
                          />}</div>
                      {this.state.fcValuesLocal_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('fcValuesLocal_mlValue', index) ? this.getColData('fcValuesLocal_mlValue', index) : '--'}</p></div>}
                    </td>

                    <td>
                      <div key={this.state.pwpChecked}>
                        {this.state.pwpChecked ?
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="pwpValuesLocal"
                            defaultValue={this.getColData('pwpValuesLocal', index)}
                            onChange={(e) => this.handleChange(e, index)}
                          /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="pwpValuesLocal"
                            disabled
                            defaultValue={this.getColData('pwpValuesLocal_mlValue', index)}
                          />}</div>
                      {this.state.pwpValuesLocal_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('pwpValuesLocal_mlValue', index) ? this.getColData('pwpValuesLocal_mlValue', index) : '--'}</p></div>}
                    </td>

                  </tr>
                ))}
            {this.state.hardwareSensorsByKey &&
              Object.values(this.state.hardwareSensorsByKey)
                .sort((a, b) => a.depth - b.depth)
                .map((sensor, index) => (
                  <tr
                    key={sensor.name}
                    className="zzz"
                  >
                    <td style={{ color: `${this.getColorForEachSensor(sensor.name, false)}` }} className="one"><div>{`${sensor?.name} (${sensor?.depth} in)`}</div></td>

                    <td>
                      <div key={this.state.rpChecked}>
                        {this.state.rpChecked === true ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="rpValues"
                          defaultValue={this.getColData("rpValues", index)}
                          onChange={(e) => this.handleChange(e, index)}
                        /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="rpValues"
                            disabled
                            defaultValue={this.getColData("rpValues_mlValue", index)}

                          />}
                      </div>
                      {this.state.rpValues_mlValue.length > 0 && <div className="ml-value" >


                        <p className="op-05 mb-0">{this.getColData('rpValues_mlValue', index)}</p>
                      </div>}


                    </td>
                    {/* key={this.state.wl1Checked} */}
                    <td >
                      <div key={this.state.wl1Checked}>
                        {this.state.wl1Checked === true ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="wl1Values"
                          defaultValue={this.getColData('wl1Values', index)}
                          onChange={(e) => this.handleChange(e, index)}
                        /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl1Values"
                            disabled
                            defaultValue={this.getColData('wl1Values_mlValue', index)}


                          />}
                      </div>
                      {this.state.wl1Values_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('wl1Values_mlValue', index) ? this.getColData('wl1Values_mlValue', index) : '--'}</p></div>}
                    </td>

                    <td >
                      <div key={this.state.wl2Checked}>
                        {this.state.wl2Checked === true ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="wl2Values"
                          defaultValue={this.getColData('wl2Values', index)}
                          onChange={(e) => this.handleChange(e, index)}
                        /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="wl2Values"
                            disabled
                            defaultValue={this.getColData('wl2Values_mlValue', index)}
                          />}</div>
                      {this.state.wl2Values_mlValue.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('wl2Values_mlValue', index) ? this.getColData('wl2Values_mlValue', index) : '--'}</p></div>}
                    </td>

                    <td>
                      <div key={this.state.fcChecked}>
                        {this.state.fcChecked ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="fcValues"
                          defaultValue={this.getColData('fcValues', index)}
                          onChange={(e) => this.handleChange(e, index)}
                        /> :
                          <input
                            type="number"
                            step="0.01"
                            precision={2}
                            name="fcValues"
                            disabled
                            defaultValue={this.getColData('fcValues_mlValue', index)}
                          />}</div>
                      {this.state?.fcValues_mlValue?.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('fcValues_mlValue', index) ? this.getColData('fcValues_mlValue', index) : '--'}</p></div>}
                    </td>

                    <td>
                      <div key={this.state.pwpChecked}>
                        {this.state.pwpChecked ? <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="pwpValues"
                          onChange={(e) => this.handleChange(e, index)}
                          defaultValue={this.getColData('pwpValues', index)}
                        /> :
                          <input
                          type="number"
                          step="0.01"
                          precision={2}
                          name="pwpValues"
                          disabled
                          defaultValue={this.getColData('pwpValues_mlValue', index)}
                        />}
                      </div>
                      {this.state?.pwpValues_mlValue?.length > 0 && <div className="ml-value" >
                        <p className="op-05 mb-0">{this.getColData('pwpValues_mlValue', index) ? this.getColData('pwpValues_mlValue', index) : '--'}</p></div>}
                    </td>

                  </tr>
                ))}





            <tr>
              <td> User_Edited</td>
              <td>

                <FormCheck
                  type="switch"
                  id="rpChecked"
                  label={!this.state.rpChecked ? "A" : "M"}
                  name="rpChecked"
                  checked={this.state.rpChecked}
                  onClick={(e) => this.handleCheck(e)}
                />


              </td>
              <td>
                <Form.Check
                  type="switch"
                  id="wl1Checked"
                  label={!this.state.wl1Checked ? "A" : "M"}
                  name="wl1Checked"
                  checked={this.state.wl1Checked}
                  onChange={(e) => this.handleCheck(e)}
                // onChange={(e) =>(this.state.wl1ValuesLocal_mlValue.length>0&&this.state.wl1Values_mlValue.length>0)&& this.handleCheck(e)}
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  id="wl2Values"
                  label={!this.state.wl2Checked ? "A" : "M"}
                  name="wl2Checked"
                  checked={this.state.wl2Checked}
                  onChange={(e) => this.handleCheck(e)}
                />
              </td>
              <td>

                <Form.Check
                  type="switch"
                  id="fcChecked"
                  label={!this.state.fcChecked ? "A" : "M"}
                  name="fcChecked"
                  checked={this.state.fcChecked}
                  onChange={(e) => this.handleCheck(e)}
                //onChange={(e) =>(this.state.fcValuesLocal_mlValue.length>0&&this.state.fcValues_mlValue.length>0)&& this.handleCheck(e)}
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  id="pwpChecked"
                  label={!this.state.pwpChecked ? "A" : "M"}
                  name="pwpChecked"
                  checked={this.state.pwpChecked}
                  onChange={(e) => this.handleCheck(e)}
                //onChange={(e) =>(this.state.pwpValuesLocal_mlValue.length>0&&this.state.pwpValues_mlValue.length>0)&& this.handleCheck(e)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className=" d-flex">

          {this.props.siteDetailsLoading.isLoading && <Spinner style={{ display: 'block', margin: 'auto' }} animation="grow" />}
          <div className="text-right mr-20 mt-10">
            {!this.props.dataError &&
              <SpinnerButton
                spinning={this.state.savingForm}
                disabled={this.state.savingForm || isDisabled}
                onClick={() => this.handleSubmit({ allValues: false })}>Recompute future values</SpinnerButton>}
          </div>
          <div className="text-right mt-10">
            {!this.props.dataError &&
              <SpinnerButton
                spinning={this.state.savingAllValues}
                disabled={this.state.savingForm || isDisabled}
                onClick={() => this.handleSubmit({ allValues: true })}>Recompute all values</SpinnerButton>}
          </div>
          <DoneModal
            buttonLabel="Ok"
            show={this.state.isSuccessDialogueShowing}
            onClose={this.closeDialogue}
            data={{ title: 'Update Successful!', message: 'The threshold data updated successfully.' }} />
          <DoneModal
            buttonLabel="Ok"
            show={this.state.error}
            onClose={this.closeDialogue}
            data={{ title: 'Update In Progress!', message: 'Recompute all values is in progress, Please refresh the page and check after some time' }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userDetails } = state;
  const profileDetails = userDetails.profileDetails;
  return { profileDetails };
};

export default connect(mapStateToProps)(TemplateTable);