export const PLAN_DETAILS = {
    NONE: "None",
    SILVER: "Silver",
    GOLD: "Gold",
}

export const ROLES = {
    GROGURU_ADMIN: "groguru_admin",
    DISTRIBUTOR_ADMIN: "distributor_admin",
    DISTRIBUTOR_USER: "distributor_user",
    DEALER_ADMIN: "dealer_admin",
    DEALER_USER: "dealer_user",
    BUSINESS_ADMIN: "business_admin",
    BUSINESS_USER: "business_user",
    FARM_ADMIN: "farm_admin",
    FARM_USER: "farm_user",
}