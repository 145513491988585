import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import cornerIcon from "../../assets/images/corner-arrow/rectangle.png";
import GraphSiteItem from "../sites/sidemenu/graphSiteItem";
import Search from "../common/search";
import SiteEmptyCase from "./sidemenu/siteEmptyCase";

class SelectSite extends Component {

  state = {
    show: false,
    search: null,
    notfound: false,
  };

  handleModal() {
    this.setState({ show: !this.state.show });
  }

  closeModal = () => {
    this.Modal();
  }

  Modal() {
    this.setState(state => ({
      search: '',
      show: !state.show,
    }));
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value });
  }

  findMatch = (data) => {
    const { search } = this.state;
    if (data.name.toLowerCase().includes(search.toLowerCase())) {
      return data;
    } else if (data.siteId.toLowerCase().includes(search.toLowerCase())) {
      return data;
    }
  }


  render() {
    const { search } = this.state;
    const { sites } = this.props;
    let filteredItems;

    if (sites && search) {
      filteredItems = sites.filter(this.findMatch);
    } else if (sites && !search) {
      filteredItems = sites;
    }

    return (
      <div>
        <Button
          onClick={() => {
            this.handleModal();
          }}
          variant="none"
          className="create-farm-btn"
        >
          <img src={cornerIcon} alt="icon"></img>
        </Button>
        <Modal
          className="select-farm selectsite"
          show={this.state.show}
          onHide={() => this.Modal()}
          size="lg"
          centered
        >
          <Modal.Header
            closeButton={false}
            className="d-flex justify-content-between"
          >
            <p>Select a Site</p>
            <div className="d-flex header-btn">
              <p
                className="text-right cp"
                onClick={() => {
                  this.Modal();
                }}
              >
                {" "}
                Close
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="select-container">
              <div className="d-flex justify-content-between ">
                <h6>
                  Sort By:{" "}
                  <span>
                    Alphabetical <img src={cornerIcon} alt="corner-icon"></img>
                  </span>
                </h6>
              </div>
              <div className="select-farm-search">
                {" "}
                <Search
                  placeholder="Search Sites ..."
                  onSearch={this.onSearch}
                />{" "}
              </div>
              <div className="content-area">
                {this.props.sites !== null && this.props.sites.length !== 0 ? (
                  filteredItems.map((site) => (
                    <GraphSiteItem data={site} close={this.closeModal} key={site.id} userRole={this.props.userRole}/>
                  ))
                ) : (
                    <SiteEmptyCase search={this.state.search} />
                  )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default SelectSite;