import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import TemplateDetails from "../template/templateDetails";
import TemplateFilter from "../template/templateFilter";
import TemplateTable from "../template/templateTable";
import { StickyMessage } from '../../../common/messages';
import TemplateGraphDetails from '../graph/templateGraphDetails';

function TemplateBody({ userid, siteDetails, onThresholdChange, match }) {

    const [dateParams, setDateParams] = useState('ONE_YEAR');
    const [templateList, setTemplateList] = useState({ loaded: false, list: [] });
    const [template, setTemplate] = useState({ id: null, name: null });
    const [isTemplateChanged, setIsTemplateChanged] = useState(false);
    const [annotationName, setAnnotationName] = useState(null);
    const [siteAnnotation, setSiteAnnotation] = useState(siteDetails.twigDetails);
    const [checkList, setCheckList] = useState(
        {
            rpChecked: siteAnnotation?.rpValuesEdited,
            wl1Checked: siteAnnotation?.wl1ValuesEdited,
            wl2Checked: siteAnnotation?.wl2ValuesEdited,
            fcChecked: siteAnnotation?.fcValuesEdited,
            pwpChecked: siteAnnotation?.pwpValuesEdited,
        });

    const [curves, setCurvesToRender] = useState(null);
    const invalidSiteError = useMemo(() => {
        const isDataArraysMissing = siteDetails && !(siteDetails.twigDetails && siteDetails.twigDetails.rpValues && siteDetails.twigDetails.wl1Values && siteDetails.twigDetails.wl2Values && siteDetails.twigDetails.fcValues && siteDetails.twigDetails.pwpValues)
        if (siteDetails && !siteDetails.isLoading && isDataArraysMissing) {
            return `Cannot access data for this site: Site does not have valid badger / fox ids entered`;
        }
        return null;
    }, [siteDetails]);

    function setTemplateHeaderInfo(id, name, selectedTemplate) {
        setTemplate({
            id,
            name,
            isEditable: selectedTemplate?.createdBy?.objectId === userid
        });
        if(!id) setIsTemplateChanged(true);
    }

    function addTemplate(template) {
        setTemplateList(templateListData => ({
            loaded: templateListData.loaded,
            list: [...templateListData.list, template]
        }));
    }

    function selectedAnnotation(name) {
        setAnnotationName(name);
    }

    function checkAutoManualSetting(name, value) {
        setCheckList({
            ...checkList,
            [name]: value,
        })
    }

    function setAnnotationValue(n, r) {
        setSiteAnnotation({
            ...siteAnnotation,
            [n]: r,
        })
    }

    function updateCurves(curves) {
        setCurvesToRender(curves);
    }

    useEffect(() => {
        setSiteAnnotation(siteDetails.twigDetails);
    }, [siteDetails])

    return (
        <div>
            <TemplateFilter
                dateParams={dateParams}
                onDateParamChange={setDateParams}
                cropType={siteDetails?.twigDetails?.plantType}
                appliedTemplateId={template.id}
                applyTemplate={setTemplateHeaderInfo}
                templateList={templateList}
                siteDetails={siteDetails}
                setTemplateList={setTemplateList} />
            {invalidSiteError &&
                <StickyMessage variant="error" message={invalidSiteError} />}
            <TemplateDetails
                routeParams={match.params}
                cropType={siteDetails?.twigDetails?.plantType}
                templateId={template.id}
                isTemplateOverwitable={template.isEditable}
                plantingDate={siteDetails.twigDetails?.plantingDate}
                templateName={template.name ? template.name : ''}
                dateParamKey={dateParams}
                applyTemplate={setTemplate}
                onTemplateCreated={addTemplate}
                isTemplateChanged={isTemplateChanged}
                setIsTemplateChanged={setIsTemplateChanged} />
            <div className="row">
                <div className="col-lg-7 col-md-7 col-12">
                    <TemplateGraphDetails
                        routeParams={match.params}
                        timeDuration={dateParams}
                        checkList={checkList}
                        annotationName={annotationName}
                        siteAnnotation={siteAnnotation}
                        curvesToRenderUpdateToParent={(curves) => updateCurves(curves)}
                    />
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                    <TemplateTable
                        onThresholdChange={onThresholdChange}
                        dataError={invalidSiteError}
                        updateAnnotationValue={(n, r) => setAnnotationValue(n, r)}
                        siteDetails={siteAnnotation}
                        siteDetailsLoading={siteDetails}
                        changesAnnotationName={(name) => selectedAnnotation(name)}
                        handleAutoManualChange={(name, value) => checkAutoManualSetting(name, value)}
                        curves={curves}
                    /></div>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    userid: state.userDetails.profileDetails.profile.objectId
});

export default connect(mapStateToProps)(TemplateBody)