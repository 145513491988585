const DEFAULT_SCALE = 100;
export const MAX_STACK = 10;

export function applyCommonScale(seriesArray = [], appliedScale = {}, scaleTo = DEFAULT_SCALE) {
    return seriesArray.map(series => {
        let minMax = appliedScale[series.meta.shortName];
        return {
            name: series.data.name,
            type: series.data.type,
            color: series.color,
            data: series.data.data.map(point => ({
                x: point.x,
                y: (point.y * scaleTo) / minMax.max ? (point.y * scaleTo) / minMax.max : 0
            }))
        }
    })
}

export default function applyStackLevel(seriesArray = [], currentStackLevel = 0, maxStackLevel = MAX_STACK, offsetValue = DEFAULT_SCALE) {
    const offsetFactor = (currentStackLevel / maxStackLevel) * offsetValue;
    const colors = [];
    const updatedSeries = seriesArray.map((series, seriesIndex) => {
        const depthOrder = seriesArray.length - seriesIndex;
        const offset = offsetFactor * depthOrder;
        colors.push(series.color)
        return {
            name: series.name,
            type: series.type,
            data: series.data.map(point => ({
                x: point.x,
                y: point.y + offset
            }))
        }
    });
    return { colors, updatedSeries };
}

// export async function updateSeriesByComputedFactor(seriesArray, stackLevel) {
//     const seriesAvgs = seriesArray.map(
//         series => (series.data.data.reduce((acc, point) => acc + point.y, 0) / series.data.data.length) || 0
//     );

//     const sortOrder = seriesAvgs
//         .map((item, i) => [i, item])
//         .sort((a, b) => a[1] - b[1]); // order: [seriesI, avg]

//     const diffAdjacent = sortOrder.map((item, i) => {
//         const next = sortOrder[i + 1];
//         if (next) {
//             return next[1] - item[1];
//         }
//         return null;
//     }).filter(item => item);

//     const factor = diffAdjacent.reduce((a, b) => a + b, 0) / diffAdjacent.length;
//     const stackStep = (stackLevel>0)? (stackLevel/10): 0;
//     const factorForSeries = sortOrder.reduce((acc, item, orderIndex) => {
//         const [seriesI] = item;
//         acc[seriesI] = orderIndex * factor * stackStep;
//         return acc;
//     }, {});

//     return seriesArray.map((series, seriesI) =>  ({
//             name: series.data.name,
//             type: series.data.type,
//             data: series.data.data.map(data => ({
//                 x: data.x,
//                 y: data.y + factorForSeries[seriesI]
//             }))
//     }));
// }