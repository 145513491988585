import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {Link} from 'react-router-dom';
import FarmIssueStatus from './farmIssueStatus';
import farmDefaultImage from "../../assets/images/farms/farm-default-icon.png";
import supercluster from 'points-cluster';
import farmService from './duck/farmService'
import { connect } from 'react-redux';
import { filterFarms, getFilterFromQuery } from './duck/farmsUtils';


const MapMarker = props => (
    
    <Link to={`/farm/${props.farmDetails.objectId}/sites`}>
        <div className="markerStyle">
            <div>
                <img className="markerImgStyle" src={props.farmDetails.image || farmDefaultImage} alt="pic"></img>
            </div>
          
            <h6 className="markerHeadingStyle">{props.farmDetails.name}</h6>
            {!props.agronomic ?<FarmIssueStatus  agronomic={false} reportingStatus={props.farmDetails.reportingStatus} />:
             <FarmIssueStatus moistureStatus={props.farmDetails.moistureState} agronomic={true}/>} 
            <span className="markerArrowStyle"></span>
        </div>
    </Link>
);


const ClustererMarker = props => (
    <div className="clusterMarkerStyling">
        {props.points.length}
    </div>
);

const defaults = {
    zoom: 0,
    options(maps) {
        return {
            maxZoom: 18,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            }
        }
    },
};


class FarmMapView extends Component {

   
    componentDidUpdate(prevProps) {
        if (this.props.agronomic !== prevProps.agronomic) {
          this.setState({
            agronomic: this.props.agronomic,
          });
        }
      }

    static defaultProps = {
        center: {
            lat: 8.3,
            lng: 77.5
        },
        zoom: defaults.zoom
    };

    state = {
        mapOptions: {
            center: defaults.center,
            zoom: defaults.zoom,
        },
        clusters: [],
        agronomic:this.props.agronomic
    }

    apiCall = null;
    getFarmsForUserReq = farmService.getFarmsForUserRequest();

    filterData() {
        return filterFarms(this.props.farms, getFilterFromQuery(this.props.location.search))
    }

    createPoints() {
        return this.filterData().map(farm => ({
            id: farm.objectId,
            lat: farm.location.latitude,
            lng: farm.location.longitude,
            farm
        }))
    }

    getClusters = data => {
        const options = { minZoom: 0, maxZoom: 17, radius: 85 };
        const clusters = supercluster(data, options);
        return clusters(this.state.mapOptions);
    };

    createClusters = bounds => {
        var clusters = [];
        if (bounds) {
            clusters = this.getClusters(this.createPoints())
                .map((cluster) => ({
                    lat: cluster.wy,
                    lng: cluster.wx,
                    numPoints: cluster.numPoints,
                    points: cluster.points,
                    id: cluster.points[0].id
                }));
        }
        this.setState({ clusters });
    };

    handleMapChange = ({ center, zoom, bounds }) => {
        this.setState({ mapOptions: { center, zoom, bounds } });
        this.createClusters(bounds);
    };

    renderCluster(cluster,agronomic) {
        
        if (cluster.numPoints === 1) {
          
            
            const [farmPoint] = cluster.points;
            return (
                <MapMarker
                    key={cluster.id}
                    lat={farmPoint.lat}
                    lng={farmPoint.lng}
                    farmDetails={farmPoint.farm} 
                    agronomic={agronomic}
                    />
            );
        }

        return (
            <ClustererMarker
                key={cluster.id}
                lat={cluster.lat}
                lng={cluster.lng}
                points={cluster.points} />
        );
    }

    renderMarkers = (agronomic) => this.state.clusters.map((cluster)=>this.renderCluster(cluster,agronomic));

 render() {
        const agronomic=this.state.agronomic&&this.state.agronomic;
        const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        return (
            <div style={{ height:'100vh'}}>
                 
            {this.props.farms && (agronomic || !agronomic) && 
                            <GoogleMapReact
                            bootstrapURLKeys={{ key }}
                            center={this.props.center}
                            defaultZoom={this.props.zoom}
                            options={defaults.options}
                            onChange={this.handleMapChange}>
                            {this.renderMarkers(agronomic)}
                            </GoogleMapReact>} 
                </div>
        );
    }
}

const mapStateToProps = state => ({
    farms: state.farms.data
});

export default connect(mapStateToProps)(FarmMapView);
