import React from "react";
import defaultFarmIcon from "../../assets/images/emptycase_icon/bitmap.png";

function GroupEmptyCase(props) {
    return (
        <div className="text-center empty-case">
            <div className="emptycase-icon mx-auto">
                <img src={defaultFarmIcon} className="" alt="icon"></img>
            </div>
            {props.search && <h3 className="mt-15 mb-0">No results for “{props.search}”</h3>}
            <p className="mt-10">We didn’t find any {props.label} listed.</p>
        </div>
    );
}

export default GroupEmptyCase;
