export default function PinColorService(siteState) {
    const status = siteState;
    let insideFill = "grey";

    let twigColorStatus = "";

    if (status?.justInstalled) {
        twigColorStatus = "pre-install";
    } else if (status?.moisture && status?.salinity) {
        twigColorStatus = "both"
    } else if (status?.irrigatingNow) {
        twigColorStatus = "irrigating-now";
    } else if (status?.updatedRecently === false) {
        twigColorStatus = "no-data-received";
    } else if (status?.moisture || status?.salinity) {
        twigColorStatus = "active-twig";
    } else if (status?.pressure) {
        twigColorStatus = "pressure";
    }
    else {
        twigColorStatus = "default";
    }

    let sta = '';

    if (status?.salinity === "HIGH" && status?.moisture === "HIGH") {
        sta = "high-high";
    } else if (status?.salinity === "HIGH" && status?.moisture === "GOOD") {
        sta = "high-good"
    } else if (status?.salinity === "HIGH" && status?.moisture === "FAIR") {
        sta = "high-fair"
    } else if (status?.salinity === "HIGH" && status?.moisture === "MED") {
        sta = "high-med"
    } else if (status?.salinity === "HIGH" && status?.moisture === "LOW") {
        sta = "high-low"
    } else if (status?.salinity === "MED" && status?.moisture === "HIGH") {
        sta = "med-high"
    } else if (status?.salinity === "MED" && status?.moisture === "GOOD") {
        sta = "med-good"
    } else if (status?.salinity && status?.salinity === "MED" && status?.moisture && status?.moisture === "FAIR") {
        sta = "med-fair"
    } else if (status?.salinity === "MED" && status?.moisture === "MED") {
        sta = "med-med"
    } else if (status?.salinity === "MED" && status?.moisture === "LOW") {
        sta = "med-low"
    } else if (status?.salinity === "LOW" && status?.moisture === "HIGH") {
        sta = "low-high"
    } else if (status?.salinity === "LOW" && status?.moisture === "GOOD") {
        sta = "low-good"
    } else if (status?.salinity && status?.salinity === "LOW" && status?.moisture && status?.moisture === "FAIR") {
        sta = "low-fair"
    } else if (status?.salinity === "LOW" && status?.moisture === "MED") {
        sta = "low-med"
    } else {
        sta = "low-low"
    }


    switch (twigColorStatus) {
        case "pre-install":
            insideFill = "white";
            break;
        case "no-data-received":

            insideFill = "grey";
            break;
        case "irrigating-now": {
            if (status?.irrigatingNow === "ON") {

                insideFill = "blue";
                break;
            } else {

                insideFill = "white";
                break;
            }
        }
        case "active-twig": {

            if (status?.moisture) {
                switch (status?.moisture) {
                    case "LOW":

                        insideFill = "red";
                        break;
                    case "FAIR":

                        insideFill = "green";
                        break;
                    case "MED":

                        insideFill = "yellow";
                        break;
                    case "HIGH":

                        insideFill = "blue";
                        break;
                    case "GOOD":

                        insideFill = "lightblue";
                        break;
                    default: break;
                }
            }

            switch (status?.salinity) {
                case "LOW":

                    insideFill = "grey";
                    break;

                case "MED":

                    insideFill = "grey";
                    break;
                case "HIGH":

                    insideFill = "grey";
                    break;
                default: break;
            }
            break;
        }

        case "both": {
            switch (sta) {
                case "high-high":

                    insideFill = "blue";
                    break;
                case "high-good":

                    insideFill = "lightblue";
                    break;
                case "high-fair":

                    insideFill = "green";
                    break;
                case "high-med":

                    insideFill = "yellow";
                    break;
                case "high-low":

                    insideFill = "red";
                    break;
                case "med-high":

                    insideFill = "blue";
                    break;
                case "med-good":

                    insideFill = "lightblue";
                    break;
                case "med-fair":

                    insideFill = "green";
                    break;
                case "med-med":

                    insideFill = "yellow";
                    break;
                case "med-low":

                    insideFill = "red";
                    break;
                case "low-high":

                    insideFill = "blue";
                    break
                case "low-good":

                    insideFill = "lightblue";
                    break;
                case "low-fair":

                    insideFill = "green";
                    break;
                case "low-med":

                    insideFill = "yellow";
                    break;
                case "low-low":

                    insideFill = "red";
                    break;
                default: break;
            }
            break;
        }

        case "pressure": {
            if (status?.pressure === "ON") {

                insideFill = "blue";
            } else {

                insideFill = "white";

            }
            break;
        }

        case "default":

            insideFill = "grey";
            break;
        default: break;
    }

    return insideFill;
}
