import React, { Component } from "react";
import { Button } from "react-bootstrap";
import FarmList from "./farmList";
import FarmHealth from './farmHealth.jsx';
import FarmMastHead from "./farmMastHead";
import FarmMapView from "./farmMapView";
import { Switch, Redirect, Route } from "react-router-dom";
import SearchBar from './farmSearchBar';
import { connect } from 'react-redux';
import farmsActions from './duck/farmActions';
import FarmsTreeView from './farmsTreeView';
import farmService from './duck/farmService'
class FarmBody extends Component {

    state = {
        search: '',
        sort: "Alphabetical",
        isLoading: false,
        accessFarmSelect: null,
        accessFarmObjectId: null,
        fieldHealthVisible: false,
    }

    apiCall = null;
    onaccessFarmSelect = (accessFarmSelect, accessFarmObjectId) => {
        this.setState({ accessFarmSelect, accessFarmObjectId });
    }
    componentDidMount() {
        // this.onSortChange(this.state.sort);
        if (this.props.farms) {
            this.props.getSelectedFarms()

        }
        else {

            // if (this.props.shouldLoadFarms) {
            this.props.getFarms();
            //}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let farmsPath = this.props.match.path;
        if (!this.state.fieldHealthVisible && this.props.location.pathname === `${farmsPath}/field-report`) {
            this.setState({ fieldHealthVisible: true });
        } else if (this.state.fieldHealthVisible &&
            (this.props.location.pathname !== prevProps.location.pathname) &&
            this.props.location.pathname !== `${farmsPath}/field-report`) {
            this.setState({ fieldHealthVisible: false });
        }
        if (prevState?.sort !== this.state?.sort && this.state.search.length === 0) {
            this.onSortChange(this.state.sort)
        }
    }

    onSearchChange = (e) => {
        let searchQuery = e.target.value;

        let trimmedstr = searchQuery.replace(/\s+$/, '');
        this.setState({ search: trimmedstr });
        if (this.apiCall) {
            clearTimeout(this.apiCall);
            this.apiCall = null;
        }
        this.apiCall = this.state.sort === "Recently Added" ? setTimeout(() =>
            this.props.getFarms(trimmedstr, "createdAt"), 400) : setTimeout(() => this.props.getFarms(trimmedstr), 400);


    }

    onFilterChange = (newFilter) => {
        this.setState({ filter: newFilter });
    }

    onShowAsChange = showAs => {
        this.setState({ showAs });
    }

    onSortChange = (sort) => {
        this.setState({ sort });
        this.setState({ isLoading: true });
        const farms = farmService.getFarmsForUserRequest();
        if (sort === "Recently Added") {
            farms.request(this.state.search, 'createdAt').then(blu => {
                this.props.getSelectedFarm(blu)
                this.setState({ isLoading: false })
            });
        } else {
            farms.request(this.state.search).then(red => {
                this.props.getSelectedFarm(red)
                this.setState({ isLoading: false })
            });
        }
    }

    componentWillUnmount() {
        this.props.clearFarmsData();
    }

    handleSelectView = (view) => {

        this.setState({ view })
    }

    navToFieldHealth = () => {
        let farmsPath = this.props.match.path;
        this.setState({ fieldHealthVisible: true });
        this.props.history.push(`${farmsPath}/field-report`);
    }

    render() {
        let farmsPath = this.props.match.path;

        let agronomic = this.state.view === `Agronomical View` ? true : false;

        let focusedFarm;
        if (this.props.getSelectedFarms?.[0]?.location) {
            const { latitude: lat, longitude: lng } = this.props.getSelectedFarms[0].location;
            focusedFarm = { lat, lng };
        }

        return (
            <React.Fragment>
                {!this.state.fieldHealthVisible ?
                    <FarmMastHead
                        farmSelect={this.onaccessFarmSelect}
                        sort={this.state.sort}
                        agronomic={agronomic}
                        search={this.state.search} /> : null}
                <div className="master-body container-fluid ">
                    {!this.state.fieldHealthVisible ? <div className="row">
                        <div className="col-8 col-xs-8 col-sm-8 col-md-9 col-lg-9">
                            <SearchBar
                                border={this.props.location.pathname.includes('farms/map-view')}
                                onSearchChange={this.onSearchChange}
                                onFilterChange={this.onFilterChange}
                                onShowAsChange={this.onShowAsChange}
                                onViewChange={this.handleSelectView}
                                handleSort={this.onSortChange}
                            />
                        </div>
                        <div className="field-rpt col-4 col-xs-4 col-sm-4 col-md-3 col-lg-3" >
                            <Button
                                onClick={this.navToFieldHealth}>Field Health Report</Button>
                        </div>
                    </div> : null}
                    <div className="view">
                        <Switch>
                            <Route path={`${farmsPath}/list-view`} render={props => <FarmList
                                {...props}
                                agronomic={agronomic}
                                isLoading={this.state.isLoading}
                                sortType={this.state.sortType}
                                search={this.state.search} />} />

                            <Route path={`${farmsPath}/tree-view`} render={props => <FarmsTreeView
                                {...props}
                                sortType={this.state.sort}
                                accessFarm={this.state.accessFarmSelect}
                                agronomic={agronomic}
                                accessFarmObject={this.state.accessFarmObjectId}
                                search={this.state.search} />} />

                            <Route path={`${farmsPath}/map-view`} render={props => <FarmMapView
                                {...props}
                                filter={this.state.filter}
                                search={this.state.search}
                                agronomic={agronomic}
                                center={focusedFarm} />} />
                            <Route path={`${farmsPath}/field-report`} render={props => <FarmHealth />} />

                            <Redirect to={`${farmsPath}/list-view`} />
                        </Switch>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    shouldLoadFarms: !state.farms.data,
    getSelectedFarms: state.farms.data
});

const mapDispatchToProps = {
    getFarms: farmsActions.fetchFarms,
    clearFarmsData: farmsActions.clearFarmsData,
    getSelectedFarm: farmsActions.getSelectedFarm,
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmBody);