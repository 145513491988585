import http from "../../common/duck/httpService";
import logger from "../../common/duck/logService";

const apiEndPoint = "/user";
const login = (email, password) => {
    return http
        .post(`${apiEndPoint}/signin`, {
            email: email,
            password: password
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const {data} = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
};

const getProfile = userId => {
    return http
        .get(`${apiEndPoint}/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const {data} = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
};

const editProfile = (userId, payload) => {
    return http
        .patch(`${apiEndPoint}/${userId}`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const {data} = error.response;
            logger.log(error);
            return Promise.reject(data);
        });
};

const getPreferences = () => {
    return http
        .get(`${apiEndPoint}/details/`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const {data} = error.response;
            logger.log(error);
            return Promise.reject(data);
        });

}


const updatePreferences = (payload) => {

    return http
        .put(`${apiEndPoint}/details`, payload, {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const data = error?.response?.data;
            logger.log(error);
            return Promise.reject(data);
        });

}

const contact = (payload) => {

    return http
        .post(`${apiEndPoint}/contact`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const {data} = error.response;
            logger.log(error);
            return Promise.reject(data);
        });

}


export default {
    login,
    getProfile,
    editProfile,
    getPreferences,
    updatePreferences,
    contact
  
}
