import React from "react";
import './batteryIcon.scss';

export default function BatteryIcon(props) {
    if (props.charge === 0) {
        return (<div className="battery-txt"><span>{Math.trunc(props.charge)}% </span><i className="gg-battery-empty"></i></div>)
    } else if (props.charge > 0 && props.charge <= 25) {
        return (<div className="battery-txt"><span>{Math.trunc(props.charge)}% </span><i className="gg-battery-quarter"></i></div>)
    } else if (props.charge > 25 && props.charge <= 50) {
        return (<div className="battery-txt"><span>{Math.trunc(props.charge)}% </span><i className="gg-battery-half"></i></div>)
    } else if (props.charge > 50 && props.charge <= 75) {
        return (<div className="battery-txt"><span>{Math.trunc(props.charge)}% </span><i className="gg-battery-threequarter"></i></div>)
    } else if (props.charge > 75 && props.charge <= 100) {
        return (<div className="battery-txt"><span>{Math.trunc(props.charge)}% </span><i className="gg-battery-full"></i></div>)
    } else return null;
}

