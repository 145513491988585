import alertTypes from './userTypes';

const alert = (state = {}, action) => {
    switch (action.type) {
        case alertTypes.SUCCESS:
            return {
                type: 'success',
                message: action.payload
            };
        case alertTypes.ERROR:
            return {
                type: 'error',
                message: action.payload
            };
        case alertTypes.CLEAR:
            return {};
        default:
            return state
    }
}

export default alert;
