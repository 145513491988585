import http from '../../../../common/duck/httpService';
import userService from '../../../../user/duck/userService';

const { getDataOrError } = http;

function getTwigData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, twigid, params,) {
        const apiEndPoint = `/stem/graph-data/${siteid}/${twigid}/`;
        return http.get(apiEndPoint, {
            params: params,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

//Graph Thumbnail Data
function getGraphThumbnailData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, twigid, params,) {
        const apiEndPoint = `/stem/graph-datas`;
        return http.post(apiEndPoint, {
            params: params,
            cancelToken: source.token,
            siteIds: siteid,
            twigIds: twigid,
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function deleteGraphData() {
    const source = http.CancelAxiosToken.source();
    function request(siteId, twigId, params) {
        const apiEndPoint = `/stem/graph-data/${siteId}/${twigId}`;
        return http.delete(apiEndPoint, {
            params: params,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function deleteGraphDataFromPhant() {
    const source = http.CancelAxiosToken.source();
    function request(siteId, twigId, params) {
        const apiEndPoint = `/stem/phant-data/${siteId}/${twigId}`;
        return http.delete(apiEndPoint, {
            params: params,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getRainFallData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, dateparams) {
        const apiEndPoint = `/stem/get-rain-fall-data/${siteid}`;
        return http.get(apiEndPoint, {
            params: dateparams,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getIrrigationData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, dateparams) {
        const apiEndPoint = `/stem/get-irrigation-data/${siteid}`;
        return http.get(apiEndPoint, {
            params: dateparams,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getPredictionData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid) {
        const apiEndPoint = `/stem/get-prediction-data/${siteid}`;
        return http.get(apiEndPoint, {
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getWaterPerlocationnData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, dateparams) {
        const apiEndPoint = `/stem/wp-s3-data/${siteid}`;
        return http.get(apiEndPoint, {
            params: dateparams,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getRootActivityData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, dateparams) {
        const apiEndPoint = `/stem/root-activity/${siteid}`;
        return http.get(apiEndPoint, {
            params: dateparams,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function getRootActivityDataForSiteId() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, plantingDate) {
        const apiEndPoint = `/stem/ra/${siteid}`;
        return http.get(apiEndPoint, {
            params: plantingDate,
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}


function updateWPManualData(payload) {
    const source = http.CancelAxiosToken.source();
    function request(siteid, dateparams) {
        const apiEndPoint = `/stem/wp-s3-data/${siteid}`;
        return http.put(apiEndPoint, payload,{
            params: dateparams,
            cancelToken: source.token
        })
        .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}


// const updatingWPManualData = (payload, siteId) => {
//     const apiEndPoint = `/stem/wp-s3-data/${siteId}`;

//     return http
//         .put(apiEndPoint, payload)
//         .then((response) =>
//             response.data.data[0]
//         );
// };

const updatingRAManualData = (payload, siteId) => {
    const apiEndPoint = `/stem/ra/${siteId}`;

    return http
        .put(apiEndPoint, payload)
        .then((response) =>
            response.data.data[0]
        );
};

function updateGraphPreferences(graph) {
    return userService.updatePreferences({ graph });
}

function saveTabForSite(tab, siteObjectId, currentTabsForUser) {
    const currentSettings = currentTabsForUser || {};
    return updateGraphPreferences({ siteCategory: { ...currentSettings, [siteObjectId]: tab } });
}

function saveFilterOption(siteId, value) {
    return userService.updatePreferences({
        graphPreference: {
            [siteId]: value
        }
    }).then(response => response.data);
}


function getGddData() {
    const source = http.CancelAxiosToken.source();
    function request(siteid, growthId) {
        const apiEndPoint = `/stem/gdd-envelope/${siteid}/${growthId}`;
        return http.get(apiEndPoint, {
            cancelToken: source.token
        })
            .then(response => response.data.data)
    };
    return { request, cancel: source.cancel }
}

function recalculateEnvelope(siteId) {
    return http.post(`/stem/recompute/gdd-envelope`, siteId)
        .then((response) => getDataOrError(response));
}

const getNotesForSite = (siteId, dateparams) => {
    return http.get(`/notes/${siteId}`, {
        params: dateparams,
    }).then(response => response.data.data);
}

const addNoteForSite = (siteId, payload) => {
    return http.post(`/notes/add/${siteId}`, payload).then(response => response.data.data);
}

const removeNoteForSite = (objectId) => {
    return http.delete(`/notes/delete/${objectId}`).then(response => response.data.data);
}

export default {
    getIrrigationData,
    getPredictionData,
    getTwigData,
    getRainFallData,
    getWaterPerlocationnData,
    getRootActivityData,
    getRootActivityDataForSiteId,
    getGddData,
    updateGraphPreferences,
    recalculateEnvelope,
    saveTabForSite,
    saveFilterOption,
    deleteGraphData,
    deleteGraphDataFromPhant,
    updateWPManualData,
    updatingRAManualData,
    getNotesForSite,
    addNoteForSite,
    removeNoteForSite,
    getGraphThumbnailData
}