import * as Sentry from '@sentry/browser';

function init() {
    Sentry.init({dsn: "https://69346979c7ed42bcaa36429ddd5b82f8@sentry.io/2701237"});
}

function log(...rest) {
    // Sentry.captureMessage({data: rest});
    console.log(rest);
    return rest;
}

function exception(err) {
    // Sentry.captureException(err);
}

export default {
    init,
    exception,
    log
};
