import { default as GForm } from "./form";
import userService from "../user/duck/userService";
import localStorageService from "../common/duck/localStorageService";

const userId = localStorageService.getUserId();
class AbstractForm extends GForm {
  state = {
    disabled: true,
    errors: {},
    responseMsg: null
  };
  onEdit = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };

  onSubmit = async ({ target: input }) => {
    const error = this.validateProperty(input);

    if (!error) {
      userService
        .editProfile(userId, { [input.name]: input.value })
        .then(response => {
          this.setState({ responseMsg: response.message });
        })
        .catch(err => {
          this.setState({ errors: { serviceError: err.message } });
        });

      this.setState({
        disabled: !this.state.disabled
      });
    }
  };
}

export default AbstractForm;
