import React from "react";
import {Card, Form} from "react-bootstrap";
import GForm from "../../common/form";
import logo from "../../../assets/images/common/groguru-long-logo.png";
import {connect} from "react-redux";
import userActions from "../duck/userActions";
import localStorageService from "../../common/duck/localStorageService";
import {capitalise} from "../../common/duck/utils";
import Joi from "joi-browser";
import Message from "../../common/messages";
import groupService from "../../group/duck/groupService";
import {history} from "../../common/duck/history";

class InviteMembers extends GForm {

    state = {
        data: {
            email0: "",
            type0: "admin",
            email1: "",
            type1: "admin"
        },
        errors: {},
        serviceError: null,
        range: [0, 1]
    }

    schema = {
        email0: Joi.string().optional().allow('').email({minDomainAtoms: 2}).label('Email'),
        type0: Joi.any(),
        email1: Joi.string().optional().allow('').email({minDomainAtoms: 2}).label('Email'),
        type1: Joi.any()
    }

    componentDidMount() {
        if (!this.props.profileDetails) {
            this.props.getProfile(localStorageService.getUserId());
        }
    }

    doSubmit = () => {
        const {profile} = this.props.profileDetails;
        this.setState({serviceError: null});
        const users = [];
        const userCount = Object.keys(this.state.data).length / 2;
        for (let i = 0; i < userCount; ++i) {
            if (this.state.data[`email${i}`]) {
                users.push({email: this.state.data[`email${i}`], type: this.state.data[`type${i}`]})
            }
        }
        if (!users.length) {
            history.push("/dashboard")
            return;
        }
        groupService.inviteMember(profile.organisation.objectId, profile.organisation.type, {users})
            .then(() => {
                history.push("/dashboard");
            })
            .catch((error) => {
                this.setState({serviceError: error.response.data.message});
            });
    }

    onBack = () => {
        history.goBack();
    }

    render() {
        const {profile} = this.props.profileDetails;
        const options = [{_id: 'admin', name: 'Administrator'}, {_id: 'user', name: 'Member'}];
        return (
            <div className="invite-member-container">
                <div className="invite-member-image-container">
                    <div className="content mb-40 mt-40">
                        <div className="text-center  ">
                            <img src={logo} className="groguru_long_logo" alt="logoimg"/>
                            <h1>Welcome to GroGuru!</h1>
                            <p>
                                You’ve been invited to join the {profile.organisation.name} team.
                                Please fill in the information below to get started.
                            </p>
                        </div>
                        <Card className="mt-40">
                            <Card.Header className="d-flex justify-content-between">
                                <div>New {capitalise(profile.organisation.type)} Team Set-Up</div>
                                <div>
                                    <button onClick={this.onBack}>Back</button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>
                                    Finally, invite any other members of your team and choose their
                                    permissions. Administrators can add or remove any other team
                                    member.
                                </Card.Title>

                                <Form className="mt-20">
                                <div className="invitation-area">
                                    {this.state.range.map((range) => {
                                        return (

                                            <div className="row mr-10" key={range}>
                                                <div className="col-md-6 ">
                                                    {this.renderFormGroup(`email${range}`, 'New Member Email',
                                                        'text', {placeholder: 'email@email.com'})}

                                                </div>
                                                <div className="col-md-6 ">
                                                    {this.renderSelect(`type${range}`, 'Member Level',
                                                        options)}
                                                </div>
                                            </div>

                                        );
                                    })}
                                     </div>
                                    <button onClick={this.onAddRow} className="invite-more mb-15">Invite More +</button>
                                    {this.renderButton('Finish', 'btn btn-submit mt-20')}
                                    {this.state.serviceError && <Message variant="error" message={this.state.serviceError}/>}
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }

    onAddRow = (e) => {
        e.preventDefault();
        const inviteeDetails = this.state.data;
        const inviteeCount = Object.keys(inviteeDetails).length / 2;
        this.schema[`email${inviteeCount}`] = Joi.string().allow('').email({minDomainAtoms: 2}).label('Email');
        this.schema[`type${inviteeCount}`] = Joi.any();
        const invitees = {...inviteeDetails};
        invitees[`email${inviteeCount}`] = '';
        invitees[`type${inviteeCount}`] = 'admin';
        const range = [...this.state.range];
        range.push(inviteeCount);
        this.setState({data: invitees, range});
    }
}

const mapDispatchToProps = {
    getProfile: userActions.getProfile
};

const mapStateToProps = (state) => {
    const {profileDetails} = state.userDetails;
    return {profileDetails}
}

const connectedInviteMembers = connect(mapStateToProps, mapDispatchToProps)(InviteMembers);
export {connectedInviteMembers as InviteMembers};
