import React from 'react';
import {connect} from 'react-redux';
import userActions from "./duck/userActions";
import Joi from 'joi-browser';
import Form from "../common/form";
import logo from "../../assets/images/login/groguru-long-logo/groguru-long-logo@3x.png";
import {history} from "../common/duck/history";
import Message from "../common/messages";

class Login extends Form {

    state = {
        data: {
            email: "", password: ""
        },
        errors: {},
    }
    schema = {
        email: Joi.string().required(),
        password: Joi.string().required()
    };

    doSubmit = () => {
        const {email, password} = this.state.data;
        this.props.login(email, password);
    }

    render() {
        const {error, loggingIn} = this.props.loginDetails;
        return (
            <div className="image-wrapper">
                <div className="container mb-40">
                    <div className="text-center pt-25 ">
                        <img src={logo} className="groguru_long_logo" alt="logo"/>
                    </div>
                    <div className="card border-0 shadow  card-style mx-auto">
                        <div className="card-body  ">
                            <h5 className="card-title">Sign In</h5>
                            <p className="card-desc">Log in to your account to continue.</p>
                            <form className="form-signin">
                                {this.renderInput("email", "Username")}
                                {this.renderInput("password", "Password", {type: "password"})}
                                {this.renderButton("Login",
                                    "btn btn-lg btn-submit btn-block mt-30 sign-in-text cp",
                                    loggingIn, "Logging In...")}
                               <div className="d-flex justify-content-between pl-5 pr-10 cp">
                               <h3 className="sign-up-text text-center "
                                    onClick={() => history.push("/signup")}>Don’t have an account? Sign up!</h3>
                                      <h3 className="sign-up-text text-center "
                                  >Forgot Password</h3>
                               </div>
                            </form>
                            {error && <Message variant="error" message={error.message}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {loginDetails} = state.userDetails;
    return {loginDetails};
}

const mapDispatchToProps = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export {connectedLogin as Login};
