const TOO_SMALL_ERROR = 'Length of template data is too low';
const INVALID_START_DAYS_ERROR = 'Has invalid start dates';
const DUPLICATE_START_DAYS_ERROR = 'Has duplicate \'Start Date\'s';
const LIGHT_BLUE_NOT_100_ERROR = 'Dark Blue region has values less than 100';
const INVALID_COLOR_REGIONS_ERROR = 'Color series has invalid data';
const DATA_MISSING_ERROR = 'Template has color region values missing';


function invalidLength(templateData) {
    return templateData.length > 1? null: TOO_SMALL_ERROR;
}

function invalidStartDates(templateData,DuplicateData) {
    let start_days = {};
    for (let row of templateData) {
        if (Number.isNaN(row.start_day)) {
            return INVALID_START_DAYS_ERROR;
        } else if (start_days[row.start_day] && !DuplicateData.includes(row.start_day)) {
            return DUPLICATE_START_DAYS_ERROR;
        }
        start_days[row.start_day] = 1;
    }
    return null;
}

function invalidLightBlue(templateData) {
    for (let row of templateData) {
        if (row.light_blue !== 100) {
            return LIGHT_BLUE_NOT_100_ERROR;
        }
    }
    return null;
}

function emptyColorSeries(templateData) {
    for (let row of templateData) {
        if (
            Number.isNaN(row.red) ||
            Number.isNaN(row.green) ||
            Number.isNaN(row.yellow) ||
            Number.isNaN(row.light_blue)
            ) {
            return DATA_MISSING_ERROR;
        }
    }
    return null;
}

function invalidColorSeries(templateData) {
    for (let row of templateData) {
        if (
            row.green > row.light_blue ||
            row.yellow > row.green ||
            row.red > row.yellow ||
            row.green < 0 ||
            row.yellow < 0 ||
            row.red < 0
        ) {
            return INVALID_COLOR_REGIONS_ERROR;
        }
    }
    return null;
}

export default function validateTemplateData(templateData, DuplicateData) {
    return (
        invalidLength(templateData) ||
        invalidStartDates(templateData,DuplicateData) ||
        invalidLightBlue(templateData) ||
        emptyColorSeries(templateData) ||
        invalidColorSeries(templateData)
    );
}

export function orderByStartDay(templateData, key = 'start_day') {
    const orderedData = templateData.slice();
    orderedData.sort((a, b) => a[key] - b[key]);
    return orderedData;
}