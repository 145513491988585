/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import iconimage from "../../assets/images/left-arrow/chevron-left.png";
import SelectFarm from "./selectFarm";
import Menu from "./menu";
import farmService from "../farm/duck/farmService";
import siteService from "../sites/duck/siteService";
import NotificationTabContent from "./sidemenu/notificationTabContent";
import UserAccessTabContent from "./sidemenu/userAccessTabContent";
import SettingsTabContent from "./sidemenu/settingsTabContent";
import SiteBody from "./sidemenu/siteBody";
import SitesMap from "./sitesMap";
import MultiFarmMap from "./multiFarmMap";
import { Spinner } from "react-bootstrap";
import userService from "../user/duck/userService";
import userAction from '../user/duck/userActions';
import { connect } from "react-redux";
import farmsActions from '../farm/duck/farmActions';
import { history } from "../common/duck/history";

function FarmDetails(props) {
  const [sites, setSites] = useState([]);
  const [singleSite, setSingleSite] = useState(null);
  const [multiFarms, setMultiFarms] = useState([]);
  const farmid = props.match.params.farmid;

  const [navOpen, setNavOpen] = useState(true);
  const [menuOpen] = useState(true);
  const toggleSideNav = () => {
    setNavOpen(!navOpen);
  };

  const [farmdetails, setFarmDetails] = useState(
    props.location.state ? props.location.state.farmdetails : null
  );

  const [multiFarmsdetails, setMultiDetails] = useState([]);
  const [multiSites, setMultiSites] = useState([]);
  const [flag, setFlag] = useState(false);
  const [check, setCheck] = useState(false);
  const [view, setView] = useState('?query=Active');
  const [lastselectsites, setLastSelectedSites] = useState(false);
  const [selectAll, setSelectAll] = useState();
  const [updatedFarmdata, setUpdatedFarmData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [multiFarmLoader, setMultiFarmLoader] = useState(false);
  const selectedFarmIds = useRef([]);
  const [status, setStatus] = useState("?query=Active");
  const [singleFarmDetails, setSingleFarmDetails] = useState([]);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [loadSelectedFarms, setLoadSelectedFarms] = useState(false);
  const [mainLoadings , setMainLoadings] = useState(false)

  const { allFarms } = props.userPreferences?.preferences?.preference?.defaultLanding;
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      siteService
        .getFarmDetails(farmid)
        .then((response) => {
          setFarmDetails(response.data);
        })
      siteService.getSiteDetails(farmid).then((response) => {
        if (response && response.data) {
          setIsLoading(false);
          setSites(response.data);
        }
      });
    }
    const arr = [];
    arr.push(props.history.location?.pathname)
    setPreviousUrl(arr);


    return () => {
      mounted = false;
    };
  }, [farmid, props.history.location.pathname]);

  const { getMultipleFarms } = props;

  const a = useCallback((getMultipleFarms) => {
    let selectedarray = [];
    getMultipleFarms.map((id) => selectedarray.push(id.objectId));
    setMultiDetails(getMultipleFarms);
    Farms(getMultipleFarms);
    setFlag(true);
    setMultiFarms(selectedarray);
    handleSelectedView("?query=Active");
  }, []
  )
  const { lastFarmVisited } = props.userPreferences?.preferences?.preference?.defaultLanding;

  useEffect(() => {
    if (getMultipleFarms && getMultipleFarms?.length !== 0 && !lastselectsites) {

      a(getMultipleFarms)
    }


  }, [getMultipleFarms, lastselectsites, a])

  useEffect(() => {

    if (multiFarms.length === 1) {
        const newfarm = multiFarms[0];
        let path = props.history?.location?.pathname;

        if(newfarm && path){
          path = path.replace(`/farm/${farmid}`, `/farm/${newfarm}`);
          props.history.push({
            pathname: path,
            search: props.location.search
          });
        }
        setSingleSite(multiFarms[0]);
    
    } else {
      setSingleSite(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiFarms, setSingleSite]);

  const { updateDefaultLanding, getSelectedMultiFarm } = props;
  useEffect(() => {
    if (lastFarmVisited) {
      userService.updatePreferences({
        selectedFarm: farmid
      }).then(response => updateDefaultLanding(response.data));
    }
  }, [farmid, updateDefaultLanding, lastFarmVisited]);



  useEffect(() => {
    let data;
    function getmultiPartialSelectedFarms() {
      let allFarmIds = selectedFarmIds.current;
      let reqFarms = allFarmIds.splice(0, 10);
      selectedFarmIds.current = allFarmIds;

      if (reqFarms.length !== 0) {
        Farms(reqFarms, response => {
          setMultiDetails(farms => [...farms, ...reqFarms]);
          setMultiSites(siteDetailsList => [...siteDetailsList, ...response]);
        });
      }

    }
    function multipleFarmsLanding(id, selectAll) {
      setMultiSites([]);
      setMultiDetails([]);
      selectedFarmIds.current = [...id];
      let selectedarray = id.map((id) => id.objectId);
      setMultiFarms(selectedarray);
      setSelectAll(selectAll);
      if (id?.length !== 0) {
        getmultiPartialSelectedFarms();
        setFlag(true);
        handleSelectedView("?query=Active");
        setLastSelectedSites(true);
        getSelectedMultiFarm(id);
      }

      else {
        let name = farmdetails?.name;
        let farm = [];
        let ids = [];
        ids.push(farmid)
        farm.push({ "name": name, "objectId": farmid })
        setMultiDetails(farm);
        Farms(farm);
        setMultiFarms(ids);
        setFlag(true);
        handleSelectedView("?query=Active");
        getSelectedMultiFarm(farm);
        setLastSelectedSites(true);
        userService.updatePreferences(
          { "lastVisitedFarms": selectedarray }
        ).then(response => updateDefaultLanding(response.data));

      }

    }
    if (allFarms && !loadSelectedFarms) {
      setMultiFarmLoader(true);
      const reqObj = farmService.getFarmsForUserRequest();
      reqObj
        .request()
        .then((farms) => {
          data = farms.map(({ name, objectId }) => ({
            name,
            objectId,
          }));
    
          multipleFarmsLanding(data, true);
          setMultiFarmLoader(false);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFarmDetailsUpdate = useCallback(update => {
    setUpdatedFarmData(updatedFarmdata => ({ ...updatedFarmdata, ...update }))
    setFarmDetails(farmdetails => ({ ...farmdetails, ...update }));
  }, []);

  const handleSingleFarmDetailsUpdate = useCallback(update => {
    setSingleFarmDetails(singleFarmDetails => ({ ...singleFarmDetails, ...update }));
  }, []);

  const mainLoad = () => {
    setMainLoadings(true)
  }
  const Farms = (id, callback = setMultiSites) => {

    let selectedarray = id.map(selectedFarm => selectedFarm?.objectId).filter(oId => oId);

    siteService
      .getSitesForFarms(selectedarray)
      .then((response) => {
        response.map((farm, index) => {
          const data = []
          farm[selectedarray[index]].map((sites) => {
             if(sites.twigDetails.settings?.extracted === 'true' || sites.deleted){
               //Extracted site
             }
             else{
               data.push(sites)
             }
          })
          response[index][selectedarray[index]] = data
        })
        callback(response)
      });

    if (selectedarray.length === 1) {

      siteService
        .getFarmDetails(selectedarray)
        .then((response) => {
          setSingleFarmDetails(response.data);
          setFarmDetails(response.data);
        })

    }

    setCheck(check => !check)
  }

  function handleSelectedView(view) {
    setView(view)
  }
  function getPartialSelectedFarms(onStart, onComplete) {
    let allFarmIds = selectedFarmIds.current;
    let reqFarms = allFarmIds.splice(0, 10);
    selectedFarmIds.current = allFarmIds;

    if (onStart) {
      onStart();
    }

    if (reqFarms.length !== 0) {
      Farms(reqFarms, response => {
        setMultiDetails(farms => [...farms, ...reqFarms]);
        setMultiSites(siteDetailsList => [...siteDetailsList, ...response]);
        if (onComplete) {
          onComplete();
        }
      });
    }

    if (selectedFarmIds.current.length === 0) {
      //
    }


  }


  function handleMultipleFarms(id, selectAll) {
    setMultiSites([])
    setMultiDetails([])
    selectedFarmIds.current = [...id];
    let selectedarray = id.map((id) => id.objectId);
    setMultiFarms(selectedarray);
    setSelectAll(selectAll);


    if (id?.length !== 0) {
      if (!selectAll) {
        if(id?.length === 1){
          setFlag(true);
          setLoadSelectedFarms(true);
          setMultiFarms(selectedarray);
          handleSelectedView("?query=Active");
          userService.updatePreferences({ "lastVisitedFarms": selectedarray }).then(response => updateDefaultLanding(response.data));
          setLastSelectedSites(true)
          props.getSelectedMultiFarm(id)
          setMultiDetails(id);
          selectedFarmIds.current = [];
          Farms(id);
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
        else{
          setFlag(true);
          setLoadSelectedFarms(true);
          setMultiFarms(selectedarray);
          handleSelectedView("?query=Active");
          userService.updatePreferences({ "lastVisitedFarms": selectedarray }).then(response => updateDefaultLanding(response.data));
          setLastSelectedSites(true)
          props.getSelectedMultiFarm(id)
          setMultiDetails(id);
          selectedFarmIds.current = [];
          Farms(id);
        }
      }
      else {
        getPartialSelectedFarms();

        setFlag(true);
        handleSelectedView("?query=Active");
        userService.updatePreferences(
          { "lastVisitedFarms": selectedarray }
        ).then(response => updateDefaultLanding(response.data));
        setLastSelectedSites(true)
        props.getSelectedMultiFarm(id)
      }



    }

    else {
      let name = farmdetails?.name;
      let farm = [];
      let ids = [];
      ids.push(farmid)
      farm.push({ "name": name, "objectId": farmid })
      setMultiDetails(farm);
      Farms(farm);
      setMultiFarms(ids);
      setFlag(true)
      handleSelectedView("?query=Active")
      props.getSelectedMultiFarm(farm)
      setLastSelectedSites(true)
      userService.updatePreferences(
        { "lastVisitedFarms": selectedarray }
      ).then(response => updateDefaultLanding(response.data));


    }

    setMainLoadings(false)
  }

  function fetchMoreFarmsOnScroll(onStart, onCompleted) {
    if (selectedFarmIds.current.length !== 0) {
      getPartialSelectedFarms(onStart, onCompleted);
    }
  }


  function farmListing(name, physicalAddress, location) {
    function a(names, Address, location) {
      return (
        <div className="header-text">
          <div className="d-flex">
            <h1>{names}</h1>
            <div className="corner-arrow">
              <SelectFarm onSubmit={handleMultipleFarms} updatedFarmdata={updatedFarmdata} mainLoad={mainLoad} />
            </div>
          </div>
          <p> {Address || physicalAddress}</p>
        </div>
      );
    }

    let length = "";
    if (multiFarms.length === 1) length = "singlefarm";
    else if (
      multiFarms !== [] &&
      multiFarms.length !== 0 &&
      multiFarms.length !== 1
    )
      length = "multifarms";
    else length = "default";

    switch (length) {
      case "singlefarm":
        return multiFarmsdetails[0]
          ? a(multiFarmsdetails[0].name, '')
          : a(" ", " ");

      case "multifarms":
        let totalfarms = multiFarms.length;
        return a("Multiple Farms", `${totalfarms} total farms selected`);

      default:
        return a(name, physicalAddress, location);
    }
  }
  function renderFarmDetails() {
    if (!farmdetails) {
      return (
        <div className="spinnerCentre">
          <Spinner animation="grow" size="lg" />
        </div>
      );
    }
    if (allFarms && multiFarmLoader) {
      return (
        <div className="spinnerCentre">
          <Spinner animation="grow" size="lg" />
        </div>
      );
    }


    function handleStatusChange(value) {
      setStatus(value)
    }
    const { name, physicalAddress, location } = farmdetails;
    if(mainLoadings){
      return (
        <div className="spinnerCentre">
          <Spinner animation="grow" size="lg" />
         </div> 
      )
    }
    return (
      <div className="d-flex">
       {/* { mainLoadings && <div className="spinnerCentre">
          <Spinner animation="grow" size="lg" />
         </div> } */}
        <div
          id="mySidenav"
          className={`sidenav ${navOpen ? "fetch-navbar" : "a-links"}`}
        >
          <div className="d-flex bg-white">
            <Menu open={menuOpen} farmdetails={farmdetails} />
            {farmListing(name, physicalAddress, location)}
          </div>
          <hr></hr>
          <div className="navbar-section  ">
            <Switch>
              <Route
                path="/farm/:farmid/sites/notifications"
                component={NotificationTabContent}
              />
              <Route
                path="/farm/:farmid/sites/user-access"
                render={(props) => (
                  <UserAccessTabContent {...props} multi={multiFarms} farmdetails={farmdetails} />
                )}
              />
              <Route
                path={"/farm/:farmid/sites/settings"}
                render={(props) => (
                  <SettingsTabContent {...props} farmdetails={farmdetails}
                    singleFarmDetails={singleFarmDetails}
                    farmdetailsUpdated={handleFarmDetailsUpdate}
                    singleFarmdetailsUpdated={handleSingleFarmDetailsUpdate}
                    singleSiteId={singleSite} />
                )}
              />

              {multiFarms.length !== 0 && multiFarms !== [] ? (
                <>
                  <Route
                    exact
                    path={"/farm/:farmid/sites"}
                    render={(props) =>
                      multiFarmsdetails.length !== 0 && (
                        <SiteBody
                          {...props}
                          multiFarmsdetails={multiFarmsdetails}
                          multiSites={multiSites}
                          flag={flag}
                          handleStatusChange={handleStatusChange}
                          multiFarms={multiFarms}
                          selectedView={handleSelectedView}
                          farmdetails={farmdetails}
                          onScroll={fetchMoreFarmsOnScroll}
                          singleFarmDetails={singleFarmDetails}
                          selectAll={selectAll}
                        />
                      )
                    }
                  />
                </>
              ) : (
                <>
                  {!(previousUrl.includes(`/farm/${farmid}/sites`) && props.history.location?.pathname === "/farm/farmid/sites") ?
                    <>
                      <Route
                        exact
                        path={"/farm/:farmid/sites"}
                        render={(props) => (
                          <SiteBody
                            {...props}
                            sitedetails={sites}
                            multiFarmsdetails={multiFarmsdetails}
                            handleStatusChange={handleStatusChange}
                            selectedView={handleSelectedView}
                            farmdetails={farmdetails}
                            flag={flag}
                            isLoading={isLoading}
                          />
                        )}
                      />
                    </>
                    : history.goBack()
                  }
                </>
              )}
            </Switch>
          </div>
          <div className="navbar-section-area">
            {singleSite ? (<div className="navbar d-flex justify-content-center">
              <NavLink to={`/farm/${singleSite}/sites${status}`} exact>
                <span className="icon-map  "></span>
              </NavLink>
              <NavLink to={`/farm/${singleSite}/sites/notifications`}>
                <div className="position-relative">
                  <span className="icon-bell "></span>
                  {/* <span className="notification_num position-absolute">2</span> */}
                </div>
              </NavLink>
              <NavLink to={`/farm/${singleSite}/sites/user-access`}>
                <span className="icon-account"></span>
              </NavLink>
              <NavLink to={`/farm/${singleSite}/sites/settings`}>
                <span className="icon-controller"></span>
              </NavLink>
            </div>) : (<div className="navbar d-flex justify-content-center">
              <NavLink to={`/farm/${farmid}/sites${status}`} exact>
                <span className="icon-map  "></span>
              </NavLink>
              <NavLink to={`/farm/${farmid}/sites/notifications`}>
                <div className="position-relative">
                  <span className="icon-bell "></span>
                  {/* <span className="notification_num position-absolute">2</span> */}
                </div>
              </NavLink>
              <NavLink to={`/farm/${farmid}/sites/user-access`}>
                <span className="icon-account"></span>
              </NavLink>
              <NavLink to={`/farm/${farmid}/sites/settings`}>
                <span className="icon-controller"></span>
              </NavLink>
            </div>)}

          </div>
        </div>

        <div id="main" className={` ${navOpen ? "content-move" : ""}`}>
          <div className="arrow-icon-container cp">
            <div
              onClick={toggleSideNav}
              style={{
                position: "relative",
                zIndex: "100",
                background: "white",
              }}
              className="arrow-style"
            >
              <img
                src={iconimage}
                className={navOpen ? "arrow-icon " : ""}
                alt=""
              ></img>
            </div>

            {multiFarms.length !== 0 && multiFarms !== [] ? (
              <MultiFarmMap
                center={{
                  lat: 59.95,
                  lng: 30.33,
                }}
                multiFarmsdetails={multiFarmsdetails}
                zoom={15}
                userRole={props.userRole}
                sites={multiSites}
                navOpen={navOpen}
                check={check}
                multiFarms={multiFarms}
                selectedView={view}
              ></MultiFarmMap>
            ) : (
              <SitesMap
                center={{
                  lat: farmdetails.location.latitude || 59.95,
                  lng: farmdetails.location.longitude || 30.33,
                }}
                zoom={15}
                farmid={farmid}
                sites={sites}
                navOpen={navOpen}
                selectedView={view}
                userRole={props.userRole}
              ></SitesMap>

            )}
          </div>
        </div>
      </div>
    );
  }

  return renderFarmDetails();
}

const mapStateToProps = state => ({
  userPreferences: state.userDetails.userPreferences,
  getMultipleFarms: state.farms.multiFarmdata,
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
 

});
const mapDispatchToProps = {

  updateDefaultLanding: userAction.updateDefaultLanding,
  getSelectedMultiFarm: farmsActions.getSelectedMultiFarm,
};
export default connect(mapStateToProps, mapDispatchToProps)(FarmDetails);
