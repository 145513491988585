import React, { useEffect, useState } from 'react';
import { Card, Table } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import configService from "../configuration/duck/configService";

function ChangeLog(props) {
  const [changelog, setChangelog] = useState([])
  const objectid = props.match.params.siteObjectId;
  const twigObjectId = props?.siteDetails?.twigDetails?.twigObjectId ? props?.siteDetails?.twigDetails?.twigObjectId :
    props?.hardwaredetails?.twigDetails?.twigObjectId;

  useEffect(() => {
    let mounted = true;
    const logsValues = ["site name",
      "add local sensor",
      "type of local sensor",
      "planting date",
      "crop type"];
    if (mounted) {
      configService.getChangelog(objectid, twigObjectId).then((res) => {

        let tempArray = res.changeLogs.filter(i => {
          return logsValues.indexOf(i.friendlyName) !== -1;
        });
        setChangelog(tempArray);
      }
      );
    }

    return () => {
      mounted = false;
    };
  }, [objectid, twigObjectId])


  useEffect(() => {
    const logsValues = ["site name",
      "add local sensor",
      "type of local sensor",
      "planting date",
      "crop type"];
    if (props.updateChangeLogs) {

      configService.getChangelog(objectid, twigObjectId).then((res) => {

        let tempArray = res.changeLogs.filter(i => {
          return logsValues.indexOf(i.friendlyName) !== -1;
        });
        setChangelog(tempArray);
      }
      );
    }

  }, [props.updateChangeLogs, objectid, twigObjectId])


  function updateMonth(month) {
    switch (month) {
      case 0: return "January";
      case 1: return "February";
      case 2: return "March";
      case 3: return "April";
      case 4: return "May";
      case 5: return "June";
      case 6: return "July";
      case 7: return "August";
      case 8: return "September";
      case 9: return "October";
      case 10: return "November";
      case 11: return "December";
      default: return "";
    }
  }

  function updateHour(hour) {
    if (hour <= 0) {
      return { hour: "12", zone: "am" };
    } else if (hour > 12) {
      const newhour = hour - 12;
      return { hour: newhour, zone: "pm" };
    } else {
      return { hour, zone: "am" };
    }
  }

  function renderName(name) {
    if (name === 'add local sensor') {
      return 'TSS';
    } else if (name === 'type of local sensor') {
      return 'TSS type'
    } else {
      return name;
    }
  }

  return <div className="changelog-container">
    <Card>
      <Card.Header >
        <div>
          <h6> Changelog</h6>
        </div>

      </Card.Header>

      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Change</th>
              <th>Old Value</th>
              <th>New Value</th>
              <th>Date</th>
              <th>Modified By</th>
            </tr>
          </thead>
          <tbody>
            {changelog && changelog.map((log, index) => {

              const date = new Date(log.createdAt);
              const day = date.getDate();
              const month = updateMonth(date.getMonth());
              const year = date.getFullYear();
              const { hour, zone } = updateHour(date.getHours());
              const minute = date.getMinutes();

              return <tr>
                <td>{renderName(log.friendlyName)}</td>
                <td>{log.oldValue}</td>
                <td>{log.newValue}</td>
                <td>{month} {day}, {year} - {hour}:{minute < 10 ? '0' + minute : minute}{zone}</td>
                <td>{log.user.name}</td>
              </tr>
            })}



          </tbody>
        </Table>


      </Card.Body>


    </Card>

  </div>;
}

export default withRouter(ChangeLog)
