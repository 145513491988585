import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { DashboardNavBar } from "./dashboardNavBar";
import { Elements } from '@stripe/react-stripe-js';
import FarmBody from "../farm/farmBody";
import GroupBody from "../group/groupBody";
import ShopBody from "../shop/shopBody";
import ContactForm from "../contact/contactForm";
import Help from "../contact/help";
import EditMyAccount from "../user/profile/editMyAccount";
import { connect } from "react-redux";
import paymentService from '../shop/duck/paymentService';
import { loadStripe } from '@stripe/stripe-js';


class Dashboard extends Component {

  stripePromise = paymentService
    .getStripePublishableKey()
    .then(key => loadStripe(key));

  render() {

    return (
      <div>


        <DashboardNavBar />
        <Elements stripe={this.stripePromise}>
          <Switch>
            <Route path="/dashboard/farms" component={FarmBody} />
            <Route path="/dashboard/groups" component={GroupBody} />
            <Route path="/dashboard/shop" component={ShopBody} />
            <Route path="/dashboard/contact" component={ContactForm} />
            <Route path="/dashboard/help" component={Help} />
            <Route path="/dashboard/edit-account" component={EditMyAccount} />
            <Redirect from="/dashboard" to={this.getURLFromPreference()} />
          </Switch>

        </Elements>
      </div>
    );
  }



  getURLFromPreference() {
    const { profile } = this.props.profileDetails;
    const { preference } = this.props.userPreferences?.preferences;
    if (profile?.farm?.objectId) {
      return `/farm/${profile.farm.objectId}/sites`;
    }

    if (preference.selectedFarm?.objectId) {
      return `/farm/${preference.selectedFarm.objectId}/sites`;
    }

    /* if (preference?.defaultLanding?.specificFarm && preference.selectedFarm?.objectId) {
      return `/farm/${preference.selectedFarm.objectId}/sites`;
    }
 */
    return '/dashboard/farms';
  }
}

const mapStateToProps = (state) => {
  const { profileDetails, userPreferences } = state.userDetails;
  return { profileDetails, userPreferences };
};

const connectedDashboard = withRouter(connect(mapStateToProps)(Dashboard));
export { connectedDashboard as Dashboard };
