import { nearestLower, nearestUpper } from '../graphUtility';

export default function yaxisScaleOptions(lowestYValue = 0, highestYValue = 0) {
    let min, max;
    let decimalsInFloat = 1;
    let tickAmount;

    if (lowestYValue < 0 && highestYValue < 0) {
        // rssi values are all -ve
        max = -nearestLower(Math.abs(Math.round(highestYValue)));
        min = -nearestUpper(Math.abs(Math.round(lowestYValue)));
    } else if (lowestYValue === highestYValue) {
        min = Math.floor(lowestYValue);
        max = Math.ceil(highestYValue);
        if (min === max) {
            min -= 0.1;
            max += 0.1;
        }
        tickAmount = 2;
    } else if (highestYValue - lowestYValue < 1) {
        min = lowestYValue - 0.01;
        max = highestYValue + 0.01;
        decimalsInFloat = 2;
        tickAmount = 3;
    } else if (highestYValue - lowestYValue < 5) {
        min = nearestLower(lowestYValue, 0.1)
        max = nearestUpper(highestYValue, 0.1)
        decimalsInFloat = 2;
    } else if (lowestYValue < 0 && highestYValue > 0) {
        min = Math.floor(lowestYValue);
        max = nearestUpper(highestYValue, 5); 
    } else {
        min = nearestLower(lowestYValue, 5);
        max = nearestUpper(highestYValue, 5);
    }

    return { min, max, decimalsInFloat, tickAmount }
}