const tokenKey = "token";
const userKey = "userId";
const lastFarm = 'lastVisiedFarm';

async function storeToken(token) {
    localStorage.setItem(tokenKey,token);
}

function removeItems() {
    localStorage.clear();
}

function getToken() {
    return localStorage.getItem(tokenKey);
}

function isTokenAvailable() {
    return (localStorage.getItem(tokenKey)) ? true: false;
}

function getUserId() {
    return localStorage.getItem(userKey);
}

function storeUserId(userId) {
    localStorage.setItem(userKey,userId);
}

function isUserIdAvailable() {
    return (localStorage.getItem(userKey)) ? true: false;
}

export function getLastVisiedFarmId() {
    const setting = JSON.parse(localStorage.getItem(lastFarm));
    if (setting && setting.userId === getUserId()) {
        return setting.farmId;
    }
    return null;
};

export function setLastVisiedFarmId(farmId) {
    localStorage.setItem(lastFarm, JSON.stringify({farmId, userId: getUserId()}));
}

export default {
    storeToken,
    removeItems,
    getToken,
    isTokenAvailable,
    getUserId,
    storeUserId,
    isUserIdAvailable
}
