import React from 'react';
import farmService from '../farm/duck/farmService';
import {GroupTableList} from './groupList';

class GroupViewTeam extends React.Component {

    state = {
        orgsData: null,
        search: ''
    }

    flattenChildren = (data) => data.children
        .concat(data.children.map(child => this.flattenChildren(child)))
        .flat(Infinity)
        .filter(n => n.type)

    componentDidMount() {
        const farms= farmService.getTreeView();
        farms.request()
            .then(head => this.setState({orgsData: this.flattenChildren(head)}));  
    }

    handleSearch = event => this.setState({search: event.currentTarget.value})

    render() {
        const {orgId} = this.props.match.params;

        let data = null;
        if (this.state.orgsData) {
            const orgNode = this.state.orgsData.find(n => n.objectId === orgId);
            data = {};
            if (orgNode?.children.length > 0) {
                orgNode.children.forEach(node => {
                    // filter out farms & items that do not match search
                    if (node.type && node.name.includes(this.state.search)) {
                        if (data[node.type]) {
                            data[node.type].push(node);
                        } else {
                            data[node.type] = [node];
                        }
                    }
                });
            }
            console.log({data, orgNode})
        }

        return (
            <GroupTableList data={data} search={this.state.search} onSearch={this.handleSearch} />
        )
    }
}

export default GroupViewTeam;