import React from "react";
import { Card, Form} from "react-bootstrap";
import defaultimg from "../../assets/images/farms/farm-default-icon.png";
import groupService from './duck/groupService';
import Joi from "joi-browser";
import CForm from '../common/form';
import Message from "../common/messages";
import {capitalise} from '../common/duck/utils';



class EditBasicInfo extends CForm {

  state = {
    prevData: {...this.props.data},
    data: {...this.props.data},
    uploadedImage: null,
    errors: {},
    responseMsg: null
  }

  schema = {
    image: Joi.any(),
    name: Joi.string().required().label('Distributor Name'),
    hqAddress: Joi.string().required().label('Headquarter Address'),
    businessEmail: Joi.string().email().required().label('Business Email'),
    phone: Joi.string().regex(/^\+?[0-9()-]+$/).required().label('Business Phone')
  }

  htmlUpload = React.createRef()
  requestObj = groupService.getGroupsData();
  apiCall = null;

componentDidMount()
{
     const type=this.props.data.type
    
    groupService.getGroupsData().request()
        .then((res) =>

        { 
          type==='groguru' ? this.setState({data:res.groguru[0]}) :

          type==='distributor'? this.setState({data:res.distributor.filter
          (dist=>dist.objectId===this.props.uid)[0]}) :
        type==='business'?this.setState({data:res.business.filter
          (dist=>dist.objectId===this.props.uid)[0]}) : this.setState({data:res.dealer.filter
            (dist=>dist.objectId===this.props.uid)[0]})
          }
             )
}
  onSubmit = (e) => {


    const { name, value } = e.target;
    
    if (!this.validateProperty(e.target) && value !== this.state.prevData[name]) {
      const payload = { [name]: value };
      this.setState({
        errors: { ...this.state.errors, serviceError: null },
        responseMsg: null
      }, () => {
        groupService.editDetails(this.props.uid, payload)
          .then(res => {
            this.setState({
              prevData: { ...this.state.prevData, ...payload },
              responseMsg: `'${name}' updated`
            });
          })
          .catch(error => {
            this.setState({ errors: { serviceError: `${error.message}: '${name}' could not be updated` } });
          });
      })
    }
  }
 
  componentDidUpdate(prevProps) {
    
    
    if ( this.props.data && prevProps.data !== this.props.data) {

      
      this.setState({prevData: this.props.data, data: this.props.data});
    }
  }

  openFileUpload = (e) => {
    this.htmlUpload.current.click();
  }

  onUpload = (e) => {
    const imageData = e.currentTarget.files[0];
    this.setState({ responseMsg: null, errors: {serviceError: null} }, () => {
      groupService.postImage(imageData)
        .then(data=>
        
          groupService.editDetails(this.props.uid,{image:data.file.url,fileId:data.objectId})
            .then(data =>{ this.setState({
              uploadedImage: URL.createObjectURL(imageData),
              data: { ...this.state.data, image: data.image },
              responseMsg: "'image' Updated"
            });
            //this.props.onChange(this.state.uploadedImage)

          
          })
        )
        .catch(error => this.setState({ errors: {serviceError: "'image' Upload Failed"} }));
    });
    
  }
   
 

render() {




 const accountType = capitalise(this.props.data.type) || 'Account';
    return (
      <div className="edit-basic-info">
        <Card >
          <Card.Body>
            <div className="card-header-text">Edit Basic Information</div>
            <hr></hr>
            <div className="card-body-text">
              <div  className="account-profile d-flex">
                <div className="account-img" onClick={this.openFileUpload}>
                  <img src={this.state.uploadedImage ? this.state.uploadedImage 
                    : this.state.data?.image ? this.state.data?.image: defaultimg} alt="icon" />
                </div>
                <p>Edit {accountType} Logo</p>
                <input name="image" type="file" onChange={this.onUpload} ref={this.htmlUpload} style={{ "display": "none" }} />
              </div>

              <Form onBlur={this.onSubmit}>
                <div className="row mt-40" >
                  <div className="col-md-6">
                    {this.renderFormGroup('name', `${accountType} Name`)}
                  </div>
                  <div className="col-md-6">
                    {this.renderFormGroup('hqAddress', 'Headquarter Address')}
                  </div>
                </div>
                <div className="row mt-20" >
                  <div className="col-md-6">
                    {this.renderFormGroup('businessEmail', 'Business Email', 'email')}
                  </div>
                  <div className="col-md-6">
                    {this.renderFormGroup('phone', 'Business Phone', 'tel')}
                  </div>
                </div>
                {/* {this.renderButton('Submit', 'btn-submit btn btn-none')} */}

                {this.state.errors.serviceError &&
                  <Message variant="error" message={this.state.errors.serviceError} />}

                {this.state.responseMsg &&
                  <Message variant="success" message={this.state.responseMsg} />}
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div >
    )
    
  }

 
}

export default EditBasicInfo;
