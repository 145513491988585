import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import ProductDetails from './productDetails';
import ShopHome from './shopHome';
import StandardEmptyCase from '../common/standardEmptyCase';
import UserHasShopAccess from '../shop/UserHasShopAccess';


// let stripePromise = paymentService
//     .getStripePublishableKey()
//     .then(key => loadStripe(key));
const message = <StandardEmptyCase
  mainText="Cannot access this page"
  subText="Please contact your admin to add Plans for your sites" />;

export default function ShopBody(props) {
    return (
      // <Elements stripe={stripePromise}>
      <UserHasShopAccess fallback={message}>
        <Switch>
          <Route path="/dashboard/shop/product/:productId" component={ProductDetails} />
          <Route path="/dashboard/shop/" component={ShopHome} />
          <Redirect to="/dashboard/shop/" />
        </Switch>
      </UserHasShopAccess>
      // </Elements>
    );
}