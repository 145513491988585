import React, { Component } from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import searchIcon from "../../../assets/images/dashboard/search/group-10.png";
import defaultimg from "../../../assets/images/farms/farm-default-icon.png";
import defaultFarmIcon from "../../../assets/images/emptycase_icon/bitmap.png";
import groupService from '../duck/groupService';
import Message from "../../common/messages";

function ResultItem(props) {

  const headImg = props.org.image || defaultimg;
  const openDoneModal = () => props.openDoneModal({
    title: `Added ${props.org.name}`,
    message: `We've added ${props.org.name} to this farm. All admins and users now have access to this site and can update information as necessary.`,
    image: headImg
  })

  return (
    <ListGroup.Item className="d-flex" onClick={openDoneModal}>
      <div className="default-img">
        <img
          src={headImg}
          alt="default-icon"
          className="default-icon" />
      </div>
      <div className="list-text ">
        <h4>{props.org.name}</h4>
        <p>{props.org.hqAddress}</p>
      </div>
    </ListGroup.Item>
  )
}

function Results(props) {
  const matchedOrgs = () => {
    if (props.data.length)
      return props.data.map(
        org => <ResultItem
          key={org.objectId}
          org={org}
          openDoneModal={props.openDoneModal} />
      )
  }
  return (
    <React.Fragment>
      <ListGroup>
        {matchedOrgs()}
      </ListGroup>
      {props.error &&
        <Message
          variant="error"
          message={`${props.error.message}: ${(props.error.response && props.error.response.message) || 'You might be offline'}`} />}
      <Button
        onClick={props.onClickCreate}
        variant="none"
        className="text-right">
        Create a New {props.label} +
        </Button>
    </React.Fragment>
  );
}

function EmptyCase(props) {
  return (
    <div className="empty-case-modal">
      <div className="empty-img mx-auto">
        <img src={defaultFarmIcon} alt="img"></img>
      </div>
      <h4 className="pt-15">Begin typing to find a {props.label}…</h4>
    </div>
  )
}

class InviteUser extends Component {

  state = { q: '', data: null, error: null }

  requestObj = groupService.getGroupsData();
  apiCall = null;

  searchResult() {
    if (this.state.data)
      return <Results
        error={this.state.error}
        label={this.props.label}
        data={this.state.data}
        onClickCreate={this.props.onClickCreate}
        onClickItems={this.props.openDoneModal}
        openDoneModal={this.props.openDoneModal} />;
    return <EmptyCase label={this.props.label.toLowerCase()} />;
  }

  onSearch = e => {
    const searchQuery = e.target.value;
    this.setState({ q: searchQuery, error: null });
    if (!searchQuery)
      return;
    const type = this.props.label.toLowerCase();
    if (this.apiCall !== null && this.requestObj) {
      this.requestObj.cancel('SEARCH_SKIPPED');
      clearTimeout(this.apiCall);
      this.apiCall = null;
      this.requestObj = null;
    }
    this.apiCall = setTimeout(() => {
      this.requestObj = groupService.getGroupsData();
      this.requestObj.request({name: searchQuery, type})
        .then(data =>  this.setState({
          data: (data[type] && data[type].map(({ name, hqAddress, image, objectId }) => ({ name, hqAddress, image, objectId }))) || []
        }))
        .catch(error => !error.isCancelled && this.setState({ data: [], error }));
    }, 600);
  }

  render() {
    const labelLowerCase = this.props.label.toLowerCase();
    return (
      <Modal
        className="user-invitation-modal"
        show={this.props.show}
        onHide={this.props.onClose}
        size="lg"
        centered>
        <Modal.Header closeButton={false}>
          <p>Invite a New {this.props.label}</p>
          <span
            className="text-right cp"
            onClick={this.props.onClose}>
            Close
            </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-para">
            <p>
              Begin by typing in the name of the new {labelLowerCase}. If the {labelLowerCase} is in our system, they will appear in the results.
              If they are not, you can add their basic information and invite an administrator. The email associated with the administrator will finish signing up and be automatically added to your site ID.
              </p>
          </div>

          <div className="select-section">
            <form className="search-bar d-flex">
              <div className="search-icon">
                <img src={searchIcon} alt="search-icon"></img>
                <input
                  type="text"
                  value={this.state.q}
                  placeholder={`Search for a ${labelLowerCase}`}
                  onChange={this.onSearch}
                  className="pl-30"
                />
              </div>
            </form>
            {this.searchResult()}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default InviteUser;
