import React from "react";
import {Card, Form} from "react-bootstrap";
import GForm from "../../common/form";
import logo from "../../../assets/images/common/groguru-long-logo.png";
import profileImage from "../../../assets/images/farms/farm-create/group-2.png";
import userActions from "../duck/userActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import localStorageService from "../../common/duck/localStorageService";
import Joi from "joi-browser";
import {capitalise} from "../../common/duck/utils";
import groupService from "../../group/duck/groupService";
import Message from "../../common/messages";
import {history} from "../../common/duck/history";

class BusinessInformation extends GForm {

    state = {
        data: {
            name: this.props.profileDetails.profile.organisation.name || "",
            hqAddress: this.props.profileDetails.profile.organisation.hqAddress || "",
            businessEmail: this.props.profileDetails.profile.organisation.businessEmail || "",
            phone: this.props.profileDetails.profile.organisation.phone || "",
            image: this.props.profileDetails.profile.organisation.image
        },
        errors: {},
        serviceError: null,
        uploadedImage: null,
    }
    schema = {
        name: Joi.string().required().label('Name'),
        hqAddress: Joi.string().required().label('Headquarter Address'),
        businessEmail: Joi.string().email({minDomainAtoms: 2}).label('Business Email'),
        phone: Joi.string().trim().regex(/\(?([+]?)([0-9]{4})?\)?([ .-]?)\(?([0-9]{3})\)?([ .-]?)([0-9]{3})([ .-]?)([0-9]{4})/).required().label('Business Phone').error(() => { return { message: 'Invalid Phone Number' }}),
        image: Joi.any()
    };

    htmlUpload = React.createRef()

    componentDidMount() {
        if (!this.props.profileDetails) {
            this.props.getProfile(localStorageService.getUserId());
        }
    }

    openFileUpload = (e) => {
        this.htmlUpload.current.click();
    }

    onUpload = (e) => {
        const {profile} = this.props.profileDetails;
        const image = e.currentTarget.files[0];
        this.setState({serviceError: null}, () => {
            groupService.postImage(image)
                .then(data => {
                    groupService.editDetails(profile.organisation.objectId, { image: data.file.url,fileId:data.objectId})
                        .then(data => this.setState({
                            uploadedImage: URL.createObjectURL(image),
                        }));
                })
                .catch(error => this.setState({ errors: {serviceError: "'image' Upload Failed"} }));
        });
    }

    doSubmit = () => {
        const {profile} = this.props.profileDetails;
        this.setState({serviceError: null});
        const payload = {...this.state.data};
        groupService.editDetails(profile.organisation.objectId, payload)
            .then(() => {
                this.props.getProfile(localStorageService.getUserId());
                history.push(`/signUp/org/personalInfo`);
            })
            .catch(error => this.setState( {serviceError: error.response.data.message} ));
    }

    render() {
        const {profile} = this.props.profileDetails;
        return (
            <div className="business-info">
                <div className="business-info-image-container">
                    <div className="content mb-40 mt-40">
                        <div className="text-center  ">
                            <img src={logo} className="groguru_long_logo" alt="logopicture"/>
                            <h1>Welcome to GroGuru!</h1>
                            <p>
                                You’ve been invited to create a new {profile.organisation.type} team.
                                Please fill in the information below to get started.
                            </p>
                        </div>
                        <Card className="mt-40">
                            <Card.Header className="d-flex justify-content-between">
                                <div>New {capitalise(profile.organisation.type)} Team Set-Up</div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>
                                    First fill out the information necessary for
                                    your new {profile.organisation.type} team profile including business logo, address,
                                    email and phone. The name has been prefilled for you but you may
                                    change it if necessary.
                                </Card.Title>
                                <h6 className="mt-40">Basic Business Information</h6>
                                <div className="d-flex  mt-20">
                                    <div className="d-flex" onClick={this.openFileUpload}>
                                    <img 
                                        src={this.state.uploadedImage || (this.state.data && this.state.data.image) || profileImage}
                                        alt="business profile"/>
                                    <h3>Add a Logo +</h3>
                                    </div>
                                    <input name="image" type="file" onChange={this.onUpload} ref={this.htmlUpload}
                                           style={{"display": "none"}}/>
                                </div>

                                <Form className="mt-10">
                                    <div className="row">
                                        <div className="col-md-6 ">
                                            {this.renderFormGroup('name',
                                                `${capitalise(profile.organisation.type)} Name`,
                                                "text")}
                                        </div>
                                        <div className="col-md-6 ">
                                            {this.renderFormGroup('hqAddress', 'Headquarter Address',
                                                "text", {placeholder: "Enter Address Here"})}
                                        </div>
                                    </div>
                                    <div className="row mt-0">
                                        <div className="col-md-6 ">
                                            {this.renderFormGroup('businessEmail', 'Business Email',
                                                "text", {placeholder: "info@business.com"})}
                                        </div>
                                        <div className="col-md-6 ">
                                            {this.renderFormGroup('phone', 'Business Phone',
                                                "text", {placeholder: "(XXX) XXX - XXXX"})}
                                        </div>
                                    </div>
                                    {this.renderButton('Next: Create Your Profile', 'btn btn-submit mt-20')}
                                    {this.state.serviceError &&
                                    <Message variant="error" message={this.state.serviceError} />}
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getProfile: userActions.getProfile
};

const mapStateToProps = (state) => {
    const {profileDetails} = state.userDetails;
    return {profileDetails}
}

const connectedBusinessInfo = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BusinessInformation)
);
export {connectedBusinessInfo as BusinessInformation};
