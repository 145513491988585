import React from 'react';

export default class Pins extends React.Component {
 
    render() {
   
      
      return (
        
<svg style={this.props.style}  width={this.props.width}
            height={this.props.height} version="1.1" id="Layer_1" 
            xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
viewBox="0 0 1366 768"  xmlSpace="preserve">




<path className="st0" style={{'fill': this.props.insideFill}} d="M550.1,500.5C567.3,471.3,657,316.1,657,256.7c0-65-48.1-117.9-106.9-117.9c-42,0-78.4,26.9-95.8,65.9	c-4.9,10.9-8.2,22.5-9.8,34.3C446.7,299.8,537.2,478.5,550.1,500.5z"/>
<path d="M414.1,206.5c16.1-42.2,83.2-124.5,135.7-124c82.5,0.7,152.6,78.7,149.3,166c-2.1,55-78.9,228-146,350	c-39-70.1-90.6-180.9-121.7-254.7l-48.2-0.1C430,470.3,525.8,633.8,549.1,673.5c30.9-52.5,182-318.1,182-425c0-117.2-71.1-197-177-197c-75.7,0-136.6,36.7-168,107c-8.8,19.6-25,93-25,93l136,5c-0.3-6.8-0.5-12.5,0.1-18.6	c0.9-8.2,1.5-17.2,4.4-31.4H414.1z"/>
<path className="st0" style={{'fill': this.props.insideFill}} d="M473.5,176.5c20.6-30.6,46.3,228.3,20.5,167l-83.9-0.3c39.3,106.5,121.6,249.2,141.2,282.5c26-44.1,161.9-279.5,161.9-369.4c0-98.5-72.9-178.7-161.9-178.7c-63.6,0-118.8,40.7-145.2,99.8c-7.4,16.5-12.4,34-14.9,52h107c1.9-17.7,5.6-35.1,11.2-52L473.5,176.5z"/>
<path className="st0" style={{'fill': this.props.insideFill}} d="M401.8,291.3c0,0-1,23.9,8.4,52s86.4,14,86.4,14l-19-83.3L401.8,291.3z"/>
<path className="st1" style={{'fill': this.props.borderFill}} d="M333.8,291.1h167c-2.3-11.5-3.5-23.2-3.7-34.9c0-5.6,0-11.2,0.6-17.1H380.3c0-3.3,1.1-9.8,1.1-9.8c2.3-17.9,7-35.4,14-52c27.3-64.7,87-109.7,155.9-109.7c94.8,0,171.9,84.6,171.9,188.6c0,101.6-160.8,370.1-167.6,381.7l-4.3,7.1	l-4.3-7.1c-5.2-8.7-104.1-174.3-147.1-294.7h-52.3c39.5,121.1,134.3,282.2,157,320.3l46.7,77.4l46.7-77.7	c29.2-48.6,174.7-296.2,174.7-407.1C772.8,124.9,673.5,18,551.3,18C455.1,18,373,84.7,342.6,177.3c-5.6,16.9-9.3,34.3-11.2,52	c0,0-0.6,6.6-0.8,9.8c-0.4,5.6-0.6,11.2-0.6,17.1C330.2,267.9,331.4,279.6,333.8,291.1z"/>

</svg>
      );
    }
  }

  