import React from 'react';
import { Nav } from "react-bootstrap";
import { connect } from 'react-redux';
import userActions from '../../../user/duck/userActions';
import graphService from './duck/graphService';


function NavItem({ eventKey, name }) {
    return (
        <Nav.Item as="li">
            <Nav.Link eventKey={eventKey}>{name}</Nav.Link>
        </Nav.Item>
    )
}

function GraphNavbar({ siteId, measurementOptions, measurements, setMeasurements, graphPreferences, updateGraphPreferencesForSite, dataPoints, plan, setSubscriptionAlert, userRole, onTabChangeCalled }) {
    let currentTab;
    if (measurements.includes("BT") && measurements.includes("RSSI")) {
        currentTab = "SYS";
    } else if (!measurements.includes("RAIN") && !measurements.includes("IRR") && measurements.length > 1) {
        currentTab = "custom";
    } else {
        currentTab = measurements[0];
    }
    onTabChangeCalled(currentTab)

    return (
        <div className="navbar-area">
            {measurementOptions.length > 0 && <Nav onSelect={handleNavChange} activeKey={currentTab} as="ul">
                {measurementOptions.map(item => <NavItem
                    key={item.key}
                    eventKey={item.key}
                    name={item.name} />
                )}
                {measurementOptions.length > 1 &&
                    <NavItem eventKey="custom" name="Custom/Combined" />}
            </Nav>}
        </div>
    )

    function handleNavChange(eventKey) {
        onTabChangeCalled(eventKey)
        if (eventKey === 'custom') {
            const selected = measurementOptions.slice(0, 2).map(m => m.key)
            setMeasurements(selected);
            saveTab(selected);
        } else if (eventKey === 'SYS') {
            setMeasurements(["BT", "RSSI"]);
            saveTab(["BT", "RSSI"]);
        } else {
            if (eventKey === 'GDD' && plan === "Silver" && userRole !== "groguru_admin") {
                setSubscriptionAlert(true)
            } else {
                setMeasurements([eventKey]);
                saveTab([eventKey]);
            }
        }
    }

    function saveTab(measurements) {
        const currentSettings = graphPreferences?.[siteId];
        graphService.saveFilterOption(siteId, { ...currentSettings, measurements })
            .then(newPreference => updateGraphPreferencesForSite(newPreference));
    }
}

const mapStateToProps = state => ({
    graphPreferences: state.userDetails.userPreferences.preferences.preference?.graphPreference
})

const mapDispatchToProps = {
    updateGraphPreferencesForSite: userActions.updateGraphPreferencesForSite
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphNavbar);