import React from 'react';
import { Form, Dropdown } from "react-bootstrap";
import {useParams} from 'react-router-dom';
import TemplateSettings from '../graph/templateSettingsModal';
import { TIME_PERIODS } from '../graph/duck/constants';

export default function TemplateFilter({
  dateParams,
  appliedTemplateId,
  applyTemplate,
  templateList,
  setTemplateList,
  onDateParamChange,
  cropType,
  siteDetails,
}) {

  const {siteObjectId} = useParams();

  return (
    <div className="template-filter-container">
      <div className=" d-flex template-filters" >
        <Form.Group>
          <Form.Label>Dates</Form.Label>
          <DateFilter value={dateParams} onChange={onDateParamChange} />
        </Form.Group>
        <Form.Group >
          <Form.Label>Template</Form.Label>
            <TemplateSettings
              cropType={cropType}
              templateList={templateList}
              setTemplateList={setTemplateList}
              fromDate={dateParams.fromDate}
              appliedTemplate={appliedTemplateId}
              applyTemplate={applyTemplate}
              siteObjectId={siteObjectId}
              siteDetails={siteDetails}
              savePreference={false} />
        </Form.Group>

      </div>
    </div>
  );
}

function DateFilter({ value, onChange }) {

  const options = Object.entries(TIME_PERIODS).map(([key, name]) => ({key, name}));
  const selectedOption = options.find(opt => opt.key === value);

  return (
    <Dropdown  onSelect={onChange}>
      <Dropdown.Toggle style={{background: 'white', color: 'black'}}>{selectedOption?.name}</Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map(opt => <Dropdown.Item
          key={opt.key}
          eventKey={opt.key}>{opt.name}</Dropdown.Item>)}
      </Dropdown.Menu>
    </Dropdown>
  )
}