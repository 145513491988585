import http from '../../common/duck/httpService';
import localStorageService from '../../common/duck/localStorageService';

const customerAPI = '/customers';

function createCustomer(profile) {
    return http.post(customerAPI, profile);
}

function updateCustomer(body) {
    const userid = localStorageService.getUserId();
    return http.patch(`${customerAPI}/${userid}`, body)
        .then(response => http.getDataOrError(response));
}

function updateCustomerToken(token) {
    return updateCustomer({token});
}

function updateCustomerDefaultPaymentSource(sourceId) {
    return updateCustomer({
        sourceId,
        changeDefaultPayment: true
    });
}

export default {
    createCustomer,
    updateCustomerToken,
    updateCustomerDefaultPaymentSource
}