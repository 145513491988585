import React, { Component } from "react";
import { Dropdown, Card } from "react-bootstrap";
import SiteEmptyCase from "../sites/sidemenu/siteEmptyCase";
import { connect } from "react-redux";
import search from "../../assets/images/dashboard/search/group-10.png";
import farmService from "../farm/duck/farmService";
import profileIcon from "../../assets/images/placeholder/group.png";
import farmActions from '../farm/duck/farmActions';
import { Link } from "react-router-dom";
import { findFarms } from "../farm/farmsTreeView";

class SearchByGroups extends Component {
  state = {
    data: '',
    distributor: [],
    dealer: [],
    business: [],
    farmsSelected: [],
    noResultsdist: false,
    noResultsdealer: false,
    noResultsbus: false,
    searchQuery: null,
    open: false

  }
  componentDidMount() {

    const farms = farmService.getTreeView();

    farms.request().then(data => {

      let distributor = [];
      let dealer = [];
      let business = [];

      data.children && data.children.map((child) => child.type === 'distributor' ? distributor.push(child) :
        child.type === 'dealer' ? dealer.push(child) : child.type === 'business' ? business.push(child) : '')
      this.setState({ distributor, data, dealer, business })

    }
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.sort !== this.props?.sort) {
      this.setState({ searchQuery: '' });
      this.props.farmSelect(null, '');
      this.getNewAccountsList('')
    }
  }

  getNewAccountsList = (searchQuery) => {

    if (searchQuery.length === 0) {
      this.props.sort === "Recently Added" ? this.props.getFarms(this.props.search, 'createdAt') : this.props.getFarms(this.props.search);
      this.props.farmSelect(null, '');
      this.setState({ noResultsdist: false, noResultsdealer: false, noResultsbus: false, searchQuery: null })
    } else {

      if (searchQuery.length > 0) {

        let distributor = [];
        let dealer = [];
        let business = [];
        let dist = [];
        let deal = [];
        let bus = [];
        let children = [];
        children = this.state.data?.children;


        children && children.map((child) => child.type === 'distributor' ? dist.push(child) : child.type === 'dealer' ? deal.push(child) : child.type === 'business' ? bus.push(child) : '')


        dist.map((child) => child.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1 ? distributor.push(child) : '');

        if (distributor.length !== 0) {
          this.setState({ distributor, noResultsdist: false });
        } else {
          this.setState({ noResultsdist: true })
        }

        deal.map((child) => child.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1 ? dealer.push(child) : '');

        if (dealer.length !== 0) {
          this.setState({ dealer, noResultsdealer: false });
        } else {
          this.setState({ noResultsdealer: true });
        }


        bus.map((child) => child.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1 ? business.push(child) : '');

        if (business.length !== 0) {
          this.setState({ business, noResultsbus: false });
        } else {
          this.setState({ noResultsbus: true });
        }
      }
    }
  }

  escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }


  onSearchChange = (e) => {

    this.setState({ dealer: [], distributor: [], business: [] });
    let trimmedstr = e.target.value;
    let searchStr = trimmedstr.replace(/\s+$/, '');
    let searching = this.escapeRegExp(searchStr);

    let searchQuery = e.target.value;
    this.setState({ searchQuery });
    this.getNewAccountsList(searching)
  }

  node = (children) => {
    let farmSelected = [];
    children.map((child) => child?.children?.length > 0 ? getFarmFromChildren(child?.children) : child?.type ? '' : farmSelected.push(child))


    function getFarmFromChildren(children) {
      children.map((child) => child?.children?.length > 0 ? getFarmFromChildren(child?.children) : child?.type ? '' : farmSelected.push(child))
    }

    return this.props.getSelectedFarm(farmSelected);
  }

  handleClick = ({ children, name, objectId }) => {
    if (children.length === 0) {
      this.props.farmSelect('', '');
      this.props.getSelectedFarm([]);
      return <Link to={"/dashboard/farms"}></Link>
    }
    this.props.farmSelect(name, objectId);
    this.node(children);
  }

  render() {

    let distributor = [];
    let dealer = [];
    let business = [];
    let children = [];
    children = this.state.data?.children
    children && children.map((child) => child.type === 'distributor' ? distributor.push(child) :
      child.type === 'dealer' ? dealer.push(child) : child.type === 'business' ? business.push(child) : '')


    return (
      <React.Fragment>
        <div className="master-account">
          <Dropdown >
            <Dropdown.Toggle variant="none" id="dropdown-basic" className="d-flex">

              <div className="pl-30">
                <p className="text-left">All Farms you have access to</p>

              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="search-bar d-flex">
                <img
                  src={search}
                  alt="search icon"
                  className="search-icon"
                ></img>
                <form className="dropdown-search" onSubmit={(event) => event.preventDefault()}>
                  <input type="text" placeholder="Search Accounts..." value={this.state.searchQuery || ''} onChange={this.onSearchChange}></input>
                </form>
              </div>
              <div className="scroller">
                <div className="menu-contents">

                  <p>Distributors</p>

                  {!this.state.noResultsdist && this.state.searchQuery && this.state.distributor.length !== 0 ? this.state.distributor.map((dist) =>

                    <Dropdown.Item key={dist.objectId}>
                      <Card onClick={() => this.handleClick(dist)}>
                        <Card.Body className="d-flex">
                          <div className="img-icon">
                            <img src={dist.image ? dist.image : profileIcon} alt="icon"></img>
                          </div>
                          <div className="text">
                            <h3>{dist.name}</h3>
                            <p>{findFarms(dist.children)}farms</p>
                            {/* <span className="red"> (1 needing attention) </span> */}

                          </div>
                        </Card.Body>
                      </Card>

                    </Dropdown.Item>) :
                    this.state.noResultsdist ?
                      <SiteEmptyCase search={this.state.searchQuery} /> :

                      this.state.searchQuery === null && !this.state.noResultsdist && distributor.map((dist) =>

                        <Dropdown.Item key={dist.objectId}>
                          <Card onClick={() => this.handleClick(dist)}>
                            <Card.Body className="d-flex">
                              <div className="img-icon">
                                <img src={dist.image ? dist.image : profileIcon} alt="icon"></img>
                              </div>
                              <div className="text">
                                <h3>{dist.name}</h3>
                                <p>{findFarms(dist.children)}farms</p>
                                {/* <span className="red"> (1 needing attention) </span> */}

                              </div>
                            </Card.Body>
                          </Card>

                        </Dropdown.Item>)}


                  <p>Dealers</p>

                  {!this.state.noResultsdealer && this.state.searchQuery && this.state.dealer.length !== 0
                    ? this.state.dealer.map((deal) =>
                      <Dropdown.Item key={deal.objectId}>
                        <Card onClick={() => this.handleClick(deal)}>
                          <Card.Body className="d-flex">
                            <div className="img-icon">
                              <img src={deal.image ? deal.image : profileIcon} alt="icon"></img>
                            </div>

                            <div className="text">
                              <h3>{deal.name}</h3>
                              <p>{findFarms(deal.children)}farms </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Dropdown.Item>) :

                    this.state.noResultsdealer === true ?
                      <SiteEmptyCase search={this.state.searchQuery} /> :




                      this.state.searchQuery === null && this.state.noResultsdealer === false && dealer.map((deal) =>
                        <Dropdown.Item key={deal.objectId}>
                          <Card onClick={() => this.handleClick(deal)}>
                            <Card.Body className="d-flex">
                              <div className="img-icon">
                                <img src={deal.image ? deal.image : profileIcon} alt="icon"></img>
                              </div>

                              <div className="text">
                                <h3>{deal.name}</h3>
                                <p>{findFarms(deal.children)}farms </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Dropdown.Item>)


                  }



                  <p>Business</p>

                  {this.state.noResultsbus === false && this.state.searchQuery && this.state.business.length !== 0
                    ? this.state.business.map((deal) =>
                      <Dropdown.Item key={deal.objectId}>
                        <Card onClick={() => this.handleClick(deal)}>
                          <Card.Body className="d-flex">
                            <div className="img-icon">
                              <img src={deal.image ? deal.image : profileIcon} alt="icon"></img>
                            </div>

                            <div className="text">
                              <h3>{deal.name}</h3>
                              <p>{findFarms(deal.children)}farms </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Dropdown.Item>) :

                    this.state.noResultsbus === true ?
                      <SiteEmptyCase search={this.state.searchQuery} /> :




                      this.state.searchQuery === null && this.state.noResultsbus === false && business.map((deal) =>
                        <Dropdown.Item key={deal.objectId}>
                          <Card onClick={() => this.handleClick(deal)}>
                            <Card.Body className="d-flex">
                              <div className="img-icon">
                                <img src={deal.image ? deal.image : profileIcon} alt="icon"></img>
                              </div>

                              <div className="text">
                                <h3>{deal.name}</h3>
                                <p>{findFarms(deal.children)}farms </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Dropdown.Item>)


                  }
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getFarms: farmActions.fetchFarms,
  getSelectedFarm: farmActions.getSelectedFarm
};


const mapStateToProps = state => {
  const { profileDetails } = state.userDetails;
  return { profile: profileDetails.profile };
};

const connectedSearchByGroups = connect(mapStateToProps, mapDispatchToProps)(SearchByGroups);

export { connectedSearchByGroups as SearchByGroups };
