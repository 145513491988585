import React, { Component, useState, useEffect, useCallback, useRef } from "react";
import { Dropdown, Button, Modal, Spinner } from "react-bootstrap";
import { DATA_TYPES, TIME_PERIODS } from './duck/constants';
import graphService from './duck/graphService';
import { connect } from 'react-redux';
import SpinnerButton from '../../../common/spinnerButton';
import userActions from '../../../user/duck/userActions';
import * as moment from 'moment';
import { ROLES } from '../../../common/duck/constants';

function displayValue(value, mapping) {
  if (Array.isArray(value)) {
    try {
      return value.map(key => mapping.find(k => k.key === key).name).join(', ');
    } catch (err) {
      return value.join(', ');
    }
  } else {
    let option = mapping.find && mapping.find(k => k.key === value);
    return (option && option.name) || value;
  }
}

function CheckboxSelect({
  filterOptionKey,
  siteId,
  options,
  label,
  value,
  isSaving,
  disableSaveButton = false,
  onChange,
  children,
  isCustom,
  checkboxClassName = 'checkmark',
  getCurrentMergedPreference,
  updateGraphPreferencesForSite,
  toggleIsLoading
}) {

  function savePreference() {
    if (disableSaveButton) {
      return;
    }

    toggleIsLoading(filterOptionKey);
    graphService.saveFilterOption(siteId, getCurrentMergedPreference(filterOptionKey, value))
      .then(newPreference => {
        updateGraphPreferencesForSite(newPreference); // update redux
        toggleIsLoading(filterOptionKey)
      });
  }

  return (
    <div className="dash-columns d-flex flex-column">
      <label className="menu-subheading">{label}</label>
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="none" className={`toggleButton pt-15 toggleMenu-${label}`} >
            {displayValue(value, options)}
          </Dropdown.Toggle>

          <Dropdown.Menu className="smallMenuHeight">
            <div className={`menu-box menu-box-${label} d-flex flex-column justify-content-between`}>
              <label className="d-flex justify-content-between mb-20">
                <h6>{label}</h6>
                <SpinnerButton
                  className={`saveGraphOptions btn btn-none ${disableSaveButton ? "disabledSave" : ''}`}
                  spinning={isSaving}
                  disabled={disableSaveButton}
                  onClick={savePreference}>Save</SpinnerButton>
              </label>
              {options.map(option => {
                const key = option.key || option;
                const name = option.name || option;
                const isChecked = (isCustom && name === 'Custom') ||
                  (value && (value === key || (value.includes && value.includes(key))));
                return (
                  <label key={key}>
                    <div className="first-option d-flex">
                      <label className="label ">
                        <input type="checkbox" name={key} checked={isChecked} onChange={onChange} />
                        <span className={checkboxClassName}></span>
                      </label>
                      <label className="optionFont">{name}</label>
                    </div>
                  </label>
                )
              })}
              {children}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

const CUSTOM_DATE_PERIOD = 'CUSTOM';

export function TimePeriodSelect({ setCustom, ...props }) {
  const savedFromDateFormatted = props.lastSavedDateRange?.fromDate && getNormalDate(props.lastSavedDateRange.fromDate);
  const savedToDateFormatted = props.lastSavedDateRange?.toDate && getNormalDate(props.lastSavedDateRange.toDate);
  const defaultFromDate = savedFromDateFormatted || (
    props.dateParams.fromDate ?
      getNormalDate(props.dateParams.fromDate) : ''
  );
  const defaultToDate = savedToDateFormatted || (
    props.dateParams.toDate ?
      getNormalDate(props.dateParams.toDate) : ''
  );

  const [customDateLocal, setCustomDateLocal] = useState({ fromDate: defaultFromDate, toDate: defaultToDate });
  const [isValid, setIsValid] = useState(false)
  const hasValidDaterange = props.value !== CUSTOM_DATE_PERIOD || isValid

  const prevOption = useRef(null);
  const [isChanged, setIsChanged] = useState(false);

  function getNormalDate(date) {
    const norDate = new Date(date)
    const newnorDate = moment(norDate);
    return newnorDate.format("YYYY-MM-DD");
  }

  function handleCustomDate(event) {
    const { name, value } = event.target;
    setCustomDateLocal(daterange => {
      const newDateRange = { ...daterange, [name]: value };
      setIsChanged(newDateRange.fromDate !== defaultFromDate || newDateRange.toDate !== defaultToDate);
      return newDateRange;
    });
  }

  const checkDaterangeIsValid = useCallback(function checkDaterangeIsValid() {
    const fromDateMS = customDateLocal.fromDate && Date.parse(customDateLocal.fromDate);
    const toDateMS = customDateLocal.toDate && Date.parse(customDateLocal.toDate);
    return !!(fromDateMS && toDateMS && fromDateMS < toDateMS);
  }, [customDateLocal]);

  const handleSubmitCustom = useCallback(() => {
    if (checkDaterangeIsValid()) {
      setCustom({
        fromDate: new Date(customDateLocal.fromDate).toISOString(),
        toDate: new Date(customDateLocal.toDate).toISOString()
      });
      setIsChanged(false);
    }
  }, [setCustom, checkDaterangeIsValid, customDateLocal.fromDate, customDateLocal.toDate]);

  useEffect(() => {
    setIsValid(checkDaterangeIsValid());
  }, [checkDaterangeIsValid]);

  useEffect(() => {
    const isChangeToCustom = (
      props.value === CUSTOM_DATE_PERIOD &&
      prevOption.current !== CUSTOM_DATE_PERIOD
    );

    if (isChangeToCustom) {
      setCustomDateLocal({ fromDate: defaultFromDate, toDate: defaultToDate });
      handleSubmitCustom(); // change graph immediatly to prev selected custom date
    }

    prevOption.current = props.value;
  }, [props.value, defaultFromDate, defaultToDate, handleSubmitCustom])

  return (
    <CheckboxSelect {...props} disableSaveButton={isChanged || !hasValidDaterange} checkboxClassName="checkmark rounded-check" >
      {props.value === CUSTOM_DATE_PERIOD && <div className="customTimePeriod">
        <h6>Custom Time Period</h6>
        <DatePicker
          name="fromDate"
          label="From"
          value={customDateLocal.fromDate}
          onChange={handleCustomDate}
          className="ml-15" />
        <DatePicker
          name="toDate"
          label="To"
          value={customDateLocal.toDate}
          onChange={handleCustomDate} />
        <div className="settingButton d-flex flex-column justify-content-center align-items-center">
          {!isValid && <small style={{ color: 'red' }}>Invalid date range</small>}
          <Button disabled={!isValid} variant="none" className={`setButton ${!isValid ? "btnDisable" : ''}`} onClick={handleSubmitCustom}> Set </Button>
        </div>
      </div>}
    </CheckboxSelect>
  )
}

function DatePicker({ label, name, value, ...props }) {
  return (
    <div className="d-flex justify-content-between">
      <label className="menu-subheading">{label}</label>
      <input type="date" name={name} value={value} {...props} />
    </div>
  )
}

function DateTimePicker({ label, name, value, ...props }) {
  return (
    <div className="d-flex justify-content-between">
      <label className="menu-subheading">{label}</label>
      <input type="datetime-local" name={name} value={value} {...props} />
    </div>
  )
}

function DeleteDataSelect(props) {
  const [dateLocal, setDateLocal] = useState({ fromDate: moment().format("YYYY-MM-DDTHH:mm"), toDate: moment().add('1', 'hour').format("YYYY-MM-DDTHH:mm")});
  const [isValid, setIsValid] = useState(true);
  const [confirmModalState, setConfirmModalState] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function handleDateChange(event) {
     const { name, value } = event.target;
     setDateLocal(dateValues => {
       const newDateRange = {...dateValues, [name]: value}
       const fromDate = newDateRange.fromDate && Date.parse(newDateRange.fromDate);
       const toDate = newDateRange.toDate && Date.parse(newDateRange.toDate);
       setIsValid(fromDate && toDate && ((toDate - fromDate)/1000 >= 3600));
       return newDateRange;
     });
  }

  function onClick() {
    setShowConfirm(true);
    setConfirmModalState({
      message: `Are you sure you want to delete data from ${moment(dateLocal.fromDate).local().format("MMM DD,YYYY-hh:mmA")} to ${moment(dateLocal.toDate).local().format("MMM DD,YYYY-hh:mmA")} (local time) ?`,
      yesLabel: 'OK',
      onYes: onConfirmMySQLDeletion,
    });
  }

  function onConfirmMySQLDeletion() {
    setIsSaving(true);
    const apiCall = graphService.deleteGraphData();
    const params = { fromDate: new Date(dateLocal.fromDate).toISOString(), toDate: new Date(dateLocal.toDate).toISOString() }
    apiCall.request(props.siteId, props.twigId, params)
      .then(data => {
        setIsSaving(false);
        setConfirmModalState({
          message: 'This will delete data permanently. Are you sure you want to proceed ?',
          yesLabel: 'OK',
          onYes: onConfirmPhantDeletion,
        });
        props.forceUpdateGraphData()
      });
  }
  
  function onConfirmPhantDeletion() {
    setIsSaving(true);
    const apiCall = graphService.deleteGraphDataFromPhant();
    const params = { fromDate: new Date(dateLocal.fromDate).toISOString(), toDate: new Date(dateLocal.toDate).toISOString() };
    apiCall.request(props.siteId, props.twigId, params)
      .then(data => {
         onCloseModal();
      });
  }

  function onCloseModal() {
    setShowConfirm(false);
    setIsSaving(false);
  }

  return (
    <div className="dash-columns d-flex flex-column">
      <label className="menu-subheading">{props.label}</label>
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="none" className={`toggleButton pt-15 pl-20 toggleMenu-${props.label}`} >
              Select timeframe
          </Dropdown.Toggle>

          <Dropdown.Menu className="smallMenuHeight">
            <div className={`menu-box menu-box-${props.label} d-flex flex-column justify-content-between`}>
              <label className="d-flex justify-content-between mb-20">
                <h6>{props.label}</h6>
              </label>
              <div className="customTimePeriod">
                <DateTimePicker
                  name="fromDate"
                  label="From"
                  width="85%"
                  value={dateLocal.fromDate}
                  onChange={handleDateChange}
                />
                <DateTimePicker
                  name="toDate"
                  label="To"
                  width="85%"
                  value={dateLocal.toDate}
                  onChange={handleDateChange}
                />
                <div className="settingButton d-flex flex-column justify-content-center align-items-center">
                  {!isValid && <small style={{ color: 'red' }}>Please select "From" and "To" times that are at-least one hour apart</small>}
                  <Button disabled={!isValid} variant="none" className={`setButton ${!isValid ? "btnDisable" : ''}`} onClick={onClick}> Delete </Button>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal
        show={showConfirm}
        onHide={onCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmModalState.message}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-nowrap">
          <button className="btn-submit mr-20" onClick={onCloseModal}>Cancel</button>
          <button className="btn-submit mr-20" onClick={confirmModalState.onYes} disabled={isSaving}>
            {isSaving ? <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : ""}
            {confirmModalState.yesLabel}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
   )
}

function NotesSelect({ notes, showNotes }) {
  return showNotes ?
    (
      <div>
        <div className="first-option d-flex" >
          <div title="Notes" style={{ textAlign: "left" }}>Notes</div>
        </div>
        {[...(notes || [])].sort((a, b) => b.x - a.x).map(eachNote => (
          <div key={`${eachNote.x}__${eachNote.note}`} style={{ wordWrap: 'break-word' }}>
            <span>{moment(eachNote.x).format("MM/DD/YY HH:mm")} - </span>
            <span>{eachNote.note}</span>
          </div>
        ))}
      </div>
    ) : null
}

class GraphFilter extends Component {
  state = {
    displayOptions: [
      { name: "Absolute", key: 'absolute' },
      { name: "Relative", key: 'relative' }
    ],
    timePeriod: Object.entries(TIME_PERIODS).map(([key, name]) => ({ name, key })).concat({ name: 'Custom', key: 'CUSTOM' }),
    dataType: Object.entries(DATA_TYPES).map(([key, name]) => ({ name, key })),
    isSaving: {
      displayOptions: false,
      timePeriod: false,
      dataType: false,
      measurements: false
    }
  };

  toggleIsLoading = filterOptionKey => this.setState(state => ({
    isSaving: {
      ...state.isSaving,
      [filterOptionKey]: !state.isSaving[filterOptionKey]
    }
  }))

  onMenuOptionsChange = (name) => {
    return (event) => {
      if (name === "timePeriod") {
        if (
          event.target.name === "THREE_MONTHS" ||
          event.target.name === "ONE_DAY" ||
          event.target.name === "ONE_WEEK" ||
          event.target.name === "ONE_MONTH"
        ) {
          this.props.graphControls[name].set(event.target.name);
          this.props.graphControls["dataType"].set("5", event.target.name);
        } else {
          this.props.graphControls[name].set(event.target.name);
          this.props.graphControls["dataType"].set("0", event.target.name);
        }
      } else {
        this.props.graphControls[name].set(event.target.name);
      }
    };
  };  

  getCurrentMergedPreference = (filterOptionKey, value) => {
    let updatedValue;
    const currentOptions = this.props.graphPreferences?.[this.props.siteId] || {};

    switch (filterOptionKey) {
      case 'displayOptions':
        updatedValue = value.toUpperCase();
        break;
      case 'dataType':
        updatedValue = value.map(dataTypeInt => DATA_TYPES[dataTypeInt]);
        break;
      case 'timePeriod':
        if (value === 'CUSTOM') {
          updatedValue = {
            name: value,
            fromDate: this.props.dateParams.fromDate,
            toDate: this.props.dateParams.toDate
          };
        } else {
          const { fromDate, toDate } = currentOptions.timePeriod || {};
          updatedValue = {
            name: value,
            fromDate,
            toDate
          }
        }
        break;
      case 'measurements':
        updatedValue = value;
        break;
      default:
        updatedValue = null;
    }

    return { ...currentOptions, [filterOptionKey]: updatedValue };
  }

  render() {

    const {
      timePeriod,
      dataType
    } = this.state;

    return (
      <div className="mainNavbar d-flex flex-column">
        <TimePeriodSelect
          label="Time Period"
          siteId={this.props.siteId}
          options={timePeriod}
          value={this.props.graphControls.timePeriod.get}
          dateParams={this.props.dateParams}
          onChange={this.onMenuOptionsChange('timePeriod')}
          setCustom={this.props.graphControls.timePeriod.setCustom}
          filterOptionKey="timePeriod"
          isSaving={this.state.isSaving.timePeriod}
          lastSavedDateRange={this.props.graphPreferences?.[this.props.siteId]?.timePeriod}
          toggleIsLoading={this.toggleIsLoading}
          getCurrentMergedPreference={this.getCurrentMergedPreference}
          updateGraphPreferencesForSite={this.props.updateGraphPreferencesForSite} />
        <CheckboxSelect
          label="Data Type"
          siteId={this.props.siteId}
          options={dataType}
          value={this.props.graphControls.dataType.get}
          onChange={this.onMenuOptionsChange('dataType')}
          filterOptionKey="dataType"
          isSaving={this.state.isSaving.dataType}
          toggleIsLoading={this.toggleIsLoading}
          getCurrentMergedPreference={this.getCurrentMergedPreference}
          updateGraphPreferencesForSite={this.props.updateGraphPreferencesForSite} />
        {
          (this.props.userRole === ROLES.GROGURU_ADMIN ||
            this.props.userRole === ROLES.DEALER_ADMIN || this.props.userRole === ROLES.DISTRIBUTOR_ADMIN) &&
          (this.props.selectedMeasurement === "AW" ||
            this.props.selectedMeasurement === "MOIST" ||
            this.props.selectedMeasurement === "TEMP" ||
            this.props.selectedMeasurement === "SAL" ||
            this.props.selectedMeasurement === "EC" ||
            this.props.selectedMeasurement === "VIC") ? (
            <DeleteDataSelect
              label="Delete Data"
              siteId={this.props.siteId}
              twigId={this.props.twigId}
              forceUpdateGraphData={this.props.forceUpdateGraphData}
            />
          ) : null
        }
        {
          (this.props.selectedMeasurement === "AW" ||
          this.props.selectedMeasurement === "MOIST") ? (
            <NotesSelect
              notes={this.props.notes}
              showNotes={this.props.showNotes}
            />
          ) : null
        }
      </div >
    );
  }
}

const mapStateToProps = state => ({
  graphPreferences: state.userDetails.userPreferences.preferences.preference?.graphPreference,
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
})

const mapDispatchToProps = {
  updateGraphPreferencesForSite: userActions.updateGraphPreferencesForSite
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphFilter);