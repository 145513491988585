import http from "./httpService";

const {getDataOrError} = http;

function postImage(imageData) {
    const imageFormData = new FormData();
    imageFormData.append('file', imageData);
    return http.post('/file/image', imageFormData)
        .then(response => getDataOrError(response));
}

export default {
    postImage
}
