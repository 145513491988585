import React from 'react';
import FarmCard from "./farmCard";
import FarmLoadingCard from './farmLoadingCard';
import FarmEmptyCase from './farmEmptyCase';
import {filterFarms, getFilterFromQuery,filterFarm,getFilter} from './duck/farmsUtils';
import { connect } from 'react-redux';

function FarmList(props) {

    return (
        <div className="container-fluid">
            <div className="farmCard-container">
                {renderResults()}
            </div>
        </div>
    );
 
    

    function renderResults() {
        if (props.farms === undefined)
            return null;
        if (props.farms === null || props.isLoading) {
            return <FarmLoadingCard/>;
        } else if (props.farms.length === 0) {
            return props.search && <FarmEmptyCase search={props.search}/>
        } else if (props.farms.length > 0) {
            if(!props.agronomic===true){
            return filterFarms(props.farms, getFilterFromQuery(props.location.search)).map(
                farm => <FarmCard key={farm.objectId} data={farm} agronomic={false}/>
            )}
            else
            {
                return filterFarm(props.farms, getFilter(props.location.search)).map(
                    farm => <FarmCard key={farm.objectId} data={farm} agronomic={true}/>)
            }
        } else {
            console.log('Fetch failed with error: ', props.farms);
        }
    }
}
const mapStateToProps = state => ({
    farms: state.farms.data
});

export default connect(mapStateToProps)(FarmList);
