import React, { useState, useEffect, useMemo, useRef } from "react";
import SaveTemplateModal from './saveTemplateModal';
import GraphLoader from '../graph/graphLoader';
import Chart from '../../../common/chart/Chart';
import { getDateParams, chartTooltipXAxisFormatting, toUTC, diffDays } from '../graph/duck/dateUtility';
import { getPoints, nearestUpper, getDatapointsMinMax } from '../graph/duck/graphUtility';
import graphService from '../graph/duck/graphService';
import { Area, Line } from "../../../common/chart/series";
import colors from '../graph/duck/colorUtility';
import templateService from './duck/templateService';
import TemplateCreate from './colorTable/tableBody';
import {capitalise, lastItem} from '../../../common/duck/utils';
import {orderByStartDay} from './duck/templateValidation';

export const templateSampleData = {
  start_day: 1,
  red: 10,
  yellow: 20,
  green: 30,
  light_blue: 100
};

export default function TemplateDetails({
  templateId,
  templateName,
  isTemplateOverwitable,
  cropType,
  plantingDate,
  dateParamKey,
  routeParams,
  onTemplateCreated,
  isTemplateChanged,
  setIsTemplateChanged,
  applyTemplate,
}) {

  const { siteid, twigid } = routeParams;
  const [awSeries, setAwSeries] = useState(null);
  const [dataLoading, setDataLoading] = useState({graph: false, template: false});
  const [isTemplateJustFetched, setIsTemplateJustFetched] = useState(false);
  const [templateColorSeries, setTemplateColorSeries] = useState({isEmpty: true});
  const awScale = useRef({});
  const summaryXValue = useRef({});
  const plantingDateMS = useRef(Date.parse(plantingDate));
  const [shouldUpdatePreview, setShouldUpdatePreview] = useState(false);
  const yAxisMaxScale = useRef(100);

  const [formData, setFormData] = useState([templateSampleData]);

  useEffect(() => {
    plantingDateMS.current = Date.parse(plantingDate);
  }, [plantingDate]);

  useEffect(() => {
    // fetch AW line
    const date = getDateParams(dateParamKey);
    const apiCall = graphService.getTwigData();
    setDataLoading(loading => ({template: loading.template, graph: true}));
    awScale.current = {};
    apiCall.request(siteid, twigid, date)
      .then(data => {
        const awSeriesArray = [];
        let minMax;
        let xValue;
        yAxisMaxScale.current = 100;
        if (data?.graph?.aw?.relative.length > 0) {
          const seriesDataPoints = getPoints(data.graph.aw.relative);
          minMax = getDatapointsMinMax(seriesDataPoints);
          xValue = getDatapointsMinMax(seriesDataPoints, xValue, 'x');
          awSeriesArray.push(Line('AW', seriesDataPoints))
        }
        awScale.current = minMax;
        if (minMax && minMax.max > 100) {
          yAxisMaxScale.current = nearestUpper(minMax.max);
        }
        summaryXValue.current = xValue;
        setAwSeries(awSeriesArray);
        setDataLoading(loading => ({template: loading.template, graph: false}));
      });
    return () => apiCall.cancel('Cancel: AW for Template')
  }, [dateParamKey, siteid, twigid]);

  useEffect(() => {
    // fetch template data
    if (templateId && plantingDate) {
      setDataLoading(loading => ({ graph: loading.graph, template: true }));
      templateService
        .getTemplateData(siteid, templateId, toUTC(plantingDate))
        .then((data) => {
          let colorSeriesData = {};
          for (let color in data.colorSeries) {
            colorSeriesData[color] = data.colorSeries[color].map((p, i) => ({
              x: Date.parse(data.dateSeries[i]),
              y: p
            }));
          }
          setTemplateColorSeries(colorSeriesData);
          setDataLoading(loading => ({ graph: loading.graph, template: false }));
          setIsTemplateJustFetched(true);
        })
        .catch((error) => console.log(error));
    } else if (!templateId && isTemplateChanged) {
      setTemplateColorSeries({isEmpty: true});
      setIsTemplateChanged(false);
    }
  }, [templateId, siteid, plantingDate, isTemplateChanged, setIsTemplateChanged]);

  useEffect(() => {
    // fetched template data -> table
    if (templateId && isTemplateJustFetched) {
      let tableData = [];
      const plantingdate = plantingDateMS.current;
      // const firstXonTemplate = templateColorSeries['red'][0].x;
      for (let i = 0; i < templateColorSeries['red'].length; i++) {
        const colorX = templateColorSeries['red'][i].x;
        let daysFromPlantingDate = diffDays(colorX, plantingdate) + 1;
        // since start date was being reduced by one day so commented
        // if (firstXonTemplate < plantingdate) {
        //   --daysFromPlantingDate;
        // }
        let data = {start_day: daysFromPlantingDate};
        for (let color in templateColorSeries) {
          data[color] = templateColorSeries[color][i]?.y;
        }
        tableData.push(data);
      }
      setFormData(tableData);
      setIsTemplateJustFetched(false);
    } else if (!templateId && isTemplateChanged) {
      setFormData([templateSampleData]);
      setIsTemplateChanged(false);
    }
  }, [templateId, isTemplateJustFetched, templateColorSeries, isTemplateChanged, setIsTemplateChanged]);

  useEffect(() => {
    if (shouldUpdatePreview) {
      // update preview chart when button pressed
      let series = {
        red: [],
        yellow: [],
        green: [],
        light_blue: []
      };

      const DAY = 86400000;
      let plantingdate = plantingDateMS.current;
      let preventChartUpdate = false;

      orderByStartDay(formData).forEach(row => {
        // ms after plantingdate
        const x = plantingdate + (row.start_day * DAY);
        Object.keys(series).forEach(color => {
          const data = parseFloat(row[color]);
          series[color].push({x, y: data});
          if (Number.isNaN(data)) {
            preventChartUpdate = true;
          }
        })
      });

      if (!preventChartUpdate) {
        // do not update in case of error
        setTemplateColorSeries(series);
        setShouldUpdatePreview(false);
      }
    }
  }, [shouldUpdatePreview, formData]);

  const shouldSkipTemplateLoad = (awScale.current && typeof(awScale.current.max) !== 'number') || templateColorSeries.isEmpty;

  // const summaryLineStart = summaryXValue.current?.min;
  // const summaryLineEnd = summaryXValue.current?.max;

  let template = useMemo(() => {
    if (shouldSkipTemplateLoad) {
      return [];
    } else {
      const awMax = nearestUpper(awScale.current?.max || 10);
      const templateSeries = Object.entries(templateColorSeries).map(([color, colorSeries]) => {
        let dataPointsTimeseries = colorSeries.map((point) => ({...point}));
        return Area(
          capitalise(color),
          dataPointsTimeseries,
          // templateForDateRange(dataPointsTimeseries, summaryLineStart, summaryLineEnd), //For Adding padding for template graph 
          { color: colors[color], meta: { isTemplate: true } }
        );
      });

      const templateStartTime = templateSeries[0].data.data[0].x;
      const templateEndingTime = lastItem(templateSeries[0].data.data).x;

      if (awMax > 100) {
        // Dark Blue Area for points > 100
        const specialCaseDataPoints = [{ x: templateStartTime, y: awMax }, { x: templateEndingTime, y: awMax }];
        return [
          ...templateSeries,
          Area(capitalise(colors.dark_blue), specialCaseDataPoints, { color: colors.dark_blue, meta: { isTemplate: true } })
        ].reverse();
      }

      return templateSeries.reverse();
    }
  }, [templateColorSeries, shouldSkipTemplateLoad]);

  let curves = useMemo(() => [...template, ...(awSeries? awSeries: [])], [
    template,
    awSeries
  ]);

  const awMax = yAxisMaxScale.current;
  let seriesYAxis = useMemo(() => [{
      min: 0,
      max: awMax,
      decimalsInFloat: 0,
      title: { text: `Available Water (%)`,offsetX: 10 },
      forceUpdate: Date.now()
      // should not reuse the old yaxis object when the loadedTemplate or the summary data is changed
      // even if the axis.max is not changed
      // this is to prevent axis fallback to default max=100
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }], [awMax, templateColorSeries, awSeries]);

  return (
    <>
    <div className="template-details-container">
      <div className="template-details bg-white">
        <div className="template-header d-flex justify-content-between">
          <h6>{templateName}</h6>
          <SaveTemplateModal
            cropType={cropType}
            formData={formData}
            onTemplateCreated={onTemplateCreated} />
        </div>
        <div className="template-graph">
          {!plantingDate && <small>This site does not have planting date. Template feature will not work for this site.</small>}
          <GraphLoader templateIsLoading={dataLoading.template} graphIsLoading={dataLoading.graph} />
          <Chart
            id="template-creation"
            series={curves}
            yAxis={seriesYAxis}
            zoomType="none"
            tools={false}
            xAxisLabel={chartTooltipXAxisFormatting}
          />
        </div>
      </div>
    </div>
    <TemplateCreate
      templateId={templateId}
      isTemplateOverwitable={isTemplateOverwitable}
      formData={formData}
      setFormData={setFormData}
      templateName={templateName}
      applyTemplate={applyTemplate}
      setTemplateColorSeries={setTemplateColorSeries}
      updatePreview={() => setShouldUpdatePreview(true)} />
    </>
  );
}
