import React, { useEffect, useState } from "react";
import _ from 'lodash';

const MARKER_COLORS = {
  RED: "#e6726c",
  YELLOW: "#ffe119",
  GREEN: "#66b92d",
  LIGHT_BLUE: '#c0c9f0',
  BLUE: "#175c93",
};

export default function ProgressBar(props) {
  const [bucket, setBucket] = useState("");
  useEffect(() => {
    props.bucketValue && setBucket(Math.max(...props.bucketValue));
  }, [props.bucketValue]);

  let RP = props?.RP && props?.RP?.map((rp) => rp === null ? '' : Object.values(rp)[0]);
  let WL1 = props.WL1 && props.WL1.map((wl1) => wl1 === null ? '' : Object.values(wl1)[0]);
  let WL2 = props.WL2 && props.WL2.map((wl2) => wl2 === null ? '' : Object.values(wl2)[0]);
  const { RED, YELLOW, GREEN, LIGHT_BLUE } = MARKER_COLORS;
  if (props.totalpercentage || props.totalpercentage === 0) {
    return (
      // props.totalpercentage &&
      <div style={{ position: 'relative' }}>
        <div className="progress-bar" style={{
          width: "100%",
        }}>
          <Filler
            percentage={props.totalpercentage}
            RP={props.RP && props.RP.map((rp) => Object.values(rp)[0])}
            WL1={props.WL1 && props.WL1.map((wl1) => Object.values(wl1)[0])}
            WL2={props.WL2 && props.WL2.map((wl2) => Object.values(wl2)[0])}
          />
        </div>
        <div className="marker-list">
          <div>
            <div className="triangle-down" style={{ borderTop: `10px solid ${RED}`, left: `calc(${RP + '%'} - 4px)` }}></div>
            <div className="marker" style={{ left: RP + "%", background: RED }}></div>
          </div>
          <div>
            <div className="triangle-down" style={{ borderTop: `10px solid ${YELLOW}`, left: `calc(${WL1 + '%'} - 4px)` }}></div>
            <div className="marker" style={{ left: WL1 + "%", background: YELLOW }}></div>
          </div>
          <div>
            <div className="triangle-down" style={{ borderTop: `10px solid ${GREEN}`, left: `calc(${WL2 + '%'} - 4px)` }}></div>
            <div className="marker" style={{ left: WL2 + "%", background: GREEN }}></div>
          </div>
          <div>
            <div className="triangle-down" style={{ borderTop: `10px solid ${LIGHT_BLUE}`, left: `calc(100% - 4px)` }}></div>
            <div className="marker" style={{ left: "100%", background: LIGHT_BLUE }}></div>
          </div>
        </div>
        <div className="d-flex justify-content-between" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
          <div title="E" className="e-f-label"><p>E</p></div>
          {props.planGold && _.isNumber(props.totalpercentage24HrsBack) ?
            <div className="triangle-up" title={props.totalpercentage24HrsBack} style={{ borderBottom: `10px solid #bfbfbf`, left: props.totalpercentage24HrsBack > 100 ? `calc(${100 + '%'} - 5px)` : `calc(${props.totalpercentage24HrsBack + '%'} - 5px)` }}></div>
            : null
          }
          <div title="F" className="e-f-label"><p>F</p></div>
        </div>
      </div>
    );
  } else {
    return (
      (props.bucketValue || props.bucketValue === 0) && (bucket || bucket === 0) &&

      props.bucketValue.map((value, index) => {
      let bucketWidthActual;
      if (value / bucket === 0 || value / bucket === Infinity || value / bucket < 0) {
         bucketWidthActual = 100;
      } else {
        bucketWidthActual = (value/bucket) * 100;
      }
      let bucketWidthAfterRP = bucketWidthActual - ((props.RP && props.RP[index] - 10) * bucketWidthActual/100); // bucket width after subtracting (RP-10)
      let fillPercentageAfterRP = bucketWidthActual * ((props.percentage && props.percentage[index]) - (props.RP && props.RP[index] - 10))/100; 
      let fillWidthAfterRP =  fillPercentageAfterRP * 100 / bucketWidthAfterRP;
      return (
        <div
          className="progress-bar"
          key={index}
          style={{
            marginTop: (props.type === 'hardware' && props.localSensors) ? props.depthValues[index + (props.localSensors)] + 'px' : props.depthValues[index] + 'px',
            width: bucketWidthAfterRP+ "%",
            overflow: "visible"
          }}
        >
          <FillerForBucketModel
            percentage={props.percentage && props.percentage[index]}
            RP={props.RP && props.RP[index]}
            WL1={props.WL1 && props.WL1[index]}
            WL2={props.WL2 && props.WL2[index]}
            showBucketStart={true}
            fillWidthAfterRP={fillWidthAfterRP}
          />
        </div>
      )})
    );
  }
}



const Filler = (props) => {

  const percentage = props.percentage ? props.percentage : 0;
  const backgroundColor = getHorizontalBarColor(
    percentage,
    props.RP,
    props.WL1,
    props.WL2
  );
  return (
    <div
      className="filler"
      style={{
        background: backgroundColor,
        width: `${percentage >= 100
          ? "100%"
          : percentage === 0
            ? "100%"
            : percentage + "%"
          }`,
      }}
    >
      {(percentage < 10) ? (
        <div className="filler-text-small ">
          <span className="blackColor">{percentage === 0 ? '' : (percentage && percentage + '%')}</span>
        </div>
      ) : (
        backgroundColor !== "#175c93" ?
          <div className="filler-text">
            <span className="blackColor">{percentage + '%'}</span>
          </div> :
          <div className="filler-text">
            <span>{percentage + '%'}</span>
          </div>
      )}
    </div>
  );
};


const FillerForBucketModel = (props) => {

  const percentage = props.percentage ? props.percentage : 0;
  const backgroundColor = getHorizontalBarColor(
    percentage,
    props.RP,
    props.WL1,
    props.WL2
  );
  let fillWidthAfterRP;
  if (props.showBucketStart && props.fillWidthAfterRP) {
     if (props.fillWidthAfterRP >= 100) {
        fillWidthAfterRP = 100;
     } else if (props.fillWidthAfterRP < 0) {
        fillWidthAfterRP = 0;
     } else {
        fillWidthAfterRP = props.fillWidthAfterRP;
     }
  }
  return (
    <>
     {(percentage < 10) ? (
        <div className="filler-text-small filler-text-bucket">
          <span className="blackColor" title={percentage + '%'} style={{backgroundColor:"#A7DCFB"}}>{percentage === 0 ? '' : (percentage && percentage + '%')}</span>
        </div>
      ) : (
        backgroundColor !== "#175c93" ?
          <div className="filler-text filler-text-bucket">
            <span className="blackColor" title={percentage + '%'} style={{backgroundColor:"#A7DCFB"}} >{percentage + '%'}</span>
          </div> :
          <div className="filler-text filler-text-bucket">
            <span className="blackColor" title={percentage + '%'} style={{backgroundColor: "#A7DCFB"}} >{percentage + '%'}</span>
          </div>
    )}
    <div
      className="filler"
      style={{
        background: props.showBucketStart && (props.percentage <= (props.RP - 10)) ? '' : backgroundColor,
        width: props.showBucketStart ? fillWidthAfterRP + "%" 
        : `${percentage >= 100
          ? "100%"
          : percentage === 0
            ? "100%"
            : percentage + "%"
          }`
      }}
    >
    </div>
    </>
  );
};

function getHorizontalBarColor(percentage, RP, WL1, WL2) {

  let Refill = RP;
  let Warning1 = WL1;
  let Warning2 = WL2;


  switch (true) {
    case percentage === 0 || percentage.length === 0:
      return null;

    case percentage > 100:
      return "#175c93";

    case percentage >= Warning2 && percentage <= 100:
      return '#c0c9f0'; //Lightblue

    case percentage >= Warning1 && percentage < Warning2:
      return "#66b92d"; //Green

    case percentage < Warning1 && percentage >= Refill:
      return "#ffe119"; //Yellow

    case percentage > 0 && percentage < Refill:
      return "#e6726c";







    default:
      return "#FFFFFF";
  }
}

