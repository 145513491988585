import axios from "axios";
import logger from "./logService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 501;

    if (!expectedError) {
        logger.log(error)
    }

    return Promise.reject(error);
});

function setToken(sessionToken) {
    axios.defaults.headers.common['Authorization'] = sessionToken;
}

function getDataOrError(response){
    if (response.data.success) {
        return response.data.data;
    } else {
        let error = new Error(response.data.message);
        error.name = response.data.error;
        error.status = response.data.status;
        throw error;
    }
}


export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    setToken,
    CancelAxiosToken: axios.CancelToken,
    isCancel: axios.isCancel,
    getDataOrError
};
