import React, { Component } from "react";
import { Table } from "react-bootstrap";
import TableHead from '../../../common/tableHead';
import { dateTimeFormat } from '../../../sites/siteDetails/graph/duck/dateUtility';
import graphService from './duck/graphService';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  getMapping,
} from '../graph/duck/graphUtility';

class IrrigationTable extends Component {

  tableHeads = [
    "Date",
    this.props.secondColumnHeader,
  ].map((i) => ({ text: i }));

  tableBody = {}
  auto_Body = {}

  state = {
    wpManualSensorDepths: [],
    sensorList: [],
    disabled: true,
    hasPlantingDate:true,
    rootActivityDateManual: []
  }

  componentDidMount() {
    if (this.props.thirdColumnHeader) {
      this.tableHeads.push({ text: this.props.thirdColumnHeader });
      if (this.props.thirdColumnHeader === "RA (manual)") {
        this.tableHeads[0].text = "Sensor Id"
      }
    }

    if (this.props.hardwareSensorLocation) {
      let list = [];
      list.push(0);
      let data = getMapping.calculateDepth(this.props.hardwareSensorLocation);
      let arrayValues = Object.values(data);
      arrayValues.map((eachValue) => list.push(eachValue.depth));
      this.setState({ sensorList: list });
    }
  }

  handleChange = (e, index, date) => {
    const { wpManualSensorDepths } = this.state;
    let value = e.target.value;
    wpManualSensorDepths[index] = { date: date, waterPercolationManual: parseInt(value) };
    this.setState({ wpManualSensorDepths });
  };

  onEdit = (headerName) => {
    if (headerName.includes("WP")) {
      this.setState({
        disabled: !this.state.disabled
      });
      if (!this.state.disabled) {
         this.onSubmitWPData();
        
      }  
    } else if (this.props.plantingDate == null){
      this.setState({
        hasPlantingDate: false
      });

    } else {
      this.setState({
        disabled: !this.state.disabled
      });
      if (!this.state.disabled) {
          this.onSubmitRAData();
      }  

    }
  };

  onSubmitWPData = () => {

    let apiBody = this.state.wpManualSensorDepths.filter((each) => { return each !== null });
    this.setState({wpManualSensorDepths:[]})

    if(apiBody.length > 0){
      this.props.updateWPData(apiBody)
      this.setState({wpManualSensorDepths:[]})
    }
  };

  onSubmitRAData = () => {
    const { rootActivityDateManual } = this.state;
      const tempObj = JSON.parse(JSON.stringify(rootActivityDateManual));
      let apiBody = tempObj.filter((each) => { if (each !== null) { delete each.isEditedNull; } return each !== null });
      if(apiBody.length > 0){
        graphService.updatingRAManualData(apiBody, this.props.siteId).then((res) => {
        }).catch((error) => {
         console.log("error", error);
        });
      }    
  };

  printDate = (date) => {
    return dateTimeFormat(date, 'MM/DD/YYYY');
  }
    
  getTotal = (data) => {
    let sum = 0;
    // eslint-disable-next-line
    data.map((row) => {
      sum = sum + (isNaN(row.y || row.waterPercolation) ? 0 : (row.y || row.waterPercolation))
    })
    return sum;
  }

  handleDate = (date, index, sensorId) => {
    const { rootActivityDateManual } = this.state;
    if (date !== null) {
      rootActivityDateManual[index] = { dateOfFirstRAManual: dateTimeFormat(date, "YYYY-MM-DDThh:mm:ss.000") + "Z", sensorId: sensorId, isEditedNull: false };
      this.setState({ rootActivityDateManual });
    } else {
      rootActivityDateManual[index] = { dateOfFirstRAManual: null, sensorId: sensorId, isEditedNull: true };
      this.setState({ rootActivityDateManual });
    }
  };

  render() {

    const { wpManualSensorDepths, rootActivityDateManual } = this.state;

    return (
      <div className="irrigation-table">
        <div className="template-tablecontainer">
          <div>
            <h6 className="table-heading" style={{ float: 'left' }}>{this.props.title}</h6>
            {
              this.props.data?.length > 0 && (this.props.secondColumnHeader === "WP (auto)" || this.props.secondColumnHeader === "RA (auto)")
                ? <button className="template-button table-button" onClick={() => this.onEdit(this.props.secondColumnHeader)}>{this.state.disabled ? 'Edit' : 'Save'}</button>
                : (this.props.data?.length > 0 && this.props.secondColumnHeader !== "WP (auto)" ? <div style={{ float: 'right', fontSize: '24px' }}><b>Total</b>: <span style={{ padding: '6px 40px', border: '5px solid #575798' }}>
                  {this.getTotal(this.props.data).toFixed(2)}</span></div> : null)
            }
            <div >
              {!this.state.hasPlantingDate && <small style={{ color: 'red' , float: 'right', marginBottom:'0%'}}>Invalid Planting Date</small>}
            </div>

          </div>
          {this.props.data?.length > 0 ?
            (
              <Table striped bordered hover responsive="lg">
                <TableHead heads={this.tableHeads} />
                <tbody>
                  {this.props.data?.sort((a, b) => new Date(b.date || b.x) - new Date(a.date || a.x))
                    .map((row, index) => (
                      this.tableHeads.length === 3 ? (
                        <tr key={row.x+'__'+index}>
                          {this.props.secondColumnHeader === "WP (auto)" ? <td>{this.printDate(row.x)}</td> : (this.props.secondColumnHeader === "RA (auto)" ? <td>{row.sensorId}</td> : <td>{this.printDate(row.x)}</td>)}
                          {this.props.secondColumnHeader === "WP (auto)" ? <td> {row.waterPercolation}</td> : (this.props.secondColumnHeader === "RA (auto)" ? <td> {row.dateOfFirstRA !== "Not detected" ? this.printDate(row.dateOfFirstRA) : "Not detected"}</td> : <td> {row.y}</td>)}
                          <td>
                            {this.props.secondColumnHeader === "WP (auto)" ? (
                              <div className="col-12 pl-0">
                                <div className="selectdiv rowWM">
                                  <select name="sensorDepth" className={`${this.state.disabled ? '' : "whiteBC"}`}
                                    onChange={(event) => { this.handleChange(event, index, row.date) }} disabled={this.state.disabled}
                                    value={wpManualSensorDepths && wpManualSensorDepths[index] && wpManualSensorDepths[index].waterPercolationManual != null ? wpManualSensorDepths[index].waterPercolationManual : (row.waterPercolationManual ? row.waterPercolationManual : 0)}>
                                    {this.state.sensorList.map((eachSensor) => (<option value={eachSensor}>{eachSensor}</option>))}
                                  </select>
                                  <span className="fas fa-caret-down vertical-middle"> </span>
                                </div>
                              </div>
                            )
                              : (
                                <div>

                                  {
                                    rootActivityDateManual && rootActivityDateManual[index] && rootActivityDateManual[index].isEditedNull !== null && rootActivityDateManual[index].isEditedNull ? (
                                      <DatePicker
                                        className="manualDatePicker"
                                        dateFormat="MM/dd/yyyy"
                                        onChange={(date) => this.handleDate(date, index, row.sensorId)}
                                        disabled={this.state.disabled}
                                        placeholderText="Not detected"
                                        minDate={new Date(this.props.plantingDate)}

                                      />
                                    )
                                      : (
                                        <DatePicker
                                        className="manualDatePicker"
                                        selected={rootActivityDateManual && rootActivityDateManual[index] && rootActivityDateManual[index].dateOfFirstRAManual ? new Date(rootActivityDateManual[index].dateOfFirstRAManual) : row.dateOfFirstRAManual !== "Not detected" ? (row.dateOfFirstRAManual && new Date(row.dateOfFirstRAManual)) : ''}
                                        dateFormat="MM/dd/yyyy"
                                        onChange={(date) => this.handleDate(date, index, row.sensorId)}
                                        disabled={this.state.disabled}
                                        placeholderText={row.dateOfFirstRAManual === "Not detected" ? "Not detected" : (row.dateOfFirstRAManual !== null ? this.printDate(row.dateOfFirstRAManual) : '')}
                                        minDate={new Date(this.props.plantingDate)}
                                      />

                                      )
                                  }
                                </div>
                              )
                            }
                          </td>
                        </tr>
                      )
                        : (
                          <tr key={row.x+'__'+index}>
                            {this.props.secondColumnHeader === "WP (auto)" ? <td>{this.printDate(row.x)}</td> : <td>{this.printDate(row.x)}</td>}
                            {this.props.secondColumnHeader === "WP (auto)" ? <td> {row.waterPercolation}</td> : <td> {row.y}</td>}
                          </tr>
                        )
                    )
                    )}
                </tbody>
              </Table>) : null}

        </div>
        {this.props.data?.length > 0 ? null : <div style={{ marginTop: '20px' }}>Data not available</div>}
      </div>
    );
  }
}

export default IrrigationTable;