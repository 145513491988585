import React, { Component } from "react";
import farmsActions from '../farm/duck/farmActions';
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import logo from "../../assets/images/common/groguru-long-logo@3x.png";
import bell from "../../assets/images/dashboard/bell-ring/bell-ring.png";
import userActions from "../user/duck/userActions";
import localStorageService from "../common/duck/localStorageService";
import { NavLink, withRouter, Link } from "react-router-dom";
import editAccount from "../../assets/images/dashboard/edit-account/account-outline.png";
import logout from "../../assets/images/dashboard/logout/exit-to-app.png";
import UserHasShopAccess from '../shop/UserHasShopAccess';
import defaultimg from "../../assets/images/farms/farm-default-icon.png";

class DashboardNavBar extends Component {
  componentDidMount() {
    if (!this.props.profileDetails.profile) {
      this.props.getProfile(localStorageService.getUserId());
    }
  }

  render() {
    const { profile } = this.props.profileDetails;
    const userRole = profile?.role
    const brandLogo = profile?.organisation?.image
    const isGroguruAdmin = userRole.name === 'groguru_admin'
    const isDistributor = userRole.name === 'distributor_admin'
    const parentLogo = profile?.parentsData?.organisation?.image
    let navBrandLogo = logo
    let profileLogo = defaultimg
    if(isGroguruAdmin){
      navBrandLogo = logo
      profileLogo = defaultimg
    }
    if(isDistributor){
      navBrandLogo = brandLogo?.length > 1 ? brandLogo : logo
      profileLogo = brandLogo?.length > 1 ? brandLogo : defaultimg
    }
    if(!isGroguruAdmin && !isDistributor){
      navBrandLogo = parentLogo?.length > 1 ? parentLogo : logo
      profileLogo = parentLogo?.length > 1 ? parentLogo : defaultimg
    }
    return (
      <div className="navbar-container">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="" >
          <Link to="/"><img src={navBrandLogo} className="navbrand" alt="logo" /></Link>
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto active">
            
              <NavLink className="navlink" to="/dashboard/farms">
                Farms
              </NavLink>
              <NavLink className="navlink" to="/dashboard/groups">
                Accounts
              </NavLink>
              {profile.role?.name === 'groguru_admin' ?
              <UserHasShopAccess>
                <NavLink className="navlink" to="/dashboard/shop/subscriptions">
                  Subscription
                </NavLink>
              </UserHasShopAccess>
              : null}  
              <NavLink className="navlink" to="/dashboard/contact">
                Contact
              </NavLink>
              <NavLink className="navlink" to="/dashboard/help">
                Help
              </NavLink>
            </Nav>
            <div className="nav-right">
              <div className="">
                <Dropdown>
                  <Dropdown.Toggle variant="none" id="dropdown-basic">
                    <div className="row">
                      <div className="col-sm-4 pr-20">
                        <div className="profile-img">
                          <img src={profileLogo} alt="pic"></img>
                        </div>
                      </div>
                      <div className="col-sm-8 pr-40 pl-0 text-left">
                        <h6 className="f-22" style={{whiteSpace: 'initial', overflowWrap: 'break-word'}} >{profile && profile.name}</h6>
                        <p className="f-16">{profile && profile.role.name}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/dashboard/edit-account">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex ">
                          <img src={editAccount} alt="icon"></img>
                          <p>Edit Profile</p>
                        </div>

                        <div className="pt-5">
                          <i className="fas fa-caret-right"></i>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/logout" >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex ">
                          <img src={logout} alt="icon"></img>
                          <p>Log Out</p>
                        </div>

                        <div className="pt-5">
                          <i className="fas fa-caret-right"></i>
                        </div>
                      </div>
                    </Dropdown.Item>
                   
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="bell-icon  ">
                <img src={bell} className="" alt="logo" />
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loginDetails, profileDetails } = state.userDetails;
  return { loginDetails, profileDetails};

};

const mapDispatchToProps = {
  getProfile: userActions.getProfile,
  getFarms: farmsActions.fetchFarms,
  getSelectedMultiFarm :farmsActions.getSelectedFarm,
};

const connectedNavBar = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardNavBar)
);
export { connectedNavBar as DashboardNavBar };
