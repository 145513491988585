import React, {Component} from 'react';
import queryString from 'query-string';
import {GroupSignUp} from './groupInvite/groupSignUp';
import {SignUpToGroup} from './userInvite/signUpToGroup';
import invitationService from "./duck/invitationService";
import {Spinner} from "react-bootstrap";

const INVITED_STATUS = 'INVITED';
const SIGN_UP_MODE = {
    LOADING: 'loading',
    TEAM: 'team',
    MEMBER: 'member'
}
class SignUp extends Component{

    state = {
        signUpMode: SIGN_UP_MODE.LOADING,
        invitationDetails: null
    }

    componentDidMount() {
        const {invitationId} = queryString.parse(this.props.location.search)
        invitationService.getInvitationDetails(invitationId)
            .then(invitationDetails => {
                let signUpMode = null;
                if(invitationDetails.organisation.status === INVITED_STATUS) {
                    signUpMode = SIGN_UP_MODE.TEAM;
                } else {
                    signUpMode = SIGN_UP_MODE.MEMBER;
                }
                this.setState({invitationDetails, signUpMode})
            });
    }

    render() {
        return(
            <React.Fragment>
                {this.state.signUpMode === SIGN_UP_MODE.TEAM &&
                <GroupSignUp invitationDetails={this.state.invitationDetails}/>}
                {this.state.signUpMode === SIGN_UP_MODE.MEMBER && <SignUpToGroup invitationDetails={this.state.invitationDetails}/>}
                {this.state.signUpMode === SIGN_UP_MODE.LOADING &&
                    <React.Fragment><div className="spinnerCentre"><Spinner animation="grow" size="lg" /></div> </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default SignUp;
