import farmsService from './farmService';

const LOADING = 'FARMS_FETCH_LOADING';
const SUCCESS = 'FARMS_FETCH_SUCCESS';
const FAILURE = 'FARMS_FETCH_FAILURE';
const CLEAR_DATA = 'FARMS_CLEAR_DATA';

const CREATING = 'FARM_CREATING';
const CREATE_SUCCESS = "FARM_CREATE_SUCCESS";
const CREATE_FAILURE = "FARM_CREATE_FAILURE";
const MULTI_SELECTED_FARM="FARM_MULTISELECTION_UPDATE"
const SELECTED_FARM="FARM_SELECTION_UPDATE";


function fetchFarms(q,s) {
    return (dispatch, getState) => {
        const token = farmsService.getFarmsForUserRequest();
        const state = getState().farms;
        if (state.token) {
            state.token.cancel('SEARCH SKIPPED');
        }
        dispatch(loading(token));
        token.request(q,s)
        .then(data => dispatch(success(data)))
        .catch(err => {
            if (!err.isCancelled) {
                console.log(err);
                dispatch(failure(err))
            }
        });
    }

    function loading(token) {
        return {
            type: LOADING,
            token
        }
    }

    function success(data) {
        return {
            type: SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: FAILURE,
            error
        }
    }
}

function clearFarmsData() {
    return {
        type: CLEAR_DATA
    }
}



function createFarm(payload,sort) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(creating());
            farmsService.createFarm(payload).then(response => {
                dispatch(success(response));
                dispatch(fetchFarms('',sort));
            }).catch(error => {
                dispatch(failure(error));
            });
            resolve();
        });
    }

    function creating() {
        return {type: CREATING, payload: true }
    }

    function success(data) {
        return { type: CREATE_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CREATE_FAILURE, payload:error}
    }
}

const getSelectedFarm = (payload) => ({
    type: SELECTED_FARM,
    payload
})

const getSelectedMultiFarm = (payload) => ({
    type: MULTI_SELECTED_FARM,
    payload
})



export default {
    fetchFarms,
    clearFarmsData,
    createFarm,
    getSelectedFarm,
    getSelectedMultiFarm,
    TYPES: {LOADING, SUCCESS, FAILURE, CLEAR_DATA, CREATING, CREATE_FAILURE, CREATE_SUCCESS,SELECTED_FARM,MULTI_SELECTED_FARM}
}
