import React from "react";
import {Card, Form} from "react-bootstrap";
import GForm from "../../common/form";
import logo from "../../../assets/images/common/groguru-long-logo.png";
import profileImage from "../../../assets/images/farms/farm-create/group-2.png";
import userActions from "../duck/userActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Message from "../../common/messages";
import Joi from "joi-browser";
import groupService from "../../group/duck/groupService";
import userService from "../duck/userService";
import localStorageService from "../../common/duck/localStorageService";
import {history} from "../../common/duck/history";

class JoinGroup extends GForm {

    state = {
        data: {
            name: this.props.profileDetails.profile.name || "",
            email: this.props.profileDetails.profile.email || "",
            profileImage: this.props.profileDetails.profile.profileImage
        },
        errors: {},
        serviceError: null,
        uploadedImage: null,
    }
    schema = {
        name: Joi.string().required().label('Your Name'),
        email: Joi.string().email({minDomainAtoms: 2}).label('Your Email'),
        profileImage: Joi.any()
    };

    htmlUpload = React.createRef();

  openFileUpload = (e) => {
    this.htmlUpload.current.click();
  }

  onUpload = (e) => {
    const {profile} = this.props.profileDetails;
    const image = e.currentTarget.files[0];
    this.setState({serviceError: null}, () => {
      groupService.postImage(image)
          .then(data => {
            userService.editProfile(profile.objectId, {profileImage: data.file.url,fileId:data.objectId})
                .then(data => this.setState({
                  uploadedImage: URL.createObjectURL(image),
                  data: { ...this.state.data, profileImage: data.image }
                }));
          })
          .catch(error => this.setState({ errors: {serviceError: "'image' Upload Failed"} }));
    });
  }


  doSubmit = () => {
    const {profile} = this.props.profileDetails;
    this.setState({serviceError: null});
    const payload = {...this.state.data};
    delete payload.profileImage;
    userService.editProfile(profile.objectId, payload)
        .then(() => {
          this.props.getProfile(localStorageService.getUserId());
          history.push(`/dashboard`);
        })
        .catch(error => this.setState( {serviceError: error.response.data.message} ));
  }

    render() {
        const {profile} = this.props.profileDetails;
        return (
            <div>
                <div className="join-team">
                    <div className="join-team-image-container">
                        <div className="content mb-40 mt-40">
                            <div className="text-center  ">
                                <img src={logo} className="groguru_long_logo" alt="logo-ic"/>
                                <h1>Welcome to GroGuru!</h1>
                                <p>
                                    You’ve been invited to join the {profile.organisation.name} team.
                                    Please fill in the information below to get started.
                                </p>
                            </div>
                            <Card className="mt-40">
                                <Card.Header className="d-flex justify-content-between">
                                    <div>Join a Team</div>
                                </Card.Header>
                                <Card.Body>
                                    <h6 className="mt-20">Personal Information</h6>
                                    <div className="d-flex  mt-20">
                                        <div className="d-flex" onClick={this.openFileUpload} >
                                        <img
                                            src={this.state.uploadedImage || (this.state.data && this.state.data.profileImage) || profileImage}
                                            alt="business profile"/>
                                        <h3>Add a Profile Picture +</h3>
                                        </div>
                                        <input name="image" type="file" onChange={this.onUpload} ref={this.htmlUpload}
                                               style={{"display": "none"}}/>
                                    </div>

                                    <Form className="mt-10">
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                {this.renderFormGroup('name', 'Your Name', "text")}
                                            </div>
                                            <div className="col-md-6 ">
                                                {this.renderFormGroup('email', 'Your Email', "text")}
                                            </div>
                                            {this.renderButton('Finish', 'btn btn-submit mt-20')}
                                            {this.state.serviceError && <Message variant="error" message={this.state.serviceError}/>}
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getProfile: userActions.getProfile
};

const mapStateToProps = (state) => {
    const {profileDetails} = state.userDetails;
    return {profileDetails}
}

const connectedJoinGroup = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(JoinGroup)
);

export {connectedJoinGroup as JoinGroup};
