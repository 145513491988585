import React,{useEffect,useState,useRef} from "react";
import { Card } from "react-bootstrap";
import * as moment from 'moment';
import aboutService from "../about/duck/aboutService";


export default function AboutBody(props) {
    
    const [notes,setNotes]=useState([]);

        const prevProps = useRef(props.siteDetails);
        useEffect(
            () => {
                if (prevProps.id!== props.siteDetails.id ) {            
                    aboutService.getNotes(props.siteDetails.id).then(res=>setNotes(res))

                   
                }
            },[props.siteDetails.id]
        )
       useEffect(
         ()=>{
           if(props.updatedNotes!==null)
           {
            aboutService.getNotes(props.siteDetails.id).then(res=>setNotes(res))

           }
         },[props.updatedNotes,props.siteDetails.id]
       )
 return (

<div className="previous-note-container mt-50">
    <Card>
        <Card.Header  className="d-flex justify-content-between">
            <h6>Previous Notes</h6>
        </Card.Header>
        <hr></hr>
        <Card.Body>
            <div className="notes-container">
            {notes  && Object.values(notes).map((notes)=>{
                   let stillUtc = notes.date&&notes.date!==null?moment.utc(notes.date).toDate():null;
                      let local = stillUtc!==null?moment(stillUtc).local().format("MMM DD,YYYY-hh:mmA"):'';


          return (notes.text||notes.image) && <div className="previous-notes" key={Math.random()}>
           
            <div className="search-content ">
                  <div className="d-flex justify-content-between">
                  <div className="d-flex">
                  <div className="">
                    <img src={notes.user?.organisation?.image} alt=""></img>
                  </div>
                  <div className="search-text pl-20">
                  <h6>{notes.user?.name}</h6>
                  <p>{notes.user?.organisation?.name},{notes.user?.organisation?.type}</p>
                  </div>
                  </div>
                
                  {notes.date &&<div className="date-time">{local}
                      </div>}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-20">
                {typeof(notes.text) !== 'object' &&notes.text !== null && 
                    <div className="contents">
                          <p>{notes.text && notes.text}</p>
                      </div>}
                      <div >
                          <img src={notes.image && notes.image} className="content-img" alt='img'></img>
                      </div>
                  </div>

                    </div>})}
          
          
          </div>
        </Card.Body>
    </Card>
</div>
    );
}