import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import InputSelect from "./inputSelect";
import FormGroup from "./formGroup";
import RadioInput from "./radioInput";
import SpinnerButton from './spinnerButton';

class Form extends Component {
    state = {
        data: {},
        errors: {},
        userIdError: {},
        userEmailError: {}
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors !== null) return;
        var resp = await this.doSubmit();
        if (resp?.data.status === 500 && resp?.data.message === "UserId already exists, kindly enter a new userId!") {
            this.setState({ userIdError: { ...resp.data } });
        }
        else if (resp?.data.status === 500 && resp?.data.message === "Email already exists, kindly enter different email!") {
            this.setState({ userEmailError: { ...resp.data } });
        }
    };

    validate = () => {
        const { error } = Joi.validate(this.state.data, this.schema, {
            abortEarly: false
        });
        const errors = {};
        if (!error) return null;
        for (let item of error.details) {
            errors[item.path[0]] = item.message;

        }
        return errors;
    };


    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const compiled = Joi.compile(this.schema[name]);

        if (compiled._refs.length) {
            const referencedField = compiled._refs[0];
            schema[referencedField] = this.schema[referencedField];
            obj[referencedField] = this.state.data[referencedField];
        }
        const { error } = Joi.validate(obj, schema);

        return error ? error.details[0].message : null;
    };

    handleChange = ({ currentTarget: input }) => {


        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        this.setState({ userIdError: {}, userEmailError: {} });
        const error = this.validateProperty(input);
        if (error) errors[input.name] = error;
        else delete errors[input.name];

        data[input.name] = input.value;
        this.setState({ data, errors });

        if (!errors[input.name] && this.handleOptionChange) {
            this.handleOptionChange(input.name, data[input.name]);
        }
    }

    renderButton = (label, classAttribs = "btn btn-primary", spinner = false, spinnerText = "Loading..") => {
        if (spinner) {
            return <SpinnerButton
                disabled
                spinning={true}
                className={classAttribs} >{spinnerText}</SpinnerButton>

        }

        return <button type="submit" disabled={this.validate()}


            className={!this.validate() ? classAttribs
                : `${classAttribs} disabled`
            }
            onClick={this.handleSubmit}>{label}</button>
    }



    renderInput = (name, label, controlProps = {}) => {
        const { data, errors } = this.state;
        return (
            <Input
                label={label}
                name={name}
                onChange={this.handleChange}
                value={data[name]}
                error={errors[name]}
                {...controlProps}
            />
        );
    };
    renderSelect = (name, label, options, controlProps = {}) => {
        const { data, errors } = this.state;
        return (
            <InputSelect
                label={label}
                name={name}
                onChange={this.handleChange}
                options={options}
                value={data[name]}
                error={errors[name]}
                {...controlProps}
            />
        );
    };

    renderRadioInput = (label, name, value, controlProps) => {
        const type = "radio"
        const { errors } = this.state;
        return (
            <RadioInput
                type={type}
                name={name}
                label={label}
                value={value}
                {...controlProps}
                error={errors[name]} />
        )
    }

    renderFormGroup = (name, label, type, controlProps = {}) => {



        const { errors, data, userIdError, userEmailError } = this.state;
        return (
            <FormGroup
                label={label}
                name={name}
                type={type}
                userIdStatus={userIdError}
                userEmailStatus={userEmailError}
                value={data[name]}
                onChange={this.handleChange}
                //onBlur={this.handleChange}
                error={errors[name]}
                {...controlProps}
            />
        );
    };

}


export default Form;

