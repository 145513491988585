import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import {connect} from 'react-redux';
import userActions from '../user/duck/userActions';
import CardElementForm from './cardInput';

function AddCardModal({show, updateCardsList, onClose, addCardToken}){

    const [error, setError] = useState(null);

    function handleSubmit(token) {
        addCardToken(token)
            .then(data =>{ updateCardsList(data.stripeCustomerDetails.sources.data)})
            .then(data => onClose())
            .catch(err => setError(err));
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header className="d-flex justify-content-between">
                <p>Add Card</p>
            </Modal.Header>
            <Modal.Body>
                {error && 'Error: could\'nt update your card details'}
                <CardElementForm
                    onSubmit={handleSubmit}
                    buttonLabel="Add Card" />
            </Modal.Body>
        </Modal>
    );
}

const mapDispatchToProps = {
    updateCardsList: userActions.updateCardList
}

export default connect(null, mapDispatchToProps)(AddCardModal);