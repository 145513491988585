import React from 'react';

function RadioInput({name,type,label,value,...controlProps}) {
  return (
  
      <label className="label">
         <p>{label}</p>
        <input
          type={type}
          name={name}
          value={value}
          {...controlProps}
        />
        <span className="checkmark"></span>
      </label>
   
  );
}

export default RadioInput;