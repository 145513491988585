import React from 'react';
import { Dropdown } from 'react-bootstrap';
import defaultimg from "../../assets/images/farms/farm-default-icon.png";
import groupService from './duck/groupService';
import {invertUserLevel} from './duck/groupUtils';


const memberMenuRoleChangeOption = {
    admin: 'Downgrade to User Permissions',
    user: 'Upgrade to Admin Permissions'
}

function MemberMenu(props) {

    const [orgType, userLevel] = props.role.split('_');

    return (
        <div>
            <Dropdown onSelect={menuActions}>
                <Dropdown.Toggle variant="none" id="dropdown-basic">
                    <i className="fas fa-ellipsis-v"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item eventKey="roleChange" >{memberMenuRoleChangeOption[props.userLevel]}</Dropdown.Item>
                    <Dropdown.Item eventKey="remove" className="red-color">Remove From Team</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    function menuActions(key) {
        switch (key) {
            case 'roleChange':
                props.changeUserRoleUI(userLevel, props.userId);
                groupService.memberRoleChange(props.orgId, props.userId, `${orgType}_${invertUserLevel[userLevel]}`)
                    .then(console.log)
                    .catch(error => props.changeUserRoleUI(invertUserLevel[userLevel], props.userId));
                break;
            case 'remove':
                groupService.removeGroupMember(props.orgId, props.userId)
                    .then(data => props.removeMemberUI(userLevel, props.userId))
                    .catch(console.log);
                break;
            default:
                console.log(key);
        }
    }

}


export default function MemberItem(props) {
   
  
    return (
        <div className="row pt-20">
            <div className="col-7">
                <div className="members">
                    <div className="member-profile d-flex">
                        <div className="profile-img">
                            <img src={props.image ?props.image : props.data.profileImage ?props.data.profileImage:defaultimg} alt="icon" />
                        </div>
                        <div className="profile-desc pt-5">
                            <h3 className="mb-0">{props.data.name}</h3>
                            <p>{props.data.phone}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div  className="col-5 text-right">
            <MemberMenu
                role={props.data.role.name}
                userLevel={props.userLevel}
                orgId={props.data.organisation.objectId}
                userId={props.data.objectId}
                changeUserRoleUI={props.changeUserRoleUI}
                removeMemberUI={props.removeMemberUI} />
                </div>
        </div>
    );
}