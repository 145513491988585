import React from 'react';

function Input({name,label,error, ...rest}) {
  return (
    <div className="form-label-group mt-10">
      <label htmlFor={name} className="form-label">{label}</label>
      <input {...rest} id={name} name={name} className="form-control"/>
      { error &&  <span className="error">{error}</span> }
    </div>
  );
}

export default Input;
