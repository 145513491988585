import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import SpinnerButton from '../../../../common/spinnerButton';
import templateService from '../duck/templateService';
import Confirm, { useConfirm } from '../../../../common/confirm';
import validateTemplateData, { orderByStartDay } from '../duck/templateValidation';
import TableRow from './tableRow';
import { templateSampleData } from '../templateDetails';
import { lastItem } from '../../../../common/duck/utils';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const templateTableHead = ['Start Day', 'Red', 'Yellow', 'Green', 'Blue'].map(text => ({ text }));

function TemplateCreate(props) {

    const [isSubmiting, setIsSubmiting] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isTableHidden, setIsTableHidden] = useState(true);

    const { confirmBoxSate, askConfirmation } = useConfirm();

    const htmlUpload = React.createRef();

    useEffect(() => {
        // reset errors when template is changed
        setError(null);
    }, [props.templateId])

    function insertRow() {
        props.setFormData(data => {
            const lastRow = lastItem(data);
            if (!lastRow) {
                return [templateSampleData];
            }
            return [...data, {
                ...lastRow,
                start_day: parseInt(lastRow.start_day) + 10,
                addedRow: true,
            }]
        });
    }

    function updateRowAtIndex(index, upadte) {
        props.setFormData(data => data.map((row, i) => {
            if (index === i) {
                return { ...row, ...upadte };
            }
            return row;
        }));
    }

    function deleteRowAtIndex(index) {
        props.setFormData(data => data.filter((row, rowIndex) => {
            if (rowIndex !== index) {
                return row;
            }
            return false;
        }))
    }

    function insertAfterIndex(index, row) {
        props.setFormData(data => {
            data.splice(index + 1, 0, row);
            return [...data];
        })
    }

    function exportTemplateData() {
        try {
            const csv = Papa.unparse(props.formData);
            let blob = new Blob([csv]);
            saveAs(blob, props.templateName ? `${props.templateName}.csv` : `download.csv`);
        } catch (err) {
            console.error(err);
        }
    }

    function addValues(color) {
        let column;
        switch (color) {
            case 'Red':
                column = 'red';
                break;
            case 'Yellow':
                column = 'yellow';
                break;
            case 'Blue':
                column = 'light_blue';
                break;
            default:
                column = 'green';
        }
        props.setFormData(data => data.map((row, i) => {
            let y = { [column]: ++row[column] };
            return { ...row, ...y }
        }));
    }

    function checkNegativeValues(color, data) {
        let c = data.filter(d => {
            return d[color] < 1;
        });
        if (c.length > 0) {
            return false;
        } else {
            return true;
        }
    }
    function minusValues(color) {
        let column;
        switch (color) {
            case 'Red':
                column = 'red';
                break;
            case 'Yellow':
                column = 'yellow';
                break;
            case 'Blue':
                column = 'light_blue';
                break;
            default:
                column = 'green';
        }
        let canProceed = checkNegativeValues(column, data);
        if (canProceed) {
            props.setFormData(data => data.map((row, i) => {
                let y = { [column]: --row[column] };
                return { ...row, ...y }
            }));
        } else {
            setShowModal(true);
        }

    }

    function importTemplateData(e) {
        let csvFile = e.currentTarget.files[0];
        Papa.parse(csvFile, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
                if (results.data.length > 0) {
                    props.applyTemplate({
                        id: null,
                        name: null,
                        isEditable: false,
                    });
                    props.setFormData(results.data);
                    const templateDataError = validateTemplateData(results.data, DuplicateData);
                    if (!templateDataError) {
                        setError(null);
                        props.updatePreview();
                    } else {
                        props.setTemplateColorSeries({ isEmpty: true });
                        setError(templateDataError);
                    }
                }
                if (results.errors.length > 0) {
                    console.error(results.errors)
                }
            },
        });
    }

    const openFileUpload = (e) => {
        htmlUpload.current.click();
    }

    function zeroIfNaN(num) {
        const int = parseInt(num);
        if (Number.isNaN(int)) {
            return 0;
        } else {
            return int;
        }
    }

    function saveTemplate() {
        const filterData = props.formData.filter((row, index, arr) => (arr.findIndex(e => e.start_day === row.start_day)) === index);
        const templateDataError = validateTemplateData(props.formData, DuplicateData);
        if (!templateDataError) {
            setError(null)
            askConfirmation({
                onYes: () => {
                    setIsSubmiting(true);
                    const data = filterData.map(({ start_day, red, yellow, green, light_blue }) => ({
                        start_day: zeroIfNaN(start_day),
                        red: zeroIfNaN(red),
                        yellow: zeroIfNaN(yellow),
                        green: zeroIfNaN(green),
                        light_blue: zeroIfNaN(light_blue)
                    }));
                    templateService.editTemplate(props.templateId, { templateData: orderByStartDay(data) })
                        .catch(err => setError('An error occured'))
                        .then(() => setIsSubmiting(false))
                }
            })
        } else {
            setError(templateDataError);
        }
    }

    function viewEditedTemplate() {
        setError(false);
        // const filterData = formData.filter((row,index, arr) => (arr.findIndex(e => e.start_day === row.start_day) ) === index);
        const templateDataError = validateTemplateData(props.formData, DuplicateData);
        if (!templateDataError) {
            setError(null);
            props.updatePreview();
        } else {
            setError(templateDataError);
        }
    }
    let data = [];
    const arr = [...props.formData];
    const comp = (arr, len = 0, deletable = false) => {
        if (len < arr.length) {
            if (deletable) {
                if ((isNaN(arr[len].start_day))) {
                    console.log("row1");
                    let newArr = { ...arr[len], isNew: true }
                    arr[len] = { ...newArr }
                }
                else if (arr[len].addedRow) {
                    console.log("row2");

                    arr[len] = { ...arr[len] }
                }
                else {
                    console.log("row3");
                    let newArr = { ...arr[len], addedNew: true }
                    arr[len] = { ...newArr }
                }
            }
            return comp(arr, len + 1, arr[len]?.red === arr[len + 1]?.red && arr[len]?.yellow === arr[len + 1]?.yellow && arr[len]?.green === arr[len + 1]?.green && arr[len]?.light_blue === arr[len + 1]?.light_blue)
        };
        return;
    };
    comp(arr);
    data = [...arr]

    //  (formData).map((row,index, arr) => (arr.findIndex(e => e.red ===row.red && e.yellow === row.yellow && e.green === row.green && (isNaN(e.start_day) === isNaN(row.start_day) && !isNaN(row.start_day) && !row.isNew) ) === index) ? data.push(row) : (isNaN(row.start_day) ? data.push({...row,isNew : true})  : row.addedRow ? data?.push({...row}) : data?.push({...row,addedNew : true})) );
    let DuplicateData = []
    data.map((r, i) => {
        if (!DuplicateData.includes(r.start_day) && r.addedNew) {
            DuplicateData.push(r.start_day)
            return DuplicateData;
        }
        return DuplicateData;
    }

    )

    let isDisabled = false;
    if (props.profileDetails && (props.profileDetails.profile.role.name === 'dealer_user' ||
        props.profileDetails.profile.role.name === 'business_user' ||
        props.profileDetails.profile.role.name === 'farm_user')) {
        isDisabled = true;
    }
    return (
        <div className="template-tablecontainer">

            {isTableHidden ? <button onClick={() => setIsTableHidden(false)}>Show Template Table</button>
                : null}
            {!isTableHidden ? <button onClick={() => setIsTableHidden(true)}>Hide Template Table</button>
                : null}
            {!isTableHidden ? (
                <div>
                    <div> {error && <small style={{ color: "red", display: 'block', textAlign: 'right' }}>{error}</small>}</div>
                    <div className="text-right">
                        <button disabled={isDisabled} onClick={exportTemplateData}>Export</button>
                        <button disabled={isDisabled} onClick={openFileUpload}>Import</button>
                        <input disabled={isDisabled} name="csv" type="file" onChange={importTemplateData} onClick={(event) => { event.currentTarget.value = null }} accept=".csv" ref={htmlUpload} style={{ "display": "none" }} />
                        <button disabled={isDisabled} onClick={insertRow}>Add Row</button>
                        <button disabled={isDisabled} onClick={viewEditedTemplate}>Update Preview</button>
                        {props.isTemplateOverwitable && <SpinnerButton
                            spinning={isSubmiting}
                            disabled={isSubmiting || isDisabled}
                            onClick={saveTemplate}>Save</SpinnerButton>}
                    </div>
                    <div className="template-header">
                        <h6>{props.templateName ? props.templateName : 'New Template'}</h6>
                    </div>
                    <Table striped bordered hover responsive="lg">
                        {/* <TableHead heads={templateTableHead} /> */}
                        <thead>
                            <tr>{templateTableHead.map(({ text, tooltipName, ...rest }) =>
                                <th key={text} title={tooltipName} {...rest}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ marginBottom: text === 'Start Day' ? '10px' : '-3px' }} >{text}</div>
                                        {text !== 'Start Day' ? <div style={{ display: 'flex' }}>

                                            <button onClick={() => addValues(text)} style={{ padding: '5px', border: 'none' }}>+</button>
                                            <button onClick={() => minusValues(text)}
                                                style={{ padding: '5px', border: 'none', marginTop: '5px' }}>-</button>
                                        </div> : null}
                                    </div>
                                </th>)}
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((row, index) => <TableRow
                                key={index}
                                data={row}
                                index={index}
                                onChange={updateRowAtIndex}
                                onDelete={deleteRowAtIndex}
                                onInsetAfter={insertAfterIndex} />)}
                        </tbody>
                    </Table></div>) : null}

            <Modal centered
                onHide={() => { setShowModal(false) }}
                className="checkout-modal" show={showModal}>
                <Modal.Header className="d-flex justify-content-between">
                    <p>Info</p>
                    <p className="cp" onClick={() => { setShowModal(false) }}>Close</p>
                </Modal.Header>

                <Modal.Body>Values cannot be negative.</Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Confirm
                show={confirmBoxSate.show}
                title="Overwrite existing template?"
                message="This action cannot be reverted. Are you sure you want to continue?"
                onYes={confirmBoxSate.onYes}
                onNo={confirmBoxSate.onNo} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { userDetails } = state;
    const profileDetails = userDetails.profileDetails;
    return { profileDetails };
};

export default connect(mapStateToProps)(TemplateCreate);