import ApexCharts from 'apexcharts';

export function hiddenYAxis(seriesName, rest = {}) {
    return {
        seriesName,
        show: false,
        decimalsInFloat: 1,
        // forceNiceScale: true,
        crosshairs: {
            show: true,
            position: 'front',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 4,
            }
        },
        tooltip: {
            enabled: true,
        },
        ...rest
        // max: 100
    }
}

export function hiddenTemplateYAxis(seriesName, rest = {}) {
    return {
        seriesName,
        show: true,
        decimalsInFloat: 1,
        // forceNiceScale: true,
        crosshairs: {
            show: true,
            position: 'front',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 4,
            }
        },
        tooltip: {
            enabled: true,
        },
        ...rest
        // max: 100
    }
}


export function toggleSeriesVisibility(chartName, seriesName, toggle = 'toggle') {
    try {
        return ApexCharts.exec(chartName, `${toggle}Series`, seriesName);
    } catch (err) {
        console.log(err)
        return false;
    }
}

export function toggleSectionVisibility(chartName, seriesNameList, toggle = 'toggle') {
    return Promise.all(seriesNameList.map(seriesName => toggleSeriesVisibility(chartName, seriesName, toggle)));
}

export function updateOptions(chartName, options, redraw = false, animate = false) {
    return ApexCharts.exec(chartName, 'updateOptions', options, redraw, animate);
}

export function updateSeries(chartName, newSeries, animate = false) {
    return ApexCharts.exec(chartName, 'updateSeries', newSeries, animate);
}

export function clearAnnotations(chartName) {
    return ApexCharts.exec(chartName, 'clearAnnotations');
}

export function resetChart(chartName) {
    try {
        return ApexCharts.exec(chartName, 'resetSeries');
    } catch (err) {
        console.log(err);
        return false;
    }
}

export function removeAnnotation(chartName, annotationName) {
    return ApexCharts.exec(chartName, 'removeAnnotation', annotationName)
};

export function addYaxisAnnotation(chartName, options) {
    return ApexCharts.exec(chartName, 'addYaxisAnnotation', options);
}

export function dataURI(chartName) {
    return ApexCharts.exec(chartName, 'dataURI');
}

export function hideSeries(chartName, series, options) {
    return ApexCharts.exec(chartName, series, options);
}

export function showSeries(chartName, series, options) {
    return ApexCharts.exec(chartName, series, options);
}