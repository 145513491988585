import React, { useState, useEffect, useCallback, useMemo, useContext, useRef } from "react";
import { useSelector } from 'react-redux';
import { connect } from "react-redux";
import ThresholdChart from '../../../common/chart/ThresholdChart';
import { Line } from '../../../common/chart/series';
import { dataURI } from '../../../common/chart/utils';
import templateService from '../template/duck/templateService';
import graphService from './duck/graphService';
import TemplateGraphLegend from "../graph/templateGraphLegend";

import {
  getDateParams,
  chartTooltipXAxisFormatting,
  toUTC,
  initialDateParams,
} from './duck/dateUtility';
import {
  getPoints,
  getPointsByDepth,
  getDatapointsMinMax,
  sliceTimeseries,
  getMapping,
  hasNoDepthVariants,
} from './duck/graphUtility';
import { showSeries, hideSeries, addYaxisAnnotation, hiddenTemplateYAxis, updateOptions, updateSeries, clearAnnotations } from '../../../common/chart/utils';
import colors, { axisColors } from './duck/colorUtility';
import {
  MEASURE_SHORT_NAMES,
  MEASURE_NAME,
  MEASURE_TYPE,
  DATA_TYPES,
  DATA_TYPES_KEYS
} from './duck/constants';
import GraphLoader from './graphLoader';
import { StickyMessage } from '../../../common/messages';
import SiteContext, { SiteUpdate } from './duck/siteContext';
import sma from './duck/features/sma';
import applyStackLevel, { MAX_STACK, applyCommonScale } from './duck/features/stacking';
import yaxisScaleOptions from './duck/features/yaxisScale';
import printIcon from '../../../../assets/images/print/print.svg';
import yzoom from '../../../../assets/images/zoom/yzoom.png';
import printJS from 'print-js';
import makeDocFromChart from './duck/features/printDoc';
import http from '../../../common/duck/httpService';
import ERROR from './duck/messages';

function tooltipUpdater(callback) {
  return function ({ dataPointIndex, seriesIndex, w }) {
    let xValue;
    try {
      xValue = w.config.series[seriesIndex].data[dataPointIndex].x;
    } catch (err) {
      xValue = null;
    }
    callback({ dataPointIndex, seriesIndex, xValue });
    return '<div style="display:none"></div>';
  };
}

function TemplateGraphDetails({ siteAnnotation, timeDuration, routeParams, checkList, annotationName, curvesToRenderUpdateToParent, }) {
  const graphPreferences = useSelector(state => state.userDetails.userPreferences.preferences.preference?.graphPreference)
  const { siteid, /*farmid,*/ twigid, } = routeParams;
  const siteUserSettings = graphPreferences?.[siteid];
  const siteDetails = useContext(SiteContext);
  const updateSiteDetails = useContext(SiteUpdate);
  const [depthKeyMapping, setDepthKeyMapping] = useState({}); // {key: {key: objKey, depth: calcd_depth}}
  const [measurementsMapping, setMeasurementsMapping] = useState({}); // {'aw': {unit, label}}
  // eslint-disable-next-line no-unused-vars
  const [displayKey, setDisplayKey] = useState('absolute');
  const templateId = siteDetails?.cropTemplate?.objectId || null;
  const [plantingDate, setPlantingDate] = useState(null);
  //const [waterPercoationData, setWaterPercoationData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [legendView, setLegend] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState({ shouldLoadInitialGraph: true, shouldLoadInitialTemplate: true, graph: false, template: false });


  const setTemplateId = useCallback(objectId => {
    updateSiteDetails.handleSiteDetailsUpdate({ cropTemplate: { objectId } });
  }, [updateSiteDetails])

  const [datapointsCache, setDatapointsCache] = useState({ queued: true, cacheCount: 0 });

  // filter menu options
  const [measurements, setMeasurements] = useState([]);
  const [depths, setDepths] = useState([]);
  const [timePeriod, setTimePeriod] = useState(timeDuration);
  // eslint-disable-next-line no-unused-vars
  const [dataType, setDataType] = useState([DATA_TYPES_KEYS.Raw]);
  const [dateParams, setDateParams] = useState(
    initialDateParams(
      siteUserSettings?.timePeriod?.fromDate,
      siteUserSettings?.timePeriod?.toDate,
      //savedTimePeriodOption
    )
  );

  const [currentSensorIndex, setCurrentSensorIndex] = useState(0);

  const fcValues = siteAnnotation?.fcValues || [];
  let blueAbsolute;
  if (fcValues) {
    blueAbsolute = fcValues.map((item) => {
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });
  }
  let fcMin = Math.min(...blueAbsolute);

  let fcMax = Math.max(...blueAbsolute);

  const fcValues_mlValue = siteAnnotation?.fcValues_mlValue || [];
  let blueAutoAbsolute;
  if (fcValues_mlValue) {
    blueAutoAbsolute = fcValues_mlValue.map((item) => {
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const pwpValues = siteAnnotation?.pwpValues || [];
  let maroonAbsolute;
  // let prevMaroonAbsolute = ;
  if (pwpValues) {
    maroonAbsolute = pwpValues.map((item) => {
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });

  }
  let pwpMin = Math.min(...maroonAbsolute);

  const pwpValues_mlValue = siteAnnotation?.pwpValues_mlValue || [];
  let maroonAutoAbsolute;
  if (pwpValues_mlValue) {
    maroonAutoAbsolute = pwpValues_mlValue.map((item) => {
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const rpValues = siteAnnotation?.rpValues || [];
  let redAbsolute;
  let rpMin;
  if (rpValues.length > 0 && fcValues && maroonAbsolute) {

    redAbsolute = rpValues.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
     rpMin = Math.min(...redAbsolute);

  }

  const rpValues_mlValue = siteAnnotation?.rpValues_mlValue || [];
  let redAutoAbsolute;
  if (rpValues_mlValue.length > 0 && fcValues && maroonAbsolute) {
    redAutoAbsolute = rpValues_mlValue.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
  }
  const wl1Values = siteAnnotation?.wl1Values || [];
  let yellowAbsolute;
  let wl1Min;
  if (wl1Values.length > 0 && fcValues && maroonAbsolute) {
    yellowAbsolute = wl1Values.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
    wl1Min = Math.min(...yellowAbsolute);

  }

  const wl1Values_mlValue = siteAnnotation?.wl1Values_mlValue || [];
  let yellowAutoAbsolute;
  if (wl1Values_mlValue.length > 0 && fcValues && maroonAbsolute) {
    
    yellowAutoAbsolute = wl1Values_mlValue.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });

  }

  const wl2Values = siteAnnotation?.wl2Values || [];
  let greenAbsolute;
  let greenMax;
  let wl2Min;
  if (wl2Values.length > 0 && fcValues && maroonAbsolute) {
    greenAbsolute = wl2Values.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
    greenMax = Math.max(...greenAbsolute);
    wl2Min = Math.min(...greenAbsolute);

  }

  const graphMax = fcMax > greenMax ? fcMax : greenMax;
  const wl2Values_mlValue = siteAnnotation?.wl2Values_mlValue || [];
  let greenAutoAbsolute;
  if (wl2Values_mlValue.length > 0 && fcValues && maroonAbsolute) {
    greenAutoAbsolute = wl2Values_mlValue.map((item, i) => {
      const a = fcValues[i];
      const x = Object.values(a)[0] - maroonAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const fcValuesLocal = siteAnnotation?.fcValuesLocal || [];
  let blueLocalAbsolute;
  if (fcValuesLocal.length > 0) {
    blueLocalAbsolute = fcValuesLocal.map(item => { 
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const pwpValuesLocal = siteAnnotation?.pwpValuesLocal || [];
  let maroonLocalAbsolute;
  if (pwpValuesLocal.length > 0) {
    maroonLocalAbsolute = pwpValuesLocal.map(item => {
      const r = Object.values(item)[0];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const rpValuesLocal = siteAnnotation?.rpValuesLocal || [];
  let redLocalAbsolute;
  if (rpValuesLocal.length > 0 && blueLocalAbsolute && maroonLocalAbsolute) {
    redLocalAbsolute = rpValuesLocal.map((item, i) => {
      const x = blueLocalAbsolute[i] - maroonLocalAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonLocalAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const wl1ValuesLocal = siteAnnotation?.wl1ValuesLocal || [];
  let yellowLocalAbsolute;
  if (wl1ValuesLocal.length > 0 && blueLocalAbsolute && maroonLocalAbsolute) {
    yellowLocalAbsolute = wl1ValuesLocal.map((item, i) => {
      const x = blueLocalAbsolute[i] - maroonLocalAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonLocalAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  const wl2ValuesLocal = siteAnnotation?.wl2ValuesLocal || [];
  let greenLocalAbsolute;
  if (wl2ValuesLocal.length > 0 && blueLocalAbsolute && maroonLocalAbsolute) {
    greenLocalAbsolute = wl2ValuesLocal.map((item, i) => {
      const x = blueLocalAbsolute[i] - maroonLocalAbsolute[i];
      const y = (Object.values(item)[0] * x) / 100;
      const r = y + maroonLocalAbsolute[i];
      const d = Number(r.toFixed(2));
      return d;
    });
  }

  let pwpLocal; let fcLocal;
  let graphMin;
  let minLocalArray = [];
  let minArray = [pwpMin, fcMin, rpMin, wl1Min, wl2Min];

  if (pwpValuesLocal.length > 0 || fcValuesLocal.length > 0) {
    if (pwpValuesLocal.length > 0){
      pwpLocal = pwpValuesLocal[0].deepSensor1;
      minLocalArray.push(pwpLocal);
    }
    if(fcValuesLocal.length > 0){
      fcLocal = fcValuesLocal[0].deepSensor1;
      minLocalArray.push(fcLocal);
    }
    graphMin = Math.min(...minLocalArray) < Math.min(...minArray) ? Math.min(...minLocalArray) : Math.min(...minArray);
  } else {
    graphMin = Math.min(...minArray);
  }

  useEffect(() => {
    setTimePeriod(timeDuration);
    setCurrentSensorIndex(0);
  }, [timeDuration])

  const dataNotFoundForDateRange = useRef(false);
  const prevTimePeriod = useRef(null);
  const prevDateParams = useRef({ fromDate: '', toDate: '' });
  const dateParamsOnDisplay = useRef({ fromDate: '', toDate: '' });
  const dataPoints = useMemo(() => {
    dataNotFoundForDateRange.current = false;
    if (timePeriod !== 'CUSTOM' && datapointsCache[timePeriod]) {
      prevTimePeriod.current = timePeriod;
      prevDateParams.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
      dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
      return datapointsCache[timePeriod];
    } else {
      const reqStart = Date.parse(dateParams.fromDate);
      const reqEnd = Date.parse(dateParams.toDate);
      const startAvailable = reqStart >= datapointsCache.fromDate;
      const endAvailable = reqEnd <= datapointsCache.toDate;
      if (timePeriod === 'CUSTOM') {
        if (startAvailable && endAvailable) {
          dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
          return sliceTimeseries(datapointsCache.ONE_YEAR, {
            fromDate: dateParams.fromDate,
            toDate: dateParams.toDate
          });
        } else {
          if (
            datapointsCache['CUSTOM'] &&
            datapointsCache['CUSTOM'].start === dateParams.fromDate &&
            datapointsCache['CUSTOM'].end === dateParams.toDate
          ) {
            if (Object.keys(datapointsCache['CUSTOM'].data).length === 0) {
              return []
            } else {
              dateParamsOnDisplay.current = { fromDate: dateParams.fromDate, toDate: dateParams.toDate };
              return datapointsCache['CUSTOM'].data;
            }
          }
          dataNotFoundForDateRange.current = true;
        }
      }
      if (timePeriod !== 'CUSTOM' && datapointsCache.cacheCount !== 0) {
        dataNotFoundForDateRange.current = true;
      }
      if (prevTimePeriod.current && datapointsCache[prevTimePeriod.current]) {
        if (prevDateParams.current.fromDate === dateParams.fromDate && prevDateParams.current.toDate === dateParams.toDate) {
          dataNotFoundForDateRange.current = false;
        }
        return datapointsCache[prevTimePeriod.current]
      }
      return []
    }
  }, [timePeriod, dateParams.fromDate, dateParams.toDate, datapointsCache]);

  const measurementOptions = useMemo(() => {
    const measurementsByName = {};
    let measurementsOrdered = [];

    for (let measureKey in dataPoints) {
      if (dataPoints[measureKey].absolute.length > 0 || dataPoints[measureKey].relative.length > 0) {
        const [measureAliasKey] = measureKey.split('_local');
        const name = MEASURE_NAME[measureAliasKey];
        const key = MEASURE_SHORT_NAMES[name];
        measurementsByName[name] = { key, name };
      }
    }

    for (let key in MEASURE_TYPE) {
      const item = measurementsByName[MEASURE_TYPE[key]];
      if (item) {
        measurementsOrdered.push(item);
      }
    }

    return measurementsOrdered;
  }, [dataPoints]);

  const [errors, setErrors] = useState(null);
  const templateAreaCount = useRef(0);
  const depthOptions = useMemo(() => Object.entries(depthKeyMapping).sort(([_, depthB], [__, depthA]) => depthB.depth - depthA.depth).map(([key, depthObj]) => ({ key, name: `${depthObj.depth} in` })), [depthKeyMapping]);

  // set data for legend
  const [legendPoints, setLegendPoints] = useState({ dataPointIndex: 0, seriesIndex: 0, xValue: 0 });
  const customTooltip = useCallback(
    tooltipUpdater(({ dataPointIndex, seriesIndex, xValue }) => {
      if (xValue === null) {
        console.log('might be hovering disabled lines.')
      } else if (templateAreaCount.current === 0) {
        setLegendPoints({ dataPointIndex, seriesIndex, xValue })
      } else {
        setLegendPoints({ dataPointIndex, seriesIndex: seriesIndex - templateAreaCount.current, xValue })
      }
    }), []
  );

  useEffect(() => {
    // show 'loading template' onload only when there is a preset template
    if (!siteDetails.isLoading) {
      const savedTemplateId = siteDetails?.cropTemplate?.objectId || null;
      if (savedTemplateId) {
        setTemplateId(savedTemplateId);
      } else {
        setIsDataLoading(loading => ({ ...loading, shouldLoadInitialTemplate: false }));
      }
    }
    // sitedetails has loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetails.isLoading]);

  useEffect(() => {
    if (timePeriod !== 'CUSTOM') {
      setDateParams(getDateParams(timePeriod));
    }
  }, [timePeriod])

  useEffect(() => {
    // fetch template data
    if (templateId && plantingDate) {
      setIsDataLoading(loading => ({ ...loading, template: true }));
      templateService
        .getTemplateData(siteid, templateId, plantingDate)
        .then((data) => {
          let colorSeriesData = {};
          for (let color in data.colorSeries) {
            colorSeriesData[color] = data.colorSeries[color].map((p, i) => ({
              x: data.dateSeries[i],
              y: p,
            }));
          }
          setIsDataLoading(loading => ({ ...loading, shouldLoadInitialTemplate: false, template: false }))
        })
        .catch(error => {
          setIsDataLoading(loading => ({ ...loading, shouldLoadInitialTemplate: false, template: false }));
          setTemplateId(null);
        });
    }
  }, [templateId, siteid, setTemplateId, plantingDate]);

  let shouldLoadInitialGraphData = (!dateParams.isEmpty && datapointsCache.cacheCount === 0);
  useEffect(() => {
    if (shouldLoadInitialGraphData) {
      setIsDataLoading(loading => ({ ...loading, graph: true }));
      const apiCall = graphService.getTwigData();

      apiCall.request(siteid, twigid, dateParams)
        .then(data => {
          let depthLSL = {};
          let depthHSL = getMapping.calculateDepth(data.siteDetails.settings.hardwareSensorLocation);
          if (data.siteDetails.settings.localSensorLocation.count) {
            depthLSL = getMapping.calculateDepth(data.siteDetails.settings.localSensorLocation, 'local-');
          }
          setDepthKeyMapping(Object.assign(depthHSL, depthLSL));
          setMeasurementsMapping(getMapping.measurementUnitsAndLabels(data.siteDetails.details.data));
          setDatapointsCache(cache => {
            if (timePeriod === 'CUSTOM') {
              const CUSTOM = {
                start: dateParams.fromDate,
                end: dateParams.toDate,
                data: data.graph
              }
              return { ...cache, cacheCount: cache.cacheCount + 1, CUSTOM };
            } else {
              return { ...cache, cacheCount: cache.cacheCount + 1, [timePeriod]: data.graph };
            }
          });
          setPlantingDate(toUTC(data.siteDetails.settings.plantingDate.value));
          setIsDataLoading(loading => ({ ...loading, shouldLoadInitialGraph: false, template: loading.template, graph: false }));
          setErrors(null); // dateParams change frequently when custom timeperiod is selected,  causing the api call to get cancelled multiple times
        })
        .catch(err => {
          if (!http.isCancel(err)) {
            console.log(err)
            setErrors(err?.response?.data?.message || 'Sorry, Something went wrong!');
            setIsDataLoading({ graph: false, template: false });
          }
        });
      return () => apiCall.cancel('Cancel: graph data');
    }
    // should not run when dataParams.isEmpty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteid, twigid, dateParams.fromDate, dateParams.toDate, shouldLoadInitialGraphData]);

  const isDepthsNotSet = depths.length === 0;
  const isMeasurementsNotSet = measurements.length === 0;
  useEffect(() => {
    // set default selections of measurement & depths
    const depthKeys = depthOptions.map((d) => d.key);
    if (isDepthsNotSet) {
      setDepths(depthKeys);
    }
    if (isMeasurementsNotSet && measurementOptions.length > 0) {
      const setting = (["MOIST"]).filter(
        measure => measurementOptions.find(opt => opt.key === measure)
      );
      if (setting.length > 0) {
        setMeasurements(setting);
      }
    }
    // 'siteUserSettings' changes on 'save' this should not reset filter options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDepthsNotSet, isMeasurementsNotSet, depthOptions, measurementOptions]);


  const linesXValueMinMax = useRef({ min: 0, max: 0 });
  const scalesByMeasurement = useRef({});
  // setting graph series data
  const curvesToRender = useMemo(() => {
    if (dataPoints.length === 0 || measurements.length === 0 || dataType.length === 0) {
      return []
    }

    const [left] = measurements;
    let xValue;
    scalesByMeasurement.current = {};
    linesXValueMinMax.current = { min: 0, max: 0 };

    let measurementUnitAndLabelByShortName = {};
    let curvesByMeasurement = {};
    for (let measure of measurements) {
      curvesByMeasurement[measure] = [];
    }


    for (const measureKey in dataPoints) {
      const measureKeyAlias = measureKey.split('_local')[0];
      const measureLabel = MEASURE_NAME[measureKeyAlias];
      const measureShortName = MEASURE_SHORT_NAMES[measureLabel];
      if (measurements.includes(measureShortName)) {
        let displayBranch = dataPoints[measureKey][displayKey];
        if (displayBranch.length === 0) {
          if (measureKey === 'aw_local' && displayKey === 'relative') {
            // without this fix, 'aw_local' absolute will be shown in chart because of this branch selection
            continue;
          } else if (dataPoints[measureKey]['absolute'].length !== 0) {
            displayBranch = dataPoints[measureKey]['absolute'];
          } else {
            displayBranch = dataPoints[measureKey]['relative'];
          }
        }
        for (const dType of dataType) {
          const smaWindowSize = parseInt(dType);
          const dataTypeName = dType === '0' ? 'Raw' : 'SMA';
          if (hasNoDepthVariants(displayBranch)) {
            const measurementDatapoints = sma(getPoints(displayBranch), smaWindowSize);
            scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
            if (!measurementUnitAndLabelByShortName[measureShortName]) {
              measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
            }
            xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
            const showTopSensorLabel = measureKey.includes('_local');
            const series = Line(`${measureShortName} ${showTopSensorLabel ? 'L' : '-'} ${dataTypeName}`, measurementDatapoints, {
              color: (measureKey.includes('aw')) ?
                colors.black
                : null,
              meta: {
                measureKey,
                shortName: measureShortName,
                measurement: measureLabel,
                depth: null,
                showTopSensorLabel,
                unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                opposite: left !== measureShortName,
                dataType: DATA_TYPES[dType]
              }
            });
            curvesByMeasurement[measureShortName].push(series);
          } else {
            let depthsToLoop = [];
            let prefix = '';
            if (measureKey.includes('_local')) {
              prefix = 'local-'
              depthsToLoop = depths.filter(d => d.includes('local-')).map(d => d.split('local-')[1]);
            } else {
              depthsToLoop = depths.filter(d => !d.includes('local-'));
            }
            if (!measurementUnitAndLabelByShortName[measureShortName]) {
              measurementUnitAndLabelByShortName[measureShortName] = { measureKey, ...measurementsMapping[measureKey] };
            }
            for (const depth of depthsToLoop) {
              const measurementDatapoints = sma(getPointsByDepth(displayBranch, depth), smaWindowSize);
              scalesByMeasurement.current[measureShortName] = getDatapointsMinMax(measurementDatapoints, scalesByMeasurement.current[measureShortName]);
              xValue = getDatapointsMinMax(measurementDatapoints, xValue, 'x');
              const series = Line(`${measureShortName} ${prefix}${depth} ${dataTypeName}`, measurementDatapoints, {
                color: null,
                meta: {
                  measureKey,
                  shortName: measureShortName,
                  measurement: measureLabel,
                  depth: depthKeyMapping[`${prefix}${depth}`],
                  showTopSensorLabel: false,
                  unit: measurementsMapping[measureKey]?.unit || measurementsMapping[measureKeyAlias]?.unit,
                  opposite: left !== measureShortName,
                  dataType: DATA_TYPES[dType]
                }
              });
              curvesByMeasurement[measureShortName].push(series);
            }
          }
        }
      }
    }
    let seriesArray = [];
    let colorIndex = 0;
    let localColorIndex = 0;
    // flatten all series & add colors linearly by depth
    for (let series of Object.values(curvesByMeasurement)) {
      let seriesForMeasurement = series;
      if (seriesForMeasurement[0]?.meta?.depth?.depth) {
        seriesForMeasurement = seriesForMeasurement.sort(
          (a, b) => a.meta?.depth?.depth - b.meta?.depth?.depth
        );
      }

      const multipleDatatypesSelected = dataType.length > 1;
      let shouldUseDifferentColor = true;
      for (let curve of seriesForMeasurement) {
        if (curve.color === null) {

          if (curve.meta?.depth?.key?.includes('local-')) {
            curve.color = colors.brown_fox[localColorIndex];
            ++localColorIndex;
          } else {
            curve.color = axisColors[colorIndex];

            if (multipleDatatypesSelected) {
              // the lines for same sensors must have same color for SMA & Raw
              shouldUseDifferentColor = !shouldUseDifferentColor;
            }

            if (shouldUseDifferentColor) {
              ++colorIndex;
            }

          }
        }

        seriesArray.push(curve);
      }
    }
    let localSeriesArray = seriesArray.filter(each => each?.data?.name?.includes("local-"));
    let normalSeriesArray = seriesArray.filter(each => !each?.data?.name?.includes("local-"));
    seriesArray = [...localSeriesArray, ...normalSeriesArray];
    if (xValue === undefined) {
      return []
    }

    let newMeasurements = measurements.map(each => (each === "RAIN" || each === "IRR") ? 'RAIN&IRR' : each).filter((item, index, array) => array.indexOf(item) === index);
    const axisSeries = newMeasurements.map(measureShortName => Line(`${measureShortName} yaxis`, [
      { x: xValue.min, y: scalesByMeasurement.current[measureShortName]?.min || 0 },
      { x: xValue.max, y: scalesByMeasurement.current[measureShortName]?.max || 10 }
    ], {
      color: colors.transparent,
      meta: {
        measureKey: measurementUnitAndLabelByShortName[measureShortName],
        measurement: MEASURE_TYPE[measureShortName],
        shortName: measureShortName,
        unit: measurementUnitAndLabelByShortName[measureShortName]?.unit,
        label: measurementUnitAndLabelByShortName[measureShortName]?.label,
        opposite: measureShortName !== left,
        hasYAxis: measureShortName,
        isHidden: true
      }
    }));

    linesXValueMinMax.current = xValue;

    return [...seriesArray, ...axisSeries];
  }, [dataPoints, measurements, displayKey, dataType, depths, depthKeyMapping, measurementsMapping]);

  // making template series
  const shouldNotViewTemplate = (
    !templateId ||
    !measurements.includes('AW') ||
    displayKey !== 'relative' ||
    !((dataPoints.aw || dataPoints.aw_local)?.relative?.length > 0)
  );

  let curves = useMemo(() => [...curvesToRender], [
    curvesToRender,
  ]);


  const curvesToRender1 = useMemo(() => {
    const newCurvesToRender = curvesToRender.filter((each) => !each.meta?.shortName.includes("RAIN") && !each.meta?.shortName.includes("IRR"));
    return newCurvesToRender.map((curve, seriesIndex) => ({
      color: curve.color,
      name: curve.meta.measurement,
      depth: curve.meta.depth && curve.meta.depth.depth,
      depthName: curve.meta.depth && curve.meta.depth.name,
      data: curve.data.data,
      dataType: curve.meta.dataType,
      unit: curve.meta.unit,
      hasYAxis: curve.meta.hasYAxis,
      isHidden: curve.meta.isHidden,
      seriesName: curve.data.name,
      seriesIndex,
      showTopSensorLabel: curve.meta.showTopSensorLabel
    }))
  }, [curvesToRender]);


  const prevSeriesNameList = useRef([]);
  const seriesNameList = useMemo(() => {
    const currentSeriesList = curvesToRender1.map(series => series.seriesName);

    if (prevSeriesNameList.current.length === currentSeriesList.length) {

      for (let seriesName of currentSeriesList) {

        if (!prevSeriesNameList.current.includes(seriesName)) {
          prevSeriesNameList.current = currentSeriesList;
          return currentSeriesList;
        }
      }
      return prevSeriesNameList.current;
    } else {
      prevSeriesNameList.current = currentSeriesList;
      return currentSeriesList;
    }
  }, [curvesToRender1]);
  const localSensorCount = siteAnnotation?.settings?.localSensorCount?.value || 0;
  const hasTopSensor = localSensorCount > 0;

  useEffect(() => {
    clearAnnotations('threshold-graph');
    if (hasTopSensor && currentSensorIndex < localSensorCount) {

      let annotateY = [
        {
          y: blueLocalAbsolute[currentSensorIndex],
          borderColor: "#4472C4", //dark blue
          id: "fcChecked",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#4472C4",
            style: {
              color: "#fff",
              background: "#4472C4",
              width: '50px',
            },
            text:
              `A: ${blueLocalAbsolute[currentSensorIndex].toString()} R: 100 `

          }
        },
        {
          y: greenLocalAbsolute[currentSensorIndex],
          id: "wl2Checked",
          borderColor: "#B4C7E7",// light blueAbsolute color(green in table should be light blueAbsolute as requirement)
          borderWidth: 3,

          strokeDashArray: 0,
          label: {
            offsetX: 0,
            offsetY: 15,
            borderColor: "#B4C7E7",
            style: {
              color: "#fff",
              background: "#B4C7E7"
            },
            text: `A: ${greenLocalAbsolute[currentSensorIndex]}
                   R: ${Object.values(wl2ValuesLocal[currentSensorIndex])[0]}`
          }
        },
        {
          y: yellowLocalAbsolute[currentSensorIndex],
          id: "wl1Checked",
          borderColor: "#70AD47", // green color(yellow in table should be green as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#70AD47",
            style: {
              color: "#fff",
              background: "#70AD47"
            },
            text: `A: ${yellowLocalAbsolute[currentSensorIndex]}
                   R: ${Object.values(wl1ValuesLocal[currentSensorIndex])[0]}`
          }
        },
        {
          y: redLocalAbsolute[currentSensorIndex],
          id: "rpChecked",
          borderColor: "#FF0000", //red color
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#FF0000",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#FF0000"
            },
            text: `A: ${redLocalAbsolute[currentSensorIndex]}
                   R: ${Object.values(rpValuesLocal[currentSensorIndex])[0]}`
          }
        },
        {
          y: maroonLocalAbsolute[currentSensorIndex],
          id: "pwpChecked",
          borderColor: "#A50021",//maroon
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#A50021",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#A50021"
            },
            text: `A: ${maroonLocalAbsolute[currentSensorIndex].toString()} R: 0`
          }
        },

      ];

      annotateY.map((i) => {
        return addYaxisAnnotation('threshold-graph', i);
      })
    } else {
      let redList;
      let yellowList;
      let greenList;
      let blueList;
      let maroonList;

      let redRelative = rpValues;
      if (checkList["rpChecked"] && redAbsolute?.length > 0) {
        redList = redAbsolute;
      } else if (checkList["rpChecked"] === undefined && redAbsolute?.length > 0) {
        redList = redAbsolute;
      } else if (!checkList["rpChecked"] && redAutoAbsolute?.length > 0) {
        redList = redAutoAbsolute;
        redRelative = rpValues_mlValue;
      } else if (!checkList["rpChecked"] && !redAutoAbsolute) {
        redList = redAbsolute; // single data on refresh fix
      }

      let yellowRelative = wl1Values;
      if (checkList["wl1Checked"] && yellowAbsolute?.length > 0) {
        yellowList = yellowAbsolute;
      } else if (checkList["wl1Checked"] === undefined && yellowAbsolute?.length > 0) {
        yellowList = yellowAbsolute;
      } else if (!checkList["wl1Checked"] && yellowAutoAbsolute?.length > 0) {
        yellowList = yellowAutoAbsolute;
        yellowRelative = wl1Values_mlValue;
      } else if (!checkList["wl1Checked"] && !yellowAutoAbsolute) {
        yellowList = yellowAbsolute;
      }

      let greenRelative = wl2Values;
      if (checkList["wl2Checked"] && greenAbsolute?.length > 0) {
        greenList = greenAbsolute;
      } else if (checkList["wl2Checked"] === undefined && greenAbsolute?.length > 0) {
        greenList = greenAbsolute;
      } else if (!checkList["wl2Checked"] && greenAutoAbsolute?.length > 0) {
        greenList = greenAutoAbsolute;
        greenRelative = wl2Values_mlValue;
      } else if (!checkList["wl2Checked"] && !greenAutoAbsolute) {
        greenList = greenAbsolute;

      }

      if (checkList["fcChecked"] && blueAbsolute?.length > 0) {
        blueList = blueAbsolute;
      } else if (checkList["fcChecked"] === undefined && blueAbsolute?.length > 0) {
        blueList = blueAbsolute;
      } else if (!checkList["fcChecked"] && blueAutoAbsolute?.length > 0) {
        blueList = blueAutoAbsolute;
      } else if (!checkList["fcChecked"] && blueAutoAbsolute.length === 0) {
        blueList = blueAbsolute;

      }

      if (checkList["pwpChecked"] && maroonAbsolute?.length > 0) {
        maroonList = maroonAbsolute;
      } else if (checkList["pwpChecked"] === undefined && maroonAbsolute?.length > 0) {
        maroonList = maroonAbsolute;
      } else if (!checkList["pwpChecked"] && maroonAutoAbsolute?.length > 0) {
        maroonList = maroonAutoAbsolute;
      } else if (!checkList["pwpChecked"] && maroonAutoAbsolute.length === 0) {
        maroonList = maroonAbsolute;

      }


      let annotateY = [];

      let r, y, g, b, m;
      let index = currentSensorIndex - localSensorCount; 
      if (maroonList?.length > 0) {
        m = {
          y: maroonList[index],
          id: "pwpChecked",
          borderColor: "#A50021",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#A50021",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#A50021"
            },
            text: `A: ${maroonList[index].toString()} R: 0`
          }
        }
        annotateY.push(m);
      }

      if (blueList?.length > 0) {
        b = {
          y: blueList[index],
          id: "fcChecked",
          borderColor: "#4472C4",
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#4472C4",
            style: {
              color: "#fff",
              background: "#4472C4",
              width: '50px'
            },
            text: `A: ${blueList[index].toString()} R: 100 `

          }
        };
        annotateY.push(b);
      }

      if (redList?.length > 0) {
        r = {
          y: redList[index],
          id: "rpChecked",
          borderColor: "#FF0000", //rpValues color
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#FF0000",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#FF0000",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${redList[index].toString()} 
                   R: ${Object.values(redRelative[index])[0]} `
          }
        };
        annotateY.push(r);
      };

      if (yellowList?.length > 0) {
        y = {
          y: yellowList[index],
          id: "wl1Checked",
          borderColor: "#70AD47", // green color(yellow in table should be green as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#70AD47",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#70AD47",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${yellowList[index].toString()}
                   R: ${Object.values(yellowRelative[index])[0]}`
          }
        };
        annotateY.push(y);
      }

      if (greenList?.length > 0) {
        g = {
          y: greenList[index],
          id: "wl2Checked",
          borderColor: "#B4C7E7",// light blue color(green in table should be light blue as requirement)
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: "#B4C7E7",
            offsetX: 0,
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#B4C7E7",
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
            text: `A: ${greenList[index].toString()}
                   R: ${Object.values(greenRelative[index])[0]}`
          }
        };
        annotateY.push(g);
      }
      if (annotateY.length > 0) {
        annotateY.map((i) => {
          return addYaxisAnnotation('threshold-graph', i);
        });
      }

    }

  }, [checkList,
    pwpValuesLocal, maroonLocalAbsolute,
    hasTopSensor, fcValuesLocal, blueLocalAbsolute,
    rpValuesLocal, redLocalAbsolute, wl1ValuesLocal, yellowLocalAbsolute, 
    wl2ValuesLocal, greenLocalAbsolute,
    localSensorCount,
    maroonAutoAbsolute, blueAutoAbsolute, blueAbsolute, redAbsolute,
    redAutoAbsolute, yellowAbsolute, yellowAutoAbsolute,
    greenAbsolute, greenAutoAbsolute,
    currentSensorIndex, wl2Values, wl2Values_mlValue,
    rpValues_mlValue, rpValues, maroonAbsolute, wl1Values, wl1Values_mlValue])

  // add Y-Axis forEach series
  const curvesYAxis = useMemo(() => {
    if (curves.length === 0) {
      return { min: 0, max: 100, decimalsInFloat: 0 };
    }

    let { min: awTemplateMin, max: awTemplateMax } = yaxisScaleOptions(
      scalesByMeasurement.current?.AW?.min < graphMin ? scalesByMeasurement.current?.AW?.min : graphMin,
      scalesByMeasurement.current?.AW?.max > graphMax ? scalesByMeasurement.current?.AW?.max : graphMax
    );

    if (awTemplateMin > 40) {
      awTemplateMin = 40;
    }

    if (awTemplateMax < 110) {
      awTemplateMax = 110;
    }

    return curves.map(curve => {
      const seriesName = curve.data.name;
      const [measureShortName] = seriesName.split(' ');

      const lowestYValue = scalesByMeasurement.current[measureShortName]?.min < graphMin ? (scalesByMeasurement.current[measureShortName]?.min) : graphMin;
      const highestYValue = scalesByMeasurement.current[measureShortName]?.max > graphMax ? (scalesByMeasurement.current[measureShortName]?.max) : graphMax;

      let { min, max, tickAmount, decimalsInFloat } = yaxisScaleOptions(lowestYValue, highestYValue);
      
      clearAnnotations('threshold-graph');
      if (templateAreaCount.current !== 0 && (measureShortName === 'AW' || curve.meta.isTemplate)) {
        if (min > 40) {
          min = 40;
        }

        if (max < 110) {
          max = 110;
        }
      }

      const tooltip = { enabled: true };
      if (curve.meta?.isTemplate) {
        return hiddenTemplateYAxis(seriesName, { min: awTemplateMin, max: awTemplateMax, tooltip })
      } else if (!curve.meta?.hasYAxis) {
        return hiddenTemplateYAxis(`${measureShortName} yaxis`, { min, max, tooltip })
      } else {
        return {
          seriesName,
          opposite: curve.meta.opposite,
          min,
          max,
          decimalsInFloat,
          title: { text: `${curve.meta.label} (${curve.meta.unit})` },
          forceUpdate: Date.now(),
          tickAmount,
          tooltip
        }
      }
    });
  }, [curves, graphMin, graphMax]);

  const minmaxFromStacking = useRef({ min: null, max: null });
  const [zoomY, setZoomY] = useState(0);
  const activeZoomedY = useRef(null);
  useEffect(() => {
    // Y-Axis zooming
    if (zoomY > 0) {
      let zoomedAxis;
      let canZoomIn = true;
      let isStacking = minmaxFromStacking.current.min !== null && minmaxFromStacking.current.max !== null;

      if (isStacking) {

        const start = activeZoomedY.current?.min || minmaxFromStacking.current.min;
        const end = activeZoomedY.current?.max || minmaxFromStacking.current.max;
        const factor = (end - start) / 10;
        const min = start + factor;
        const max = end - factor;
        zoomedAxis = { min, max, show: false };
        activeZoomedY.current = { min, max }

        if (min === max || max < min) {
          canZoomIn = false;
        }

      } else if (activeZoomedY.current === null) {

        activeZoomedY.current = [];
        zoomedAxis = curvesYAxis.map(yaxis => {
          let factor = (yaxis.max - yaxis.min) / 10;
          let min = yaxis.min + factor;
          let max = yaxis.max - factor;
          activeZoomedY.current.push({ min, max });
          return { ...yaxis, min, max };
        });

      } else {
        zoomedAxis = curvesYAxis.map((yaxis, index) => {
          let { min: low, max: high } = activeZoomedY.current[index];
          let factor = (high - low) / 10;
          let min = low + factor;
          let max = high - factor;
          activeZoomedY.current[index] = { min, max };
          if (min === max || max < min) {
            canZoomIn = false;
          }
          return { ...yaxis, min, max };
        });
      }
      if (canZoomIn) {
        updateOptions('threshold-graph', { yaxis: zoomedAxis });
      }
    } else if (zoomY === 0 && activeZoomedY.current) {
      updateOptions('threshold-graph', { yaxis: curvesYAxis });
      activeZoomedY.current = null;
    }
  }, [zoomY, curvesYAxis])
  let graphClass = "col-lg-12 col-md-12 col-12";

  if (!legendView) {
    graphClass = "col";
  }

  const storageMiss = dataNotFoundForDateRange.current;
  useEffect(() => {
    if (storageMiss) {
      // fetch the custom range in case its not available on dataPointsCache
      setIsDataLoading(state => ({ ...state, graph: true }));
      const apiCall = graphService.getTwigData();
      apiCall.request(siteid, twigid, { fromDate: dateParams.fromDate, toDate: dateParams.toDate })
        .then(data => {
          setDatapointsCache(cache => {
            if (timePeriod === 'CUSTOM') {
              const CUSTOM = {
                start: dateParams.fromDate,
                end: dateParams.toDate,
                data: data.graph
              }
              return { ...cache, cacheCount: cache.cacheCount + 1, CUSTOM };
            } else {
              return { ...cache, cacheCount: cache.cacheCount + 1, [timePeriod]: data.graph };
            }
          });
          setIsDataLoading(state => ({ ...state, graph: false }));
        })
    }
  }, [storageMiss, dateParams.fromDate, dateParams.toDate, siteid, twigid, timePeriod]);

 /*  useEffect(() => {
    setIsDataLoading(state => ({ ...state, graph: true }));
    const apiCall = graphService.getRainFallData();
    apiCall.request(siteid, { startDate: dateParams.fromDate, endDate: dateParams.toDate })
      .then(data => {
        let finalRainfallData = [];
        for (let i = 0; i < data.length; i++) {
          finalRainfallData.push({ x: getTimestamp(data[i].xValue), y: parseFloat(data[i].yValue.toFixed(3)) });
        }
        setIsDataLoading(state => ({ ...state, graph: false }));
      })
  }, [dateParams.fromDate, dateParams.toDate, siteid]);

  useEffect(() => {
    setIsDataLoading(state => ({ ...state, graph: true }));
    const apiCall = graphService.getIrrigationData();
    apiCall.request(siteid, { startDate: dateParams.fromDate, endDate: dateParams.toDate })
      .then(data => {
        let finalIrrigationData = [];
        for (let i = 0; i < data.length; i++) {
          finalIrrigationData.push({ x: getTimestamp(data[i].xValue), y: parseFloat(data[i].yValue.toFixed(3)) });
        }
        setIsDataLoading(state => ({ ...state, graph: false }));
      })
  }, [dateParams.fromDate, dateParams.toDate, siteid]); */

  //set stacking
  const [stackLevel, setStackLevel] = useState({ current: null, prev: null });
  const [stackedSeriesCache, setStackedSeriesCache] = useState([]);


  useEffect(() => {
    if (stackLevel.current !== null && stackLevel.current <= MAX_STACK && stackedSeriesCache.length > 0) {
      const { colors, updatedSeries, min, max } = stackedSeriesCache[stackLevel.current];

      updateSeries('threshold-graph', updatedSeries);
      if (stackLevel.current === 0) {
        setStackLevel({ current: null, prev: null });
        updateOptions('threshold-graph', { yaxis: curvesYAxis });
        minmaxFromStacking.current = { min: null, max: null };
        seriesNameList.map(s => {
          return hideSeries("threshold-graph", 'hideSeries', s);
        });
        const series = [updatedSeries[currentSensorIndex]];
        showSeries("threshold-graph", 'showSeries', series[0].name);
        clearAnnotations('threshold-graph');
        let annotateY;
        if (fcValues.length > 0 && maroonAbsolute.length > 0) {
          annotateY = [
            {
              y: Object.values(fcValues[0])[0],
              id: "fcChecked",
              borderColor: "#4472C4",
              borderWidth: 3,
              strokeDashArray: 0,
              label: {
                borderColor: "#4472C4",
                style: {
                  color: "#fff",
                  background: "#4472C4",
                  width: '50px'
                },
                text: `A: ${Object.values(fcValues[0])[0].toString()}
              R: 100`
              }
            },
            {
              y: greenAbsolute[0],
              id: "wl2Checked",
              borderColor: "#B4C7E7",// light blue color(green in table should be light blue as requirement)
              borderWidth: 3,
              strokeDashArray: 0,
              label: {
                offsetX: 0,
                offsetY: 15,
                borderColor: "#B4C7E7",
                style: {
                  color: "#fff",
                  background: "#B4C7E7"
                },
                text: `A: ${greenAbsolute[0].toString()}
              R:${Object.values(wl2Values[0])[0]} `
              }
            },
            {
              y: yellowAbsolute[0],
              id: "wl1Checked",
              borderColor: "#70AD47", // green color(yellow in table should be green as requirement)
              borderWidth: 3,
              strokeDashArray: 0,
              label: {
                borderColor: "#70AD47",
                style: {
                  color: "#fff",
                  background: "#70AD47"
                },
                text: `A: ${yellowAbsolute[0].toString()}
              R:${Object.values(wl1Values[0])[0]} `
              }
            },
            {
              y: redAbsolute[0],
              id: "rpChecked",
              borderColor: "#FF0000", //rpValues color
              borderWidth: 3,
              strokeDashArray: 0,
              label: {
                borderColor: "#FF0000",
                offsetX: 0,
                offsetY: 15,
                style: {
                  color: "#fff",
                  background: "#FF0000"
                },
                text: `A: ${redAbsolute[0].toString()} 
              R:${Object.values(rpValues[0])[0]} `
              }
            },
            {
              y: maroonAbsolute[0],
              id: "pwpChecked",
              borderColor: "#A50021",
              borderWidth: 3,
              strokeDashArray: 0,
              label: {
                borderColor: "#A50021",
                offsetX: 0,
                offsetY: 15,
                style: {
                  color: "#fff",
                  background: "#A50021"
                },
                text: `A: ${maroonAbsolute[0].toString()}
              R: 0`
              }
            },

          ];
          annotateY.map((i) => {
            return addYaxisAnnotation('threshold-graph', i);
          })
        }
      }

      if (stackLevel.current > 0) {
        updateOptions('threshold-graph', { colors, yaxis: { show: false, min, max } });
        minmaxFromStacking.current = { min, max };
        const onlyseries = seriesNameList.filter((i) => !i.includes('yaxis'))
        onlyseries.map(s => {
          return hideSeries("threshold-graph", 'hideSeries', s);
        });
        const series = [updatedSeries[currentSensorIndex]];
        showSeries("threshold-graph", 'showSeries', series[0].name);
        clearAnnotations('threshold-graph');
      }

    }
  }, [greenAbsolute, curvesYAxis,
    redAbsolute, yellowAbsolute, fcValues,
    wl2Values, wl1Values, rpValues, maroonAbsolute,
    stackLevel, currentSensorIndex, seriesNameList, stackedSeriesCache]);

  useEffect(() => {
    if (curvesToRender.length > 0) {
      // force reset the chart options & series
      setStackLevel(prevState => ({ current: 0, prev: prevState.current }))
    }

  }, [curvesToRender]);

  useEffect(() => {
    // precalculate all the stacked up levels of the graph data
    const normalisedSeries = applyCommonScale(curvesToRender.filter(series => !series.meta.isHidden && !series.meta.isTemplate), scalesByMeasurement.current)
    let computedSeriesList = Array
      .from({ length: MAX_STACK }, (_, i) => i + 1)
      .map(i => {
        const seriesDataAndColors = applyStackLevel(normalisedSeries, i);
        let minmax;
        for (const series of seriesDataAndColors.updatedSeries) {
          minmax = getDatapointsMinMax(series.data, minmax);
        }
        return { ...seriesDataAndColors, ...minmax };
      });
    setStackedSeriesCache([
      { updatedSeries: curves.map(c => c.data), colors: curves.map(c => c.color) },
      ...computedSeriesList
    ]);
  }, [curves, curvesToRender]);

  curvesToRenderUpdateToParent(curvesToRender);

  const currentDisabledLines = useRef([]);

  const siteHardwareType = siteDetails.twigDetails?.hardwaretype;
  const siteLastUpdated = siteDetails.twigDetails?.lastUpdated;
  const sitePlantType = siteDetails.twigDetails?.plantType;
  const stackingControls = useMemo(() => [
    { icon: '<i class="fa fa-plus" aria-hidden="true"></i>', title: 'Stack Plus', class: 'counterplus-btn', click: () => setStackLevel(prevState => ({ current: prevState.current < MAX_STACK ? prevState.current + 1 : prevState.current, prev: prevState.current })), index: 2 },
    { icon: '<i class="fa fa-minus" aria-hidden="true"></i>', title: 'Stack Minus', class: 'counterminus-btn', click: () => setStackLevel(prevState => ({ current: (prevState.current > 0 ? prevState.current - 1 : prevState.current), prev: prevState.current })), index: 3 },
    {
      icon: '<i class="fa fa-refresh" aria-hidden="true"></i>', title: 'Stack Reset', class: 'reset-btn', click: () => {
        setStackLevel(prevState => ({ current: 0, prev: prevState.current }));
        setZoomY(0);
      }
    },
    {
      icon: `<img src='${printIcon}' width="20">`, title: 'Print', class: 'print-btn', click: () => {
        dataURI('threshold-graph').then(({ imgURI }) => {
          printJS({
            printable: imgURI,
            type: 'image',
            header: makeDocFromChart(
              siteDetails.name,
              siteHardwareType,
              siteid,
              siteLastUpdated,
              sitePlantType,
              displayKey,
              dateParams.fromDate,
              dateParams.toDate,
              dataType,
              curves,
              currentDisabledLines.current
            )
          });
        })
      }
    },
    { icon: `<img src='${yzoom}' width="20">`, class: 'zoomin-yaxis', title: 'Zoom Y', click: () => setZoomY(zoom => zoom + 1), index: 1 }
  ], [siteid, siteDetails.name, siteHardwareType, siteLastUpdated, sitePlantType, displayKey, dateParams.fromDate, dateParams.toDate, dataType, curves]);

  const isPrefetchCompletionRequired = (
    isDataLoading.prefetching &&
    timePeriod !== 'CUSTOM'
  );
  const isCustomFetchLoading = timePeriod === 'CUSTOM' && (
    dateParamsOnDisplay.current.fromDate !== dateParams.fromDate ||
    dateParamsOnDisplay.current.toDate !== dateParams.toDate
  )
  const graphIsLoading = isCustomFetchLoading || isDataLoading.graph || isPrefetchCompletionRequired;

  const isStackedView = stackLevel.current > 0;

  let showMessage = null;
  if (!errors && !isDataLoading.shouldLoadInitialGraph) {
    if (curvesToRender.length === 0) {
      showMessage = ERROR.NO_DATA_FOR_DATE_RANGE;
    } else if (!plantingDate && isDataLoading.shouldLoadInitialTemplate) {
      showMessage = ERROR.NO_PLANTING_DATE;
    }
  }
  let showGraph = true;
  return (

    <React.Fragment >
      <GraphLoader
        graphIsLoading={graphIsLoading && siteDetails.siteId}
        templateIsLoading={!shouldNotViewTemplate && isDataLoading.template} />
      { isDataLoading.prefetching && <small className="prefetching">Prefetching ...</small>}
      {
        (showGraph && (errors || showMessage)) && <StickyMessage
          variant={errors ? 'error' : null}
          message={errors || showMessage} />
      }
      <div className="graph-body-container">
        <div className="row">
          <div className={graphClass}>
            <div className="vertical-line">
              <div className="header-text border-bottom-black d-flex justify-content-between">

                <TemplateGraphLegend
                  timePeriod={timePeriod}
                  siteId={siteid}
                  legendPoints={legendPoints}
                  curvesToRender={curvesToRender}
                  dataTypesCount={dataType?.length}
                  updateCurrentSensorIndex={(i) => setCurrentSensorIndex(i)}
                  checkList={checkList}
                  red={rpValues}
                  redAbsolute={redAbsolute}
                  red2={rpValues_mlValue}
                  redAutoAbsolute={redAutoAbsolute}
                  yellow={wl1Values}
                  yellowAbsolute={yellowAbsolute}
                  yellow2={wl1Values_mlValue}
                  yellowAutoAbsolute={yellowAutoAbsolute}

                  green={wl2Values}
                  greenAbsolute={greenAbsolute}
                  green2={wl2Values_mlValue}
                  greenAutoAbsolute={greenAutoAbsolute}

                  blueAbsolute={blueAbsolute}
                  blueAutoAbsolute={blueAutoAbsolute}

                  maroonAbsolute={maroonAbsolute}
                  maroonAutoAbsolute={maroonAutoAbsolute}

                  rpValuesLocal={rpValuesLocal}
                  redLocalAbsolute={redLocalAbsolute}

                  wl1ValuesLocal={wl1ValuesLocal}
                  yellowLocalAbsolute={yellowLocalAbsolute}

                  wl2ValuesLocal={wl2ValuesLocal}
                  greenLocalAbsolute={greenLocalAbsolute}

                  fcValuesLocal={fcValuesLocal}
                  blueLocalAbsolute={blueLocalAbsolute}

                  pwpValuesLocal={pwpValuesLocal}
                  maroonLocalAbsolute={maroonLocalAbsolute}

                  siteAnnotation={siteAnnotation}
                />
              </div>
              <div id="main-chart-area" className="graph-region">
                {isStackedView && <small className="stackedView">Stacked View</small>}
                {siteDetails?.twigDetails ? <ThresholdChart
                  checkList={checkList}
                  timePeriod={timePeriod}
                  keys={legendView.toString()}
                  id="threshold-graph"
                  series={curves}
                  yAxis={curvesYAxis}
                  xAxisLabel={chartTooltipXAxisFormatting}
                  zoomType="xy"
                  customTooltip={customTooltip}
                  tools={stackingControls}
                  sensorIndex={currentSensorIndex}
                  red={rpValues}
                  redAbsolute={redAutoAbsolute}
                  red2={rpValues_mlValue}
                  redAutoAbsolute={redAutoAbsolute}

                  yellow={wl1Values}
                  yellowAbsolute={yellowAbsolute}
                  yellow2={wl1Values_mlValue}
                  yellowAutoAbsolute={yellowAutoAbsolute}

                  green={wl2Values}
                  greenAbsolute={greenAbsolute}
                  green2={wl2Values_mlValue}
                  greenAutoAbsolute={greenAutoAbsolute}

                  blueAbsolute={blueAbsolute}
                  blueAutoAbsolute={blueAutoAbsolute}

                  maroonAbsolute={maroonAbsolute}
                  maroonAutoAbsolute={maroonAutoAbsolute}
                /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
const mapStateToProps = state => ({
  userRole: state.userDetails.profileDetails?.profile?.role?.name,
});

export default connect(mapStateToProps)(TemplateGraphDetails);
