import React, { Component } from "react";
import { Nav, Spinner, DropdownButton, Dropdown } from "react-bootstrap";
// import cornerIcon from "../../../assets/images/corner-arrow/rectangle.png";
import Search from "../../common/search";
import SiteItem from "./siteItem";
import { connect } from 'react-redux';
import WeatherDetails from "./weatherDetails";
import siteService from "../duck/siteService";
import userService from "../../user/duck/userService";
import userAction from '../../user/duck/userActions';
import SiteEmptyCase from "../sidemenu/siteEmptyCase";
import defaultFarmIcon from "../../../assets/images/emptycase_icon/bitmap.png";
import SitePending from './sitePending';
import sortImg from '../../../assets/images/sort/sort_btn.png';
import aToz from '../../../assets/images/sort/az.png';
import zToa from '../../../assets/images/sort/za.png';
import PinColorService from '../duck/pinColorService';
import red from '../../../assets/images/sort/red.png';
import blue from '../../../assets/images/sort/blue.png';

class SiteBody extends Component {
  state = {
    search: "",
    data: null,
    errors: null,
    notfound: false,
    previousFarm : this.props.match.params.farmid,
    multifarmid: [],
    scrollReactionIncomplete: false,
    allFarmsFetched: false,
    multiFarmSites: null,
    farmSiteDetails: null,
    sort: this.props?.userSort?.sort || "ALPHA-ASC",
  };
  apiCall = null;
  requestObj = siteService.getSiteByName();
  componentDidMount() {

    const farmid = this.props.match.params.farmid;
    this.props.history.push({
      pathname: `/farm/${farmid}/sites`,
      search: "?query=Active"
    });

    if (this.props?.userSort?.sort === 'ALPHA-ASC') {
      this.aTozSort();
    } else if (this.props?.userSort?.sort === 'ALPHA-DESC') {
      this.zToaSort();
    } else if (this.props?.userSort?.sort === 'PINCLR-RED') {
      this.sortRed();
    } else if (this.props?.userSort?.sort === 'PINCLR-BLUE') {
      this.sortBlue();
    } else if (this.props?.multiSites) {
      this.setState({ multiFarmSites: [...this.props?.multiSites] });
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props?.multiSites && prevProps.multiSites !== this.props?.multiSites) || (this.props?.multiSites && this.state.multiFarmSites === null)) {
      if (this.state.sort === 'ALPHA-ASC') {
        this.setState({ multiFarmSites: [...this.props?.multiSites] }, () => {
          this.aTozSort();
        });

      } else if (this.state.sort === 'ALPHA-DESC') {
        this.setState({ multiFarmSites: [...this.props?.multiSites] }, () => {
          this.zToaSort();
        });

      } else if (this.state.sort === 'PINCLR-RED') {
        this.setState({ multiFarmSites: [...this.props?.multiSites] }, () => {
          this.sortRed();
        });

      } else if (this.state.sort === 'PINCLR-BLUE') {
        this.setState({ multiFarmSites: [...this.props?.multiSites] }, () => {
          this.sortBlue();
        });

      } else {
        this.setState({ multiFarmSites: [...this.props?.multiSites] });
      }

    };
    if (this.props?.sitedetails && prevProps.sitedetails !== this.props?.sitedetails) {

      if (this.state.sort === 'ALPHA-ASC') {
        this.setState({ farmSiteDetails: [...this.props?.sitedetails] }, () => {
          this.aTozSort();
        });

      } else if (this.state.sort === 'ALPHA-DESC') {
        this.setState({ farmSiteDetails: [...this.props?.sitedetails] }, () => {
          this.zToaSort();
        });

      } else if (this.state.sort === 'PINCLR-RED') {
        this.setState({ farmSiteDetails: [...this.props?.sitedetails] }, () => {
          this.sortRed();
        });

      } else if (this.state.sort === 'PINCLR-BLUE') {
        this.setState({ farmSiteDetails: [...this.props?.sitedetails] }, () => {
          this.sortBlue();
        });

      }
      this.setState({ farmSiteDetails: [...this.props?.sitedetails] });
    }
  }

  componentWillUnmount() {
    this.requestObj.cancel();
  }

  renderSection() {
    if (this.props.flag) {
      if (this.state.data !== null) {
        if (!this.state.notfound) {
          // handled sort
          return this.props.multiFarmsdetails.map(({ name, objectId }) =>
            <div key={objectId}>
              <div className="farm-name"><h6>{name}</h6></div>
              {this.state.data.map((site, index) => ((objectId === site.farm?.objectId) && site.twigDetails) &&
                <div key={index}>
                  {this.props.location.search === "?query=Active" ?

                    <SiteItem data={site} key={index} /> : <SitePending data={site} key={index} />}
                </div>
              )}
            </div>)
        } else {
          return <SiteEmptyCase search={this.state.search} />
        }
      } else {
        if (!this.state.notfound) {
          // handled sort
          let array = [];
          let multiSitesList = null;
          if (this.state?.multiFarmSites) {
            multiSitesList = this.state?.multiFarmSites?.filter((sites) => !array.includes(Object.keys(sites)[0]) ? array.push(Object.keys(sites)[0]) : null);
          }

          return (
            <>
              {(this.props?.multiFarmsdetails?.length > 0 && this.state?.multiFarmSites?.length === 0) ? <Spinner animation="grow" /> : (this.props.multiFarmsdetails.map(({ name, objectId }) =>
                <div key={objectId}>
                  {this.state?.multiFarmSites?.length > 1 && <div className="farm-name"><h6>{name}</h6></div>}
                  {multiSitesList && multiSitesList.map((sites) => objectId === Object.keys(sites)[0] ?
                    (Object.values(sites)[0].length !== 0 ? (Object.values(sites)[0].map((site) => (site.twigDetails &&
                      this.props.location.search === "?query=Active" ?
                      (<SiteItem data={site} farmid={objectId} key={site.siteId} />) : (this.props.location.search === "?query=Pending" ? <SitePending data={site} farmid={objectId} key={site.siteId} /> : <SiteItem data={site} farmid={objectId} key={site.siteId} />)
                    )))
                      : (
                        <div className="text-center empty-case" key={objectId}>
                          <div className="emptycase-icon mx-auto mt-15">
                            <img src={defaultFarmIcon} alt="icon"></img>
                          </div>
                          {<h4 className="mt-15 mb-10 noSitesFont">No Sites</h4>}
                          <hr></hr>
                        </div>
                      )) : ("")
                  )}
                </div>))}


              {this.state.scrollReactionIncomplete && this.props.selectAll && <Spinner animation="grow" />}

            </>)
        } else {
          return <SiteEmptyCase search={this.state.search} />
        }
      }
    }

    else {
      if (this.state.data !== null) {
        if (!this.state.notfound) {
          // handled sort
          return this.state.data.map(data => data.twigDetails && data.twigDetails.settings &&
            this.props.location.search === "?query=Active" ?
            <SiteItem data={data} key={data.id} /> :
            data.twigDetails && data.twigDetails.settings && <SitePending data={data} key={data.id} />);
        } else {
          return <SiteEmptyCase search={this.state.search} />
        }

      } else {
        if (!this.state.notfound) {

          return <div>
            {this.props?.isLoading && <Spinner animation="grow" />}
            {this.state?.farmSiteDetails?.map((site, index) => site?.twigDetails && site?.twigDetails?.settings &&
              this?.props?.location?.search === "?query=Active" ? <SiteItem data={site} key={index} /> : (this.props.location.search === "?query=Pending" ?
                (site?.twigDetails && site?.twigDetails?.settings && <SitePending data={site} key={index} />) :
                <SiteItem data={site} key={index} />))}
          </div>
        } else {
          return <SiteEmptyCase search={this.state.search} />
        }
      }
    }

  }

  getSite = (data, search) => {
    let siteArray = [];

    if (search.length === 0) {
      this.setState({ data: null, notfound: false });
    } else if (!this.props.flag) {
      data.map((site) => site.farm && site.farm.objectId === this.props.match.params.farmid ? siteArray.push(site) : "");

      this.setState({ data: siteArray, notfound: false });
      if (siteArray.length === 0) this.setState({ data: null, notfound: true });
    } else {
      data.map((site) => site.farm && this.props.multiFarms.includes(site?.farm?.objectId) ?

        siteArray.push(site) : "");

      this.setState({ data: siteArray, notfound: false });
      if (siteArray.length === 0) this.setState({ data: null, notfound: true });
    }

  };

  onSearch = (e) => {
    let status = this.props.location.search === "?query=Active" ? 'ACTIVE' : 'PENDING'
    let farmId = this.props.match.params.farmid;
    let trimmedstr = e.target.value;
    let searchQuery = trimmedstr.replace(/\s+$/, '');
    this.setState({ search: searchQuery });
    if (this.props?.flag && (searchQuery?.length > 0 && searchQuery?.length <= 3)) {
      let siteArray = [];

      this.state.multiFarmSites && this.state.multiFarmSites.map((sites) => Object.values(sites)[0].map((multi) => siteArray.push(multi)));

      const arr = siteArray.filter(site => ((site?.siteId.slice(0, 3) === searchQuery || site?.name?.toLowerCase().slice(0, 3) === searchQuery.toLowerCase()) || (site?.siteId?.slice(0, 2) === searchQuery || site?.name?.toLowerCase().slice(0, 2) === searchQuery.toLowerCase()) || (site?.siteId.slice(0, 1) === e.target.value || site?.name.toLowerCase().slice(0, 1) === e.target.value.toLowerCase())))
      // arr.sort((a, b) => (a.name > b.name) ? -1 : 1);
      if (arr?.length === 0) { this.setState({ data: null, notfound: true }) }
      else {
        this.setState({ data: arr, notfound: false })
      };
    }
    else {

      if (this.apiCall !== null && this.requestObj) {
        this.requestObj.cancel("SEARCH_SKIPPED");
        clearTimeout(this.apiCall);
        this.apiCall = null;
        this.requestObj = null;
      }

      this.requestObj = siteService.getSiteByName();
      this.apiCall = setTimeout(() => {
        let { flag } = this.props;
        this.requestObj
          .request(searchQuery, status, farmId, flag)
          .then((response) => {

            this.getSite(response, searchQuery)
          })
          .catch((error) => !error.isCancelled && this.setState({ data: null }));
      }, 800);
    }
  };

  handleClick = (value) => {
    const farmid = this.props.match.params.farmid;
    this.props.handleStatusChange(value)
    this.setState({ view: value })
    this.props.history.push({
      pathname: `/farm/${farmid}/sites`,
      search: value

    })
    this.props.selectedView(value)
  }

  handleFetchStart = () => {
    this.setState({ scrollReactionIncomplete: true });
  }

  handleFetchComplete = () => {
    this.setState({ scrollReactionIncomplete: false });
  }

  sortRToB = (siteList) => {
    let newArray = [];
    const red = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'red';
    });
    newArray.push(...red);

    const yellow = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'yellow';
    });
    newArray.push(...yellow);

    const green = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'green';
    });
    newArray.push(...green);

    const lightblue = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'lightblue';
    });
    newArray.push(...lightblue);

    const blue = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'blue';
    });
    newArray.push(...blue);

    const grey = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'white';
    });
    newArray.push(...grey);

    const white = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'grey';
    });
    newArray.push(...white);

    return newArray;
  }

  sortBToR = (siteList) => {
    let newArray = [];

    const blue = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'blue';
    });
    newArray.push(...blue);

    const lightblue = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'lightblue';
    });
    newArray.push(...lightblue);

    const green = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'green';
    });
    newArray.push(...green);

    const yellow = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'yellow';
    });
    newArray.push(...yellow);

    const red = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'red';
    });
    newArray.push(...red);

    const grey = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'white';
    });
    newArray.push(...grey);

    const white = siteList.filter(s => {
      return PinColorService(s.twigDetails.state) === 'grey';
    });
    newArray.push(...white);

    return newArray;
  }

  sortRed = () => {
    const { data, multiFarmSites, farmSiteDetails } = this.state;
    if (farmSiteDetails) {
      const farmdatarray = [...farmSiteDetails];
      const sortedList = this.sortRToB(farmdatarray);
      this.setState({ farmSiteDetails: sortedList });
    }
    if (data) {
      const datarray = [...data];
      const sortedList = this.sortRToB(datarray);
      this.setState({ data: sortedList });
    }

    if (multiFarmSites) {
      const a = multiFarmSites.map(s => {
        const sortedList = this.sortRToB(Object.values(s)[0]);
        return { [Object.keys(s)[0]]: sortedList }
      });
      this.setState({ multiFarmSites: a });
    }
    userService.updatePreferences({
      "site": {
        "sort": "PINCLR-RED"
      }
    }).then(response => this.props.updateSort(response.data));
    this.setState({
      sort: 'PINCLR-RED'
    });
  }

  sortBlue = () => {
    const { data, multiFarmSites, farmSiteDetails } = this.state;

    if (farmSiteDetails) {
      const farmdatarray = [...farmSiteDetails];
      const sortedList = this.sortBToR(farmdatarray);
      this.setState({ farmSiteDetails: sortedList });
    }
    if (data) {
      const datarray = [...data];
      const sortedList = this.sortBToR(datarray);
      this.setState({ data: sortedList });
    }

    if (multiFarmSites) {
      const a = multiFarmSites.map(s => {
        const sortedList = this.sortBToR(Object.values(s)[0]);
        return { [Object.keys(s)[0]]: sortedList }
      });
      this.setState({ multiFarmSites: a });
    }
    userService.updatePreferences({
      "site": {
        "sort": "PINCLR-BLUE"
      }
    }).then(response => this.props.updateSort(response.data));
    this.setState({
      sort: 'PINCLR-BLUE'
    });
  }

  sortDecend = (sites) => {
    return sites.sort(function (x, y) {
      let a = x.name.toLowerCase(),
        b = y.name.toLowerCase();
      return a === b ? 0 : a > b ? -1 : 1;
    });
  }

  zToaSort = () => {
    const { data, multiFarmSites, farmSiteDetails } = this.state;

    if (farmSiteDetails) {
      const farmdatarray = [...farmSiteDetails];
      const sortedList = this.sortDecend(farmdatarray);
      this.setState({ farmSiteDetails: sortedList });
    }
    if (data) {
      const datarray = [...data];
      const sortedList = this.sortDecend(datarray);
      this.setState({ data: sortedList });
    }

    if (multiFarmSites) {
      const a = multiFarmSites.map(s => {
        const sortedList = this.sortDecend(Object.values(s)[0]);
        return { [Object.keys(s)[0]]: sortedList }
      });
      this.setState({ multiFarmSites: a });
    }
    userService.updatePreferences({
      "site": {
        "sort": "ALPHA-DESC"
      }
    }).then(response => this.props.updateSort(response.data));
    this.setState({ sort: 'ALPHA-DESC' });
  }


  sortAcend = (sites) => {
    return sites.sort(function (x, y) {
      let a = x.name.toLowerCase(),
        b = y.name.toLowerCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  aTozSort = () => {
    const { data, multiFarmSites, farmSiteDetails } = this.state;
    if (farmSiteDetails) {
      const farmdatarray = [...farmSiteDetails];
      const sortedList = this.sortAcend(farmdatarray);
      this.setState({ farmSiteDetails: sortedList });
    }
    if (data) {
      const datarray = [...data];
      const sortedList = this.sortAcend(datarray);
      this.setState({ data: sortedList });
    }

    if (multiFarmSites) {
      const a = multiFarmSites.map(s => {
        const sortedList = this.sortAcend(Object.values(s)[0]);
        return { [Object.keys(s)[0]]: sortedList }
      });
      this.setState({ multiFarmSites: a });
    }
    userService.updatePreferences({
      "site": {
        "sort": "ALPHA-ASC"
      }
    }).then(response => this.props.updateSort(response.data));
    this.setState({ sort: 'ALPHA-ASC' });
  }
  handleScroll = (e) => {

    const bottom = Math.ceil(((e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight) * 100) >= 90
    if (bottom && this.props.onScroll) {
      this.props.onScroll(
        this.handleFetchStart,
        this.handleFetchComplete
      );
    }
  }
  render() {
    return (
      <div className="sitebody-container" onScroll={this.handleScroll} >
        {((this.props.farmdetails && this.props.multiFarmsdetails.length === 0) || (this.props.farmdetails && this.props.multiFarmsdetails.length === 1)) &&
          <WeatherDetails farmdetails={this.props.farmdetails} singleFarmDetails={this.props.singleFarmDetails} />}
        < div className="sitebody-header d-flex justify-content-between" >
          <div>
            <span>
              Site IDs
              {/* <img src={cornerIcon} alt="icon"></img> */}
            </span>
          </div>
          <div>
            {/* this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString()); */}
            <Nav defaultActiveKey="active" as="ul">
              <Nav.Item as="li">
                <Nav.Link eventKey="active" onClick={() => this.handleClick('?query=Active')}>Active</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="pending" onClick={() => this.handleClick('?query=Pending')}>Pending</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div >
        <hr></hr>
        <div className="row pt-10 pb-10 bg-white search-area">
          <div className="col-9" style={{ height: '44px' }}>
            <Search
              placeholder="Search for Site ID's ..."
              onSearch={this.onSearch}
            />
          </div>

          {this.props.location.search === "?query=Active" ? <div className="col-3">
            <DropdownButton
              className="sort_dropdown"
              key="right"
              id={`dropdown-button-drop`}
              drop="top"
              title={<img src={sortImg} alt="sort" height="30px" width="30px" />}
            >
              <Dropdown.Item eventKey="1">
                <div onClick={this.aTozSort}>
                  <img src={aToz} alt="sort" height="40px" width="40px" />
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <div onClick={this.zToaSort}>
                  <img src={zToa} alt="sort" height="40px" width="40px" />
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">
                <div onClick={this.sortRed}>
                  <img src={red} alt="sort" height="40px" width="40px" />
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="4">
                <div onClick={this.sortBlue}>
                  <img src={blue} alt="sort" height="40px" width="40px" />
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div> : null}
        </div>
        <hr></hr>
        { this.renderSection()}

      </div >
    );
  }
}

const mapStateToProps = state => ({
  userSort: state.userDetails.userPreferences.preferences.preference.site || { sort: "ALPHA-ASC" }
});

const mapDispatchToProps = {
  updateSort: userAction.updateSort,

};
export default connect(mapStateToProps, mapDispatchToProps)(SiteBody);

