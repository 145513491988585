import React from "react";
import { Card, Form } from "react-bootstrap";
import { connect } from 'react-redux';
import userAction from '../../user/duck/userActions';
import userService from "../../user/duck/userService";
import { default as GForm } from "../../common/form";

class EditUnitPreferences extends GForm {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        userService.getPreferences().then(response => {
            const { temperature, unit, defaultLanding, site } = response.data.preference;
            const sort = site?.sort;
            this.setState({ temperature, unit, defaultLanding, sort });
        });
    }

    state = {
        sort: null,
        unit: {
            feetInches: true,
            meters: false
        },
        temperature: {
            celsius: true,
            farenheit: false
        },
        defaultLanding: {},
        errors: {}
    };

    handlePreference = ({ target }) => {

        const field = target.name;
        const { value } = target;
        const preferred = { ...this.state[field] };
        for (let key in preferred) {
            preferred[key] = false;
        }
        preferred[value] = true;
        this.setState({
            [field]: preferred
        });
        userService.updatePreferences({ [field]: preferred });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeSort = (e) => {
        e.stopPropagation();
        userService.updatePreferences({
            "site": {
                "sort": e.target.value
            }
        }).then(response => this.props.updateSort(response.data));
        this.setState({ sort: e.target.value });
    }
    render() {
        const { unit } = this.state;
        const { temperature } = this.state;

        return (
            <div className="preference mt-15">
                <Card>
                    <Card.Body>
                        <div className="card-header-text d-flex justify-content-between">
                            <p className="header-text">Preferences</p>
                        </div>
                        <hr></hr>

                        <div className="card-body-text">
                            <Form onChange={this.handlePreference}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Units</Form.Label>

                                            <div className="d-flex radio-buttons">
                                                <div className="radio_tick ">
                                                    {this.renderRadioInput(
                                                        "Feet/Inches",
                                                        "unit",
                                                        "feetInches",

                                                        { checked: unit.feetInches, onChange: () => { } }
                                                    )}
                                                </div>
                                                <div className="radio_tick pl-30">
                                                    {this.renderRadioInput(
                                                        "Meters",
                                                        "unit",
                                                        "meters",

                                                        { checked: unit.meters, onChange: () => { } }
                                                    )}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Temperature</Form.Label>
                                            <div className="d-flex radio-buttons">
                                                <div className="radio_tick">
                                                    {this.renderRadioInput(
                                                        "Celsius",
                                                        "temperature",
                                                        "celsius",

                                                        { checked: temperature.celsius, onChange: () => { } }
                                                    )}
                                                </div>
                                                <div className="radio_tick pl-30">
                                                    {this.renderRadioInput(
                                                        "Fahrenheit",
                                                        "temperature",
                                                        "farenheit",
                                                        { checked: temperature.farenheit, onChange: () => { } }
                                                    )}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>



                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Sort order</Form.Label>
                                            <div className="form-group">
                                                <label htmlFor="sort" className="form-label">sort</label>
                                                <select className="form-control" id="sort-order" name="sort" value={this.state.sort} onChange={this.changeSort}>
                                                    <option key="ALPHA-ASC" value="ALPHA-ASC">ALPHA-ASC</option>
                                                    <option key="ALPHA-DESC" value="ALPHA-DESC">ALPHA-DESC</option>
                                                    <option key="PINCLR-RED" value="PINCLR-RED">PINCLR-RED</option>
                                                    <option key="PINCLR-BLUE" value="PINCLR-BLUE">PINCLR-BLUE</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>


                                </div>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

const mapDispatchToProps = {
    updateSort: userAction.updateSort,

};

export default connect(null, mapDispatchToProps)(EditUnitPreferences);
