import React from 'react';
import PropTypes from 'prop-types';
import IssueStatus from './issueStatus';

function FarmIssueStatus(props) {

  
    let needsAttentionCount='';
    let hasWarningCount='';
    let noIssuesCount='';
    let extractedCount='';
    if(props.reportingStatus)
    {
    
     needsAttentionCount = props.reportingStatus.find(farm => farm.status === 'LOW').count;
     hasWarningCount = props.reportingStatus.find(farm => farm.status === 'MED').count;
     noIssuesCount = props.reportingStatus.find(farm => farm.status === 'FAIR').count;
     extractedCount = props.reportingStatus.find(farm => farm.status === 'EXTRACTED').count;
    }
    else{
        needsAttentionCount = props.moistureStatus.find(farm => farm.value === 'LOW').count;
        hasWarningCount = props.moistureStatus.find(farm => farm.value === 'MED').count;
        noIssuesCount = props.moistureStatus.find(farm => farm.value === 'FAIR').count;
        extractedCount = props.moistureStatus.find(farm => farm.value === 'EXTRACTED').count;

    }
    return (
        <div className="col-md-4 d-flex justify-content-center align-items-center map-style">
            <IssueStatus
                agronomic={props.agronomic?props.agronomic:''}
                red={needsAttentionCount}
                yellow={hasWarningCount}
                green={noIssuesCount}
                maroon={extractedCount}
            />
        </div>
    );
}

FarmIssueStatus.propTypes = {
    reportingStatus: PropTypes.array,

    moistureStatus: PropTypes.array
};

export default FarmIssueStatus;
