/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import farmDefaultImage from "../../assets/images/farms/farmdefaultimg.png";
import { Card, Accordion, Button, Nav, ListGroup } from "react-bootstrap";
import FarmIssueStatus from "./farmIssueStatus";
import iconimage from "../../assets/images/left-arrow/chevron-left.png";
import techviewgreen from "../../assets/images/issues-icons/techview-green.svg";
import techviewred from "../../assets/images/issues-icons/techview-red.svg";
import techviewyellow from "../../assets/images/issues-icons/techview-yellow.svg";
import techviewmaroon from "../../assets/images/issues-icons/techview-maroon.svg";
import { NavLink } from "react-router-dom";
import {history} from "../common/duck/history"
class FarmCard extends Component {
  toggleArrow = (event) => {
    event.currentTarget.classList.toggle("arrow-90icon");
  };

  renderSiteListItem = (site) => {
    let state = this.props.data?.reportingStatusWithSites?.find(
      (e) => e.siteId === site.siteId
    );
    let image;
    if (state?.status === "LOW") {
      image = techviewred;
    } else if (state?.status === "MED") {
      image = techviewyellow;
    } else if (state?.status === "FAIR") {
      image = techviewgreen;
    }else if (state?.status === "EXTRACTED"){
      image = techviewmaroon; 
    }
    return (
      <ListGroup.Item
        key={site.siteId}
        className="attention d-flex justify-content-between"
        action
        onClick={() => history.push((`/farm/${site?.farm?.objectId}/site/${site.siteId}/${site.id}/${site.twigId}/graph`))}
      >
        <p>{site.siteId}</p>
        <p>{site.name}</p>
        <p className="pl-5 mt-1">
          <img src={image} alt=""></img>
        </p>
      </ListGroup.Item>
    );
  };

  render() {
    const farmDetails = this.props.data;

    return (
      <Accordion>
        <Card className="mb-2">
          <Card.Body>
            <div className="row">
              <Nav.Link
                className="col-md-7 d-flex"
                as={NavLink}
                to={{
                  pathname: `/farm/${farmDetails.objectId}/sites`,
                  state: {
                    farmDetails,
                  },
                }}
              >
                <div className="d-flex profile-content"></div>
                <div className="card-img ">
                  <img
                    src={farmDetails.image || farmDefaultImage}
                    alt="pic"
                  ></img>
                </div>
                <div className="card-text">
                  <h3 className="mb-0">{farmDetails.name}</h3>
                  <p>{farmDetails.physicalAddress}</p>
                </div>
              </Nav.Link>
              {!this.props.agronomic ? (
                <FarmIssueStatus
                  reportingStatus={farmDetails.reportingStatus}
                />
              ) : (
                <FarmIssueStatus
                  moistureStatus={farmDetails.moistureState}
                  agronomic={this.props.agronomic}
                />
              )}
              <div className="col-md-1" style={{ marginTop: "4%" }}>
                <Accordion.Toggle
                  as={Button}
                  onClick={this.toggleArrow}
                  variant="link"
                  eventKey="0"
                >
                  <img src={iconimage} alt="Expand this Accordion" />
                </Accordion.Toggle>
              </div>
            </div>
          </Card.Body>
          <Accordion.Collapse
            eventKey="0"
            style={{
              padding: "0px",
              border: "0.5px solid gray",
              borderRadius: "5px",
            }}
          >
            <Card.Header>
              <ListGroup>
                {farmDetails?.sites?.filter(sites => !(sites.deleted)).map((site) =>
                  this.renderSiteListItem(site)
                )}
              </ListGroup>
            </Card.Header>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default FarmCard;
